import React, {useState, useEffect, useRef} from 'react';
import { url } from '../../../../../Config';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import ApprovalIcon from '@mui/icons-material/Approval';


export default function ViewCriteria() {

    const [data, setdata] = useState(null);
    const { criteria_id } = useParams();
    const Navigate = useNavigate();

    const rightsval = localStorage.getItem("rights");
  
    let rights = [];
  
    if(rightsval !== null){
      rights = rightsval.split(",");
    }
  
    const has_right = rights.includes("4");
    const can_edit_after_approval = rights.includes("51");


    function DateFormat(params) {
        if (params == null) {
          return "-";
        } else {
          const date = new Date(params);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/UpdateCriteria/${criteria_id}`);
            const resp = res.data;
            setdata(resp.criteria);
        }

        func();
    },[])


  return (
    <div style={{height: '90vh'}}>
       <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/criteria-view"} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Criteria Group Member Detail</li>
                </ol>
            </nav>
            <hr/>
        </div>
        <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            Criteria Group Member Detail
        </div>
        {
     data !== null &&
     <>
     <div className='mt-2 col-11 mx-auto d-flex justify-content-end'>
        {(has_right == true || can_edit_after_approval || data.status == 'submit' || data.status == null) && <Tooltip title="Edit"><button className='btn btn-warning btn-sm me-1 mb-1' onClick={()=>Navigate(`/edit-criteria/${criteria_id}`)}><EditIcon /></button></Tooltip>}
     </div>
     <div className='card shadow col-11 mx-auto'>
        <table className="table table-bordered border-dark">
            <tbody>
                <tr className="text-center">
                    <td className="col-4 table-secondary fw-bold ">Member</td>
                    <td className="">{data.name}</td>
                </tr>
                <tr className="text-center">
                    <td className="col-4 table-secondary fw-bold ">Description</td>
                    <td className="">{data.description}</td>
                </tr>
                <tr className="text-center">
                    <td className="col-4 table-secondary fw-bold ">Appointment Date</td>
                    <td className="">{DateFormat(data.appointment_date)}</td>
                </tr>
                <tr className="text-center">
                    <td className="col-4 table-secondary fw-bold ">Experience in PACRA</td>
                    <td className="">{Number(data.experience_pacra) > 1 ? `${data.experience_pacra} Years` :  `${data.experience_pacra} Year`}</td>
                </tr>
                <tr className="text-center">
                    <td className="col-4 table-secondary fw-bold ">Total Experience</td>
                    <td className="">{Number(data.experience_total) > 1 ? `${data.experience_total} Years` :  `${data.experience_total} Year`}</td>
                </tr>
            </tbody>

        </table>
     </div>
     
     </>
     }
    </div>
  )
}
