import React, {useEffect, useState} from 'react';
import { url } from '../../../Config';
import axios from 'axios';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


export default function Edit_initiation_date(props) {

    const [data, setdata] = useState([]);
    const [bool, setbool] = useState(false);
    const [status, setstatus] = useState('loading');
    const [searchTerm, setSearchTerm] = useState("");
    // const [reason, setreason] = useState(Array(data.length));
    const [open, setOpen] = useState(false);
    const [message, setmessage] = useState();
    const id = localStorage.getItem("userID");



    function DateFormat(params) {
        if (params == null) {
          return "-";
        } else {
          const date = new Date(params);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

    useEffect(()=>{

        const func = async ()=>{
            const res = await axios.get(`${url}/api/myinprocess2/19`);
            const resp = res.data;
            setdata(resp);
            setstatus('loaded');
        }
        func();
    },[bool])

    // const [open, setOpen] = useState(Array(props.myinprocess.length).fill(false));
    let filteredOpinions = [];
    filteredOpinions = data.filter((value) => {

    if(searchTerm !== null){
      let Entity = [];
      if(value['Entity'] !== null){
        Entity = value['Entity'].toLowerCase().includes(searchTerm.toLowerCase());
      }
    //   let R_type = [];
    //   if(value['rating_type'] !== null){
    //     R_type = value['rating_type'].toLowerCase().includes(searchTerm.toLowerCase());
    //   }
    //   let r_updatetype = [];
    //   if(value['title'] !== null){
    //     r_updatetype = value['title'].toLowerCase().includes(searchTerm.toLowerCase());
    //   }
    //   let analystfilter = [];
    //   if(value['pacraAnalyst'] !== null){
    //     analystfilter = value['pacraAnalyst'].toLowerCase().includes(searchTerm.toLowerCase());
    //   }
    //   let leadfilter = [];
    //   if(value['managerName'] !== null){
    //    leadfilter = value['managerName'].toLowerCase().includes(searchTerm.toLowerCase());
    //   }
  
      return Entity;
    //   return Entity || R_type || r_updatetype || analystfilter || leadfilter;
    }
    else if(searchTerm == null){
      return data;
    }
    }
    );

    const submit = async(event, record_id, client_id, olddate, date, reason, name, managerid, sub, load, btn)=>{
        event.preventDefault();
        sub.hidden = true;
        load.hidden = false;
        btn.disabled = true;
        // const date = document.getElementById(`date${record_id}`).value;
        // const reason = document.getElementById(`reasonid${record_id}`).value;

        const res = await axios.post(`${url}/api/update_initiation_date`,{
            'record_id' : record_id,
            'client_id' : client_id,
            'name' : name,
            'date' : date,
            'old_date' : olddate,
            'reason': reason,
            'id': id,
            'managerid' : managerid
        });

        const resp = res.data;
        if(resp.status == true){
            sub.hidden = false;
            load.hidden = true;
            window.location.reload();
        }
        else{
            btn.disabled = false;
            sub.hidden = false;
            load.hidden = true;
            setOpen(true);
            setmessage(resp.messages); 
        }
        

    }


  return (
    <div style={data.length == 0 ? {height: '90vh'} : null}>
        <div className='fs-3 fw-bold text-center mt-2 mb-3' style={{color: "#000078"}}>
            Edit Initiation Date
        </div>
        {status == 'loaded' ?
         data.length > 0 ?
            <>
                <div className='col-9 mx-auto'>
                    <input className='form-control' placeholder='Search Opinion' onChange={(e)=>{setSearchTerm(e.target.value)}} />
                </div>
                <div className='mt-3 card shadow col-11 mx-auto'>
                    <table className='table table-bordered border-dark table-striped table-hover'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Opinion Name</th>
                                <th>Previous Initiation Date</th>
                                <th>Current Initiation Date</th>
                                <th>Team Lead</th>
                                <th>Analyst</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredOpinions.map((value, index)=>{
                                    return(
                                        <>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{value.Entity}</td>
                                                <td>{value.previous_init_date !== null ? DateFormat(value.previous_init_date) : ''}</td>
                                                <td>{DateFormat(value.Initiation)}</td>
                                                <td>{value.managerName}</td>
                                                <td>{value.pacraAnalyst}</td>
                                                <td><div data-bs-toggle="modal" data-bs-target={`#reason${value.record_id}`}><i className='fa-regular fa-pen-to-square fa-lg me-1' data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" style={{color: '#ffc107'}}></i></div></td>
                                            </tr>

                                            <div className="modal fade" id={`reason${value.record_id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <form className="modal-dialog modal-dialog-centered" onSubmit={(event)=>{submit(event, value.record_id, value.Id, value.Initiation, document.getElementById(`date${value.record_id}`, value.Entity).value, document.getElementById(`reasonid${value.record_id}`).value, value.Entity, value.user_id1, document.getElementById(`submit${value.record_id}`), document.getElementById(`load${value.record_id}`), document.getElementById(`btn${value.record_id}`))}}>
                                                    <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel">{value.Entity}</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <Box sx={{ width: '100%' }}>
                                                        <Collapse in={open}>
                                                            <Alert
                                                            action={
                                                                <IconButton
                                                                aria-label="close"
                                                                color="inherit"
                                                                size="small"
                                                                onClick={() => {
                                                                    setOpen(false);
                                                                }}
                                                                >
                                                                <CloseIcon fontSize="inherit" />
                                                                </IconButton>
                                                            }
                                                            sx={{ mb: 2 }}
                                                            >
                                                            {message}
                                                            </Alert>
                                                        </Collapse>
                                                    </Box>
                                                    <div className="modal-body">
                                                        <div className='text-center fs-6 fw-bold mb-2 mt-1'>Change Initiation Date</div>
                                                        <div className='fw-bold'>
                                                        Date:
                                                        </div>
                                                        <div>
                                                            <input className='form-control' type='date' defaultValue={value.Initiation} id={`date${value.record_id}`} required/>
                                                        </div>
                                                        <div className='fw-bold mt-2'>
                                                        Reason:
                                                        </div>
                                                        <div>
                                                        <textarea className='form-control'  style={{height: "100%"}} id={`reasonid${value.record_id}`} required></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" id={`reasondeclineclose${index}`} data-bs-dismiss="modal">Close</button>
                                                        <button className="btn btn-primary" type='submit' id={`btn${value.record_id}`}><div id={`submit${value.record_id}`}>Submit</div><div id={`load${value.record_id}`} hidden={true}><i class='fa-solid fa-spinner fa-spin fa-lg'></i></div></button>
                                                    </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </>

                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </> :
            <div className='fs-3 fw-bold text-center mt-5 mb-3' style={{color: "#000078"}}>
                No rows to show!
            </div>
            :
            (<div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
            <div class="wrapper" id="loader2">
              <svg
                class="hourglass"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 120 206"
                preserveAspectRatio="none"
              >
                <path
                  class="middle"
                  d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
                />
                <path
                  class="outer"
                  d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
                />
              </svg>
            </div>
          </div>)
        }
    </div>
  )
}
