import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../Config';
import { Check, Clear, Event } from "@material-ui/icons";
import Select from "react-select";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { TrendingUpRounded } from '@mui/icons-material';


export default function Edit_industry_grid(props) {

    const { id } = useParams();
    const [group, setgroup] = useState([]);
    const [industries, setindustries] = useState([]);
    const [sectors, setsectors] = useState([]);
    const [groupval, setgroupval] = useState('');
    const [industriesval, setindustriesval] = useState('');
    const [sectorsval, setsectorsval] = useState('');
    const [name, setname] = useState('');
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const btnRef = useRef(null);
    const Navigate = useNavigate();


    useEffect(()=>{

        const func = async ()=>{

            const res = await axios.get(`${url}/api/getclient_industry/${id}`);
            const resp = res.data;
            setgroup(resp.group);
            setname(resp.data[0].name);
            for(let i in resp.group){
                if(resp.group[i].id == resp.data[0].segment_id){
                    setindustries(resp.group[i].industries);
                    for(let j in resp.group[i].industries){
                        if(resp.group[i].industries[j].id == resp.data[0].main_sector_id){
                            setsectors(resp.group[i].industries[j].sectors);
                        }
                    }
                }
            }

            setgroupval(resp.data[0].segment_id);
            setindustriesval(resp.data[0].main_sector_id);
            setsectorsval(resp.data[0].sector_id);
           

        }

        func();
    },[])

    const editForm = async (event)=>{
        event.preventDefault();
        btnRef.current.disabled = true;
        btnRef.current.innerHTML = "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";


        const res = await axios.post(`${url}/api/update_client_industry`,{
            'segment': groupval,
            'mainsector': industriesval,
            'subsector': sectorsval,
            'client_id': id
        })

        const resp = res.data;
        if(resp.status == true){
            btnRef.current.disabled = false;
            btnRef.current.innerHTML = "Update";

            // Navigate('/sector_study');
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btnRef.current.disabled = false;
            btnRef.current.innerHTML = "Update";

            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
    }

  return (
    <div style={{height: '90vh'}}>
       <div className='fs-3 fw-bold text-center mt-2 mb-3' style={{color: "#000078"}}>
            {name}
        </div>
        <form className='card shadow col-11 mx-auto p-2' onSubmit={(event)=>{editForm(event)}}>
                <div>
                    <div className='mt-2 fw-bold'>
                        Update Segment:
                    </div>
                    <div className='mt-2'>
                    <select className='form-select' value={groupval} onChange={(e)=>{
                        setgroupval(e.target.value);
                        if(e.target.value == ''){
                            setsectors([]);
                            setindustries([]);
                        }
                        else{
                            for(let i in group){
                                if(group[i].id == e.target.value){
                                    setindustries(group[i].industries);
                                }
                            }
                        }
                        }
                        
                        } required>
                        <option value={''}>Select Group</option>
                        {
                            group.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                    </div>
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Update Main Sector:
                    </div>
                    <div className='mt-2'>
                        <select className='form-select' value={industriesval} onChange={(e)=>{
                            setindustriesval(e.target.value)
                            if(e.target.value == ''){
                                setsectors([]);
                            }
                            else{
                                for(let i in industries){
                                    if(industries[i].id == e.target.value){
                                        setsectors(industries[i].sectors);
                                    }
                                }
                            }
                            }} required>
                            <option value={''}>Select Industry</option>
                            {
                                industries.map((value, index)=>{
                                    return(
                                        <option value={value.id}>{value.title}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div>
                <div className='mt-2 fw-bold'>
                    Update Sub Sector:
                </div>
                <div className='mt-2'>
                    <select className='form-select' id='sectorname' value={sectorsval} onChange={(e)=>{
                        setsectorsval(e.target.value);
                        }} required>
                        <option value={''}>Select Sector</option>
                        {
                            sectors.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <Box className="mx-auto mt-2 mb-2" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
            </Box>
            <div>
                    <button className='btn btn-primary mt-3 mt-2 me-1' type='submit' ref={btnRef}>Update</button>
            </div>
        </form>
    </div>
  )
}
