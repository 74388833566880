import React, {useState, useEffect} from 'react'
import bg from "../../../Assets/Images/greyModel.jpg"
import './external.css';
import { url } from '../../../Config';
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';
import white from '../../../Assets/Images/whitebg.jpg'


export default function Rc_approved(props) {

    const id = localStorage.getItem("userID");
    const [alertopen, setalertopen] = useState(false);
    const [AlertMessage, setAlertMessage] = useState(null);
    const [alertType, setalertType] = useState("error");
    const [addcomment, setaddcomment] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    function DateFormat(params) {
        if (params == null) {
          return "-";
        } else {
          const date = new Date(params);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

    let filteredOpinions = [];
    filteredOpinions = props.data.filter((value) => {
    if(searchTerm !== null){
      let Entity = [];
      if(value['name'] !== null){
        Entity = value['name'].toLowerCase().includes(searchTerm.toLowerCase());
      }
  
      return Entity;
    }
    else if(searchTerm == null){
      return props.data;
    }
    }
    );
  
    const handleSearch = (event) => {
      setSearchTerm(event.target.value)
    };


  return (
        <div style={props.data.length == 0 ? {height: '90vh', backgroundImage:`url(${bg})`} : { backgroundImage:`url(${bg})`}}>
        <div style={props.screenWidth > 771 ? {position: "fixed", zIndex: 999, width: "100%", backgroundColor: "white"}: null}>

            <div className="col-lg-6 col-md-6 col-sm-8 col-11 mx-auto p-2 d-flex mt-lg-0 mt-md-0 mt-sm-5 mt-5">
            <input
                id="search"
                className="form-control"
                type="text"
                placeholder="Search here.."
                autoComplete="off"
                onChange={handleSearch}
              />
            </div>
        </div>
        <div style={{visibility: 'hidden'}}>

            <div className="col-lg-6 col-md-6 col-sm-8 col-11 mx-auto p-2 d-flex mt-lg-0 mt-md-0 mt-sm-5 mt-5">
            <input
                id="search"
                className="form-control"
                type="text"
                placeholder="Search here.."
                autoComplete="off"
                onChange={handleSearch}
              />
            </div>
        </div>
        <div className="row ms-lg-5 ms-md-5 ms-sm-4 ms-2">
            <>
                <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                      <Alert
                        severity={alertType}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setalertopen(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                        sx={{ mb: 2 }}
                      >
                        {AlertMessage}
                      </Alert>
                    </Collapse>
                </Box> 
            </>
            {
                props.data.length > 0 ?
                filteredOpinions.map((value, index)=>{
                    return(
                            <>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-1 mt-1">
                                    <div class="card shadow col-11 p-3" style={{height: '100%', borderWidth:8, borderColor: 'silver', backgroundImage: `url(${white})`, backgroundColor: "white", backgroundSize: "cover"}}>
                                        <div className='fs-4 fw-bold' style={{fontFamily: "Kalinga", color: "#000078", height: '100px'}}>
                                            {value.name}
                                        </div>
                                        <div className='fs-6 mb-2' style={{fontFamily: "Kalinga"}}>
                                            {DateFormat(value.date)}
                                        </div>
                                        <div className='fs-6 mb-2' style={{fontFamily: "Kalinga"}}>
                                            <b>Rating : </b> {value.long_term} / {value.short_term == null ? '-' : value.short_term}  ({value.action})
                                        </div>
                                        <div>
                                            <button class="btn btn-danger me-1 mb-1" onClick={()=>{window.open(`${url}/api/pdfcover/${value.opinion_id}/${value.record_id}/${value.rating_scale_id}/${null}`, '_blank')}}>Cover</button>
                                        </div>
                                    </div>
                                </div>
                            
                            </>
                    )
                }) :
                (
                    <div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
                    <div class="wrapper" id="loader2">
                      <svg
                        class="hourglass"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 120 206"
                        preserveAspectRatio="none"
                      >
                        <path
                          class="middle"
                          d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
                        />
                        <path
                          class="outer"
                          d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
                        />
                      </svg>
                    </div>
                  </div>
                )
            }
          
            
        </div>
        <br /><br />
        <div className="card mt-1 pt-2 d-flex" style={{bottom: 0, position: "fixed", zIndex: 999, width: "100%"}}>
            <div className="text-center">
              <b>Total Opinions:</b> {props.data.length}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Filtered Opinions:</b> {filteredOpinions.length}
            </div>
        </div>
        <div className="card mt-1 pt-2 d-flex" style={{bottom: 0, position: "fixed", zIndex: 999, width: "100%"}}>
            <div className="text-center">
              <b>Total Opinions:</b> {props.data.length}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Filtered Opinions:</b> {filteredOpinions.length}
            </div>
        </div>
      
    </div>
  )
}
