import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { url } from "../../../Config";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';


export default function ClientInfo(props) {
  const { clientId } = useParams();
  const [client_detail, setclient_detail] = useState({});
  const [Ceo, setCeo] = useState([]);
  const [LiasonOne, setLiasonOne] = useState([]);
  const [LiasonTwo, setLiasonTwo] = useState([]);

  useEffect(()=>{
    const func = async ()=>{
      const res2 = await axios.get(`${url}/api/get_client_detail_api/${clientId}`);
      const resp2 = res2.data.data;
        if(resp2.length > 0){
            setclient_detail(resp2[0]);
            let ceo = resp2[0].ceo;
            if(ceo !== null && ceo !== undefined){
              let ceo_detail = ceo.split(',');
              setCeo(ceo_detail);
            }

            let L1 = resp2[0].liaison_one;
            if(L1 !== null && L1 !== undefined){
              let L1_detail = L1.split(',');
              setLiasonOne(L1_detail);
            }
            let L2 = resp2[0].liaison_two;
            if(L2 !== null && L2 !== undefined){
              let L2_detail = L2.split(',');
              setLiasonTwo(L2_detail);
            }
        }

        
    }

    func();
    
  },[clientId])



  return (
    <div className="row container">
      <div className="d-flex" style={{overflow: 'scroll' }}>
        <div className="row">
          <div className="col-11">
            {props.client !== null && <div style={{visibility: 'hidden'}}> <Tooltip title="Edit Client Info"><button className="btn btn-warning mt-2" onClick={()=>{window.open(`/edit-client-details/${props.client}`, '_blank')}}><EditIcon /></button></Tooltip> </div>}
            <table className="table table-striped table-hover table-bordered">
              <tbody>
                <tr>
                  <td className="fw-bold">Analyst</td>
                  <td>{props.client_detail.analyst_name}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Team Lead</td>
                  <td>{props.client_detail.manager_name}</td>
                </tr>
                <tr>
                  <td className="fw-bold">CRO</td>
                  <td>{props.client_detail.lead_name}</td>
                </tr>
                {/* <tr>
                  <td><b>Address</b> | {client_detail.address}, {client_detail.city}</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        
        </div>
        <div className="row">
          <div className="col-12">
          {props.client !== null && <div className="d-flex justify-content-end"> <Tooltip title="Edit Client Info"><button className="btn btn-warning mt-2" onClick={()=>{window.open(`/edit-client-details/${props.client}`, '_blank')}}><EditIcon /></button></Tooltip> </div>}

            <table className="table table-striped table-hover table-bordered">
              <tbody>
                <tr>
                  <td>{props.Ceo !== null ? props.Ceo.length > 0 ? <><b>{props.Ceo[3]}</b> | {props.Ceo[0]} {props.Ceo[1]} {props.Ceo[2]} | {props.Ceo[4]} | {props.Ceo[5]} | {props.Ceo[6]}</> : null : null}</td>
                </tr>
                <tr>
                  <td>{props.LiasonOne !== null ? props.LiasonOne.length > 0 ? <><b>{props.LiasonOne[3]}</b> | {props.LiasonOne[0]} {props.LiasonOne[1]} {props.LiasonOne[2]} | {props.LiasonOne[4]} | {props.LiasonOne[5]} | {props.LiasonOne[6]}</> : null : null}</td>
                </tr>
                <tr>
                  <td>{props.LiasonTwo !== null ? props.LiasonTwo.length > 0 ? <><b>{props.LiasonTwo[3]}</b> | {props.LiasonTwo[0]} {props.LiasonTwo[1]} {props.LiasonTwo[2]} | {props.LiasonTwo[4]} | {props.LiasonTwo[5]} | {props.LiasonTwo[6]}</> : null : null}</td>
                </tr>
                <tr>
                  <td><b>Address</b> | {props.client_detail.address}, {props.client_detail.city}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* <div className="container">
          <table className="text-white fw-bold ms-3 w-100">
            <tr className="d-inline-flex w-100 text-center">
              <td className="theme_text col-4 text-start">Analyst :</td>
              <td className="theme_text px-2 text-start">
                {props.opinionsData[0] && props.opinionsData[0].analyst_name}
              </td>
            </tr>
            <tr className="d-inline-flex w-100 text-center">
              <td className="theme_text col-4 text-start">Team :</td>
              <td className="theme_text px-2 text-start">
                {props.opinionsData[0] && props.opinionsData[0].lead_name}
              </td>
            </tr>
            <tr className="d-inline-flex w-100 text-center">
              <td className="theme_text col-4 text-start">Chief Ratings :</td>
              <td className="theme_text px-2 text-start">
                {props.opinionsData[0] && props.opinionsData[0].manager_name}
              </td>
            </tr>
            {/* <tr className="d-inline-flex w-100 text-center">
              <td className="theme_text col-4 text-start">
                Director Finance :
              </td>
              <td className="theme_text px-2 text-start">Mr. Kamran Saleem</td>
            </tr> */}
          {/* </table> */}
        {/* </div> */}
      </div>
      {/* <div className="col-md-8 px-md-4 py-md-2">
        <table className="text-white fw-bold ms-3 w-100">
          <tr className="d-inline-flex w-100 text-center">
            <td className="theme_text col-4 text-start">
              {Ceo == null ? "President & CEO" : Ceo[3]}
            </td>
            {Ceo == null ? (
              <td className="theme_text px-2 text-start">-----</td>
            ) : (
              <td className="theme_text pe-2 text-start w-100 text-break">
                {Ceo[0]} {Ceo[1]} {Ceo[2]} |{" "}
                {Ceo[4] && Ceo[4].includes("00-0000") ? "" : Ceo[4]} |{" "}
                {Ceo[5] && Ceo[5].includes("00-0000") ? "" : Ceo[5]} | {Ceo[6]}
              </td>
            )}
          </tr>
          <tr className="d-inline-flex w-100 text-center">
            <td className="theme_text col-4 text-start">
              {LiasonOne == null ? "Unit Head-Policies & Procedures" : LiasonOne[3]}
            </td>
            {LiasonOne == null ? (
              <td className="theme_text px-2 text-start">-----</td>
            ) : (
              <td className="theme_text pe-2 text-start w-100 text-break">
                {LiasonOne[0]} {LiasonOne[1]} {LiasonOne[2]} |{" "}
                {LiasonOne[4] && LiasonOne[4].includes("00-0000")
                  ? ""
                  : LiasonOne[4]}{" "}
                |{" "}
                {LiasonOne[5] && LiasonOne[5].includes("00-0000")
                  ? ""
                  : LiasonOne[5]}{" "}
                | {LiasonOne[6]}
              </td>
            )}
          </tr>
          <tr className="d-inline-flex w-100 text-center">
            <td className="theme_text col-4 text-start">
              {LiasonTwo == null ? "Chief Financial Officer" : LiasonTwo[3]}
            </td>
            {LiasonTwo == null ? (
              <td className="theme_text px-2 text-start">-----</td>
            ) : (
              <td className="theme_text pe-2 text-start w-100 text-break">
                {LiasonTwo[0]} {LiasonTwo[1]} {LiasonTwo[2]} |{" "}
                {LiasonTwo[4] && LiasonTwo[4].includes("00-0000")
                  ? ""
                  : LiasonTwo[4]}{" "}
                |{" "}
                {LiasonTwo[5] && LiasonTwo[5].includes("00-0000")
                  ? ""
                  : LiasonTwo[5]}{" "}
                | {LiasonTwo[6]}
              </td>
            )}
          </tr>
          <tr className="d-inline-flex w-100 text-center">
            <td className="theme_text col-4 text-start">Address</td>
            <td className="theme_text px-2 text-start">
              {props.opinionsData[0] && props.opinionsData[0].address},{props.opinionsData[0] && props.opinionsData[0].city}
            </td>
          </tr>
        </table>
      </div> */}
    </div>
  );
}
