import React, {useState, useEffect, useRef} from 'react';
import { url } from '../../../../../Config';
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CancelIcon from "@mui/icons-material/Cancel";


export default function AddCriteria(props) {

    const id = localStorage.getItem("userID");
    const Navigate = useNavigate();
    const nameRef = useRef(null);
    const DescriptionRef = useRef(null);
    const AppointmentRef = useRef(null);
    const Ex_in_Ref = useRef(null);
    const Total_Ex_Ref = useRef(null);
    const btnRef = useRef(null);

    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();


    const submitForm = async (event)=>{
        event.preventDefault();

        btnRef.current.disabled = true;

        const fileData = new FormData();
            fileData.append("criteria_member", nameRef.current.value);
            fileData.append("description", DescriptionRef.current.value);
            fileData.append("appointment_date", AppointmentRef.current.value);
            fileData.append("pacra_experience", Ex_in_Ref.current.value);
            fileData.append("total_experience", Total_Ex_Ref.current.value);
            fileData.append("id", Number(id));

            const res = await axios.post(`${url}/api/storeCriteriaMember`,
                fileData,
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
            );
            
            const resp = res.data;
            if(resp.status == true){
                Navigate(`/edit-criteria/${resp.id}`);
            }
            else{
                btnRef.current.disabled = false;
                setalertType("error");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
    }

  return (
    <div style={{height: '90vh'}}>
      <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/criteria-view"} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Add New Member</li>
                </ol>
            </nav>
            <hr/>
        </div>
        <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            Add New Criteria Group Member
        </div>
        <form className ='col-10 card shadow p-3 mx-auto mt-2' onSubmit={(event)=>{submitForm(event)}}>
            <div>
                <div className='fw-bold'>
                    Director
                </div>
                <div className='mt-2'>
                    <input className='form-control' ref={nameRef} placeholder='Enter Name' required />
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Description
                </div>
                <div className='mt-2'>
                    <input className='form-control' ref={DescriptionRef} placeholder='Enter Description' required />
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Date of appointment
                </div>
                <div className='mt-2'>
                    <input className='form-control' ref={AppointmentRef} type='date' required />
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Experience in PACRA
                </div>
                <div className='mt-2'>
                    <input className='form-control' type='text' ref={Ex_in_Ref} required />
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Total Experience
                </div>
                <div className='mt-2'>
                    <input className='form-control' type='text' ref={Total_Ex_Ref} placeholder='Enter Total Experience' required />
                </div>
            </div>
            <Box className="mx-auto" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
        </Box>
        <div className='mt-3'>
            <button className='btn btn-primary' ref={btnRef}  type='submit'>Save</button>
        </div>
        </form>

    </div>
  )
}
