import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../Config';
import { Check, Clear, Event } from "@material-ui/icons";
import Select from "react-select";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";

export default function Edit_sector_industry(props) {

    const [group, setgroup] = useState([]);
    const [industries, setindustries] = useState([]);
    const [analyst, setanalyst] = useState([]);
    const [group_val, setgroup_val] = useState('');
    const [analyst_val, setanalyst_val] = useState('');
    const [industriesval, setindustriesval] = useState('');
    const [sector_val, setsector_val] = useState('');
    const [reason_val, setreason_val] = useState('');
    const [sub_sec_id, setsub_sec_id] = useState(null);
    const [sectorstudy, setsectorstudy] = useState({});
    const sectorRef = useRef(null);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const { id } = useParams();
    const reasonRef = useRef(null);
    const Navigate = useNavigate();


    const sourceRef = useRef(null);
    const docRef = useRef(null);
    const fileref = useRef(null);
    const imageRef = useRef(null);
    const textRef = useRef(null);
    const teamRef = useRef(null);
    const dateRef = useRef(null);
    const supRef = useRef(null);
    const ssnameRef = useRef(null);
    const btnRef = useRef(null);
        
    useEffect(()=>{

        const func = async ()=>{
            const res = await axios.get(`${url}/api/edit_sector/${id}`);
            const resp = res.data;
            setgroup(resp.segments);
            setanalyst(resp.analyst);
            setgroup_val(resp.sector_study[0].segment_id);
            setsub_sec_id(resp.sector_study[0].id);
            setsectorstudy(resp.sector_study[0]);
            if(resp.sector_study[0].analyst !== null){
                setanalyst_val(resp.sector_study[0].analyst);
            }
            for(let i in resp.segments){
                if(resp.sector_study[0].segment_id == resp.segments[i].id){
                    setindustries(resp.segments[i].industries);
                }
            }
            if(resp.sector_study[0].main_sector_id !== null){
                setindustriesval(resp.sector_study[0].main_sector_id);
            }
            setsector_val(resp.sector_study[0].sector_title);
            setreason_val(resp.sector_study[0].reason);
        }

        func();
    },[])


    


    const submitForm = async (event)=>{
        event.preventDefault();

        let analyst = null;
        if(analyst_val !== ''){
            analyst = analyst_val;
        }
        btnRef.current.disabled = true;
        btnRef.current.innerHTML = "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";

        const res = await axios.post(`${url}/api/update_sector`,{
            'sub_sector': sector_val,
            'analyst' : analyst,
            'reason' : reason_val,
            'main_sector' : industriesval,
            'sub_sector_id': id
        });
        const resp = res.data;
        if(resp.status == true){
            btnRef.current.disabled = false;
            btnRef.current.innerHTML = "Update";

            // Navigate('/classification-dashboard');
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btnRef.current.disabled = false;
            btnRef.current.innerHTML = "Update";
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }

    }


  return (
    <div style={{height: '90vh'}}>
    <div className='fs-3 fw-bold text-center mt-2 mb-3' style={{color: "#000078"}}>
        Edit Sector Study
    </div>
    <form className='card shadow col-11 mx-auto p-2' onSubmit={(event)=>{submitForm(event)}}>
            <div>
                <div className='mt-2 fw-bold'>
                    Group
                </div>
                <div className='mt-2'>
                    <select className='form-select'  value={group_val} 
                    // onChange={(e)=>{
                    //     setgroup_val(e.target.value)
                    //     if(e.target.value == ''){
                    //         setindustries([]);
                    //     }
                    //     else{
                    //         for(let i in group){
                    //             if(group[i].id == e.target.value){
                    //                 setindustries(group[i].industries);
                    //             }
                    //         }
                    //     }
                        
                    //     }} 
                        required>
                       <option value={sectorstudy.segment_id}>{sectorstudy.segmenttitle}</option>
                       {/* {
                        group.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.title}</option>
                            )
                        })
                       } */}
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Industry
                </div>
                <div className='mt-2'>
                    <select className='form-select'  value={industriesval} onChange={(e)=>{setindustriesval(e.target.value)}} required>
                       <option value={sectorstudy.main_sector_id}>{sectorstudy.mainsectortitle}</option>
                       {/* {
                        industries.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.title}</option>
                            )
                        })
                       } */}
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Sector
                </div>
                <div>
                    <input className='form-control' ref={sectorRef} value={sector_val} onChange={(e)=>{setsector_val(e.target.value)}} placeholder='Enter Sector Name' required />
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Research Analyst
                </div>
                <div className='mt-2'>
                    <select className='form-select'  value={analyst_val} onChange={(e)=>{setanalyst_val(e.target.value)}}>
                       <option value=''>Select Analyst</option>
                       {
                        analyst.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.username}</option>
                            )
                        })
                       }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Reason
                </div>
                <div>
                    <textarea className='form-control' ref={reasonRef} value={reason_val} onChange={(e)=>{setreason_val(e.target.value)}} placeholder='Enter the reason' />
                </div>
            </div>
            <div>
                <Box className="mx-auto mt-2 mb-2" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
                </Box>
                <button className='btn btn-primary mt-3 mt-2' type='submit' ref={btnRef}>Update</button>
            </div>
    </form>
</div>
  )
}
