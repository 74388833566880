import React, {useState, useEffect, useRef} from 'react';
import { url } from '../../../../../Config';
import axios from 'axios';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";


export default function EditRC_Members(props) {

    const id = localStorage.getItem("userID");
    const userid = localStorage.getItem("userID");
    const [data, setdata] = useState({});
    const { rc_id } = useParams();
    const Navigate = useNavigate();
    const nameRef = useRef(null);
    const dateRef = useRef(null);
    const emailRef = useRef(null);
    const btnRef = useRef(null);

    const [catval, setcatval] = useState('');
    const [desigval, setdesigval] = useState('');
    const [category, setcategory] = useState([]); 
    const [designations, setdesignations] = useState([]);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [bool, setbool] = useState(false);

    
    const rightsval = localStorage.getItem("rights");
  
    let rights = [];
  
    if(rightsval !== null){
      rights = rightsval.split(",");
    }
  
    const has_right = rights.includes("5");
    const can_edit_after_approval = rights.includes("51");


    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/UpdateRcMember/${rc_id}`);
            const resp = res.data;
            setcategory(resp.rc_members_categories);
            setdesignations(resp.designations);
            setcatval(resp.rcmember.status);
            setdesigval(resp.rcmember.designation);
            setdata(resp.rcmember);
        }

        func();
    },[bool])

    const editForm = async (event)=>{
        event.preventDefault();

        btnRef.current.disabled = true;

            const res = await axios.post(`${url}/api/onUpdateRcMember`,{
                'rcmemberName': nameRef.current.value,
                'appointment_date': dateRef.current.value,
                'category': catval,
                'rc_member_email': emailRef.current.value,
                'designation': desigval,
                'id': Number(id),
                'recordId': Number(rc_id)

            });
            
            const resp = res.data;
            btnRef.current.disabled = false;
            if(resp.status == true){
                btnRef.current.disabled = false;
                setbool(!bool);
                setalertType("success");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
            else{
                btnRef.current.disabled = false;
                setalertType("error");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
    }

    const approve = async ()=>{
        setalertOpen(false);
        const res = await axios.post(`${url}/api/ApproveRcMember`, {
            'id' : Number(rc_id),
            'userid' : Number(userid)
        });
        const resp = res.data;
        if(resp.status == true){
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success");
            setbool(!bool);
        }
        else{
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error")
        }
    }
  return (
    <div>
      <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/rcreport-view"} >Dashboard</Link></li>
                    <li className="breadcrumb-item"><Link to={`/view-rc-member/${rc_id}`} >RC Member Detail</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Update RC Member</li>
                </ol>
            </nav>
            <hr/>
        </div>
        <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            Add RC Member
        </div>
        <form className ='col-10 card shadow p-3 mx-auto mt-2' onSubmit={(event)=>{editForm(event)}}>
        <div>
            <div className='fw-bold'>
                RC Member
            </div>
            <div className='mt-2'>
                <input className='form-control' ref={nameRef} defaultValue={data.name} placeholder='Enter Name' required />
            </div>
        </div>
        <div>
            <div className='fw-bold mt-2'>
                Applicable date for nomination
            </div>
            <div className='mt-2'>
                <input className='form-control' type='date' ref={dateRef} defaultValue={data.appointment_date} required />
            </div>
        </div>
        <div>
            <div className='fw-bold mt-2'>
                Category
            </div>
            <div className='mt-2'>
                <select className='form-select' value={catval} onChange={(e)=>{setcatval(e.target.value)}}>
                    <option value={''}>Select Category</option>
                    {
                        category.map((value, index)=>{
                            return(
                                <option value={value.title}>{value.title}</option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
        <div>
            <div className='fw-bold mt-2'>
                RC Member's Email
            </div>
            <div className='mt-2'>
                <input className='form-control' type="email" ref={emailRef} defaultValue={data.email} placeholder='Enter Email' required />
            </div>
        </div>
        <div>
            <div className='fw-bold mt-2'>
                Designation
            </div>
            <div className='mt-2'>
                <select className='form-select' value={desigval} onChange={(e)=>{setdesigval(e.target.value)}}>
                    <option value={''}>Select Designation</option>
                    {
                        designations.map((value, index)=>{
                            return(
                                <option value={value.title}>{value.title}</option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
        <Box className="mx-auto mt-1" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
        </Box>
        <div className='mt-3'>
            {(has_right == true || can_edit_after_approval || data.approval_status == 'submit' || data.approval_status == null) && <button className='btn btn-primary me-1' ref={btnRef}  type='submit'>Update</button>}
            {data.approval_status !== 'approve' && has_right == true && <button className='btn btn-success' onClick={()=>{approve()}} type='button'>Approve</button>}
        </div>
        </form>
    </div>
  )
}
