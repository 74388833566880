import React,{useEffect, useState, useRef} from 'react';
import { url } from '../../../../../Config';
import axios from 'axios';
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { encryptParams } from '../../../encrypt_url';


export default function Assign_support_analyst(props) {

    const { support_portfolio_id } = useParams();
    const [data, setdata] = useState({});
    const [analyst, setanalyst] = useState([]);
    const [team_lead, set_team_lead] = useState("");
    const [team_lead_id, set_team_lead_id] = useState(null);
    const [support_analyst_id, set_support_analyst_id] = useState('');
    const [reason, setreason] = useState('');
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [analyst_arr, setanalyst_arr] = useState([]);

    const btnRef = useRef(null);


    useEffect(()=>{

        const func = async ()=>{
            const res = await axios.get(`${url}/api/get_assign_support_portfolio_record/${support_portfolio_id}`);
            const resp = res.data;
            // console.log(resp, 'response')
            setdata(resp.portfolio[0]);
            setanalyst(resp.analysts);
            let str = localStorage.getItem('selected_analyst');
            if(str !== null){
                let analystarr = str.split(',');
                setanalyst_arr(analystarr);
            }

        }

        func();

    },[])


    const submitform = async (event)=>{

        event.preventDefault();

        if(btnRef.current){
            btnRef.current.disabled = true;
            btnRef.current.innerHTML = 'Please Wait...';
        }

        const fileData = new FormData();
        fileData.append('id', data.id);
        fileData.append("support_analyst", support_analyst_id);
        fileData.append("support_tl", team_lead_id);
        fileData.append("reason", reason);

        const res = await axios.post(`${url}/api/assign_analyst`,
        fileData,
        {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        });

        const resp = res.data;
        if(resp.status == true){
            if(btnRef.current){
                btnRef.current.innerHTML = 'Assigned';
            }
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            if(btnRef.current){
                btnRef.current.disabled = false;
                btnRef.current.innerHTML = 'Assign';
            }
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }

    }


  return (
    <div style={{height: '90vh'}}>
        <form className='card col-10 mx-auto shadow mt-3 p-3' onSubmit={(event)=>{submitform(event)}}>
            <div className="mx-auto p-3 fs-3 fw-bold mb-0 text-capitalize" style={{ width: "fit-content", color:"#000078" }}>
                Assign Support Analyst
            </div>
                <Box className="mx-auto" sx={{ width: "98%" }}>
                        <Collapse in={alertopen}>
                            <Alert
                            severity={alertType}
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setalertOpen(false);
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            >
                            {AlertMessage}
                            </Alert>
                        </Collapse>
                </Box>
            <div className='row mt-3'>
                <div className='col-2 fw-bold fs-6'>
                    Name:
                </div>
                <div className='col-9'>
                    <input className='form-control' defaultValue={data ? data.opinion_name : ""} disabled />
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-2 fw-bold fs-6'>
                    Team Lead:
                </div>
                <div className='col-9'>
                    <input className='form-control' defaultValue={data ? data.team_lead_name : ""} disabled />
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-2 fw-bold fs-6'>
                    Current Analyst:
                </div>
                <div className='col-9'>
                    <input className='form-control' defaultValue={data ? data.analyst_name : ""} disabled />
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-2 fw-bold fs-6'>
                    Select Support Analyst:
                </div>
                <div className='col-9'>
                    <select className='form-select' value={support_analyst_id} onChange={(e)=>{
                        set_support_analyst_id(e.target.value);
                        for(let i in analyst){
                            if(analyst[i].id == e.target.value){
                                set_team_lead(analyst[i].team_lead);
                                set_team_lead_id(analyst[i].am_id);
                            }
                        }
                    }}>
                        <option value={''}>Select Analyst</option>
                        {
                            analyst.map((value, index)=>{
                                if(analyst_arr.includes(`${value.id}`)){
                                    return(
                                        <option value={value.id}>{value.display_name}</option>
                                    )
                                }
                            })
                        }
                    </select>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-2 fw-bold fs-6'>
                    Support Analyst TL:
                </div>
                <div className='col-9'>
                    <input className='form-control' value={team_lead} disabled />
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-2 fw-bold fs-6'>
                    Reason:
                </div>
                <div className='col-9'>
                    <textarea className='form-control' value={reason} onChange={(e)=>{
                        setreason(e.target.value)
                        }} required />
                </div>
            </div>

            <div className='mt-4 mx-auto mb-3' style={{width:'fit-content'}}>
                {support_analyst_id !== '' && <button className='btn btn-info me-1' onClick={()=>{window.open(`/analyst-detail/${support_analyst_id}`, '_blank')}}>Analyst Detail</button>}
                {data ? data.rm_assigned_analyst == null ?
                    <button className='btn btn-primary' ref={btnRef}  disabled={support_analyst_id !== '' ? false : true}>Assign</button> :
                    <div className='fw-bold' style={{color: 'red'}}>Already Assigned to Support Analyst</div>
                : null}
            </div>
        </form>
    </div>
  )
}
