import  React, { useEffect, useState }  from 'react'
import img from "../../../../Assets/Images/PACRA_logo.png"
import axios from 'axios';
import "../views/print.css";
import { green } from '@mui/material/colors';
import bg from "../../../../Assets/Images/greyModel.jpg"
import { url } from '../../../../Config';
import { useParams } from "react-router-dom";


export default function Pplview() {
    const { recordid } = useParams();
    const [data, setdata] = useState(null);
    const [to_name_chunks, setto_name_chunks] = useState(null);
    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/pdfviewPPL/${recordid}`);
            let resp = res.data;
            setdata(resp);
            setto_name_chunks(resp.to_name_chunks[0])

        }
        func();
    },[])
    function DateFormat(params) {
        if (params == null) {
          return "-";
        } else {
          const date = new Date(params);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
    
    function useWindowSize() {
    const [size, setsize] = useState([window.innerWidth]);
    useEffect(() => {
          const handleResize = () => {
            setsize([window.innerWidth]);
          };
          window.addEventListener("resize", handleResize);
        }, []);
        return size;
      }
    const [screenWidth] = useWindowSize();
    
  return (
    <div className='divprint'>
    <div className='d-none d-print-block mt-0' style={{backgroundColor: "white"}}>
      {data !== null && 
      <div className=''>
        <div className='mx-auto' style={{width:"fit-content"}}>
            <img src={img} style={{height: "50px", width: "50px"}}></img>
        </div>
        <div className='text-center fw-bold fs-5' style={{fontFamily: "Kalinga"}}>
        The Pakistan Credit Rating Agency Limited
        </div>
        <hr/>
        <div className='row'>
            <div className='col-6 text-start' style={{fontFamily: "Kalinga"}}>
                PPL FY-{data.ppl[0].financial_year}-{data.ppl[0].ppl_number}
            </div>
            <div className='col-6 text-end'>
                <div className='text-decoration-underline fw-bold' style={{fontFamily: "Kalinga"}}>
                    {data.ppl[0].confidential}
                </div>
                <div style={{fontFamily: "Kalinga"}}>
                    {DateFormat(data.ppl[0].date)}
                </div>
            </div>
        </div>
        <div className='fw-bold' style={{fontFamily: "Kalinga"}}>{data.ppl[0].to_name}</div>
        <div style={{fontFamily: "Kalinga"}}>{data.ppl[0].to_designation}</div>
        <div style={{fontFamily: "Kalinga"}}>{data.ppl[0].company}</div>
        <div style={{fontFamily: "Kalinga"}}>{data.ppl[0].address}</div> <br/>
        <div className='text-center fw-bold fs-6' style={{ color: "#000078", fontFamily: "Kalinga" }}>{data.ppl[0].company}</div>
        <div className='text-center fw-bold fs-6' style={{ color: "#000078", fontFamily: "Kalinga" }}>
        {
            data.ppl[0].rating_scale_opinion=="Security Agency Grading" ? "Security Grading" : 
            data.ppl[0].rating_scale_opinion=="Debt Instrument" ? "Rating" : 
            data.ppl[0].rating_scale_opinion=="Stability Rating" || data.ppl[0].rating_scale_opinion=="Capital Protection"  ? "Mutual Fund Ratings" : 
            data.ppl[0].rating_scale_opinion=="Performance Ranking" ? "Ranking" : 
            data.ppl[0].rating_scale_opinion=="Project Grading" ? "Project Grading" : "Ratings | " + data.ppl[0].opinion_type

        }

        </div>
        <div style={{fontFamily: "Kalinga"}}>Dear {to_name_chunks == "Mr" ? "Sir" : to_name_chunks == "Mrs" || to_name_chunks == "Ms" ? "Madam" : to_name_chunks !== "Mrs" ? "Sir" : null}</div>
        <div style={{fontFamily: "Kalinga"}}>{data.ppl[0].withdraw_text}</div> <br />
        <div className='mt-1 mx-auto' style={{width: "98%"}}>
            { data.ppl[0].rating_scale_opinion == "Entity" || data.ppl[0].rating_scale_opinion =="Debt Instrument"?
            <table className='table newtable'>
                    <tr className='pdf'>
                        <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}> <strong>Opinion</strong></td>
                        <td className='text-center' colSpan="2" style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Rating</strong></td>
                        <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Outlook</strong></td>
                        <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}> <strong>Action</strong></td>
                        <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion Type</strong></td>
                    </tr>
                    <tr className='pdf'>
                        <td> </td>
                        <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><b>Long Term</b></td>
                        <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><b>Short Term</b></td>
                        <td> </td>
                        <td></td>
                        <td> </td>
                    </tr>
                    <tr>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].opinion}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].long_term}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].short_term }</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].outlook}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].action}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].rating_type}</td>

                    </tr>
            </table> :
            data.ppl[0].rating_scale_opinion =="Structured Obligations" ?
            <table className='table newtable'>
             <tr className='pdf'>
                 <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}> <strong>Opinion</strong></td>
                 <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Rating</strong></td>
                 <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Outlook</strong></td>
                 <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}> <strong>Action</strong></td>
                 <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion Type</strong></td>
             </tr>
             <tr>
                 <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].opinion}</td>
                 <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].long_term}</td>
                 <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].outlook}</td>
                 <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].action}</td>
                 <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].rating_type}</td>

             </tr>
            </table> :
            data.ppl[0].rating_scale_opinion == "IFS" ?
            <table className='table newtable'>
             <tr className='pdf'>
                 <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}> <strong>Opinion</strong></td>
                 <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>IFS Rating</strong></td>
                 <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Outlook</strong></td>
                 <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}> <strong>Action</strong></td>
                 <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion Type</strong></td>
             </tr>
             <tr>
                 <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].opinion}</td>
                 <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].long_term}</td>
                 <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].outlook}</td>
                 <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].action}</td>
                 <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].rating_type}</td>

             </tr>
            </table> :
             data.ppl[0].rating_scale_opinion == "Broker Management" || data.ppl[0].rating_scale_opinion == "Broker Management Rating" || 
             data.ppl[0].rating_scale_opinion == "Trustee Fiduciary Rating" ?
             <table className='table newtable'>
                <tr className='pdf'>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion</strong></td>
                    <td colSpan="2" style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Rating</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Outlook</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Action</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr className='pdf'>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    {
                     data.ppl[0].rating_scale_opinion !="Trustee Fiduciary Rating" ?
                     <td colspan="2" style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><b>BFR</b></td> :
                     <td colspan="2"><b></b></td>
                    }
                    <td></td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].opinion}</td>
                    <td className='text-center' colspan="2" style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].long_term}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].outlook}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].action}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].rating_type}</td>
                </tr>
             </table> :
             data.ppl[0].rating_scale_opinion == "BFR" || data.ppl[0].rating_scale_opinion == "Social Impact and Performance Rating"?
             <table className='table newtable' rules="cols" width="100%">
                <tr className='pdf'>
                    <td style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion</strong></td>
                    <td style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Rating</strong></td>
                    <td style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Outlook</strong></td>
                    <td style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Action</strong></td>
                    <td style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion Type</strong></td>
                </tr>

                <tr>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].opinion}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].long_term}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].outlook}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].action}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].rating_type}</td>
                </tr>
            </table> :
            data.ppl[0].rating_scale_opinion == "Project Grading" ?
            <table className='table newtable' rules="cols" width="100%">
                <tr className='pdf'>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Grading</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Outlook</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Action</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].opinion}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].long_term}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].outlook}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].action}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].rating_type}</td>
                </tr>
            </table> :
            data.ppl[0].rating_scale_opinion == "Performance Ranking" ?
            <table className='table-striped newtable' rules="cols" width="100%">
                <tr className='pdf'>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}> <strong>Opinion</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}> <strong>Action</strong></td>
                    <td colSpan="3" style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Ranking</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Category</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr className='pdf'>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><b>1 Year</b></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><b>3 Year</b></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><b>5 Year</b></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].opinion == null ? '-' : data.ppl[0].opinion}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].action == null ? '-' : data.ppl[0].action}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].short_term == null ? '-' : data.ppl[0].short_term}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].long_term == null ? '-' : data.ppl[0].long_term}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].rating_five_yr == null ? '-' : data.ppl[0].rating_five_yr}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].outlook == null ? '-' : data.ppl[0].outlook}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].rating_type}</td>
                </tr>
            </table> :
            data.ppl[0].rating_scale_opinion=="Stability Rating" ?
            <table className='table newtable' rules="cols" width="100%">
                <tr className='pdf'>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}> <strong>Opinion</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Rating</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Category</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}> <strong>Action</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].opinion}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].long_term}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].outlook}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].action}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].rating_type}</td>
                </tr>
            </table> :
            data.ppl[0].rating_scale_opinion=="Asset Manager" ?
            <table className='table newtable' rules="cols" width="100%">
                <tr className='pdf'>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}> <strong>Opinion</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Rating</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Outlook</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}> <strong>Action</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>
                    {data.ppl[0].opinion}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].long_term}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].outlook}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].action}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].rating_type}</td>
                </tr>
            </table> :
            data.ppl[0].rating_scale_opinion=="REIT Manager Rating" ||  data.ppl[0].rating_scale_opinion=="REIT Fund Rating" ?
            <table className='table-striped newtable' rules="cols" width="100%">
                <tr className='pdf'>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}> <strong>Opinion</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Rating</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Outlook</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}> <strong>Action</strong></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].opinion}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].long_term}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].outlook}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].action}</td>
                    <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].rating_type}</td>
                </tr>
            </table> : 
            <table className='table newtable' rules="cols" width="100%">
            <tr className='pdf'>
                <td style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion</strong></td>
                <td style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Rating</strong></td>
                <td style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Outlook</strong></td>
                <td style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Action</strong></td>
                <td style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion Type</strong></td>
            </tr>

            <tr>
                <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].opinion}</td>
                <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].long_term}</td>
                <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].outlook}</td>
                <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].action}</td>
                <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{data.ppl[0].rating_type}</td>
            </tr>
            </table>
            }
        </div>

        <div className='mt-3' style={{fontFamily: "Kalinga"}}>
            {data.ppl[0].text}
        </div> <br />
        <div className='mb-5' style={{fontFamily: "Kalinga"}}>Yours truly</div>

        <div id='sign'>
        {data.ppl[0].second_approve == "Approved" &&
            <img src={'data:image/jpg;base64,'+data.getApproval[0].sign} style={{width: "120px", height: "60px"}} />
        }
        </div> <br/>
        <div>
            {
                data.ppl[0].s_designation=='uh' || data.ppl[0].s_designation=='cro' ?
                <>
                {
                    data.ppl[0].date < '2020-09-24' ?
                    <div className='fw-bold' style={{fontFamily: "Kalinga"}}>{`(${data.getApproval[0].name})`} <br></br></div> :
                    <div className='fw-bold' style={{fontFamily: "Kalinga"}}>{`(${data.ppl[0].uh1})`} <br></br></div>
                }
                {
                    data.ppl[0].s_designation=='cro' ?
                    <div style={{fontFamily: "Kalinga"}}>Chief Rating Officer </div> :
                    <div style={{fontFamily: "Kalinga"}}>Unit Head | Ratings</div>
                }
                </> : null
            }
        </div>

        <div className='mt-3' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
          Encl: 1) Draft Press Release <br/>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2) Draft Rating Report
        </div>
        <div class="divFooter  container text_footer" id="c2">
            <hr className="new4" />
            <div className='row'>
                <p className='col text-start mb-0 fw-bold' style={{fontSize: "12px"}}>The Pakistan Credit Rating Agency Limited [PACRA]</p>
                <p className='col text-end mb-0' style={{fontSize: "12px"}}>PABX: 92(42)3586 9504</p>
            </div>
            <div className='row'>
                <p className='col text-start mb-0' style={{fontSize: "12px"}}>Awami Complex FB-1, Usman Block, New Garden Town, </p>
                <p className='col text-end mb-0' style={{fontSize: "12px"}}>Fax: 92(42)3583 0425</p>
            </div>
            <div className='row'>
                <p className='col text-start mb-0' style={{fontSize: "12px"}}>Lahore - 54600, Pakistan </p>
                <p className='col text-end mb-0' style={{fontSize: "12px"}}>www.pacra.com</p>
            </div>
        </div>
      </div>}
    </div>
    <div className='card d-print-none'>
      {data !== null && 
      <div className={`card shadow ${screenWidth < 576 ? 'col-11 mt-3' : 'col-10 mt-5'} mx-auto p-3`}>
        <div className='mx-auto mt-2' style={{width:"fit-content"}}>
            <img src={img} style={{height: "50px", width: "50px"}}></img>
        </div>
        <div className={`text-center fw-bold ${screenWidth > 576 && 'fs-5'}`} style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
        The Pakistan Credit Rating Agency Limited
        </div>
        <hr/>
        <div className='row'>
            <div className='col-6 text-start' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
                PPL FY-{data.ppl[0].financial_year}-{data.ppl[0].ppl_number}
            </div>
            <div className='col-6 text-end'>
                <div className='text-decoration-underline fw-bold' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
                    {data.ppl[0].confidential}
                </div>
                <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
                    {DateFormat(data.ppl[0].date)}
                </div>
            </div>
        </div>
        <div className='fw-bold' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].to_name}</div>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].to_designation}</div>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].company}</div>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].address}</div>
        <div className='text-center fw-bold fs-6' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", color: "#000078"} : {fontFamily: "Kalinga", color: "#000078"}}>{data.ppl[0].company}</div>
        <div className='text-center fw-bold fs-6' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", color: "#000078"} : {fontFamily: "Kalinga", color: "#000078"}}>
        {
            data.ppl[0].rating_scale_opinion=="Security Agency Grading" ? "Security Grading" : 
            data.ppl[0].rating_scale_opinion=="Debt Instrument" ? "Rating" : 
            data.ppl[0].rating_scale_opinion=="Stability Rating" || data.ppl[0].rating_scale_opinion=="Capital Protection"  ? "Mutual Fund Ratings" : 
            data.ppl[0].rating_scale_opinion=="Performance Ranking" ? "Ranking" : 
            data.ppl[0].rating_scale_opinion=="Project Grading" ? "Project Grading | " + data.ppl[0].opinion_type : "Ratings | " + data.ppl[0].opinion_type

        }

        </div>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>Dear {to_name_chunks == "Mr" ? "Sir" : to_name_chunks == "Mrs" || to_name_chunks == "Ms" ? "Madam" : to_name_chunks !== "Mrs" ? "Sir" : null}</div>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].withdraw_text}</div> <br />
        <div style={{overflow: "auto"}}>
            { data.ppl[0].rating_scale_opinion == "Entity" || data.ppl[0].rating_scale_opinion =="Debt Instrument"?
                <table className='table newtable'>
                    <tr className='pdf'>
                        <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> <strong>Opinion</strong></td>
                        <td className='text-center' colSpan="2" style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Rating</strong></td>
                        <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Outlook</strong></td>
                        <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> <strong>Action</strong></td>
                        <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Opinion Type</strong></td>
                    </tr>
                    <tr className='pdf'>
                        <td> </td>
                        <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><b>Long Term</b></td>
                        <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><b>Short Term</b></td>
                        <td> </td>
                        <td></td>
                        <td> </td>
                    </tr>
                    <tr>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].opinion}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].long_term}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].short_term }</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].outlook}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].action}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].rating_type}</td>

                    </tr>
                </table> :
            data.ppl[0].rating_scale_opinion =="Structured Obligations" ?
            <table className='table newtable'>
             <tr className='pdf'>  
                 <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> <strong>Opinion</strong></td>
                 <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Rating</strong></td>
                 <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Outlook</strong></td>
                 <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> <strong>Action</strong></td>
                 <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Opinion Type</strong></td>
             </tr>
             <tr>
                 <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].opinion}</td>
                 <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].long_term}</td>
                 <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].outlook}</td>
                 <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].action}</td>
                 <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].rating_type}</td>

             </tr>
            </table> :
            data.ppl[0].rating_scale_opinion == "IFS" ?
            <table className='table newtable'>
             <tr className='pdf'>
                 <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> <strong>Opinion</strong></td>
                 <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>IFS Rating</strong></td>
                 <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Outlook</strong></td>
                 <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> <strong>Action</strong></td>
                 <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Opinion Type</strong></td>
             </tr>
             <tr>
                 <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].opinion}</td>
                 <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].long_term}</td>
                 <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].outlook}</td>
                 <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].action}</td>
                 <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].rating_type}</td>

             </tr>
            </table> :
             data.ppl[0].rating_scale_opinion == "Broker Management" || data.ppl[0].rating_scale_opinion == "Broker Management Rating" || 
             data.ppl[0].rating_scale_opinion == "Trustee Fiduciary Rating" ?
             <table className='table newtable'>
                <tr className='pdf'>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Opinion</strong></td>
                    <td colSpan="2" style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Rating</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Outlook</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Action</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].opinion}</td>
                    <td className='text-center' colspan="2" style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].long_term}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].outlook}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].action}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].rating_type}</td>
                </tr>
             </table> :
             data.ppl[0].rating_scale_opinion == "BFR" || data.ppl[0].rating_scale_opinion == "Social Impact and Performance Rating" ?
             <table className='table newtable' rules="cols" width="100%">
                <tr className='pdf'>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Opinion</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Rating</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Outlook</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Action</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Opinion Type</strong></td>
                </tr>

                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].opinion}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].long_term}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].outlook}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].action}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].rating_type}</td>
                </tr>
            </table> :
            data.ppl[0].rating_scale_opinion == "Project Grading" ?
            <table className='table newtable' rules="cols" width="100%">
                <tr className='pdf'>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Opinion</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Grading</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Outlook</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Action</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].opinion}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].long_term}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].outlook}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].action}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].rating_type}</td>
                </tr>
            </table> :
            data.ppl[0].rating_scale_opinion == "Performance Ranking" ?
            <table className='table-striped newtable' rules="cols" width="100%">
                <tr className='pdf'>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> <strong>Opinion</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> <strong>Action</strong></td>
                    <td colSpan="3" style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Ranking</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Category</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr className='pdf'>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><b>1 Year</b></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><b>3 Year</b></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><b>5 Year</b></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].opinion}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].action}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].short_term}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].long_term}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].rating_five_yr}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].outlook}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].rating_type}</td>
                </tr>
            </table> :
            data.ppl[0].rating_scale_opinion=="Stability Rating" ?
            <table className='table newtable' rules="cols" width="100%">
                <tr className='pdf'>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> <strong>Opinion</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Rating</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Category</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> <strong>Action</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].opinion}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].long_term}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].outlook}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].action}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].rating_type}</td>
                </tr>
            </table> :
            data.ppl[0].rating_scale_opinion=="Asset Manager" ?
            <table className='table newtable' rules="cols" width="100%">
                <tr className='pdf'>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> <strong>Opinion</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Rating</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Outlook</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> <strong>Action</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
                    {data.ppl[0].opinion}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].long_term}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].outlook}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].action}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].rating_type}</td>
                </tr>
            </table> :
            data.ppl[0].rating_scale_opinion=="REIT Manager Rating" ||  data.ppl[0].rating_scale_opinion=="REIT Fund Rating" ?
            <table className='table-striped newtable' rules="cols" width="100%">
                <tr className='pdf'>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> <strong>Opinion</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Rating</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Outlook</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> <strong>Action</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].opinion}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].long_term}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].outlook}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].action}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].rating_type}</td>
                </tr>
            </table> : 
            <table className='table newtable' rules="cols" width="100%">
                <tr className='pdf'>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Opinion</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Rating</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Outlook</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Action</strong></td>
                    <td style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}><strong>Opinion Type</strong></td>
                </tr>

                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].opinion}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].long_term}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].outlook}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].action}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.ppl[0].rating_type}</td>
                </tr>
            </table>
            }
        </div>
        <br />
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
            {data.ppl[0].text}
        </div> <br />
        <div style={{fontFamily: "Kalinga"}}>Yours truly</div>
        <br /> <br />
        <div id='sign'>
        {data.ppl[0].second_approve == "Approved" &&
            <img src={'data:image/jpg;base64,'+data.getApproval[0].sign} style={{width: "120px", height: "60px"}} />
        }
        </div> <br/>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
            {
                data.ppl[0].s_designation=='uh' || data.ppl[0].s_designation=='cro' || data.ppl[0].s_designation=='Unit Head' ?
                <>
                {
                    data.ppl[0].date < '2020-09-24' ?
                    <div className='fw-bold' style={{fontFamily: "Kalinga"}}>{`(${data.getApproval[0].name})`} <br></br></div> :
                    <div className='fw-bold' style={{fontFamily: "Kalinga"}}>{`(${data.ppl[0].uh1})`}<br></br></div>
                }
                {
                    data.ppl[0].s_designation=='cro' ?
                    <div style={{fontFamily: "Kalinga"}}> Chief Rating Officer </div> :
                    <div style={{fontFamily: "Kalinga"}}>Unit Head | Ratings</div>
                }
                </> : null
            }
        </div>
        <br />
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
          Encl: 1) Draft Press Release <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2) Draft Rating Report
        </div>
      </div>}
    </div>
    </div>
  )
}
