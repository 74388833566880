import { AES } from 'crypto-js';
import CryptoJS from 'crypto-js'; // Import CryptoJS library

const secretKey = 'your-secret-key';

export function encryptParams(params) {
  const encryptedParams = AES.encrypt(JSON.stringify(params), secretKey).toString();
  return encryptedParams;
}

export function decryptParams(encryptedParams) {
    try {
        const decryptedBytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedParams), secretKey);
        const decryptedParams = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
        console.log(decryptedParams, "d parameters");
        return decryptedParams;
        // Use the decrypted parameters in your component
      } catch (error) {
        console.error("Error decrypting parameters:", error);
        // Handle the error appropriately, e.g., redirect or display an error message
      }
}