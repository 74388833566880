import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios';
import { url } from '../../../Config';
import Select from "react-select";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";


export default function Edit_policy(props) {
    const [data, setdata] = useState(null);
    const { id } = useParams();
    const [source, setsource] = useState([]);
    const [classification, setclassification] = useState([]);
    const [law, setlaw] = useState([]);
    const [selectedlaw, setselectedlaw] = useState();
    const [Dissemination, setDissemination] = useState([]);
    const [Applicability, setApplicability] = useState([]);
    const [selectedApplicability, setselectedApplicability] = useState();
    const [scopehr, setscopehr] = useState([]);
    const [scopebd, setscopebd] = useState([]);
    const [scopebod, setscopebod] = useState([]);
    const [scoperat, setscoperat] = useState([]);
    const [scopeguide, setscopeguide] = useState([]);
    const [naturecc, setnaturecc] = useState([]);
    const [naturecoi, setnaturecoi] = useState([]);
    const [natureic, setnatureic] = useState([]);
    const [naturerat, setnaturerat] = useState([]);
    const [lawid, setlawid] = useState(null);
    const [Applicabilityid, setApplicabilityid] = useState(null);
    const [sourceid, setsourceid] = useState(0);
    const [classificationid, setclassificationid] = useState(0);
    const [scopeid, setscopeid] = useState(0);
    const [doctype, setdoctype] = useState("");
    const [reviewDate, setreviewDate] = useState(null);
    const [effectiveDate, seteffectiveDate] = useState(null);
    const [disseminationid, setdisseminationid] = useState(0);
    const [policydocpdf, setpolicydocpdf] = useState(null);
    const [policydocword, setpolicydocword] = useState(null);
    const [minutespdf , setminutespdf] = useState(null); 
    const [minutesword , setminutesword] = useState(null); 
    const [bodFile, setbodFile] = useState(null);
    const userid = localStorage.getItem("userID");
    const Navigate = useNavigate();

    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();


    const scopeRef = useRef(null);
    const natureRef = useRef(null);
    const doctypeRef = useRef(null);
    const reviewDateRef = useRef(null);
    const lawRef = useRef(null);
    const clauseRef = useRef(null);
    const disseminationRef = useRef(null);
    const applicabilityRef = useRef(null);
    const minutesRef = useRef(null);
    const bodRef = useRef(null);
    const clauseValueRef = useRef(null);

    const sourcevalRef = useRef(null);
    const ClassificationRef = useRef(null);
    const scopevalref = useRef(null);
    const naturevalref = useRef(null);
    const docTypeValRef = useRef(null)
    const dissValRef = useRef(null);
    const reviewDatevalRef = useRef(null);
    const effectiveDatevalRef = useRef(null);
    const minutesfileRef = useRef(null);
    const minuteswordfileRef = useRef(null);
    const BoDfileRef = useRef(null)
    const policyfileRef = useRef(null)
    const policywordfileRef = useRef(null)



    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/editPg/${id}`);
            const resp = res.data;
            const law = resp.law;
            const lawoptions = law.map(item => ({ value: item.id, label: item.title }));
            const appli = resp.applicability;
            const applicability_option = appli.map(item => ({ value: item.id, label: item.title }));
            setsource(resp.rating_agency);
            setclassification(resp.classification)
            setlaw(lawoptions);
            setDissemination(resp.dissemination);
            setApplicability(applicability_option);
            setscopehr(resp.scopehr);
            setscopebd(resp.scopebd);
            setscopebod(resp.scopebod);
            setscoperat(resp.scoperat);
            setscopeguide(resp.scopeguide);
            setnaturecc(resp.naturecc);
            setnaturecoi(resp.naturecoi);
            setnatureic(resp.natureic);
            setnaturerat(resp.naturerat);
            setdata(resp);
            const oldPolicy = resp.oldPolicy;
            // setsourceid(Number(oldPolicy.ratings_agencies_id));
            // setclassificationid(Number(oldPolicy.pacra_policy_classification_id))
            // setscopeid(Number(oldPolicy.pacra_policy_category_new_id))
            // setdoctype(oldPolicy.doc_type);
           
            if(sourcevalRef.current){
                sourcevalRef.current.value = Number(oldPolicy.ratings_agencies_id);
                setsourceid(Number(oldPolicy.ratings_agencies_id));
            }
            if(ClassificationRef.current){
                ClassificationRef.current.value = Number(oldPolicy.pacra_policy_classification_id);
                setclassificationid(Number(oldPolicy.pacra_policy_classification_id))
            }
            if(Number(oldPolicy.pacra_policy_classification_id) == 1){
                if(scopeRef.current !== null){
                    scopeRef.current.hidden = false;
                    if(scopevalref.current){
                        scopevalref.current.value = Number(oldPolicy.pacra_policy_category_new_id);
                        setscopeid(Number(oldPolicy.pacra_policy_category_new_id));
                    }
                }
            }
            else if(Number(oldPolicy.pacra_policy_classification_id) == 2){
                if(natureRef.current !== null){
                    natureRef.current.hidden = false;
                    if(naturevalref){
                        naturevalref.current.value = Number(oldPolicy.pacra_policy_category_new_id);
                        setscopeid(Number(oldPolicy.pacra_policy_category_new_id));
                    }
                }
            }

            if(Number(oldPolicy.ratings_agencies_id) == 1){
                if(docTypeValRef.current !== null){
                    docTypeValRef.current.value = oldPolicy.doc_type;
                    setdoctype(oldPolicy.doc_type);
                }
    
                setreviewDate(oldPolicy.review_date);
                seteffectiveDate(oldPolicy.effective_date);
                if(reviewDatevalRef.current){
                    reviewDatevalRef.current.required = true;
                }
                if(effectiveDatevalRef.current){
                    effectiveDatevalRef.current.required = true;
                }
    
                if(clauseValueRef.current){
                    clauseValueRef.current.value = oldPolicy.clause
                }
                
                if(dissValRef.current){
                    dissValRef.current.value = Number(oldPolicy.og_pacra_security_id);
                    setdisseminationid(Number(oldPolicy.og_pacra_security_id));
                }
    
                const lawstring = oldPolicy.pacra_policy_law_id;
                const lawarr = lawstring.split(",");
                const arr1 = [];
    
                for(let i in lawoptions){
                    if(lawarr.includes(`${lawoptions[i].value}`)){
                        arr1.push(lawoptions[i])
                    }
                }
    
                setselectedlaw(arr1);
                setlawid(lawstring);

                const applistring = oldPolicy.pacra_policy_applicability_id;
                const appliarr = applistring.split(",");
                const arr2 = [];

                for(let i in applicability_option){
                    if(appliarr.includes(`${applicability_option[i].value}`)){
                        arr2.push(applicability_option[i])
                    }
                }

                setselectedApplicability(arr2);
                setApplicabilityid(applistring);


                if(minutesfileRef.current){
                    if(oldPolicy.minutes == null){
                        minutesfileRef.current.required = true;
                    }
                }
                if(minuteswordfileRef.current){
                    if(oldPolicy.minutes_word == null){
                        minuteswordfileRef.current.required = true;
                    }
                }
                if(BoDfileRef.current){
                    if(oldPolicy.bod_approval == null){
                        BoDfileRef.current.required = true;
                    }
                }
            }
            else{
                    seteffectiveDate(oldPolicy.effective_date);
                    if(doctypeRef.current){
                        doctypeRef.current.hidden = true;
                    }
                    if(reviewDateRef.current){
                        reviewDateRef.current.hidden = true;
                    }
                    if(lawRef.current){
                        lawRef.current.hidden = true;
                    }
                    if(clauseRef.current){
                        clauseRef.current.hidden = true;
                    }
                    if(disseminationRef.current){
                        disseminationRef.current.hidden = true;
                    }
                    if(applicabilityRef.current){
                        applicabilityRef.current.hidden = true;
                    }
                    if(minutesRef.current){
                        minutesRef.current.hidden = true;
                    }
                    if(bodRef.current){
                        bodRef.current.hidden = true;
                    }
            }

            

            

            if(policyfileRef.current){
                if(oldPolicy.policy_document == null){
                    policyfileRef.current.required = true;
                }
            }
            if(policyfileRef.current){
                if(oldPolicy.policy_document_word == null){
                    policyfileRef.current.required = true
                }
            }
            

        }

        func();
    },[sourcevalRef.current])

    // useEffect(()=>{
    //     if(data !== null){
    //         const oldPolicy = data.oldPolicy;
    //         setsourceid(Number(oldPolicy.ratings_agencies_id));
    //         setclassificationid(Number(oldPolicy.pacra_policy_classification_id))
    //         setscopeid(Number(oldPolicy.pacra_policy_category_new_id))
    //         setdoctype(oldPolicy.doc_type);
    //         setreviewDate(oldPolicy.review_date);
    //         seteffectiveDate(oldPolicy.effective_date);
    //     }
    // },[data])

    const handlelaw = (data)=>{
        
        const arr = [];
        for(let i in data){
            arr.push(data[i].value);
        }

        const stringifyArray = arr.join(",");
        setselectedlaw(data);
        setlawid(stringifyArray);

    }
    const handleApplicability = (data)=>{
        const arr = [];
        for(let i in data){
            arr.push(data[i].value);
        }

        const stringifyArray = arr.join(",");
        setselectedApplicability(data);
        setApplicabilityid(stringifyArray);
    }

    const editpg = async (event)=>{
        event.preventDefault();

        if(sourceid == 0 || sourceid == "0"){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Option from Source First!`);
            return ;
        }

        else if(sourceid == 1){

            if(classificationid == 0 || classificationid == '0'){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Classification First!`);
                return ;
            }
            if(scopeid == 0 || scopeid == '0'){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Scope/Nature First!`);
                return ;
            }
            if(doctype == ''){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Document Type First!`);
                return ;
            }

            if(lawid == null || lawid == ''){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Law First!`);
                return ;
            }

            if(Applicabilityid == null || Applicabilityid == ''){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Applicability Type First!`);
                return ;
            }
            

            const fileData = new FormData();
            fileData.append("userid", userid);
            fileData.append("old_policy_id", Number(id));
            fileData.append("ratings_agencies_id", sourceid);
            fileData.append("pacra_policy_classification_id", classificationid);
            fileData.append("pacra_policy_category_new_id", scopeid);
            fileData.append("doc_type", doctype);
            fileData.append("review_date", reviewDate);
            fileData.append("effective_date", effectiveDate);
            fileData.append("pacra_policy_law_id", lawid);
            fileData.append("clause", clauseValueRef.current.value);
            fileData.append("og_pacra_security_id", disseminationid);
            fileData.append("pacra_policy_applicability_id", Applicabilityid);
            fileData.append("policy_document", policydocpdf);
            fileData.append("policy_document_word", policydocword);
            fileData.append("minutes", minutespdf);
            fileData.append("minutes_word", minutesword);
            fileData.append("bod_approval", bodFile);

            const res = await axios.post(`${url}/api/updatePg`,
                fileData,
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
            );
            const resp = res.data;
            if(resp.status == true){
                Navigate('/p&g');
            }
            else{
                setalertType("error");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
        }

        else{
            if(classificationid == 0 || classificationid == '0'){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Classification First!`);
                return ;
            }
            if(scopeid == 0 || scopeid == '0'){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Scope/Nature First!`);
                return ;
            }
            
            

            const fileData = new FormData();
            fileData.append("userid", userid);
            fileData.append("old_policy_id", Number(id));
            fileData.append("ratings_agencies_id", sourceid);
            fileData.append("effective_date", effectiveDate);
            fileData.append("pacra_policy_classification_id", classificationid);
            fileData.append("pacra_policy_category_new_id", scopeid);
            fileData.append("policy_document", policydocpdf);
            fileData.append("policy_document_word", policydocword);


            const res = await axios.post(`${url}/api/updatePg`,
                fileData,
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
            );
            const resp = res.data;
            if(resp.status == true){
                Navigate('/p&g');
            }
            else{
                console.log(resp, 'resp');
                setalertType("error");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
        }
    }

  return (
    <div>
       <div className='mt-2 text-center mb-2 fw-bold fs-3' style={{color:"#000078"}}>
        Edit P & G
      </div>
      <hr />
      <form className='card shadow col-10 mx-auto mt-1 p-3' onSubmit={(event)=>{editpg(event)}}>
        <div className='fw-bold fs-6'>
            Source
        </div>
        <div className='col-11'>
            <select className='form-control' ref={sourcevalRef} onChange={(e)=>{
                if(e.target.value == 1 || e.target.value == 0){
                    doctypeRef.current.hidden = false;
                    reviewDateRef.current.hidden = false;
                    lawRef.current.hidden = false;
                    clauseRef.current.hidden = false;
                    disseminationRef.current.hidden = false;
                    applicabilityRef.current.hidden = false;
                    minutesRef.current.hidden = false;
                    bodRef.current.hidden = false;
                    reviewDatevalRef.current.required = true;
                    effectiveDatevalRef.current.required = true;
                    if(data.oldPolicy.policy_document == null){
                        policyfileRef.current.required = true;
                    }
                    if(data.oldPolicy.policy_document_word == null){
                        policyfileRef.current.required = true
                    }
                    if(data.oldPolicy.minutes == null){
                        minutesfileRef.current.required = true;
                    }
                    if(data.oldPolicy.minutes_word == null){
                        minuteswordfileRef.current.required = true;
                    }
                    if(data.oldPolicy.bod_approval == null){
                        BoDfileRef.current.required = true;
                    }
                    
                }
                else{

                    doctypeRef.current.hidden = true;
                    reviewDateRef.current.hidden = true;
                    lawRef.current.hidden = true;
                    clauseRef.current.hidden = true;
                    disseminationRef.current.hidden = true;
                    applicabilityRef.current.hidden = true;
                    minutesRef.current.hidden = true;
                    bodRef.current.hidden = true;
                    reviewDatevalRef.current.required = false;
                    effectiveDatevalRef.current.required = true;
                    if(data.oldPolicy.policy_document == null){
                        policyfileRef.current.required = true;
                    }
                    if(data.oldPolicy.policy_document_word == null){
                        policyfileRef.current.required = true
                    }
                    minutesfileRef.current.required = false;
                    minuteswordfileRef.current.required = false;
                    BoDfileRef.current.required = false;
                }

                setsourceid(e.target.value);
            }}>
                <option value={0}>Select Source</option>
                { 
                    source.map((value, index)=>{
                        return(
                            <option value={value.id}>{value.name}</option>
                        )
                    })
                }
            </select>
        </div>

        <div className='fw-bold fs-6 mt-4'>
            Classification
        </div>
        <div className='col-11'>
            <select className='form-control' ref={ClassificationRef} onChange={(e)=>{
                if(e.target.value == 1){
                    scopeRef.current.hidden = false;
                    natureRef.current.hidden = true;
                    if(scopevalref.current){
                        scopevalref.current.value = 0;
                    }
                    if(naturevalref.current){
                        naturevalref.current.value = 0;
                    }
                }
                else if(e.target.value == 2){
                    scopeRef.current.hidden = true;
                    natureRef.current.hidden = false;
                    if(scopevalref.current){
                        scopevalref.current.value = 0;
                    }
                    if(naturevalref.current){
                        naturevalref.current.value = 0;
                    }
                }
                else{
                    scopeRef.current.hidden = true;
                    natureRef.current.hidden = true;
                    if(scopevalref.current){
                        scopevalref.current.value = 0;
                    }
                    if(naturevalref.current){
                        naturevalref.current.value = 0;
                    }
                }
                setscopeid(0);
                setclassificationid(e.target.value);
            }}>
                <option value={0}>Select Classification</option>
                { 
                    classification.map((value, index)=>{
                        return(
                            <option value={value.id}>{value.title}</option>
                        )
                    })
                }
            </select>
        </div>

        <div ref={scopeRef} hidden={true}>
            <div className='fw-bold fs-6 mt-4'>
                Scope
            </div>
            <div className='col-11'>
                <select className='form-control' ref={scopevalref} onChange={(e)=>{setscopeid(e.target.value)}}>
                    <option value={0}>Select Scope</option>
                    <optgroup label='HR'>
                    { 
                        scopehr.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>
                    
                    <optgroup label='Business Development'>
                    { 
                        scopebd.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>

                    <optgroup label='Rating'>
                    { 
                        scoperat.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>

                    <optgroup label='BOD'>
                    { 
                        scopebod.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>

                    <optgroup label='Guideline'>
                    { 
                        scopeguide.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>
                    
                </select>
            </div>
        </div>

        <div ref={natureRef} hidden={true}>
            <div className='fw-bold fs-6 mt-4'>
                Nature
            </div>
            <div className='col-11'>
                <select className='form-control' ref={naturevalref} onChange={(e)=>{setscopeid(e.target.value)}}>
                    <option value={0}>Select Scope</option>
                    <optgroup label='Code of Conduct'>
                    { 
                        naturecc.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>
                    
                    <optgroup label='Conflict of Interest'>
                    { 
                        naturecoi.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>

                    <optgroup label='Internal Controls'>
                    { 
                        natureic.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>

                    <optgroup label='Rating'>
                    { 
                        naturerat.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>
                    
                </select>
            </div>
        </div>

        <div ref={doctypeRef}>
            <div className='fw-bold fs-6 mt-4'>
                Document Type
            </div>
            <div className='col-11'>
                <select className='form-control' ref={docTypeValRef} onChange={(e)=>{setdoctype(e.target.value)}}>
                    <option value="">Select Classification</option>
                    <option value="Policy">Policy</option>
                    <option value="Guidelines">Guidelines</option>
                    <option value="Policy_Annexure_H">Policy–Annexure H</option>
                    <option value="Policy_Internal_Control_Policy">Policy–Internal Control Policy</option>
                    <option value="TORs">TORs</option>
                    <option value="Code_of_Conduct">Code of Conduct</option>
                    <option value="Forms">Forms</option>
                </select>
            </div>
        </div>
        
        <div ref={reviewDateRef}>
            <div className='fw-bold fs-6 mt-4'>
                Review Date
            </div>
            <div className='col-11'>
                <input className='form-control' type='date' ref={reviewDatevalRef} value={reviewDate} onChange={(e)=>{setreviewDate(e.target.value)}}/>
            </div>
        </div>
        
        <div>
            <div className='fw-bold fs-6 mt-4'>
                Applicability / Effective Date
            </div>
            <div className='col-11'>
                <input className='form-control' type='date' ref={effectiveDatevalRef} value={effectiveDate} onChange={(e)=>{seteffectiveDate(e.target.value)}} />
            </div>
        </div>

        <div ref={lawRef}>
            <div className='fw-bold fs-6 mt-4'>
                Law
            </div>
            <div className='col-11'>
                    <Select
                        options={law}
                        placeholder="Select Opinion"
                        value={selectedlaw}
                        onChange={handlelaw}
                        isSearchable={true}
                        isMulti
                        />
            </div>
        </div>
        
        <div ref={clauseRef}>
            <div className='fw-bold fs-6 mt-4'>
                Clause
            </div>
            <div className='col-11'>
                <textarea className='form-control' ref={clauseValueRef} maxLength={400} placeholder='You can Type Maximum 400 characters here'/>
            </div>
        </div>
        
        <div ref={disseminationRef}>
            <div className='fw-bold fs-6 mt-4'>
                Dissemination
            </div>
            <div className='col-11'>
            <select className='form-control' ref={dissValRef} onChange={(e)=>{setdisseminationid(e.target.value)}}>
                    <option value={0}>Select Source</option>
                    { 
                        Dissemination.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.title}</option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
        <div ref={applicabilityRef}>
            <div className='fw-bold fs-6 mt-4'>
                Applicability
            </div>
            <div className='col-11'>
                    <Select
                        options={Applicability}
                        placeholder="Select Opinion"
                        value={selectedApplicability}
                        onChange={handleApplicability}
                        isSearchable={true}
                        isMulti
                        />
            </div>
        </div>

        <div className='fw-bold fs-6 mt-4'>
            Policy Document
        </div>
        {data !== null ?
         data.oldPolicy.policy_document !== null &&
        <div className='mt-1'>
            Already Uploaded: <a href={`${url}/api/getPolicyDoc/${data.oldPolicy.policy_document_encrypt}`} target='_blank'>{data.oldPolicy.policy_document}</a>
        </div> : null}
        <div className='col-11'>
            <input className='form-control' type='file' accept="application/pdf" ref={policyfileRef} onChange={(e)=>{
                setpolicydocpdf(e.target.files[0]);
            }}/>
            <div style={{fontSize: "13px"}}>
                Please upload a valid pdf file.
            </div>
        </div>
        {data !== null ?
         data.oldPolicy.policy_document_word !== null &&
        <div className='mt-1'>
            Already Uploaded: <a href={`${url}/api/getPolicyDoc/${data.oldPolicy.policy_document_word_encrypt}`} download={`${data.oldPolicy.policy_document_word}.docx`} target='_blank'>{data.oldPolicy.policy_document_word}</a>
        </div> : null}
        <div className='col-11 mt-2'>
            <input className='form-control' type='file' accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document" ref={policywordfileRef} onChange={(e)=>{setpolicydocword(e.target.files[0]);}}/>
            <div style={{fontSize: "13px"}}>
                Please upload a valid word file.
            </div>
        </div>
        
        <div ref={minutesRef}>
            <div className='fw-bold fs-6 mt-4'>
                Minutes
            </div>
            {data !== null ?
            data.oldPolicy.minutes !== null &&
            <div className='mt-1'>
                Already Uploaded: <a href={`${url}/api/getPolicyDoc/${data.oldPolicy.minutes_encrypt}`} target='_blank'>{data.oldPolicy.minutes}</a>
            </div> : null}
            <div className='col-11'>
                <input className='form-control' type='file' accept="application/pdf" ref={minutesfileRef} onChange={(e)=>{setminutespdf(e.target.files[0])}}/>
                <div style={{fontSize: "13px"}}>
                    Please upload a valid pdf file.
                </div>
            </div>
            {data !== null ?
            data.oldPolicy.minutes_word !== null &&
            <div className='mt-1'>
                Already Uploaded: <a href={`${url}/api/getPolicyDoc/${data.oldPolicy.minutes_word_encrypt}`} download={`${data.oldPolicy.minutes_word}.docx`} target='_blank'>{data.oldPolicy.minutes_word}</a>
            </div> : null}
            <div className='col-11 mt-2'>
                <input className='form-control' type='file' ref={minuteswordfileRef} accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e)=>{setminutesword(e.target.files[0])}}/>
                <div style={{fontSize: "13px"}}>
                    Please upload a valid word file.
                </div>
            </div>
        </div>
        
        <div ref={bodRef}>
            <div className='fw-bold fs-6 mt-4'>
                BOD Approvals
            </div>
            {data !== null ?
            data.oldPolicy.bod_approval !== null &&
            <div className='mt-1'>
                Already Uploaded: <a href={`${url}/api/getPolicyDoc/${data.oldPolicy.bod_approval_encrypt}`} target='_blank'>{data.oldPolicy.bod_approval}</a>
            </div> : null}
            <div className='col-11'>
                <input className='form-control' type='file' accept="application/pdf" ref={BoDfileRef} onChange={(e)=>{setbodFile(e.target.files[0])}}/>
                <div style={{fontSize: "13px"}}>
                    Please upload a valid pdf.
                </div>
            </div>
        </div>

        <div className='mt-1 mb-2'>
            <Box className="mx-auto" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
            </Box>
        </div>

        <div className='mt-5' style={{width: 'fit-content'}}>
            <button className='btn btn-primary' type='submit'>Update</button>
        </div>
      </form>
    </div>
  )
}
