import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { url } from '../../../../Config';


export default function Perf_rank() {

    const [data, setdata] = useState(null);
    const {client_id, og_rating_id } = useParams();
    const [alertopen, setalertopen] = useState(false);
    const [AlertMessage, setAlertMessage] = useState(null);
    const [alertType, setalertType] = useState("success");
    const [file1, setfiles1] = useState("No File Chosen");
    const [file2, setfiles2] = useState("No File Chosen");
    const [file3, setfiles3] = useState("No File Chosen");
    const Navigate = useNavigate();

    
    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/perf_rank_index/${client_id}/${og_rating_id}`);
            const resp = res.data;
            
            if(resp.message == "Data Already Exist"){
                Navigate(`/Ratings/perf_rank/edit/${client_id}/${og_rating_id}`);
            }
            else{
                setdata(resp);
            }
        }
        func();
    },[])

    const submit = async (event)=>{
        event.preventDefault();
        
        const res = await axios.post(`${url}/api/addRR_Krd`,{
            "type": "prf",
            'opinion_id' : client_id,
            'recordId' : og_rating_id,
            'rrId' : og_rating_id,
            'record_id' : og_rating_id,
            'analyst_id' : data.analystId,
            'rating_scale_name' : data.ratingScaleName,
            'manager_id' : data.manager_id,
            'lead_rc_id' : data.lead_rc_id,
            'press_release_title' : document.getElementById("title").value,
            'opName' : data.opName,
            'rating_type_id' : data.ratingTypeId,
            'rating_scale_id' : data.ratingScaleId,
            'img1' : file1,
            'img2' : file2,
            'img3' : file3,
            'rating_rationale_1' : document.getElementById('rating_rationale_1').value,
            'rating_rationale_3' : document.getElementById('rating_rationale_3').value,
            'rating_rationale_5' : document.getElementById('rating_rationale_5').value,
            'total_fund' : document.getElementById('total_fund').value,

        },
        {
            headers: {
              "Content-Type": "multipart/form-data",
            },
        }
        )

        if(res.data.status == true){
            Navigate(`/Ratings/perf_rank/edit/${client_id}/${og_rating_id}`);
        }
        else{
            setalertType("error");
            setAlertMessage("An error occure while process your request");
            setalertopen(true);
        }

    }

  return (
    <div style={{height: "90vh"}}>
      <br />
      <form className='card shadow col-11 mx-auto p-2' style={{backgroundColor: "whitesmoke"}} onSubmit={submit}>
        <div className='text-center text-decoration-underline fw-bold fs-5' style={{ color: "#000078", fontFamily: "Kalinga" }}>
            Rating Rationale & Key Rating Drivers
        </div>
        <div className='text-center text-decoration-underline fw-bold fs-5 mt-2' style={{ color: "#000078", fontFamily: "Kalinga" }}>
            {data !== null ? data.opName : null}
        </div>
        <div className='col-11 mx-auto mt-2'>
            <input className='form-control' placeholder='Enter Press Release Title' id='title' required />
        </div>
        <hr />
        <div className='col-11 mx-auto'>
            <div className='mt-1'>
                <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Rating Rationale 1-Year:</label>
                <div>
                    <textarea className='form-control' id='rating_rationale_1' />
                </div>
            </div>
            <div className='mt-1'>
                <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Rating Rationale 3-Year:</label>
                <div>
                    <textarea className='form-control' id='rating_rationale_3' />
                </div>
            </div>
            <div className='mt-1'>
                <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Rating Rationale 5-Year:</label>
                <div>
                    <textarea className='form-control' id='rating_rationale_5' />
                </div>
            </div>
            <div className='mt-1'>
                <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Total Funds:</label>
                <div>
                    <input className='form-control' type='text' id='total_fund' />
                </div>
            </div> 
            <hr />

            <div className='mt-1'>
                <table className='table table-bordered'>
                    <tbody>
                        <tr>
                            <td className="col-2">Image 1-Year</td>
                            <td className="col-4">
                                <input className='form-control' type='file' onChange={(e) => {
                                    let newFiles = file1
                                    newFiles = e.target.files[0];
                                    setfiles1(newFiles);
                                }} required/>
                            </td>
                            <td className="col-4"><span>{file1 !== "No File Chosen" ? file1.name : file1}</span></td>
                        </tr>
                        <tr>
                            <td className='col-2'>Image 3-Year</td>
                            <td className="col-4">
                                <input className='form-control' type='file' onChange={(e) => {
                                    let newFiles = file2
                                    newFiles = e.target.files[0];
                                    setfiles2(newFiles);
                                }} required />
                            </td>
                            <td className="col-4"><span>{file2 !== "No File Chosen" ? file2.name : file2}</span></td>
                        </tr>
                        <tr>
                            <td className="col-2">Image 5-Year</td>
                            <td className="col-4">
                                <input className='form-control' type='file' onChange={(e) => {
                                    let newFiles = file3
                                    newFiles = e.target.files[0];
                                    setfiles3(newFiles);
                                }} required />
                            </td>
                            <td className='col-4'><span>{file3 !== "No File Chosen" ? file3.name : file3}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                        severity={alertType}
                        action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setalertopen(alertopen);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        sx={{ mb: 2 }}
                      >
                        {AlertMessage}
                    </Alert>
                </Collapse>
        </Box>
            <div className='mx-auto mt-2' style={{width: "fit-content"}}>
                <button className='btn btn-primary btn-sm' type='submit'>Save</button>
            </div>
        </div>
      </form>
    </div>
  )
}
