import React, {useEffect, useState} from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Auth from '../../../middleWare/Auth/Auth';
import TableComponent from '../../Atoms/TableComponent';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import Tooltip from '@mui/material/Tooltip';


function EmployeeList(props) {

    const [list, setList] = useState([])
    const history = useNavigate();

    async function loginAs(id) {
        let res = await axios.post('https://polaris.pacra.com/mit-login-get-user', {
            user_id: id
        });
        const resp = res.data;
        if (res.status === 200) {
            const userID = resp.id;
            const rights = resp.rights;
            const designation = resp.display_designation;
            const username = resp.display_name;
            localStorage.setItem('rights', rights);
            localStorage.setItem('username', username);
            localStorage.setItem('userID', userID);
            localStorage.setItem('designation', designation);
            
            const avatar_file = resp.avatar_file;
            const departmentName = resp.department;
            const subdepartmentName = resp.sub_dpt;
            let dep = null;
            if(avatar_file !== null && avatar_file !== ''){
              localStorage.setItem('userImage', `https://209.97.168.200/hr/public/users/${avatar_file}`)
            }
            else{
                localStorage.setItem('userImage', `https://ptetutorials.com/images/user-profile.png`)
            }
            if(subdepartmentName == null){
              dep = departmentName;
              localStorage.setItem('depName', departmentName);
            }
            else{
              localStorage.setItem('depName', subdepartmentName);
              dep = subdepartmentName;
            }
            if(userID == 12 || userID == 469 || userID == 379 || userID == 341){
              localStorage.setItem('admin', true);
              localStorage.setItem('admin_rights', rights);
              localStorage.setItem('admin_username', username);
              localStorage.setItem('admin_userID', userID);
              localStorage.setItem('admin_designation', designation);
              if(avatar_file !== null && avatar_file !== ''){
                localStorage.setItem('userImage', `https://209.97.168.200/hr/public/users/${avatar_file}`)
              }
              else{
                  localStorage.setItem('userImage', `https://ptetutorials.com/images/user-profile.png`)
              }
              localStorage.setItem('admin_depName', dep);
            }
            Auth.login();
            goToDashboard(dep, userID);
        }
    }

    const goToDashboard = (departmentName, userID) => {
      if (Number(departmentName) == 9) {
          history('/Ratings');
      }
      else if (Number(departmentName) == 16) {
          history('/research-dashboard');
      }
      else if (Number(departmentName) == 8) {
          history('/criteria-dashboard');
      }
      else if (Number(departmentName) == 4 || Number(departmentName) == 31) {
          history('/bd_dashboard');
      }
      else if (userID == 48 || userID == 297 || userID == 298 || userID == 344 || userID == 372) {
        history('/rating-dashboard-external');
      }

      else{
          history('/pacra-wide');
      }
      window.location.reload();
  }

    useEffect(() => {
        (async () => {
            let res = await axios.post('https://polaris.pacra.com/mit-login-ajax')

            if (res.status === 200) {
                setList(res.data)
            }
        })()
    }, [])

    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return 100;
        } else {
          return 120;
        }
      };
    
      function innerReviewDate(params) {
        if (params.data.review_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.review_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };

      const responsiveColumnPin2 = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "right";
        }
      };

      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };

      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };

    const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Name",
          minWidth: responsiveColumns(),
          field: "name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
        },
        {
          headerName: "Email",
          minWidth: responsiveColumns(),
          field: "email",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "email",
        },

        {
            headerName: "Actions",
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            field: "",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
                return(
                    <Tooltip title='Login'><LoginIcon sx={{color: 'blue'}} onClick={()=>{loginAs(params.data.id)}} /></Tooltip>
                )
            },
            pinned: responsiveColumnPin2(),
        },

    ]

    const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>

        </h1>
    )

    return (
        <div className="container-fluid" style={{width: '100%', margin: 'auto'}}>
             <div className='mt-2 text-center mb-2 fw-bold fs-3' style={{color:"#000078"}}>
                Login Manager
             </div>
            <div>
                <TableComponent
                    Data={list}
                    screenWidth={props.screenWidth}
                    MobViewRender={MobViewRender}
                    columnDefs={columnDefs}
                    datefilters={datefilters}
                    datefilter={false}
                    // policy= {true}
                />
            </div>
            {/* <div className="table-responsive">
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {list.map((val, index) => {
                        return <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{val.name}</td>
                            <td>{val.email}</td>
                            <td>
                                <button onClick={() => loginAs(val.id)}>Login</button>
                            </td>
                        </tr>
                    })}

                    </tbody>
                </table>
            </div> */}
        </div>
    );
}

export default EmployeeList;