import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../Config';
import { Check, Clear, Event } from "@material-ui/icons";
import Select from "react-select";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
// const id = localStorage.getItem("userID");



export default function Edit_industry(props) {

  const [group, setgroup] = useState([]);
  const { id } = useParams();
  const [group_val, setgroup_val] = useState('');
  const industryRef = useRef(null);
  const [industry_val, setindustry_val] = useState('');
  const [alertopen, setalertOpen] = useState(false);
  const [alertType, setalertType] = useState();
  const [AlertMessage, setalertmessage] = useState();
  const btnRef = useRef(null);
  const Navigate = useNavigate();


  useEffect(()=>{

      const func = async ()=>{
          const res = await axios.get(`${url}/api/edit_industry/${id}`);
          const resp = res.data;
          setgroup(resp.segments);
          if(resp.sector_study.length > 0){
            setgroup_val(resp.sector_study[0].segment_id);
            setindustry_val(resp.sector_study[0].mainsectortitle);
          }
          
      }

      func();
  },[])


  const submitForm = async (event)=>{
      event.preventDefault();
      btnRef.current.disabled = true;
      btnRef.current.innerHTML = "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";

      const res = await axios.post(`${url}/api/update_industry`,{
          'industry_title': industryRef.current.value,
          'segment' : group_val,
          'id' : id
      });
      const resp = res.data;
      if(resp.status == true){
          btnRef.current.disabled = false;
          btnRef.current.innerHTML = "Update";

          // Navigate('/sector_study');
          setalertType("success");
          setalertOpen(true);
          setalertmessage(resp.message);
      }
      else{
          btnRef.current.disabled = false;
          btnRef.current.innerHTML = "Update";

          setalertType("error");
          setalertOpen(true);
          setalertmessage(resp.message);
      }

  }


  return (
      <div style={{height: '90vh'}}>
        <div className='fs-3 fw-bold text-center mt-2 mb-3' style={{color: "#000078"}}>
            Edit Industry
        </div>
        <form className='card shadow col-11 mx-auto p-2' onSubmit={(event)=>{submitForm(event)}}>
                <div>
                    <div className='mt-2 fw-bold'>
                        Group
                    </div>
                    <div className='mt-2'>
                        <select className='form-select'  value={group_val} onChange={(e)=>{setgroup_val(e.target.value)}} required>
                           <option value=''>Select Group</option>
                           {
                            group.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                           }
                        </select>
                    </div>
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Industry
                    </div>
                    <div>
                        <input className='form-control' defaultValue={industry_val} ref={industryRef} placeholder='Enter Industry Name' required />
                    </div>
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Reason
                    </div>
                    <div>
                        <textarea className='form-control' placeholder='Enter the reason' />
                    </div>
                </div>
                <div>
                <Box className="mx-auto mt-2 mb-2" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
                </Box>
                <button className='btn btn-primary mt-3 mt-2' type='submit' ref={btnRef}>Update</button>
                </div>
        </form>
    </div>
  )
}
