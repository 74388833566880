import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { url } from "../../../../Config";


export default function Editreview(props) {

    const { client_id, og_rating_id } = useParams();
    const Navigate = useNavigate();
    const id = localStorage.getItem("userID");
    const [data, setdata] = useState(null);
    const [latestRec, setlatestRec] = useState([]);
    const [compiled_client_details, setcompiled_client_details] = useState(null);
    const [RatingTitle, setRatingTitle] = useState(null);
    const [reason, setreason] = useState(null);
    const [alertopen, setalertopen] = useState(false);
    const [AlertMessage, setAlertMessage] = useState(null);
    const [alertType, setalertType] = useState("error");


    function DateFormating(params) {
        if (params == null) {
          return "-";
        } else {
          const date = new Date(params);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
    }

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/editreview/${id}/${client_id}/${og_rating_id}`);
            const resp = res.data;
            setdata(resp);
            if(resp.latestRecords.length > 0){
                setRatingTitle(resp.latestRecords[0].ratingType);
            }
            setlatestRec(resp.latestRecords);
            setcompiled_client_details(resp.compiled_client_details);
        }

        func();
    },[])

    const submit = async(type)=>{
        setalertopen(false);
        if(data.daystoreviewDeadline < 0){
            const res = await axios.post(`${url}/api/updatereview`, {
            "recordId" : og_rating_id,
            "opid" : client_id,
            "longTermRating" : latestRec[0].longTermRating,
            "shortTermRating" : latestRec[0].shortTermRating,
            "fiveYrRating" : latestRec[0].fiveYrRating,
            "outLook" : latestRec[0].outLook,
            "ratingAction" : latestRec[0].ratingAction,
            "ratingType" : latestRec[0].ratingType,
            "confidential" : "Confidential",
            "pplDate" : document.getElementById("date").value,
            "c_rating_watch" : data.lastestRW,
            "rating_watch" : data.rc_data[0].final_rating_watch,
            "p_action" : data.previousAction,
            "p_outlook" : data.previousOutLook,
            "p_lterm" : data.previousLT[0].title,
            "p_sterm" : data.p_st_title,
            "p_five_yr" : data.p_fiveYr_title,
            "p_rating_watch" : data.previousRW,
            "p_rc_date" : data.previousRCDate,
            "c_rc_date" : DateFormating(data.rc_data[0].date),
            "p_dis_date" : data.reviewTable.p_dis_date,
            "reasonForDelay" : document.getElementById("reason").value,
            "reviewDeadline" : data.daystoreviewDeadline,
            "diseminationDeadline" : data.daystoDisemDeadline,
            "s_name" : document.getElementById("s_name").value,
            "s_designation" : document.getElementById("s_designation").value,
            "address" : data.compiled_client_details.city,
            "opTitle" : latestRec[0].opTitle,
            "pplId" : data.getPpl.id,
            "type": type
            });
            console.log(res.data);
            if(res.data.status == true){
                if(res.data.message == "Approved"){
                    const approve = data;
                    approve.getPpl.second_approve = "Approved";
                    setdata(approve);
                    setalertType("success");
                    setAlertMessage("Successfully Approved!");
                    setalertopen(true);
                }
                else{
                    setalertType("success");
                    setAlertMessage("Successfully Saved!");
                    setalertopen(true);
                }
            }
            else{
                setalertType("error");
                setAlertMessage("Some Error occure while Processing your request");
                setalertopen(true);
            }
        }
        else{
            const res = await axios.post(`${url}/api/updatereview`, {
            "recordId" : og_rating_id,
            "opid" : client_id,
            "longTermRating" : data.final_l_term,
            "shortTermRating" : data.final_s_term,
            "fiveYrRating" : data.final_5yr,
            "outLook" : data.final_outlook,
            "ratingAction" : data.final_rating_action,
            "ratingType" : latestRec[0].ratingType,
            "confidential" : "Confidential",
            "pplDate" : document.getElementById("date").value,
            "c_rating_watch" : data.lastestRW,
            "p_action" : data.previousAction,
            "p_outlook" : data.previousOutLook,
            "p_lterm" : data.previousLT[0].title,
            "p_sterm" : data.p_st_title,
            "p_five_yr" : data.p_fiveYr_title,
            "p_rating_watch" : data.previousRW,
            "p_rc_date" : data.previousRCDate,
            "c_rc_date" : DateFormating(data.rc_data[0].date),
            "p_dis_date" : data.reviewTable.p_dis_date,
            "reviewDeadline" : data.daystoreviewDeadline,
            "diseminationDeadline" : data.daystoDisemDeadline,
            "s_name" : document.getElementById("s_name").value,
            "s_designation" : document.getElementById("s_designation").value,
            "address" : data.compiled_client_details.city,
            "opTitle" : latestRec[0].opTitle,
            "pplId" : data.getPpl.id,
            "type": type
            });
            console.log(res.data);
            if(res.data.status == true){
                if(res.data.message == "Approved"){
                    const approve = data;
                    approve.getPpl.second_approve = "Approved";
                    setdata(approve);
                    setalertType("success");
                    setAlertMessage("Successfully Approved!");
                    setalertopen(true);
                }
                else{
                    setalertType("success");
                    setAlertMessage("Successfully Saved!");
                    setalertopen(true);
                }
            }
            else{
                setalertType("error");
                setAlertMessage("Some Error occure while Processing your request");
                setalertopen(true);
            }
            }
        

    }

  return (
    <div style={{height: "90vh"}}>
     {data !== null ? 
     <div className='card shadow col-11 mx-auto p-2 border border-dark p-1 mt-3 mb-3' style={{backgroundColor: "whitesmoke"}}>
        {props.screenWidth <= 576 && 
            <div className='col-12 text-wrap text-center fw-bold' style={{fontStyle : "12px"}}>
            {compiled_client_details !== null ? compiled_client_details.clientName : null}
            </div>}

            {props.screenWidth <= 576 && 
            <div className={'col-12 text-wrap text-center mb-1'} style={{fontSize : "10px"}}>
                {data !== null ? data.latestRecords.length > 0 ? 
                <>
                {data.latestRecords[0].ratingType == "BFR" ? `${data.latestRecords[0].ratingType} - ` : `${data.latestRecords[0].ratingType} Ratings -`}
                {data.latestRecords[0].ratingAction == "Initial" ? data.latestRecords[0].ratingAction : "Update"}
                </>
                 : null : null}
            </div>}
        
        <div className='row'>
            {props.screenWidth > 576 && 
            <div className='col-3'>
            </div> }
            {props.screenWidth > 576 && <div className='col-6 text-wrap text-center fs-6 fw-bold'>
                {compiled_client_details !== null ? compiled_client_details.clientName : null}
            </div>}
            {props.screenWidth > 576 && <div className='col-3'>
                <input className='form-control' value={'Confidential'} disabled />
            </div>}
        </div>

        <div className='row mt-1'>
            {props.screenWidth > 576 && 
            <div className='col-3'>
            </div> }
            {props.screenWidth > 576 && <div className='col-6 text-wrap text-center fs-6'>
                {data !== null ? data.latestRecords.length > 0 ? 
                <>
                {data.latestRecords[0].ratingType == "BFR" ? `${data.latestRecords[0].ratingType} - ` : `${data.latestRecords[0].ratingType} Ratings -`}
                {data.latestRecords[0].ratingAction == "Initial" ? data.latestRecords[0].ratingAction : "Update"}
                </>
                 : null : null}
            </div>}
            <div className={props.screenWidth > 576 ? 'col-3' : 'col-12 mb-1'}>
                {data !== null ? data.getPpl.second_approve !== "Approved" ? <input className='form-control' type='text' id="date" value={data.notapprove} disabled required/> :
                <input className='form-control' type='text' value={data.onapprove} id="date" disabled required/> : null}
            </div>
            {props.screenWidth <= 576 && <div className={'col-12'}>
                <input className='form-control' value={'Confidential'} disabled />
            </div>}
        </div>

        <div className='mt-3'>
            <textarea className='form-control' id="textarea1" style={{height: "100px"}} readOnly>
            {"Objective | Rating ReviewScope \n" +
             "                   CRC Regulatons Clause 17(b) Review all outstanding ratings on semi annual basis or upon occurence of such as event which requires to do so; Provided that public dissemination of semi annual review will be required only in case of any change in underlying ratings."}
            </textarea>
        </div>

        <div className='mt-3' style={{overflow: "auto"}}>
        <table className='table table-secondary newtable'>
            <tbody>
                <tr>
                    <td rowSpan={2} className="col-2 fw-bold">Rating Type</td>
                    <td colSpan={2} className="col-10 text-center fw-bold">Entity</td>
                </tr>
                <tr>
                    <td className="text-center">Current</td>
                    <td className="text-center">Previous</td>
                </tr>
                <tr>
                    <td className="fw-bold">Action</td>
                    <td className="text-center">{data !== null ? data.final_rating_action : null}</td>
                    <td className="text-center">{data !== null ? data.previousAction : null}</td>
                </tr>
                <tr>
                    {
                        RatingTitle !== null ?
                        RatingTitle == "Performance Ranking"?
                        <td>3 Year</td> : <td className="fw-bold">Long Term</td> : <td className="fw-bold">Long Term</td>
                    }
                    {
                        data !== null ? data.final_l_term !== null ?
                        <td className="text-center">{data.final_l_term}</td> :
                        <td className="text-center">-</td> : <td className="text-center">-</td>
                    }
                    {
                        <td className="text-center">{data !== null ?
                            data.previousLT[0].title : "-" }</td>
                    }
                </tr>
                <tr>
                    {
                        RatingTitle !== null ?
                        RatingTitle == "Performance Ranking"?
                        <td>1 Year</td> : <td className="fw-bold">Short Term</td> : <td className="fw-bold">Short Term</td>
                    }
                    {
                         data !== null ? data.final_s_term !== null ?
                        <td className="text-center">{data.final_s_term}</td> :
                        <td className="text-center">-</td> : <td className="text-center">-</td>
                    }
                    {
                        <td className="text-center">{data !== null ?
                            data.p_st_title : "-" }</td>
                    }
                </tr>
                {  data !== null ? data.final_5yr !== null ?
                    <tr>
                    <td className="fw-bold">5 Year</td>
                    <td className="text-center">{data.final_5yr}</td>
                    <td className="text-center">{data.p_fiveYr_title}</td>
                    </tr>
                    : null : null
                }
                <tr>
                    <td className="fw-bold">Outlook</td>
                    <td className="text-center">{data !== null ? data.final_outlook : null}</td>
                    <td className="text-center">{data !== null ? data.previousOutLook : null}</td>
                </tr>
                <tr>
                    <td className="fw-bold">Rating Watch</td>
                    <td className="text-center">{data !== null ? data.rc_data.length > 0 ? data.rc_data[0].final_rating_watch == "YES" ? "YES" : "-" : "-" : null}</td>
                    <td className="text-center">{data !== null ? data.previousRW == "YES" ? "YES" : "-" : "-"}</td>
                </tr>
            </tbody>
        </table>
        </div>

        <div className="mt-3" style={{overflow: "auto"}}>
        {data !== null ? 
        <table className='table table-secondary table-bordered border-dark'>
            <tbody>
                {<tr>
                    <td className="fw-bold" colSpan={data.daystoreviewDeadline < 0 ? 6 : 5}>Review Deadline</td>
                </tr>}
                <tr>
                    <td></td>
                    <td className="fw-bold">Previous</td>
                    <td className="fw-bold">Current</td>
                    <td className="fw-bold">Deadline</td>
                    <td className="fw-bold">DTD</td>
                    {data.daystoreviewDeadline < 0 && <td className="fw-bold">Reason for delay</td>}
                </tr>
                <tr>
                    <td className="fw-bold">Review</td>
                    <td>{data !== null ? data.previousRCDate :null}</td>
                    <td>{latestRec.length > 0 ? DateFormating(data !== null ? data.rc_data.length > 0 ? data.rc_data[0].date : null : null) :null}</td>
                    <td>{data !== null ? data.reviewDLDate :null}</td>
                    <td>{data !== null ? data.daystoreviewDeadline :null}</td>
                    {data.daystoreviewDeadline < 0 && <td rowSpan={2}><textarea style={{height:"100%", width:"100%"}} id="reason">{data.getPpl.reason_for_delay}</textarea></td>}
                </tr>
                <tr>
                    <td className="fw-bold">Dissemination</td>
                    <td>{data !== null ? data.previousDDate : null}</td>
                    <td>-</td>
                    <td>{data !== null ? data.disDLDate : null}</td>
                    <td>{data !== null ? data.daystoDisemDeadline : null}</td>
                </tr>
                <tr>
                    <td className="fw-bold">Decision</td>
                    <td colSpan={4}>No need to publicly disseminate.</td>
                    {data.daystoreviewDeadline < 0 && <td></td>}
                </tr>
            </tbody>
        </table>
         : null}
        </div>

        <div className='row mt-3'>
            <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                <div className='col-lg-8 col-md-8 col-sm-8 col-12 mx-auto'>
                <select className='form-select' id="s_name">
                <option value="19">Jhangeer Hanif</option>

                </select>
                </div>
            </div>
            <div className='col-lg-3 col-md-3 col-sm-3 col-12'>
                <select className='form-select' id="s_designation">
                    <option value="cro">Chief Rating Officer</option>
                </select>
            </div>
        </div>
        <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                        severity={alertType}
                        action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setalertopen(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        sx={{ mb: 2 }}
                      >
                        {AlertMessage}
                    </Alert>
                </Collapse>
          </Box>
        <div className='mt-3 mx-auto' style={{width: "fit-content"}}>
                {data !== null ? data.can_approve_ppl == 1 && data.getPpl.second_approve !== "Approved" && <button className="btn btn-primary btn-sm me-1" onClick={()=>{submit("Approved")}}>Approve</button> : null}
                {data !== null ? data.getPpl.second_approve !== "Approved" && <button className="btn btn-primary btn-sm me-1" onClick={()=>{submit("submit")}}>Save</button> : null}
                <button className="btn btn-primary btn-sm me-1" onClick={() => window.open(`${url}/api/printpreview/${client_id}/${og_rating_id}`, '_blank')}>Print Preview</button>
        </div>

        </div> :
          (<div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
          <div class="wrapper" id="loader2">
            <svg
              class="hourglass"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 120 206"
              preserveAspectRatio="none"
            >
              <path
                class="middle"
                d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
              />
              <path
                class="outer"
                d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
              />
            </svg>
          </div>
        </div>)}
    </div>
  )
}
