import React, {useState, useEffect, useRef} from 'react';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import axios from 'axios';
import { url } from '../../../../Config';
import { Link, useNavigate } from 'react-router-dom';



export default function Mandate_revise(props) {

    const [rating_types, setrating_types] = useState([]);
    const [clients, setclients] = useState([]);
    const [rating_scale, setrating_scale] = useState([]);
    const [hidescale, sethidescale] = useState(false);
    const clientref = useRef(null);
    const btnref = useRef(null);
    const rating_typeRef = useRef(null);
    const rating_scaleRef = useRef(null);

    
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();


    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/revisemandate`);
            const resp = res.data;
            setrating_types(resp.rating_types);

        }

        func();
    },[])

    const submitform = async (event)=>{
        event.preventDefault();
        try {
            
            if(clientref.current == null){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Client First!`);
                return ;
            }
            console.log(clientref.current.value, 'val');

            btnref.current.disabled = true;

            const res = await axios.post(`${url}/api/onrevisemandate`, {
                'approvedclients' : clientref.current.value

            })

            const resp = res.data;
            console.log(res, 'res');
            if(resp.status == true){
                btnref.current.disabled = false;
                setalertType("success");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
            else{
                btnref.current.disabled = false;
                setalertType("error");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
        } catch (error) {
            btnref.current.disabled = false;
            setalertType("error");
            setalertOpen(true);
            setalertmessage('some error occure while processing request!');
        }
    }


  return (
     <div style={{height: '90vh'}}>
        <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/bd_dashboard"} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Mandate Revised</li>
                </ol>
            </nav>
            <hr/>
        </div>
       <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            Revise Mandate
        </div>
        <form className='col-10 card shadow p-3 mx-auto mt-lg-4 mt-md-4 mt-2' onSubmit={(event)=>{submitform(event)}}>
            <div>
                <div className='mt-2 fw-bold'>
                    Rating Type:
                </div>
                <div className='mt-2'>
                    <select className='form-select' ref={rating_typeRef} onChange={async (e)=>{
                        if(e.target.value == ""){
                            sethidescale(false);
                            setrating_scale([]);
                            setclients([]);
                        }
                        else{
                            for(let i in rating_types){
                            
                                if(rating_types[i].id == e.target.value){
                                    setrating_scale(rating_types[i].rating_scale);
                                    const res = await axios.get(`${url}/api/getclients/${e.target.value}/${rating_types[i].rating_scale[0].id}`);
                                    const resp = res.data;
                                    setclients(resp.clients);
                                    sethidescale(true);
                                }
                            }
                        }
                        
                    }}>
                        <option value={''}>Select Rating Type</option>
                        {
                            rating_types.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Rating Scale:
                </div>
                <div className='mt-2'>
                    <select className='form-select' ref={rating_scaleRef} onChange={async (e)=>{
                            const res = await axios.get(`${url}/api/getclients/${rating_typeRef.current.value}/${e.target.value}`);
                            const resp = res.data;
                            setclients(resp.clients);
                    }}>
                        {hidescale == false && <option value={''}>Select Rating Scale</option>}
                        {
                            rating_scale.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            {clients.length > 0 && 
            <div>
                <div className='mt-3 fw-bold'>
                    Clients:
                </div>
                <div className='mt-2'>
                    <select className='form-select' ref={clientref}>
                        {
                            clients.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>}

            <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                    severity={alertType}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setalertOpen(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                    >
                    {AlertMessage}
                    </Alert>
                </Collapse>
            </Box>
            <div className='mt-4'>
                <button className='btn btn-primary' ref={btnref} type='submit'>Revise Mandate</button>
            </div>
        </form>
    </div>
  )
}
