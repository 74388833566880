import React, {useState, useEffect, useRef} from 'react';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import TableComponent from '../../Atoms/TableComponent';
import DeleteIcon from '@mui/icons-material/Delete';
import { Table, TableCell, TableRow, Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from "@mui/material/Skeleton";
import axios from 'axios';
import { url } from '../../../Config';
import Swal from "sweetalert2";



export default function Cluster(props) {

    const Navigate = useNavigate();
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [Update, setUpdate] = useState();



    // useEffect(()=>{
    //     if(localStorage.getItem('cluster') !== null){
    //         if(props.data.length == 0){
    //             setUpdate('no rows');
    //           }
    //           else{
    //             setUpdate(false);
    //           }
    //     }
    // },[])


    const deactivate = async (id)=>{
        Swal.fire({ title: 'Do you want to deactivate this Record?', 
        showDenyButton: false, showCancelButton: true, confirmButtonText: 'Delete'})
        .then(async(result) => {
            
          if (result.isConfirmed) {
            const res = await axios.get(`${url}/api/deactivate_cluster/${id}`);
            const resp = res.data;
            if(resp.status == true){
                // Navigate('/sector_study');
                props.setbool((prev_bool)=> !prev_bool);
                setalertType("success");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
            else{
                setalertType("error");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
          }
        })
        
    }

    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };

      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };

      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };

    const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: 'left',
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: 'left',
        },
        {
          headerName: "Cluster",
          minWidth: responsiveColumns(),
          field: "mainsector",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
        },
        {
            headerName: "Actions",
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            field: "priority",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer : (params)=>{
                return(
                    <>
                        <i className='fa-regular fa-pen-to-square fa-lg me-1' data-bs-toggle="tooltip" data-bs-placement="top" title="Edit Cluster" style={{color: '#ffc107'}} onClick={()=>{Navigate(`/edit-industry/${params.data.main_sector_id}`)}}></i>
                        <Tooltip title="Delete"><DeleteIcon sx={{color: 'red'}} onClick={()=>{deactivate(params.data.id)}}/></Tooltip>
                    </>
                )
            },
        },
    ]

    const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
        </h1>
      );
  return (
    <div>
    <Box className="mx-auto mb-1 mt-1" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
        </Box>
    <div>
        <div className='mx-auto mt-2'><div style={{width: "fit-content"}}><button className='btn btn-primary' onClick={()=>{Navigate('/add-industry')}}>Add New Cluster</button></div></div>
    </div>
      <TableComponent
      Data={props.data}
      screenWidth={props.screenWidth}
      MobViewRender={MobViewRender}
      columnDefs={columnDefs}
      datefilters={datefilters}
      datefilter={false}
    //   update = {Update}
      rowstatus={props.rowstatus}

       />
    </div>
  )
}
