import React,{useEffect, useState} from 'react';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import TableComponent from '../../Atoms/TableComponent';
import { url } from '../../../Config';
import axios from 'axios';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";

export default function Cob_to_rating_log(props) {

    const [data, setdata] = useState([]);
    const id = localStorage.getItem("userID");

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/cobToRatingsTransferLog/${id}`);
            const resp = res.data;
            if(id == "226" || id == "130"){
                setdata(resp.cobLog);
            }
            else{
                setdata(resp.logData);
            }
        }
        func();
    },[])

    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };
    
      function innerNotiDate(params) {
        if (params.data.notification_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.notification_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerFCDate(params) {
        if (params.data.file_closure_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.file_closure_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerOpenRequest(params) {
        if (params.data.request_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.request_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerApproval(params) {
        if (params.data.approval_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.approval_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerClosed(params) {
        if (params.data.closure_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.closure_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerreOpen(params) {
        if (params.data.reopen_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.reopen_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }


      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };
      const responsiveColumnPin2 = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "right";
        }
      };
      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };
    
      const columnDefs = [
            {
              headerName: "",
              field: "sNo",
              maxWidth: 30,
              filter: true,
              menuTabs: false,
              pinned: responsiveColumnPin(),
              hide: detailShow(),
              cellRenderer: "agGroupCellRenderer",
              suppressColumnsToolPanel: true,
              suppressFiltersToolPanel: true,
            },
            {
              headerName: "#",
              maxWidth: 50,
              // minWidth: 66,
              field: "sNo",
              sortable: true,
              filter: true,
              // filter: "agSetColumnFilter",
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
              suppressFiltersToolPanel: true,
              menuTabs: false,
              cellRenderer: cellrandered,
              pinned: responsiveColumnPin(),
            },
            {
              headerName: "Client Name",
              minWidth: responsiveColumns(),
              field: "clientName",
              sortable: true,
              filter: "agSetColumnFilter",
              excelMode: "windows",
              cellRenderer: cellrander,
              tooltipField: "name",
              pinned: responsiveColumnPin(),
            },
            {
              headerName: "Team",
              minWidth: responsiveColumns(),
              field: "manager",
              sortable: true,
              filter: "agSetColumnFilter",
              excelMode: "windows",
              hide: columnHide(),
              cellRenderer: cellrander,
              tooltipField: "RatingScale",
            },
            {
              headerName: "Analyst",
              field: "analyst",
              minWidth: 130,
              minWidth: 130,
              sortable: true,
              filter: "agSetColumnFilter",
              excelMode: "windows",
              tooltipField: "pre_lterm",
              hide: columnHide(),
              cellRenderer: cellrander,
              // pinned: responsiveColumnPin(),
            },
            {
              headerName: "Client Creation",
              field: "creation_date",
              minWidth: 115,
              hide: columnHide(),
              sortable: true,
              filter: "agDateColumnFilter",
              excelMode: "windows",
              cellRenderer: fullDate,
              debounceMs: "DateFilter",
              filterParams: {
                filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
                inRangeInclusive: true,
                comparator: function (filterLocalDateAtMidnight, cellValue) {
                  var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                  var dateParts = dateAsString.split("/");
                  var cellDate = new Date(
                    Number(dateParts[2]),
                    Number(dateParts[1]) - 1,
                    Number(dateParts[0])
                  );
        
                  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                    return 0;
                  }
        
                  if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                  }
        
                  if (cellDate > filterLocalDateAtMidnight) {
                    return 1;
                  }
                },
                buttons: ["clear", "reset", "apply"],
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    "  </div>" +
                    "</div>",
                },
              },
            },
            {
              headerName: "Transfer to TL",
              field: "to_manager_date",
              minWidth: 115,
              hide: columnHide(),
              sortable: true,
              filter: "agDateColumnFilter",
              excelMode: "windows",
              cellRenderer: fullDate,
              debounceMs: "DateFilter",
              filterParams: {
                filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
                inRangeInclusive: true,
                comparator: function (filterLocalDateAtMidnight, cellValue) {
                  var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                  var dateParts = dateAsString.split("/");
                  var cellDate = new Date(
                    Number(dateParts[2]),
                    Number(dateParts[1]) - 1,
                    Number(dateParts[0])
                  );
        
                  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                    return 0;
                  }
        
                  if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                  }
        
                  if (cellDate > filterLocalDateAtMidnight) {
                    return 1;
                  }
                },
                buttons: ["clear", "reset", "apply"],
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    "  </div>" +
                    "</div>",
                },
              },
            },
            {
                headerName: "Transfer to Analyst",
                minWidth: 200,
                autoHeight: true,
                wrapText: true,
                field: "to_analyst_date",
                sortable: true,
                filter: "agSetColumnFilter",
                excelMode: "windows",
                cellRenderer: (params)=>{
                    if(params.value == "" || params.value == null){
                        return("Not Transferred yet");
                    }
                    else{
                        return params.value;
                    }
                },
                tooltipField: "name",
                pinned: responsiveColumnPin2(),
            },
            
          ];

      const columnDefs2 = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Client Name",
          minWidth: responsiveColumns(),
          field: "clientName",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Team",
          minWidth: responsiveColumns(),
          field: "manager",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: cellrander,
          tooltipField: "RatingScale",
        },
        {
          headerName: "Analyst",
          field: "analyst",
          minWidth: 130,
          minWidth: 130,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          tooltipField: "pre_lterm",
          hide: columnHide(),
          cellRenderer: cellrander,
          // pinned: responsiveColumnPin(),
        },
        {
          headerName: "Client Creation",
          field: "creation_date",
          minWidth: 115,
          hide: columnHide(),
          sortable: true,
          filter: "agDateColumnFilter",
          excelMode: "windows",
          cellRenderer: fullDate,
          debounceMs: "DateFilter",
          filterParams: {
            filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
            inRangeInclusive: true,
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              var dateAsString = moment(cellValue).format("DD/MM/YYYY");
              var dateParts = dateAsString.split("/");
              var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );
    
              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }
    
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
    
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
            buttons: ["clear", "reset", "apply"],
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
          },
        },
        {
          headerName: "Transfer to TL",
          field: "to_manager_date",
          minWidth: 115,
          hide: columnHide(),
          sortable: true,
          filter: "agDateColumnFilter",
          excelMode: "windows",
          cellRenderer: fullDate,
          debounceMs: "DateFilter",
          filterParams: {
            filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
            inRangeInclusive: true,
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              var dateAsString = moment(cellValue).format("DD/MM/YYYY");
              var dateParts = dateAsString.split("/");
              var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );
    
              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }
    
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
    
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
            buttons: ["clear", "reset", "apply"],
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
          },
        },
        {
            headerName: "Transfer to Analyst",
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            field: "to_analyst_date",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
                if(params.value == "" || params.value == null){
                    return("Not Transferred yet");
                }
                else{
                    return params.value;
                }
            },
            tooltipField: "name",
            pinned: responsiveColumnPin2(),
        },
        {
            headerName: "Status",
            field: "status",
            minWidth: 130,
            minWidth: 130,
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: cellrander,
            // pinned: responsiveColumnPin(),
          },
        
      ];

      const MobViewRender = ()=>{
        
      }


  return (
    <div>
       { (id == "226" || id == "130") &&
        <TableComponent
            Data={data}
            screenWidth={props.screenWidth}
            MobViewRender={MobViewRender}
            columnDefs={columnDefs2}
            datefilters={datefilters}
            datefilter={false}
       />}
       {
        (id !== "226"&& id !== "130") &&
        <TableComponent
            Data={data}
            screenWidth={props.screenWidth}
            MobViewRender={MobViewRender}
            columnDefs={columnDefs}
            datefilters={datefilters}
            datefilter={false}
   />
       }
    </div>
  )
}
