import React,{useEffect, useState, useRef} from 'react';
import { url } from '../../../../../Config';
import Select from "react-select";
import axios from 'axios';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Swal from "sweetalert2";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ApprovalIcon from '@mui/icons-material/Approval';
import TableComponent from '../../../../Atoms/TableComponent';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";



export default function Support_approvals(props) {
    const userid = localStorage.getItem("userID");
    const [data, setdata] = useState([]);
    const [bool, setbool] = useState(false);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [alertopen2, setalertOpen2] = useState(false);
    const [alertType2, setalertType2] = useState();
    const [AlertMessage2, setalertmessage2] = useState();
    const [declinereason, setdeclinereason] = useState(Array(data.length));


    useEffect(()=>{
        setdata([])
        const func = async ()=>{
            const res = await axios.get(`${url}/api/cro_support_portfolio_record`);
            const resp = res.data;
            setdata(resp);
        }
        func();
      },[bool])


      const submit = async (opinion_id, portfolio_id, id, btn, p_id, ids2, reason, event)=>{
        event.preventDefault();
        if(document.getElementById(btn) !== null){
            document.getElementById(btn).innerHTML = "Please Wait...";
            document.getElementById(btn).disabled = true;
          }
        
        const fileData = new FormData();
        fileData.append("opinion_id", opinion_id);
        fileData.append("portfolio_id", portfolio_id);
        fileData.append('id', id);
        fileData.append("reason", reason);
        const res = await axios.post(`${url}/api/cro_decline`, 
        fileData,
        {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        }
        );
  
        const resp = res.data;

        if(resp.status == true){
            if(document.getElementById(ids2) !== null){
              document.getElementById(ids2).click();
            }
              setbool(!bool);
              setalertType("success");
              setalertOpen(true);
              setalertmessage(resp.message);
          }
          else{
            if(document.getElementById(btn) !== null){
              document.getElementById(btn).innerHTML = "Submit";
              document.getElementById(btn).disabled = false;
            }
              setalertType("error");
              setalertOpen(true);
              setalertmessage('Some error occure while processing your request');
          }
      }

      const approval = async (opinion_id, portfolio_id, id)=>{
        Swal.fire({ title: 'Approve Request?', 
        showDenyButton: false, showCancelButton: true, confirmButtonText: 'Confirm'})
        .then(async(result) => {
            if (result.isConfirmed) {
                const res = await axios.post(`${url}/api/cro_approval`,{
                    'opinion_id': opinion_id,
                    'portfolio_id': portfolio_id,
                    'id': id
                });
        
                const resp = res.data;
                if(resp.status == true){
                    setbool(!bool);
                    setalertType("success");
                    setalertOpen(true);
                    setalertmessage(resp.message);
                }
                else{
                    setalertType2("error");
                    setalertOpen2(true);
                    setalertmessage2("Some Error Occure While Processing your Request");
                }
            }
        })

      }
  
    
      const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };
  
      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };
    
      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
    
      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
    
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };
    
    const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Opinion",
          minWidth: 250,
          field: "opinion_name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "opinion_name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Team Lead",
          minWidth: 150,
          field: "team_lead_name",
          sortable: true,
          hide: columnHide(),
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "team_lead_name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Reason",
          field: "reason_for_support_analyst",
          minWidth: responsiveColumns(),
          hide: columnHide(),
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: (params)=>{
            return(
                <Tooltip title="View"><VisibilityIcon sx={{color: 'blue'}} data-bs-toggle="modal" data-bs-target={`#reason${params.data.opinion_id}`}/></Tooltip>
            )
          },
          // pinned: responsiveColumnPin(),
        },
    
        {
            headerName: "Action",
            minWidth: 150,
            field: "",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
                return(
                    <>
                      <Tooltip title='Decline'><ThumbDownAltIcon className='me-1' sx={{color: 'red'}} data-bs-toggle="modal" data-bs-target={`#reason-decline${params.data.opinion_id}`}/></Tooltip>
                      <Tooltip title='Approve'><ApprovalIcon sx={{color: 'green'}} onClick={()=>{approval(params.data.opinion_id, params.data.portfolio_id, params.data.id)}}/></Tooltip>
                    </>
                  )
            },
        },
             
      ];
  
    const MobViewRender = (params) => (
          <h1 style={{ padding: "10px 20px" }}>
            <Table className="overflow-scroll responsiveTableFonts">
              <TableRow>
                <TableCell
                  variant="head"
                  className="fw-bolder responsiveTableFonts py-1"
                >
                  Team Lead
                </TableCell>
                <TableCell className="responsiveTableFonts py-1">
                  {params.data.team_lead_name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  variant="head"
                  className="fw-bolder responsiveTableFonts py-1"
                >
                  Reason
                </TableCell>
                <TableCell className="responsiveTableFonts py-1">
                  {params.data.reason_for_support_analyst}
                </TableCell>
              </TableRow>
            </Table>
          </h1>
        );


  return (
    <div>

        <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
        </Box>

        {
                  data.map((value, index)=>{
                    return(
                    <>
                      <form className="modal fade" id={`reason-decline${value.opinion_id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
                      onSubmit={(event)=>{submit(value.opinion_id, value.portfolio_id, value.id, `declinebtn${index}`, value.id, `reasondeclineclose${index}`, declinereason[index], event)}}>
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Reason to Decline : {value.opinion_name}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                      <Box className="mx-auto" sx={{ width: "98%" }}>
                                        <Collapse in={alertopen2}>
                                            <Alert
                                            severity={alertType2}
                                            action={
                                                <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setalertOpen2(false);
                                                }}
                                                >
                                                <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                            sx={{ mb: 2 }}
                                            >
                                            {AlertMessage2}
                                            </Alert>
                                        </Collapse>
                                      </Box>
                                <div className='fw-bold'>
                                  Reason
                                </div>
                                <div>
                                  <textarea className='form-control'  style={{height: "100%"}} onChange={(e)=>{
                                    declinereason[index] = e.target.value;
                                    setdeclinereason(declinereason);
                                  }} required></textarea>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id={`reasondeclineclose${index}`} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" id={`declinebtn${index}`}>Submit</button>
                              </div>
                            </div>
                          </div>
                      </form>
                      <div className="modal fade" id={`reason${value.opinion_id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Reason</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                               <div>
                                {value.reason_for_support_analyst}
                               </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    </>
                    )
                  })
        }

        <TableComponent
                Data={data}
                screenWidth={props.screenWidth}
                MobViewRender={MobViewRender}
                columnDefs={columnDefs}
                datefilters={datefilters}
                datefilter={false}
            />
    </div>
  )
}
