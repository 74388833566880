import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios';
import { url } from '../../../Config';
import Select from "react-select";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { useNavigate } from 'react-router-dom';


export default function Create_policy() {

    const [source, setsource] = useState([]);
    const [classification, setclassification] = useState([]);
    const [law, setlaw] = useState([]);
    const [selectedlaw, setselectedlaw] = useState();
    const [Dissemination, setDissemination] = useState([]);
    const [Applicability, setApplicability] = useState([]);
    const [selectedApplicability, setselectedApplicability] = useState();
    const [scopehr, setscopehr] = useState([]);
    const [scopebd, setscopebd] = useState([]);
    const [scopebod, setscopebod] = useState([]);
    const [scoperat, setscoperat] = useState([]);
    const [scopeguide, setscopeguide] = useState([]);
    const [naturecc, setnaturecc] = useState([]);
    const [naturecoi, setnaturecoi] = useState([]);
    const [natureic, setnatureic] = useState([]);
    const [naturerat, setnaturerat] = useState([]);
    const [lawid, setlawid] = useState(null);
    const [Applicabilityid, setApplicabilityid] = useState(null);
    const [sourceid, setsourceid] = useState(0);
    const [classificationid, setclassificationid] = useState(0);
    const [scopeid, setscopeid] = useState(0);
    const [doctype, setdoctype] = useState("");
    const [reviewDate, setreviewDate] = useState(null);
    const [effectiveDate, seteffectiveDate] = useState(null);
    const [disseminationid, setdisseminationid] = useState(0);
    const [policydocpdf, setpolicydocpdf] = useState(null);
    const [policydocword, setpolicydocword] = useState(null);
    const [minutespdf , setminutespdf] = useState(null); 
    const [minutesword , setminutesword] = useState(null); 
    const [bodFile, setbodFile] = useState(null);
    const Navigate = useNavigate();

    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const id = localStorage.getItem("userID");


    const scopeRef = useRef(null);
    const natureRef = useRef(null);
    const doctypeRef = useRef(null);
    const reviewDateRef = useRef(null);
    const lawRef = useRef(null);
    const clauseRef = useRef(null);
    const disseminationRef = useRef(null);
    const applicabilityRef = useRef(null);
    const minutesRef = useRef(null);
    const bodRef = useRef(null);
    const clauseValueRef = useRef(null);
    const reviewDatevalRef = useRef(null);
    const effectiveDatevalRef = useRef(null);
    const minutesfileRef = useRef(null);
    const minuteswordfileRef = useRef(null);
    const BoDfileRef = useRef(null);

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/createPg`);
            const resp = res.data;
            const law = resp.law;
            const lawoptions = law.map(item => ({ value: item.id, label: item.title }));
            const appli = resp.applicability;
            const applicability_option = appli.map(item => ({ value: item.id, label: item.title }));
            setsource(resp.rating_agency);
            setclassification(resp.classification)
            setlaw(lawoptions);
            setDissemination(resp.dissemination);
            setApplicability(applicability_option);
            setscopehr(resp.scopehr);
            setscopebd(resp.scopebd);
            setscopebod(resp.scopebod);
            setscoperat(resp.scoperat);
            setscopeguide(resp.scopeguide);
            setnaturecc(resp.naturecc);
            setnaturecoi(resp.naturecoi);
            setnatureic(resp.natureic);
            setnaturerat(resp.naturerat);
        }

        func();
    },[])
    
    const handlelaw = (data)=>{
        
        const arr = [];
        for(let i in data){
            arr.push(data[i].value);
        }

        const stringifyArray = arr.join(",");
        setselectedlaw(data);
        setlawid(stringifyArray);

    }
    const handleApplicability = (data)=>{
        const arr = [];
        for(let i in data){
            arr.push(data[i].value);
        }

        const stringifyArray = arr.join(",");
        setselectedApplicability(data);
        setApplicabilityid(stringifyArray);
    }

    const storepg = async (event)=>{

        event.preventDefault();

        if(sourceid == 0 || sourceid == "0"){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Option from Source First!`);
            return ;
        }

        else if(sourceid == 1){

            if(classificationid == 0 || classificationid == '0'){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Classification First!`);
                return ;
            }
            if(scopeid == 0 || scopeid == '0'){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Scope/Nature First!`);
                return ;
            }
            if(doctype == ''){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Document Type First!`);
                return ;
            }

            if(lawid == null || lawid == ''){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Law First!`);
                return ;
            }

            if(Applicabilityid == null || Applicabilityid == ''){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Applicability Type First!`);
                return ;
            }
            

            const fileData = new FormData();
            fileData.append("userid", id);
            fileData.append("ratings_agencies_id", sourceid);
            fileData.append("pacra_policy_classification_id", classificationid);
            fileData.append("pacra_policy_category_new_id", scopeid);
            fileData.append("doc_type", doctype);
            fileData.append("review_date", reviewDate);
            fileData.append("effective_date", effectiveDate);
            fileData.append("pacra_policy_law_id", lawid);
            fileData.append("clause", clauseValueRef.current.value);
            fileData.append("og_pacra_security_id", disseminationid);
            fileData.append("pacra_policy_applicability_id", Applicabilityid);
            fileData.append("policy_document", policydocpdf);
            fileData.append("policy_document_word", policydocword);
            fileData.append("minutes", minutespdf);
            fileData.append("minutes_word", minutesword);
            fileData.append("bod_approval", bodFile);

            const res = await axios.post(`${url}/api/storePg`,
                fileData,
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
            );


            const resp = res.data;
            console.log(resp, 'response');
            // if(resp.status == true){
            //     Navigate('/p&g');
            // }
            // else{
            //     setalertType("error");
            //     setalertOpen(true);
            //     setalertmessage(resp.message);
            // }
        }

        else{
            if(classificationid == 0 || classificationid == '0'){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Classification First!`);
                return ;
            }
            if(scopeid == 0 || scopeid == '0'){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Scope/Nature First!`);
                return ;
            }
            

            const fileData = new FormData();
            fileData.append("ratings_agencies_id", sourceid);
            fileData.append("pacra_policy_classification_id", classificationid);
            fileData.append("pacra_policy_category_new_id", scopeid);
            fileData.append("effective_date", effectiveDate);
            fileData.append("policy_document", policydocpdf);
            fileData.append("policy_document_word", policydocword);
            fileData.append("userid", id);



            const res = await axios.post(`${url}/api/storePg`,
                fileData,
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
            );
            const resp = res.data;
            console.log(resp, 'response')
            // if(resp.status == true){
            //     Navigate('/p&g');
            // }
            // else{
            //     setalertType("error");
            //     setalertOpen(true);
            //     setalertmessage(resp.message);
            // }
        }

    }


  return (
    <div>
      <div className='mt-2 text-center mb-2 fw-bold fs-3' style={{color:"#000078"}}>
        Add New P & G
      </div>
      <hr />
      <form className='card shadow col-10 mx-auto mt-1 p-3' onSubmit={(event)=>{storepg(event)}}>
        <div className='fw-bold fs-6'>
            Source
        </div>
        <div className='col-11'>
            <select className='form-control' onChange={(e)=>{
                if(e.target.value == 1 || e.target.value == 0){
                    doctypeRef.current.hidden = false;
                    reviewDateRef.current.hidden = false;
                    lawRef.current.hidden = false;
                    clauseRef.current.hidden = false;
                    disseminationRef.current.hidden = false;
                    applicabilityRef.current.hidden = false;
                    minutesRef.current.hidden = false;
                    bodRef.current.hidden = false;
                    reviewDatevalRef.current.required = true;
                    effectiveDatevalRef.current.required = true;
                    minutesfileRef.current.required = true;
                    minuteswordfileRef.current.required = true;
                    BoDfileRef.current.required = true;
                    
                }
                else{

                    doctypeRef.current.hidden = true;
                    reviewDateRef.current.hidden = true;
                    lawRef.current.hidden = true;
                    clauseRef.current.hidden = true;
                    disseminationRef.current.hidden = true;
                    applicabilityRef.current.hidden = true;
                    minutesRef.current.hidden = true;
                    bodRef.current.hidden = true;
                    reviewDatevalRef.current.required = false;
                    effectiveDatevalRef.current.required = true;
                    minutesfileRef.current.required = false;
                    minuteswordfileRef.current.required = false;
                    BoDfileRef.current.required = false;
                }

                setsourceid(e.target.value);
            }}>
                <option value={0}>Select Source</option>
                { 
                    source.map((value, index)=>{
                        return(
                            <option value={value.id}>{value.name}</option>
                        )
                    })
                }
            </select>
        </div>

        <div className='fw-bold fs-6 mt-4'>
            Classification
        </div>
        <div className='col-11'>
            <select className='form-control' onChange={(e)=>{
                if(e.target.value == 1){
                    scopeRef.current.hidden = false;
                    natureRef.current.hidden = true;
                }
                else if(e.target.value == 2){
                    scopeRef.current.hidden = true;
                    natureRef.current.hidden = false;
                }
                else{
                    scopeRef.current.hidden = true;
                    natureRef.current.hidden = true;
                }
                setscopeid(0);
                setclassificationid(e.target.value);
            }}>
                <option value={0}>Select Classification</option>
                { 
                    classification.map((value, index)=>{
                        return(
                            <option value={value.id}>{value.title}</option>
                        )
                    })
                }
            </select>
        </div>

        <div ref={scopeRef} hidden={true}>
            <div className='fw-bold fs-6 mt-4'>
                Scope
            </div>
            <div className='col-11'>
                <select className='form-control' value={scopeid} onChange={(e)=>{setscopeid(e.target.value)}}>
                    <option value={0}>Select Scope</option>
                    <optgroup label='HR'>
                    { 
                        scopehr.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>
                    
                    <optgroup label='Business Development'>
                    { 
                        scopebd.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>

                    <optgroup label='Rating'>
                    { 
                        scoperat.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>

                    <optgroup label='BOD'>
                    { 
                        scopebod.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>

                    <optgroup label='Guideline'>
                    { 
                        scopeguide.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>
                    
                </select>
            </div>
        </div>

        <div ref={natureRef} hidden={true}>
            <div className='fw-bold fs-6 mt-4'>
                Nature
            </div>
            <div className='col-11'>
                <select className='form-control' value={scopeid} onChange={(e)=>{setscopeid(e.target.value)}}>
                    <option value={0}>Select Nature</option>
                    <optgroup label='Code of Conduct'>
                    { 
                        naturecc.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>
                    
                    <optgroup label='Conflict of Interest'>
                    { 
                        naturecoi.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>

                    <optgroup label='Internal Controls'>
                    { 
                        natureic.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>

                    <optgroup label='Rating'>
                    { 
                        naturerat.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.category_title}</option>
                            )
                        })
                    }
                    </optgroup>
                    
                </select>
            </div>
        </div>

        <div ref={doctypeRef}>
            <div className='fw-bold fs-6 mt-4'>
                Document Type
            </div>
            <div className='col-11'>
                <select className='form-control' onChange={(e)=>{setdoctype(e.target.value)}}>
                    <option value="">Select Classification</option>
                    <option value="Policy">Policy</option>
                    <option value="Guidelines">Guidelines</option>
                    <option value="Policy_Annexure_H">Policy–Annexure H</option>
                    <option value="Policy_Internal_Control_Policy">Policy–Internal Control Policy</option>
                    <option value="TORs">TORs</option>
                    <option value="Code_of_Conduct">Code of Conduct</option>
                    <option value="Forms">Forms</option>
                </select>
            </div>
        </div>
        
        <div ref={reviewDateRef}>
            <div className='fw-bold fs-6 mt-4'>
                Review Date
            </div>
            <div className='col-11'>
                <input className='form-control' type='date' ref={reviewDatevalRef} onChange={(e)=>{setreviewDate(e.target.value)}}/>
            </div>
        </div>
        
        <div>
            <div className='fw-bold fs-6 mt-4'>
                Applicability / Effective Date
            </div>
            <div className='col-11'>
                <input className='form-control' type='date' ref={effectiveDatevalRef} onChange={(e)=>{seteffectiveDate(e.target.value)}} />
            </div>
        </div>

        <div ref={lawRef}>
            <div className='fw-bold fs-6 mt-4'>
                Law
            </div>
            <div className='col-11'>
                    <Select
                        options={law}
                        placeholder="Select Opinion"
                        value={selectedlaw}
                        onChange={handlelaw}
                        isSearchable={true}
                        isMulti
                        />
            </div>
        </div>
        
        <div ref={clauseRef}>
            <div className='fw-bold fs-6 mt-4'>
                Clause
            </div>
            <div className='col-11'>
                <textarea className='form-control' ref={clauseValueRef} maxLength={400} placeholder='You can Type Maximum 400 characters here'/>
            </div>
        </div>
        
        <div ref={disseminationRef}>
            <div className='fw-bold fs-6 mt-4'>
                Dissemination
            </div>
            <div className='col-11'>
            <select className='form-control' onChange={(e)=>{setdisseminationid(e.target.value)}}>
                    <option value={0}>Select Source</option>
                    { 
                        Dissemination.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.title}</option>
                            )
                        })
                    }
                </select>
            </div>
        </div>

        <div ref={applicabilityRef}>
            <div className='fw-bold fs-6 mt-4'>
                Applicability
            </div>
            <div className='col-11'>
                    <Select
                        options={Applicability}
                        placeholder="Select Opinion"
                        value={selectedApplicability}
                        onChange={handleApplicability}
                        isSearchable={true}
                        isMulti
                        />
            </div>

        </div>

        <div className='fw-bold fs-6 mt-4'>
            Policy Document
        </div>
        <div className='col-11'>
            <input className='form-control' type='file' accept="application/pdf" onChange={(e)=>{
                setpolicydocpdf(e.target.files[0]);
            }} required/>
            <div style={{fontSize: "13px"}}>
                Please upload a valid pdf file.
            </div>
        </div>
        <div className='col-11 mt-2'>
            <input className='form-control' type='file' accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e)=>{setpolicydocword(e.target.files[0])}} required/>
            <div style={{fontSize: "13px"}}>
                Please upload a valid word file.
            </div>
        </div>
        
        <div ref={minutesRef}>
            <div className='fw-bold fs-6 mt-4'>
                Minutes
            </div>
            <div className='col-11'>
                <input className='form-control' type='file' accept="application/pdf" onChange={(e)=>{setminutespdf(e.target.files[0])}} ref={minutesfileRef} required/>
                <div style={{fontSize: "13px"}}>
                    Please upload a valid pdf file.
                </div>
            </div>
            <div className='col-11 mt-2'>
                <input className='form-control' type='file' accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document" ref={minuteswordfileRef} onChange={(e)=>{setminutesword(e.target.files[0])}} required/>
                <div style={{fontSize: "13px"}}>
                    Please upload a valid word file.
                </div>
            </div>
        </div>
        
        <div ref={bodRef}>
            <div className='fw-bold fs-6 mt-4'>
                BOD Approvals
            </div>
            <div className='col-11'>
                <input className='form-control' type='file' accept="application/pdf" onChange={(e)=>{setbodFile(e.target.files[0])}} ref={BoDfileRef} required/>
                <div style={{fontSize: "13px"}}>
                    Please upload a valid pdf.
                </div>
            </div>
        </div>

        <div className='mt-1 mb-2'>
            <Box className="mx-auto" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
            </Box>
        </div>

        <div className='mt-5' style={{width: 'fit-content'}}>
            <button className='btn btn-primary' type='submit'>Save</button>
        </div>
      </form>
    </div>
  )
}
