import React, { useEffect, useState } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import Logo from "../../../Assets/Images/PACRA_white.png";

export default function ConsultanciesProvided() {
  const [startdate, setstartdate] = useState();
  const [enddate, setenddate] = useState();

  function DateFormat(params) {
    if (params == null) {
      return "-";
    } else {
      const date = new Date(params);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }

  const func = async () => {
    if(startdate != null && startdate != "" && enddate != null && enddate != ""){
      axios({
        url: `https://209.97.168.200/pacrawizpackv3/public/api/consultancyprovidedpdfApi/${startdate}/${enddate}`,
        method: 'Get',
        responseType: 'blob',
      }).then(response => {
        const file = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      }).catch(error => console.error(error))
    }
    else{
      alert("enter valid date")
    }
  };

  return (
    <div>
    <p className="mx-auto p-3 fs-3 fw-bold text-capitalize" style={{ width: "fit-content", color:"#000078" }}>
    C-iv | Detail of Consultancy and Advisory Services provided by rating agency
    </p>      
    <div className="row w-100">
      <div
        className="card shadow mx-auto col-lg-10 col-md-10 col-sm-10 col-11 pt-4"
        style={{ height: "auto", backgroundColor: "#e0e0e0" }}
      >

        <div className="row w-100 pb-3">
            <div className="col-lg-5 col-md-5 ms-lg-4 ms-md-2 ms-sm-3 col-sm-11 col-11 ms-2">
            <input
              type="date"
              style={{height: "38px"}}
              className="form-control"
              onChange={(e) => {
                setstartdate(e.target.value);
              }}
            />
            </div>
            <div className="col-lg-5 col-md-5 ms-md-1 pt-sm-1 pt-md-0 col-sm-11 ms-sm-3 col-11 ms-2 pt-1">
            <input
              type="date"
              style={{height: "38px"}}
              className="form-control"
              onChange={(e) => {
                setenddate(e.target.value);
              }}
            />
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 pt-sm-1 pt-md-0 ms-md-1 mx-sm-auto col-1 pt-1 mx-auto">
            <button type="button" className="btn btn-primary h-60" onClick={func} >
            Submit
          </button>
            </div>
        </div>
      </div>
    </div>
  </div>
  );
}
