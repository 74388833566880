import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { url } from '../../../../Config';

export default function RR_krd() {
    const [data, setdata] = useState(null);
    const [editit, seteditit] = useState(false);
    const [editdata, seteditdata] = useState(null);
    const [Title, setTitle] = useState();
    const [RatingRationale, setRatingRationale] = useState();
    const [KeyRatingDrivers, setKeyRatingDrivers] = useState();
    const [AboutTheEntity, setAboutTheEntity] = useState();
    const [AboutTheInstrument, setAboutTheInstrument] = useState();
    const [editTitle, seteditTitle] = useState();
    const [editRatingRationale, seteditRatingRationale] = useState();
    const [editKeyRatingDrivers, seteditKeyRatingDrivers] = useState();
    const [editAboutTheEntity, seteditAboutTheEntity] = useState();
    const [editAboutTheInstrument, seteditAboutTheInstrument] = useState();
    const [alertopen, setalertopen] = useState(false);
    const [AlertMessage, setAlertMessage] = useState(null);
    const [rating_id, setrating_id] = useState(null);
    const [alertType, setalertType] = useState("success");
    const { client_id, og_rating_id, status, r_id } = useParams();
    
    const Navigate = useNavigate();

    useEffect(()=>{
        const func = async ()=>{
            if(status == 'add'){
                const res = await axios.get(`${url}/api/getRR_KrdData/${client_id}/${og_rating_id}`);
                if(res.data.message == 'Record Already Exist'){
                    Navigate(`/Ratings/rr_krd/${client_id}/${og_rating_id}/edit/${r_id}`);
                    seteditit(!editit);
                }
                else{
                    setdata(res.data);
                    // setrating_id(r_id);
                }
            }

            if(status == 'edit'){
                const res = await axios.get(`${url}/api/editRR_Krd/${client_id}/${og_rating_id}`);
                const resp = res.data;
                if(resp.message == "Data not added yet"){
                    Navigate(`/Ratings/rr_krd/${client_id}/${og_rating_id}/add/${r_id}`);
                    seteditit(!editit);
                }
                else{
                    // setrating_id(r_id);
                    seteditdata(resp);
                    seteditTitle(resp.title);
                    seteditRatingRationale(resp.rr_data.rating_rationale_text);
                    seteditKeyRatingDrivers(resp.rr_data.krd_text);
                    seteditAboutTheEntity(resp.rr_data.entity_text);
                    seteditAboutTheInstrument(resp.rr_data.instrument_text);
                }
            }
        }
        func();
    },[editit, status]) 

    const submit = async (event)=>{
        event.preventDefault();
        const res = await axios.post(`${url}/api/addRR_Krd`, {
            'opinion_id' : client_id,
            'recordId' : og_rating_id,
            'record_id' : og_rating_id,
            'rating_rationale' : RatingRationale,
            'key_rating_driver' : KeyRatingDrivers,
            'about_the_entity' : AboutTheEntity,
            'rating_scale_name' : data.ratingScaleName,
            'about_the_instrument' : AboutTheInstrument,
            'rrId' : og_rating_id,
            'analyst_id' : data.analystId,
            'manager_id' : data.manager_id,
            'lead_rc_id' : data.lead_rc_id,
            'press_release_title' : Title,
            'opName' : data.opName,
            'rating_type_id' : data.ratingTypeId,
            'rating_scale_id' : data.ratingScaleId,
            'type' : 'rr_krd'
        });

        if(res.data.status == true){
            Navigate(`/Ratings/rr_krd/${client_id}/${og_rating_id}/edit/${r_id}`);
            seteditit(!editit);
            setalertType("success");
            setAlertMessage("Successfully Added");
            setalertopen(true);
        }

        else{
             setalertType("error");
            setAlertMessage("An Error occure while Processing Your Request");
            setalertopen(true);
        }
    }

    const edit = async (event)=>{
        event.preventDefault();
        const res = await axios.post(`${url}/api/updateRR_Krd`, {
            'opinion_id' : client_id,
            'record_id' : og_rating_id,
            'rating_rationale' : editRatingRationale,
            'key_rating_driver' : editKeyRatingDrivers,
            'about_the_entity' : editAboutTheEntity,
            'about_the_instrument' : editAboutTheInstrument,
            'press_release_title' : editTitle,
        });

        if(res.data.status == true){
            setalertType("success");
            setAlertMessage("Successfully Update Data");
            setalertopen(true);
        }
        else{
            setalertType("error");
            setAlertMessage("An error occure while process your request");
            setalertopen(true);
        }
    }

  return (
    <div style={{height: "90vh"}}>
        <br />
        <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                        severity={alertType}
                        action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setalertopen(alertopen);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        sx={{ mb: 2 }}
                      >
                        {AlertMessage}
                    </Alert>
                </Collapse>
        </Box>
      {status == 'add' && 
      <form className='card shadow col-11 mx-auto p-2' style={{backgroundColor: "whitesmoke"}} onSubmit={submit}>
        <div className='text-center text-decoration-underline fw-bold fs-5' style={{ color: "#000078", fontFamily: "Kalinga" }}>
            Rating Rationale & Key Rating Drivers
        </div>
        <div className='text-center text-decoration-underline fw-bold fs-5 mt-2' style={{ color: "#000078", fontFamily: "Kalinga" }}>
            {data !== null ? data.opName : null}
        </div>
        <div className='col-11 mx-auto mt-2'>
            <input className='form-control' placeholder='Enter Press Release Title' value={Title}  onChange={(e)=>{setTitle(e.target.value)}} required />
        </div>
        <hr />
        <div className='col-11 mx-auto'>
            <div className='mt-1'>
                <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Rating Rationale:</label>
                <div>
                    <textarea className='form-control' value={RatingRationale} onChange={(e)=>{setRatingRationale(e.target.value)}} required/>
                </div>
            </div>
            <div className='mt-1'>
                <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Key Rating Drivers:</label>
                <div>
                    <textarea className='form-control' value={KeyRatingDrivers} onChange={(e)=>{setKeyRatingDrivers(e.target.value)}} required/>
                </div>
            </div>
            <div className='mt-1'>
                <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>About the Entity:</label>
                <div>
                    <textarea className='form-control' value={AboutTheEntity} onChange={(e)=>{setAboutTheEntity(e.target.value)}} required />
                </div>
            </div>
            {r_id == "4" && <div className='mt-1'>
                <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>About the Instrument:</label>
                <div>
                    <textarea className='form-control' value={AboutTheInstrument}  onChange={(e)=>{setAboutTheInstrument(e.target.value)}} />
                </div>
            </div>}
            <div className='mx-auto mt-2' style={{width: "fit-content"}}>
                <button className='btn btn-primary btn-sm' type='submit'>Save</button>
            </div>
        </div>
      </form>}
      {status == 'edit' &&
      <form className='card shadow col-11 mx-auto p-2' style={{backgroundColor: "whitesmoke"}} onSubmit={edit}>
      <div className='text-center text-decoration-underline fw-bold fs-5' style={{ color: "#000078", fontFamily: "Kalinga" }}>
          Rating Rationale & Key Rating Drivers
      </div>
      <div className='text-center text-decoration-underline fw-bold fs-5 mt-2' style={{ color: "#000078", fontFamily: "Kalinga" }}>
            {editdata !== null ? editdata.opName : null}
      </div>
      <div className='col-11 mx-auto mt-2'>
          <input className='form-control' placeholder='Enter Press Release Title' value={editTitle}  onChange={(e)=>{seteditTitle(e.target.value)}} required />
      </div>
      <hr />
      <div className='col-11 mx-auto'>
          <div className='mt-1'>
              <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Rating Rationale:</label>
              <div>
                  <textarea className='form-control' value={editRatingRationale} onChange={(e)=>{seteditRatingRationale(e.target.value)}} required/>
              </div>
          </div>
          <div className='mt-1'>
              <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Key Rating Drivers:</label>
              <div>
                  <textarea className='form-control' value={editKeyRatingDrivers} onChange={(e)=>{seteditKeyRatingDrivers(e.target.value)}} required/>
              </div>
          </div>
          <div className='mt-1'>
              <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>About the Entity:</label>
              <div>
                  <textarea className='form-control' value={editAboutTheEntity} onChange={(e)=>{seteditAboutTheEntity(e.target.value)}} required />
              </div>
          </div>
          {r_id == "4" && <div className='mt-1'>
              <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>About the Instrument:</label>
              <div>
                  <textarea className='form-control' value={editAboutTheInstrument}  onChange={(e)=>{seteditAboutTheInstrument(e.target.value)}} />
              </div>
          </div>}
          <div className='mx-auto mt-2' style={{width: "fit-content"}}>
              <button className='btn btn-primary btn-sm' type='submit'>Update</button>
          </div>
      </div>
      </form>}
    </div>
  )
}
