import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { url } from '../../../../Config';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import TableComponent from '../../../Atoms/TableComponent';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import ApprovalIcon from '@mui/icons-material/Approval';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";



export default function Client_entry_index(props) {

    const id = localStorage.getItem("userID");
    const [data, setdata] = useState([]);
    const [bool, setbool] = useState(false);
    const [can_approve_mandate, setcan_approve_mandate] = useState(null)
    const Navigate = useNavigate();
    const groupref = useRef(null);

    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [alertopen2, setalertOpen2] = useState(false);
    const [alertType2, setalertType2] = useState();
    const [AlertMessage2, setalertmessage2] = useState();

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/get_client_entery/${id}`);
            const resp = res.data;
            setdata(resp.pacraClients);
            setcan_approve_mandate(resp.can_approve_mandate);
        }

        func();
    },[bool])

    const approve = async (client_id)=>{

            const res = await axios.post(`${url}/api/approveClient`,{
                'user_id' : id,
                'client_id' : Number(client_id)
            });
        const resp = res.data;

        if(resp.status == true){
            setbool(!bool);
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
       
    }

    const addgroup = async()=>{
        const res = await axios.post(`${url}/api/insertGroup`,{
            'groupname' : groupref.current.value,
            'login_user_id': Number(id)
        })
        const resp = res.data;

        if(resp.status == true){
            setalertType2("success");
            setalertOpen2(true);
            setalertmessage2(resp.message);
        }
        else{
            setalertType2("error");
            setalertOpen2(true);
            setalertmessage2(resp.message);
        }
    }

    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };
    
      function innerDate(params) {
        if (params.data.updated_at == null) {
          return "-";
        } else {
          const date = new Date(params.data.updated_at);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };

      const responsiveColumnPin2 = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "right";
        }
      };

      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };

      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };
    
      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Name",
          minWidth: responsiveColumns(),
          field: "title",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "title",
          pinned: responsiveColumnPin(),
        },
        {
            headerName: "Date",
            field: "updated_at",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            hide: columnHide(),
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },
        {
            headerName: "Actions",
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            field: "status",
            sortable: true,
            filter: "agSetColumnFilter",
            autoHeight: true,
            excelMode: "windows",
            cellRenderer: (params)=>{
                if(can_approve_mandate == 1 && params.value !='Approved'){
                    return(
                        <div>
                            <div>
                            <Tooltip title="Add/Edit Info"><button className='btn btn-warning btn-sm me-1 mb-1' onClick={()=>Navigate(`/edit-client/${params.data.id}`)}><EditIcon /></button></Tooltip>
                            <Tooltip title="Approve"><button className='btn btn-success btn-sm me-1 mb-1' onClick={()=>{approve(params.data.id)}}><ApprovalIcon /></button></Tooltip>

                            </div>
                        </div>
                    )
                }
                else if(params.value =='Approved'){
                        return(
                            <div>
                                <div>
                                <Tooltip title="Add/Edit Info"><button className='btn btn-warning btn-sm me-1 mb-1' onClick={()=>Navigate(`/edit-client/${params.data.id}`)}><EditIcon /></button></Tooltip>
                                </div>
                            </div>
                        )

                }
                else{
                    return(
                        <div>
                            <div>
                            <Tooltip title="Add/Edit Info"><button className='btn btn-warning btn-sm me-1 mb-1' onClick={()=>Navigate(`/edit-client/${params.data.id}`)}><EditIcon /></button></Tooltip>
                            </div>
                        </div>
                    )
                }
            },
            pinned: responsiveColumnPin2(),
        },
        
      ];


      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
        </h1>
      );

  return (
    <div>
      <div className='' style={{color:"#000078"}}>
        <button className='btn btn-primary me-1 mt-1 ms-1' onClick={()=>{Navigate('/add-new-client')}}>Add Client</button>
        <button className='btn btn-secondary me-1 mt-1' data-bs-toggle="modal" data-bs-target={'#addgroup'}>Add Group</button>
        <button className='btn btn-warning me-1 mt-1' onClick={()=>{Navigate('/edit-group')}}>Edit Group</button>
      </div>
      <div className="modal fade" id={`addgroup`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add Group</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={()=>{
                        setalertOpen2(false);
                        groupref.current.value = '';
                    }} aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                  <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                        <Collapse in={alertopen2}>
                            <Alert
                            severity={alertType2}
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setalertOpen2(false);
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            >
                            {AlertMessage2}
                            </Alert>
                        </Collapse>
                    </Box>
                    <div className='fw-bold'>
                      Name
                    </div>
                    <div>
                      <input className='form-control' ref={groupref}/>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" id={`closegroup`} onClick={()=>{
                        setalertOpen2(false);
                        groupref.current.value = '';
                    }} data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={()=>{addgroup()}}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
      <div>
      <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                    severity={alertType}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setalertOpen(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                    >
                    {AlertMessage}
                    </Alert>
                </Collapse>
        </Box>
        <TableComponent
            Data={data}
            screenWidth={props.screenWidth}
            MobViewRender={MobViewRender}
            columnDefs={columnDefs}
            datefilters={datefilters}
            datefilter={false}
        />
      </div>
    </div>
  )
}
