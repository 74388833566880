import React,{useEffect, useState, useRef} from 'react';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import TableComponent from '../../Atoms/TableComponent';
import { url } from '../../../Config';
import axios from 'axios';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import PanToolIcon from '@mui/icons-material/PanTool';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import ApprovalIcon from '@mui/icons-material/Approval';
import rip from '../../../Assets/Images/rip.svg';
import icu from '../../../Assets/Images/ICU.jpg';
import EmailIcon from '@mui/icons-material/Email';
import AttachmentIcon from '@mui/icons-material/Attachment';



export default function Cob_to_icu_rip(props) {

    const [cob, setcob] = useState([]);
    const [approval, setapproval] = useState([]);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const id = localStorage.getItem("userID");
    const [file, setfile] = useState(null);
    const [file2, setfile2] = useState(null);
    const [bool, setbool] = useState(false);

    const btnRef = useRef(null);

    useEffect(()=>{
        const func = async ()=>{
          setapproval([]);
            const res = await axios.get(`${url}/api/cobToIcuRip`);
            const resp = res.data;
            if(id == "226"){
                setcob(resp.cobList);
            }
            else if(id == '130'){
                setapproval(resp.approvalList);
            }
        }
        func();
    },[bool])


    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };
    
      function innerNotiDate(params) {
        if (params.data.notification_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.notification_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerFCDate(params) {
        if (params.data.file_closure_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.file_closure_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerOpenRequest(params) {
        if (params.data.request_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.request_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerApproval(params) {
        if (params.data.approval_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.approval_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerClosed(params) {
        if (params.data.closure_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.closure_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerreOpen(params) {
        if (params.data.reopen_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.reopen_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }


      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };
      const responsiveColumnPin2 = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "right";
        }
      };
      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };
    

      const submitform = async (event, c_id, action)=>{
          event.preventDefault();
        
        const fileData = new FormData();
        if(action == 'rip'){
            fileData.append("attachment", file);
        }
        else if(action == 'icu'){
            fileData.append("attachment", file2);
        }
        fileData.append('user_id', id);
        btnRef.current.disabled = true;

        const res = await axios.post(`${url}/api/transfer-icu-rip/${c_id}/${action}`,  
        fileData,
        {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        })

        const resp = res.data;
        if(resp.status == true){
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btnRef.current.disabled = false;
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
      }

      const approve = async (r_id, stage)=>{
        const res = await axios.post(`${url}/api/transferIcuRipApproval`, {
            'id': r_id,
            'stage': stage,
            'user_id': id,
        })
        const resp = res.data;
        if(resp.status == true){
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success");
            setbool(!bool);
        }
        else{
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error")
        }
      }

      const columnDefs = [
            {
              headerName: "",
              field: "sNo",
              maxWidth: 30,
              filter: true,
              menuTabs: false,
              pinned: responsiveColumnPin(),
              hide: detailShow(),
              cellRenderer: "agGroupCellRenderer",
              suppressColumnsToolPanel: true,
              suppressFiltersToolPanel: true,
            },
            {
              headerName: "#",
              maxWidth: 50,
              // minWidth: 66,
              field: "sNo",
              sortable: true,
              filter: true,
              // filter: "agSetColumnFilter",
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
              suppressFiltersToolPanel: true,
              menuTabs: false,
              cellRenderer: cellrandered,
              pinned: responsiveColumnPin(),
            },
            {
              headerName: "Client Name",
              minWidth: responsiveColumns(),
              field: "name",
              sortable: true,
              filter: "agSetColumnFilter",
              excelMode: "windows",
              cellRenderer: cellrander,
              tooltipField: "name",
              pinned: responsiveColumnPin(),
            },
            {
              headerName: "Team",
              minWidth: responsiveColumns(),
              field: "display_name",
              sortable: true,
              filter: "agSetColumnFilter",
              excelMode: "windows",
              hide: columnHide(),
              cellRenderer: cellrander,
              tooltipField: "display_name",
            },
            {
              headerName: "Notified Date",
              field: "to_cob_date",
              minWidth: 115,
              hide: columnHide(),
              sortable: true,
              filter: "agDateColumnFilter",
              excelMode: "windows",
              cellRenderer: fullDate,
              debounceMs: "DateFilter",
              filterParams: {
                filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
                inRangeInclusive: true,
                comparator: function (filterLocalDateAtMidnight, cellValue) {
                  var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                  var dateParts = dateAsString.split("/");
                  var cellDate = new Date(
                    Number(dateParts[2]),
                    Number(dateParts[1]) - 1,
                    Number(dateParts[0])
                  );
        
                  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                    return 0;
                  }
        
                  if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                  }
        
                  if (cellDate > filterLocalDateAtMidnight) {
                    return 1;
                  }
                },
                buttons: ["clear", "reset", "apply"],
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    "  </div>" +
                    "</div>",
                },
              },
            },
            {
                headerName: "Current Stage",
                minWidth: 200,
                autoHeight: true,
                wrapText: true,
                field: "stage",
                sortable: true,
                filter: "agSetColumnFilter",
                excelMode: "windows",
                cellRenderer: (params)=>{
                    if(params.value !== null){
                        return (
                          <div className='text-uppercase'>{params.value}</div>
                        )
                    }
                    else{
                        return(
                          <div className='text-uppercase'>{params.data.raw_stage}</div>
                        )
                    }
                },
            },
            {
                headerName: "Actions",
                minWidth: 200,
                autoHeight: true,
                wrapText: true,
                field: "raw_stage",
                sortable: true,
                filter: "agSetColumnFilter",
                excelMode: "windows",
                cellRenderer: (params)=>{
                    return(
                        <div>
                            {
                                params.data.stage_raw == 'rip' || params.data.stage == 'rip' || params.data.stage_raw == 'RIP' || params.data.stage == 'RIP' ?
                                <>
                                {/* <p className='me-1 mb-0 pb-0'>Moved to RIP</p> */}
                                {params.data.saved_flag == true ?
                                <a className='me-1' href={`${url}/api/viewnl-new/${params.data.CompanyId}/${params.data.ratingsId}`}
                                target="_blank"><Tooltip title="Letter"><EmailIcon sx={{color: 'wheat'}}  /></Tooltip></a> :
                                  <a className='me-1' href={`/rip_letter/${params.data.CompanyId}/${params.data.ratingsId}`}
                                    target="_blank"><Tooltip title="Letter"><EmailIcon sx={{color: 'wheat'}} /></Tooltip></a>}
                                </> : null
                            }
                            {
                                params.data.attachment &&
                                <a className='me-2' href={`https://209.97.168.200/pacrawizpackv3/public/view/public/attachment/${params.data.attachment}`} target="_blank"><Tooltip title="Attachment"><AttachmentIcon /></Tooltip></a>
                            }
                            {
                                params.data.stage_raw == 'icu' || params.data.stage == 'icu' || params.data.stage_raw == 'ICU' || params.data.stage == 'ICU' ?
                                <Tooltip title="RIP"><img className='me-2' src={rip} style={{height: '20px', width: '20px'}} data-bs-toggle="modal" data-bs-target={`#modelrip${params.data.CompanyId}`}/></Tooltip> : null
                            }
                            {
                                params.data.stage_raw == null && params.data.stage == null ?
                                <>
                                <Tooltip title="ICU"><img className='me-2' src={icu} style={{height: '20px', width: '20px'}} data-bs-toggle="modal" data-bs-target={`#modelicu${params.data.CompanyId}`}/></Tooltip>
                                <Tooltip title="RIP"><img className='me-2' src={rip} style={{height: '20px', width: '20px'}} data-bs-toggle="modal" data-bs-target={`#modelrip${params.data.CompanyId}`}/></Tooltip>
                                </> : null
                            }
                            <>
                            <Tooltip title="View Reason"><VisibilityIcon className='me-2' style={{color: 'blue'}} data-bs-toggle="modal" data-bs-target={`#modelreason${params.data.CompanyId}`} /></Tooltip>
                            </>
                        </div>
                    )
                },
            },
            
      ];

      const columnDefs2 = [
        {
            headerName: "",
            field: "sNo",
            maxWidth: 30,
            filter: true,
            menuTabs: false,
            pinned: responsiveColumnPin(),
            hide: detailShow(),
            cellRenderer: "agGroupCellRenderer",
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true,
          },
          {
            headerName: "#",
            maxWidth: 50,
            // minWidth: 66,
            field: "sNo",
            sortable: true,
            filter: true,
            // filter: "agSetColumnFilter",
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
            suppressFiltersToolPanel: true,
            menuTabs: false,
            cellRenderer: cellrandered,
            pinned: responsiveColumnPin(),
          },
          {
            headerName: "Client Name",
            minWidth: responsiveColumns(),
            field: "name",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: cellrander,
            tooltipField: "name",
            pinned: responsiveColumnPin(),
          },
          {
            headerName: "Team",
            minWidth: responsiveColumns(),
            field: "display_name",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            hide: columnHide(),
            cellRenderer: cellrander,
            tooltipField: "display_name",
          },

          {
            headerName: "Reason",
            minWidth: responsiveColumns(),
            field: "to_cob_reason",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            hide: columnHide(),
            cellRenderer: cellrander,
            tooltipField: "to_cob_reason",
          },

          {
              headerName: "Current Stage",
              minWidth: 200,
              autoHeight: true,
              wrapText: true,
              field: "stage",
              sortable: true,
              filter: "agSetColumnFilter",
              excelMode: "windows",
              cellRenderer: (params)=>{
                 return(
                    <div>{params.value !== null ? `Moved to ${params.value.toUpperCase()}` : params.data.raw_stage}</div>
                 )
              },
          },
          {
              headerName: "Actions",
              minWidth: 200,
              autoHeight: true,
              wrapText: true,
              field: "raw_stage",
              sortable: true,
              filter: "agSetColumnFilter",
              excelMode: "windows",
              cellRenderer: (params)=>{
                  return(
                      <div>
                        <div>
                            {params.data.stage == null || params.data.rawstage !== null ?
                            <>
                                {
                                    params.data.attachment &&
                                    <a className='me-2' href={`https://209.97.168.200/pacrawizpackv3/public/view/public/attachment/${params.data.attachment}`} target="_blank"><Tooltip title="Attachment"><AttachmentIcon /></Tooltip></a>
                                }
                                <Tooltip title='Approve'><ApprovalIcon sx={{color: 'green'}} onClick={()=>{approve(params.data.recordId, params.data.rawstage)}}/></Tooltip>
                            </> :
                            <>
                                {
                                    params.data.attachment &&
                                    <a className='me-2' href={`https://209.97.168.200/pacrawizpackv3/public/view/public/attachment/${params.data.attachment}`} target="_blank"><Tooltip title="Attachment"><AttachmentIcon /></Tooltip></a>
                                }
                                {params.data.saved_flag == true ?
                                <a className='me-1' href={`${url}/api/viewnl-new/${params.data.CompanyId}/${params.data.recordId}`}
                                target="_blank"><Tooltip title="Letter"><EmailIcon sx={{color: 'wheat'}} /></Tooltip></a> :
                                  <a className='me-1' href={`/rip_letter/${params.data.CompanyId}/${params.data.recordId}`}
                                    target="_blank"><Tooltip title="Letter"><EmailIcon sx={{color: 'wheat'}} /></Tooltip></a>}
                            </>
                            }
                        </div>
                          
                      </div>
                  )
              },
          },
        
      ];

      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
          <Table className="overflow-scroll responsiveTableFonts">
          </Table>
        </h1>
    );


  return (
    <div>
      <div className='text-center mt-2 mb-2 fs-4 fw-bold' style={{color: "#000078" }}>
        COB to ICU & RIP
      </div>
      { id == "226" &&
        <>
            {
            cob.map((value, index)=>{
                return(
                    <>
                        <form className="modal fade" id={`modelrip${value.CompanyId}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{zIndex: 1000000}} onSubmit={(event)=>{submitform(event, value.CompanyId, 'rip')}}>
                            <div className="modal-dialog modal-dialog-centered modal-xl">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">RIP : {value.name}</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{setalertOpen(false)}}></button>
                                    </div>
                                    <div className="modal-body" style={{overflow: "scroll"}}>
                                            <div className='fw-bold mt-1'>
                                                Transfer Date:
                                            </div>
                                            <div className='fw-bold'>
                                                <input className='form-control' type='date' required />
                                            </div>
                                            <div className='mt-2'>
                                                <button className='btn btn-primary' type='button'>RIP Letter</button>
                                            </div>
                                            <div className='fw-bold mt-2'>
                                                Attachment:
                                            </div>
                                            <div className=''>
                                                <input className='form-control' type='file' onChange={(e)=>{
                                                    setfile(e.target.files[0]);
                                                }} required />
                                            </div>
                                            <div className='mt-2'>
                                                <button className='btn btn-primary' ref={btnRef} type='submit'>Save</button>
                                            </div>
                                    </div>
                                    <div className="modal-footer">
                                        <Box className="mx-auto" sx={{ width: "98%" }}>
                                            <Collapse in={alertopen}>
                                                <Alert
                                                severity={alertType}
                                                action={
                                                    <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setalertOpen(false);
                                                    }}
                                                    >
                                                    <CloseIcon fontSize="inherit" />
                                                    </IconButton>
                                                }
                                                sx={{ mb: 2 }}
                                                >
                                                {AlertMessage}
                                                </Alert>
                                            </Collapse>
                                        </Box>
                                        <button type="button" className="btn btn-secondary" id={`reasonopenclose${index}`} data-bs-dismiss="modal" onClick={()=>{setalertOpen(false)}}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form className="modal fade" id={`modelicu${value.CompanyId}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{zIndex: 1000000}} onSubmit={(event)=>{submitform(event, value.CompanyId, 'icu')}}>
                            <div className="modal-dialog modal-dialog-centered modal-xl">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">ICU : {value.name}</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{setalertOpen(false)}}></button>
                                    </div>
                                    <div className="modal-body" style={{overflow: "scroll"}}>
                                            <div className='fw-bold mt-2'>
                                                Attachment:
                                            </div>
                                            <div className=''>
                                                <input className='form-control' type='file' onChange={(e)=>{
                                                    setfile2(e.target.files[0]);
                                                }}  required />
                                            </div>
                                            <div className='mt-2'>
                                                <button className='btn btn-primary' ref={btnRef} type='submit'>Save</button>
                                            </div>
                                    </div>
                                    <div className="modal-footer">
                                        <Box className="mx-auto" sx={{ width: "98%" }}>
                                            <Collapse in={alertopen}>
                                                <Alert
                                                severity={alertType}
                                                action={
                                                    <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setalertOpen(false);
                                                    }}
                                                    >
                                                    <CloseIcon fontSize="inherit" />
                                                    </IconButton>
                                                }
                                                sx={{ mb: 2 }}
                                                >
                                                {AlertMessage}
                                                </Alert>
                                            </Collapse>
                                        </Box>
                                        <button type="button" className="btn btn-secondary"  id={`reasonopenclose${index}`} data-bs-dismiss="modal" onClick={()=>{setalertOpen(false)}}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="modal fade" id={`modelreason${value.CompanyId}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{zIndex: 1000000}}>
                            <div className="modal-dialog modal-dialog-centered modal-xl">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Reason to Transfor: {value.name}</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{setalertOpen(false)}}></button>
                                    </div>
                                    <div className="modal-body" style={{overflow: "scroll"}}>
                                        {value.to_cob_reason}
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </>
                )
            })
            }
            <TableComponent
                Data={cob}
                screenWidth={props.screenWidth}
                MobViewRender={MobViewRender}
                columnDefs={columnDefs}
                datefilters={datefilters}
                datefilter={false}
        />
        </>
       }
      {id == "130" &&
      <>
        <Box className="mx-auto" sx={{ width: "98%" }}>
         <Collapse in={alertopen}>
             <Alert
             severity={alertType}
             action={
                 <IconButton
                 aria-label="close"
                 color="inherit"
                 size="small"
                 onClick={() => {
                     setalertOpen(false);
                 }}
                 >
                 <CloseIcon fontSize="inherit" />
                 </IconButton>
             }
             sx={{ mb: 2 }}
             >
             {AlertMessage}
             </Alert>
         </Collapse>
        </Box>
        <TableComponent
            Data={approval}
            screenWidth={props.screenWidth}
            MobViewRender={MobViewRender}
            columnDefs={columnDefs2}
            datefilters={datefilters}
            datefilter={false}
       />
      </>
       
       }
    </div>
  )
}
