import React, {useEffect, useState} from "react";
import undertaking from "../../../../Assets/Images/undertaking_blue.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { url } from "../../../../Config";
import {encryptParams} from "../../encrypt_url"
import { useLocation } from 'react-router-dom';
import { decryptParams } from "../../encrypt_url";

export default function Acceptence_undertaking() {

  const location = useLocation();
  const pathname = location.pathname;
  const encryptedParams = pathname.substring('/Ratings/undertaking/'.length);
  const { client_id, og_rating_id, company } = decryptParams(encryptedParams);

  const id = localStorage.getItem("userID");
  const Navigate = useNavigate();
  
  const addUndertaking = async()=>{
        try {
            const date = new Date();
            var month = date.getMonth() + 1;
            if (month < 10) {
              month = `0${month}`;
            }
            var current_date = date.getDate();
            if (current_date < 10) {
              current_date = `0${current_date}`;
            }
            const getdate = `${date.getFullYear()}-${month}-${current_date}`;
            const res = await axios.post(`${url}/api/addUndertaking`,
            {
                "userId": id,
                "clientId": client_id,
                "recordId": og_rating_id,
                "date": getdate,
                "file": `Undertaking${client_id}${id}${og_rating_id}.pdf`
            },{
                headers: {
                    "Content-Type": "application/json",
                    "accept": "application/json",
                  },
            })
            if(res.data.message == "done"){
              const encryptedParams = encryptParams({client_id, og_rating_id})
              Navigate(`/Ratings/addTask/Information/${encryptedParams}`);
            }
        } catch (error) {
            console.log(error);
        }
    }
  return (
    <div>
      <div
        className="fs-3 fw-bold text-center mt-2"
        style={{ color: "#000078", fontFamily: "Kalinga" }}
      >
        CLIENT ACCEPTANCE UNDERTAKING
      </div>
      <div
        className="card shadow col-10 mx-auto"
        style={{
          display: "flex",
          borderColor: "#000078",
          borderWidth: 10,
        }}
      >
        <div className="col-10 mx-auto mt-4 mb-4" style={{ fontFamily: "Kalinga", fontSize: "1.5vw"  }}>
        <p>Clauses 11-A-(r), 12-2-(j & I) & Annexure F & Annexure I</p>
        <p>I, in connection with the Rating of <b>{company}</b>, hereby, undertake that I</p>
        <p><b>I.</b> Or any of my Family Members do not have any Interest in it [Clause 12-2-(i)]</p>
        <p><b>II.</b> Or any of my Family Members do not buy or sell, engage in any transaction or Own Securities of or of any entity related to it, other than holdings in diversified collective investment schemes [Clause 12-2-(I) & Annexure I: 3-ii-(a) & (b)]</p>
        <p><b>III.</b> Am not an Associate of any director, Substantial Shareholder or senior management officer of the customers of PACRA [Annexure F: d-(i)]</p>
        <p><b>IV.</b> Am not Substantial Shareholder of the customer being rated by PACRA [Annexure F: d-(ii)]</p>
        <p><b>V.</b> Am not a director of an entity being rated by PACRA [Annexure F: d-(iii)]</p>
        <p><b>VI.</b> Have not had a recent employment (within the past six (6) months) or other Significant Business or Personal Relationship with it that may cause or may be perceived as causing a Conflict of Interest [Clause 11-(A)-(r)]</p>
        <p><b>VII.</b> Do not have an immediate relation (i.e., a spouse, partner, parent, child, or sibling) who currently works for it [Annexure I: 3-ii-(d)]</p>
        <p><b>VIII.</b> Do not have any other relationship with it directly or indirectly that may cause or may be perceived as causing Conflict of Interest [Annexure I: 3-ii-(e)]</p>
        <p><b>IX.</b> If “Yes” to any of the above statements, shall not get involved in its rating process [Annexure I: 3-ii]</p>
        <p><b>X.</b> Shall not join any PACRA Client (including this one), within one year of relieving from PACRA [Annexure I: 3-iv]</p>
        <p><b>XI.</b> At any time during the rating process, if any of the above conditions become applicable, shall immediately make Disclosure to Team Leader and withdraw from the rating assignment</p>
        <p>Capitalized terms used herein are defined in "Defined Terms" available on the website: <a href={'https://www.pacra.com/'}> www.pacra.com </a></p>
        </div>
      </div>
      <br/>
      <div className="col-lg-2 col-md-3 col-sm-4 col-6 mx-auto">
        <button className="button btn btn-primary" onClick={()=>{addUndertaking()}}>Agree</button>
        <button className="button btn btn-danger ms-1" onClick={()=>{window.close()}}>Disagree</button>
      </div>
      <br />
    </div>
  );
}
