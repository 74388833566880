import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Check, Clear, Event } from "@material-ui/icons";
import { Table, TableCell, TableRow } from "@mui/material";
import GetGridsApi from "../../../API/GetGridsApi";
import TableComponent from "../../Atoms/TableComponent";


export default function RcOverdue(props) {
    const [rcOverdue, setrcOverdue] = useState([]);
    const [Update, setUpdate] = useState();
    const [startdate, setstartdate] = useState();
    const [rowstatus, setrowstatus] = useState();
    const [enddate, setenddate] = useState();
    const [fromDate, setfromDate] = useState(localStorage.getItem('reportsfirstDate'));
    const [toDate, settoDate] = useState(localStorage.getItem('reportslastDate'));
    const responsiveColumns = () => {
      if (props.screenWidth < 770) {
        return null;
      } else {
        return 120;
      }
    };
    const responsiveColumnPin = () => {
      if (props.screenWidth < 770) {
        return null;
      } else {
        return "left";
      }
    };
    const detailShow = () => {
      if (props.screenWidth < 770) {
        return false;
      } else {
        return true;
      }
    };

    const columnHide = () => {
      if (props.screenWidth < 770) {
        return true;
      } else {
        return false;
      }
    };

    function innerLastRC(params) {
      if (params.data.RcDate == null) {
        return "-";
      } else {
        const date = new Date(params.data.RcDate);
        const yyyy = date.getFullYear();
        const yy = yyyy.toString();
        const y = yy.slice(2, 4);
        let mm = date.toLocaleString("default", { month: "short" });
        let dd = date.getDate();
        if (dd < 10) dd = "0" + dd;
        return dd + "-" + mm + "-" + y;
      }
    }
    function innerDueDate(params) {
      if (params.data.nextRc == null) {
        return "-";
      } else {
        const date = new Date(params.data.nextRc);
        const yyyy = date.getFullYear();
        const yy = yyyy.toString();
        const y = yy.slice(2, 4);
        let mm = date.toLocaleString("default", { month: "short" });
        let dd = date.getDate();
        if (dd < 10) dd = "0" + dd;
        return dd + "-" + mm + "-" + y;
      }
    }
    function innerActualRC(params) {
      if (params.data.nextrc2 == null) {
        return "-";
      } else {
        const date = new Date(params.data.nextrc2);
        const yyyy = date.getFullYear();
        const yy = yyyy.toString();
        const y = yy.slice(2, 4);
        let mm = date.toLocaleString("default", { month: "short" });
        let dd = date.getDate();
        if (dd < 10) dd = "0" + dd;
        return dd + "-" + mm + "-" + y;
      }
    }
    function innerDaysOver(params) {
      if (params.data.RcDate == null) {
        return "-";
      } else {
        const date = new Date(params.data.RcDate);
        const yyyy = date.getFullYear();
        return yyyy;
      }
    }
  

    useEffect(() => {
      getTabsData(fromDate, toDate);
    }, []);
  
    const getTabsData = async (fromDate, toDate) => {
      if(fromDate !== null){
      setrowstatus(false)
      const res = GetGridsApi.RcOverdueApi(fromDate, toDate);
      const resp = await res;
      console.log(resp, "response");
      console.log(resp.data["$over"],'ressspppp');
      setrcOverdue(resp.data["$over"]);
      if(resp.data["$over"].length == 0){
        setrowstatus(true)
      }
      setstartdate(fromDate)
        setenddate(toDate)
        localStorage.removeItem("reportsfirstDate");
        localStorage.removeItem("reportslastDate");
      }
    };

    const updatedata = async ()=>{
      if(startdate != null && enddate != null){
      setUpdate(true);
      const res = GetGridsApi.RcOverdueApi(startdate, enddate);
      const resp = await res;
      console.log(resp.data["$over"],'ressspppp');
      setrcOverdue(resp.data["$over"]);
      if(resp.data["$over"].length == 0){
        setUpdate('no rows');
      }
      else{
        setUpdate(false);
      }
      }
    }
  
    const datefilters = (
      startDate,
      endDate,
      gridApi,
      getFilterType
    ) => {
      var dateFilterComponent = gridApi.api.getFilterInstance("nextRc");
      dateFilterComponent.setModel({
        type: getFilterType(),
        inRange: true,
        dateFrom: startDate,
        dateTo: endDate,
      });
    };
  
    function fullDate(params) {
      if (params.value === "nullData") return <Skeleton />;
      if (params.value == null) {
        return "-";
      } else if (params.value == "") {
        return "-";
      } else {
        const date = new Date(params.value);
        const yyyy = date.getFullYear();
        const yy = yyyy.toString();
        const y = yy.slice(2, 4);
        let mm = date.toLocaleString("default", { month: "short" });
        let dd = date.getDate();
        if (dd < 10) dd = "0" + dd;
        return dd + "-" + mm + "-" + y;
      }
    }
    const cellrander = (params) => {
      if (params.value === "nullData") return <Skeleton />;
      if (params.value === "" || params.value == null) {
        return "-";
      } else {
        return params.value;
      }
    };
    const cellrandered = (params) => {
      if (params.value === "nullData") {
        return <Skeleton />;
      } else {
        return params.value;
      }
    };
  
    const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
  

        {
          headerName: "Opinion",
          minWidth: responsiveColumns(),
          field: "Entity",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "Entity",
          pinned: responsiveColumnPin(),
        },
  
  
        {
          headerName: "Sector",
          minWidth: responsiveColumns(),
          field: "Industry",
          hide: columnHide(),
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "Industry",
        },
        {
          headerName: "Rating Type",
          minWidth: responsiveColumns(),
          hide: columnHide(),
          field: "RatingScale",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
        },
        {
          headerName: "Unit",
          minWidth: responsiveColumns(),
          hide: columnHide(),
          field: "leadRcName",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
        },
        {
          headerName: "Team",
          minWidth: responsiveColumns(),
          hide: columnHide(),
          field: "managerName",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
        },
        {
          headerName: "Analyst",
          minWidth: responsiveColumns(),
          hide: columnHide(),
          field: "pacraAnalyst",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
        },
        {
          headerName: "Current Review",
          minWidth: responsiveColumns(),
          hide: columnHide(),
          field: "",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
        },
        {
          headerName: "Previous Review",
          minWidth: responsiveColumns(),
          hide: columnHide(),
          field: "RatingAction",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
        },
        {
          headerName: "Action",
          minWidth: responsiveColumns(),
          hide: columnHide(),
          field: "RatingAction",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
        },
        {
          headerName: "RW",
          minWidth: responsiveColumns(),
          hide: columnHide(),
          field: "rw",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
        },
        {
          headerName: "CF",
          minWidth: responsiveColumns(),
          hide: columnHide(),
          field: "cf",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
        },
        {
          headerName: "Outlook",
          minWidth: responsiveColumns(),
          hide: columnHide(),
          field: "Outlook",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
        },
        {
            headerName: "Last RC Date",
            field: "RcDate",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            hide: columnHide(),
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
          },

        {
            headerName: "Due Date",
            field: "nextRc",
            minWidth: 115,
            hide: columnHide(),
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
          },

          {
            headerName: "Days Over",
            minWidth: responsiveColumns(),
            hide: columnHide(),
            field: "RcDate",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
              if(params.value != null){
                const date = new Date(params.value);
                const yyyy = date.getFullYear();
                return yyyy;
              }
              else{
                return "-";
              }
            },
          },
          
          {
            headerName: "Actual RC Date",
            field: "nextrc2",
            minWidth: 115,
            hide: columnHide(),
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
          },

          {
            headerName: "H",
            field: "Id",
            // hide: true,
            sortable: true,
            hide: columnHide(),
            filter: "agSetColumnFilter",
            cellRenderer: function (params) {
              if (params.value === "nullData") return <Skeleton />;
              else
                return (
                  <a
                    href={`https://209.97.168.200/pacrawizpackv3/public/admin/pacraWork/${params.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Event className="theme_text" />
                  </a>
                );
            },
            excelMode: "windows",
          },
      
  
      ];

    const MobViewRender = (params) => (
      <h1 style={{ padding: "10px 20px" }}>
        <Table className="overflow-scroll responsiveTableFonts">
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Sector
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.Industry}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Rating Type
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.RatingScale}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Unit
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.leadRcName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Team
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.managerName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Analyst
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.pacraAnalyst}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Previous Review
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.RatingAction}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Action
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.RatingAction}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              RW
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.rw}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              CF
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.cf}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Outlook
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.Outlook}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Last RC Date
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {innerLastRC(params)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Due Date
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {innerDueDate(params)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Days Over
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {innerDaysOver(params)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Actual RC Date
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {innerActualRC(params)}
            </TableCell>
          </TableRow>
          <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            H
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.value === "nullData" ? (
              <Skeleton />
            ) : params.data.Id ? (
              <a
                href={`https://209.97.168.200/pacrawizpackv3/public/admin/pacraWork/${params.data.Id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Event className="theme_text" />
              </a>
            ) : (
              <Clear className="theme_text" />
            )}
          </TableCell>
        </TableRow>
        </Table>
      </h1>
    );
  
    return (
      <>
      <br></br>
      <div>  
      <div className="row w-100">
        <div
          className="card shadow mx-auto col-lg-10 col-md-10 col-sm-10 col-11 pt-4"
          style={{ height: "auto", backgroundColor: "#e0e0e0" }}
        >

<div className="row w-100 pb-3">
              <div className="col-lg-5 col-md-5 ms-lg-4 ms-md-2 ms-sm-3 col-sm-11 col-11 ms-2">
              <input
                type="date"
                style={{height: "38px"}}
                defaultValue={fromDate}
                className="form-control"
                onChange={(e) => {
                  setstartdate(e.target.value);
                }}
              />
              </div>
              <div className="col-lg-5 col-md-5 ms-md-1 pt-sm-1 pt-md-0 col-sm-11 ms-sm-3 col-11 ms-2 pt-1">
              <input
                type="date"
                style={{height: "38px"}}
                defaultValue={toDate}
                className="form-control"
                onChange={(e) => {
                  setenddate(e.target.value);
                }}
              />
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 pt-sm-1 pt-md-0 ms-md-1 mx-sm-auto col-1 pt-1 mx-auto">
              <button type="button" className="btn btn-primary h-60" onClick={updatedata} >
              Submit
            </button>
              </div>
          </div>
        </div>
      </div>
    </div> <br />
        <TableComponent
          rowstatus = {rowstatus}
          value={"rcOverdue"}
          report = {true}
          handleRfreshTab={props.handleRfreshTab}
          Data={rcOverdue}
          screenWidth={props.screenWidth}
          MobViewRender={MobViewRender}
          columnDefs={columnDefs}
          datefilters={datefilters}
          datefilter={true}
          update = {Update}
          // FullPageTable={true}
        />
      </>
    );
}
