import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../../Config';
import { useParams } from "react-router-dom";
import { Check, Clear, Event } from "@material-ui/icons";


export default function View_methodology(props) {

    const { m_id } = useParams();
    const [name, setname] = useState('');
    const [source, setsource] = useState([]);
    const [scale, setscale] = useState([]);
    const [group, setgroup] = useState([]);
    const [industry, setindustry] = useState([]);
    const [sector, setsector] = useState([]);
    const [date, setdate] = useState('');
    const [scale_file, setscale_file] = useState(null);
    const [minutes_file, setminutes_file] = useState(null);
    const [doc_file, setdoc_file] = useState(null);
    const [img_file, setimg_file] = useState(null);
    const [scale_file_encrypt, setscale_file_encrypt] = useState(null);
    const [mm_file_encrypt, setmm_file_encrypt] = useState(null);
    const [doc_file_encrypt, setdoc_file_encrypt] = useState(null);
    const [img_file_encrypt, setimg_file_encrypt] = useState(null);
    const [text, settext] = useState('');

    useEffect(()=>{
        const func = async ()=>{

            const res = await axios.get(`${url}/api/view_mathodology/${m_id}`);
            const resp = res.data;
            setname(resp.methodology.name);
            for(let i in resp.rating_agencies){
                if(Number(resp.methodology.source) == resp.rating_agencies[i].id){
                    setsource(resp.rating_agencies[i].name)
                }
            }
            for(let i in resp.rating_scales){
                if(Number(resp.methodology.scale_id) == resp.rating_scales[i].id){
                    setscale(resp.rating_scales[i].title)
                }
            }
            let arr = [];
            if(resp.methodology.segment_id !== null){
                for(let i in resp.methodology.segment_id){
                    for(let j in resp.segments){
                        if(resp.segments[j].id == Number(resp.methodology.segment_id[i])){
                            arr.push(resp.segments[j].title)
                        }
                    }
                }
            }
            setgroup(arr);

            let arr1 = [];
            if(resp.methodology.main_sector_id !== null){
                for(let i in resp.methodology.main_sector_id){
                    for(let j in resp.main_sectors){
                        if(resp.main_sectors[j].id == Number(resp.methodology.main_sector_id[i])){
                            arr1.push(resp.main_sectors[j].title)
                        }
                    }
                }
            }
            setindustry(arr1);

            let arr2 = [];
            if(resp.methodology.sector_id !== null){
                for(let i in resp.methodology.sector_id){
                    for(let j in resp.sectors){
                        if(resp.sectors[j].id == Number(resp.methodology.sector_id[i])){
                            arr2.push(resp.sectors[j].title)
                        }
                    }
                }

            }
            setsector(arr2);

            setdate(resp.methodology.date);
            setscale_file(resp.methodology.scale_file);
            setminutes_file(resp.methodology.mm_file);
            setdoc_file(resp.methodology.doc_file);
            setimg_file(resp.methodology.img_file);
            setscale_file_encrypt(resp.methodology.scale_file_encrypt);
            setmm_file_encrypt(resp.methodology.mm_file_encrypt);
            setdoc_file_encrypt(resp.methodology.doc_file_encrypt);
            setimg_file_encrypt(resp.methodology.img_file_encrypt);
            settext(resp.methodology.text);


        }

        func();
    },[])


    function DateFormat(params) {
        if (params == null) {
          return "-";
        } else {
          const date = new Date(params);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

  return (
    <div style={{height: '90vh'}}>
       {name !== '' ?
       <>
        <div className='fs-3 fw-bold text-center mt-2 mb-3' style={{color: "#000078"}}>
            {name}
        </div>
        <div className='col-11 mx-auto'>
            <table className='table table-bordered border-dark table-striped table-hover'>
                <tbody>
                    <tr>
                        <td className='fw-bold'>Source</td>
                        <td>{source}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold'>Scale</td>
                        <td>{scale}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold'>Group</td>
                        <td>{group.join(',')}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold'>Industry</td>
                        <td>{industry.join(',')}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold'>Sector</td>
                        <td>{sector.join(',')}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold'>Date</td>
                        <td>{DateFormat(date)}</td>
                    </tr>
                    <tr>
                        <td className='fw-bold'>Scale File</td>
                        <td>
                            {
                                scale_file !== null && scale_file !== '' ?
                                <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${scale_file_encrypt}`} target='_blank'><Check style={{color: "#000078"}} /></a> :
                                <Clear />
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className='fw-bold'>MM File</td>
                        <td>
                            {
                                minutes_file !== null && minutes_file !== '' ?
                                <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${mm_file_encrypt}`} target='_blank'><Check style={{color: "#000078"}} /></a> :
                                <Clear style={{color: "#000078"}} />
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className='fw-bold'>Document File</td>
                        <td>
                            {
                                doc_file !== null && doc_file !== '' ?
                                <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${doc_file_encrypt}`} target='_blank'><Check style={{color: "#000078"}} /></a> :
                                <Clear style={{color: "#000078"}} />
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className='fw-bold'>Image File</td>
                        <td>
                            {
                                img_file !== null && img_file !== '' ?
                                <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${img_file_encrypt}`} target='_blank'><Check style={{color: "#000078"}} /></a> :
                                <Clear style={{color: "#000078"}} />
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className='fw-bold'>Write up</td>
                        <td>{text}</td>
                    </tr>
                </tbody>
            </table>
        </div>
       </>
        :
        (<div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
        <div class="wrapper" id="loader2">
          <svg
            class="hourglass"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 120 206"
            preserveAspectRatio="none"
          >
            <path
              class="middle"
              d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
            />
            <path
              class="outer"
              d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
            />
          </svg>
        </div>
      </div>)}
    </div>
  )
}
