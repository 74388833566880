import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { NavLink } from "react-router-dom";
import { Check, Clear, Event } from "@material-ui/icons";
import { Table, TableCell, TableRow } from "@mui/material";
import GetGridsApi from "../../../API/GetGridsApi";
import TableComponent from "../../Atoms/TableComponent";

export default function ToBeReviewedRatings(props) {
  const [tobereviewedratings, settobereviewedratings] = useState([]);
  const [Update, setUpdate] = useState();
  const [rowstatus, setrowstatus] = useState();
  const [startdate, setstartdate] = useState();
  const [enddate, setenddate] = useState();
  const [fromDate, setfromDate] = useState(
    localStorage.getItem("reportsfirstDate")
  );
  const [toDate, settoDate] = useState(localStorage.getItem("reportslastDate"));
  const responsiveColumns = () => {
    if (props.screenWidth < 770) {
      return null;
    } else {
      return 120;
    }
  };
  const columnHide = () => {
    if (props.screenWidth < 770) {
      return true;
    } else {
      return false;
    }
  };
  const responsiveColumnPin = () => {
    if (props.screenWidth < 770) {
      return null;
    } else {
      return "left";
    }
  };
  const detailShow = () => {
    if (props.screenWidth < 770) {
      return false;
    } else {
      return true;
    }
  };

  function innerDissemDate(params) {
    if (params.data.Dissemination == null) {
      return "-";
    } else {
      const date = new Date(params.data.Dissemination);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }

  function innerDueDate(params) {
    if (params.data.nextdis == null) {
      return "-";
    } else {
      const date = new Date(params.data.nextdis);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }
  function innerActualDesc(params) {
    if (params.data.nextnl2 == null) {
      return "-";
    } else {
      const date = new Date(params.data.nextnl2);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }

  useEffect(() => {
    getTabsData(fromDate, toDate);
  }, []);

  const getTabsData = async (fromDate, toDate) => {
    if (fromDate !== null) {
      setrowstatus(false)
      const res = GetGridsApi.Tobereviewedratings(fromDate, toDate);
      const resp = await res;
      console.log(resp.data, "ressspppp");
      settobereviewedratings(resp.data[0]);
      if(resp.data[0].length == 0){
        setrowstatus(true);
      }
      setstartdate(fromDate);
      setenddate(toDate);
      localStorage.removeItem("reportsfirstDate");
      localStorage.removeItem("reportslastDate");
    }
  };

  const updatedata = async ()=>{
    if(startdate != null && enddate != null){
      setUpdate(true);
      const res = GetGridsApi.Tobereviewedratings(startdate, enddate);
      const resp = await res;
      settobereviewedratings(resp.data[0]);
      if(resp.data[0].length == 0){
        setUpdate('no rows');
      }
      else{
        setUpdate(false);
      }
    }
  }

  const datefilters = (
    startDate,
    endDate,
    gridApi,
    getFilterType
  ) => {
    var dateFilterComponent =
      gridApi.api.getFilterInstance("Dissemination");
    dateFilterComponent.setModel({
      type: getFilterType(),
      inRange: true,
      dateFrom: startDate,
      dateTo: endDate,
    });
  };

  function fullDate(params) {
    if (params.value === "nullData") return <Skeleton />;
    if (params.value == null) {
      return "-";
    } else if (params.value == "") {
      return "-";
    } else {
      const date = new Date(params.value);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }
  const cellrander = (params) => {
    if (params.value === "nullData") return <Skeleton />;
    if (params.value === "" || params.value == null) {
      return "-";
    } else {
      return params.value;
    }
  };
  const cellrandered = (params) => {
    if (params.value === "nullData") {
      return <Skeleton />;
    } else {
      return params.value;
    }
  };

  const columnDefs = [
    {
      headerName: "",
      field: "sNo",
      maxWidth: 30,
      filter: true,
      menuTabs: false,
      pinned: responsiveColumnPin(),
      hide: detailShow(),
      cellRenderer: "agGroupCellRenderer",
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      headerName: "#",
      maxWidth: 50,
      // minWidth: 66,
      field: "sNo",
      sortable: true,
      filter: true,
      // filter: "agSetColumnFilter",
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          "  </div>" +
          "</div>",
      },
      suppressFiltersToolPanel: true,
      menuTabs: false,
      cellRenderer: cellrandered,
      pinned: responsiveColumnPin(),
    },

    {
      headerName: "Opinion",
      minWidth: responsiveColumns(),
      field: "Entity",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      cellRenderer: cellrander,
      tooltipField: "Entity",
      pinned: responsiveColumnPin(),
    },

    {
      headerName: "Sector",
      minWidth: responsiveColumns(),
      field: "Industry",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      cellRenderer: cellrander,
      tooltipField: "RatingScale",
    },
    {
      headerName: "Rating Type",
      field: "RatingScale",
      hide: columnHide(),
      minWidth: 130,
      minWidth: 130,
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      tooltipField: "pre_lterm",
      cellRenderer: cellrander,
      // pinned: responsiveColumnPin(),
    },
    {
      headerName: "Unit",
      field: "leadRcName",
      hide: columnHide(),
      minWidth: 130,
      minWidth: 130,
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      tooltipField: "pre_lterm",
      cellRenderer: cellrander,
      // pinned: responsiveColumnPin(),
    },
    {
      headerName: "Team",
      field: "managerName",
      minWidth: 130,
      hide: columnHide(),
      minWidth: 130,
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      tooltipField: "managerName",
      cellRenderer: cellrander,
      // pinned: responsiveColumnPin(),
    },
    {
      headerName: "Analyst",
      field: "pacraAnalyst",
      hide: columnHide(),
      minWidth: 130,
      minWidth: 130,
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      tooltipField: "managerName",
      cellRenderer: cellrander,
      // pinned: responsiveColumnPin(),
    },
    {
      headerName: "Previous Review",
      field: "ratingUpdateType",
      minWidth: 130,
      minWidth: 130,
      hide: columnHide(),
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      tooltipField: "ratingUpdateType",
      cellRenderer: cellrander,
      // pinned: responsiveColumnPin(),
    },
    {
      headerName: "Action",
      field: "RatingAction",
      minWidth: 130,
      minWidth: 130,
      hide: columnHide(),
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      tooltipField: "RatingAction",
      cellRenderer: cellrander,
      // pinned: responsiveColumnPin(),
    },
    {
      headerName: "RW",
      field: "rw",
      minWidth: 130,
      minWidth: 130,
      hide: columnHide(),
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      tooltipField: "rw",
      cellRenderer: cellrander,
      // pinned: responsiveColumnPin(),
    },
    {
      headerName: "CF",
      field: "cf",
      minWidth: 130,
      minWidth: 130,
      sortable: true,
      hide: columnHide(),
      filter: "agSetColumnFilter",
      excelMode: "windows",
      tooltipField: "cf",
      cellRenderer: cellrander,
      // pinned: responsiveColumnPin(),
    },
    {
      headerName: "Outlook",
      field: "Outlook",
      minWidth: 130,
      minWidth: 130,
      sortable: true,
      hide: columnHide(),
      filter: "agSetColumnFilter",
      excelMode: "windows",
      tooltipField: "Outlook",
      cellRenderer: cellrander,
      // pinned: responsiveColumnPin(),
    },
    {
      headerName: "Last Dissemination",
      field: "Dissemination",
      minWidth: 115,
      // hide: true,
      sortable: true,
      hide: columnHide(),
      filter: "agDateColumnFilter",
      excelMode: "windows",
      cellRenderer: fullDate,
      debounceMs: "DateFilter",
      filterParams: {
        filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
        inRangeInclusive: true,
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateAsString = moment(cellValue).format("DD/MM/YYYY");
          var dateParts = dateAsString.split("/");
          var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );

          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }

          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        buttons: ["clear", "reset", "apply"],
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
        },
      },
    },
    {
      headerName: "Due Date",
      field: "nextdis",
      minWidth: 130,
      sortable: true,
      hide: columnHide(),
      filter: "agDateColumnFilter",
      excelMode: "windows",
      cellRenderer: fullDate,
      hide: columnHide(),
      debounceMs: "DateFilter",
      filterParams: {
        filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
        inRangeInclusive: true,
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateAsString = moment(cellValue).format("DD/MM/YYYY");
          var dateParts = dateAsString.split("/");
          var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );

          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }

          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        buttons: ["clear", "reset", "apply"],
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
        },
      },
    },
    {
      headerName: "Days Over",
      field: "Disseminationdays",
      minWidth: 130,
      minWidth: 130,
      sortable: true,
      hide: columnHide(),
      filter: "agSetColumnFilter",
      excelMode: "windows",
      tooltipField: "Dissemination",
      cellRenderer: cellrander,
      // pinned: responsiveColumnPin(),
    },
    {
      headerName: "Actual Dissemination",
      field: "nextnl2",
      minWidth: 115,
      // hide: true,
      sortable: true,
      filter: "agDateColumnFilter",
      excelMode: "windows",
      cellRenderer: fullDate,
      hide: columnHide(),
      debounceMs: "DateFilter",
      filterParams: {
        filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
        inRangeInclusive: true,
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateAsString = moment(cellValue).format("DD/MM/YYYY");
          var dateParts = dateAsString.split("/");
          var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );

          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }

          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        buttons: ["clear", "reset", "apply"],
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
        },
      },
    },
    {
      headerName: "H",
      hide: columnHide(),
      field: "Id",
      sortable: true,
      filter: "agSetColumnFilter",
      hide: columnHide(),
      cellRenderer: function (params) {
        if (params.value === "nullData") return <Skeleton />;
        else
          return (
            <a
              href={`https://209.97.168.200/pacrawizpackv3/public/admin/pacraWork/${params.value}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Event className="theme_text" />
            </a>
          );
      },
      excelMode: "windows",
    },
  ];
  const MobViewRender = (params) => (
    <h1 style={{ padding: "10px 20px" }}>
      <Table className="overflow-scroll responsiveTableFonts">
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Rating Type
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.RatingScale}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Unit
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.leadRcName}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Team
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.managerName}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Analyst
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.pacraAnalyst}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Previous Review
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.ratingUpdateType}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Action
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.RatingAction}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            RW
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.rw}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            CF
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.cf}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Outlook
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.Outlook}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Last Dissemination
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {innerDissemDate(params)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Due Date
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {innerDueDate(params)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Days Over
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.Disseminationdays}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Actual Dissemination
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {innerActualDesc(params)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            H
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.value === "nullData" ? (
              <Skeleton />
            ) : params.data.Id ? (
              <a
                href={`https://209.97.168.200/pacrawizpackv3/public/admin/pacraWork/${params.data.Id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Event className="theme_text" />
              </a>
            ) : (
              <Clear className="theme_text" />
            )}
          </TableCell>
        </TableRow>
      </Table>
    </h1>
  );

  return (
    <>
      <div>
        <div className="row w-100">
          <div
            className="card shadow mx-auto col-lg-10 col-md-10 col-sm-10 col-11 mt-2 pt-4"
            style={{ height: "auto", backgroundColor: "#e0e0e0" }}
          >
            <div className="row w-100 pb-3">
              <div className="col-lg-5 col-md-5 ms-lg-4 ms-md-2 ms-sm-3 col-sm-11 col-11 ms-2">
                <input
                  type="date"
                  style={{ height: "38px" }}
                  defaultValue={fromDate}
                  className="form-control"
                  onChange={(e) => {
                    setstartdate(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-5 col-md-5 ms-md-1 pt-sm-1 pt-md-0 col-sm-11 ms-sm-3 col-11 ms-2 pt-1">
                <input
                  type="date"
                  style={{ height: "38px" }}
                  defaultValue={toDate}
                  className="form-control"
                  onChange={(e) => {
                    setenddate(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 pt-sm-1 pt-md-0 ms-md-1 mx-sm-auto col-1 pt-1 mx-auto">
                <button
                  type="button"
                  className="btn btn-primary h-60"
                  onClick={updatedata}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <br />
      <TableComponent
        value={"tobereviewedratings"}
        rowstatus = {rowstatus}
        report={true}
        handleRfreshTab={props.handleRfreshTab}
        Data={tobereviewedratings}
        screenWidth={props.screenWidth}
        MobViewRender={MobViewRender}
        columnDefs={columnDefs}
        datefilters={datefilters}
        datefilter={true}
        update = {Update}
      />
    </>
  );
}
