import React, { useEffect, useState } from "react";
import GetData from "../../../API/GetData";
import HistoryTable from "./HistoryTable";

export default function history(props) {
  const [historyData, sethistoryData] = useState([]);

  useEffect(() => {
    if(props.historyData !== null && props.historyData !== undefined){
      sethistoryData(props.historyData.sort((a, b) => {
        // Convert null dates to a "fallback" date, such as the beginning of time
        const dateA = a.prDate ? new Date(a.prDate) : new Date(0);
        const dateB = b.prDate ? new Date(b.prDate) : new Date(0);
        
        // Compare the dates in reverse order for descending sorting
        return dateB - dateA;
      }));

    }
    else{
      sethistoryData(props.historyData);
    }
  }, [props.historyData]);


  return (
    <HistoryTable
      HistoryTable={historyData}
      open={props.open}
      screenWidth={props.screenWidth}
    />
  );
}
