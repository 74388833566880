import React, { useState, useEffect } from 'react'
import './Login.css'
import logo from '../../../Assets/Images/PACRA_logo.png'
import { Alert, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import LoginIcon from '@mui/icons-material/Login';
import Account from '../../../API/Account'
import Auth from '../../../middleWare/Auth/Auth'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';



const index = (props) => {

    const history = useNavigate();
    const location = useLocation();

    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [sendingReq, setsendingReq] = useState(false)
    const [errorMessage, seterrorMessage] = useState(false)

    const Login = async (e) => {
        setsendingReq(true)
        e.preventDefault()
        const res = await axios.post(`https://209.97.168.200/hr/public/api/login`, null, {
            params: {
                email,
                password
            }
        })

        const resp = res.data;
        
        if(resp.status == true){
            setsendingReq(false);
            const userID = resp.user[1];
            const now = new Date()
            if(userID == 243){
                var item = {
                    value: true,
                    // expiry: now.getTime() + (1 * 60 * 1000),
                    expiry: now.getTime() + (12 * 60 * 60 * 1000),
                    // expiry: now.getTime() + (7 * 24 * 60 * 60 * 1000),
                }
            }
            else{
                var item = {
                    value: true,
                    // expiry: now.getTime() + (12 * 60 * 60 * 1000),
                    expiry: now.getTime() + (7 * 24 * 60 * 60 * 1000),
                }
            }
            localStorage.setItem('loggedIn', JSON.stringify(item));
            const rights = resp.user.rights;
            const designation = resp.user.designation;
            const username = resp.user.name;
            // localStorage.setItem('prevlocation', location.pathname)
            localStorage.setItem('rights', rights);
            localStorage.setItem('username', username)
            localStorage.setItem('userID', userID)
            localStorage.setItem('designation', designation)
            if(resp.user['1'] == 12 || resp.user['1'] == 469 || resp.user['1'] == 379 || resp.user['1'] == 341){
                localStorage.setItem('admin', true);
                localStorage.setItem('admin_userID', userID);
                localStorage.setItem('admin_rights', rights);
                localStorage.setItem('admin_username', username)
                localStorage.setItem('admin_designation', designation)
            }
            localStorage.setItem('designation', designation)
            Auth.login()

            const res2 = await axios.post(`https://209.97.168.200/hr/public/api/already_existing_user_role_api`, null, {
                    params: {
                        email,
                    }
                })
            const resp2 = res2.data;
            const avatar_file = resp2.avatar_file;
            const departmentName = resp2.department;
            if(avatar_file !== null && avatar_file !== ''){
                localStorage.setItem('userImage', `https://209.97.168.200/hr/public/users/${avatar_file}`)
            }
            else{
                localStorage.setItem('userImage', `https://ptetutorials.com/images/user-profile.png`)
            }
            localStorage.setItem('depName', departmentName)
            if(resp.user['1'] == 12 || resp.user['1'] == 469 || resp.user['1'] == 379 || resp.user['1'] == 341){
                if(avatar_file !== null && avatar_file !== ''){
                    localStorage.setItem('admin_userImage', `https://209.97.168.200/hr/public/users/${avatar_file}`)
                }
                else{
                    localStorage.setItem('admin_userImage', `https://ptetutorials.com/images/user-profile.png`)
                }
                localStorage.setItem('admin_depName', departmentName);
            }
            goToDashboard(departmentName, userID)
        }
        else{
            setsendingReq(false);
            console.log('error occure')
            seterrorMessage(true)
        }
        // res.then((value) => {
        //     setsendingReq(false)
        //     setemail(email)
        //     setpassword(password)

        //     if (value !== undefined && value.data.status == true) {
        //         localStorage.setItem('loggedIn', 'true')
        //         const username = value.data.user.name;
        //         const userID = value.data.user[1];
        //         const rights = value.data.user.rights;
        //         const designation = value.data.user.designation;
        //         localStorage.setItem('rights', rights);
        //         localStorage.setItem('username', username)
        //         localStorage.setItem('userID', userID)
        //         localStorage.setItem('designation', designation)
        //         Auth.login()
        //     }
        //     else
        //         seterrorMessage(true)

        // })
        // const image = Account.UserImageApi(email)
        // image.then((value) => {
        //     setemail(email)
        //     if (value !== undefined || localStorage.getItem('loggedIn') == true) {
        //         const avatar_file = value.data.avatar_file;
        //         const departmentName = value.data.department;
        //         localStorage.setItem('userImage', avatar_file)
        //         localStorage.setItem('depName', departmentName)
        //         goToDashboard(departmentName)
        //     }
        // })
    }

    const goToDashboard = (departmentName, userID) => {
        if (Number(departmentName) == 9) {
            if(props.prev !== null){
                history(props.prev.pathname)
            }
            else{
                history('/Ratings');
            }
        }
        else if (Number(departmentName) == 16) {
            if(props.prev !== null){
                history(props.prev.pathname)
            }
            else{
                history('/research-dashboard');
            }
        }
        else if (Number(departmentName) == 8) {
            if(props.prev !== null){
                history(props.prev.pathname)
            }
            else{
                history('/criteria-dashboard');
            }
        }
        else if (Number(departmentName) == 4 || Number(departmentName) == 31) {
            if(props.prev !== null){
                history(props.prev.pathname)
            }
            else{
                history('/bd_dashboard');
            }
        }
        else if (userID == 48 || userID == 297 || userID == 298 || userID == 344 || userID == 372) {
            history('/rating-dashboard-external');
          }
        else{
            if(props.prev !== null){
                history(props.prev.pathname)
            }
            else{
                history('/pacra-wide');
            }
        }
        // window.location.reload()
    }

    const [isHidden, setisHidden] = useState(true)
    const handleClickShowPassword = () => {
        setisHidden(!isHidden)
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <body className='themeContainer items_middle'>
            <div className='row w-100 mx-auto justify-content-center'>
                <form onSubmit={Login} className='col-12 min_height shadow_1 col-md-5 col-lg-4'>
                    <div className='my-auto p-3 mx-auto mx-md-5'>
                        <div className='text-center'>
                            <img src={logo} className="logo_size mx-auto" />
                            <h3 className='my-2 mx-auto fw-bold theme_text'>Login Here</h3>
                        </div>

                        <FormControl className='w-100 mt-3' variant="outlined">
                            <TextField
                                id="outlined-basic"
                                label="Email / Username"
                                name="user_id"
                                variant="outlined"
                                value={email}
                                autoFocus
                                required
                                onChange={(e) => { setemail(e.target.value) }} />
                        </FormControl>

                        <FormControl className='w-100 mt-3' variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={!isHidden ? 'text' : 'password'}
                                name="user_password"
                                value={password}
                                required
                                onChange={(e) => { setpassword(e.target.value) }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {isHidden ? <VisibilityOff className='theme_text' /> : <Visibility className='theme_text' />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        <button type='submit' className='button_'>
                            {sendingReq ? (
                                <div className='loader'></div>
                            ) : (
                                <>Log In<LoginIcon className='ms-2' /></>
                            )}
                        </button>
                        {errorMessage ?
                            // <div class="toast align-items-center" role="alert" aria-live="assertive" aria-atomic="true">
                            //     <div class="d-flex">
                            //         <div class="toast-body">
                            //             Wrong Credentials
                            //         </div>
                            //     </div>
                            // </div>
                                <Alert severity="error">Wrong Credentials/In-active User</Alert>
                            :
                            null
                        }
                    </div>
                </form>
            </div>
        </body >
    )
}

export default index