import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { url } from '../../../../Config';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow, Tooltip } from "@mui/material";
import TableComponent from '../../../Atoms/TableComponent';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReplayIcon from '@mui/icons-material/Replay';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Select from "react-select";



export default function Edit_module(props) {

    const [data, setdata] = useState([]);
    const id = localStorage.getItem("userID");
    const [Update, setUpdate] = useState();
    const [rowstatus, setrowstatus] = useState();
    const [startdate, setstartdate] = useState();
    const [enddate, setenddate] = useState();
    const [fromDate, setfromDate] = useState(localStorage.getItem("reportsfirstDate"));
    const [toDate, settoDate] = useState(localStorage.getItem("reportslastDate"));
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [open, setOpen] = useState(false);
    const [message, setmessage] = useState();
    const [openreason, setopenreason] = useState(Array(data.length));
    const [declinereason, setdeclinereason] = useState(Array(data.length));
    const [reopenreason, setreopenreason] = useState(Array(data.length));
    const [option, setoption] = useState([{value: 'Information', label : 'Information'}, {value: 'Assessment', label : 'Assessment'}, {value: 'Site Visit', label : 'Site Visit'}, {value: 'MM', label : 'MM'}, {value: 'RC', label : 'RC'}, {value: 'NL', label : 'NL'}, {value: 'RED', label : 'RED'}])
    const [selectedOptions, setSelectedOptions] = useState();
    const [taskjoin, settaskjoin] = useState('');
    // const submitref = useRef(null);


    const submit = async (type, recordid, openreason, declinereason, reopenreason, ids, ids2)=>{
      setalertOpen(false);
      if(document.getElementById(ids2) !== null){
        document.getElementById(ids2).disabled = true;
      }
      if(type == 'approved'){
        document.getElementById(`approve${recordid}`).hidden = true;
        document.getElementById(`load${recordid}`).hidden = false;
      }
      else if(type == 'close'){
        document.getElementById(`close${recordid}`).disabled = true;
      }

      else if(type == 'entered'){
        if(taskjoin == ""){
          setalertType("error");
          setalertOpen(true);
          setalertmessage('Select Atleast 1 Task');
          return;
        }
      }

      const res = await axios.post(`${url}/api/openFileRequest/${recordid}`, {
        "reason" : openreason,
        "reopen_reason" : reopenreason,
        "decline_reason" : declinereason,
        "tasks": taskjoin,
        "submit" : type
      })
      if(type !== 'approved' && type !== 'close'){
        document.getElementById(ids).click();
      }

      let messages = res.data.message
      if(res.data.status == true){
          props.setbool1((prevsetbool1)=> !prevsetbool1);
          setOpen(true);
          setmessage(messages); 
          if(type !== 'approved' && type !== 'close'){
            if(document.getElementById(ids2) !== null){
              document.getElementById(ids2).disabled = false;
            }
          }   
          // if(submitref.current){
          //   submitref.current.disabled = false;
          // }
      }
      else{
        if(type == 'approved'){
          // if(submitref.current){
          //   submitref.current.disabled = false;
          // }
          document.getElementById(`approve${recordid}`).hidden = false;
          document.getElementById(`load${recordid}`).hidden = true;
        }
        else if(type == 'close'){
          // if(submitref.current){
          //   submitref.current.disabled = false;
          // }
          document.getElementById(`close${recordid}`).disabled = true;
        }
        setOpen(true);
        setmessage('Some Error Occure While Processing Your Request');
      }
      
    }

      const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };
    
      function innerNotiDate(params) {
        if (params.data.notification_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.notification_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerFCDate(params) {
        if (params.data.file_closure_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.file_closure_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerOpenRequest(params) {
        if (params.data.request_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.request_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerApproval(params) {
        if (params.data.approval_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.approval_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerClosed(params) {
        if (params.data.closure_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.closure_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerreOpen(params) {
        if (params.data.reopen_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.reopen_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }


      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };

      const responsiveColumnPin2 = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "right";
        }
      };

      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
      
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };

      
    function handleSelect(data) {
      const arr = []
      
      for(let i in data){
          arr.push(data[i].value)
      }

      setSelectedOptions(data);
      let joinit = arr.join(',');
      settaskjoin(joinit);
    }

    
      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Opinion Name",
          minWidth: responsiveColumns(),
          field: "Entity",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Team",
          minWidth: responsiveColumns(),
          field: "managerName",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: cellrander,
          tooltipField: "RatingScale",
        },
        {
          headerName: "Analyst",
          field: "pacraAnalyst",
          minWidth: 130,
          minWidth: 130,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          tooltipField: "pre_lterm",
          hide: columnHide(),
          cellRenderer: cellrander,
          // pinned: responsiveColumnPin(),
        },
        {
          headerName: "NL",
          field: "notification_date",
          minWidth: 115,
          // hide: true,
          sortable: true,
          filter: "agDateColumnFilter",
          excelMode: "windows",
          cellRenderer: fullDate,
          debounceMs: "DateFilter",
          hide: columnHide(),
          filterParams: {
            filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
            inRangeInclusive: true,
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              var dateAsString = moment(cellValue).format("DD/MM/YYYY");
              var dateParts = dateAsString.split("/");
              var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );
    
              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }
    
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
    
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
            buttons: ["clear", "reset", "apply"],
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
          },
        },
        {
          headerName: "FC",
          field: "file_closure_date",
          minWidth: 115,
          hide: columnHide(),
          sortable: true,
          filter: "agDateColumnFilter",
          excelMode: "windows",
          cellRenderer: fullDate,
          debounceMs: "DateFilter",
          filterParams: {
            filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
            inRangeInclusive: true,
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              var dateAsString = moment(cellValue).format("DD/MM/YYYY");
              var dateParts = dateAsString.split("/");
              var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );
    
              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }
    
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
    
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
            buttons: ["clear", "reset", "apply"],
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
          },
        },
        {
          headerName: "Open Request",
          field: "request_date",
          minWidth: 115,
          hide: columnHide(),
          sortable: true,
          filter: "agDateColumnFilter",
          excelMode: "windows",
          cellRenderer: fullDate,
          debounceMs: "DateFilter",
          filterParams: {
            filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
            inRangeInclusive: true,
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              var dateAsString = moment(cellValue).format("DD/MM/YYYY");
              var dateParts = dateAsString.split("/");
              var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );
    
              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }
    
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
    
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
            buttons: ["clear", "reset", "apply"],
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
          },
        },
        {
          headerName: "Approval",
          field: "approval_date",
          minWidth: 115,
          hide: columnHide(),
          sortable: true,
          filter: "agDateColumnFilter",
          excelMode: "windows",
          cellRenderer: fullDate,
          debounceMs: "DateFilter",
          filterParams: {
            filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
            inRangeInclusive: true,
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              var dateAsString = moment(cellValue).format("DD/MM/YYYY");
              var dateParts = dateAsString.split("/");
              var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );
    
              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }
    
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
    
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
            buttons: ["clear", "reset", "apply"],
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
          },
        },
        {
          headerName: "Closed",
          field: "closure_date",
          minWidth: 115,
          hide: columnHide(),
          sortable: true,
          filter: "agDateColumnFilter",
          excelMode: "windows",
          cellRenderer: fullDate,
          debounceMs: "DateFilter",
          filterParams: {
            filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
            inRangeInclusive: true,
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              var dateAsString = moment(cellValue).format("DD/MM/YYYY");
              var dateParts = dateAsString.split("/");
              var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );
    
              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }
    
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
    
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
            buttons: ["clear", "reset", "apply"],
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
          },
        },
        {
          headerName: "Re-Opened",
          field: "reopen_date",
          minWidth: 115,
          hide: columnHide(),
          sortable: true,
          filter: "agDateColumnFilter",
          excelMode: "windows",
          cellRenderer: fullDate,
          debounceMs: "DateFilter",
          filterParams: {
            filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
            inRangeInclusive: true,
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              var dateAsString = moment(cellValue).format("DD/MM/YYYY");
              var dateParts = dateAsString.split("/");
              var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );
    
              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }
    
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }
    
              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
            buttons: ["clear", "reset", "apply"],
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
          },
        },
        {
            headerName: "Actions",
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            field: "priority",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
              if(Number(id) !== 19){
                if(params.data.editStatus == 'Approved' || params.data.editStatus == 'Open'){
                  return (
                    <>
                    <div>
                    <button className='btn btn-danger btn-sm' id={`close${params.data.recordid}`} disabled={false} onClick={()=>{submit("close", params.data.recordid, null, null, null, null,null)}}> Close </button>
                    </div>
                    </>
                  )
                }
                else if(params.data.editStatus == 'Entered'){
                  return(
                  <>
                  <div style={{color: "blue"}}>Requested</div>
                  </>
                  )
                }
                else if(params.data.editStatus == 'Closed'){
                  return(
                    <>
                      {"Closed  "}
                      {params.data.file_closure_date && <Tooltip title='Re-Open'><ReplayIcon sx={{color: '#ffc107'}} data-bs-toggle="modal" data-bs-target={`#reason-reopen${params.data.recordid}`}/></Tooltip>}
                    </>
                  )
                }
                else if(params.data.editStatus == 'Declined'){
                  return(
                    <>
                      {"Declined  "}
                      {params.data.file_closure_date && <Tooltip title='Re-Open'><ReplayIcon sx={{color: '#ffc107'}} data-bs-toggle="modal" data-bs-target={`#reason-reopen${params.data.recordid}`}/></Tooltip>}
                    </>
                  )
                }
                else {
                  return(
                    <>
                      {params.data.file_closure_date && <button className='btn btn-primary btn-sm' data-bs-toggle="modal" data-bs-target={`#reason${params.data.recordid}`}>Open</button>}
                    </>
                  )
                }
              }
              else if(Number(id) == 19){
                if(params.data.editStatus == 'Entered' || params.data.editStatus == 'opened'){
                  return(
                    <div>
                    
                    <Tooltip title='Approve'><><i className="fa-solid fa-circle-check fa-lg me-1" id={`approve${params.data.recordid}`} data-bs-toggle="tooltip" data-bs-placement="top" title="Approve" hidden={false} style={{color: 'green'}} onClick={()=>{submit("approved", params.data.recordid, null, null, null, null)}}></i><i className="fa-solid fa-spinner fa-spin fa-lg me-1" id={`load${params.data.recordid}`} hidden={true} style={{color: 'green'}}></i></></Tooltip>
                    <Tooltip title='Decline'><ThumbDownAltIcon className='me-1' sx={{color: 'red'}} data-bs-toggle="modal" data-bs-target={`#reason-decline${params.data.recordid}`}/></Tooltip>
                    <Tooltip title="View Reason"><VisibilityIcon className='me-1' sx={{color: 'blue'}} data-bs-toggle="modal" data-bs-target={`#reason-view${params.data.recordid}`} /></Tooltip>
                    </div>
                  )
                }
                else if(params.data.editStatus == 'Open'){
                  return(
                    <>Opened</>
                  )
                }
                else{
                  return("-");
                }
              }
              
            },
            tooltipField: "name",
            pinned: responsiveColumnPin2(),
        },
        
      ];


      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
          <Table className="overflow-scroll responsiveTableFonts">
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Team
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.managerName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Analyst
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.pacraAnalyst}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                NL
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerNotiDate(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                FC
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerFCDate(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Open Request
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerOpenRequest(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Approval
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerApproval(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Closed
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerClosed(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Re-Opened
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerreOpen(params)}
              </TableCell>
            </TableRow>
          </Table>
        </h1>
      );

  return (
    <div>
      {
        props.data.map((value, index)=>{
          return(
            <>
            <div className="modal fade" id={`reason${value.recordid}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Reason to Open : {value.Entity}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                  <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
                  </Box>
                    <div className='fw-bold'>
                      Select Section to Update
                    </div>
                    <div>
                      <Select
                          options={option}
                          placeholder="Select Tasks"
                          value={selectedOptions}
                          onChange={handleSelect}
                          isMulti
                          />
                    </div>
                    <div className='fw-bold mt-2'>
                      Reason
                    </div>
                    <div>
                      <textarea className='form-control' style={{height: "100%"}} value={openreason[index]} onChange={(e)=>{
                        openreason[index] = e.target.value;
                        setopenreason(openreason);
                      }}></textarea>
                    </div>
                    <div className='mt-2'>
                      <b>Note: </b>In case of Assessment, PPL and NL contact MIT.
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" id={`reasonopenclose${index}`} data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" id={`openbtn${index}`}  onClick={()=>{submit("entered", value.recordid, openreason[index], declinereason[index], reopenreason[index], `reasonopenclose${index}`, `openbtn${index}`)}}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id={`reason-reopen${value.recordid}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Reason to Re-Open : {value.Entity}</h5> 
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                  <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
                  </Box>
                    <div className='fw-bold'>
                      Select Section to Update
                    </div>
                    <div>
                      <Select
                          options={option}
                          placeholder="Select Tasks"
                          value={selectedOptions}
                          onChange={handleSelect}
                          isMulti
                          />
                    </div>
                    <div className='fw-bold'>
                      Reason
                    </div>
                    <div>
                      <textarea className='form-control' style={{height: "100%"}} onChange={(e)=>{
                        reopenreason[index] = e.target.value;
                        setreopenreason(reopenreason);
                      }}></textarea>
                    </div>
                    <div className='mt-2'>
                      <b>Note: </b>In case of Assessment, PPL and NL contact MIT.
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" id={`reasonreopenclose${index}`} data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" id={`reopenbtn${index}`}  onClick={()=>{submit("reopen", value.recordid, openreason[index], declinereason[index], reopenreason[index], `reasonreopenclose${index}`, `reopenbtn${index}`)}}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id={`reason-decline${value.recordid}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Reason to Decline : {value.Entity}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className='fw-bold'>
                      Reason
                    </div>
                    <div>
                      <textarea className='form-control'  style={{height: "100%"}} onChange={(e)=>{
                        declinereason[index] = e.target.value;
                        setdeclinereason(declinereason);
                      }}></textarea>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" id={`reasondeclineclose${index}`} data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" id={`declinebtn${index}`}  onClick={()=>{submit("decline", value.recordid, openreason[index], declinereason[index], reopenreason[index], `reasondeclineclose${index}`, `declinebtn${index}`)}}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id={`reason-view${value.recordid}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Reason to Open : {value.Entity}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className='fw-bold'>
                      Reason
                    </div>
                    <div>
                      {value.reason}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </>
          )
        })
      }
      <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
      <TableComponent
      Data={props.data}
      screenWidth={props.screenWidth}
      MobViewRender={MobViewRender}
      columnDefs={columnDefs}
      datefilters={datefilters}
      datefilter={false}
       />
    </div>
  )
}
