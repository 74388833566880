import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../../Config';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';


export default function View_format(props) {

    const { id } = useParams();
    const [model_name, setmodel_name] = useState();
    const [mainheading, setmainheading] = useState([]);

    useEffect(()=>{

        const func = async ()=>{
            const res = await axios.get(`${url}/api/postcreatemodel/${id}`);
            const resp = res.data;
            setmodel_name(resp.pacra_model_name[0].name);
            setmainheading(resp.mainheading);
           
        }

        func();
    },[])


  return (
    <div>
    <div className='mt-4 col-10 mx-auto'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to={"/model-dashboard"} >Assessment Format Grid</Link></li>
                <li className="breadcrumb-item active" aria-current="page">View Format</li>
            </ol>
        </nav>
        <hr/>
    </div>
   <div className='fs-4 fw-bold text-center mt-2' style={{color:"#000078", fontFamily: "Kalinga"}}>
        Format: {model_name}
  </div> <br/>
  <div className='card shadow col-11 mx-auto p-3'>
    {
        mainheading.map((outervalue, outerindex)=>{
            return(
            <div className='mb-2'>
            <div className='ms-1 fs-5'> <b>{outervalue.name}</b></div>
            {
            outervalue.subheading.map((value, index)=>{
                return(
                <>
                <div className='ms-1'>{value.name}</div>
                <div className='ms-1 me-1'> <textarea className='form-control' ></textarea></div>
                </>
                );
            })
            }
            </div>
            );
        })
    }
    </div>
    <div className='mx-auto mt-2' style={{width: "fit-content"}}>
         <button className='button btn btn-primary' onClick={()=>{alert('This is demo page of Assessment Format')}}>Submit</button>
    </div>
    <br /> <br/>
</div>
  )
}
