import React, {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import { url } from '../../../Config';
import axios from 'axios';
import { Check, Clear, Event } from "@material-ui/icons";




export default function Policy_history(props) {
    const { category_id } = useParams();
    const [data, setdata] = useState([]);
    const [nav, setnav] = useState(0);

    function DateFormat(params) {
        if (params == null) {
          return "-";
        } else {
          const date = new Date(params);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }


    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/policy_history/${category_id}`);
            const resp = res.data;
            const policy = resp.pacra_policies;
            for(let i in policy){
                let appli = [];
                let law = []
                for(let j in policy[i].applicability){
                    appli.push(policy[i].applicability[j].title);
                }
                let applijoin = appli.join(',');
                
                for(let j in policy[i].law){
                    law.push(policy[i].law[j].title);
                }
                let lawjoin = law.join(',');
                policy[i].law = lawjoin;
                policy[i].applicability = applijoin;
            }
            const compareDatesDesc = (a, b) => {
                const dateA = new Date(a.review_date);
                const dateB = new Date(b.review_date);
              
                return dateB - dateA;
              };

            const sortedArray = policy.sort(compareDatesDesc);
            setdata(sortedArray);

        }
        func();
    },[])

  return (
    <div style={data.length < 3 && props.screenWidth > 767 ? {height: '90vh'} : null}>
      <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
        Policies and Guidelines History
      </div>
      <div className='row'>
        {
            data.map((value, index)=>{
                // if(nav == index){
                    return(
                        <div className='mt-4 card shadow col-lg-5 ms-lg-5 col-md-5 ms-md-5 col-10 ms-5 mt-2' style={{height: 'fit-content'}}>
                            <table className='table table-striped table-hover table-bordered border-dark'>
                                <tbody>
                                    <tr>
                                        <td className='fw-bold'>Source</td>
                                        <td>{value.name}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>Category</td>
                                        <td>{value.category_title}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>Doc Type</td>
                                        <td>{value.doc_type}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>Review Date</td>
                                        <td>{DateFormat(value.review_date)}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>Clause</td>
                                        <td>{value.clause}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>Applicability</td>
                                        <td>{value.applicability}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>Law</td>
                                        <td>{value.law}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>Policy Document</td>
                                        <td>{value.policy_document !== null ? <a href={`${url}/api/getPolicyDoc/${value.policy_document_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear  className="theme_text"/>}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>Minutes</td>
                                        <td>{value.minutes !== null ? <a href={`${url}/api/getPolicyDoc/${value.minutes_encrypt}`} target='_blank'><Check  className="theme_text"/></a> : <Clear  className="theme_text"/>}</td>
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>BOD Approval</td>
                                        <td>{value.bod_approval !== null ? <a href={`${url}/api/getPolicyDoc/${value.bod_approval_encrypt}`} target='_blank'><Check  className="theme_text"/></a> : <Clear  className="theme_text"/>}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )
                // }
            })
        }
      </div>
    </div>
  )
}
