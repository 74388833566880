import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../../Config';
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function PostCreateModel(props) {
    const { id } = useParams();

    const [modelname, setmodelname] = useState('');
    const [mainheading, setmainheading] = useState([]);
    const Navigate = useNavigate();

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/postcreatemodel/${id}`);
            const resp = res.data;
            setmodelname(resp.pacra_model_name[0].name);
            setmainheading(resp.mainheading)

        }

        func();
    },[])

  

  return (
    <div style={{height: '90vh'}}>
        <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/model-dashboard"} >Assessment Format Grid</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">View Model</li>
                </ol>
            </nav>
            <hr/>
        </div>
            <Box className="mx-auto mt-2 mb-2" sx={{ width: "98%" }}>
                <Collapse in={true}>
                    <Alert
                    severity={'success'}
                    // action={
                    //     <IconButton
                    //     aria-label="close"
                    //     color="inherit"
                    //     size="small"
                    //     onClick={() => {
                    //         setalertOpen(false);
                    //     }}
                    //     >
                    //     <CloseIcon fontSize="inherit" />
                    //     </IconButton>
                    // }
                        sx={{ mb: 2 }}
                    >
                    {'Your Report Model has been created with following headings and sub headings!'}
                    </Alert>
                </Collapse>
            </Box>
            <div className='fs-4 fw-bold text-center'>
                {modelname}
            </div>
            <div className='mt-2 col-10 mx-auto'>
                <table className='table table-bordered table-striped table-hover'>
                <thead>
                    <tr>
                        <th scope="col">Main Heading</th>
                        <th scope="col">Sub Headings</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        mainheading.map((value, index)=>{
                            return(
                                <tr>
                                    <td>{value.name}</td>
                                    <td>
                                        {
                                            value.subheading.map((value2, index2)=>{
                                                return(
                                                    <>{value2.name}{value.subheading.length > index2 + 1 ? ', ' : null}</>
                                                );
                                            })
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                </table>
            </div>
            <div className=' col-10 mx-auto mt-2'>
                <button className='btn btn-warning me-2' onClick={()=>{Navigate(`/view-demo/${id}`)}}>VIEW DEMO</button>
                <button className='btn btn-success' onClick={()=>{Navigate(`/add-decription/${id}`)}}>Add Descriptions to Headings</button>
            </div>
            
    </div>
  )
}
