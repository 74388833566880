import React, {useRef, useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../Config';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';




export default function Email_dashboard(props) {

    const Navigate = useNavigate();

    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    
    const card1 = useRef(null);
    const card2 = useRef(null);
    const card3 = useRef(null);
    const card4 = useRef(null);
    const card5 = useRef(null);
    const card6 = useRef(null);
    const card7 = useRef(null);
    const card8 = useRef(null);
    const card9 = useRef(null);

    const sendmail = async (command)=>{
        if(command == 'regulatoremail'){
            card6.current.innerHTML = 'Please Wait...'
        }

        if(command == 'bdemail'){
            card7.current.innerHTML = 'Please Wait...'
        }

        if(command == 'mediaemail'){
            card8.current.innerHTML = 'Please Wait...'
        }

        if(command == 'announcementemail'){
            card9.current.innerHTML = 'Please Wait...'
        }
        


        const res = await axios.get(`${url}/api/sendMail/${command}`);
        console.log(res, 'res');
        const resp = res.data;

        if(resp.status == true){
            if(command == 'regulatoremail'){
                card6.current.innerHTML = 'Send Regulator Mail'
            }
    
            if(command == 'bdemail'){
                card7.current.innerHTML = 'Send BD Mail'
            }
    
            if(command == 'mediaemail'){
                card8.current.innerHTML = 'Send Media Mail'
            }
    
            if(command == 'announcementemail'){
                card9.current.innerHTML = 'Send Sector Mail'
            }
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            if(command == 'regulatoremail'){
                card6.current.innerHTML = 'Send Regulator Mail'
            }
    
            if(command == 'bdemail'){
                card7.current.innerHTML = 'Send BD Mail'
            }
    
            if(command == 'mediaemail'){
                card8.current.innerHTML = 'Send Media Mail'
            }
    
            if(command == 'announcementemail'){
                card9.current.innerHTML = 'Send Sector Mail'
            }

            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }

    }

  return (
    <div style={props.screenWidth > 760 ? {height : '90vh'} : null}>
        <div className='mt-2 text-center mb-2 fw-bold fs-3' style={{color:"#000078"}}>
            Pacra Emails Dashboard
        </div>
        <Box className="mx-auto" sx={{ width: "98%" }}>
        <Collapse in={alertopen}>
            <Alert
            severity={alertType}
            action={
                <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                    setalertOpen(false);
                }}
                >
                <CloseIcon fontSize="inherit" />
                </IconButton>
            }
                sx={{ mb: 2 }}
            >
            {AlertMessage}
            </Alert>
        </Collapse>
        </Box>
        <div className='row p-5 mt-3'>
            <div className='card shadow ms-lg-5 ms-md-5 me-lg-2 me-md-2 me-0 col-lg-5 col-md-5 col-12 p-3'>
                <div className='text-center fs-5 fw-bold'>
                    Email Templates
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card1} onClick={()=>{Navigate('/test-mail')}} onMouseEnter={()=>{card1.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card1.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Regulator Email
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card2} onClick={()=>{Navigate('/bd-mail')}} onMouseEnter={()=>{card2.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card2.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Buisness Email
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card3} onClick={()=>{Navigate('/sector-study-mit')}} onMouseEnter={()=>{card3.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card3.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Sector Study
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card4} onClick={()=>{Navigate('/mandate-reminder')}} onMouseEnter={()=>{card4.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card4.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Mandate Reminder
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card5} onClick={()=>{Navigate('/news-letter')}} onMouseEnter={()=>{card5.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card5.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Pacra Daily
                </div>
            </div>

            <div className='card shadow ms-lg-5 ms-md-5 me-lg-2 me-md-2 me-0 col-lg-5 col-md-5 col-12 p-3'>
                <div className='text-center fs-5 fw-bold'>
                    Email Sending
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card6} onClick={()=>{sendmail('regulatoremail')}} onMouseEnter={()=>{card6.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card6.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Send Regulator Mail
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card7} onClick={()=>{sendmail('bdemail')}} onMouseEnter={()=>{card7.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card7.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Send BD Mail
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card8} onClick={()=>{sendmail('mediaemail')}} onMouseEnter={()=>{card8.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card8.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Send Media Mail
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card9} onClick={()=>{sendmail('announcementemail')}} onMouseEnter={()=>{card9.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card9.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Send Sector Mail
                </div>
                {/* <div className='card shadow text-center mt-3 border p-3' ref={card5} onClick={()=>{Navigate('/mandate-revised')}} onMouseEnter={()=>{card3.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card3.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Pacra Daily
                </div> */}
            </div>

        </div>
        
      
    </div>
  )
}
