import React from 'react';

function WizPacGraph() {
    const id = localStorage.getItem("userID");
    return (
        <div>
            <iframe src={`https://209.97.168.200/pacrawizpackv3/public/api/new-performance-data-frame/${id}`}
                    frameBorder="0"
                    style={{width: '100%', height: '100vh'}}></iframe>
        </div>
    );
}

export default WizPacGraph;