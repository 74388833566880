import React, {useState, useEffect, useRef} from 'react';
import { url } from '../../../../../Config';
import axios from 'axios';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';

export default function AddSecpReport(props) {

    const id = localStorage.getItem("userID");
    const Navigate = useNavigate();
    const nameRef = useRef(null);
    const date1Ref = useRef(null);
    const date2Ref = useRef(null);
    const fileRef = useRef(null);
    const btnRef = useRef(null);


    const [files, setfiles] = useState(null);    
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();

    const submitForm = async (event)=>{
        event.preventDefault();
        setalertOpen(false);

        const input = document.getElementById('pdfInput');

        if (input.files.length === 0) {
            setalertType("error");
            setalertOpen(true);
            setalertmessage('Please select a PDF file.');
            return;
        }

        const allowedFormats = ['application/pdf'];
        const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
        const file = input.files[0];

        // Check file format
        if (!allowedFormats.includes(file.type)) {
            setalertType("error");
            setalertOpen(true);
            setalertmessage('Invalid file format. Please choose a valid pdf file.');
            return;
        }

        // Check file size
        // if (file.size > maxFileSize) {
        //     setalertType("error");
        //     setalertOpen(true);
        //     setalertmessage('File size exceeds the maximum allowed limit (2MB).');
        //     return;
        // }

        btnRef.current.disabled = true;
        const currentDateOnly = new Date().toISOString().split('T')[0];

        const fileData = new FormData();
            fileData.append("reportname", nameRef.current.value);
            fileData.append("reportDate", currentDateOnly);
            fileData.append("start_date", date1Ref.current.value);
            fileData.append("end_date", date2Ref.current.value);
            fileData.append("secpreportfile", files);
            fileData.append("id", Number(id));

            const res = await axios.post(`${url}/api/storeSecpReport`,
                fileData,
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
            );
            
            
            const resp = res.data;

            if(resp.status == true){
                Navigate(`/edit-secpt-report/${resp.id}`);
                
            }
            else{
                btnRef.current.disabled = false;
                setalertType("error");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
        
    }


  return (
    <div style={{height: '90vh'}}>
      <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/secp-view"} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Add SECP Report</li>
                </ol>
            </nav>
            <hr/>
        </div>
      <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
        Add SECP Report
      </div>
      <form className ='col-10 card shadow p-3 mx-auto mt-2' onSubmit={(event)=>{submitForm(event)}}>
        <div>
            <div className='fw-bold'>
                Report
            </div>
            <div className='mt-2'>
                <input className='form-control' ref={nameRef} placeholder='Title' required />
            </div>
        </div>
        <div className='row mt-2'>
            <div className='col-5 me-2'>
                <div className='fw-bold'>
                    Start Date
                </div>
                <div className='mt-2'>
                    <input className='form-control' ref={date1Ref} type='date' required />
                </div>
            </div>
            <div className='col-5'>
                <div className='fw-bold'>
                    End Date
                </div>
                <div className='mt-2'>
                    <input className='form-control' ref={date2Ref} type='date' required />
                </div>
            </div>
        </div>
        <div className='mt-2'>
            <div className='fw-bold'>
                File
            </div>
            <div className='mt-2'>
                <input className='form-control' id="pdfInput" ref={fileRef} type='file' accept=".pdf" onChange={(e)=>{
                setfiles(e.target.files[0]);
            }} required />
            </div>
        </div>
        <Box className="mx-auto" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
        </Box>
        <div className='mt-3'>
            <button className='btn btn-primary' ref={btnRef}  type='submit'>Save</button>
        </div>
        </form>
    </div>
  )
}
