import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../../../Config';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";

export default function Ratings_jcr(props) {
    const [rating_type, setrating_type] = useState([]);
    const [rating_scale, setrating_scale] = useState([]);
    const [client, setclient] = useState([]);
    const [action, setaction] = useState([]);
    const [outlook, setoutlook] = useState([]);
    const [l_term, setl_term] = useState([]);
    const [s_term, sets_term] = useState([]);
    const [rating_type_val, setrating_type_val] = useState('');
    const [rating_scale_val, setrating_scale_val] = useState('');
    const [client_val, setclient_val] = useState('');
    const [action_val, setaction_val] = useState('');
    const [outlook_val, setoutlook_val] = useState('');
    const [l_term_val, setl_term_val] = useState('');
    const [s_term_val, sets_term_val] = useState('');
    const [pr, setpr] = useState(null);
    const [report, setreport] = useState(null);

    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();

    const s_termRef = useRef(null);
    const rating_titleRef = useRef(null);
    const ddRef = useRef(null);
    const btnRef = useRef(null);
    

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/ratings_jcr`);
            const resp = res.data
            setrating_type(resp.rating_type);
            setaction(resp.og_actions);
            setoutlook(resp.outlook);
        }

        func();
    },[])

    const submitform = async (event)=>{
        event.preventDefault();
        setalertOpen(false);
        if(rating_type_val == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Rating Type First!`);
            return ;
        }
        if(rating_scale_val == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Rating Scale First!`);
            return ;
        }
        if(client_val == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Entity First!`);
            return ;
        }
        if(action_val == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Rating Action First!`);
            return ;
        }
        if(l_term_val == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Long Term Rating First!`);
            return ;
        }

        btnRef.current.disabled = true;
        const fileData = new FormData();
        fileData.append("rating_type_id", rating_type_val);
        fileData.append("rating_scale_id", rating_scale_val);
        fileData.append("client_id", client_val);
        fileData.append("jcr_action", action_val);
        fileData.append("jcr_outlook", outlook_val);
        fileData.append("jcr_lterm", l_term_val);
        fileData.append("jcr_sterm", s_term_val);
        if(pr !== null && pr !== undefined){
            fileData.append("press_release_jcr", pr);
        }
        fileData.append("client_info", '2');
        fileData.append("jcr_dissemination_date", ddRef.current.value);
        fileData.append("document_check", '0');
        fileData.append("rating_title", rating_titleRef.current.value);

        if(report !== null && report !== undefined){
            fileData.append("sr_jcr", report);
        }

        const res = await axios.post(`${url}/api/addratings_jcr`,
         fileData,
        {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        })

        const resp = res.data;

        if(resp.status == true){
            btnRef.current.disabled = false;
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btnRef.current.disabled = false;
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }

    }

  return (
    <div>
       <div className='fs-4 fw-bold text-center'>
            VIS Ratings Entry
        </div>
        {rating_type.length > 0 ?
            <form className='mt-2 card shadow col-10 mx-auto p-2' onSubmit={(event)=>{submitform(event)}}>
                    <div className='fs-6 fw-bold mt-2'>
                        Rating Type
                    </div>
                    <div className='mt-1'>
                        <select className='form-select' value={rating_type_val} onChange={(e)=>{
                            if(e.target.value != ''){
                                for(let i in rating_type){
                                    if(rating_type[i].id == e.target.value){
                                        setrating_scale(rating_type[i].rating_scale)
                                    }
                                }
                                setrating_type_val(e.target.value)
                                if(Number(e.target.value) == 2 || Number(e.target.value) == 4){
                                    s_termRef.current.hidden = true;
                                    sets_term_val('');
                                }
                                else{
                                    s_termRef.current.hidden = false;
                                }

                            }
                            else{
                                setrating_type_val(e.target.value)
                                setrating_scale([]);
                                setclient([]);
                                setl_term([]);
                                sets_term([]);
                                s_termRef.current.hidden = false;
                            }
                        }}>
                            <option value={''}>Select Rating Type</option>
                            {
                                rating_type.map((value, index)=>{
                                    return(
                                        <option value={value.id}>{value.title}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='fs-6 fw-bold mt-2'>
                        Rating Scale
                    </div>
                    <div className='mt-1'>
                        <select className='form-select' value={rating_scale_val} onChange={(e)=>{
                            if(e.target.value != ''){
                                for(let i in rating_scale){
                                    if(rating_scale[i].id == e.target.value){
                                        setclient(rating_scale[i].clients)
                                    }
                                }
                                setrating_scale_val(e.target.value);
                            }
                            else{
                                setrating_scale_val(e.target.value);
                                setclient([]);
                                setl_term([]);
                                sets_term([]);
                            }
                        }}>
                            <option value={''}>Select Rating Scale</option>
                            {
                                rating_scale.map((value, index)=>{
                                    return(
                                        <option value={value.id}>{value.title}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='fs-6 fw-bold mt-2'>
                        Entity Name
                    </div>
                    <div className='mt-1'>
                        <select className='form-select' value={client_val} onChange={(e)=>{
                            if(e.target.value != ''){
                                for(let i in client){
                                    if(client[i].ID == e.target.value){
                                        setl_term(client[i].l_term);
                                        sets_term(client[i].s_term);
                                    }
                                }
                                setclient_val(e.target.value);
                            }
                            else{
                                setclient_val(e.target.value);
                                setl_term([]);
                                sets_term([]);
                            }
                        }}>
                            <option value={''}>Select Entity Name</option>
                            {
                                client.map((value, index)=>{
                                    return(
                                        <option value={value.ID}>{value.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='fs-6 fw-bold mt-2'>
                        Rating Title
                    </div>
                    <div className='mt-1'>
                    <input className='form-control' ref={rating_titleRef} />
                    </div>
                    <div className='fs-6 fw-bold mt-2'>
                        Action
                    </div>
                    <div className='mt-1'>
                        <select className='form-select' value={action_val} onChange={(e)=>{setaction_val(e.target.value)}}>
                            <option value={''}>Select Rating Action</option>
                            {
                                action.map((value, index)=>{
                                    return(
                                        <option value={value.id}>{value.title}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='fs-6 fw-bold mt-2'>
                        Outlook
                    </div>
                    <div className='mt-1'>
                        <select className='form-select' value={outlook_val} onChange={(e)=>{setoutlook_val(e.target.value)}}>
                            <option value={''}>Select Outlook</option>
                            {
                                outlook.map((value, index)=>{
                                    return(
                                        <option value={value.id}>{value.title}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='fs-6 fw-bold mt-2'>
                        JCR Long Term Rating
                    </div>
                    <div className='mt-1'>
                        <select className='form-select' value={l_term_val} onChange={(e)=>{setl_term_val(e.target.value)}}>
                            <option value={''}>Select Rating</option>
                            {
                                l_term.map((value, index)=>{
                                    return(
                                        <option value={value.id}>{value.title}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div ref={s_termRef}>
                        <div className='fs-6 fw-bold mt-2'>
                            JCR Short Term Rating
                        </div>
                        <div className='mt-1'>
                            <select className='form-select' value={s_term_val} onChange={(e)=>{sets_term_val(e.target.value)}}>
                                <option value={''}>Select Short Term Rating</option>
                                {
                                    s_term.map((value, index)=>{
                                        return(
                                            <option value={value.id}>{value.title}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='fs-6 fw-bold mt-2'>
                        Press Release
                    </div>
                    <div className='mt-1'>
                    <input className='form-control' type='file' onChange={(e)=>{
                        setpr(e.target.files[0]);
                    }} />
                    </div>
                    <div className='fs-6 fw-bold mt-2'>
                        Report
                    </div>
                    <div className='mt-1'>
                    <input className='form-control' type='file' onChange={(e)=>{
                        setreport(e.target.files[0])
                    }} />
                    </div>
                    <div className='fs-6 fw-bold mt-2'>
                        Dissemination Date
                    </div>
                    <div className='mt-1'>
                    <input className='form-control' ref={ddRef} type='date' />
                    </div>
                    <Box className="mx-auto" sx={{ width: "98%" }}>
                        <Collapse in={alertopen}>
                            <Alert
                            severity={alertType}
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setalertOpen(false);
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            >
                            {AlertMessage}
                            </Alert>
                        </Collapse>
                    </Box>
                    <div className='mt-2'>
                        <button className='btn btn-primary' ref={btnRef}>Save</button>
                    </div>
            </form>
            :
            (<div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
        <div class="wrapper" id="loader2">
            <svg
            class="hourglass"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 120 206"
            preserveAspectRatio="none"
            >
            <path
                class="middle"
                d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
            />
            <path
                class="outer"
                d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
            />
            </svg>
        </div>
    </div>)
        }
    </div>
  )
}
