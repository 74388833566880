import React, {useRef, useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Check, Clear, Event } from "@material-ui/icons";
import { Table, TableCell, TableRow } from "@mui/material";
import TableComponent from '../../Atoms/TableComponent';
import { url } from '../../../Config';


export default function SS_grid(props) {

    
    const [data, setdata] = useState([]);


    const getdate = ()=>{

        const currentDate = new Date();
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
        return formattedDate;
    }

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/get_MIT_Sector_study`);
            const resp = res.data;
            setdata(resp.sectorMIT);
        }

        func();
    },[])


    
      function innerDate(params) {
        if (params.data.date == null) {
          return "-";
        } else {
          const date = new Date(params.data.date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };

      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };
    
      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
        //   pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
        //   pinned: responsiveColumnPin(),
        },
        {
          headerName: "Sector Name",
          minWidth: 240,
          field: "name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
        //   pinned: responsiveColumnPin(),
        },
        {
            headerName: "Date",
            field: "date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            hide: columnHide(),
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },
          {
            headerName: "File",
            field: "file",
            hide: columnHide(),
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            quickFilterText: "string",
            cellRenderer: (params) => {
              if (params.value === "nullData") return <Skeleton />;
              if (params.value) {
                return (
                  <a
                    href={`${url}/view/storage/app/${params.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <Check style={{ size: "20 20" }} className="theme_text" />{" "}
                  </a>
                );
              } else {
                return <Clear className="theme_text" />;
              }
            },
          },
          {
            headerName: "Image",
            field: "image",
            hide: columnHide(),
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            quickFilterText: "string",
            cellRenderer: (params) => {
              if (params.value === "nullData") return <Skeleton />;
              if (params.value) {
                return (
                  <a
                    href={`${url}/view/public/sectorStudy/${params.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <Check style={{ size: "20 20" }} className="theme_text" />{" "}
                  </a>
                );
              } else {
                return <Clear className="theme_text" />;
              }
            },
          },
          {
            headerName: "Slider",
            field: "image",
            hide: columnHide(),
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            quickFilterText: "string",
            cellRenderer: (params) => {
              if (params.value === "nullData") return <Skeleton />;
              if (params.value) {
                return (
                  <a
                    href={`${url}/view/public/websiteSlider/${params.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <Check style={{ size: "20 20" }} className="theme_text" />{" "}
                  </a>
                );
              } else {
                return <Clear className="theme_text" />;
              }
            },
          },

      ];


      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
          <Table className="overflow-scroll responsiveTableFonts">

          <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            File
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.value === "nullData" ? (
              <Skeleton />
            ) : params.data.recordid ? (
              <a
                href={`https://209.97.168.200/pacrawizpackv3/storage/app/${params.data.file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <Check style={{ size: "20 20" }} className="theme_text" />{" "}
              </a>
            ) : (
              <Clear className="theme_text" />
            )}
          </TableCell>
        </TableRow>

          <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Image
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.value === "nullData" ? (
              <Skeleton />
            ) : params.data.recordid ? (
              <a
                href={`https://209.97.168.200/pacrawizpackv3/public/sectorStudy/${params.data.image}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <Check style={{ size: "20 20" }} className="theme_text" />{" "}
              </a>
            ) : (
              <Clear className="theme_text" />
            )}
          </TableCell>
        </TableRow>

          <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Image
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.value === "nullData" ? (
              <Skeleton />
            ) : params.data.recordid ? (
              <a
                href={`https://209.97.168.200/pacrawizpackv3/public/websiteSlider/${params.data.image}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <Check style={{ size: "20 20" }} className="theme_text" />{" "}
              </a>
            ) : (
              <Clear className="theme_text" />
            )}
          </TableCell>
        </TableRow>

          </Table>
        </h1>
      );

  return (
    <div>
    <TableComponent
          Data={data}
          screenWidth={props.screenWidth}
          MobViewRender={MobViewRender}
          columnDefs={columnDefs}
          datefilters={datefilters}
          datefilter={false}
          // policy= {true}
      />
    </div>
  )
}
