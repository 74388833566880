import React, {useRef, useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import white from '../../../Assets/Images/whitebg.jpg';
import { url } from '../../../Config';

export default function Mandate_reminder(props) {

    const [data, setdata] = useState([]);

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/get_test_mail_reminder`);
            const resp = res.data.data;
            setdata(resp);
        }

        func();
    },[])

    const getdate = ()=>{

        const currentDate = new Date();
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
        return formattedDate;
    }


  return (
    <div>
        <div className='mt-2 p-2 card shadow' style={{backgroundColor: "#e0e0e0", backgroundImage: `url(${white})`, backgroundSize: "cover"}}>
            <div className='text-center fs-5 fw-bold'>
                {getdate()}
            </div>
            <div className='mt-1 text-center fs-5 fw-bold'>
                Reminder | New Mandate | Please Please Assign
            </div>
            <div className='mt-2'>
                <table className='table border border-dark'>
                    <tbody>
                        {data.map((value, index)=>{
                            return(
                            <tr>
                                <td className='text-center'>
                                {value.title}
                                </td>
                            </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>

        <div className='mt-2 p-3' style={{bottom: 0, position: "fixed", zIndex: 999, width: "100%", backgroundColor: '#000078'}}>
            <div className='text-center' style={{color: 'white'}}>
                For more details visit <a href="http://www.pacra.com/" style={{color: 'white'}}>www.PACRA.com</a>
            </div>
            <div className='text-center fw-bold' style={{color: 'white'}}>
                Its All About Ratings
            </div>
            <div className='text-center fw-bold' style={{color: 'white'}}>
                FB1 Awami Complex Usman Block, New Garden Town Lahore
                    +92 42 3586 9504 - 6
            </div>
        </div>

    </div>
  )
}
