import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import "../../../../index.css";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import bg from "../../../../Assets/Images/greyModel.jpg";
import white from "../../../../Assets/Images/whitebg.jpg";
import { CardContent, Typography } from "@mui/material";
import { Navigate } from "react-router";
import { url } from "../../../../Config";
import SearchableDropdown from "../../../Atoms/SearchableDropdown";
import { encryptParams } from "../../encrypt_url";

export default function Tbs(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#cedbed",
    border: "2px solid #cedbed",
    boxShadow: 24,
    p: 4,
    width: "70%",
    backgroundImage: `url(${white})`,
    backgroundSize: "cover",
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [check, setcheck] = useState("loading");
  const [ratingUpdateType, setratingUpdateType] = useState([]);
  const [r_id, setid] = useState(0);
  const myid = localStorage.getItem("userID");
  const [nullopen, setnullOpen] = useState(false);
  const [open, setOpen] = useState(Array(props.tbs.length).fill(false));
  const [searchby, setsearchby] = useState("All");
  const [rating_update, setrating_update] = useState([]);
  const [ratingType, setratingType] = useState([]);
  const [filterstatus, setfilter] = useState([]);


  useEffect(()=>{
    const func = async ()=>{
      const rating_type = await axios.get(`${url}/api/rating_type`);
      const resp1 = rating_type.data;
      setratingType(resp1.sort((a, b) => a.title.localeCompare(b.title)));

    }
    func();
  },[])


  let filteredOpinions = [];
  filteredOpinions = props.tbs.filter((value) =>
    {
      if(value[searchby] != null && searchby !== "All" && searchTerm !== null){
        return value[searchby].toLowerCase().includes(searchTerm.toLowerCase())
      }
      else if(searchby == "All" && searchTerm !== null){
        let Entity = [];
        if(value['Entity'] !== null){
          Entity = value['Entity'].toLowerCase().includes(searchTerm.toLowerCase());
        }
        let R_type = [];
        if(value['rating_scale'] !== null){
          R_type = value['rating_scale'].toLowerCase().includes(searchTerm.toLowerCase());
        }

        let analystfilter = [];
        if(value['pacraAnalyst'] !== null){
          analystfilter = value['pacraAnalyst'].toLowerCase().includes(searchTerm.toLowerCase());
        }
        let leadfilter = [];
        if(value['managerName'] !== null){
         leadfilter = value['managerName'].toLowerCase().includes(searchTerm.toLowerCase());
        }
    
        return Entity || R_type || analystfilter || leadfilter;
      }
      else if(searchTerm == null){
        return props.tbs;
      }
    }
  );
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOpen = async (index, id, rating_scale) => {
    setratingUpdateType([]);
    setOpen((prevOpen) => {
      const newOpen = [...prevOpen];
      newOpen[index] = true;
      return newOpen;
    });
    const res = await axios.get(`${url}/api/rating_updateType/${id}/${rating_scale}`);
    const resp = res.data;
    setratingUpdateType(resp);
    if(resp.length == 1){
      setid(5);
    }
  };
  const handleClose = (index) => {
    setid(null);
    setnullOpen(false);
    setOpen((prevOpen) => {
      const newOpen = [...prevOpen];
      newOpen[index] = false;
      return newOpen;
    });
  };

  const Initiate = async (id, opinionId, index, manager_id, analyst_id) => {
    if (id == 0 || id == '0') {
        setnullOpen(true);
        document.getElementById("initbtn").disabled = false;
        return;
    } 
    else {
          document.getElementById(`btn${index}`).hidden = true;
          document.getElementById(`icon${index}`).hidden = false;
          const res = await axios.post(
            `${url}/api/initiatetask`,
            {
              id: id,
              opinion: opinionId,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (res.data.message == "initiated") {
            if(Number(myid) == manager_id || Number(myid) == analyst_id){
              const res = await axios.get(`${url}/api/movetoundertaking/${opinionId}`);
              const resp = res.data.data[0];
              handleClose(index);
              props.setValue(1);
              props.setrefresh_tbs((prevrefresh_tbs) => !prevrefresh_tbs);
              props.setrefresh_inprocess((prerefresh_inprocess) => !prerefresh_inprocess);
              const client_id = resp.client_id;
              const og_rating_id = resp.id;
              const company = resp.name;
              const encryptedParams = encryptParams({client_id, og_rating_id, company});
              window.open(`Ratings/undertaking/${encryptedParams}`);
            }
            else{
              props.setchangeorder(true);
              props.setrefresh_inprocess((prerefresh_inprocess) => !prerefresh_inprocess);
              handleClose(index);
              props.setValue(1);
              props.setrefresh_tbs((prevrefresh_tbs) => !prevrefresh_tbs);
            }
          }
    }
  };

  return (
    <div
      className="themeContainer"
      style={props.screenWidth < 500 ? {overflow: "scroll",backgroundImage:`url(${bg})`} : {height: "85vh", overflow: "scroll",backgroundImage:`url(${bg})`}}
    >
      <div>
        <div style={props.screenWidth > 771 ? {position: "fixed", zIndex: 999, width: "100%", backgroundColor: "white"}: null}>
          <div className="col-6 mx-auto p-2 d-flex">
          {searchby == "pacraAnalyst" ?
            <select className="form-select" onChange={(e)=>{setSearchTerm(e.target.value)}}>
              <option>Select Analyst</option>
              <>{
                props.analyst.map((value, index)=>{
                  return <option value={value.pacraAnalyst}>{value.pacraAnalyst}</option>
                })
              }</>
            </select> :
            searchby == "managerName" ?
            <select className="form-select" onChange={(e)=>{setSearchTerm(e.target.value)}}>
              <option>Select Team Lead</option>
              <>{
                props.lead.map((value, index)=>{
                  return <option value={value.managerName}>{value.managerName}</option>
                })
              }</>
            </select> :
            searchby == "Entity" ?
            <SearchableDropdown 
            options={props.tbs}
            label={'Entity'}
            id={"id"}
            selectedVal={searchTerm}
            handleChange={(val) => setSearchTerm(val)}
            /> :
            searchby == "All" ?
            <input
              id="search"
              className="form-control"
              type="text"
              placeholder="Search here.."
              onChange={handleSearch}
            /> :
            <select className="form-select" onChange={(e)=>{setSearchTerm(e.target.value)}}>
              <option>Select Rating Type</option>
            <>{ratingType.map((value, index)=>{
              return(<option value={value.title}>{value.title}</option>);
            })}</>
            </select>}
            <RefreshIcon
              className="ms-1"
              style={{ color: "#000078" }}
              onClick={() => {
                props.setrefresh_tbs((prevrefresh_tbs) => !prevrefresh_tbs);
              }}
            />
          </div>
          <div className="col-4 col-md-4 col-sm-6 col-8 mx-auto">
            <select className="form-select" id="search_select" onChange={(e)=>{
              setsearchby(e.target.value);
              setSearchTerm(null);
            }}>
              <option value={"All"}>All</option>
              <option value={"Entity"}>Client</option>
              <option value={"rating_scale"}>Rating Type</option>
              <option value={"pacraAnalyst"}>Analyst</option>
              <option value={"managerName"}>Team Lead</option>
            </select>
          </div>
        </div>
        {props.screenWidth > 771 && 
        <div style={{visibility: "hidden"}}>
          <div className="col-6 mx-auto p-2 d-flex">
          {searchby == "pacraAnalyst" ?
            <select className="form-select" onChange={(e)=>{setSearchTerm(e.target.value)}}>
              <option>Select Analyst</option>
              <>{
                props.analyst.map((value, index)=>{
                  return <option value={value.pacraAnalyst}>{value.pacraAnalyst}</option>
                })
              }</>
            </select> :
            searchby == "managerName" ?
            <select className="form-select" onChange={(e)=>{setSearchTerm(e.target.value)}}>
              <option>Select Team Lead</option>
              <>{
                props.lead.map((value, index)=>{
                  return <option value={value.managerName}>{value.managerName}</option>
                })
              }</>
            </select> :
            searchby == "Entity" ?
            <SearchableDropdown 
            options={props.tbs}
            label={'Entity'}
            id={"id"}
            selectedVal={searchTerm}
            handleChange={(val) => setSearchTerm(val)}
            /> :
            searchby == "All" ?
            <input
              id="search"
              className="form-control"
              type="text"
              placeholder="Search here.."
              onChange={handleSearch}
            /> :
            <select className="form-select" onChange={(e)=>{setSearchTerm(e.target.value)}}>
              <option>Select Rating Type</option>
            <>{ratingType.map((value, index)=>{
              return(<option value={value.title}>{value.title}</option>);
            })}</>
            </select>}
            <RefreshIcon
              className="ms-1"
              style={{ color: "#000078" }}
              onClick={() => {
                props.setrefresh_tbs((prevrefresh_tbs) => !prevrefresh_tbs);
              }}
            />
          </div>
          <div className="col-4 col-md-4 col-sm-6 col-8 mx-auto">
            <select className="form-select" id="search_select" onChange={(e)=>{
              setsearchby(e.target.value);
              setSearchTerm(null);
            }}>
              <option value={"All"}>All</option>
              <option value={"Entity"}>Client</option>
              <option value={"rating_scale"}>Rating Type</option>
              <option value={"pacraAnalyst"}>Analyst</option>
              <option value={"managerName"}>Team Lead</option>
            </select>
          </div>
        </div>}
        <div className="row ms-lg-5 ms-md-5 ms-sm-4 ms-2">
          {props.tbs.length > 0 ? (
            <>
              {filteredOpinions.map((value, index) => {
                return (
                  <>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-1 mt-1">
                    <Card
                      onClick={() => {
                        handleOpen(index, value.Id, value.rating_scale);
                      }}
                      key={index}
                      className="card shadow col-lg-11 col-md-11 col-sm-11 col-12 hover-overlay ripple"
                      sx={{
                        backgroundImage: `url(${white})`,
                        backgroundColor: "white",
                        backgroundSize: "cover",
                        height : "100%"
                      }}
                    >
                     
                      <CardContent>
                      <div className="fw-bold fs-4 mb-2" style={{fontFamily: "Kalinga"}}>{value.Entity}</div>
                        <Typography
                          sx={{
                            color: "black",
                            fontFamily: "Kalinga",
                            textShadow:
                              "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white",
                          }}
                        >
                          {" "}
                          <b>Team Lead:</b> {value.managerName}
                        </Typography>
                        <Typography
                          sx={{
                            color: "black",
                            fontFamily: "Kalinga",
                            textShadow:
                              "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white",
                          }}
                        >
                          {" "}
                          <b>Analyst:</b> {value.pacraAnalyst}
                        </Typography>
                        {
                          value.prevratings == 'Initial' ? 
                          <Typography
                          sx={{
                            color: "black",
                            fontFamily: "Kalinga",
                            textShadow:
                              "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white",
                          }}
                        >
                          {" "}
                          <b>Previous Rating:</b> Initial Client
                        </Typography> :
                        value.rating_scale == 'Entity' || value.rating_scale == 'Debt Instrument' ?
                        <>
                        <Typography
                          sx={{
                            color: "black",
                            fontFamily: "Kalinga",
                            textShadow:
                              "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white",
                          }}
                        >
                          {" "}
                          <b>Previous Rating (Long Term/Short Term):</b> {value.prevratings.lterm}/{value.prevratings.sterm}
                        </Typography>

                        </> :
                        value.rating_scale == "Performance Ranking" ?
                        <>
                        <Typography
                          sx={{
                            color: "black",
                            fontFamily: "Kalinga",
                            textShadow:
                              "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white",
                          }}
                        >
                          {" "}
                          <b>Previous Rating (1 year/3 year/5 year):</b> {value.prevratings.sterm}/{value.prevratings.lterm}/{value.prevratings['5year']}
                        </Typography>
                        </> :
                        <Typography
                        sx={{
                          color: "black",
                          fontFamily: "Kalinga",
                          textShadow:
                            "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white",
                        }}
                      >
                        {" "}
                        <b>Previous Rating:</b> {value.prevratings.lterm}
                      </Typography>

                        }
                      </CardContent>
                    </Card>
                  </div>
                    <Modal
                      open={open[index]}
                      onClose={() => {
                        handleClose(index);
                      }}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box className="col-lg-10 col-md-10 col-12 p-2" sx={style}>
                      <div className ="text-end mb-0">
                        <CloseIcon onClick={()=>{handleClose(index)}} />
                      </div>
                        <Box sx={{ width: "100%" }}>
                          <Collapse in={nullopen}>
                            <Alert severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setnullOpen(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                              sx={{ mb: 2 }}
                            >
                              You can't initialize without selecting any Rating Update Type!
                            </Alert>
                          </Collapse>
                        </Box>
                      {ratingUpdateType.length > 0 ? <>
                        <div
                          className="fs-4 fw-bold text-center"
                          style={{ fontFamily: "Kalinga" }}
                        >
                          Rating Update Type
                        </div>
                        <div className="col-lg-5 col-md-5 col-10 mx-auto mt-1">
                          <select
                            className="form-select"
                            onChange={(event) => setid(event.target.value)}
                          >
                            {ratingUpdateType.length > 1 && <option value={0}>
                              Select Rating Update Type
                            </option>}
                            {ratingUpdateType.map((value, index) => {
                              return (
                                <option value={value.id}>{value.title}</option>
                              );
                            })}
                          </select>
                        </div>
                        <div
                          className="mx-auto mt-2"
                          style={{ width: "fit-content" }}
                        >
                          <button
                            className="button btn btn-primary"
                            id="initbtn"
                            disabled = {false}
                            onClick={() => {
                              document.getElementById("initbtn").disabled = true;
                              Initiate(r_id, value.Id, index, value.user_id1, value.user_id);
                            }}
                          >
                            {" "}
                            <div id={`btn${index}`}>Initiate</div>
                            <div id={`icon${index}`} hidden={true}>
                              <CircularProgress size={17} />
                            </div>{" "}
                          </button>
                        </div>
                        </> :
                      <div className="mx-auto" style={{width: "fit-content"}}><CircularProgress size={20} /></div>}
                      </Box>
                    </Modal>
                  </>
                );
              })}
            </>
          ) : (
            <div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
              <div class="wrapper" id="loader2">
                <svg
                  class="hourglass"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 120 206"
                  preserveAspectRatio="none"
                >
                  <path
                    class="middle"
                    d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
                  />
                  <path
                    class="outer"
                    d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
        <div className="card mt-1 pt-2 d-flex" style={{bottom: 0, position: "fixed", zIndex: 999, width: "100%"}}>
            <div className="text-center">
              <b>Total Clients:</b> {props.tbs.length}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Filtered Clients:</b> {filteredOpinions.length}
            </div>
          </div>
      </div>
    </div>
  );
}
