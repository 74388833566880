import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { url } from '../../../../Config';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';

export default function Editgroup(props) {

    const [data, setdata] = useState([]);
    const Navigate = useNavigate();
    const [value, setValue] = useState('');
    const [bool, setbool] = useState(false);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const userid = localStorage.getItem("userID");

    
    useEffect(()=>{
        setdata([]);
        const func = async ()=>{
            const res = await axios.get(`${url}/api/editGroupDashboard`);
            const resp = res.data;
            setdata(resp.clientsgroups);
        }

        func();
    },[bool])

    const edit = async (id, input)=>{
        const val = document.getElementById(input).value;
        const res = await axios.post(`${url}/api/insertGroup`, {
            'groupid' : Number(id),
            'groupname' : val,
            'login_user_id': Number(userid)
        })
        const resp = res.data;
        if(resp.status == true){
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
            document.getElementById(`Group${id}`).disabled = true;
            document.getElementById(`Edit${id}`).hidden = false;
            document.getElementById(`Save${id}`).hidden = true;
        }
        else{
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
    }

    const deleteitem = async (id)=>{
        Swal.fire({ title: 'Do you want to delete this Group?', 
        showDenyButton: false, showCancelButton: true, confirmButtonText: 'Delete'})
        .then(async(result) => {
            if (result.isConfirmed) {
                const res = await axios.post(`${url}/api/delGroup`, {
                    'id' : Number(id),
                })
                const resp = res.data;
                if(resp.status == true){
                    setbool(!bool);
                    setalertType("success");
                    setalertOpen(true);
                    setalertmessage(resp.message);
                }
                else{
                    setalertType("error");
                    setalertOpen(true);
                    setalertmessage(resp.message);
                }
            }
        })
    }

  return (
    <div style={{height: '90vh'}}>
    <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            Edit Group
    </div>
    <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/client-entry-view"} >Grids</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Edit Group</li>
                </ol>
            </nav>
            <hr/>
    </div>
      <div className='p-3'>
      <Box className="mx-auto" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
        </Box>
      <div className='mt-2 col-10 mx-auto' style={{height: '400px', overflow: 'scroll'}}>
            <table className='table table-striped table-hover table-bordered border-dark'>
                <thead>
                    <tr>
                        <th className='col-6'>Name</th>
                        <th className='col-2'>Action</th>

                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((value, index)=>{
                            return(
                                <tr>
                                    <td><input className='form-control' defaultValue={value.title} id={`Group${value.id}`} disabled/></td>
                                    <td><button className='btn btn-warning btn-sm me-1' id={`Edit${value.id}`} onClick={()=>{
                                        document.getElementById(`Group${value.id}`).disabled = false;
                                        document.getElementById(`Edit${value.id}`).hidden = true;
                                        document.getElementById(`Save${value.id}`).hidden = false;
                                        }}>Edit</button>
                                    <button className='btn btn-primary btn-sm me-1' id={`Save${value.id}`} hidden onClick={()=>{edit(value.id, `Group${value.id}`)}}>Save</button>
                                    <button className='btn btn-danger btn-sm' onClick={()=>{deleteitem(value.id)}}>Delete</button></td>
                                </tr>
                            )
                        })
                    }
                    
                </tbody>
            </table>
        </div>
      </div>
    </div>
  )
}
