import React, { useEffect, useState } from "react";
import Login from "./Routs/Login";
import Dashboard from "./Dashboard";
import ProtectedRoutes from "../middleWare/ProtectedRouting/ProtectedRute";
import { Routes, Route, Navigate } from "react-router-dom";
import Pplview from "./Routs/Ratings/views/pplview";
import Nlview from "./Routs/Ratings/views/nlview";
import Auth from "../middleWare/Auth/Auth";
import { useLocation } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap. css';

const MainComponent = () => {

  const location = useLocation();
  const [prev, setprev] = useState(null);


  useEffect(()=>{
    if(location.pathname !== '/login' && location.pathname !== '/'){
      setprev(location);
    }
    let User = JSON.parse(localStorage.getItem('loggedIn'));
    const now = new Date();
    if(User !== null && User.expiry !== null && User.expiry !== undefined){
        if (now.getTime() > User.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.clear();
            window.open('/login', '_self');

        } 
    }
    else{
          localStorage.clear();
    }

  },[location])



  return (
    <Routes>
      {localStorage.getItem("loggedIn") ? null : (
        <Route path="/login" element={<Login prev={prev} />} />
      )}

      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/Ratings/pplview/:recordid" element={<Pplview />} />
        <Route path="/Ratings/nlview/:recordid" element={<Nlview />} />
        <Route path="/*" element={<Dashboard />} />
      </Route>
    </Routes>
  );
};

export default MainComponent;