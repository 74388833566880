import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../../../Config';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";


export default function Old_sr_jcr(props) {

    const [entity, setentity] = useState([]);
    const [entity_val, setentity_val] = useState('');
    const [files, setfiles] = useState(null);

    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const btnRef = useRef(null);


    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/old_sr_jcr`);
            const resp = res.data
            setentity(resp.data);
        }

        func();
    },[])

    function DateFormat(params) {
        if (params == null) {
          return "-";
        } else {
          const date = new Date(params);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
    const submitForm = async (event)=>{

        event.preventDefault();
        setalertOpen(false);

        btnRef.current.disabled = true;
        const fileData = new FormData();
        fileData.append("recordId", entity_val);
        fileData.append("sr_jcr", files);

        const res = await axios.post(`${url}/api/addOld_sr_jcr`,
        fileData,
       {
           headers: {
           "Content-Type": "multipart/form-data",
           },
       })

       const resp = res.data;

       if(resp.status == true){
           btnRef.current.disabled = false;
           setalertType("success");
           setalertOpen(true);
           setalertmessage(resp.message);
       }
       else{
           btnRef.current.disabled = false;
           setalertType("error");
           setalertOpen(true);
           setalertmessage(resp.message);
       }

    }

  return (
    <div style={{height: '90vh'}}>
      <div className='fs-4 fw-bold text-center'>
                VIS Report Update
        </div>
        <form className='mt-2 card shadow col-10 mx-auto p-2' onSubmit={(event)=>{submitForm(event)}}>
            <div className='fs-6 fw-bold mt-2'>
                Entity
            </div>
            <div className='mt-1'>
                <select className='form-select' value={entity_val} onChange={(e)=>{setentity_val(e.target.value)}}>
                    <option>Select Rating Agency</option>
                    {
                        entity.map((value, index)=>{
                            return(
                                <option value={value.record_id}>{value.Entity} | {DateFormat(value.DisseminationDate)}</option>
                            )
                        })
                    }
                </select>
            </div>
            <div className='fs-6 fw-bold mt-2'>
                Report
            </div>
            <div>
                <input className='form-control' type='file' ref={files} onChange={(e)=>{setfiles(e.target.files[0])}} required />
            </div>
            <Box className="mx-auto" sx={{ width: "98%" }}>
                        <Collapse in={alertopen}>
                            <Alert
                            severity={alertType}
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setalertOpen(false);
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            >
                            {AlertMessage}
                            </Alert>
                        </Collapse>
            </Box>
            <div className='mt-2'>
                    <button className='btn btn-primary' ref={btnRef}>Save</button>
            </div>
        </form>
    </div>
  )
}
