import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Outstanding from "../Home/Outstanding";
import InProcess from "../Home/InProcess";
import GetData from "../../../API/GetData";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Fab } from "@mui/material";
import { Popover } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import InitialSubTabs from "../Home/InitialSubTabs";
import WithdrawSubTabs from "../Home/WithdrawSubTabs";
import DeadLSubTabs from "../Home/DeadLSubTabs";
import DoneSubTabs from "../Home/DoneSubTabs";
import InstrumentSubTabs from "../Home/InstrumentSubTabs";
import PvtRatings from "../Home/PvtRatings";
import Message from "./message";
import MyInprocess from "./MyInprocess";
import Tbs from "./TBS/Tbs";
import axios from "axios";
import { url } from "../../../Config";
import TeamleadInvites from "../Home/Invites/TeamleadInvites";
const rightsval = localStorage.getItem("rights");
const id = localStorage.getItem("userID");
  
  let rights = [];

  if(rightsval !== null){
    rights = rightsval.split(",");
  }

  const has_invite_right = rights.includes("42");

export default function Index(props) {
  const classes = useStyles();
  const [value, setValue] = useState(1);
  const [myinprocess, setmyinprocess] = useState([]);
  const [tbs, settbs] = useState([]);
  const [refresh_inprocess, setrefresh_inprocess] = useState(false);
  const [changeorder, setchangeorder] = useState(false);
  const [refresh_tbs, setrefresh_tbs] = useState(false);
  const [analyst, setanalyst] = useState([]);
  const [lead, setlead] = useState([]);
  const [analyst1, setanalyst1] = useState([]);
  const [lead1, setlead1] = useState([]);
  const [inviteClientsList, setinviteClientsList] = useState([]);
  const [refresh_invite, setrefresh_invite] = useState([]);

  // eslint-disable-next-line
  const [state, setstate] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function useWindowSize() {
    const [size, setsize] = useState([window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setsize([window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
    }, []);
    return size;
  }
  
  const [screenWidth] = useWindowSize();
  const id = localStorage.getItem("userID");

  useEffect(()=>{
    const func = async ()=>{
      settbs([]);
      const resp1 = await axios.get(
        `${url}/api/TBS`
      );
      const data1 = resp1.data;
      const arr1 = [];
      for(let i in data1){
        if(data1[i].user_id == id || data1[i].lead_rc_id == id || data1[i].user_id1 == id || id == "42" || id == "12"){
            arr1.push(data1[i]);
        }
      }
      if(window.location.hostname == 'localhost'){
        settbs(arr1);
        const filteredData = filterData(arr1);
        setlead1(filteredData);
        const filteredData2 = filterData2(arr1);
        setanalyst1(filteredData2); 
      }
      else{
        settbs(arr1.sort((a, b) => a.Entity.localeCompare(b.Entity)));
        const filteredData = filterData(arr1);
        setlead1(filteredData.sort((a, b) => a.managerName.localeCompare(b.managerName)));
        const filteredData2 = filterData2(arr1);
        setanalyst1(filteredData2.sort((a, b) => a.pacraAnalyst.localeCompare(b.pacraAnalyst))); 
      }
      

    }
    func();
  },[refresh_tbs])

  useEffect(()=>{

    const func = async ()=>{
      setmyinprocess([]);
      if(changeorder == true){
        const resp2 = await axios.get(
          `${url}/api/myinprocess/${id}`
        );
        const data2 = resp2.data;
        const arr2 = [];
        for(let i in data2){
          if(data2[i].user_id == id || data2[i].lead_rc_id == id || data2[i].user_id1 == id || id == "42" || id == "12"){
              arr2.push(data2[i]);
          }
        } 

        setmyinprocess(arr2);

        if(window.location.hostname == 'localhost'){
          const filteredData = filterData(arr2);
          setlead(filteredData);
          const filteredData2 = filterData2(arr2);
          setanalyst(filteredData2);
          setchangeorder(false);
        }
        else{
          const filteredData = filterData(arr2);
          setlead(filteredData.sort((a, b) => a.managerName.localeCompare(b.managerName)));
          const filteredData2 = filterData2(arr2);
          setanalyst(filteredData2.sort((a, b) => a.pacraAnalyst.localeCompare(b.pacraAnalyst)));
          setchangeorder(false);
        }
       
        
        if(id == "19" || id == "42" || id == "12"){
          localStorage.setItem("myinprocess", JSON.stringify(arr2));
        }
      }
      else{

        const resp2 = await axios.get(
          `${url}/api/myinprocess/${id}`
        );
        const data2 = resp2.data;
        const arr2 = [];
        for(let i in data2){
          if(data2[i].user_id == id || data2[i].lead_rc_id == id || data2[i].user_id1 == id || id == "42" || id == "12"){
              arr2.push(data2[i]);
          }
        } 

        if(window.location.hostname == 'localhost'){
          setmyinprocess(arr2);
          const filteredData = filterData(arr2);
          setlead(filteredData);
          const filteredData2 = filterData2(arr2);
          setanalyst(filteredData2);
        }
        else{
          setmyinprocess(arr2.sort((a, b) => a.Entity.localeCompare(b.Entity)));
          const filteredData = filterData(arr2);
          setlead(filteredData.sort((a, b) => a.managerName.localeCompare(b.managerName)));
          const filteredData2 = filterData2(arr2);
          setanalyst(filteredData2.sort((a, b) => a.pacraAnalyst.localeCompare(b.pacraAnalyst)));
        }

        if(id == "19" || id == "42" || id == "12"){
          localStorage.setItem("myinprocess", JSON.stringify(arr2));
        }
      }
      }
    func();

  },[refresh_inprocess])

  useEffect(()=>{
    const func = async ()=>{
      const res = await axios.get(`${url}/api/inviteClientsList/${id}`);
      const entered_clients = res.data.entered_clients;
      
      setinviteClientsList(entered_clients);

    }
    func();
  },[refresh_invite])

  const filterData = (data) => {
    const uniqueEntries = new Set();
    return data.filter((item) => {
      const {managerName} = item;
      const entry = `${managerName}`;
      if (!uniqueEntries.has(entry)) {
        uniqueEntries.add(entry);
        return true;
      }
      return false;
    });
  };
  
  const filterData2 = (data) => {
    const uniqueEntries = new Set();
    return data.filter((item) => {
      const {pacraAnalyst } = item;
      const entry = `${pacraAnalyst}`;
      if (!uniqueEntries.has(entry)) {
        uniqueEntries.add(entry);
        return true;
      }
      return false;
    });
  };


  return (
    <div className={classes.root}>
      {props.screenWidth > 770 ? (
        <AppBar
          position="fixed"
          className={` ${classes.topMargin} ${
            props.open
              ? classes.leftMargin
              : props.screenWidth < 700
              ? classes.responsiveLeft
              : classes.left
          }`}
          color="default"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            className="theme_text"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="My-TBS" {...a11yProps(1)} />
            <Tab label="My-Inprocess" {...a11yProps(2)} />
            <Tab label="Outstanding" {...a11yProps(3)} />
            <Tab label="In-Process" {...a11yProps(4)} />
            <Tab label="Done" {...a11yProps(5)} />
            <Tab label="Deadline" {...a11yProps(6)} />
            <Tab label="Initial" {...a11yProps(7)} />
            <Tab label="Withdraw" {...a11yProps(8)} />
            <Tab label="Inst" {...a11yProps(9)} />
            <Tab label="Pvt Ratings" {...a11yProps(10)} />
            {(Number(id) !== 19) && <Tab label="Client Invitation" {...a11yProps(11)} />}
          </Tabs>
        </AppBar>
      ) : (
        <PopupState
          variant="popover"
          popupId="demo-popup-popover"
          className={`height_TabsBar ${classes.topMargin} ${
            props.open ? classes.leftMargin : classes.responsiveLeft
          }`}
        >
          {(popupState) => (
            <>
              <Fab
                color="transparent"
                aria-label="edit"
                variant="extended"
                className={`ms-2 fabCustom ${
                  (value === 8 && classes.topAbsoluteSubTabs) ||
                  (value === 2 && classes.topAbsoluteSubTabs) ||
                  (value === 3 && classes.topAbsoluteSubTabs) ||
                  (value === 4 && classes.topAbsoluteSubTabs) ||
                  (value === 5 && classes.topAbsoluteSubTabs) ||
                  (value === 6 && classes.topAbsoluteSubTabs) ||
                  classes.topAbsolute
                }`}
              >
                <MoreVertIcon {...bindTrigger(popupState)} />
              </Fab>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  orientation="vertical"
                  indicatorColor="primary"
                  className="theme_text"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab
                    onClick={() => {
                      popupState.close();
                    }}
                    label="My-TBS"
                    {...a11yProps(1)}
                  />
                  <Tab
                    onClick={() => {
                      popupState.close();
                    }}
                    label="My-Inprocess"
                    {...a11yProps(2)}
                  />
                  <Tab
                    onClick={() => {
                      popupState.close();
                    }}
                    label="Outstanding"
                    {...a11yProps(3)}
                  />
                  <Tab
                    onClick={() => {
                      popupState.close();
                    }}
                    label="In-Process"
                    {...a11yProps(4)}
                  />
                  <Tab
                    onClick={popupState.close}
                    label="Done"
                    {...a11yProps(5)}
                  />
                  <Tab
                    onClick={popupState.close}
                    label="Deadline"
                    {...a11yProps(6)}
                  />
                  <Tab
                    onClick={() => {
                      popupState.close();
                    }}
                    label="Initial"
                    {...a11yProps(7)}
                  />
                  <Tab
                    onClick={() => {
                      popupState.close();
                    }}
                    label="Withdraw"
                    {...a11yProps(8)}
                  />
                  <Tab
                    onClick={() => {
                      popupState.close();
                    }}
                    label="Inst"
                    {...a11yProps(9)}
                  />
                  <Tab
                    onClick={() => {
                      popupState.close();
                    }}
                    label="Pvt Ratings"
                    {...a11yProps(10)}
                  />
                  {(has_invite_right == true && Number(id) !== 19) &&
                  <Tab
                    onClick={() => {
                      popupState.close();
                    }}
                    label="Client Invitation"
                    {...a11yProps(11)}
                  />}
                </Tabs>
              </Popover>
            </>
          )}
        </PopupState>
      )}
      {/* <div className='container'> */}
      <TabPanel value={value} index={0}>
       <Tbs
       analyst={analyst1} 
       lead={lead1}
       setValue = {setValue} 
       tbs={tbs} 
       screenWidth={screenWidth}
       setchangeorder={setchangeorder}
       setrefresh_inprocess = {setrefresh_inprocess}
       setrefresh_tbs={setrefresh_tbs} />
       {/* <Message /> */}
      </TabPanel>

      <TabPanel value={value} index={1}>
       <MyInprocess
       analyst={analyst} 
       lead={lead}
       myinprocess={myinprocess} 
       setrefresh_inprocess={setrefresh_inprocess} 
       screenWidth={screenWidth}
       refresh_tbs={refresh_tbs} />
       {/* <Message /> */}
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Outstanding
          // stating={state1}
          state={state}
          Outstanding={props.OutstandingDataArray}
          // setOutstanding={props.setOutstandingDataArray}
          screenWidth={props.screenWidth}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <InProcess
          // stating={state2}
          setInProcess={props.setInProcessDataArray}
          InProcess={props.InProcessDataArray}
          screenWidth={props.screenWidth}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <DoneSubTabs
          state={state}
          screenWidth={props.screenWidth}
          DoneRc={props.DoneRc}
          setDoneRc={props.setDoneRc}
          DoneDiss={props.DoneDiss}
          setDoneDiss={props.setDoneDiss}
          DoneIc={props.DoneIc}
          setDoneIc={props.setDoneIc}
          DoneFc={props.DoneFc}
          setDoneFc={props.setDoneFc}
          mainTabValue={value}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <DeadLSubTabs
          state={state}
          screenWidth={props.screenWidth}
          DeadlineRc={props.DeadlineRc}
          setDeadlineRc={props.setDeadlineRc}
          DeadlineDiss={props.DeadlineDiss}
          DeadlineDiss2={props.DeadlineDiss2}
          setDeadlineDiss={props.setDeadlineDiss}
          setDeadlineDiss2={props.setDeadlineDiss2}
          DeadlineIc={props.DeadlineIc}
          setDeadlineIc={props.setDeadlineIc}
          DeadlineFc={props.DeadlineFc}
          setDeadlineFc={props.setDeadlineFc}
          // OutstandingDead={props.OTDLineArray}
          UnFinished={props.UnFinishedDataArray}
          setUnFinished={props.setUnFinishedDataArray}
          mainTabValue={value}
        />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <InitialSubTabs
          state={state}
          screenWidth={props.screenWidth}
          Initial={props.InitialDataArray}
          setInitial={props.setInitialDataArray}
          MNA={props.InMNADataArray}
          setMNA={props.setInMNADataArray}
          InCob={props.InCobDataArray}
          setInCob={props.setInCobDataArray}
          InICU={props.InICUDataArray}
          setInICU={props.setICUDataArray}
          mainTabValue={value}
        />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <WithdrawSubTabs
          screenWidth={props.screenWidth}
          Withdraw={props.WithdrawDataArray}
          setWithdraw={props.setWithdrawDataArray}
          InRIP={props.InRIPDataArray}
          setInRIP={props.setInRIPDataArray}
          mainTabValue={value}
        />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <InstrumentSubTabs
          Inst={props.InstDataArray}
          setInst={props.setInstDataArray}
          InstP={props.InstPDataArray}
          setInstP={props.setInstPDataArray}
          screenWidth={props.screenWidth}
          mainTabValue={value}
        />
      </TabPanel>
      <TabPanel value={value} index={9}>
        <PvtRatings
          // stating={state14}
          PvtRatings={props.PvtRatingsDataArray}
          setPvtRatings={props.setPvtRatingsDataArray}
          screenWidth={props.screenWidth}
        />
      </TabPanel>
      {Number(id) !== 19 &&
      <TabPanel value={value} index={10}>
        <TeamleadInvites screenWidth={props.screenWidth}
        inviteClientsList={inviteClientsList}
        setrefresh_invite ={setrefresh_invite}
        refresh_invite = {refresh_invite} />
      </TabPanel >}
      {/* </div> */}
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      className={`card ${props.screenWidth > 770 ? "mt-4" : "negative_margin"}`}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  topMargin: {
    top: "50px",
  },
  leftMargin: {
    width: `calc(100% - ${240}px)`,
  },
  left: {
    width: `calc(100% - ${74}px)`,
  },
  responsiveLeft: {
    width: "100%",
  },
  topAbsolute: {
    top: "4px",
  },
  topAbsoluteSubTabs: {
    top: "45px",
  },
}));
