import  React, { useEffect, useState }  from 'react'
import img from "../../../../Assets/Images/PACRA_logo.png"
import axios from 'axios';
import "../views/print.css";
import { green } from '@mui/material/colors';
import { useParams } from "react-router-dom";
import { url } from '../../../../Config';


export default function Nlview() {
    const { recordid } = useParams();
    const [data, setdata] = useState(null);
    const [to_name_chunks, setto_name_chunks] = useState(null);
    const [nl, setnl] = useState({});
    const [opinions, setopinions] = useState([]);
    const [action, setaction] = useState(null);


    function DateFormat(params) {
        if (params == null) {
          return "-";
        } else {
          const date = new Date(params);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

    function useWindowSize() {
        const [size, setsize] = useState([window.innerWidth]);
        useEffect(() => {
              const handleResize = () => {
                setsize([window.innerWidth]);
              };
              window.addEventListener("resize", handleResize);
            }, []);
            return size;
          }
        const [screenWidth] = useWindowSize();

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/viewNL/${recordid}`);
            const resp = res.data;
            setdata(resp);
            setnl(resp.nl[0]);

            let opinion = resp.nl[0].opinion.split(",");
            let action = [];
            if(resp.nl[0].action !== null){
              action = resp.nl[0].action.split(",");
            }
            setaction(action[0]);
            let outlook = [];
            if(resp.nl[0].outlook !== null){
              outlook = resp.nl[0].outlook.split(",");
            }
            let long_term_rating = [];
            if(resp.nl[0].long_term_rating !== null){
              long_term_rating = resp.nl[0].long_term_rating.split(",");
            }
            let p_long_term_rating = [];
            if(resp.nl[0].p_long_term_rating !== null){
              p_long_term_rating = resp.nl[0].p_long_term_rating.split(",")
            }
            let short_term_rating = [];
            if(resp.nl[0].short_term_rating !== null){
              short_term_rating = resp.nl[0].short_term_rating.split(",")
            }
            let p_short_term_rating = [];
            if(resp.nl[0].p_short_term_rating !== null){
              p_short_term_rating = resp.nl[0].p_short_term_rating.split(",")
            }
            let rating_type = [];
            if(resp.nl[0].rating_type !== null){
              rating_type = resp.nl[0].rating_type.split(",");
            }
            let category = [];
            if(resp.nl[0].category !== null){
              category = resp.nl[0].category.split(",");
            }
            let rating_five_yr = [];
            if(resp.nl[0].rating_five_yr !== null){
               rating_five_yr = resp.nl[0].rating_five_yr.split(",");
            }
            let reviewEndDate = [];
            if(resp.nl[0].reviewEndDate !== null){
               reviewEndDate = resp.nl[0].reviewEndDate.split(",");
            }
            let arr = [];
            for(let i in opinion){
              arr.push({opinion : opinion[i], action : action[i], outlook : outlook[i], long_term_rating : long_term_rating[i],
              p_long_term_rating : p_long_term_rating[i], short_term_rating : short_term_rating[i], 
              p_short_term_rating : p_short_term_rating[i], rating_type : rating_type[i], category : category[i],
              rating_five_yr : rating_five_yr[i], reviewEndDate : reviewEndDate[i]})
            }
            console.log(resp.nl, "nl");
            setopinions(arr);
            setto_name_chunks(resp.to_name_chunks[0]);
        }
        func();

    },[])

  return (
    <div className='divprint'>
      <div className='d-none d-print-block mt-0' style={{backgroundColor: "white"}}>
      {data !== null && 
      <div className=''>
        <div className='mx-auto' style={{width:"fit-content"}}>
            <img src={img} style={{height: "50px", width: "50px"}}></img>
        </div>
        <div className={`text-center fw-bold ${screenWidth > 576 && 'fs-5'}`} style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
            The Pakistan Credit Rating Agency Limited
        </div>
        <hr />
        <div className='row'>
            <div className='col-6 text-start' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
                NL FY-{data.nl[0].financial_year}-{data.nl[0].nl_number}
            </div>
            <div className='col-6 text-end'>
                <div className='text-decoration-underline fw-bold' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
                    {data.nl[0].confidential}
                </div>
                <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
                    {DateFormat(data.nl[0].date)}
                </div>
            </div>
        </div>
        <div className='fw-bold' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.nl[0].to_name}</div>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.nl[0].to_designation}</div>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.nl[0].company}</div>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.nl[0].address}</div> <br/>
        <div className='text-center fw-bold fs-6' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", color: "#000078"} : {fontFamily: "Kalinga", color: "#000078"}}>{data.nl[0].company}</div>
        <div className='text-center fw-bold fs-6' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", color: "#000078"} : {fontFamily: "Kalinga", color: "#000078"}}>
        {
            data.nl[0].record_id =="Security Agency Grading" ? "Security Grading" : 
            data.nl[0].record_id =="Debt Instrument" ? "Rating" : 
            data.nl[0].record_id =="Stability Rating" || data.nl[0].record_id =="Capital Protection"  ? "Mutual Fund Ratings" : 
            data.nl[0].record_id =="Performance Ranking" ? "Ranking" : 
            data.nl[0].record_id =="Project Grading" ? "Project Grading" : "Ratings"

        } | {nl.opinion_type}
        </div>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>Dear {to_name_chunks == "Mr" ? "Sir" : to_name_chunks == "Mrs" || to_name_chunks == "Ms" ? "Madam" : to_name_chunks !== "Mrs" ? "Sir" : null}</div>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.nl[0].withdraw_text}</div>
        <br/>
        <div className='mt-1 mx-auto' style={{width: "98%"}}>
        {
          nl.action == "Redeem" ?
          <>
          <table className='table' style={{border: "1px solid black"}}>
                <tr>
                    <td className='text-center' rowSpan="2" style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}> <strong>Opinion</strong></td>
                    <td className='text-center' rowSpan="2" style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}> <strong>Action</strong></td>
                    <td className='text-center' colspan="2" style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Previous Rating</strong></td>
                    <td className='text-center' rowSpan="2" style={{backgroundColor: "#bfbfbf"}}><strong>Opinion Type</strong></td>
                </tr>

                <tr>
                    <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Long Term</b></td>
                    <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Outlook</b></td>
                </tr>
                <tr>
                    <td className='text-center' style={{border: "1px solid black"}}>{nl.opinion}</td>
                    <td className='text-center' style={{border: "1px solid black"}}>{nl.action}</td>
                    <td className='text-center' style={{border: "1px solid black"}}>{nl.p_long_term == null ? "-" : nl.p_long_term}</td>
                    <td className='text-center' style={{border: "1px solid black"}}>{nl.outlook == null ? "-" : nl.outlook}</td>
                    <td className='text-center' style={{border: "1px solid black"}}>{nl.rating_type}</td>
                </tr>
            </table>
            </> : 
            nl.record_id == "Performance Ranking" ?
            <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga"}}><strong>Fund Name</strong></td>
                    <td colspan="3" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga"}}><strong>Ranking</strong></td>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga"}}><strong>Category</strong></td>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga"}}><strong>Opinion Type</strong></td>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga"}}><strong>Review Period End</strong></td>
                </tr>
                <tr>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga"}}><b>1 Year</b></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga"}}><b>3 Year</b></td>
                    <td style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga"}}><b>5 Year</b></td>
                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                    <tr>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga"}}>{value.opinion}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga"}}>{value.short_term_rating}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga"}}>{value.long_term_rating}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga"}}>{value.rating_five_yr}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga"}}>{value.category}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga"}}>{value.rating_type}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga"}}>{DateFormat(value.reviewEndDate)}</td>
                    </tr>
                    )
                  })
                }
              </tbody>
            </table>
            <div className='mt-1' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{nl.other_text}</div>
            </> :
            nl.record_id == "Stability Rating" || nl.record_id == "Performance Ranking" || nl.record_id == "Capital Protection" ?
            <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga"}}><strong>Mutual Fund</strong></td>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga"}}><strong>Action</strong></td>
                    <td className='text-center' colSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga"}}><strong>Rating</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga"}}><strong>Outlook</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga"}}><b>Current</b></td>
                    <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga"}}><b>Previous</b></td>
                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                      <tr>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga"}}>{value.opinion}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga"}}>{value.action}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga"}}>{value.long_term_rating}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga"}}>{value.p_long_term_rating}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga"}}>{value.outlook}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga"}}>{value.rating_type}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </> : 
             nl.record_id == "Security Agency Grading" || nl.record_id == "Developer Grading" || nl.record_id == "Project Grading" || nl.record_id == "REIT" ?
             <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion</strong></td>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Action</strong></td>
                    <td className='text-center' colSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Grading</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Outlook</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating Watch</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Current</b></td>
                    <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Previous</b></td>
                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                      <tr>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.opinion}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.action}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.long_term_rating}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.p_long_term_rating == null || value.p_long_term_rating == "" ? "-" : value.p_long_term_rating}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.outlook}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{nl.rating_watch == "-" || nl.rating_watch == null ? "-" : nl.rating_watch}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.rating_type}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </> : 
            nl.record_id == "IFS" ?
            <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion</strong></td>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Action</strong></td>
                    <td className='text-center' colSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Outlook</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating Watch</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Current</b></td>
                    <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Previous</b></td>
                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                      <tr>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.opinion}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.action}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.long_term_rating}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.p_long_term_rating == null || value.p_long_term_rating == "" ? "-" : value.p_long_term_rating}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.outlook}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{nl.rating_watch == "-" || nl.rating_watch == null ? "-" : nl.rating_watch}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.rating_type}</td>
                    </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </> : 
            nl.record_id == "Broker Management Rating" ?
            <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion</strong></td>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Action</strong></td>
                    <td className='text-center' colSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Outlook</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating Watch</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' colspan={action == "Initial" ? "2" : "1"} style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Current</b></td>
                    {action !== "Initial" && <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Previous</b></td>}
                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                      <tr>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.opinion}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.action}</td>
                        <td className='text-center' colspan={action == "Initial" ? "2" : "1"} style={{border: "1px solid black"}}>{value.long_term_rating}</td>
                        {action !== "Initial" && <td className='text-center' style={{border: "1px solid black"}}>{value.p_long_term_rating == null || value.p_long_term_rating == "" ? "-" : value.p_long_term_rating}</td>}
                        <td className='text-center' style={{border: "1px solid black"}}>{value.outlook}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{nl.rating_watch == "-" || nl.rating_watch == null ? "-" : nl.rating_watch}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.rating_type}</td>
                    </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </> : 
            nl.record_id == "BFR" ?
            <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion</strong></td>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Action</strong></td>
                    {action !== "Initial" ? <td className='text-center' colSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating</strong></td> :
                    <td className='text-center' colSpan='1' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating</strong></td>}
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Outlook</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating Watch</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Current</b></td>
                    {action !== "Initial" && <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Previous</b></td>}
                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                      <tr>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.opinion}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.action}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.long_term_rating}</td>
                        {action !== "Initial" && <td className='text-center' style={{border: "1px solid black"}}>{value.p_long_term_rating == null || value.p_long_term_rating == "" ? "-" : value.p_long_term_rating}</td>}
                        <td className='text-center' style={{border: "1px solid black"}}>{value.outlook}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{nl.rating_watch == "-" || nl.rating_watch == null ? "-" : nl.rating_watch}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.rating_type}</td>
                    </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </> :
            nl.record_id == "Entity" || nl.record_id == "Debt Instrument" ?
            <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' rowSpan='3' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion</strong></td>
                    <td className='text-center' rowSpan='3' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Action</strong></td>
                    <td className='text-center' colSpan='4' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Rating</strong></td>
                    <td rowSpan="3" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Outlook</strong></td>
                    <td rowSpan="3" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Rating Watch</strong></td>
                    <td rowSpan="3" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' colSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><b>Long Term</b></td>
                    <td className='text-center' colSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><b>Short Term</b></td>
                </tr>
                <tr>
                    <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><b>Current</b></td>
                    <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><b>Previous</b></td>
                    <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><b>Current</b></td>
                    <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}><b>Previous</b></td>
                </tr>
                <tr>

                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                      <tr>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{value.opinion}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{value.action}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{value.long_term_rating == null || value.long_term_rating == "" ? "-" : value.long_term_rating}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{value.p_long_term_rating == null || value.p_long_term_rating == "" ? "-" : value.p_long_term_rating}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{value.short_term_rating == null || value.short_term_rating == "" ? "-" : value.short_term_rating}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{value.p_short_term_rating == null || value.p_short_term_rating == "" ? "-" : value.p_short_term_rating}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{value.outlook}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{nl.rating_watch == "-" || nl.rating_watch == null ? "-" : nl.rating_watch}</td>
                        <td className='text-center' style={{border: "1px solid black", fontFamily: "Kalinga", fontSize: "10px"}}>{value.rating_type}</td>
                    </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </> :
            <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion</strong></td>
                    <td className='text-center' rowSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Action</strong></td>
                    <td className='text-center' colSpan='2' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Outlook</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating Watch</strong></td>
                    <td rowSpan="2" className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Current</b></td>
                    <td className='text-center' style={{backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Previous</b></td>
                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                      <tr>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.opinion}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.action}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.long_term_rating}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.p_long_term_rating == null || value.p_long_term_rating == "" ? "-" : value.p_long_term_rating}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.outlook}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{nl.rating_watch == "-" || nl.rating_watch == null ? "-" : nl.rating_watch}</td>
                        <td className='text-center' style={{border: "1px solid black"}}>{value.rating_type}</td>
                    </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </>
            }

        </div>
        <div>
          {
          nl.security == 2 && action == "Initial" && <p>Since this is an Initial Rating, it is not being made public as per your request.</p>
          }
        </div> <br />
        <div>
          {
            nl.designation == "Chief Operating Officer" ?
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> Yours sincerely </div> : 
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{nl.y_truly} </div>
          }
        </div>
        <br/>
        <div>
          {
            nl.second_approve == null ? "" :
            nl.second_approve !== null && nl.s_name == 10 ?
            <>
            <img src={'data:image/jpg;base64,'+data.get_coo[0].sign} style={{width: "120px", height: "60px"}} />
            <br/>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
            <b>({data.get_coo[0].name})</b>
            </div>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
              {data.get_coo[0].designation}
            </div>
            </> :
            nl.second_approve !== null && nl.s_name == 19 ?
            <>
            <img src={'data:image/jpg;base64,'+data.get_jh[0].sign} style={{width: "120px", height: "60px"}} />
            <br/>
            {
              nl.date > '2020-09-22' ?
              <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
                <b>({nl.uh1})</b>
              </div> :
               <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
               <b>({data.get_jh[0].name})</b>
               </div>
            }
            {
              nl.date > '2022-01-01' ?
              <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
              {nl.s_designation}
              </div> :
              <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
              {data.get_jh[0].designation} | Ratings
              </div>
            }

            </> :
            nl.second_approve !== null && nl.s_name == 17 ?
            <>
            <img src={'data:image/jpg;base64,'+data.get_rai[0].sign} style={{width: "120px", height: "60px"}} />
            <br/>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
            <b>({data.get_rai[0].name})</b>
            </div>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
              {data.get_rai[0].designation} | Ratings
            </div>
            </> :
            nl.second_approve !== null && nl.s_name == 22 ?
            <>
            <img src={'data:image/jpg;base64,'+data.get_amara[0].sign} style={{width: "120px", height: "60px"}} />
            <br/>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
            <b>({data.get_amara[0].name})</b>
            </div>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
              {data.get_amara[0].designation} | Ratings
            </div>
            </> :
            nl.second_approve !== null && nl.s_name == 42 ?
            <>
            <img src={'data:image/jpg;base64,'+data.get_ad[0].sign} style={{width: "120px", height: "60px"}} />
            <br/>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
            <b>({data.get_ad[0].name})</b>
            </div>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
              {data.get_ad[0].designation} | Ratings
            </div>
            </> :
            nl.second_approve !== null && nl.s_name == 33 ?
            <>
            <img src={'data:image/jpg;base64,'+data.get_rn[0].sign} style={{width: "120px", height: "60px"}} />
            <br/>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
            <b>({data.get_rn[0].name})</b>
            </div>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
              {data.get_rn[0].designation} | Ratings
            </div>
            </> : null

          }
        </div>
        <br />
        <div>
          Encl: 1) Press Release <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2) Rating Report
        </div>
        <div class="divFooter  container text_footer" id="c2">
            <hr className="new4" />
            <div className='row'>
                <p className='col text-start mb-0 fw-bold' style={{fontSize: "12px"}}>The Pakistan Credit Rating Agency Limited [PACRA]</p>
                <p className='col text-end mb-0' style={{fontSize: "12px"}}>TEL: 92(42)3586 9504</p>
            </div>
            <div className='row'>
                <p className='col text-start mb-0' style={{fontSize: "12px"}}>Awami Complex FB-1, Usman Block, New Garden Town, </p>
                <p className='col text-end mb-0' style={{fontSize: "12px"}}>Fax: 92(42)3583 0425</p>
            </div>
            <div className='row'>
                <p className='col text-start mb-0' style={{fontSize: "12px"}}>Lahore - 54600, Pakistan </p>
                <p className='col text-end mb-0' style={{fontSize: "12px"}}>www.pacra.com</p>
            </div>
        </div>
      </div>}
      </div>
      <div className='card d-print-none'>
      {data !== null && 
      <div className={`card shadow ${screenWidth < 576 ? 'col-11 mt-3' : 'col-10 mt-5'} mx-auto p-3`}>
        <div className='mx-auto mt-2' style={{width:"fit-content"}}>
            <img src={img} style={{height: "50px", width: "50px"}}></img>
        </div>
        <div className={`text-center fw-bold ${screenWidth > 576 && 'fs-5'}`} style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
            The Pakistan Credit Rating Agency Limited
        </div>
        <hr />
        <div className='row'>
            <div className='col-6 text-start' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
                NL FY-{data.nl[0].financial_year}-{data.nl[0].nl_number}
            </div>
            <div className='col-6 text-end'>
                <div className='text-decoration-underline fw-bold' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
                    {data.nl[0].confidential}
                </div>
                <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
                    {DateFormat(data.nl[0].date)}
                </div>
            </div>
        </div>
        <div className='fw-bold' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.nl[0].to_name}</div>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.nl[0].to_designation}</div>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.nl[0].company}</div>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.nl[0].address}</div>
        <div className='text-center fw-bold fs-6' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", color: "#000078"} : {fontFamily: "Kalinga", color: "#000078"}}>{data.nl[0].company}</div>
        <div className='text-center fw-bold fs-6' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", color: "#000078"} : {fontFamily: "Kalinga", color: "#000078"}}>
        {
            data.nl[0].record_id =="Security Agency Grading" ? "Security Grading" : 
            data.nl[0].record_id =="Debt Instrument" ? "Rating" : 
            data.nl[0].record_id =="Stability Rating" || data.nl[0].record_id =="Capital Protection"  ? "Mutual Fund Ratings" : 
            data.nl[0].record_id =="Performance Ranking" ? "Ranking" : 
            data.nl[0].record_id =="Project Grading" ? "Project Grading" : "Ratings"

        } | {nl.opinion_type}
        </div>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>Dear {to_name_chunks == "Mr" ? "Sir" : to_name_chunks == "Mrs" || to_name_chunks == "Ms" ? "Madam" : to_name_chunks !== "Mrs" ? "Sir" : null}</div>
        <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{data.nl[0].withdraw_text}</div>
        <br/>
        <div style={{overflow: "auto"}}>
        {
          nl.action == "Redeem" ?
          <>
          <table className='table' style={{border: "1px solid black"}}>
                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}} rowSpan="2"> <strong>Opinion</strong></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}} rowSpan="2"> <strong>Action</strong></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}} colspan="2"><strong>Previous Rating</strong></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}} rowSpan="2"><strong>Opinion Type</strong></td>
                </tr>

                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Long Term</b></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Outlook</b></td>
                </tr>
                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{nl.opinion}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{nl.action}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{nl.p_long_term == null ? "-" : nl.p_long_term}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{nl.outlook == null ? "-" : nl.outlook}</td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{nl.rating_type}</td>
                </tr>
            </table>
            </> : 
            nl.record_id == "Performance Ranking" ?
            <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}} rowSpan='2'><strong>Fund Name</strong></td>
                    <td colspan="3" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Ranking</strong></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}} rowSpan='2'><strong>Category</strong></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}} rowSpan='2'><strong>Opinion Type</strong></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}} rowSpan='2'><strong>Review Period End</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>1 Year</b></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>3 Year</b></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>5 Year</b></td>
                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                      <tr>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.opinion}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.short_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.long_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.rating_five_yr}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.category}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.rating_type}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{DateFormat(value.reviewEndDate)}</td>
                    </tr>
                    )
                  })
                }
              </tbody>
            </table>
            <br />
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{nl.other_text}</div>
            </> :
            nl.record_id == "Stability Rating" || nl.record_id == "Performance Ranking" || nl.record_id == "Capital Protection" ?
            <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' rowSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Mutual Fund</strong></td>
                    <td className='text-center' rowSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Action</strong></td>
                    <td className='text-center' colSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Outlook</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating Watch</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Current</b></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Previous</b></td>
                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                      <tr>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.opinion}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.action}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.long_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.p_long_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.outlook}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{nl.rating_watch == "-" || nl.rating_watch == null ? "-" : nl.rating_watch}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.rating_type}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </> : 
             nl.record_id == "Security Agency Grading" || nl.record_id == "Developer Grading" || nl.record_id == "Project Grading" || nl.record_id == "REIT" ?
             <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' rowSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion</strong></td>
                    <td className='text-center' rowSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Action</strong></td>
                    <td className='text-center' colSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Grading</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Outlook</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating Watch</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Current</b></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Previous</b></td>
                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                      <tr>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.opinion}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.action}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.long_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.p_long_term_rating == null || value.p_long_term_rating == "" ? "-" : value.p_long_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.outlook}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{nl.rating_watch == "-" || nl.rating_watch == null ? "-" : nl.rating_watch}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.rating_type}</td>
                    </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </> : 
            nl.record_id == "IFS" ?
            <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' rowSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion</strong></td>
                    <td className='text-center' rowSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Action</strong></td>
                    <td className='text-center' colSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Outlook</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating Watch</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Current</b></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Previous</b></td>
                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                      <tr>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.opinion}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.action}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.long_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.p_long_term_rating == null || value.p_long_term_rating == "" ? "-" : value.p_long_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.outlook}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{nl.rating_watch == "-" || nl.rating_watch == null ? "-" : nl.rating_watch}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.rating_type}</td>
                    </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </> : 
            nl.record_id == "Broker Management Rating" ?
            <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' rowSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion</strong></td>
                    <td className='text-center' rowSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Action</strong></td>
                    <td className='text-center' colSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Outlook</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating Watch</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' colspan={action == "Initial" ? "2" : "1"} style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Current</b></td>
                    {action !== "Initial" && <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Previous</b></td>}
                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                      <tr>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.opinion}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.action}</td>
                        <td className='text-center' colspan={action == "Initial" ? "2" : "1"} style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.long_term_rating}</td>
                        {action !== "Initial" && <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.p_long_term_rating == null || value.p_long_term_rating == "" ? "-" : value.p_long_term_rating}</td>}
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.outlook}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{nl.rating_watch == "-" || nl.rating_watch == null ? "-" : nl.rating_watch}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.rating_type}</td>
                    </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </> : 
            nl.record_id == "BFR" ?
            <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' rowSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion</strong></td>
                    <td className='text-center' rowSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Action</strong></td>
                    <td className='text-center' colSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Outlook</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating Watch</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Current</b></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Previous</b></td>
                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                      <tr>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.opinion}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.action}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.long_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.p_long_term_rating == null || value.p_long_term_rating == "" ? "-" : value.p_long_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.outlook}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{nl.rating_watch == "-" || nl.rating_watch == null ? "-" : nl.rating_watch}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.rating_type}</td>
                    </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </> :
            nl.record_id == "Entity" || nl.record_id == "Debt Instrument" ?
            <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' rowSpan='3' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion</strong></td>
                    <td className='text-center' rowSpan='3' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Action</strong></td>
                    <td className='text-center' colSpan='4' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating</strong></td>
                    <td rowSpan="3" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Outlook</strong></td>
                    <td rowSpan="3" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating Watch</strong></td>
                    <td rowSpan="3" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' colSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Long Term</b></td>
                    <td className='text-center' colSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Short Term</b></td>
                </tr>
                <tr>

                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Current</b></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Previous</b></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Current</b></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Previous</b></td>
                </tr>
                <tr>

                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                      <tr>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.opinion}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.action}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.long_term_rating == null || value.long_term_rating == "" ? "-" : value.long_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.p_long_term_rating == null || value.p_long_term_rating == "" ? "-" : value.p_long_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.short_term_rating == null || value.short_term_rating == "" ? "-" : value.short_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.p_short_term_rating == null || value.p_short_term_rating == "" ? "-" : value.p_short_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.outlook}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{nl.rating_watch == "-" || nl.rating_watch == null ? "-" : nl.rating_watch}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.rating_type}</td>
                    </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </> :
            <>
            <table className='table' style={{border: "1px solid black"}}>
              <tbody>
                <tr>
                    <td className='text-center' rowSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion</strong></td>
                    <td className='text-center' rowSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Action</strong></td>
                    <td className='text-center' colSpan='2' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Outlook</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Rating Watch</strong></td>
                    <td rowSpan="2" className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><strong>Opinion Type</strong></td>
                </tr>
                <tr>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Current</b></td>
                    <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", backgroundColor: "#bfbfbf", border: "1px solid black"} : {fontFamily: "Kalinga", backgroundColor: "#bfbfbf", border: "1px solid black"}}><b>Previous</b></td>
                </tr>
                {
                  opinions.map((value, index)=>{
                    return(
                      <tr>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.opinion}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.action}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.long_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.p_long_term_rating == null || value.p_long_term_rating == "" ? "-" : value.p_long_term_rating}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.outlook}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{nl.rating_watch == "-" || nl.rating_watch == null ? "-" : nl.rating_watch}</td>
                        <td className='text-center' style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px", border: "1px solid black"} : {fontFamily: "Kalinga", border: "1px solid black"}}>{value.rating_type}</td>
                    </tr>
                    )
                  })
                }
              </tbody>
            </table>
            </>
            }

        </div>
        <div>
          {
          nl.security == 2 && action == "Initial" && <p>Since this is an Initial Rating, it is not being made public as per your request.</p>
          }
        </div> <br />
        <div>
          {
            nl.designation == "Chief Operating Officer" ?
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}> Yours sincerely </div> : 
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>{nl.y_truly} </div>
          }
        </div>
        <br/>
        <div>
          {
            nl.second_approve == null ? "" :
            nl.second_approve !== null && nl.s_name == 10 ?
            <>
            <img src={'data:image/jpg;base64,'+data.get_coo[0].sign} style={{width: "120px", height: "60px"}} />
            <br/>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
            <b>({data.get_coo[0].name})</b>
            </div>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
              {data.get_coo[0].designation}
            </div>
            </> :
            nl.second_approve !== null && nl.s_name == 19 ?
            <>
            <img src={'data:image/jpg;base64,'+data.get_jh[0].sign} style={{width: "120px", height: "60px"}} />
            <br/>
            {
              nl.date > '2020-09-22' ?
              <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
                <b>({nl.uh1})</b>
              </div> :
               <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
               <b>({data.get_jh[0].name})</b>
               </div>
            }
            {
              nl.date > '2022-01-01' ?
              <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
              {nl.s_designation}
              </div> :
              <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
              {data.get_jh[0].designation} | Ratings
              </div>
            }

            </> :
            nl.second_approve !== null && nl.s_name == 17 ?
            <>
            <img src={'data:image/jpg;base64,'+data.get_rai[0].sign} style={{width: "120px", height: "60px"}} />
            <br/>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
            <b>({data.get_rai[0].name})</b>
            </div>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
              {data.get_rai[0].designation} | Ratings
            </div>
            </> :
            nl.second_approve !== null && nl.s_name == 22 ?
            <>
            <img src={'data:image/jpg;base64,'+data.get_amara[0].sign} style={{width: "120px", height: "60px"}} />
            <br/>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
            <b>({data.get_amara[0].name})</b>
            </div>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
              {data.get_amara[0].designation} | Ratings
            </div>
            </> :
            nl.second_approve !== null && nl.s_name == 42 ?
            <>
            <img src={'data:image/jpg;base64,'+data.get_ad[0].sign} style={{width: "120px", height: "60px"}} />
            <br/>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
            <b>({data.get_ad[0].name})</b>
            </div>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
              {data.get_ad[0].designation} | Ratings
            </div>
            </> :
            nl.second_approve !== null && nl.s_name == 33 ?
            <>
            <img src={'data:image/jpg;base64,'+data.get_rn[0].sign} style={{width: "120px", height: "60px"}} />
            <br/>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
            <b>({data.get_rn[0].name})</b>
            </div>
            <div style={screenWidth < 576 ? {fontFamily: "Kalinga", fontSize: "12px"} : {fontFamily: "Kalinga"}}>
              {data.get_rn[0].designation} | Ratings
            </div>
            </> : null

          }
        </div>
        <br />
        <div>
          Encl: 1) Press Release <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{action !== 'Withdraw' && '2) Rating Report'}
        </div>
      </div>}
    </div>
    </div>
  )
}
