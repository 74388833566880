import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../../Config';
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from "uuid";
import { Link } from 'react-router-dom';



export default function AddDescToHeadings(props) {
    const { id } = useParams();
    const [company1, setcompany1] = useState([{ id: uuidv4() }]);
    const [mainheading, setmainheading] = useState([]);
    const [subheading, setsubheading] = useState([]);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();

    const btn = useRef(null);


    useEffect(()=>{

        const func = async ()=>{
            const res = await axios.get(`${url}/api/postcreatemodel/${id}`);
            const resp = res.data;
            setmainheading(resp.mainheading)
            let arr = [];
            for(let i in resp.mainheading){
                for(let j in resp.mainheading[i].subheading){
                    arr.push({id: resp.mainheading[i].subheading[j].id, desc: resp.mainheading[i].subheading[j].desc});
                }
            }
            setsubheading(arr);
        }

        func();
    },[])

    const submitForm = async (event)=>{
        event.preventDefault();
        
        btn.current.disabled = true;
        btn.current.innerHTML = "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";

        const res = await axios.post(`${url}/api/add_desc`,{
            'subheading': subheading
        })

        const resp = res.data;
        if(resp.status == true){
            btn.current.disabled = false;
            btn.current.innerHTML = "Submit";

            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("success");
        }
        else{
            btn.current.disabled = false;
            btn.current.innerHTML = "Submit";

            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error");
        }
        

    }

  return (
    <div>
        <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/model-dashboard"} >Assessment Format Grid</Link></li>
                    <li className="breadcrumb-item" ><Link to={`/post-create-model/${id}`} >View Model</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Add Description</li>
                </ol>
            </nav>
            <hr/>
        </div>
        <div className='mt-2 fs-4 fw-bold text-center'>
            Add Description to Headings accordingly
        </div>
        <form className='card shadow mx-auto col-10 mt-2 p-2' onSubmit={(event)=>{submitForm(event)}}>
            {
                mainheading.map((value, index)=>{
                    return(
                        <div>
                            <div className='fw-bold fs-5 text-center mb-2'>
                               {value.name}
                            </div>
                            <table className='table table-bordered border-dark table-striped table-hover'>
                                <tbody>
                                    {
                                        value.subheading.map((value2, index2)=>{
                                             return(
                                                <tr>
                                                    <td className='col-4'>{value2.name}</td>
                                                    <td className='col-8'><input className='form-control' defaultValue={value2.desc} onChange={(e)=>{
                                                        let arr = [...subheading];
                                                        for(let i in arr){
                                                            if(arr[i].id == value2.id){
                                                                arr[i].desc = e.target.value;
                                                            }
                                                        }
                                                        // arr[index][index2] = {id: value2.id, desc: e.target.value};
                                                        setsubheading(arr);

                                                    }} /></td>
                                                </tr>
                                                    )
                                        })
                                    }
                                </tbody>
                            </table>
        
                        </div>
                    )
                })
            }
            <Box className="mx-auto mt-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                    severity={alertType}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setalertOpen(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                        sx={{ mb: 2 }}
                    >
                    {AlertMessage}
                    </Alert>
                </Collapse>
                </Box>
            <div className='mt-2 mx-auto' style={{width: 'fit-content'}}>
                <button className='btn btn-primary' type='submit' ref={btn}>Submit</button>
            </div>
        </form>
    </div>
  )
}
