import React, {useState, useEffect, useRef} from 'react';
import { url } from '../../../../../Config';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';


export default function Viewsponsers(props) {

    const [data, setdata] = useState(null);
    const { sponser_id } = useParams();
    const Navigate = useNavigate();

    const rightsval = localStorage.getItem("rights");
  
    let rights = [];
  
    if(rightsval !== null){
      rights = rightsval.split(",");
    }
  
    const has_right = rights.includes("3");
    const can_edit_after_approval = rights.includes("51");

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/UpdateShareholders/${sponser_id}`);
            const resp = res.data;
            setdata(resp.shareholder);

        }

        func();
    },[])


  return (
    <div style={{height: '90vh'}}>
        <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/secp-dashboard"} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Sponsors/Sharehoders</li>
                </ol>
            </nav>
            <hr/>
        </div>
      <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
        Sponsor/Sharehoder Detail
     </div>

     {
     data !== null &&
     <>
    <div className='mt-2 col-11 mx-auto d-flex justify-content-end'>
        {(has_right == true || can_edit_after_approval == true || data.status == 'submit' || data.status == null) && <Tooltip title="Edit"><button className='btn btn-warning btn-sm me-1 mb-1' onClick={()=>Navigate(`/edit-shareholder/${sponser_id}`)}><EditIcon /></button></Tooltip>}
     </div>
     <div className='card shadow col-11 mx-auto'>
        <table className="table table-bordered border-dark">
            <tbody>
                <tr className="text-center">
                    <td className="table-secondary fw-bold ">Sharehoder/Sponsor</td>
                    <td className="">{data.name}</td>
                </tr>
                <tr className="text-center">
                    <td className="table-secondary fw-bold ">No. of Shares</td>
                    <td className="">{data.no_of_shares.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                </tr>
                <tr className="text-center">
                    <td className="table-secondary fw-bold ">Shares Percentage</td>
                    <td className="">{data.shares_percent}</td>
                </tr>
                <tr className="text-center">
                    <td className="table-secondary fw-bold ">Association</td>
                    <td className="">{data.association}</td>
                </tr>
                <tr className="text-center">
                    <td className="table-secondary fw-bold ">Position in company</td>
                    <td className="">{data.position_company}</td>
                </tr>
                <tr className="text-center">
                    <td className="table-secondary fw-bold ">Introduction</td>
                    <td className="">{data.introduction}</td>
                </tr>
                <tr className="text-center">
                    <td className="table-secondary fw-bold ">Image</td>
                    <td className=""><img src={`${url}/api/getImage/${data.image_path}`} style={{wdith: '50px', height: '50px'}} /></td>
                </tr>
            </tbody>

        </table>
     </div>
     </>
     }
     
    </div>
  )
}
