import React, {useState, useEffect, useRef} from 'react';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import axios from 'axios';
import { url } from '../../../Config';
import { useParams } from "react-router-dom";
import { Link, useNavigate } from 'react-router-dom';


export default function Edit_client_details(props) {

    const id = localStorage.getItem("userID");
    const { client_id } = useParams();
    const [cities, setcities] = useState([]);
    const [legalstatuses, setlegalstatuses] = useState([]);
    const [citiesval, setcitiesval] = useState('');
    const [legalstatusesval, setlegalstatusesval] = useState('');
    const [ceo_respect, setceo_respect] = useState('Mr.');
    const [Liaison1respect, setLiaison1respect] = useState('Mr.');
    const [Liaison2respect, setLiaison2respect] = useState('Mr.');
    const [ceo_desig, setceo_desig] = useState('MD');
    const [Liaison1desig, setLiaison1desig] = useState('MD');
    const [Liaison2desig, setLiaison2desig] = useState('MD');
    const [ceo_tier, setceo_tier] = useState('Tier-I');
    const [Liaison1tier, setLiaison1tier] = useState('Tier-I');
    const [Liaison2tier, setLiaison2tier] = useState('Tier-I');
    const [address, setaddress] = useState('');
    const [name, setname] = useState('');
    const [data, setdata] = useState({});
    const [bool, setbool] = useState(false);


    const [ceo_arr, setceo_arr] = useState(Array(8));
    const [L1_arr, setL1_arr] = useState(Array(8));
    const [L2_arr, setL2_arr] = useState(Array(8));

    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();

    const ceo_F = useRef(null);
    const Ceo_S = useRef(null);
    const ceo_office_no = useRef(null);
    const ceo_cell = useRef(null);
    const ceo_email = useRef(null);
    const L1_F = useRef(null);
    const L1_S = useRef(null);
    const L1_office_no = useRef(null);
    const L1_cell = useRef(null);
    const L1_email = useRef(null);
    const L2_F = useRef(null);
    const L2_S = useRef(null);
    const L2_office_no = useRef(null);
    const L2_cell = useRef(null);
    const L2_email = useRef(null);
    const addressRef = useRef(null);
    const btnref = useRef(null);



    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/edit_client/${client_id}`);
            const resp = res.data;
            setdata(resp.client_details[0]);
            setname(resp.client_details[0].client_name);
            setcities(resp.cities);
            setaddress(resp.client_details[0].address);
            setlegalstatuses(resp.legalstatuses);

            const ceo = resp.client_details[0].ceo.split(',');
            setceo_arr(ceo);
            setceo_respect(ceo[0]);
            if(ceo_F.current !== null){
                ceo_F.current.value = ceo[1];
            }
            if(Ceo_S.current !== null){
                Ceo_S.current.value = ceo[2];
            }
            setceo_desig(ceo[3]);

            if(ceo_office_no.current !== null){
                ceo_office_no.current.value = ceo[4];
            }

            if(ceo_cell.current !== null){
                ceo_cell.current.value = ceo[5];
            }
            if(ceo_email.current !== null){
                ceo_email.current.value = ceo[6];
            }

            setceo_tier(ceo[7])

            const L1 = resp.client_details[0].liaison_one.split(',');
            setL1_arr(L1);
            setLiaison1respect(L1[0]);
            if(L1_F.current !== null){
                L1_F.current.value = L1[1]
            }
            if(L1_S.current !== null){
                L1_S.current.value = L1[2]
            }
            setLiaison1desig(L1[3]);

            if(L1_office_no.current !== null){
                L1_office_no.current.value = L1[4];
            }

            if(L1_cell.current !== null){
                L1_cell.current.value = L1[5];
            }
            if(L1_email.current !== null){
                L1_email.current.value = L1[6];
            }

            setLiaison1tier(L1[7]);

            const L2 = resp.client_details[0].liaison_two.split(',');
            setL2_arr(L2);
            if(L2_F.current !== null){
                L2_F.current.value = L2[1]
            }
            if(L2_S.current !== null){
                L2_S.current.value = L2[2]
            }
            setLiaison2respect(L2[0]);
            setLiaison2desig(L2[3]);

            
            if(L2_office_no.current !== null){
                L2_office_no.current.value = L2[4];
            }

            if(L2_cell.current !== null){
                L2_cell.current.value = L2[5];
            }
            if(L2_email.current !== null){
                L2_email.current.value = L2[6];
            }

            setLiaison2tier(L2[7]);


        }

        func();
        
    },[ceo_F.current, bool])

    const editform = async (event)=>{
        event.preventDefault();
        setalertOpen(false);
        btnref.current.disabled = true;

        const res = await axios.post(`${url}/api/editceoname`,{
            'user_id': id,
            'client_id': Number(client_id),
            'address': address,
            'ceo_name_title': ceo_respect,
            'ceoFirstName': ceo_F.current.value,
            'ceoSecondName': Ceo_S.current.value,
            'ceo_desig': ceo_desig,
            'ceo_office_no': ceo_office_no.current.value,
            'ceo_cell_no': ceo_cell.current.value,
            'ceo_email': ceo_email.current.value,
            'ceo_tier': ceo_tier,
            'liaison_one_title': Liaison1respect,
            'liaison_one_first_name': L1_F.current.value,
            'liaison_one_second_name': L1_S.current.value,
            'liaison_one_desig': Liaison1desig,
            'liaison_one_office_no': L1_office_no.current.value,
            'liaison_one_cell_no': L1_cell.current.value,
            'liaison_one_email': L1_email.current.value,
            'liaison_one_tier': Liaison1tier,
            'liaison_two_title': Liaison2respect,
            'liaison_two_first_name': L2_F.current.value,
            'liaison_two_second_name': L2_S.current.value,
            'liaison_two_desig': Liaison2desig,
            'liaison_two_office_no': L2_office_no.current.value,
            'liaison_two_cell_no': L2_cell.current.value,
            'liaison_two_email': L2_email.current.value,
            'liaison_two_tier': Liaison2tier,
        })

        const resp = res.data;
        if(resp.status == true){
            btnref.current.disabled = false;
            setbool(!bool);
            setalertType("success");
            setalertOpen(true);
            setalertmessage('Client Updated Successfully!');
        }
        else{
            btnref.current.disabled = false;
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
    }


  return (
    <div>
      <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            {name}
      </div>
      <form className='col-10 card shadow p-3 mx-auto mt-lg-4 mt-md-4 mt-2' onSubmit={(event)=>{editform(event)}}>
      <div>
                <div className='mt-2 fw-bold'>
                    CEO:
                </div>
                <div className='mt-2'>
                    <select className='form-control' value={ceo_respect} onChange={(e)=>{setceo_respect(e.target.value)}}>
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="">-</option>
                    </select>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='First Name' ref={ceo_F} required/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='Second Name' ref={Ceo_S} required/>
                </div>
                <div className='mt-1'>
                    <select className='form-control' value={ceo_desig} onChange={(e)=>{setceo_desig(e.target.value)}}>
                        <option value='MD'>MD</option>
                        <option value='CEO'>CEO</option>
                        <option value='President'>President</option>
                        <option value='Chairman'>Chairman</option>
                        <option value='President & CEO'>President & CEO</option>
                    </select>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='Office No' ref={ceo_office_no}/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='Cell No' ref={ceo_cell}/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' placeholder='Email' ref={ceo_email}/>
                </div>
                <div className='mt-1'>
                    <select className='form-control' value={ceo_tier} onChange={(e)=>{setceo_tier(e.target.value)}}>
                        <option value="Tier-I">Tier-I</option>
                        <option value="Tier-II">Tier-II</option>
                        <option value="Tier-III">Tier-III</option>
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Liaison Person 1:
                </div>
                <div className='mt-2'>
                    <select className='form-control' value={Liaison1respect} onChange={(e)=>{setLiaison1respect(e.target.value)}}>
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="">-</option>
                    </select>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='First Name' ref={L1_F} required/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='Second Name' ref={L1_S} required/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' value={Liaison1desig} onChange={(e)=>{setLiaison1desig(e.target.value)}} placeholder='Designation' />
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' ref={L1_office_no} placeholder='Office No'/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' ref={L1_cell} placeholder='Cell No'/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' ref={L1_email} placeholder='Email'/>
                </div>
                <div className='mt-1'>
                    <select className='form-control' value={Liaison1tier} onChange={(e)=>{setLiaison1tier(e.target.value)}}>
                        <option value="Tier-I">Tier-I</option>
                        <option value="Tier-II">Tier-II</option>
                        <option value="Tier-III">Tier-III</option>
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Liaison Person 2:
                </div>
                <div className='mt-2'>
                    <select className='form-control' value={Liaison2respect} onChange={(e)=>{setLiaison2respect(e.target.value)}}>
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="">-</option>
                    </select>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='First Name' ref={L2_F} required/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='Second Name' ref={L2_S} required/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' value={Liaison2desig} onChange={(e)=>{setLiaison2desig(e.target.value)}} placeholder='Designation' />
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' ref={L2_office_no} placeholder='Office No'/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' ref={L2_cell} placeholder='Cell No'/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' ref={L2_email} placeholder='Email'/>
                </div>
                <div className='mt-1'>
                    <select className='form-control' value={Liaison2tier} onChange={(e)=>{setLiaison2tier(e.target.value)}}>
                        <option value="Tier-I">Tier-I</option>
                        <option value="Tier-II">Tier-II</option>
                        <option value="Tier-III">Tier-III</option>
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold mb-2'>
                    Address:
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='Enter Address' value={address} onChange={(e)=>{setaddress(e.target.value)}}/>
                </div>
            </div>

            <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                    severity={alertType}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setalertOpen(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                    >
                    {AlertMessage}
                    </Alert>
                </Collapse>
            </Box>
            <div className='mt-4'>
                <button className='btn btn-primary me-1' ref={btnref} type='submit'>Update</button>
            </div>

      </form>
    </div>
  )
}
