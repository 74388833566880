import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { url } from '../../../../Config';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import TableComponent from '../../../Atoms/TableComponent';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';


export default function Mandate_received(props) {

    const id = localStorage.getItem("userID");
    const [data, setdata] = useState([]);
    const Navigate = useNavigate();

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/mandatereceived`);
            const resp = res.data;
            setdata(resp.entered_clients);
        }

        func();
    },[])

    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };
    
      function innerDate(params) {
        if (params.data.updated_at == null) {
          return "-";
        } else {
          const date = new Date(params.data.updated_at);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };

      const responsiveColumnPin2 = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "right";
        }
      };

      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };

      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };
    
      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Client Name",
          minWidth: responsiveColumns(),
          field: "Title",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "Title",
          pinned: responsiveColumnPin(),
        },
        {
            headerName: "Date",
            field: "updated_at",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            hide: columnHide(),
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },
        {
            headerName: "Status",
            hide: columnHide(),
            field: "status",
            filter: "agSetColumnFilter",
            minWidth: 80,
            cellRenderer: function (params) {
                if(params.value == 'Approved'){
                    return(
                        <div style={{color: "green"}}> Approved </div>
                    )
                }
                else{
                    return(
                        <div style={{color: "red"}}> Entered </div>
                    )
                }
            },
          },
          {
            headerName: "Actions",
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            field: "status",
            sortable: true,
            filter: "agSetColumnFilter",
            autoHeight: true,
            excelMode: "windows",
            cellRenderer: (params)=>{
                return(
                    <div>
                        <div>
                        <Tooltip title="Add/Edit Info"><button className='btn btn-warning btn-sm me-1 mb-1' onClick={()=>Navigate(`/client-mandate-info/${params.data.Id}`)}><EditIcon /></button></Tooltip>
                        </div>
                    </div>
                )
            },
            pinned: responsiveColumnPin2(),
        },
        
      ];


      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
          <Table className="overflow-scroll responsiveTableFonts">

            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Date
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerDate(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Status
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.status}
              </TableCell>
            </TableRow>
          </Table>
        </h1>
      );

  return (
    <div>
        <div className='mt-2 text-center mb-2 fw-bold fs-3' style={{color:"#000078"}}>
        Mandate Received
      </div>
      <div>
        <TableComponent
            Data={data}
            screenWidth={props.screenWidth}
            MobViewRender={MobViewRender}
            columnDefs={columnDefs}
            datefilters={datefilters}
            datefilter={false}
        />
      </div>
    </div>
  )
}
