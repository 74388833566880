import React, { useEffect, useState, useRef } from 'react';
import { url } from '../../../../Config';
import TableComponent from '../../../Atoms/TableComponent';
import axios from 'axios';
import Skeleton from "@mui/material/Skeleton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';



export default function Model_dashboard(props) {
    const [data, setdata] = useState([]);
    const [bool, setbool] = useState(false);
    const Navigate = useNavigate();
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [rowstatus, setrowstatus] = useState();


    useEffect(()=>{
        const func = async ()=>{
            setrowstatus(false)

            const res = await axios.get(`${url}/api/get_pacra_model`);
            const resp = res.data;
            setdata(resp.data);
            if(resp.data.length == 0){
                setrowstatus(true);
              }
        }

        func();
    },[bool])

    const createcopy = async (model_id)=>{

        document.getElementById(`copy${model_id}`).hidden = true;
        document.getElementById(`load${model_id}`).hidden = false;
        const res = await axios.post(`${url}/api/create_model_copy`,{
            'model_id': model_id
        });

        const resp = res.data;
        if(resp.status == true){
            setbool(!bool);
            document.getElementById(`copy${model_id}`).hidden = false;
            document.getElementById(`load${model_id}`).hidden = true;
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("success");
        }
        else{
            document.getElementById(`copy${model_id}`).hidden = false;
            document.getElementById(`load${model_id}`).hidden = true;
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error");
        }
    }

    const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };

    const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };

    const columnDefs = [
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: 'left',
        },
        {
          headerName: "Model Name",
          minWidth: 350,
          field: "name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: function (params) {
            return(
                <Link to={`/view_format/${params.data.id}`}>{params.value}</Link>
            )
          },
          tooltipField: "name",
        },
        {
            headerName: "Status",
            field: "status",
            filter: "agSetColumnFilter",
            minWidth: 80,
           
            cellRenderer: function (params) {
                if(params.value == '0'){
                    return(
                        <div style={{color: "blue"}}> Pending </div>
                    )
                }
                else if(params.value == '1'){
                    return(
                        <div style={{color: "green"}}> Approved </div>
                    )
                }
                else{
                    return(
                        <div> Re-submit </div>
                    )
                }
            },
        },
        {
            headerName: "Actions",
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            field: "",
            sortable: true,
            filter: "agSetColumnFilter",
            autoHeight: true,
            excelMode: "windows",
            cellRenderer: (params)=>{
                if(params.data.status != '1'){
                    return(
                        <div>
                            <Tooltip title="Add Description"><AddCircleIcon className='me-2' style={{color: 'red'}} onClick={()=>{Navigate(`/add-decription/${params.data.id}`)}} /></Tooltip>
                            <><i className="fa-regular fa-pen-to-square fa-lg me-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" onClick={()=>{Navigate(`/edit-model/${params.data.id}`)}} style={{color: '#ffc107'}}></i></>
                        </div>
                    )
                }
                else if(params.data.status == '1'){
                    return(
                        <div>
                            <Tooltip title="Add Description"><AddCircleIcon className='me-2' style={{color: 'red'}} onClick={()=>{Navigate(`/add-decription/${params.data.id}`)}} /></Tooltip>
                            <><i class="fas fa-copy fa-lg" style={{color: 'skyblue'}} data-bs-toggle="tooltip" data-bs-placement="top" title="Create Copy" id={`copy${params.data.id}`} hidden={false} onClick={()=>{createcopy(params.data.id)}} ></i>
                            <i className="fa-solid fa-spinner fa-spin fa-lg me-1" id={`load${params.data.id}`} hidden={true} style={{color: 'skyblue'}}></i></>
                        </div>
                    )
                }
                
              
            },
        },
        
      ];

      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
        </h1>
      );


  return (
    <div>
       <div className='fs-4 fw-bold text-center'>
            Assessment Report Format Dashboard
        </div>
        <Box className="mx-auto mt-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                    severity={alertType}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setalertOpen(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                        sx={{ mb: 2 }}
                    >
                    {AlertMessage}
                    </Alert>
                </Collapse>
        </Box>
        <div className='mx-auto mt-2'><div style={{width: "fit-content"}}><button className='btn btn-primary' onClick={()=>{Navigate('/create-model')}}>Create Assessment Format</button></div></div>
        <TableComponent
            Data={data}
            screenWidth={props.screenWidth}
            rowstatus={rowstatus}
            MobViewRender={MobViewRender}
            columnDefs={columnDefs}
            datefilters={datefilters}
            datefilter={false}
            model={true}
        />
    </div>
  )
}
