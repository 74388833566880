import React, {useState, useEffect, useRef} from 'react'
import TableComponent from "../../Atoms/TableComponent";
import Skeleton from "@mui/material/Skeleton";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import GetGridsApi from "../../../API/GetGridsApi";
import moment from "moment";
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { url } from '../../../Config';



export default function IcBtwTwoDates(props) {

    const [icBetweenTwoDates, seticBetweenTwoDates] = useState([]);
    const [rowstatus, setrowstatus] = useState();
    const [fromDate, setfromDate] = useState(localStorage.getItem('reportsfirstDate'));
    const [toDate, settoDate] = useState(localStorage.getItem('reportslastDate'));
    const [Update, setUpdate] = useState();
    const [startdate, setstartdate] = useState();
    const [enddate, setenddate] = useState();
    const responsiveColumns = () => {
      if (props.screenWidth < 770) {
        return null;
      } else {
        return 120;
      }
    };
    const responsiveColumnPin = () => {
      if (props.screenWidth < 770) {
        return null;
      } else {
        return "left";
      }
    };
    const detailShow = () => {
      if (props.screenWidth < 770) {
        return false;
      } else {
        return true;
      }
    };

    useEffect(() => {
      getTabsData(fromDate, toDate);
    }, []);
  
    const getTabsData = async (fromDate, toDate) => {
      if(fromDate !== null){
      setrowstatus(false);
      const res = GetGridsApi.IcBetweenTwoDatesApi(fromDate, toDate);
      const resp = await res;
      seticBetweenTwoDates(resp.data.IC);
      if(resp.data.IC.length == 0){
        setrowstatus(true);
      }
      setstartdate(fromDate)
      setenddate(toDate)
      localStorage.removeItem("reportsfirstDate");
      localStorage.removeItem("reportslastDate");
      }
    };

    const updatedata = async ()=>{
      if(startdate != null && enddate != null){
          setUpdate(true);
          const res = GetGridsApi.IcBetweenTwoDatesApi(startdate, enddate);
          const resp = await res;
          seticBetweenTwoDates(resp.data.IC);
          if(resp.data.IC.length == 0){
            setUpdate('no rows');
          }
          else{
            setUpdate(false);
          }
      }
    }

    const columnHide = () => {
      if (props.screenWidth < 770) {
        return true;
      } else {
        return false;
      }
    };
  
    const PvtRatingsdatefilters = (
      startDate,
      endDate,
      gridApi,
      getFilterType
    ) => {
      var dateFilterComponent =
        gridApi.api.getFilterInstance("notification_date");
      dateFilterComponent.setModel({
        type: getFilterType(),
        inRange: true,
        dateFrom: startDate,
        dateTo: endDate,
      });
    };
  
    function fullDate(params) {
      if (params.value === "nullData") return <Skeleton />;
      if (params.value == null) {
        return "-";
      } else if (params.value == "") {
        return "-";
      } else {
        const date = new Date(params.value);
        const yyyy = date.getFullYear();
        const yy = yyyy.toString();
        const y = yy.slice(2, 4);
        let mm = date.toLocaleString("default", { month: "short" });
        let dd = date.getDate();
        if (dd < 10) dd = "0" + dd;
        return dd + "-" + mm + "-" + y;
      }
    }
    
    const cellrander = (params) => {
      if (params.value === "nullData") return <Skeleton />;
      if (params.value === "" || params.value == null) {
        return "-";
      } else {
        return params.value;
      }
    };

    const cellrandered = (params) => {
      if (params.value === "nullData") {
        return <Skeleton />;
      } else {
        return params.value;
      }
    };

    function innerDissemDate(params) {
      if (params.data.dissemination_date == null) {
        return "-";
      } else {
        const date = new Date(params.data.dissemination_date);
        const yyyy = date.getFullYear();
        const yy = yyyy.toString();
        const y = yy.slice(2, 4);
        let mm = date.toLocaleString("default", { month: "short" });
        let dd = date.getDate();
        if (dd < 10) dd = "0" + dd;
        return dd + "-" + mm + "-" + y;
      }
    }
    function innerinitiation_date(params) {
      if (params.data.rating_initiation_date == null) {
        return "-";
      } else {
        const date = new Date(params.data.rating_initiation_date);
        const yyyy = date.getFullYear();
        const yy = yyyy.toString();
        const y = yy.slice(2, 4);
        let mm = date.toLocaleString("default", { month: "short" });
        let dd = date.getDate();
        if (dd < 10) dd = "0" + dd;
        return dd + "-" + mm + "-" + y;
      }
    }

    function innerfc_date(params) {
      if (params.data.file_closure_date == null) {
        return "-";
      } else {
        const date = new Date(params.data.file_closure_date);
        const yyyy = date.getFullYear();
        const yy = yyyy.toString();
        const y = yy.slice(2, 4);
        let mm = date.toLocaleString("default", { month: "short" });
        let dd = date.getDate();
        if (dd < 10) dd = "0" + dd;
        return dd + "-" + mm + "-" + y;
      }
    }
  
    const columnDefs = [
      {
        headerName: "",
        field: "sNo",
        maxWidth: 30,
        filter: true,
        menuTabs: false,
        pinned: responsiveColumnPin(),
        hide: detailShow(),
        cellRenderer: "agGroupCellRenderer",
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        headerName: "#",
        maxWidth: 50,
        // minWidth: 66,
        field: "sNo",
        sortable: true,
        filter: true,
        // filter: "agSetColumnFilter",
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
        },
        suppressFiltersToolPanel: true,
        menuTabs: false,
        cellRenderer: cellrandered,
        pinned: responsiveColumnPin(),
      },
  
      {
        headerName: "Opinion",
        minWidth: responsiveColumns(),
        field: "Opinion",
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        cellRenderer: cellrander,
        tooltipField: "Opinion",
        pinned: responsiveColumnPin(),
      },
      {
        headerName: "Preview",
        minWidth: responsiveColumns(),
        field: "",
        excelMode: "windows",
        cellRenderer: (params)=>{
            return(
                <>
                    <VisibilityIcon sx={{color: 'blue'}} onClick={()=>{window.open(`${url}/api/printpreview/${params.data.client_id}/${params.data.id}`, '_blank')}} />
                </>
            )
        },
        pinned: responsiveColumnPin(),
      },
      {
        headerName: "Rating Type",
        minWidth: responsiveColumns(),
        field: "rating_scale",
        sortable: true,
        hide: columnHide(),
        filter: "agSetColumnFilter",
        excelMode: "windows",
        cellRenderer: cellrander,
        tooltipField: "rating_scale",
        pinned: responsiveColumnPin(),
      },
      {
        headerName: "Team",
        minWidth: responsiveColumns(),
        field: "team_lead",
        hide: columnHide(),
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        cellRenderer: cellrander,
        tooltipField: "team_lead",
      },

      {
        headerName: "Analyst",
        minWidth: responsiveColumns(),
        field: "analyst",
        hide: columnHide(),
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        cellRenderer: cellrander,
        tooltipField: "analyst",
      },
      {
        headerName: "Industry",
        minWidth: responsiveColumns(),
        field: "Industry",
        hide: columnHide(),
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        cellRenderer: cellrander,
        tooltipField: "Industry",
      },

      {
        headerName: "Update Type",
        field: "update_type",
        minWidth: 130,
        minWidth: 130,
        sortable: true,
        hide: columnHide(),
        filter: "agSetColumnFilter",
        excelMode: "windows",
        tooltipField: "lterm",
        cellRenderer: cellrander,
        // pinned: responsiveColumnPin(),
      },

      {
        headerName: "Notification Date",
        field: "notification_date",
        minWidth: 115,
        // hide: true,
        sortable: true,
        filter: "agDateColumnFilter",
        excelMode: "windows",
        cellRenderer: fullDate,
        debounceMs: "DateFilter",
        filterParams: {
          filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            var dateAsString = moment(cellValue).format("DD/MM/YYYY");
            var dateParts = dateAsString.split("/");
            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );
  
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }
  
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
  
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
          buttons: ["clear", "reset", "apply"],
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        },
      },

      {
        headerName: "Initiation Date",
        field: "rating_initiation_date",
        hide: columnHide(),
        minWidth: 115,
        // hide: true,
        sortable: true,
        filter: "agDateColumnFilter",
        excelMode: "windows",
        cellRenderer: fullDate,
        debounceMs: "DateFilter",
        filterParams: {
          filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            var dateAsString = moment(cellValue).format("DD/MM/YYYY");
            var dateParts = dateAsString.split("/");
            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );
  
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }
  
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
  
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
          buttons: ["clear", "reset", "apply"],
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        },
      },
      {
        headerName: "File Closure Date",
        field: "file_closure_date",
        hide: columnHide(),
        minWidth: 115,
        // hide: true,
        sortable: true,
        filter: "agDateColumnFilter",
        excelMode: "windows",
        cellRenderer: fullDate,
        debounceMs: "DateFilter",
        filterParams: {
          filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            var dateAsString = moment(cellValue).format("DD/MM/YYYY");
            var dateParts = dateAsString.split("/");
            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );
  
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }
  
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
  
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
          buttons: ["clear", "reset", "apply"],
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        },
      },

      {
        headerName: "LT",
        field: "lterm_rating",
        hide: columnHide(),
        minWidth: 130,
        minWidth: 130,
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        tooltipField: "lterm_rating",
        cellRenderer: cellrander,
        // pinned: responsiveColumnPin(),
      },
      {
        headerName: "ST",
        field: "sterm_rating",
        hide: columnHide(),
        minWidth: 130,
        minWidth: 130,
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        tooltipField: "lterm",
        cellRenderer: cellrander,
        // pinned: responsiveColumnPin(),
      },
      {
        headerName: "Rating Action",
        field: "action",
        hide: columnHide(),
        minWidth: 130,
        minWidth: 130,
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        tooltipField: "lterm",
        cellRenderer: cellrander,
        // pinned: responsiveColumnPin(),
      },
      
    ];

    const MobViewRender = (params) => (
      <h1 style={{ padding: "10px 20px" }}>
        <Table className="overflow-scroll responsiveTableFonts">
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Rating Type
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.rating_scale}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Team
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.team_lead}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Analyst
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.analyst}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Industry
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.Industry}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Update Type
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.update_type}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Initiation Date
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {innerinitiation_date(params)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              File Closure Date
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {innerfc_date(params)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              LT
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.lterm_rating}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              ST
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.sterm_rating}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Rating Action
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.action}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              ST
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.sterm_rating}
            </TableCell>
          </TableRow>
        </Table>
      </h1>
    );
  

  return (
      <>
        <div>
          <p className="mx-auto p-3 fs-3 fw-bold mb-0 text-capitalize" style={{ width: "fit-content", color:"#000078" }}>
          IC between two Dates
          </p>      
          <div className="row w-100">
            <div
              className="card shadow mx-auto col-lg-10 col-md-10 col-sm-10 col-11 pt-4"
              style={{ height: "auto", backgroundColor: "#e0e0e0" }}
            >

              <div className="row w-100 pb-3">
                  <div className="col-lg-5 col-md-5 ms-lg-4 ms-md-2 ms-sm-3 col-sm-11 col-11 ms-2">
                  <input
                    type="date"
                    style={{height: "38px"}}
                    defaultValue={fromDate}
                    className="form-control"
                    onChange={(e) => {
                      setstartdate(e.target.value);
                    }}
                  />
                  </div>
                  <div className="col-lg-5 col-md-5 ms-md-1 pt-sm-1 pt-md-0 col-sm-11 ms-sm-3 col-11 ms-2 pt-1">
                  <input
                    type="date"
                    style={{height: "38px"}}
                    defaultValue={toDate}
                    className="form-control"
                    onChange={(e) => {
                      setenddate(e.target.value);
                    }}
                  />
                  </div>
                  <div className="col-lg-1 col-md-1 col-sm-1 pt-sm-1 pt-md-0 ms-md-1 mx-sm-auto col-1 pt-1 mx-auto">
                  <button type="button" className="btn btn-primary h-60" onClick={updatedata} >
                  Submit
                </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <TableComponent
          report = {true}
          rowstatus = {rowstatus}
          datereport = {true}
          value={"IcBetweenTwoDates"}
          handleRfreshTab={props.handleRfreshTab}
          Data={icBetweenTwoDates}
          screenWidth={props.screenWidth}
          MobViewRender={MobViewRender}
          columnDefs={columnDefs}
          datefilters={PvtRatingsdatefilters}
          datefilter={true}
          update = {Update}
          // FullPageTable={true}
        />
      </>
  )
}
