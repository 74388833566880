import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import { url } from '../../../Config';
import TableComponent from '../../Atoms/TableComponent';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { NavLink } from "react-router-dom";
import { Check, Clear, Event } from "@material-ui/icons";
import { Table, TableCell, TableRow } from "@mui/material";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Swal from "sweetalert2";
import ApprovalIcon from '@mui/icons-material/Approval';
import Tooltip from '@mui/material/Tooltip';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';



export default function Rating_to_cob(props) {
    const [clientsTl, setclientsTl] = useState([]);
    const [clientsJh, setclientsJh] = useState([]);
    const [clientsBD, setclientsBD] = useState([]);
    const [cobList, setcobList] = useState([]);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [alertType2, setalertType2] = useState();
    const [alertType3, setalertType3] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [alertopen2, setalertOpen2] = useState(false);
    const [AlertMessage2, setalertmessage2] = useState();
    const [alertopen3, setalertOpen3] = useState(false);
    const [AlertMessage3, setalertmessage3] = useState();
    const [bool, setbool] = useState(false);
    const targetRef = useRef(null);
    const id = localStorage.getItem("userID");
    const rightsval = localStorage.getItem("rights");
    const [declinereason, setdeclinereason] = useState(Array(clientsJh.length));

    
    let rights = [];
  
    if(rightsval !== null){
      rights = rightsval.split(",");
    }

    useEffect(()=>{
        setclientsTl([]);
        const func = async ()=>{
            const res = await axios.get(`${url}/api/ratingsToCob/${id}`);
            const resp = res.data;
            setclientsTl(resp.clientsTl);
            setclientsJh(resp.clientsJh);
            setclientsBD(resp.clientsBD);
            setcobList(resp.cobList);
        }
        func();
    },[bool])

    const transfer = async (event, text, file, closeid, p_id, submit, btn)=>{
        event.preventDefault();
        
        document.getElementById(submit).disabled = true;
        const fileData = new FormData();
        const reason = document.getElementById(text).value;
        const attachment = document.getElementById(file).files[0];
        fileData.append("to_cob_reason", reason);
        fileData.append("attachment", attachment);
        fileData.append("submit", "entered");
        fileData.append("id", Number(id));
        const res = await axios.post(`${url}/api/ratingsToCobTransfer/${p_id}/entered`, 
        fileData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const resp = res.data;

        if(resp.status == true){
            document.getElementById(closeid).click();
            document.getElementById(btn).innerHTML = 'Request Sent';
            document.getElementById(btn).disabled = true;
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
            setbool(!bool);
        }
        else{
            setalertType2("error");
            setalertOpen2(true);
            setalertmessage2(resp.message);
            document.getElementById(submit).disabled = false;
        }
    }

    const recommend = async (p_id)=>{
      Swal.fire({ title: 'Do you want to Recommend this Request?', 
      showDenyButton: false, showCancelButton: true, confirmButtonText: 'Recommend'})
      .then(async(result) => {
        if (result.isConfirmed) {
          const fileData = new FormData();
          fileData.append("submit", "recommend");
          fileData.append("id", Number(id));
          const res = await axios.post(`${url}/api/ratingsToCobTransfer/${p_id}/recommend`, 
          fileData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
  
          const resp = res.data;
  
          if(resp.status == true){
              setbool(!bool);
              setalertType("success");
              setalertOpen(true);
              setalertmessage(resp.message);
          }
          else{
              setalertType2("error");
              setalertOpen2(true);
              setalertmessage2(resp.message);
          }
        }
      })
        
    }

    const approve = async (p_id)=>{

        const fileData = new FormData();
        fileData.append("submit", "approve");
        fileData.append("id", Number(id));
        const res = await axios.post(`${url}/api/ratingsToCobTransfer/${p_id}/approve`, 
        fileData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const resp = res.data;

        if(resp.status == true){
            setbool(!bool);
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            setalertType2("error");
            setalertOpen2(true);
            setalertmessage2(resp.message);
        }

    }

    const submit = async (btn, p_id, ids, reason)=>{
      if(document.getElementById(btn) !== null){
        document.getElementById(btn).innerHTML = "Please Wait...";
        document.getElementById(btn).disabled = true;
      }
        const fileData = new FormData();
        fileData.append("submit", "declined");
        fileData.append("id", Number(id));
        fileData.append("reason", reason);
        const res = await axios.post(`${url}/api/ratingsToCobTransfer/${p_id}/declined`, 
        fileData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const resp = res.data;

        if(resp.status == true){
          if(document.getElementById(ids) !== null){
            document.getElementById(ids).click();
          }
            setbool(!bool);
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
          if(document.getElementById(btn) !== null){
            document.getElementById(btn).innerHTML = "Submit";
            document.getElementById(btn).disabled = false;
          }
          console.log(resp, 'response')
            setalertType3("error");
            setalertOpen3(true);
            setalertmessage3(resp.message);
        }
    }

    const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };

    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 210;
        }
      };

      const responsiveColumnPin2 = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "right";
        }
      };

      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };

      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
      const sectorHide = () => {
        if (props.screenWidth > 500) {
          return false;
        } else {
          return true;
        }
      };
    
      function innerDissemDate(params) {
        if (params.data.Dissemination == null) {
          return "-";
        } else {
          const date = new Date(params.data.Dissemination);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
    
      function yes(params) {
        if (params.value === "nullData") {
          return <Skeleton />;
        }
        if (params.data.rw === "YES") {
          return "Yes";
        } else if (params.data.rw === "NO" || params.data.rw === "-") {
          return "-";
        }
      }
    
      function innerNotiDate(params) {
        if (params.data.Notification == null) {
          return "-";
        } else {
          const date = new Date(params.data.Notification);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };

      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };

      const outstandingdatefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent = gridApi.api.getFilterInstance("Notification");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
        var dateFilterComponent1 = gridApi.api.getFilterInstance("Dissemination");
        dateFilterComponent1.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };

      const customComparator = (valueA, valueB) => {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      };

      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          field: "sNo",
          // minWidth: 66,
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
    
        {
          headerName: "Client Name",
          minWidth: responsiveColumns(),
          comparator: customComparator,
          field: "name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          filterParams: {
            caseSensitive: false,
          },
          cellRenderer: function (params) {
            if (params.value === "nullData") return <Skeleton />;
            else{
    
              if(params.data != null){
                return (
                  <a
                    href={`https://209.97.168.200/pacrawizpackv3/public/admin/pacraWork/${params.data.Id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary"
                  >
                    {params.value}
                  </a>
                );
            }
            }
          },
          // cellRenderer: cellrandered,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
    
        {
          headerName: "Team",
          field: "display_name",
          minWidth: 130,
          hide: sectorHide(),
          maxWidth: 130,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          tooltipField: "Industry",
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },

        {
            headerName: "Client Creation",
            hide: sectorHide(),
            field: "creation_date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },

        {
            headerName: "Notified Date",
            hide: sectorHide(),
            field: "to_manager_date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },

        {
            headerName: "Reason",
            field: "to_cob_reason",
            minWidth: 300,
            hide: sectorHide(),
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            tooltipField: "Industry",
            cellRenderer: cellrandered,
        },

        {
            headerName: "Actions",
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            field: "to_cob",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
              if(params.value == null){
                return(
                    <button className='btn btn-success' id={`btntransfer${params.data.id}`} data-bs-toggle="modal" data-bs-target={`#transfer${params.data.id}`}>Transfer</button>
                )
              }
              else if(params.value == 'Declined'){
                return(
                  <>
                    <div className='me-1' style={{color:'red'}}>Decline</div>
                    <button className='btn btn-danger' id={`btntransfer${params.data.id}`} data-bs-toggle="modal" data-bs-target={`#transfer${params.data.id}`}>Request Again</button>

                  </>
                )
              }
              else{
                return(
                    <button className='btn btn-success' disabled>Request Sent</button>
                )
              }
            },
            pinned: responsiveColumnPin2(),
        },
      ];
      const columnDefsJH = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          field: "sNo",
          // minWidth: 66,
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
    
        {
          headerName: "Client Name",
          minWidth: responsiveColumns(),
          comparator: customComparator,
          field: "name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          filterParams: {
            caseSensitive: false,
          },
          cellRenderer: function (params) {
            if (params.value === "nullData") return <Skeleton />;
            else{
    
              if(params.data != null){
                return (
                    params.value
                );
            }
            }
          },
          // cellRenderer: cellrandered,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
    
        {
          headerName: "Team",
          field: "display_name",
          minWidth: 130,
          hide: sectorHide(),
          maxWidth: 130,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          tooltipField: "Industry",
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Analyst",
          field: "analyst",
          minWidth: 130,
          hide: sectorHide(),
          maxWidth: 130,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          tooltipField: "Industry",
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },

        {
            headerName: "Notified Date",
            hide: sectorHide(),
            field: "to_manager_date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },

        {
            headerName: "Reason",
            field: "to_cob_reason",
            minWidth: 300,
            hide: sectorHide(),
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            tooltipField: "Industry",
            cellRenderer: cellrandered,
        },

        {
            headerName: "Actions",
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            field: "to_cob",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
              if(params.data.to_cob == 'Entered'){
                return(
                  <>
                    <Tooltip title='Decline'><ThumbDownAltIcon className='me-1' sx={{color: 'red'}} data-bs-toggle="modal" data-bs-target={`#reason-decline${params.data.id}`}/></Tooltip>
                    <Tooltip title='Recommend'><RecommendIcon sx={{color: 'green'}} onClick={()=>{recommend(params.data.id)}}/></Tooltip>
                  </>
                )
              }
              else{
                return(
                  <button className='btn btn-success' disabled>Recommended</button>
              )
              }
            },
            pinned: responsiveColumnPin2(),
        },
      ];
      const columnDefsBD = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          field: "sNo",
          // minWidth: 66,
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
    
        {
          headerName: "Client Name",
          minWidth: responsiveColumns(),
          comparator: customComparator,
          field: "name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          filterParams: {
            caseSensitive: false,
          },
          cellRenderer: function (params) {
            if (params.value === "nullData") return <Skeleton />;
            else{
    
              if(params.data != null){
                return (
                    params.value
                );
            }
            }
          },
          // cellRenderer: cellrandered,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
    
        {
          headerName: "Team",
          field: "display_name",
          minWidth: 130,
          hide: sectorHide(),
          maxWidth: 130,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          tooltipField: "Industry",
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Analyst",
          field: "analyst",
          minWidth: 130,
          hide: sectorHide(),
          maxWidth: 130,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          tooltipField: "Industry",
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },

        {
            headerName: "Notified Date",
            hide: sectorHide(),
            field: "to_manager_date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },

        {
            headerName: "Reason",
            field: "to_cob_reason",
            minWidth: 300,
            hide: sectorHide(),
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            tooltipField: "Industry",
            cellRenderer: cellrandered,
        },

        {
            headerName: "Actions",
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            field: "to_cob",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
                return(
                    <Tooltip title='Approve'><ApprovalIcon sx={{color: 'green'}} onClick={()=>{approve(params.data.id)}} /></Tooltip>
                )
            },
            pinned: responsiveColumnPin2(),
        },
      ];

      
      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
          <Table className="overflow-scroll responsiveTableFonts">
            {props.screenWidth <= 500 ? (
              <TableRow>
                <TableCell
                  variant="head"
                  className="fw-bolder responsiveTableFonts py-1"
                >
                  Sector
                </TableCell>
                <TableCell className="responsiveTableFonts py-1">
                  {params.data.Industry}
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Rating Type
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.RatingScale}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                {" "}
                Team
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.managerName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Analyst
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.pacraAnalyst}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Action
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.RatingAction}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                R|LT
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.RatingLT}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                R|ST
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.RatingST}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                RW
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {yes(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                CF
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.cf}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Outlook
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.Outlook}
              </TableCell>
            </TableRow>
            {props.screenWidth <= 500 ? (
              <TableRow>
                <TableCell
                  variant="head"
                  className="fw-bolder responsiveTableFonts py-1"
                >
                  Notification
                </TableCell>
                <TableCell className="responsiveTableFonts py-1">
                  {innerNotiDate(params)}
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Dissemination
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerDissemDate(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                PR
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.value === "nullData" ? (
                  <Skeleton />
                ) : params.data.recordid ? (
                  <a
                    href={`https://209.97.168.200/pacrawizpackv3/public/admin/finalPr/${params.data.recordid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <Check style={{ size: "20 20" }} className="theme_text" />{" "}
                  </a>
                ) : (
                  <Clear className="theme_text" />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                RR
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.value === "nullData" ? (
                  <Skeleton />
                ) : params.data.sr ? (
                  <a
                    href={`https://209.97.168.200/pacrawizpackv3/storage/app/${params.data.sr}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <Check className="theme_text" />{" "}
                  </a>
                ) : (
                  <Clear className="theme_text" />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                H
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.value === "nullData" ? (
                  <Skeleton />
                ) : params.data.Id ? (
                  <a
                    href={`https://209.97.168.200/pacrawizpackv3/public/admin/pacraWork/${params.data.Id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Event className="theme_text" />
                  </a>
                ) : (
                  <Clear className="theme_text" />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                SP
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.value === "nullData" ? (
                  <Skeleton />
                ) : params.data.shl ? (
                  <a
                    href={params.data.shl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Check className="theme_text" />
                  </a>
                ) : (
                  <Clear className="theme_text" />
                )}
              </TableCell>
            </TableRow>
          </Table>
        </h1>
      );


  return (
    <div>
      {
        rights.includes("36") && 
        <div>
            <div className='mx-auto mt-2 mb-1 d-flex justify-content-end' style={{width: '95%'}}><div style={{width: "fit-content"}}><button className='btn btn-success' onClick={()=>{window.open(`/ratings-to-cob-log`, '_blank')}}>Transfer Log</button></div></div>
            <Box className="mx-auto" ref={targetRef} sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
            </Box>

                {
                    clientsTl.map((value, index)=>{
                        return(
                            <form className="modal fade" id={`transfer${value.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" onSubmit={(event)=>{transfer(event, `transfertext${value.id}`, `transferfile${value.id}`, `close${value.id}`, value.id, `submit${value.id}`, `btntransfer${value.id}`)}}>
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Reason to transfer: {value.name}</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{setalertOpen2(false)}}></button>
                                    </div>
                                    <div className="modal-body">
                                    <Box className="mx-auto" sx={{ width: "98%" }}>
                                        <Collapse in={alertopen2}>
                                            <Alert
                                            severity={alertType2}
                                            action={
                                                <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setalertOpen2(false);
                                                }}
                                                >
                                                <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                            sx={{ mb: 2 }}
                                            >
                                            {AlertMessage2}
                                            </Alert>
                                        </Collapse>
                                    </Box>
                                        <div className='fw-bold'>
                                        Reason
                                        </div>
                                        <div style={{height: "40%"}}>
                                        <textarea className='form-control' id={`transfertext${value.id}`} style={{height: "100%"}} required></textarea>
                                        </div>
                                        <div className='mt-3'>
                                            <div className='mb-1'>
                                                Attachment:
                                            </div>
                                            <input className='form-control' type='file' id={`transferfile${value.id}`} required/>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id={`close${value.id}`} data-bs-dismiss="modal" onClick={()=>{setalertOpen2(false)}}>Close</button>
                                        <button type="submit" className="btn btn-primary" id={`submit${value.id}`}>Submit</button>
                                    </div>
                                    </div>
                                </div>
                            </form>
                        )
                    })
                }

                <TableComponent
                    Data={clientsTl}
                    screenWidth={props.screenWidth}
                    MobViewRender={MobViewRender}
                    columnDefs={columnDefs}
                    datefilters={datefilters}
                    datefilter={false}
                />
        </div>
      }
      {
        rights.includes("37") && 
        <div>
            <div className='mx-auto mt-2 mb-1 d-flex justify-content-end' style={{width: '95%'}}><div style={{width: "fit-content"}}><button className='btn btn-success' onClick={()=>{window.open(`/ratings-to-cob-log`, '_blank')}}>Transfer Log</button></div></div>
            <Box className="mx-auto" ref={targetRef} sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
            </Box>
            {
                  clientsJh.map((value, index)=>{
                    return(
                      <div className="modal fade" id={`reason-decline${value.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Reason to Decline : {value.name}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                      <Box className="mx-auto" sx={{ width: "98%" }}>
                                        <Collapse in={alertopen3}>
                                            <Alert
                                            severity={alertType3}
                                            action={
                                                <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setalertOpen3(false);
                                                }}
                                                >
                                                <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                            sx={{ mb: 2 }}
                                            >
                                            {AlertMessage3}
                                            </Alert>
                                        </Collapse>
                                      </Box>
                                <div className='fw-bold'>
                                  Reason
                                </div>
                                <div>
                                  <textarea className='form-control'  style={{height: "100%"}} onChange={(e)=>{
                                    declinereason[index] = e.target.value;
                                    setdeclinereason(declinereason);
                                  }}></textarea>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id={`reasondeclineclose${index}`} data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" id={`declinebtn${index}`}  onClick={()=>{submit(`declinebtn${index}`, value.id, `reasondeclineclose${index}`, declinereason[index])}}>Submit</button>
                              </div>
                            </div>
                          </div>
                      </div>
                    )
                  })
                }
            <TableComponent
                    Data={clientsJh}
                    screenWidth={props.screenWidth}
                    MobViewRender={MobViewRender}
                    columnDefs={columnDefsJH}
                    datefilters={datefilters}
                    datefilter={false}
                />
        </div>
      }
      {
        rights.includes("38") &&
        <div>
            <div className='mx-auto mt-2 mb-1 d-flex justify-content-end' style={{width: '95%'}}><div style={{width: "fit-content"}}><button className='btn btn-success' onClick={()=>{window.open(`/ratings-to-cob-log`, '_blank')}}>Transfer Log</button></div></div>
            <Box className="mx-auto" ref={targetRef} sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
            </Box>
            <TableComponent
                    Data={clientsBD}
                    screenWidth={props.screenWidth}
                    MobViewRender={MobViewRender}
                    columnDefs={columnDefsBD}
                    datefilters={datefilters}
                    datefilter={false}
                />
        </div>
      }
    </div>
  )
}
