import React, {useState, useEffect, useRef} from 'react';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import axios from 'axios';
import { url } from '../../../../Config';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";


export default function Edit_clientmandateInfo(props) {

    const id = localStorage.getItem("userID");
    const {client_id} = useParams();
    const [rating_types, setrating_types] = useState([]);
    const [approved_clients, setapproved_clients] = useState([]);
    const [rating_scale, setrating_scale] = useState([]);
    const [CD_mandate, setCD_mandate] = useState({});
    const [rating_type_val, setrating_type_val] = useState(null);
    const [rating_scale_val, setrating_scale_val] = useState(null);
    const [approved_clients_val, setapproved_clients_val] = useState(null);
    const [duration, setduration] = useState('');
    const [feeCat, setfeeCat] = useState([]);
    const [fund_type, setfund_type] = useState([]);
    const [opinion_id, setopinion_id] = useState(null);
    const [feeMx, setfeeMx] = useState('');
    const [feeMn, setfeeMn] = useState('');
    const [feeCat_val, setfeeCat_val] = useState('');
    const [fund_type_val, setfund_type_val] = useState('');
    const [data, setdata] = useState({});
    const [billFreqy, setbillFreqy] = useState('');
    const [increment, setincrement] = useState(null);
    const [inputValue1, setInputValue1] = useState('');
    const [inputValue2, setInputValue2] = useState('');
    const [rating_mandate, setrating_mandate] = useState(null);
    const [fee_mandate, setfee_mandate] = useState(null);
    const [ratingmandate_ex, setratingmandate_ex] = useState(null);
    const [feemandate_ex, setfeemandate_ex] = useState(null);
    const [rating_type_id, setrating_type_id] = useState(null);
    const [can_approve_mandate, setcan_approve_mandate] = useState(null);
    const [fee_cat, setfeecat] = useState([]);


    const [hidescale, sethidescale] = useState(false);
    const btnref = useRef(null);
    const rating_typeRef = useRef(null);
    const rating_scaleRef = useRef(null);
    const client_approvedRef = useRef(null);
    const EffectiveDateRef = useRef(null);
    const RenewalDateRef = useRef(null);
    const FeesRef = useRef(null);
    const IncrementRef = useRef(null);
    const commentRef = useRef(null);
    const opinionRef = useRef(null);
    const btnRef = useRef(null);
    const btnRef2 = useRef(null);
    const feeRangeRef = useRef(null);

    
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/clientmandateinfo/${client_id}/${id}`);
            const resp = res.data;
            setrating_types(resp.rating_types);
            setapproved_clients(resp.approved_clients);
            setfund_type(resp.fund_types);
            setcan_approve_mandate(resp.can_approve_mandate);

            if(resp.CD.length > 0){
                setrating_type_val(resp.CD[0].rating_type_id);
                setdata(resp.CD[0]);
                if(resp.CD[0].fund_type !== null){
                    setfund_type_val(resp.CD[0].fund_type);
                }
                setrating_type_id(resp.CD[0].rating_type_id);
                setopinion_id(resp.CD[0].id)
            }

            setCD_mandate(resp.CD_mandate);
            if(resp.CD_mandate.duration !== null){
                setduration(resp.CD_mandate.duration);
            }
            if(resp.CD_mandate.billFreqy !== null){
                setbillFreqy(resp.CD_mandate.billFreqy);
            }
            if(resp.CD_mandate.billFreqy == 'Annual'){
                setincrement(resp.CD_mandate.anvlInc);
            }
            else{
                setincrement(resp.CD_mandate.biAnvlInc);
            }
            setratingmandate_ex(resp.CD_mandate.rating_mandate);
            setfeemandate_ex(resp.CD_mandate.fee_mandate);
            setfeeCat(resp.fee_cat);
            setInputValue1(resp.CD_mandate.asset_size);
            setInputValue2(resp.CD_mandate.revenue_size);
            for(let i in resp.rating_types){
                if(resp.CD[0].rating_type_id == resp.rating_types[i].id){
                    setrating_scale(resp.rating_types[i].rating_scale);
                }
            }
            if(resp.mapped_client.length > 0){
                for(let i in resp.approved_clients){
                    if(resp.mapped_client[0].client_id == resp.approved_clients[i].ID){
                        setapproved_clients_val(resp.mapped_client[0].client_id);
                    }
                }
            }

            setfeeCat(resp.fee_cat);

            for(let i in resp.fee_cat){
                if(resp.CD_mandate.feeCatgId == resp.fee_cat[i].id){
                    setfeeCat_val(resp.CD_mandate.feeCatgId);
                    setfeeMx(resp.fee_cat[i].fee_max);
                    setfeeMn(resp.fee_cat[i].fee_min);
                }
            }

        }

        func();
    },[])

    const handleChange = (event) => {
        const value = event.target.value;
    
        // Allow only numeric values and the period (.)
        if (/^[0-9.]*$/.test(value)) {
          setInputValue1(value);
        }
      };

    const handleChange2 = (event) => {
        const value = event.target.value;
    
        // Allow only numeric values and the period (.)
        if (/^[0-9.]*$/.test(value)) {
          setInputValue2(value);
        }
      };

    useEffect(()=>{
        if(rating_scale.length > 0){
            setrating_scale_val(data.rating_scale_id)
        }
    },[rating_scale])

    const approve = async ()=>{
        setalertOpen(false);
        btnRef2.current.disabled = true
        const res = await axios.post(`${url}/api/approvemandate`,{
            'op_id': CD_mandate.opinion_id,
            'mandate_id': CD_mandate.id
        });
        const resp = res.data;
        if(resp.status == true){
            btnRef2.current.disabled = false;
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btnRef2.current.disabled = false;
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }

    }

    const editform = async (event)=>{
        event.preventDefault();
        setalertOpen(false);

        const fileData = new FormData();
        if(rating_mandate !== null && rating_mandate !== undefined){
            fileData.append("mandatefile", rating_mandate);
        }
        if(fee_mandate !== null && fee_mandate !== undefined){
            fileData.append("feemandatefile", fee_mandate);
        }
        if(ratingmandate_ex !== null){
            fileData.append("ratingmandate_ex", ratingmandate_ex);
        }
        if(feemandate_ex !== null){
            fileData.append("feemandate_ex", feemandate_ex);
        }
        if(fund_type_val !== ''){
            fileData.append("fund_type", fund_type_val);
        }
        fileData.append("ratingtype", Number(rating_type_val));
        fileData.append("ratingscale", Number(rating_scale_val));
        if(EffectiveDateRef.current.value !== null && EffectiveDateRef.current.value !== ''){
            fileData.append("effectivedate", EffectiveDateRef.current.value);
        }
        if(opinion_id !== null){
            fileData.append("opinion_id", opinion_id);
        }
        if(approved_clients_val !== null){
            fileData.append("approvedclients", approved_clients_val);
        }
        if(billFreqy !== ''){
            fileData.append("bf", billFreqy);
        }
        if(CD_mandate.id !== null){
            fileData.append("recordId", CD_mandate.id);
        }
        fileData.append("user_id", Number(id));

        if(RenewalDateRef.current.value !== null && RenewalDateRef.current.value !== ''){
            fileData.append("r_or_t_date", RenewalDateRef.current.value);
        }
        if(duration !== ''){
            fileData.append("duration", duration);
        }
        if(feeCat_val !== ''){
            fileData.append("feecatog_co", feeCat_val);
        }
        if(increment !== null && increment !== ''){
            fileData.append("inc_per", increment);
        }
        if(FeesRef.current.value !== null && FeesRef.current.value !== ''){
            fileData.append("fees", FeesRef.current.value);
        }

        if(inputValue1 !== null && inputValue1 !== ''){
            fileData.append("assetIssueSize", inputValue1);
        }

        if(inputValue2 !== null && inputValue2 !== ''){
            fileData.append("revenueSize", inputValue2);
        }

        if(commentRef.current.value !== null && commentRef.current.value !== ''){
            fileData.append("reasonForFeeVariation", commentRef.current.value);
        }

        btnRef.current.disabled = true;

        const res = await axios.post(`${url}/api/savemandate`,
        fileData,
        {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        }
        );
        


        const resp = res.data;
        if(resp.status == true){
            btnRef.current.disabled = false;
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btnRef.current.disabled = false;
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }



    }


  return (
    <div>
        <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/mandate-received"} >Grid</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Add/Change Client Mandate Info</li>
                </ol>
            </nav>
            <hr/>
        </div>
       <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            {data.name}
        </div>
        <form className='col-10 card shadow p-3 mx-auto mt-lg-4 mt-md-4 mt-2' onSubmit={(event)=>{editform(event)}}>
            <div>
                <div className='mt-2 fw-bold'>
                    Rating Type:
                </div>
                <div className='mt-2'>
                    <select className='form-select' ref={rating_typeRef} value={rating_type_val} onChange={(e)=>{
                            setrating_type_val(e.target.value);
                            for(let i in rating_types){
                            
                                if(rating_types[i].id == e.target.value){
                                    setrating_scale(rating_types[i].rating_scale);
                                }
                            }
                        }
                        
                    }>
                        {
                            rating_types.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Rating Scale:
                </div>
                <div className='mt-2'>
                    <select className='form-select' ref={rating_scaleRef} value={rating_scale_val} onChange={(e)=>{setrating_scale_val(e.target.value)}}>
                        {
                            rating_scale.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            {   Number(rating_type_id) == 3 &&
                <div>
                    <div className='mt-2 fw-bold'>
                        Fund Type:
                    </div>
                    <div>
                    <select className='form-select' value={fund_type_val} onChange={(e)=>{setfund_type_val(e.target.value)}}>
                        <option value={''}>Select</option>
                        {
                            fund_type.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                    </div>
                </div>
            }
            <div>
                <div className='mt-3 fw-bold'>
                    Approved Clients:
                </div>
                <div className='mt-2'>
                    <select className='form-select' ref={client_approvedRef} value={approved_clients_val} onChange={(e)=>{setapproved_clients_val(e.target.value)}}>
                        {
                            approved_clients.map((value, index)=>{
                                return(
                                    <option value={value.ID}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Rating Mandatee:
                </div>
                <div className='mt-2'>
                    <input className='form-control' type='file' onChange={(e)=>{setrating_mandate(e.target.files[0])}} />
                </div>
                {
                    (CD_mandate.rating_mandate !== null && CD_mandate.rating_mandate !== undefined) ?
                    CD_mandate.rating_mandate.includes('http') == true ?
                    <div className='mt-2'>
                        Uploaded File: <a href={CD_mandate.rating_mandate} target='_blank'>File</a>
                    </div>
                    :
                    <div className='mt-2'>
                        Uploaded File: <a href={`https://209.97.168.200/pacrawizpackv3/public/view/storage/BD/ratingmandate/${CD_mandate.rating_mandate}`} target='_blank'>File</a>
                    </div>
                    : null
                }
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Fee Mandate:
                </div>
                <div className='mt-2'>
                    <input className='form-control' type='file' onChange={(e)=>{setfee_mandate(e.target.files[0])}} />
                </div>
                {
                    (CD_mandate.fee_mandate !== null && CD_mandate.fee_mandate !== undefined) ?
                    CD_mandate.fee_mandate.includes('http') == true ?
                    <div className='mt-2'>
                        Uploaded File: <a href={CD_mandate.fee_mandate} target='_blank'>File</a>
                    </div>
                    :
                    <div className='mt-2'>
                        Uploaded File: <a href={`https://209.97.168.200/pacrawizpackv3/public/view/storage/BD/feemandate/${CD_mandate.fee_mandate}`} target='_blank'>File</a>
                    </div>
                    : null
                }
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Effective Date:
                </div>
                <div className='mt-2'>
                    <input className='form-control' ref={EffectiveDateRef} defaultValue={CD_mandate.effectiveDate} type='date' />
                </div>
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Duration:
                </div>
                <div className='mt-2'>
                    <select className='form-select' ref={rating_scaleRef} value={duration} onChange={(e)=>{setduration(e.target.value)}}>
                        <option value={''}>Select</option>
                        <option value={'Fixed'}>Fixed</option>
                        <option value={'Perpetual'}>Perpetual</option>
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Renewal / Termination Date :
                </div>
                <div className='mt-2'>
                    <input className='form-control' ref={RenewalDateRef} defaultValue={CD_mandate.renewalDate} type='date' />
                </div>
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Fee Category:
                </div>
                <div className='mt-2'>
                    <select className='form-select' value={feeCat_val} onChange={(e)=>{
                        setfeeCat_val(e.target.value)
                        for(let i in feeCat){
                            if(Number(e.target.value) == feeCat[i].id){
                                // console.log(`${feeCat[i].fee_min}-------${feeCat[i].fee_max}`);
                                // feeRangeRef.current.value = `${feeCat[i].fee_min}-------${feeCat[i].fee_max}`
                                // setfeeCat_val(CD_mandate.feeCatgId);
                                setfeeMx(feeCat[i].fee_max);
                                setfeeMn(feeCat[i].fee_min);
                            }
                        }
                        }}>
                        <option value={''}>Select</option>
                        {
                            feeCat.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.fee_categ}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Fee Range:
                </div>
                <div className='mt-2'>
                    <input className='form-control' ref={feeRangeRef} value={`${feeMn}-------${feeMx}`} disabled />
                </div>
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Billing Frequency:
                </div>
                <div className='mt-2'>
                    <select className='form-select' ref={rating_scaleRef} value={billFreqy} onChange={(e)=>{setbillFreqy(e.target.value)}}>
                        <option value={''}>Select</option>
                        <option value={'Annual'}>Annual</option>
                        <option value={'Biannual'}>Biannual</option>
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Fees:
                </div>
                <div className='mt-2'>
                    <input className='form-control' ref={FeesRef} defaultValue={CD_mandate.feeAnvl} />
                </div>
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Increment %
                </div>
                <div className='mt-2'>
                    <input className='form-control' defaultValue={increment} onChange={(e)=>{setincrement(e.target.value)}} />
                </div>
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Entity's Total Asset / Issue Size as on agreement (PKR mln)
                </div>
                <div className='mt-2'>
                    <input className='form-control' onChange={handleChange} type='number' value={inputValue1} required />
                </div>
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Revenue Size(PKR mln)
                </div>
                <div className='mt-2'>
                    <input className='form-control' onChange={handleChange2} type='number' value={inputValue2} required />
                </div>
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Comments if Variation exist in fee charged
                </div>
                <div className='mt-2'>
                    <textarea className='form-control' ref={commentRef} defaultValue={CD_mandate.reason_outof_range_fee} />
                </div>
            </div>
            <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                    severity={alertType}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setalertOpen(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                    >
                    {AlertMessage}
                    </Alert>
                </Collapse>
            </Box>
            <div className='mt-4'>
                <button className='btn btn-primary me-2' ref={btnRef} type='submit'>Submit</button>
                {can_approve_mandate == 1 && <button className='btn btn-success' ref={btnRef2} onClick={()=>{approve()}} type='button'>Approve</button>}
            </div>
        </form>
      
    </div>
  )
}
