import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { url } from '../../../Config';
import { useRef } from 'react';
import TableComponent from '../../Atoms/TableComponent';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { NavLink } from "react-router-dom";
import { Check, Clear, Event } from "@material-ui/icons";
import { Table, TableCell, TableRow } from "@mui/material";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';


export default function Cob_to_ratings(props) {
    const id = localStorage.getItem("userID");
    const [osOpinions, setosOpinions] = useState([]);
    const [teamlead, setteamlead] = useState([]);
    const [analyst, setanalyst] = useState([]);
    const [cob, setcob] = useState([]);
    const [cro, setcro] = useState([]);
    const [cobList, setcobList] = useState([]);
    const options = useRef(null);
    const opinion = useRef(null);
    const team_lead = useRef(null);
    const analyst_id = useRef(null);
    const [bool, setbool] = useState(false);
    const [alertopen, setalertOpen] = useState(false);
    const [alertopen2, setalertOpen2] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [AlertMessage2, setalertmessage2] = useState();
    const reason = useRef(null);
    const close = useRef(null);
    const targetRef = useRef(null);


    const rightsval = localStorage.getItem("rights");
  
    let rights = [];
  
    if(rightsval !== null){
      rights = rightsval.split(",");
    }

    useEffect(()=>{
        setcobList([]);
        setosOpinions([])
        const func = async ()=>{
            const res = await axios.get(`${url}/api/clTransfer/${id}`);
            const resp = res.data;
            setosOpinions(resp.osOpinions);
            setteamlead(resp.team_lead);
            setanalyst(resp.analysts);
            setcob(resp.cob);
            setcro(resp.cro);
            setcobList(resp.cobList);
        }

        func();
    },[bool])

    const notify = async (id)=>{
        const res = await axios.post(`${url}/api/clientTransferNotification`, {
            "id": id
        });
        const resp = res.data;
        if(resp.status == true){
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success"); 
            setbool(!bool);   
        }
        else{
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error")
        }
    }

    const submit = async ()=>{
        const res = await axios.post(`${url}/api/clientTransfer`, {
            "id_team" : team_lead.current.value ,
            "id_analyst" : analyst_id.current.value,
            "id" : Number(id),
            "id_opinion" : opinion.current.value
        });

        const resp = res.data;
        if(resp.status == true){
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success");
            setbool(!bool);
            opinion.current.value = 0;
            options.current.hidden = true;
        }
        else{
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error")
        }

    }

    const revert = async()=>{
        const op = opinion.current.value;
        if(reason.current.value == null || reason.current.value == ""){
            setalertOpen2(true);
            setalertmessage2('Reason is required! plz fill reason box!');
            return;
        }
        const res = await axios.post(`${url}/api/revertBack/${op}`, {
                'revert_to_cob_reason' : reason.current.value
            });
        const resp = res.data;
        if(resp.status == true){
            close.current.click();
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success");
            setbool(!bool);
            opinion.current.value = 0;
            options.current.hidden = true;
            reason.current.value = null;
        }
        else{
            setalertOpen2(true);
            setalertmessage2(resp.message);
        }
    }

    const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };

    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 210;
        }
      };
      const responsiveColumnPin2 = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "right";
        }
      };
      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };
      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
      const sectorHide = () => {
        if (props.screenWidth > 500) {
          return false;
        } else {
          return true;
        }
      };
    
      function innerDissemDate(params) {
        if (params.data.Dissemination == null) {
          return "-";
        } else {
          const date = new Date(params.data.Dissemination);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
    
      function yes(params) {
        if (params.value === "nullData") {
          return <Skeleton />;
        }
        if (params.data.rw === "YES") {
          return "Yes";
        } else if (params.data.rw === "NO" || params.data.rw === "-") {
          return "-";
        }
      }
    
      function innerNotiDate(params) {
        if (params.data.Notification == null) {
          return "-";
        } else {
          const date = new Date(params.data.Notification);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };
      const outstandingdatefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent = gridApi.api.getFilterInstance("Notification");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
        var dateFilterComponent1 = gridApi.api.getFilterInstance("Dissemination");
        dateFilterComponent1.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };

      const customComparator = (valueA, valueB) => {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      };

      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          field: "sNo",
          // minWidth: 66,
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
    
        {
          headerName: "Client Name",
          minWidth: responsiveColumns(),
          comparator: customComparator,
          field: "name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          filterParams: {
            caseSensitive: false,
          },
          cellRenderer: function (params) {
            if (params.value === "nullData") return <Skeleton />;
            else{
    
              if(params.data != null){
                return (
                  <a
                    href={`https://209.97.168.200/pacrawizpackv3/public/admin/pacraWork/${params.data.Id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary"
                  >
                    {params.value}
                  </a>
                );
            }
            }
          },
          // cellRenderer: cellrandered,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
    
        {
          headerName: "Team",
          field: "display_name",
          minWidth: 130,
          hide: sectorHide(),
          maxWidth: 130,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          tooltipField: "Industry",
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },

        {
            headerName: "Creation Date",
            hide: sectorHide(),
            field: "creation_date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },

        {
            headerName: "Last Updated",
            hide: sectorHide(),
            field: "updated_at",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },

        {
            headerName: "Actions",
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            field: "to_ratings",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
              if(params.value == null){
                return(
                    <button className='btn btn-success' onClick={()=>{notify(params.data.id)}}>Notify</button>
                )
              }
              else if(params.value == 'rejected'){
                return(
                    <button className='btn btn-success' onClick={()=>{notify(params.data.id)}}>Notify Again</button>
                )
              }
              else{
                return(
                    <button className='btn btn-success' disabled>Notified</button>
                )
              }
            },
            tooltipField: "name",
            pinned: responsiveColumnPin2(),
        },
      ];

      
      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
          <Table className="overflow-scroll responsiveTableFonts">
            {props.screenWidth <= 500 ? (
              <TableRow>
                <TableCell
                  variant="head"
                  className="fw-bolder responsiveTableFonts py-1"
                >
                  Sector
                </TableCell>
                <TableCell className="responsiveTableFonts py-1">
                  {params.data.Industry}
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Rating Type
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.RatingScale}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                {" "}
                Team
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.managerName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Analyst
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.pacraAnalyst}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Action
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.RatingAction}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                R|LT
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.RatingLT}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                R|ST
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.RatingST}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                RW
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {yes(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                CF
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.cf}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Outlook
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.Outlook}
              </TableCell>
            </TableRow>
            {props.screenWidth <= 500 ? (
              <TableRow>
                <TableCell
                  variant="head"
                  className="fw-bolder responsiveTableFonts py-1"
                >
                  Notification
                </TableCell>
                <TableCell className="responsiveTableFonts py-1">
                  {innerNotiDate(params)}
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Dissemination
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerDissemDate(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                PR
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.value === "nullData" ? (
                  <Skeleton />
                ) : params.data.recordid ? (
                  <a
                    href={`https://209.97.168.200/pacrawizpackv3/public/admin/finalPr/${params.data.recordid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <Check style={{ size: "20 20" }} className="theme_text" />{" "}
                  </a>
                ) : (
                  <Clear className="theme_text" />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                RR
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.value === "nullData" ? (
                  <Skeleton />
                ) : params.data.sr ? (
                  <a
                    href={`https://209.97.168.200/pacrawizpackv3/storage/app/${params.data.sr}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <Check className="theme_text" />{" "}
                  </a>
                ) : (
                  <Clear className="theme_text" />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                H
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.value === "nullData" ? (
                  <Skeleton />
                ) : params.data.Id ? (
                  <a
                    href={`https://209.97.168.200/pacrawizpackv3/public/admin/pacraWork/${params.data.Id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Event className="theme_text" />
                  </a>
                ) : (
                  <Clear className="theme_text" />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                SP
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.value === "nullData" ? (
                  <Skeleton />
                ) : params.data.shl ? (
                  <a
                    href={params.data.shl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Check className="theme_text" />
                  </a>
                ) : (
                  <Clear className="theme_text" />
                )}
              </TableCell>
            </TableRow>
          </Table>
        </h1>
      );

return (
    <div style={{height: "90vh"}}>

        { rights.includes("35") &&
            <div className='card shadow m-5 mx-auto p-3 col-10' >
            <div className='fs-5 fw-bold text-center text-decoration-underline' style={{color: "#000078"}}>
                COB to Ratings Transfer
            </div>
            <div className='mx-auto mt-2 mt-1' style={{width: "fit-content"}}><button className='btn btn-success' onClick={()=>{window.open(`/cob-to-rating-log`, '_blank')}}>Transfer Log</button></div>

            <Box className="mx-auto" ref={targetRef} sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
            </Box>
            <div>
                <div className='mt-5 col-10 mx-auto'>
                    <label className='fw-6 fw-bold text-decoration-underline'>Client :</label>
                </div>
                <div className='mt-1 col-10 mx-auto'>
                    <select className='form-select' ref={opinion} onChange={(e)=>{
                        if(e.target.value == 0){
                            options.current.hidden = true
                        }
                        else{
                            options.current.hidden = false;
                        }
                    }}>
                        <option value={0}>Select Client</option>
                        {
                            osOpinions.map((value, index)=>{
                                return(
                                    <option value={value.Id}>{value.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div ref={options}  hidden={true}>
                <div>
                    <div className='mt-2 col-10 mx-auto'>
                        <label className='fw-6 fw-bold text-decoration-underline'>CRO :</label>
                    </div>
                    <div className='mt-1 col-10 mx-auto'>
                        <select className='form-select'>
                            {
                                cro.map((value, index)=>{
                                    return(
                                        <option value={value.id}>{value.display_name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>

                <div>
                    <div className='mt-2 col-10 mx-auto'>
                        <label className='fw-6 fw-bold text-decoration-underline'>Team Lead :</label>
                    </div>
                    <div className='mt-1 col-10 mx-auto'>
                        <select className='form-select' ref={team_lead}>
                            {
                            teamlead.map((value, index)=>{
                                        return(
                                            <option value={value.id}>{value.display_name}</option>
                                        )
                                    })
                            }
                        </select>
                    </div>
                </div>

                <div>
                    <div className='mt-2 col-10 mx-auto'>
                        <label className='fw-6 fw-bold text-decoration-underline'>Analyst :</label>
                    </div>
                    <div className='mt-1 col-10 mx-auto'>
                        <select className='form-select' ref={analyst_id}>
                            {   

                                cob.map((value, index)=>{
                                    return(
                                        <option value={value.id}>{value.display_name}</option>
                                    )
                                })
                            }
                            {
                                analyst.map((value, index)=>{
                                    return(
                                        <option value={value.id}>{value.display_name}</option>
                                    )
                                })
                            }
                            {
                                teamlead.map((value, index)=>{
                                    return(
                                        <option value={value.id}>{value.display_name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='mt-3 mb-3 mx-auto' style={{width: "fit-content"}}>
                    <button className='btn btn-primary me-1' onClick={()=>{submit()}}> Submit </button>
                    <button className='btn btn-danger' data-bs-toggle="modal" data-bs-target='#revert'> Revert Back to COB </button>
                </div>
            </div>
            <div className="modal fade" id='revert' tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Reason to Revert</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{setalertOpen2(false)}}></button>
                        </div>
                        <div className="modal-body">
                        <Box className="mx-auto" sx={{ width: "98%" }}>
                            <Collapse in={alertopen2}>
                                <Alert
                                severity={'error'}
                                action={
                                    <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setalertOpen2(false);
                                    }}
                                    >
                                    <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                                >
                                {AlertMessage2}
                                </Alert>
                            </Collapse>
                        </Box>
                            <div className='fw-bold'>
                            Reason
                            </div>
                            <div>
                            <textarea className='form-control' ref={reason} style={{height: "100%"}}></textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" ref={close} data-bs-dismiss="modal" onClick={()=>{setalertOpen2(false)}}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={()=>{revert()}}>Submit</button>
                        </div>
                        </div>
                    </div>
            </div>

            </div>
        }
        {
            rights.includes("34") &&
                <div>
                <Box className="mx-auto" ref={targetRef} sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
                                
                </Box>
                <TableComponent
                Data={cobList}
                screenWidth={props.screenWidth}
                MobViewRender={MobViewRender}
                columnDefs={columnDefs}
                datefilters={datefilters}
                datefilter={true}
                />
                </div>
        }
    </div>
);

}