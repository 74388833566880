import React, {useState, useEffect, useRef} from 'react';
import { url } from '../../../../../Config';
import axios from 'axios';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";



export default function Editsponsers(props) {
    
    const id = localStorage.getItem("userID");
    const userid = localStorage.getItem("userID");
    const { sponser_id } = useParams();
    const Navigate = useNavigate();
    const nameRef = useRef(null);
    const sharesRef = useRef(null);
    const PercentageRef = useRef(null);
    const AssociationRef = useRef(null);
    const positionRef = useRef(null);
    const introRef = useRef(null);
    const imageRef = useRef(null);
    const btnRef = useRef(null);


    const [data, setdata] = useState({});
    const [association, setassociation] = useState('');
    const [position_company, setposition_company] = useState('');
    const [img, setimg] = useState(null);    
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [bool, setbool] = useState(false);

    const rightsval = localStorage.getItem("rights");
  
    let rights = [];
  
    if(rightsval !== null){
      rights = rightsval.split(",");
    }
  
    const has_right = rights.includes("3");
    const can_edit_after_approval = rights.includes("51");



    useEffect(()=>{
        setdata({})
        const func = async ()=>{
            const res = await axios.get(`${url}/api/UpdateShareholders/${sponser_id}`);
            const resp = res.data;
            setdata(resp.shareholder);
            setposition_company(resp.shareholder.position_company)
            setassociation(resp.shareholder.association)
            if(resp.shareholder.image_path == null){
                if(imageRef.current){
                    imageRef.current.required = true;
                }
            }

        }

        func();
    },[!bool, imageRef.current])

    const editForm = async (event)=>{
            event.preventDefault();
            setalertOpen(false);
            if(association == ''){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Association First!`);
                return ;
            }
            if(position_company == ''){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Option from Position in company First!`);
                return ;
            }
            if(img !== null && img !== undefined){
                const input = document.getElementById('imageInput');
                const allowedFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg+xml'];
                const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

                const file = input.files[0];
                
                // Check file format
                if (!allowedFormats.includes(file.type)) {
                    setalertType("error");
                    setalertOpen(true);
                    setalertmessage('Invalid file format. Please choose a valid image file.');
                    return;
                }

                // Check file size
                if (file.size > maxFileSize) {
                    setalertType("error");
                    setalertOpen(true);
                    setalertmessage('File size exceeds the maximum allowed limit (2MB).');
                    return;
                }
            }

            btnRef.current.disabled = true;

            const fileData = new FormData();
            fileData.append("sponsorName", nameRef.current.value);
            fileData.append("noOfshares", sharesRef.current.value);
            fileData.append("sharesPercent", PercentageRef.current.value);
            fileData.append("association", association);
            fileData.append("position", position_company);
            fileData.append("txtAreaIntro", introRef.current.value);
            if(img !== null && img !== undefined){
                fileData.append("sponsorsImage", img); 
            }
            fileData.append("userid", Number(id));
            fileData.append("id", Number(sponser_id));

            const res = await axios.post(`${url}/api/onUpdateShareholders`,
                fileData,
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
            );
            
            const resp = res.data;
            if(resp.status == true){
                btnRef.current.disabled = false;
                setbool(!bool);
                setalertType("success");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
            else{
                btnRef.current.disabled = false;
                setalertType("error");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
    }

    const approve = async ()=>{
        setalertOpen(false);
        const res = await axios.post(`${url}/api/ApproveShareholders`, {
            'id' : Number(sponser_id),
            'userid' : Number(userid)
        });
        const resp = res.data;
        if(resp.status == true){
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success");
            setbool(!bool);
        }
        else{
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error")
        }
    }

  return (
    <div>
        <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/secp-dashboard"} >Dashboard</Link></li>
                    <li className="breadcrumb-item"><Link to={`/view-shareholder/${sponser_id}`} >Sponsors/Sharehoders</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Update Sponsor/Sharehoder</li>
                </ol>
            </nav>
            <hr/>
        </div>
    <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
      Update Sponsor/Sharehoder
    </div>
    <form className ='col-10 card shadow p-3 mx-auto mt-2' onSubmit={(event)=>{editForm(event)}}>
      <div>
          <div className='fw-bold'>
              Sharehoder/Sponsor
          </div>
          <div className='mt-2'>
              <input className='form-control' ref={nameRef} defaultValue={data.name} placeholder='Enter Name' required />
          </div>
      </div>
      <div>
          <div className='mt-2 fw-bold'>
              No. of Shares
          </div>
          <div className='mt-2'>
              <input className='form-control' ref={sharesRef} defaultValue={data.no_of_shares} placeholder='Enter Shares' required />
          </div>
      </div>
      <div>
          <div className='mt-2 fw-bold'>
              Shares Percentage
          </div>
          <div className='mt-2'>
              <input className='form-control' ref={PercentageRef} defaultValue={data.shares_percent} placeholder='Enter Percentage' required />
          </div>
      </div>
      <div>
          <div className='mt-2 fw-bold'>
              Association
          </div>
          <div className='mt-2'>
              <select className='form-select' value={association} onChange={(e)=>{setassociation(e.target.value)}} ref={AssociationRef}>
                  <option value="">Select Association</option>
                  <option value="Associated">Associated</option>
                  <option value="Not associated">Not associated</option>
              </select>
          </div>
      </div>
      <div>
          <div className='mt-2 fw-bold'>
              Position in company
          </div>
          <div className='mt-2'>
              <select className='form-select' value={position_company} onChange={(e)=>{setposition_company(e.target.value)}} ref={positionRef}>
                  <option value="">Select Position in company</option>
                  <option value="Nominee Directors / Shareholder">Nominee Directors / Shareholder</option>
                  <option value="Director / Shareholder">Director / Shareholder</option>
                  <option value="Shareholder">Shareholder</option>
              </select>
          </div>
      </div>
      <div>
          <div className='mt-2 fw-bold'>
              Introduction
          </div>
          <div className='mt-2'>
              <textarea className='form-control' defaultValue={data.introduction} ref={introRef} required />
          </div>
      </div>
      <div>
          <div className='mt-2 fw-bold'>
              Image
          </div>
          <div className='mt-2'>
              <input className='form-control' ref={imageRef} type='file' onChange={(e)=>{
              setimg(e.target.files[0]);
          }} id="imageInput" accept=".jpeg, .jpg, .png, .gif, .svg"  />
          </div>
          {data.image_path !== null &&
            <div className='mt-1'>
            Uploaded Image: <img src={`${url}/api/getImage/${data.image_path}`} style={{wdith: '50px', height: '50px'}}  />
          </div>
          }
      </div>
      <Box className="mx-auto" sx={{ width: "98%" }}>
          <Collapse in={alertopen}>
              <Alert
              severity={alertType}
              action={
                  <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                      setalertOpen(false);
                  }}
                  >
                  <CloseIcon fontSize="inherit" />
                  </IconButton>
              }
              sx={{ mb: 2 }}
              >
              {AlertMessage}
              </Alert>
          </Collapse>
      </Box>
      <div className='mt-3'>
          {(has_right == true || can_edit_after_approval || data.status == 'submit' || data.status == null) && <button className='btn btn-primary me-1' ref={btnRef}  type='submit'>Update</button>}
          {data.status !== 'approve' && has_right == true && <button className='btn btn-success' onClick={()=>{approve()}} type='button'>Approve</button>}
      </div>
    </form>
    <br />
  </div>
  )
}
