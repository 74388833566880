
if(window.location.hostname == 'localhost'){

  var url = "http://localhost:8000";
  var url2 = "http://localhost:8001";

}
else{
  var url = "https://209.97.168.200/pacrawizpackv3/public";
  var url2 = "https://209.97.168.200/pacrawizpackv3/public";
}

const url3 = "https://209.97.168.200/pacrawizpackv3";

function formatDatefunc(inputDate) {
    // Parse the input date string
    const parsedDate = new Date(inputDate);
  
    // Extract day, month, and year components
    const day = parsedDate.getDate().toString().padStart(2, '0');
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = parsedDate.getFullYear();
    // Create the formatted date string in the desired format
    const formattedDate = `${day}-${month}-${year}`;
  
    return formattedDate;
  }

export {url, url2, url3, formatDatefunc};