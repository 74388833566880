import React, {useState, useEffect, useRef} from 'react';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import TableComponent from '../../Atoms/TableComponent';
import DeleteIcon from '@mui/icons-material/Delete';
import { Table, TableCell, TableRow, Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { url } from '../../../Config';
import axios from 'axios';
import Select from "react-select";
import bg from "../../../Assets/Images/greyModel.jpg"




export default function Inprocess_jcr(props) {

    const Navigate = useNavigate();
    const [opdata, setopdata] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState();
    const [status, setstatus] = useState(false);
    const [group, setgroup] = useState([]);
    const [industries, setindustries] = useState([]);
    const [sectors, setsectors] = useState([]);
    const [groupval, setgroupval] = useState('');
    const [industriesval, setindustriesval] = useState('');
    const [sectorsval, setsectorsval] = useState('');
    const [name, setname] = useState('');
    const btnRef = useRef(null);
    const divRef = useRef(null);
    const div2Ref = useRef(null);
    const [opinionid, setopinionid] = useState(null);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/inprocess_jcr`);
            const resp = res.data;
            const mappedOptions = resp.inprocess.map(item => ({ value: item.Id, label: item.Entity }));
            setopdata(mappedOptions);
        }
        func();

    },[])

    const handleSelect = async(data)=> {

        setSelectedOptions(data);
        setopinionid(data.value);
        setstatus(false);
        setalertOpen(false);
        divRef.current.hidden = false;
        div2Ref.current.hidden = true;
        const res = await axios.get(`${url}/api/getclient_industry/${data.value}`);
        const resp = res.data;
        setgroup(resp.group);
        setname(resp.data[0].name);
        for(let i in resp.group){
            if(resp.group[i].id == resp.data[0].segment_id){
                setindustries(resp.group[i].industries);
                for(let j in resp.group[i].industries){
                    if(resp.group[i].industries[j].id == resp.data[0].main_sector_id){
                        setsectors(resp.group[i].industries[j].sectors);
                    }
                }
            }
        }

        setgroupval(resp.data[0].segment_id);
        setindustriesval(resp.data[0].main_sector_id);
        setsectorsval(resp.data[0].sector_id);
        divRef.current.hidden = true;
        setstatus(true);
        
    }

   

    const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
        </h1>
      );

      const editForm = async (event)=>{
        event.preventDefault();
        btnRef.current.disabled = true;
        btnRef.current.innerHTML = "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";


        const res = await axios.post(`${url}/api/update_client_industry`,{
            'segment': groupval,
            'mainsector': industriesval,
            'subsector': sectorsval,
            'client_id': opinionid
        })

        const resp = res.data;
        if(resp.status == true){
            btnRef.current.disabled = false;
            btnRef.current.innerHTML = "Update";

            // Navigate('/sector_study');
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btnRef.current.disabled = false;
            btnRef.current.innerHTML = "Update";

            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
    }



  return (
    <div style={{ display: "flex"  , backgroundImage:`url(${bg})`, height: '90vh'}}>
      {/* <TableComponent
      Data={props.data}
      rowstatus= {props.rowstatus}
      screenWidth={props.screenWidth}
      MobViewRender={MobViewRender}
      columnDefs={columnDefs}
      datefilters={datefilters}
      datefilter={false}
       /> */}
       <div className='col-12'>
       <div className='fs-2 fw-bold mt-2 text-center' hidden={false} style={{color: "#000078"}}>
            Update Industry (VIS)
        </div>
       <div className='col-10 mx-auto mt-3'>
            <Select
                options={opdata}
                placeholder="Select Opinion"
                value={selectedOptions}
                onChange={handleSelect}
                isSearchable={true}
                // isMulti
            />
       </div>
       <div className='fs-3 fw-bold mt-5 text-center' ref={div2Ref} hidden={false} style={{color: "#000078"}}>
            Form Will Appear Here...
        </div>
       {status == true &&
       <>
        <form className='card shadow col-11 mx-auto p-2 mt-2' onSubmit={(event)=>{editForm(event)}}>
                <div className='mt-2'>
                    <div className='fs-3 fw-bold text-center mt-2 mb-3' style={{color: "#000078"}}>
                        {name}
                    </div>
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Update Segment:
                    </div>
                    <div className='mt-2'>
                    <select className='form-select' value={groupval} onChange={(e)=>{
                        setgroupval(e.target.value);
                        if(e.target.value == ''){
                            setsectors([]);
                            setindustries([]);
                        }
                        else{
                            for(let i in group){
                                if(group[i].id == e.target.value){
                                    setindustries(group[i].industries);
                                }
                            }
                        }
                        }
                        
                        } required>
                        <option value={''}>Select Group</option>
                        {
                            group.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                    </div>
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Update Main Sector:
                    </div>
                    <div className='mt-2'>
                        <select className='form-select' value={industriesval} onChange={(e)=>{
                            setindustriesval(e.target.value)
                            if(e.target.value == ''){
                                setsectors([]);
                            }
                            else{
                                for(let i in industries){
                                    if(industries[i].id == e.target.value){
                                        setsectors(industries[i].sectors);
                                    }
                                }
                            }
                            }} required>
                            <option value={''}>Select Industry</option>
                            {
                                industries.map((value, index)=>{
                                    return(
                                        <option value={value.id}>{value.title}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div>
                <div className='mt-2 fw-bold'>
                    Update Sub Sector:
                </div>
                <div className='mt-2'>
                    <select className='form-select' id='sectorname' value={sectorsval} onChange={(e)=>{
                        setsectorsval(e.target.value);
                        }} required>
                        <option value={''}>Select Sector</option>
                        {
                            sectors.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <Box className="mx-auto mt-2 mb-2" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
            </Box>
            <div>
                    <button className='btn btn-primary mt-3 mt-2 me-1' type='submit' ref={btnRef}>Update</button>
            </div>
        </form>
       </>
       }
       <div className="mt-5 mx-auto" ref={divRef} hidden={true} style={{ width: "fit-content" }}>
        <div class="wrapper" id="loader2">
          <svg
            class="hourglass"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 120 206"
            preserveAspectRatio="none"
          >
            <path
              class="middle"
              d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
            />
            <path
              class="outer"
              d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
            />
          </svg>
        </div>
        </div>

       </div>

    </div>
  )
}
