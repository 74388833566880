import React, { useCallback, useEffect, useRef,useMemo } from "react";
import TableComponent from "../../../Atoms/TableComponent";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Check, Clear, Event } from "@material-ui/icons";
import { Table, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { url } from "../../../../Config";

export default function TeamleadInvites(props) {
    const gridRef = useRef();
    const [name, setname] = useState(Array(props.inviteClientsList.length));
    const [email, setemail] = useState(Array(props.inviteClientsList.length));
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const targetRef = useRef(null);
    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);


    const submitL3 = async (name, email, id, btnid, index)=>{
        document.getElementById(btnid).innerHTML = "Please Wait..."
        const buttons = document.getElementsByClassName(`mail${index}`);

        for (let i = 0; i < buttons.length; i++) {
        buttons[i].disabled = true;
        }

        let res = await axios.post(`${url}/api/addLiaison3`, {
            "fname" : name,
            "email" : email,
            "id" : id
        });
        if(res.data.status == true){
            document.getElementById(btnid).innerHTML = "Email Sent"
            setalertType("success");
            setalertOpen(true);
            setalertmessage(res.data.message)
            props.setrefresh_invite((prevrefresh_invite)=> !prevrefresh_invite);
        }
        else{
            document.getElementById(btnid).innerHTML = "Send Invite Email"
            const buttons = document.getElementsByClassName(`mail${index}`);

            for (let i = 0; i < buttons.length; i++) {
            buttons[i].disabled = false;
            }
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
            setalertType("error");
            setalertOpen(true);
            setalertmessage(res.data.message)
            props.setrefresh_invite((prevrefresh_invite)=> !prevrefresh_invite);
        }
    }
    const updatesubmitL3 = async (name, email, id, btnid, index)=>{
        document.getElementById(btnid).innerHTML = "Please Wait..."
        const buttons = document.getElementsByClassName(`mail${index}`);

        for (let i = 0; i < buttons.length; i++) {
        buttons[i].disabled = true;
        }

        let res = await axios.post(`${url}/api/addLiaison3`, {
            "fname" : document.getElementById(name).value,
            "email" : document.getElementById(email).value,
            "id" : id
        });
        if(res.data.status == true){
            document.getElementById(btnid).innerHTML = "Email Sent"
            setalertType("success");
            setalertOpen(true);
            setalertmessage(res.data.message)
            props.setrefresh_invite((prevrefresh_invite)=> !prevrefresh_invite);
        }
        else{
            document.getElementById(btnid).innerHTML = "Send Invite Email"
            const buttons = document.getElementsByClassName(`mail${index}`);

            for (let i = 0; i < buttons.length; i++) {
            buttons[i].disabled = false;
            }
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
            setalertType("error");
            setalertOpen(true);
            setalertmessage(res.data.message)
        }
    }

    const sendmail = async (name, email, id, btnid, index)=>{
        document.getElementById(btnid).innerHTML = "Please Wait..."
        const buttons = document.getElementsByClassName(`mail${index}`);

        for (let i = 0; i < buttons.length; i++) {
        buttons[i].disabled = true;
        }


        let res = await axios.post(`${url}/api/sendinviteemail`, {
            "fname" : name,
            "email" : email,
            "id" : id
        });

        if(res.data.status == true){
            document.getElementById(btnid).innerHTML = "Email Sent"
            setalertType("success");
            setalertOpen(true);
            setalertmessage(res.data.message);
            props.setrefresh_invite((prevrefresh_invite)=> !prevrefresh_invite);

        }
        else{
            document.getElementById(btnid).innerHTML = "Send Invite Email"
            const buttons = document.getElementsByClassName(`mail${index}`);

            for (let i = 0; i < buttons.length; i++) {
            buttons[i].disabled = false;
            }
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
            setalertType("error");
            setalertOpen(true);
            setalertmessage(res.data.message);
        }
    }


    const responsiveColumns = () => {
      if (props.screenWidth < 770) {
        return null;
      } else {
        return 350;
      }
    };
    const responsiveColumnPin = () => {
      if (props.screenWidth < 770) {
        return null;
      } else {
        return "left";
      }
    };
    const detailShow = () => {
      if (props.screenWidth < 770) {
        return false;
      } else {
        return true;
      }
    };
  
    const columnHide = () => {
      if (props.screenWidth < 770) {
        return true;
      } else {
        return false;
      }
    };


  
    function fullDate(params) {
      if (params.value === "nullData") return <Skeleton />;
      if (params.value == null) {
        return "-";
      } else {
        const date = new Date(params.value);
        const yyyy = date.getFullYear();
        const yy = yyyy.toString();
        const y = yy.slice(2, 4);
        let mm = date.toLocaleString("default", { month: "short" });
        let dd = date.getDate();
        if (dd < 10) dd = "0" + dd;
        return dd + "-" + mm + "-" + y;
      }
    }


    const cellrandered = (params) => {
      if (params.value === "nullData") {
        return <Skeleton />;
      } else {
        return params.value;
      }
    };
    const outstandingdatefilters = (
      startDate,
      endDate,
      gridApi,
      getFilterType
    ) => {
      var dateFilterComponent = gridApi.api.getFilterInstance("Notification");
      dateFilterComponent.setModel({
        type: getFilterType(),
        inRange: true,
        dateFrom: startDate,
        dateTo: endDate,
      });
      var dateFilterComponent1 = gridApi.api.getFilterInstance("Dissemination");
      dateFilterComponent1.setModel({
        type: getFilterType(),
        inRange: true,
        dateFrom: startDate,
        dateTo: endDate,
      });
    };
    const customComparator = (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };

    const columnDefs = [
      {
        headerName: "",
        field: "sNo",
        maxWidth: 30,
        filter: true,
        menuTabs: false,
        pinned: responsiveColumnPin(),
        hide: detailShow(),
        cellRenderer: "agGroupCellRenderer",
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        headerName: "#",
        maxWidth: 50,
        field: "sNo",
        // minWidth: 66,
        sortable: true,
        filter: true,
        // filter: "agSetColumnFilter",
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
        },
        suppressFiltersToolPanel: true,
        menuTabs: false,
        cellRenderer: cellrandered,
        pinned: responsiveColumnPin(),
      },
  
      {
        headerName: "Opinion Name",
        minWidth: responsiveColumns(),
        field: "opinion_name",
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        filterParams: {
          caseSensitive: false,
        },
   
        tooltipField: "opinion_name",
        pinned: responsiveColumnPin(),
      },
  
      {
        headerName: "Sector",
        field: "sectorName",
        minWidth: 300,
        maxWidth: 300,
        hide: columnHide(),
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        tooltipField: "Industry",
      },

      {
        headerName: "Last Access",
        field: "last_access",
        minWidth: 130,
        hide: columnHide(),
        maxWidth: 130,
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        tooltipField: "last_access",
      },

      {
        headerName: "Action",
        field: "subscribtion",
        minWidth: 130,
        maxWidth: 130,
        sortable: true,
        cellRenderer: (params)=>{
            if(params.value == "Subscribed"){
                return (<button className="btn btn-success btn-sm" disabled>{params.value}</button>);
            }
            else if(params.value == "Invited"){
                return (<button className="btn btn-primary btn-sm" disabled>{params.value}</button>);
            }
            else{
                return(<button className="btn btn-warning btn-sm" id={`invite${params.data.id}`} data-bs-toggle="modal" data-bs-target={`#model${params.data.opinion_id}`}>{params.value}</button>)
            }
        },
        filter: "agSetColumnFilter",
        excelMode: "windows",
      },
      {
        headerName: "Invited",
        field: "",
        minWidth: 350,
        autoHeight: true,
        hide: columnHide(),
        sortable: true,
        cellRenderer: (params)=>{
            if(params.data.invited !== null){
                    return(
                        <div>
                            <b>{params.data.created_at}</b> | {" "}
                            {params.data.f_name} | {" "}
                            {params.data.email}
                            {params.data.registeredClient !== null && params.data.joined == null ? <b> | {params.data.invited}</b> : null}
                        </div>
                    )
            }
            else{
                return('-');
            }
        },
        filter: "agSetColumnFilter",
        excelMode: "windows",
      },

      {
        headerName: "Subscribed",
        field: "subscribed",
        minWidth: 130,
        maxWidth: 130,
        sortable: true,
        hide: columnHide(),
        filter: "agSetColumnFilter",
        excelMode: "windows",
      },
    ];
    const MobViewRender = (params) => (
      <h1 style={{ padding: "10px 20px" }}>
        <Table className="overflow-scroll responsiveTableFonts">
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Sector
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.sectorName}
              </TableCell>
            </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Last Access
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.last_access}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Invited
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
                <div>
                    <b>{params.data.created_at}</b> | {" "}
                       {params.data.f_name} | {" "}
                       {params.data.email}
                       {params.data.registeredClient !== null && params.data.joined == null ? <b> | {params.data.invited}</b> : null}
                </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Analyst
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.pacraAnalyst}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Action
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.RatingAction}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Subscribed
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.subscribed}
            </TableCell>
          </TableRow>
        </Table>
      </h1>
    );
    return (
      <>
        {props.inviteClientsList.map((value, index)=>{
            return(
                <div className="modal fade" id={`model${value.opinion_id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{zIndex: 1000000}}>
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{value.opinion_name}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{setalertOpen(false)}}></button>
                            </div>
                            <div className="modal-body" ref={targetRef} style={{overflow: "scroll"}}>
                            
                                <div className="row">
                                {value.liaison_1_filter !== false && <div className="card col-lg-5 col-md-5 col-sm-10 col-10 p-3 mb-2 shadow ms-lg-4 ms-md-4 ms-3">
                                <div className="fw-bold text-center mb-2">
                                    Name: {value.liaison_1_name}
                                </div>
                                <div className="fw-bold text-center mb-2">
                                    Liaison 1: {value.liaison_1_email}
                                </div>
                                <div className="mx-auto mb-2" style={{width: "fit-content"}}>
                                    <button className={`btn btn-info mb-2 mail${index}`} id={`l1btn${index}`} onClick={()=>{sendmail(value.liaison_1_name, value.liaison_1_email, value.id, `l1btn${index}`, index)}}>Send Invite Email</button>
                                </div>
                                </div>}


                                {value.liaison_2_filter !== false && <div className="card col-lg-5 col-md-5 col-sm-10 col-10 p-3 mb-2 shadow ms-lg-4 ms-md-4 ms-3">
                                <div className="fw-bold text-center mb-2">
                                    Name: {value.liaison_2_name}
                                </div>
                                <div className="fw-bold text-center mb-2">
                                    Liaison 2: {value.liaison_2_email}
                                </div>
                                <div className="mx-auto mb-2" style={{width: "fit-content"}}>
                                    <button className={`btn btn-info mb-2 mail${index}`} id={`l2btn${index}`} onClick={()=>{sendmail(value.liaison_2_name, value.liaison_2_email, value.id, `l2btn${index}`, index)}}>Send Invite Email</button>
                                </div>
                                </div>}


                                {value.ceo_email_filter !== false && <div className="card col-lg-5 col-md-5 col-sm-10 col-10 p-3 mb-2 shadow ms-lg-4 ms-md-4 ms-3">
                                <div className="fw-bold text-center mb-2">
                                    Name: {value.ceo_name}
                                </div>
                                <div className="fw-bold text-center mb-2">
                                    CEO: {value.ceo_email}
                                </div>
                                <div className="mx-auto" style={{width: "fit-content"}}>
                                    <button className={`btn btn-info mb-2 mail${index}`} id={`ceobtn${index}`} onClick={()=>{sendmail(value.ceo_name, value.ceo_email, value.id, `ceobtn${index}`, index)}}>Send Invite Email</button>
                                </div>
                                </div>}

                                { value.l4 == null ?
                                    <div className="card col-lg-5 col-md-5 col-sm-10 col-10 p-3 mb-2 shadow ms-lg-4 ms-md-4 ms-3">
                                    <div className="fw-bold text-center mb-2">
                                        Name: <input className="form-control" placeholder="Mr. xyz" id={`name${index}`}></input>
                                    </div>
                                    <div className="fw-bold text-center mb-2">
                                        Liaison 3: <input className="form-control" placeholder="Enter Email" id={`email${index}`}></input>
                                    </div>
                                    <div className="mx-auto" style={{width: "fit-content"}}>
                                        <button className={`btn btn-info mb-2 mail${index}`} id={`l3btn${index}`} onClick={()=>{submitL3(document.getElementById(`name${index}`).value, document.getElementById(`email${index}`).value, value.id, `l3btn${index}`, index)}}>Send Invite Email</button>
                                    </div>
                                    </div> :

                                    <div className="card col-lg-5 col-md-5 col-sm-10 col-10 p-3 mb-2 shadow ms-lg-4 ms-md-4 ms-3">
                                    <div className="fw-bold text-center mb-2">
                                        Name: <input className="form-control" placeholder="Mr. xyz" defaultValue={value.liaison_3_name} id={`name${index}`}></input>
                                    </div>
                                    <div className="fw-bold text-center mb-2">
                                        Liaison 3: <input className="form-control" placeholder="Enter Email" defaultValue={value.liaison_3_email} id={`email${index}`}></input>
                                    </div>
                                    <div className="mx-auto" style={{width: "fit-content"}}>
                                        <button className={`btn btn-info mb-2 mail${index}`} id={`l3btn${index}`} onClick={()=>{updatesubmitL3(`name${index}`, `email${index}`, value.id, `l3btn${index}`, index)}}>Send Invite Email</button>
                                    </div>
                                    </div>
                                }

                                </div>
                            </div>
                            <div className="modal-footer">
                                <Box className="mx-auto" ref={targetRef} sx={{ width: "98%" }}>
                                    <Collapse in={alertopen}>
                                        <Alert
                                        severity={alertType}
                                        action={
                                            <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setalertOpen(false);
                                            }}
                                            >
                                            <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mb: 2 }}
                                        >
                                        {AlertMessage}
                                        </Alert>
                                    </Collapse>
                                </Box>
                                <button type="button" className="btn btn-secondary" id={`reasonopenclose${index}`} data-bs-dismiss="modal" onClick={()=>{setalertOpen(false)}}>Close</button>
                            </div>
                            </div>
                        </div>
                </div>
            )
        })
        
        }
        <TableComponent
          // stating = {props.stating}
          path = {"Rating/Invites"}
          state={props.state}
          Data={props.inviteClientsList}
          screenWidth={props.screenWidth}
          MobViewRender={MobViewRender}
          columnDefs={columnDefs}
          datefilter={false}
        />
      </>
    );
}
