import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { url } from '../../../Config';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Swal from "sweetalert2";
import Tooltip from '@mui/material/Tooltip';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


export default function AddApplicablitiy(props) {

    const [data, setdata] = useState([]);
    const [value, setValue] = useState('');
    const [bool, setbool] = useState(false);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();

    useEffect(()=>{
        setdata([]);
        const func = async ()=>{
            const res = await axios.get(`${url}/api/add_applicabilitiy`);
            const resp = res.data;
            setdata(resp.applicability_show)
        }

        func();
    },[bool])

    const saveitem = async ()=>{
        const res = await axios.post(`${url}/api/save_applicabilitiy`, {
            'app_title' : value,
            'button': 'Save'
        })
        const resp = res.data;
        if(resp.status == true){
            setbool(!bool)
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
    }

    const edit = async (id, input)=>{
        const val = document.getElementById(input).value;
        const res = await axios.post(`${url}/api/save_applicabilitiy`, {
            'id' : Number(id),
            'app_title' : val,
            'button': 'Edit'
        })
        const resp = res.data;
        if(resp.status == true){
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
            document.getElementById(`Applicability${id}`).disabled = true;
            document.getElementById(`Edit${id}`).style.display = 'unset';
            document.getElementById(`Save${id}`).style.display = 'none';
        }
        else{
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
    }

    const deleteitem = async (id)=>{
        Swal.fire({ title: 'Do you want to delete this Applicability?', 
        showDenyButton: false, showCancelButton: true, confirmButtonText: 'Delete'})
        .then(async(result) => {
            if (result.isConfirmed) {
                const res = await axios.post(`${url}/api/save_applicabilitiy`, {
                    'id' : Number(id),
                    'button': 'Deactivate'
                })
                const resp = res.data;
                if(resp.status == true){
                    setbool(!bool);
                    setalertType("success");
                    setalertOpen(true);
                    setalertmessage(resp.message);
                }
                else{
                    setalertType("error");
                    setalertOpen(true);
                    setalertmessage(resp.message);
                }
            }
        })
    }


  return (
    <div style={{height: '90vh'}}>
      <div className='p-3'>
        
        <div className='mt-1 col-10 mx-auto'>
        <Box className="mx-auto" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
            </Box>
            <div>
                <div className='fs-5 fw-bold'>
                    Applicability Name
                </div>
                <input className='form-control' value={value} onChange={(e)=>{setValue(e.target.value)}} required/>
                <div className='mt-1'>
                    <button className='btn btn-primary btn-sm' type='button' onClick={()=>{saveitem()}}>Save</button>
                </div>
            </div>
        </div>
        <div className='mt-2 col-10 mx-auto' style={{height: '400px', overflow: 'scroll'}}>
            <table className='table table-striped table-hover table-bordered border-dark'>
                <thead>
                    <tr>
                        <th className='col-6'>Applicability</th>
                        <th className='col-2'>Action</th>

                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((value, index)=>{
                            return(                                

                                <tr>
                                    <td><input className='form-control' defaultValue={value.title} id={`Applicability${value.id}`} disabled/></td>
                                    <td>
                                    <Tooltip title="Edit"><EditIcon sx={{color: '#ffc107', display:'unset'}} id={`Edit${value.id}`} onClick={()=>{
                                        document.getElementById(`Applicability${value.id}`).disabled = false;
                                        document.getElementById(`Edit${value.id}`).style.display = 'none';
                                        document.getElementById(`Save${value.id}`).style.display = 'unset';
                                        }} hidden={false} /></Tooltip>
                                    
                                    <Tooltip title="Save"><SaveIcon sx={{color: '#000078', display: 'none'}} id={`Save${value.id}`} onClick={()=>{edit(value.id, `Applicability${value.id}`)}} /></Tooltip>
                                    <Tooltip title="Delete"><DeleteIcon sx={{color: 'red'}} onClick={()=>{deleteitem(value.id)}} /></Tooltip></td>

                                </tr>
                            )
                        })
                    }
                    
                </tbody>
            </table>
        </div>
      </div>
    </div>
  )
}
