import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../../Config';
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { Table, TableCell, TableRow, Tooltip } from "@mui/material";


export default function ApproveFormat(props) {
    const [data, setdata] = useState([]);
    const [count, setcount] = useState(0);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [bool, setbool] = useState(false);
    const rightsval = localStorage.getItem("rights");
  
    let rights = [];
  
    if(rightsval !== null){
      rights = rightsval.split(",");
    }
  
    const can_approve_assesment_format = rights.includes("47");
    const can_resubmit_assesment_format = rights.includes("48");

    useEffect(()=>{
        setdata([]);
        const func = async ()=>{
            const res = await axios.get(`${url}/api/get_approve_format`);
            const resp = res.data;
            setdata(resp.unapproved_models);
            setcount(resp.count);
        }

        func();
    },[bool])

    const approve = async (modelid)=>{
        document.getElementById(`approve${modelid}`).hidden = true;
        document.getElementById(`load${modelid}`).hidden = false;
        const res = await axios.get(`${url}/api/approve_format/${modelid}`);
        const resp = res.data;
        if(resp.status == true){
            // btn.current.disabled = false;
            setbool(!bool);
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("success");
        }
        else{
            document.getElementById(`approve${modelid}`).hidden = false;
            document.getElementById(`load${modelid}`).hidden = true;
            setalertOpen(true);
            // setalertmessage(resp.message);  
            setalertType("error");
        }
    }
    const resubmit = async (modelid)=>{
        document.getElementById(`re-submit${modelid}`).hidden = true;
        document.getElementById(`load2${modelid}`).hidden = false;
        const res = await axios.get(`${url}/api/reject_format/${modelid}`);
        const resp = res.data;
        if(resp.status == true){
            // btn.current.disabled = false;
            setbool(!bool);
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("success");
        }
        else{
            document.getElementById(`re-submit${modelid}`).hidden = false;
            document.getElementById(`load2${modelid}`).hidden = true;
            setalertOpen(true);
            // setalertmessage(resp.message);  
            setalertType("error");
        }
    }

  return (
    <div>
        <div className='fs-4 fw-bold text-center'>
            Assessment Report Pending Approvals
        </div>
        <Box className="mx-auto mt-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                    severity={alertType}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setalertOpen(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                        sx={{ mb: 2 }}
                    >
                    {AlertMessage}
                    </Alert>
                </Collapse>
                </Box>
        <div className='mt-3' style={{overflow: 'scroll'}}>
            <table className='table table-bordered border-dark table-striped table-hover'>
                <thead>
                    <tr className=''>
                        <th className='col-2'>Model Name</th>
                        <th className='col-8'>Headings</th>
                        <th className='col-2'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {
                        data.map((value, index)=>{
                            return(
                                <tr className={` ${value.status == '2' ? 'bg-danger' : null}`}>
                                    <td className='' style={value.status == '2' ? {color : 'white'} : {color: 'black'}}>{value.name}</td>
                                    <td className=''>
                                        {
                                        value.main_heading.map((value2, index2)=>{
                                            return(
                                                <div className='row mb-1'>
                                                    <div className='me-1 p-1 col-2 fw-bold' style={value.status == '2' ? {color : 'white'} : {color: 'black'}}>{value2.name}</div>
                                                    <div className='col-8 d-flex'>
                                                        {
                                                            value2.sub_heading.map((value3, index3)=>{
                                                                return(
                                                                    <>{(value3.name !== null) &&<div className='me-1 p-1' style={{color: 'white', backgroundColor:'grey'}}>{value3.name}</div>}</>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </td>

                                    <td className=''>
                                        {can_approve_assesment_format == true && <Tooltip title='Approve'><><i className="fa-solid fa-circle-check fa-lg me-1" id={`approve${value.id}`} data-bs-toggle="tooltip" data-bs-placement="top" title="Approve" hidden={false} style={value.status == '2' ? {color: 'white'} : {color: 'green'}} onClick={()=>{approve(value.id)}}></i><i className="fa-solid fa-spinner fa-spin fa-lg me-1" id={`load${value.id}`} hidden={true} style={value.status == '2' ? {color: 'white'} : {color: 'green'}}></i></></Tooltip>}
                                        {can_resubmit_assesment_format == true && <Tooltip title='Re-Submit'><><i className="fas fa-redo fa-lg me-1" id={`re-submit${value.id}`} data-bs-toggle="tooltip" data-bs-placement="top" title="Re-Submit" hidden={false} style={value.status == '2' ? {color: 'white'} : {color: 'red'}} onClick={()=>{resubmit(value.id)}}></i><i className="fa-solid fa-spinner fa-spin fa-lg me-1" id={`load2${value.id}`} hidden={true} style={value.status == '2' ? {color: 'white'} : {color: 'red'}}></i></></Tooltip>}
                                    </td>
                                    
                                </tr>
                            )
                        })
                    } */}
                    {
                        data.map((value, index)=>{
                            return(
                                <tr className={` ${value.status == '2' ? 'bg-danger' : null}`}>
                                    <td className='' style={value.status == '2' ? {color : 'white'} : {color: 'black'}}>{value.name}</td>
                                    <td style={{overflow: 'scroll'}}>
                                        <table className='table table-bordered border-dark table-striped table-hover'>
                                            {/* <thead>
                                                <tr>
                                                    <th>Main Heading</th>
                                                    <th>Sub Headings</th>
                                                </tr>
                                                </thead> */}
                                            <tbody>
                                                    {
                                                    value.main_heading.map((value2, index2)=>{
                                                        return(
                                                            
                                                                    <tr>
                                                                        <td className='me-1 p-1 col-2 fw-bold' style={value.status == '2' ? {color : 'white'} : {color: 'black'}}>{value2.name}</td>
                                                                        <td className='d-flex'>
                                                                            {
                                                                                value2.sub_heading.map((value3, index3)=>{
                                                                                    return(
                                                                                        <>{(value3.name !== null) ? <div className='me-1 p-1' style={{color: 'white', backgroundColor:'grey'}}>{value3.name}</div> : <div></div>}</>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                            )
                                                    })
                                                    }
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className=''>
                                        {can_approve_assesment_format == true && <Tooltip title='Approve'><><i className="fa-solid fa-circle-check fa-lg me-1" id={`approve${value.id}`} data-bs-toggle="tooltip" data-bs-placement="top" title="Approve" hidden={false} style={value.status == '2' ? {color: 'white'} : {color: 'green'}} onClick={()=>{approve(value.id)}}></i><i className="fa-solid fa-spinner fa-spin fa-lg me-1" id={`load${value.id}`} hidden={true} style={value.status == '2' ? {color: 'white'} : {color: 'green'}}></i></></Tooltip>}
                                        {can_resubmit_assesment_format == true && <Tooltip title='Re-Submit'><><i className="fas fa-redo fa-lg me-1" id={`re-submit${value.id}`} data-bs-toggle="tooltip" data-bs-placement="top" title="Re-Submit" hidden={false} style={value.status == '2' ? {color: 'white'} : {color: 'red'}} onClick={()=>{resubmit(value.id)}}></i><i className="fa-solid fa-spinner fa-spin fa-lg me-1" id={`load2${value.id}`} hidden={true} style={value.status == '2' ? {color: 'white'} : {color: 'red'}}></i></></Tooltip>}
                                    </td>
                                       
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
        <div className='ms-2'>
            <b>Total Record Found:</b> ({count})
        </div>
        <br />
    </div>
  )
}
