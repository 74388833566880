import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { url } from '../../../../Config';

export default function Bfr() {

    const [data, setdata] = useState(null);
    const [incorporationDate, setincorporationDate] = useState(null);
    const [licencetype, setlicencetype] = useState(null);
    const [legalStatusId, setlegalStatusId] = useState(null);
    const [majorityownership, setmajorityownership] = useState(null);
    const [krdData, setkrdData] = useState(null);
    const [Title, setTitle] = useState("");
    const {client_id, og_rating_id } = useParams();
    const [factorsselect, setfactorsselect] = useState(Array(6).fill("Please Select"));
    const [factorscomment, setfactorscomment] = useState(Array(6).fill());
    const [alertopen, setalertopen] = useState(false);
    const [AlertMessage, setAlertMessage] = useState(null);
    const [alertType, setalertType] = useState("success");


    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/getbfrdata/${client_id}/${og_rating_id}`);
                    if(res.data.client.legalStatusId == null){
                        res.data.client.legalStatusId = "Please Select";
                    }
                    if(res.data.client.type_of_license == null){
                        res.data.client.type_of_license = "Please Select";
                    }
                    const resp = res.data.data
                    const arrselect = [...factorsselect];
                    const arrcomment = [...factorscomment];
                    for(let i in resp){
                        if(resp[i].factor == 'Ownership' || resp[i].factor == 'Ownership/Members'){
                            const factorselect = resp[i].description;
                            const factorcomment = resp[i].comment;
                            arrcomment[0] = factorcomment;
                            arrselect[0] = factorselect;
                        }
                        if(resp[i].factor == 'Governance'){
                            const factorselect = resp[i].description;
                            arrselect[1] = factorselect;
                            const factorcomment = resp[i].comment;
                            arrcomment[1] = factorcomment;
                        }
                        if(resp[i].factor == 'Management and Client Services' || resp[i].factor == 'Management'){
                            const factorselect = resp[i].description;
                            arrselect[2] = factorselect;
                            const factorcomment = resp[i].comment;
                            arrcomment[2] = factorcomment;
                        }
                        if(resp[i].factor == 'Internal Controls and Regulatory Compilance' || resp[i].factor == 'Social Impact'){
                            const factorselect = resp[i].description;
                            arrselect[3] = factorselect;
                            const factorcomment = resp[i].comment;
                            arrcomment[3] = factorcomment;
                        }
                        if(resp[i].factor == 'Business Sustainability'){
                            const factorselect = resp[i].description;
                            arrselect[4] = factorselect;
                            const factorcomment = resp[i].comment;
                            arrcomment[4] = factorcomment;
                        }
                        if(resp[i].factor == 'Financial Sustainability'){
                            const factorselect = resp[i].description;
                            arrselect[5] = factorselect;
                            const factorcomment = resp[i].comment;
                            arrcomment[5] = factorcomment;
                        }
                    }
                    if(resp.length > 0){
                        setkrdData(resp[0].krd_text);
                    }
                    setfactorsselect(arrselect);
                    setfactorscomment(arrcomment);
                    if(res.data.prTitle == null){
                        setTitle("");
                    }
                    else{
                        setTitle(res.data.prTitle.title);
                    }

                    setlegalStatusId(res.data.client.legalStatusId);
                    setincorporationDate(res.data.client.incorporationDate);
                    setlicencetype(res.data.client.type_of_license);
                    setmajorityownership(res.data.client.majority_ownership);
                    setdata(res.data);
        }
        func();
    },[])

  const submit = async (event)=>{
        event.preventDefault();
        setalertopen(false);
        const arr = [];
        for(let i = 1; i < 7; i++){
            if(document.getElementById(`select${i}`) !== null){
                if(document.getElementById(`select${i}`).value !== 'Please Select' && document.getElementById(`comment${i}`).value !== "" && document.getElementById(`comment${i}`).value !== null){
                    arr.push({'factor': document.getElementById(`inner${i}`).innerHTML,
                              'comment': document.getElementById(`comment${i}`).value,
                              'description': document.getElementById(`select${i}`).value})
                }
            }
        }
        const res = await axios.post(`${url}/api/addRR_Krd`,{
            "type": "bfr",
            "factor": arr,
            "client_id": data.client.id,
            "krd": krdData,
            'opinion_id' : client_id,
            'recordId' : og_rating_id,
            'rrId' : og_rating_id,
            'record_id' : og_rating_id,
            'analyst_id' : data.analystId,
            'manager_id' : data.manager_id,
            'lead_rc_id' : data.lead_rc_id,
            'press_release_title' : Title,
            'opName' : data.opName,
            'rating_type_id' : data.ratingTypeId,
            'rating_scale_id' : data.ratingScaleId,
            'Legal_Stucture' : legalStatusId,
            'Year_of_Establishment' : incorporationDate,
            'Type_of_License' : licencetype,
            'Majority_Ownership' : majorityownership,
            'rating_scale_name' : data.ratingScaleName
        })
        if(res.data.status == true){
            setalertType("success");
            setAlertMessage("Successfully Saved!");
            setalertopen(true);
        }
        else{
            console.log(res, "response data");
            setalertType("error");
            setAlertMessage(res.data.error);
            setalertopen(true);
        }
    }


  return (
    <div style={{height: "90vh"}}>
        <br />
      {data !== null ? <form className='card shadow col-11 mx-auto p-2' style={{backgroundColor: "whitesmoke"}} onSubmit={submit}>
        <div className='text-center text-decoration-underline fw-bold fs-5' style={{ color: "#000078", fontFamily: "Kalinga" }}>
            Rating Rationale & Key Rating Drivers
        </div>
        <div className='text-center text-decoration-underline fw-bold fs-5 mt-2' style={{ color: "#000078", fontFamily: "Kalinga" }}>
            {data !== null ? data.opName : null}
        </div>
        <div className='col-11 mx-auto mt-2'>
            <input className='form-control' type='text' placeholder='Enter Press Release Title' id='title' value={Title} onChange={(e)=>{setTitle(e.target.value)}} required />
        </div>
        <hr />
        <div className='col-11 mx-auto'>
            <div className='card shadow mt-1 p-2' style={{backgroundColor: "whitesmoke"}}>
                <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Rating Rationale:</label>
                <div className='row mt-1'>
                   <div className='col-3 fs-6 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Factor</div>
                   <div className='col-3 fs-6 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Description</div>
                   <div className='col-6 fs-6 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Comment</div>
                </div>
                <div className='row'>
                    <div className='col-3' style={{fontFamily: "Kalinga"}} id='inner1'>{data.ratingScaleId == 28 ? 'Ownership/Members' : 'Ownership'}</div>
                    <div className='col-3'>
                        
                        <select className='form-select' id='select1' value={factorsselect[0]} onChange={(e)=>{
                            const arr = [...factorsselect];
                            arr[0] = e.target.value;
                            setfactorsselect(arr);
                        }}>
                            
                            <option value={'Please Select'}>Please Select</option>
                            <option value={'Very Strong'}>Very Strong</option>
                            <option value={'Strong'}>Strong</option>
                            <option value={'Good'}>Good</option>
                            <option value={'Adequate'}>Adequate</option>
                            <option value={'Weak'}>Weak</option>
                        </select>
                    </div>
                    <div className='col-6'><input className='form-control ms-1' id='comment1' required value={factorscomment[0]} onChange={(e)=>{
                            const arr = [...factorscomment];
                            arr[0] = e.target.value;
                            setfactorscomment(arr);
                        }} /></div>
                </div>
                <div className='row mt-2'>
                    <div className='col-3' style={{fontFamily: "Kalinga"}} id='inner2' >Governance</div>
                    <div className='col-3'>
                        <select className='form-select' id='select2' value={factorsselect[1]} onChange={(e)=>{
                            const arr = [...factorsselect];
                            arr[1] = e.target.value;
                            setfactorsselect(arr);
                        }}>
                            <option value={'Please Select'}>Please Select</option>
                            <option value={'Very Strong'}>Very Strong</option>
                            <option value={'Strong'}>Strong</option>
                            <option value={'Good'}>Good</option>
                            <option value={'Adequate'}>Adequate</option>
                            <option value={'Weak'}>Weak</option>
                        </select>
                    </div>
                    <div className='col-6'><input className='form-control ms-1' id='comment2' required value={factorscomment[1]} onChange={(e)=>{
                            const arr = [...factorscomment];
                            arr[1] = e.target.value;
                            setfactorscomment(arr);
                        }} /></div>
                </div>
                <div className='row mt-2'>
                    <div className='col-3' style={{fontFamily: "Kalinga"}} id='inner3'>{data.ratingScaleId == 28 ? 'Management' : 'Management and Client Services'}</div>
                    <div className='col-3'>
                        <select className='form-select' id='select3' value={factorsselect[2]} onChange={(e)=>{
                            const arr = [...factorsselect];
                            arr[2] = e.target.value;
                            setfactorsselect(arr);
                        }}>
                            <option value={'Please Select'}>Please Select</option>
                            <option value={'Very Strong'}>Very Strong</option>
                            <option value={'Strong'}>Strong</option>
                            <option value={'Good'}>Good</option>
                            <option value={'Adequate'}>Adequate</option>
                            <option value={'Weak'}>Weak</option>
                        </select>
                    </div>
                    <div className='col-6'><input className='form-control ms-1' id='comment3' required value={factorscomment[2]} onChange={(e)=>{
                            const arr = [...factorscomment];
                            arr[2] = e.target.value;
                            setfactorscomment(arr);
                        }} /></div>
                </div>
                <div className='row mt-2'>
                    <div className='col-3' style={{fontFamily: "Kalinga"}} id='inner4'>{data.ratingScaleId == 28 ? 'Social Impact' : 'Internal Controls and Regulatory Compilance'}</div>
                    <div className='col-3'>
                        <select className='form-select' id='select4' value={factorsselect[3]} onChange={(e)=>{
                            const arr = [...factorsselect];
                            arr[3] = e.target.value;
                            setfactorsselect(arr);
                        }}>
                            <option value={'Please Select'}>Please Select</option>
                            <option value={'Very Strong'}>Very Strong</option>
                            <option value={'Strong'}>Strong</option>
                            <option value={'Good'}>Good</option>
                            <option value={'Adequate'}>Adequate</option>
                            <option value={'Weak'}>Weak</option>
                        </select>
                    </div>
                    <div className='col-6'><input className='form-control ms-1' id='comment4' required value={factorscomment[3]} onChange={(e)=>{
                           const arr = [...factorscomment];
                           arr[3] = e.target.value;
                           setfactorscomment(arr);
                        }} />
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-3' style={{fontFamily: "Kalinga"}} id='inner5'>Business Sustainability</div>
                    <div className='col-3'>
                        <select className='form-select' id='select5' value={factorsselect[4]} onChange={(e)=>{
                            const arr = [...factorsselect];
                            arr[4] = e.target.value;
                            setfactorsselect(arr);
                        }}>
                            <option value={'Please Select'}>Please Select</option>
                            <option value={'Very Strong'}>Very Strong</option>
                            <option value={'Strong'}>Strong</option>
                            <option value={'Good'}>Good</option>
                            <option value={'Adequate'}>Adequate</option>
                            <option value={'Weak'}>Weak</option>
                        </select>
                    </div>
                    <div className='col-6'><input className='form-control ms-1' id='comment5' required value={factorscomment[4]} onChange={(e)=>{
                            const arr = [...factorscomment];
                            arr[4] = e.target.value;
                            setfactorscomment(arr);;
                        }} /></div>
                </div>
                <div className='row mt-2'>
                    <div className='col-3' style={{fontFamily: "Kalinga"}} id='inner6'>Financial Sustainability</div>
                    <div className='col-3'>
                        <select className='form-select' id='select6' value={factorsselect[5]} onChange={(e)=>{
                            const arr = [...factorsselect];
                            arr[5] = e.target.value;
                            setfactorsselect(arr);
                        }}>
                            <option value={'Please Select'}>Please Select</option>
                            <option value={'Very Strong'}>Very Strong</option>
                            <option value={'Strong'}>Strong</option>
                            <option value={'Good'}>Good</option>
                            <option value={'Adequate'}>Adequate</option>
                            <option value={'Weak'}>Weak</option>
                        </select>
                    </div>
                    <div className='col-6'><input className='form-control ms-1' id='comment6' required value={factorscomment[5]} onChange={(e)=>{
                            const arr = [...factorscomment];
                            arr[5] = e.target.value;
                            setfactorscomment(arr);
                        }} /></div>
                </div>
            </div>
            <div className='card shadow mt-1 p-2' style={{backgroundColor: "whitesmoke"}}>
                <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Key Rating Drivers:</label>
                <div className='mt-1'>
                    <textarea className='form-control' id='krd' value={krdData} onChange={(e)=>{setkrdData(e.target.value)}} />
                </div>
            </div> 
            <div className='card shadow mt-1 p-2' style={{backgroundColor: "whitesmoke"}}>
                <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>About the Entity:</label>
                <div className='row mt-2'>
                    <div className='col-4'>Legal Stucture</div>
                    <div className='col-8'>
                        {data !== null && <select className='form-control' defaultValue={legalStatusId} id='legalStatusId' onChange={(e)=>{setlegalStatusId(e.target.value)}}>
                        <option value={'Please Select'}>Please Select</option>
                            {data !== null ? data.legalStatuses.map((value, index)=>{
                                return(<option value={value.id}>{value.title}</option>)
                            }) : null}
                        </select>}
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-4'>Year of Establishment</div>
                    <div className='col-8'>
                        {data !== null && <input className='form-control' type='date' id='date' value={incorporationDate} onChange={(e)=>{setincorporationDate(e.target.value)}} />}
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-4'>Type of License</div>
                    <div className='col-8'>
                        {data !== null && <select className='form-control' id='Type of License' defaultValue={licencetype} onChange={(e)=>{setlicencetype(e.target.value)}}>
                            <option value={'Please Select'}>Please Select</option>
                            <option value={'Trading Only'}>Trading Only</option>
                            <option value={'Trading and Self-clearing'}>Trading and Self-clearing</option>
                            <option value={'Trading and Clearing'}>Trading and Clearing</option>
                        </select>}
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-4'>Majority Ownership</div>
                    <div className='col-8'>
                        <input className='form-control' type='text' id='Majority Ownership' value={majorityownership} onChange={(e)=>{setmajorityownership(e.target.value)}} />
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                        severity={alertType}
                        action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setalertopen(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        sx={{ mb: 2 }}
                      >
                        {AlertMessage}
                    </Alert>
                </Collapse>
        </Box>
        <div className='mx-auto mt-2' style={{width: "fit-content"}}>
            <button className='btn btn-primary btn-sm' type='submit'>Save</button>
        </div>
      </form> : 
            (<div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
            <div class="wrapper" id="loader2">
              <svg
                class="hourglass"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 120 206"
                preserveAspectRatio="none"
              >
                <path
                  class="middle"
                  d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
                />
                <path
                  class="outer"
                  d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
                />
              </svg>
            </div>
          </div>)}
      <br />
    </div>
  )
}
