import React, { useEffect, useState } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { toPdf } from 'react-to-pdf';


export default function Coverpage() {
    const [pdfUrl, setPdfUrl] = useState(null);
    useEffect(() => {
        generatePdf();
      }, []);

      const generatePdf = async () => {
        const code = `
          import React from 'react';
          import ReactDOM from 'react-dom';
          
          const HelloWorld = () => {
            return <h1>Hello, World!</h1>;
          };
          
          ReactDOM.render(<HelloWorld />, document.getElementById('root'));
        `;
    
        const options = {
          orientation: 'portrait',
          unit: 'in',
          format: [8.27, 11.69], // A4 size
        };
    
        const pdf = await toPdf(
          <div>
            <h1>React JSX to PDF</h1>
            <SyntaxHighlighter language="jsx" style={docco}>
              {code}
            </SyntaxHighlighter>
          </div>,
          options
        );
    
        const blob = new Blob([pdf], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      };
      
  return (
    <div>
      {pdfUrl ? (
        <embed src={pdfUrl} type="application/pdf" width="100%" height="600px" />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  )
}
