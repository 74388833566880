import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { url } from '../../../Config';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import TableComponent from '../../Atoms/TableComponent';
import { Check, Clear, Event } from "@material-ui/icons";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddTaskIcon from '@mui/icons-material/AddTask';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import ApprovalIcon from '@mui/icons-material/Approval';
import Tooltip from '@mui/material/Tooltip';


export default function PolicyandGuidlines(props) {

    const [data, setdata] = useState([]);
    const [bool, setbool] = useState(false);
    const id = localStorage.getItem("userID");
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const Navigate = useNavigate();

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/policyApproval`);
            const resp = res.data;
            setdata(resp.approval);
        }

        func();
    },[bool])

    const approve = async (id)=>{
        const res = await axios.get(`${url}/api/approvepolicy/${id}`);
        const resp = res.data;
        if(resp.status == true){
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success");
            setbool(!bool);
        }
        else{
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error")
        }
    }

    const deletepolicy = (id)=>{
    Swal.fire({ title: 'Do you want to delete the policy?', 
    showDenyButton: false, showCancelButton: true, confirmButtonText: 'Delete'})
    .then(async(result) => {
      if (result.isConfirmed) {
        const res = await axios.get(`${url}/api/policyDelete/${id}`);
        const resp = res.data;
        if(resp.status == true){
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success");
            setbool(!bool);
        }
        else{
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error")
        }
      }
    })
       
    }

    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };
    
      function innerReviewDate(params) {
        if (params.data.review_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.review_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };

      const responsiveColumnPin2 = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "right";
        }
      };

      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };

      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };
    
      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Policy Name",
          minWidth: responsiveColumns(),
          field: "category_name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
        {
            headerName: "Review Date",
            field: "review_date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            hide: columnHide(),
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
          },

          {
            headerName: "Policy Document",
            hide: columnHide(),
            field: "policy_document",
            filter: "agSetColumnFilter",
            minWidth: 80,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
            cellRenderer: function (params) {
              if (params.value === "nullData") return <Skeleton />;
              else if(params.value == null || params.value == undefined){
                return <Clear className="theme_text" />;
              }
              else return <a href={`${url}/api/getPolicyDoc/${params.data.policy_document_encrypt}`} target="_blank"><Check className="theme_text" /></a>;
            },
          },
          {
            headerName: "Policy Document Word",
            hide: columnHide(),
            field: "policy_document_word",
            filter: "agSetColumnFilter",
            minWidth: 80,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
            cellRenderer: function (params) {
              if (params.value === "nullData") return <Skeleton />;
              else if(params.value == null || params.value == undefined){
                return <Clear className="theme_text" />;
              }
              else return <a href={`${url}/api/getPolicyDoc/${params.data.policy_document_word_encrypt}`} target="_blank"><Check className="theme_text" /></a>;
            },
          },
          {
            headerName: "Minutes",
            hide: columnHide(),
            field: "minutes",
            filter: "agSetColumnFilter",
            minWidth: 80,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
            cellRenderer: function (params) {
              if (params.value === "nullData") return <Skeleton />;
              else if(params.value == null || params.value == undefined){
                return <Clear className="theme_text" />;
              }
              else return <a href={`${url}/api/getPolicyDoc/${params.data.minutes_encrypt}`} target="_blank"><Check className="theme_text" /></a>;
            },
          },
          {
            headerName: "Minutes Word",
            hide: columnHide(),
            field: "minutes_word",
            filter: "agSetColumnFilter",
            minWidth: 80,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
            cellRenderer: function (params) {
              if (params.value === "nullData") return <Skeleton />;
              else if(params.value == null || params.value == undefined){
                return <Clear className="theme_text" />;
              }
              else return <a href={`${url}/api/getPolicyDoc/${params.data.minutes_word_encrypt}`} target="_blank"><Check className="theme_text" /></a>;
            },
          },
          {
            headerName: "BOD Approval",
            hide: columnHide(),
            field: "bod_approval",
            filter: "agSetColumnFilter",
            minWidth: 80,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
            cellRenderer: function (params) {
              if (params.value === "nullData") return <Skeleton />;
              else if(params.value == null || params.value == undefined){
                return <Clear className="theme_text" />;
              }
              else return <a href={`${url}/api/getPolicyDoc/${params.data.bod_approval_encrypt}`} target="_blank"><Check className="theme_text" /></a>;
            },
          },
          {
            headerName: "Status",
            hide: columnHide(),
            field: "status",
            filter: "agSetColumnFilter",
            minWidth: 80,
           
            cellRenderer: function (params) {
                if(params.value == 'Approved'){
                    return(
                        <div style={{color: "green"}}> Approved </div>
                    )
                }
                else{
                    return(
                        <div style={{color: "green"}}> Entered </div>
                    )
                }
            },
          },
          {
            headerName: "Actions",
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            field: "status",
            sortable: true,
            filter: "agSetColumnFilter",
            autoHeight: true,
            excelMode: "windows",
            cellRenderer: (params)=>{
              
                if(params.value == 'Approved'){
                    if(id == '207'){
                        return(
                            <div>
                                <div>
                                <Tooltip title="Edit"><EditIcon sx={{color: '#ffc107'}} onClick={()=>Navigate(`/edit-policy/${params.data.id}`)} /></Tooltip>
                                <Tooltip title="Delete"><DeleteIcon sx={{color: 'red'}} onClick={()=>deletepolicy(params.data.id)} /></Tooltip>
                                </div>
                            </div>
                        )
                    }
                    else{
                        return null;
                    }
                }
                else if(params.data.minutes !== null && params.data.bod_approval !== null && params.data.policy_document !== null){
                    if(id == '207'){
                        return(
                            <div>
                                <div>
                                    <Tooltip title="Approve"><ApprovalIcon sx={{color: 'green'}} onClick={()=>{approve(params.data.id)}} /></Tooltip>
                                    <Tooltip title="Edit"><EditIcon sx={{color: '#ffc107'}} onClick={()=>Navigate(`/edit-policy/${params.data.id}`)} /></Tooltip>
                                    <Tooltip title="Delete"><DeleteIcon sx={{color: 'red'}} onClick={()=>deletepolicy(params.data.id)} /></Tooltip>
                                </div>
                            </div>
                        )
                    }
                    else{
                        return(
                            <div>
                                 <Tooltip title="Edit"><EditIcon sx={{color: '#ffc107'}} onClick={()=>Navigate(`/edit-policy/${params.data.id}`)} /></Tooltip>
                                 <Tooltip title="Delete"><DeleteIcon sx={{color: 'red'}} onClick={()=>deletepolicy(params.data.id)} /></Tooltip>
                            </div>
                        )
                    }
                }
                else if(params.data.minutes !== null || params.data.bod_approval !== null || params.data.policy_document !== null){
                    if(id == '207'){
                        return(
                            <div>
                                 <Tooltip title="Edit"><EditIcon sx={{color: '#ffc107'}} onClick={()=>Navigate(`/edit-policy/${params.data.id}`)} /></Tooltip>
                                  <Tooltip title="Delete"><DeleteIcon sx={{color: 'red'}} onClick={()=>deletepolicy(params.data.id)} /></Tooltip>
                            </div>
                        )
                    }
                    else{
                        return(
                            <div>
                                <Tooltip title="Edit"><button className='btn btn-warning btn-sm me-1 mb-1'><EditIcon /></button></Tooltip>
                                <Tooltip title="Delete"><button className='btn btn-danger btn-sm mb-1' onClick={()=>deletepolicy(params.data.id)}><DeleteIcon /></button></Tooltip>
                            </div>
                        )
                    }

                }
              
            },
            pinned: responsiveColumnPin2(),
        },
        
      ];


      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
          <Table className="overflow-scroll responsiveTableFonts">

            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Review Date
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerReviewDate(params)}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Policy Document
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.policy_document !== null ? <a className='me-1' href={`${url}/api/getPolicyDoc/${params.data.policy_document_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Policy Document Word
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.policy_document_word !== null ? <a className='me-1' href={`${url}/api/getPolicyDoc/${params.data.policy_document_word_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Minutes
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.minutes !== null ? <a className='me-1' href={`${url}/api/getPolicyDoc/${params.data.minutes_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Minutes Word
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.minutes_word !== null ? <a className='me-1' href={`${url}/api/getPolicyDoc/${params.data.minutes_word_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                BOD Approval
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.bod_approval !== null ? <a className='me-1' href={`${url}/api/getPolicyDoc/${params.data.bod_approval_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Status
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.status == 'Approved'? <div style={{color: "green"}}> Approved </div> : <div style={{color: "green"}}> Entered </div>}
              </TableCell>
            </TableRow>
          </Table>
        </h1>
      );


  return (
    <div>
      <div className='mt-2 text-center mb-2 fw-bold fs-3' style={{color:"#000078"}}>
        Policies and Guidelines
      </div>
      <div className='row mb-1 mx-auto' style={{width: "fit-content"}}>
        <div className='me-1 mt-1' style={{width: "fit-content"}}>
            <button className='btn btn-primary' onClick={()=>{Navigate("/create-policy")}}>Add New P&G</button>
        </div>
        <div className='me-1 mt-1' style={{width: "fit-content"}}>
            <button className='btn btn-primary' onClick={()=>{Navigate('/add_applicablitiy')}}>Add Applicability</button>
        </div>
        <div className='mt-1' style={{width: "fit-content"}}>
            <button className='btn btn-primary' onClick={()=>{Navigate('/add_category')}}>Add Category</button>
        </div>
      </div>
      <hr />
      <Box className="mx-auto" sx={{ width: "98%" }}>
        <Collapse in={alertopen}>
            <Alert
            severity={alertType}
            action={
                <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                    setalertOpen(false);
                }}
                >
                <CloseIcon fontSize="inherit" />
                </IconButton>
            }
                sx={{ mb: 2 }}
            >
            {AlertMessage}
            </Alert>
        </Collapse>
        </Box>
      <div>
        <TableComponent
            Data={data}
            screenWidth={props.screenWidth}
            MobViewRender={MobViewRender}
            columnDefs={columnDefs}
            datefilters={datefilters}
            datefilter={false}
            policy= {true}
        />
      </div>
    </div>
  )
}
