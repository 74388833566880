import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";import white from '../../../../Assets/Images/whitebg.jpg';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import './print.css';
import { url } from "../../../../Config";

export default function PreviewAssessment() {

    const { model_id, client_id, og_rating_id } = useParams();
    const id = localStorage.getItem("userID");
    const [data, setdata] = useState([]);
    const [date, setdate] = useState(null);

    useEffect(()=>{
        const func = async ()=>{
          const res = await axios.get(`${url}/api/editassessmentindex/${model_id}/${client_id}/${og_rating_id}`);
          const resp = res.data;
          setdata(resp.headings);
          setdate(resp.submittion_date);
        }
        func();
      },[])
    
    const pdfview = ()=>{

    }

  return (
    <div>
      <div
        className="fs-3 fw-bold text-center mt-2"
        style={{ color: "#000078", fontFamily: "Kalinga" }}
      >
        Preview Assessment
      </div>
      <div
        className="fs-5 fw-bold text-center mt-2"
        style={{ color: "#000078", fontFamily: "Kalinga" }}
      >
        {/* Creation Date: May 30, 2023 */}
        Creation Date: {date}
      </div>
      {/* <div className="fs-5 fw-bold text-center mt-2" onClick={()=>{pdfview()}}>
            <PictureAsPdfIcon />
        </div> */}
      <div className="card shadow col-11 mx-auto p-2" style={{backgroundColor: "#e0e0e0", backgroundImage: `url(${white})`, backgroundSize: "cover", fontFamily: "Kalinga", overflow:"visible"}}>
        {
            data.map((outervalue, outerindex)=>{
                return(
                    <table className="table table-bordered border-dark">
                        <thead>
                            <tr className="fw-4 col-12 text-center">
                                <th className="table-secondary col-12 fs-4">{outervalue.name}</th>
                            </tr>
                        </thead>
                        {outervalue.subheading_data.map((value, index)=>{
                            return(
                                <table className="table table-bordered border-dark">
                                    <tbody>
                                        <tr>
                                            <th className="col-3 ">{value.sub_heading_name}</th>
                                            <td className="col-9">{value.data}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        })
                            }
                    </table>
                );
            })
        }

      </div>
    </div>
  );
}
