import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../../Config';
import TableComponent from '../../../Atoms/TableComponent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Table, TableCell, TableRow, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { Check, Clear, Event } from "@material-ui/icons";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";

export default function MethodologyGrid(props) {

    const [data, setdata] = useState([]);
    const Navigate = useNavigate();
    const [bool, setbool] = useState(false);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [rowstatus, setrowstatus] = useState();


    useEffect(()=>{

        const func = async ()=>{

            setrowstatus(false);
            setdata([]);
            const res = await axios.get(`${url}/api/getmethodology`);
            const resp = res.data;
            setdata(resp.methodologies);
            if(resp.methodologies.length == 0){
              setrowstatus(true);
            }

        }

        func();

    },[bool])



    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };
    
      function innerDate(params) {
        if (params.data.date == null) {
          return "-";
        } else {
          const date = new Date(params.data.date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };

      const responsiveColumnPin2 = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "right";
        }
      };

      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };

      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };

      const deletefunc = async (m_id)=>{
        Swal.fire({ title: 'Do you want to delete this Record?', 
        showDenyButton: false, showCancelButton: true, confirmButtonText: 'Delete'})
        .then(async(result) => {
            if (result.isConfirmed) {
                const res = await axios.get(`${url}/api/delete_methodology/${m_id}`);
                const resp = res.data;
                if(resp.status == true){
                    setbool(!bool)
                    setalertType("success");
                    setalertOpen(true);
                    setalertmessage(resp.message);
                }
                else{
                    setalertType("error");
                    setalertOpen(true);
                    setalertmessage(resp.message);
                }
            }
        })
      }
    
      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Name",
          minWidth: responsiveColumns(),
          field: "name",
          sortable: true,
          cellRenderer: (params)=>{
            if(params.value == null){
                return (params.data.name2)
            }
            else{
                return params.value;
            }
          },
          filter: "agSetColumnFilter",
          excelMode: "windows",
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
        {
            headerName: "Date",
            field: "date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            hide: columnHide(),
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
          },

          {
            headerName: "Scale",
            hide: columnHide(),
            field: "scale_file",
            filter: "agSetColumnFilter",
            minWidth: 80,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
            cellRenderer: function (params) {
              if (params.value === "nullData") return <Skeleton />;
              else if(params.value == null || params.value == ''){
                return <Clear className="theme_text" />;
              }
              else return <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.scale_file_encrypt}`} target="_blank"><Check className="theme_text" /></a>;
            },
          },

          {
            headerName: "Minutes",
            hide: columnHide(),
            field: "mm_file",
            filter: "agSetColumnFilter",
            minWidth: 80,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
            cellRenderer: function (params) {
              if (params.value === "nullData") return <Skeleton />;
              else if(params.value == null || params.value == ''){
                return <Clear className="theme_text" />;
              }
              else return <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.mm_file_encrypt}`} target="_blank"><Check className="theme_text" /></a>;
            },
          },

          {
            headerName: "Document",
            hide: columnHide(),
            field: "doc_file",
            filter: "agSetColumnFilter",
            minWidth: 80,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
            cellRenderer: function (params) {
              if (params.value === "nullData") return <Skeleton />;
              else if(params.value == null || params.value == ''){
                return <Clear className="theme_text" />;
              }
              else return <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.doc_file_encrypt}`} target="_blank"><Check className="theme_text" /></a>;
            },
          },
          
          {
            headerName: "Image",
            hide: columnHide(),
            field: "img_file",
            filter: "agSetColumnFilter",
            minWidth: 80,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
            cellRenderer: function (params) {
              if (params.value === "nullData") return <Skeleton />;
              else if(params.value == null || params.value == ''){
                return <Clear className="theme_text" />;
              }
              else return <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.img_file_encrypt}`} target="_blank"><Check className="theme_text" /></a>;
            },
          },
          {
            headerName: "Actions",
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            field: "",
            sortable: true,
            filter: "agSetColumnFilter",
            autoHeight: true,
            excelMode: "windows",
            cellRenderer: (params)=>{
                if(params.data.status == 'Approved'){
                    return(
                        <Tooltip title="View"><VisibilityIcon className='me-1' sx={{color: 'blue'}} onClick={()=>{Navigate(`/view-methodology/${params.data.id}`)}} /></Tooltip>
                    )
                }
                else{
                    return(
                        <div>
                            <i className='fa-regular fa-pen-to-square fa-lg me-1' data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" style={{color: '#ffc107'}} onClick={()=>{Navigate(`/edit-methodology/${params.data.id}`)}}></i>
                            <Tooltip title="Delete"><DeleteIcon sx={{color: 'red'}} onClick={()=>{deletefunc(params.data.id)}}/></Tooltip>
                        </div>
                    )
                }
            },
            pinned: responsiveColumnPin2(),
         },
        
      ];


      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
          <Table className="overflow-scroll responsiveTableFonts">

            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Date
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerDate(params)}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Scale
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.scale_file !== null ? <a className='me-1' href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.scale_file_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Minutes
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.mm_file !== null ? <a className='me-1' href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.mm_file_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Document
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.doc_file !== null ? <a className='me-1' href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.doc_file_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Image
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.img_file !== null ? <a className='me-1' href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.img_file_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
          </Table>
        </h1>
      );


  return (
    <div>
       <div className='fs-3 fw-bold text-center mt-2 mb-3' style={{color: "#000078"}}>
            Manage Methodology Rating Model & Program
        </div>
        <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
        </Box>
        <div>
        <div className='mx-auto mt-2'><div style={{width: "fit-content"}}><button className='btn btn-primary' onClick={()=>{Navigate('/add-new')}}>Add New Record</button></div></div>
        </div>
        <TableComponent
            Data={data}
            screenWidth={props.screenWidth}
            rowstatus={rowstatus}
            MobViewRender={MobViewRender}
            columnDefs={columnDefs}
            datefilters={datefilters}
            datefilter={false}
            model={true}

        />
    </div>  
  )
}
