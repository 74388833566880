import React, {useState, useEffect} from 'react';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { url } from '../../../Config';
import axios from 'axios';
import Box from '@mui/material/Box';

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Fab } from "@mui/material";
import { Popover } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Inprocess_pacra from './inprocess_pacra';
import Inprocess_jcr from './inprocess_jcr';



export default function Update_industry(props) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [bool, setbool] = useState(false);
    const [rowstatus, setrowstatus] = useState();
    const [rowstatus2, setrowstatus2] = useState();

  
    const id = localStorage.getItem("userID");
    
    const [data, setdata] = useState([]);
    const [data2, setdata2] = useState([]);
    const [bool1, setbool1] = useState(false);
    // eslint-disable-next-line
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    function useWindowSize() {
      const [size, setsize] = useState([window.innerWidth]);
      useEffect(() => {
        const handleResize = () => {
          setsize([window.innerWidth]);
        };
        window.addEventListener("resize", handleResize);
      }, []);
      return size;
    }
    
  
    useEffect(()=>{
      const func = async ()=>{
          setrowstatus(false)
          const res = await axios.get(`${url}/api/inprocess_pacra`);
          const resp = res.data;
          if(resp.inprocess.length == 0){
            setrowstatus(true);
          }
          setdata(resp.inprocess);
          
      }   
      func();
    },[])
    useEffect(()=>{
      const func = async ()=>{
          setdata([]);
          setrowstatus2(false)
          const res = await axios.get(`${url}/api/inprocess_jcr`);
          const resp = res.data;
          if(resp.inprocess.length == 0){
            setrowstatus2(true);
          }
          setdata2(resp.inprocess);
          
      }   
      func();
    },[])
  
    return (
      <div className={classes.root}>
        {props.screenWidth > 770 ? (
          <AppBar
            position="fixed"
            className={` ${classes.topMargin} ${
              props.open
                ? classes.leftMargin
                : props.screenWidth < 700
                ? classes.responsiveLeft
                : classes.left
            }`}
            color="default"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              className="theme_text"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="PACRA" {...a11yProps(1)} />
              <Tab label="VIS" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
        ) : (
          <PopupState
            variant="popover"
            popupId="demo-popup-popover"
            className={`height_TabsBar ${classes.topMargin} ${
              props.open ? classes.leftMargin : classes.responsiveLeft
            }`}
          >
            {(popupState) => (
              <>
                <Fab
                  color="transparent"
                  aria-label="edit"
                  variant="extended"
                  className={`ms-2 fabCustom ${
                    (value === 8 && classes.topAbsoluteSubTabs) ||
                    (value === 2 && classes.topAbsoluteSubTabs) ||
                    (value === 3 && classes.topAbsoluteSubTabs) ||
                    (value === 4 && classes.topAbsoluteSubTabs) ||
                    (value === 5 && classes.topAbsoluteSubTabs) ||
                    (value === 6 && classes.topAbsoluteSubTabs) ||
                    classes.topAbsolute
                  }`}
                >
                  <MoreVertIcon {...bindTrigger(popupState)} />
                </Fab>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    orientation="vertical"
                    indicatorColor="primary"
                    className="theme_text"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab
                      onClick={() => {
                        popupState.close();
                      }}
                      label="PACRA"
                      {...a11yProps(1)}
                    />
                    <Tab
                      onClick={() => {
                        popupState.close();
                      }}
                      label="VIS"
                      {...a11yProps(2)}
                    />
                    
                  </Tabs>
                </Popover>
              </>
            )}
          </PopupState>
        )}
        <TabPanel value={value} index={0}>
          <div style={props.screenWidth > 770 ? {marginTop: "90px"} : null}>
            <Inprocess_pacra screenWidth={props.screenWidth} data={data} rowstatus={rowstatus}/>
            {/* <Cluster screenWidth={props.screenWidth} data={data} setbool={setbool}/> */}
          </div>
        </TabPanel>
  
        <TabPanel value={value} index={1}>
        <div style={props.screenWidth > 770 ? {marginTop: "90px"} : null}>
            <Inprocess_jcr screenWidth={props.screenWidth} data={data2} rowstatus={rowstatus2}/>
            {/* <Sector_industry screenWidth={props.screenWidth} data={data} setbool={setbool}/> */}
          </div>
        </TabPanel>
        {/* </div> */}
      </div>
    );
  }
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
        className={`card ${props.screenWidth > 770 ? "mt-4" : "negative_margin"}`}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    topMargin: {
      top: "50px",
    },
    leftMargin: {
      width: `calc(100% - ${240}px)`,
    },
    left: {
      width: `calc(100% - ${74}px)`,
    },
    responsiveLeft: {
      width: "100%",
    },
    topAbsolute: {
      top: "4px",
    },
    topAbsoluteSubTabs: {
      top: "45px",
    },
  }));
  