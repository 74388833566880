import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { url } from "../../../../Config";


export default function Generateppl(props) {

    const { client_id, og_rating_id } = useParams();
    const [clientId, setclientId] = useState(null);
    const Navigate = useNavigate();
    const id = localStorage.getItem("userID");
    const [data, setdata] = useState(null);
    const [compiled_client_details, setcompiled_client_details] = useState(null);
    const [action, setaction] = useState(null);
    const [value, setvalue] = useState(null);
    const [alertopen, setalertopen] = useState(false);
    const [AlertMessage, setAlertMessage] = useState(null);
    const [alertType, setalertType] = useState("error");
    const [clientname, setclientname] = useState("");
    const btnRef = useRef(null);

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/generatePPLindex/${client_id}/${og_rating_id}`);
            const resp = res.data;
            setdata(resp);
            setclientId(resp.compiled_client_details.client_id)
            setvalue(`${resp.compiled_client_details.ceo[0]} ${resp.compiled_client_details.ceo[1]} ${resp.compiled_client_details.ceo[2]}`)
            setaction(resp.latestRecords[0].ratingAction);
            setclientname(resp.compiled_client_details.clientName);
            // if(resp.latestRecords.length > 0){

            //     if(document.getElementById("textarea1") !== null){
            //             document.getElementById("textarea1").value = `This is with reference to continuing surveillance and update of ratings of ${resp.latestRecords[0].opTitle}. As part of the process, PACRA has completed review of the following opinions.`
            //     }

            //     if(resp.latestRecords[0].ratingType == "BFR"){
            //         if(document.getElementById("textarea2") !== null){
            //             document.getElementById("textarea2").value = `A draft press release and rating report are enclosed. We would appreciate comments on these documents pertaining to i) factual accuracy and ii) any information that might be excluded for the purpose of confidentiality. Please provide the same, if any to facilitate timely notification and dissemination of ratings.`
            //         }
            //     }
            //     else{
            //         if(document.getElementById("textarea2") !== null){
            //             if(resp.latestRecords[0].ratingAction == "Initial"){
            //                 document.getElementById("textarea2").value = `We are enclosing a rating report and press release in this regard. We shall appreciate receiving your comments, if any, pertaining to (i) factual accuracy, and (ii) identification of any portion that might be excluded for purpose of confidentiality. Please provide the same at the earliest. This would enable timely notification and dissemination of opinion.`
            //             }
            //             else{
            //                 document.getElementById("textarea2").value = `A draft press release and rating report are enclosed. We would appreciate comments on these documents pertaining to i) factual accuracy and ii) any information that might be excluded for the purpose of confidentiality. Please provide the same, if any, within five working days, to facilitate timely notification and dissemination of ratings.`
            //             }
            //         }
            //     }
            // }
            setcompiled_client_details(resp.compiled_client_details);
        }

        func();
    },[])

    const pplsubmit = async (event)=>{
        event.preventDefault();
        let longTermRating = null;
        let shortTermRating = null;
        let fiveYrRating = null;
        let outLook = null;
        let ratingAction = null;
        let ratingType = null;
        let address = null;
        let opTitle = null;
        let company = null;

        if(data.latestRecords.length > 0){
            longTermRating = data.latestRecords[0].longTermRating
            shortTermRating = data.latestRecords[0].shortTermRating
            fiveYrRating = data.latestRecords[0].fiveYrRating
            outLook = data.latestRecords[0].outLook
            ratingAction = data.latestRecords[0].ratingAction
            ratingType = data.latestRecords[0].ratingType
            opTitle = data.latestRecords[0].opTitle
            
        }
        if(compiled_client_details !== null){
            address = compiled_client_details.city
            company = compiled_client_details.clientName
        }

        if(btnRef.current){
            btnRef.current.disabled = true;
            btnRef.current.innerHTML =  "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";
        }

        if(data.latestRecords[0].ratingType == 'Performance Ranking'){
            const res = await axios.post(`${url}/api/addPPLindex`, {
                "recordId": og_rating_id,
                "to_name": document.getElementById("client1").value,
                "to_designation": document.getElementById("designation1").value,
                "company" : company,
                "opid": client_id,
                "longTermRating": longTermRating,
                "shortTermRating" : shortTermRating,
                "fiveYrRating" : fiveYrRating,
                "outLook" : data.category,
                "ratingAction" : ratingAction,
                "ratingType" : ratingType,
                "confidential" : "Confidential",
                "pplDate" : document.getElementById("date").value,
                "ppl_text1" : document.getElementById("textarea1").value,
                "ppl_text2" : document.getElementById("textarea2").value,
                "y_truly" : "Yours truly",
                "s_name" : 19,
                "s_designation" : "cro",
                "address" : address,
                "opTitle" : opTitle,
                "uh1": null,
    
            })
            if(res.data.status == true && res.data.message == "New PPL Added"){

                if(btnRef.current){

                    btnRef.current.disabled = false;
                    btnRef.current.innerHTML =  "Save";
                }
                localStorage.setItem("alert","PPL Generated Successfully!");
                Navigate(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`);
            }
            else{

                if(btnRef.current){

                    btnRef.current.disabled = false;
                    btnRef.current.innerHTML =  "Save";
                }
                
                setalertType("error");
                setAlertMessage("Some Error occure while Processing your request");
                setalertopen(true);
            }
        }
        else{

            const res = await axios.post(`${url}/api/addPPLindex`, {
                "recordId": og_rating_id,
                "to_name": document.getElementById("client1").value,
                "to_designation": document.getElementById("designation1").value,
                "company" : company,
                "opid": client_id,
                "longTermRating": longTermRating,
                "shortTermRating" : shortTermRating,
                "fiveYrRating" : fiveYrRating,
                "outLook" : outLook,
                "ratingAction" : ratingAction,
                "ratingType" : ratingType,
                "confidential" : "Confidential",
                "pplDate" : document.getElementById("date").value,
                "ppl_text1" : document.getElementById("textarea1").value,
                "ppl_text2" : document.getElementById("textarea2").value,
                "y_truly" : "Yours truly",
                "s_name" : 19,
                "s_designation" : "cro",
                "address" : address,
                "opTitle" : opTitle,
                "uh1": null,
    
            })
            if(res.data.status == true && res.data.message == "New PPL Added"){
                btnRef.current.disabled = false;
                btnRef.current.innerHTML =  "Save";
                localStorage.setItem("alert","PPL Generated Successfully!");
                Navigate(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`);
            }
            else{
                btnRef.current.disabled = false;
                btnRef.current.innerHTML =  "Save";
                setalertType("error");
                setAlertMessage(res.data.message);
                setalertopen(true);
            }
        }

       

    }

  return (
    <div style={{height: "90vh"}}>
      {data !== null ? 
      <>
      <div className="col-11 mx-auto mt-3">
        <button className="btn btn-primary" onClick={()=>{window.open(`/edit-client-details/${clientId}`, '_blank')}}>Edit Client</button>
      </div>      
      <form className='card shadow col-11 mx-auto p-2 border border-dark p-1 mb-3' style={{backgroundColor: "whitesmoke"}} onSubmit={pplsubmit}>
        <div className='row'>
        {props.screenWidth <= 576 && <div className='col-12 text-wrap text-center fw-bold' style={{fontStyle : "12px"}}>
                {compiled_client_details !== null ? compiled_client_details.clientName : null}
            </div>}

        {props.screenWidth <= 576 && <div className={'col-12 text-wrap text-center mb-1'} style={{fontSize : "10px"}}>
                {data !== null ? data.latestRecords.length > 0 ? 
                <>
                {data.latestRecords[0].ratingType == "BFR" ? `${data.latestRecords[0].ratingType} - ` : data.latestRecords[0].rating_type_id == 3 ? <b> Mutual Fund Ratings - </b> : data.latestRecords[0].ratingType == "Social Impact and Performance Rating" ? <b>Social Impact and Performance Rating - </b>: <b>{data.latestRecords[0].ratingType} Ratings - </b>}
                {data.latestRecords[0].ratingAction == "Initial" ? <b>{data.latestRecords[0].ratingAction}</b> :<b>Update</b>}
                </>
                 : null : null}
            </div>}
            <div className={props.screenWidth >= 576 ? 'col-3' : 'col-12 mb-lg-0 mb-md-0 mb-sm-0 mb-1'}>
                <select className='form-select' id="client1" onChange={(e)=>{
                    const value = e.target.value;
                    setvalue(e.target.value);
                    let desig = document.getElementById('designation1');
                    if(value == `${compiled_client_details.ceo[0]} ${compiled_client_details.ceo[1]} ${compiled_client_details.ceo[2]}`){
                        if(desig !== null){
                            desig.value = `${compiled_client_details.ceo[3]}`;
                        }
                    }
                    if(value == `${compiled_client_details.liasonOne[0]} ${compiled_client_details.liasonOne[1]} ${compiled_client_details.liasonOne[2]}`){
                        if(desig !== null){
                            desig.value = `${compiled_client_details.liasonOne[3]}`;                            
                        }
                    }
                    if(value == `${compiled_client_details.liasonTwo[0]} ${compiled_client_details.liasonTwo[1]} ${compiled_client_details.liasonTwo[2]}`){
                        if(desig !== null){
                            desig.value = `${compiled_client_details.liasonTwo[3]}`;
                        }
                    }
                }}>
                    {compiled_client_details !== null && <option value={`${compiled_client_details.ceo[0]} ${compiled_client_details.ceo[1]} ${compiled_client_details.ceo[2]}`}> {`${compiled_client_details.ceo[0]} ${compiled_client_details.ceo[1]} ${compiled_client_details.ceo[2]}`} </option>}
                    {compiled_client_details !== null && <option value={`${compiled_client_details.liasonOne[0]} ${compiled_client_details.liasonOne[1]} ${compiled_client_details.liasonOne[2]}`}> {`${compiled_client_details.liasonOne[0]} ${compiled_client_details.liasonOne[1]} ${compiled_client_details.liasonOne[2]}`} </option>}
                    {compiled_client_details !== null && <option value={`${compiled_client_details.liasonTwo[0]} ${compiled_client_details.liasonTwo[1]} ${compiled_client_details.liasonTwo[2]}`}> {`${compiled_client_details.liasonTwo[0]} ${compiled_client_details.liasonTwo[1]} ${compiled_client_details.liasonTwo[2]}`} </option>}
                </select>
            </div>
            {props.screenWidth > 576 && <div className='col-6 text-wrap text-center fs-6 fw-bold'>
                {compiled_client_details !== null ? compiled_client_details.clientName : null}
            </div>}
            {props.screenWidth > 576 && <div className={'col-3'}>
                <input className='form-control' value={'Confidential'} disabled />
            </div>}
        </div>
        <div className='row mt-1'>
            <div className={props.screenWidth > 576 ? 'col-3' : 'col-12 mb-1'}>
                <select className='form-select' id="designation1">
                    {compiled_client_details !== null && <option value={`${compiled_client_details.ceo[3]}`} disabled = {value == `${compiled_client_details.ceo[0]} ${compiled_client_details.ceo[1]} ${compiled_client_details.ceo[2]}` ? false : true }> {`${compiled_client_details.ceo[3]}`} </option> }
                    {compiled_client_details !== null && <option value={`${compiled_client_details.liasonOne[3]}`} disabled = {value == `${compiled_client_details.liasonOne[0]} ${compiled_client_details.liasonOne[1]} ${compiled_client_details.liasonOne[2]}` ? false : true}> {`${compiled_client_details.liasonOne[3]}`} </option> }
                    {compiled_client_details !== null && <option value={`${compiled_client_details.liasonTwo[3]}`} disabled = {value == `${compiled_client_details.liasonTwo[0]} ${compiled_client_details.liasonTwo[1]} ${compiled_client_details.liasonTwo[2]}` ? false : true }> {`${compiled_client_details.liasonTwo[3]}`} </option> }
                </select>
            </div>
            {props.screenWidth > 576 && <div className={'col-6 text-wrap text-center fs-6'} style={props.screenWidth > 576 ? {fontSize : "10px"} : null}>
                {data !== null ? data.latestRecords.length > 0 ? 
                <>
                {data.latestRecords[0].ratingType == "BFR" ? `${data.latestRecords[0].ratingType} - ` : data.latestRecords[0].rating_type_id == 3 ? <b> Mutual Fund Ratings - </b> : data.latestRecords[0].ratingType == "Social Impact and Performance Rating" ? <b>Social Impact and Performance Rating - </b>: <b>{data.latestRecords[0].ratingType} Ratings - </b>}
                {data.latestRecords[0].ratingAction == "Initial" ? <b>{data.latestRecords[0].ratingAction}</b> :<b>Update</b>}
                </>
                 : null : null}
            </div>}
            <div className={props.screenWidth > 576 ? 'col-3' : 'col-12 mb-1'}>
                <input className='form-control' type='date' min={data.initiation_date} id="date" required/>
            </div>
            {props.screenWidth <= 576 && <div className={'col-12'}>
                <input className='form-control' value={'Confidential'} disabled />
            </div>}
        </div>
        
        <div className='col-lg-3 col-md-3 col-sm-6 col-12 mt-1'>
            <input className='form-control' value={data !== null ? clientname : null} disabled />
        </div>
        <div className='col-lg-3 col-md-3 col-sm-6 col-12 mt-1'>
            <input className='form-control' value={data !== null ? compiled_client_details.city : null} disabled />
        </div>
        <div className='mt-3'>
            <textarea className='form-control' id="textarea1" defaultValue={`This is with reference to the mandate given to us for the ratings of ${data.latestRecords[0].opTitle}. We are pleased to inform that PACRA has formulated its rating opinion. Following are the respective details:`} />
        </div>
        <div className='mt-3' style={{overflow: "auto"}}>
            <table className='table table-secondary newtable'>
                <tbody>
                    {data !== null ? data.latestRecords.length > 0 ?
                    data.latestRecords[0].ratingType == "BFR" ?
                    <tr>
                            <td className='col-2 fw-bold'>Opinion</td>
                            <td className='col-2 fw-bold'>Rating</td>
                            <td className='col-2 fw-bold'>Outlook</td>
                            <td className='col-2 fw-bold'>Action</td>
                            <td className='col-2 fw-bold'>Opinion Type</td>
                    </tr> :
                    data.latestRecords[0].ratingType == "Project Grading" ?
                        <tr>
                            <td className='col-2 fw-bold'>Opinion</td>
                            <td className='col-2 fw-bold'>Grading</td>
                            <td className='col-2 fw-bold'>Outlook</td>
                            <td className='col-2 fw-bold'>Action</td>
                            <td className='col-2 fw-bold'>Opinion Type</td>
                        </tr>:
                    data.latestRecords[0].ratingType == "Entity" || data.latestRecords[0].ratingType == "Debt Instrument" ?
                    <>
                    <tr>
                        <td className='col-2 fw-bold'>Opinion</td>
                        <td className='col-4 fw-bold text-center' colSpan="2">Ratings</td>
                        <td className='col-2 fw-bold'>Outlook</td>
                        <td className='col-2 fw-bold'>Action</td>
                        <td className='col-2 fw-bold'>Opinion Type</td>
                    </tr>
                    <tr>
                        <td className='col-2'></td>
                        <td className='col-2 fw-bold text-center'>Long Term</td>
                        <td className='col-2 fw-bold text-center'>Short Term</td>
                        <td className='col-2'></td>
                        <td className='col-2'></td>
                        <td className='col-2'></td>
                    </tr>
                    </> :
                       data.latestRecords[0].ratingType == "Performance Ranking" ?
                       <tr>
                           <td className='col-2 fw-bold'>Opinion</td>
                           <td className="col-1 fw-bold">1-Year Action</td>
                           <td className="col-1 fw-bold">3-Year Action</td>
                           <td className="col-1 fw-bold">5-Year Action</td>
                           <td className="col-1 fw-bold">1 year</td>
                           <td className="col-1 fw-bold">3 year</td>
                           <td className="col-1 fw-bold">5 year</td>
                           <td className='col-2 fw-bold'>Opinion Type</td>
                       </tr> :
                       data.latestRecords[0].ratingType == "IFS" ?
                       <tr>
                           <td className='col-2 fw-bold'>Opinion</td>
                           <td className='col-2 fw-bold'>IFS Rating</td>
                           <td className='col-2 fw-bold'>Outlook</td>
                           <td className='col-2 fw-bold'>Action</td>
                           <td className='col-2 fw-bold'>Opinion Type</td>
                       </tr> :
                       <tr>
                       <td className='col-2 fw-bold'>Opinion</td>
                       <td className='col-2 fw-bold'>Rating</td>
                       <td className='col-2 fw-bold'>Outlook</td>
                       <td className='col-2 fw-bold'>Action</td>
                       <td className='col-2 fw-bold'>Opinion Type</td>
                       </tr>
                         : null : null
                    }
                    {data !== null ? data.latestRecords.length > 0 ? 
                    data.latestRecords[0].ratingType == "BFR" ?
                        <tr>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].longTermRating : null : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].outLook : null : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingAction : null : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingType : null : null}</td>
                        </tr> :
                    data.latestRecords[0].ratingType == "Project Grading" ?
                        <tr>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].longTermRating : null : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].outLook : null : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingAction : null : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingType : null : null}</td>
                        </tr> :
                    data.latestRecords[0].ratingType == "Entity" || data.latestRecords[0].ratingType == "Debt Instrument" ?
                        <tr>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null}</td>
                            <td className='col-2 text-center'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].longTermRating : null : null}</td>
                            <td className='col-2 text-center'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].shortTermRating : null : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].outLook : null : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingAction : null : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingType : null : null}</td>
                        </tr> :
                    data.latestRecords[0].ratingType == "Performance Ranking" ?
                        <tr>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null}</td>
                            <td className="col-1">{data.latestRecords[0].StAction}</td>
                            <td className="col-1">{data.latestRecords[0].LtAction}</td>
                            <td className="col-1">{data.latestRecords[0]['5yearAction']}</td>
                            <td className="col-1">{data.latestRecords[0].shortTermRating}</td>
                            <td className="col-1">{data.latestRecords[0].longTermRating}</td>
                            <td className="col-1">{data.latestRecords[0].fiveYrRating}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingType : null : null}</td>
                        </tr> :
                        <tr>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null}</td>
                            <td className='col-2 text-center'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].longTermRating : null : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].outLook : null : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingAction : null : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingType : null : null}</td>
                        </tr>
                    :null : null}
                </tbody>
            </table>
        </div>
        <div className='mt-3'>
            <textarea className='form-control' id="textarea2" defaultValue={`Please find the attached draft rating report and draft press release in this regard. We shall appreciate receiving your comments, if any, pertaining to (i) factual accuracy, and (ii) identification of any portion that might be excluded for purpose of confidentiality. Public disclosure of these ratings without being disseminated by PACRA is not permitted.`} />
        </div>
        <div className='row mt-3'>
            <div className='col-lg-3 col-md-3 col-sm-3 col-12 mb-1'>
                <input className='form-control' value={'Yours Truly'} disabled/>
            </div>
            <div className='col-6 col-md-6 col-sm-6 col-12 mb-1'>
                <div className='col-8 col-md-8 col-sm-8 col-12 mx-auto'>
                <select className='form-select' id="s_name" value={"19"}>
                <option value="19">Jhangeer Hanif</option>
                </select>
                </div>
            </div>
            <div className='col-3 col-md-3 col-sm-3 col-12 mb-1'>
                <select className='form-select' id="s_designation" value={"cro"}>
                <option value="cro">Chief Rating Officer</option>
                </select>
            </div>
        </div>
        <div>
            Encl:
        </div>
        <div className='row mt-3'>
            <div className='col-lg-3 col-md-3 col-3 col-12'>
                <FormGroup>
                    <FormControlLabel control={<Checkbox value={"1"} id="draftpr" defaultChecked />}  label="Draft Press Release" />
                </FormGroup>
            </div>
           {action !== 'Withdraw' &&
            <div className='col-lg-3 col-md-3 col-3 col-12'>
                <FormGroup>
                    <FormControlLabel control={<Checkbox value={"1"} id="rr" defaultChecked />}  label="Draft Rating Report" />
                </FormGroup>
            </div>}
        </div>
        <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                        severity={alertType}
                        action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setalertopen(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        sx={{ mb: 2 }}
                      >
                        {AlertMessage}
                    </Alert>
                </Collapse>
          </Box>
        <div className='mx-auto mt-3' style={{width: "fit-content"}}>
            <button className={props.screenWidth > 576 ? 'btn btn-primary' : 'btn btn-primary btn-sm'} ref={btnRef} type='submit'> Save </button>
        </div>
      </form>
      </>
       : 
      (
        <div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
        <div class="wrapper" id="loader2">
          <svg
            class="hourglass"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 120 206"
            preserveAspectRatio="none"
          >
            <path
              class="middle"
              d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
            />
            <path
              class="outer"
              d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
            />
          </svg>
        </div>
      </div>
      )}
      <br/> <br />
    </div>
  )
}
