import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios';
import { url } from '../../../Config';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import TableComponent from '../../Atoms/TableComponent';
import { Check, Clear, Event } from "@material-ui/icons";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddTaskIcon from '@mui/icons-material/AddTask';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import ApprovalIcon from '@mui/icons-material/Approval';
import Tooltip from '@mui/material/Tooltip';


export default function Sector_study_research(props) {

    const [data, setdata] = useState([]);
    const [bool, setbool] = useState(false);
    const id = localStorage.getItem("userID");
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [rowstatus, setrowstatus] = useState();

    const Navigate = useNavigate();


    useEffect(()=>{
        const func = async ()=>{
            setrowstatus(false)
            const res = await axios.get(`${url}/api/getsector_study`);
            const resp = res.data;
            setdata(resp.sector_study);
            if(resp.sector_study.length == 0){
              setrowstatus(true);
            }
        }

        func();
    },[bool])


    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };
    
      function innerDate(params) {
        if (params.data.date == null) {
          return "-";
        } else {
          const date = new Date(params.data.date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };

      const responsiveColumnPin2 = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "right";
        }
      };

      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };

      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };

      const deletesector = async (s_id)=>{
        Swal.fire({ title: 'Do you want to de-activate this sector study?', 
        showDenyButton: false, showCancelButton: true, confirmButtonText: 'Delete'})
        .then(async(result) => {
            if (result.isConfirmed) {
              const res = await axios.get(`${url}/api/deactivate_sector/${s_id}`);
              const resp = res.data;
              if(resp.status == true){
                setbool(!bool);
                // Navigate('/sector_study');
                setalertType("success");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
            else{
                setalertType("error");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
            }
        })
        
      }
    
      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Name",
          minWidth: responsiveColumns(),
          field: "name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
        {
            headerName: "Date",
            field: "date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            hide: columnHide(),
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
          },

          {
            headerName: "File",
            hide: columnHide(),
            field: "file",
            filter: "agSetColumnFilter",
            minWidth: 80,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
            cellRenderer: function (params) {
              if (params.value === "nullData") return <Skeleton />;
              else if(params.value == null || params.value == undefined || params.value == ''){
                return <Clear className="theme_text" />;
              }
              else{
                return(
                  <a href={`https://209.97.168.200/pacrawizpackv3/public/view/storage/app/${params.value}`} target="_blank"><Check className="theme_text" /></a>
                ); 
              }
            },
          },
          {
            headerName: "Image",
            hide: columnHide(),
            field: "image",
            filter: "agSetColumnFilter",
            minWidth: 80,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
            cellRenderer: function (params) {
              if (params.value === "nullData") return <Skeleton />;
              else if(params.value == null || params.value == undefined || params.value == ''){
                return <Clear className="theme_text" />;
              }
              else{
                return(
                  <a href={`https://209.97.168.200/pacrawizpackv3/public/view/public/sectorStudy/${params.value}`} target="_blank"><Check className="theme_text" /></a>
                )
              }
            },
          },
          {
            headerName: "Actions",
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            field: "status",
            sortable: true,
            filter: "agSetColumnFilter",
            autoHeight: true,
            excelMode: "windows",
            cellRenderer: (params)=>{
              
                return(
                    <>
                        <Tooltip title="Edit"><EditIcon sx={{color: '#ffc107'}} onClick={()=>Navigate(`/edit-sector-study/${params.data.id}`)} /></Tooltip>
                        <Tooltip title="Delete"><DeleteIcon sx={{color: 'red'}} onClick={()=>deletesector(params.data.id)} /></Tooltip>
                    </>
                )
              
            },
            pinned: responsiveColumnPin2(),
        },
        
      ];


      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
          <Table className="overflow-scroll responsiveTableFonts">

            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Date
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerDate(params)}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                File
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {(params.data.file !== null && params.data.file !== '') ? <a className='me-1' href={`${url}/api/getPolicyDoc/${params.data.file}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Image
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {(params.data.image !== null && params.data.image !== '')? <a className='me-1' href={`${url}/api/getPolicyDoc/${params.data.image}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
          </Table>
        </h1>
      );

  return (
    <div>
        <div className='fs-3 fw-bold text-center mt-2 mb-3' style={{color: "#000078"}}>
            Sector Study
        </div>
        <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
        </Box>
        <div>
        <div className='mx-auto mt-2'><div style={{width: "fit-content"}}><button className='btn btn-primary' onClick={()=>{Navigate('/add-sector-study')}}>Add New Sector Study</button></div></div>
        </div>
        <TableComponent
            Data={data}
            rowstatus={rowstatus}
            screenWidth={props.screenWidth}
            MobViewRender={MobViewRender}
            columnDefs={columnDefs}
            datefilters={datefilters}
            datefilter={false}
            model={true}
        />
    </div>
  )
}
