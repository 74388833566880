import React, {useRef, useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import banner from '../../../Assets/Images/newBanner.png';
import facebook from '../../../Assets/Images/facebook.png';
import linkdin from '../../../Assets/Images/linkdin.png';
import white from '../../../Assets/Images/whitebg.jpg';
import { url } from '../../../Config';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import TableComponent from '../../Atoms/TableComponent';


export default function Buisness_email(props) {

    const [data, setdata] = useState([]);


    const getdate = ()=>{

        const currentDate = new Date();
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
        return formattedDate;
    }

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/get_BD_Mail`);
            const resp = res.data;
            setdata(resp.data);
        }

        func();
    },[])


    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };
    
      function innerDate(params) {
        if (params.data.review_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.review_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };

      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };
    
      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
        //   pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
        //   pinned: responsiveColumnPin(),
        },
        {
          headerName: "Entity",
          minWidth: 240,
          field: "Entity",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "Entity",
        //   pinned: responsiveColumnPin(),
        },
        {
            headerName: "Rating Type",
            minWidth: responsiveColumns(),
            field: "Rating_Scale",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            hide: columnHide(),
            cellRenderer: cellrander,
          //   pinned: responsiveColumnPin(),
          },

        {
          headerName: "Rating LT",
          minWidth: responsiveColumns(),
          field: "RatingLT",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: cellrander,
        //   pinned: responsiveColumnPin(),
        },
        {
          headerName: "Rating ST",
          minWidth: responsiveColumns(),
          field: "RatingST",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          hide: columnHide()
        //   pinned: responsiveColumnPin(),
        },
        {
          headerName: "Action",
          minWidth: responsiveColumns(),
          field: "RatingAction",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          hide: columnHide()
        //   pinned: responsiveColumnPin(),
        },
        {
          headerName: "Outlook",
          minWidth: responsiveColumns(),
          field: "Outlook",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          hide: columnHide()
        //   pinned: responsiveColumnPin(),
        }

      ];


      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
          <Table className="overflow-scroll responsiveTableFonts">

            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Rating Type
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.Rating_Scale}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Rating LT
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.RatingLT}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Rating ST
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.RatingST}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Action
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.RatingAction}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Outlook
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.Outlook}
              </TableCell>
            </TableRow>

          </Table>
        </h1>
      );

  return (
    <div>
      <div className='mt-2 p-2 card shadow' style={{backgroundColor: "#e0e0e0", backgroundImage: `url(${white})`, backgroundSize: "cover"}}>
        <div className='row'>
            <div className='col-7 fs-4 mt-2 fw-bold text-end'>{getdate()}</div>
            <div className='col-5 d-flex justify-content-end'>
                <a href='https://www.linkedin.com/company/pakistan-credit-rating-agency-pacra-/'><img src={linkdin} style={{width: 50, height: 50}} /></a>
                <a href='https://www.facebook.com/pacra.pk/'><img src={facebook} style={{width: 50, height: 50}} /></a>
            </div>
        </div>
        <img src={banner} width="100%" height="250" />
      </div>
      <div>
      <TableComponent
            Data={data}
            screenWidth={props.screenWidth}
            MobViewRender={MobViewRender}
            columnDefs={columnDefs}
            datefilters={datefilters}
            datefilter={false}
            policy= {true}
        />
      </div>
      {/* <div className='p-3' style={{backgroundColor: '#000078'}}>
            <div className='text-center' style={{color: 'white'}}>
                For more details visit <a href="http://www.pacra.com/" style={{color: 'white'}}>www.PACRA.com</a>
            </div>
            <div className='text-center fw-bold' style={{color: 'white'}}>
                Its All About Ratings
            </div>
            <div className='text-center fw-bold' style={{color: 'white'}}>
                FB1 Awami Complex Usman Block, New Garden Town Lahore
                    +92 42 3586 9504 - 6
            </div>
        </div> */}
    </div>
  )
}
