import React, {useRef, useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../../Config';

export default function Assessment_dashboard(props) {

    const Navigate = useNavigate();

    const id = localStorage.getItem("userID");
    const card1 = useRef(null);
    const card2 = useRef(null);
    const card3 = useRef(null);
    const card4 = useRef(null);
    const rightsval = localStorage.getItem("rights");
  
    let rights = [];
  
    if(rightsval !== null){
      rights = rightsval.split(",");
    }
  
    const can_approve_mapping = rights.includes("49");


  return (
    <div style={props.screenWidth > 760 ? {height : '90vh'} : null}>
    <div className='mt-2 text-center mb-2 fw-bold fs-3' style={{color:"#000078"}}>
        Assessment Dashboard
    </div>
    <div className='row p-5 mt-5'>
        <div className='card shadow  col-lg-5 col-md-5 col-12 p-3 mx-auto'>

            <div className='card shadow text-center mt-3 border p-3' ref={card2} onClick={()=>{Navigate('/model-dashboard')}} onMouseEnter={()=>{card2.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card2.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                Create Assessment Format + Detail
            </div>
            <div className='card shadow text-center mt-3 border p-3' ref={card3} onClick={()=>{Navigate('/approve-format')}} onMouseEnter={()=>{card3.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card3.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                Approve Format
            </div>
            <div className='card shadow text-center mt-3 border p-3' ref={card4} onClick={()=>{Navigate('/op-mapping-v2')}} onMouseEnter={()=>{card4.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card4.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                 Opinion Mapping
            </div>
            {can_approve_mapping == true && <div className='card shadow text-center mt-3 border p-3' ref={card1} onClick={()=>{Navigate('/approve-mapping')}} onMouseEnter={()=>{card1.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card1.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                Approve Mapping
            </div>}
        </div>
    </div>
</div>
  )
}
