import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { url } from "../../../Config";

export default function RipLetter(props) {

    const { client_id, og_rating_id } = useParams();
    const Navigate = useNavigate();
    const id = localStorage.getItem("userID");
    const [data, setdata] = useState(null);
    const [compiled_client_details, setcompiled_client_details] = useState(null);
    const [value, setvalue] = useState(null);
    const [alertopen, setalertopen] = useState(false);
    const [AlertMessage, setAlertMessage] = useState(null);
    const [alertType, setalertType] = useState("error");
    const [rating_action, setrating_action] = useState(null);
    const [dissDeadline, setdissDeadline] = useState(null);
    const [bool, setbool] = useState(false);
    const [clientId, setclientId] = useState(null);



    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/getRIPLetter/${client_id}/${og_rating_id}`);
            const resp = res.data;
            if(resp.record.clientDetails.length > 0){
                setclientId(resp.record.clientDetails[0].id);
                resp.record.clientDetails[0].liaison_one = resp.record.clientDetails[0].liaison_one.split(',')
                resp.record.clientDetails[0].liaison_two = resp.record.clientDetails[0].liaison_two.split(',')
                resp.record.clientDetails[0].ceo = resp.record.clientDetails[0].ceo.split(',')
            }
            setvalue(`${resp.record.clientDetails[0].ceo[0]} ${resp.record.clientDetails[0].ceo[1]} ${resp.record.clientDetails[0].ceo[2]}`)
            setdata(resp);
        }

        func();
    },[bool])


    const nlsubmit = async(event)=>{
        event.preventDefault();
        let rw = "-"
        let fundCateg = null;
        let reviewEndDate = null;
        let ratingtype = null;
        if(data.latestRecords.length > 0){
         ratingtype = data.latestRecords[0].ratingType;
         fundCateg =  data.latestRecords[0].fundCateg;
         reviewEndDate =  data.latestRecords[0].reviewEndDate;
        }

        if(document.getElementById("reviewEndDate") !== null && ratingtype == "Performance Ranking"){
            reviewEndDate = document.getElementById("reviewEndDate").value
        }
        
        let action = null;
        if(data.latestRecords[0].ratingAction == "Initial" || data.latestRecords[0].ratingAction == "Preliminary"){
            action = "Initial";
        }
        else if(data.latestRecords[0].ratingAction == "Redeem"){
            action = "Redeem";
        }
        else if(data.latestRecords[0].ratingAction == "Withdraw"){
            action = "Withdraw";
        }
        else{
            action = "Update"
        }

        const res = await axios.post(`${url}/api/saveRIPNew`,
                {
                    "p_st" : data.record.PreviousShortTerm,
                    "p_lt" : data.record.PreviousLongTerm,
                    "to_name" : document.getElementById("client1").value,
                    "to_designation" : document.getElementById("designation1").value,
                    "company" : data.latestRecords[0].opTitle,
                    "address" : data.record.cities,
                    "opid" : data.latestRecords[0].opId,
                    "record_id" : data.record_id,
                    "longTermRating" : data.latestRecords[0].longTermRating,
                    "shortTermRating" : data.latestRecords[0].shortTermRating,
                    "ratingType" : data.latestRecords[0].ratingType,
                    "ratingAction" : data.latestRecords[0].ratingAction,
                    "fiveYrRating" : data.latestRecords[0].fiveYrRating,
                    "outLook" : data.latestRecords[0].outLook,
                    "opTitle" : data.latestRecords[0].opTitle,
                    "opinion_type": action,
                    "categ" : fundCateg,
                    "reviewEndDate" : reviewEndDate,
                    "confidential" : "Confidential",
                    "pplDate" : document.getElementById("pplDate").value,
                    "ppl_text1" : document.getElementById("textarea1").value,
                    "rw" : rw,
                    "y_truly" : "Yours truly",
                    "s_name" : document.getElementById("s_name").value,
                    "uh1" : "Jhangeer Hanif",
                    "s_designation" : 'coo',
                    "dpr" : "1",
                    "rr" : "1"
            
                }
        )

        if(res.data.status == true){
            setalertType("success");
            setAlertMessage(res.data.message);
            setalertopen(true);
            setbool(!bool);
        }
        else{
            setalertType("error");
            setAlertMessage("An error occure while process your request");
            setalertopen(true);
        }
    }

  return (
    <div style={{height: "90vh"}}>
      {data !== null ? 
      <>
       <div className="col-11 mx-auto mt-3">
            <button className="btn btn-primary" onClick={()=>{window.open(`/edit-client-details/${clientId}`, '_blank')}}>Edit Client</button>
        </div> 
        <form className='card shadow col-11 mx-auto p-2 border border-dark p-1 mb-3' style={{backgroundColor: "whitesmoke"}} onSubmit={nlsubmit}>
        <div className='row'>
            {props.screenWidth <= 576 && 
            <div className='col-12 text-wrap text-center fw-bold' style={{fontStyle : "12px"}}>
                {data.latestRecords.length > 0  ? data.latestRecords[0].opTitle : null}
            </div> }
            {props.screenWidth <= 576 && 
            <div className={'col-12 text-wrap text-center mb-1'} style={{fontSize : "10px"}}>
                {data !== null ? data.latestRecords.length > 0 ? 
                <>
                {data.latestRecords.length > 0  ? `${data.latestRecords[0].ratingType} Ratings - Withdraw` : null}
                </>
                 : null : null}
            </div> }
            <div className={props.screenWidth >= 576 ? 'col-3' : 'col-12 mb-lg-0 mb-md-0 mb-sm-0 mb-1'}>
                <select className='form-select' id="client1" onChange={(e)=>{
                    const value = e.target.value;
                    setvalue(e.target.value);
                    let desig = document.getElementById('designation1');
                    if(value == `${data.record.clientDetails[0].ceo[0]} ${data.record.clientDetails[0].ceo[1]} ${data.record.clientDetails[0].ceo[2]}`){
                        if(desig !== null){
                            desig.value = `${data.record.clientDetails[0].ceo[3]}`;
                        }
                    }
                    if(value == `${data.record.clientDetails[0].liaison_one[0]} ${data.record.clientDetails[0].liaison_one[1]} ${data.record.clientDetails[0].liaison_one[2]}`){
                        if(desig !== null){
                            desig.value = `${data.record.clientDetails[0].liaison_one[3]}`;                            
                        }
                    }
                    if(value == `${data.record.clientDetails[0].liaison_two[0]} ${data.record.clientDetails[0].liaison_two[1]} ${data.record.clientDetails[0].liaison_two[2]}`){
                        if(desig !== null){
                            desig.value = `${data.record.clientDetails[0].liaison_two[3]}`;
                        }
                    }
                }}>
                    <option value={`${data.record.clientDetails[0].ceo[0]} ${data.record.clientDetails[0].ceo[1]} ${data.record.clientDetails[0].ceo[2]}`}> {`${data.record.clientDetails[0].ceo[0]} ${data.record.clientDetails[0].ceo[1]} ${data.record.clientDetails[0].ceo[2]}`} </option>
                    <option value={`${data.record.clientDetails[0].liaison_one[0]} ${data.record.clientDetails[0].liaison_one[1]} ${data.record.clientDetails[0].liaison_one[2]}`}> {`${data.record.clientDetails[0].liaison_one[0]} ${data.record.clientDetails[0].liaison_one[1]} ${data.record.clientDetails[0].liaison_one[2]}`} </option>
                    <option value={`${data.record.clientDetails[0].liaison_two[0]} ${data.record.clientDetails[0].liaison_two[1]} ${data.record.clientDetails[0].liaison_two[2]}`}> {`${data.record.clientDetails[0].liaison_two[0]} ${data.record.clientDetails[0].liaison_two[1]} ${data.record.clientDetails[0].liaison_two[2]}`} </option>
                </select>
            </div>
            {props.screenWidth > 576 && <div className='col-6 text-wrap text-center fs-6 fw-bold'>
                {data.latestRecords.length > 0  ? data.latestRecords[0].opTitle : null}
            </div> }
            {props.screenWidth > 576 && <div className='col-3'>
                <input className='form-control' value={'Confidential'} disabled />
            </div>}
        </div>
        <div className='row mt-1'>
            <div className={props.screenWidth > 576 ? 'col-3' : 'col-12 mb-1'}>
                <select className='form-select' id="designation1">
                    <option value={`${data.record.clientDetails[0].ceo[3]}`} disabled = {value == `${data.record.clientDetails[0].ceo[0]} ${data.record.clientDetails[0].ceo[1]} ${data.record.clientDetails[0].ceo[2]}` ? false : true }> {`${data.record.clientDetails[0].ceo[3]}`} </option>
                    <option value={`${data.record.clientDetails[0].liaison_one[3]}`} disabled = {value == `${data.record.clientDetails[0].liaison_one[0]} ${data.record.clientDetails[0].liaison_one[1]} ${data.record.clientDetails[0].liaison_one[2]}` ? false : true}> {`${data.record.clientDetails[0].liaison_one[3]}`} </option>
                    <option value={`${data.record.clientDetails[0].liaison_two[3]}`} disabled = {value == `${data.record.clientDetails[0].liaison_two[0]} ${data.record.clientDetails[0].liaison_two[1]} ${data.record.clientDetails[0].liaison_two[2]}` ? false : true }> {`${data.record.clientDetails[0].liaison_two[3]}`} </option>
                </select>
            </div>
            {props.screenWidth > 576 && <div className='col-6 text-wrap text-center fs-6'>
                {data !== null ? data.latestRecords.length > 0 ? 
                <>
                    {data.latestRecords.length > 0  ? `${data.latestRecords[0].ratingType} Ratings - Withdraw` : null}
                </>
                 : null : null}
            </div> }
            <div className={props.screenWidth > 576 ? 'col-3' : 'col-12 mb-1'}>
                <input className='form-control' type='date' min={data.ratingInitiationDate} id="pplDate" required/>
            </div>
            {props.screenWidth <= 576 && <div className={'col-12'}>
                <input className='form-control' value={'Confidential'} disabled />
            </div>}
        </div>
        <div className='col-lg-3 col-md-3 col-sm-6 col-12 mt-1'>
            <input className='form-control' value={data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null} disabled />
        </div>
        <div className='col-lg-3 col-md-3 col-sm-6 col-12 mt-1'>
            <input className='form-control' value={data !== null ? data.record.cities : null} disabled />
        </div>
        <div className='mt-3'>
           <textarea className='form-control' id="textarea1" defaultValue={`This is with reference to initial ratings of ${data.latestRecords[0].opTitle}. We understand that you are not interested in continuing the rating process for now. Therefore, we will stop the process and will initiate it again on mutually agreed time.`} style={{height: "90px"}} />
        </div>
        <div className='mt-3' style={{overflow: "auto"}}>
            <table className='table table-secondary newtable'>
                <tbody>
                {   data !== null ?
                    data.latestRecords.length > 0 ?
                    data.latestRecords[0].ratingType == "Performance Ranking" ?
                    <>
                    <tr>
                        <td className='col-2 fw-bold text-center'>Opinion</td>
                        <td className='col-1 fw-bold text-center'>Category</td>
                        <td className='col-2 fw-bold text-center'>Opinion Type</td>
                        <td className='col-2 fw-bold text-center'>Action</td>
                        <td className='col-4 fw-bold text-center' colSpan="3">Rankings</td>
                        <td className='col-2 fw-bold text-center'>Review Period End</td>
                    </tr>
                    <tr>
                        <td className='col-2'></td>
                        <td className='col-1'></td>
                        <td className='col-2'></td>
                        <td className='col-2'></td>
                        <td className='col-2 fw-bold text-center'>1 Year</td>
                        <td className='col-2 fw-bold text-center'>3 Year</td>
                        <td className='col-2 fw-bold text-center'>5 Year</td>
                        <td className='col-2'></td>
                    </tr>
                    <tr>
                        <td className='col-2 text-center'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null}</td>
                        <td className='col-1 text-center'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].fundCateg : null : null}</td>
                        <td className='col-2 text-center'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingType : null : null}</td>
                        <td className='col-2 text-center'>{data !== null ? data.latestRecords[0].ratingAction : null}</td>
                        <td className='col-2 text-center'>{data !== null ? data.latestRecords[0].shortTermRating : null}</td>
                        <td className='col-2 text-center'>{data !== null ? data.latestRecords[0].longTermRating : null}</td>
                        <td className='col-2 text-center'>{data !== null ? data.latestRecords[0].fiveYrRating : null}</td>
                        <td className='col-2'><input className="form-control" id="reviewEndDate" type="date" required></input></td>
                    </tr>
                    </> :
                    data.latestRecords[0].ratingType == "Entity" || data.latestRecords[0].ratingType == "Debt Instrument" ?
                    <>
                    <tr>
                        <td className='col-2 fw-bold'>Opinion</td>
                        <td className='col-4 fw-bold text-center' colSpan="2">Ratings</td>
                        <td className='col-2 fw-bold'>Outlook</td>
                        <td className='col-2 fw-bold'>Action</td>
                        <td className='col-2 fw-bold'>Rating Watch</td>
                        <td className='col-2 fw-bold'>Opinion Type</td>
                    </tr>
                    <tr>
                        <td className='col-2'></td>
                        <td className='col-2 fw-bold text-center'>Long Term</td>
                        <td className='col-2 fw-bold text-center'>Short Term</td>
                        <td className='col-2'></td>
                        <td className='col-2'></td>
                        <td className='col-2'></td>
                        <td className='col-2'></td>
                    </tr>
                    <tr>
                        <td className='col-2'>{data !== null ? data.latestRecords[0].opTitle : null}</td>
                        <td className='col-2 text-center'>{data !== null ? data.latestRecords[0].longTermRating : null}</td>
                        <td className='col-2 text-center'>{data !== null ? data.latestRecords[0].shortTermRating : null}</td>
                        <td className='col-2'>{data !== null ? data.latestRecords[0].outLook : null}</td>
                        <td className='col-2'>{data !== null ? data.latestRecords[0].ratingAction : null}</td>
                        <td className='col-2'>-</td>
                        <td className='col-2'>{data !== null ? data.latestRecords[0].ratingType : null}</td>
                    </tr>
                    </> :
                    <>
                    <tr>
                        <td className='col-2 fw-bold'>Opinion</td>
                        <td className='col-2 fw-bold text-center'>{data.latestRecords[0].ratingType == "IFS" ? 'IFS Rating' : 'Rating'}</td>
                        <td className='col-2 fw-bold'>Outlook</td>
                        <td className='col-2 fw-bold'>Action</td>
                        <td className='col-2 fw-bold'>Rating Watch</td>
                        <td className='col-2 fw-bold'>Opinion Type</td>
                    </tr>
                    <tr>
                        <td className='col-2'>{data !== null ? data.latestRecords[0].opTitle : null}</td>
                        <td className='col-2 text-center'>{data !== null ? data.latestRecords[0].longTermRating : null}</td>
                        <td className='col-2'>{data !== null ? data.latestRecords[0].outLook : null}</td>
                        <td className='col-2'>{data !== null ? data.latestRecords[0].ratingAction : null}</td>
                        <td className='col-2'>-</td>
                        <td className='col-2'>{data !== null ? data.latestRecords[0].ratingType : null}</td>
                    </tr>
                    </>

                    : null : null
                }
                
                </tbody>
            </table>
        </div>
        {data !== null ? <div className='mt-3 row'>
            <div className='col-lg-3 col-md-3 col-sm-3 col-12 mb-1'>
                <input className='form-control' value={'Yours Truly'} disabled/>
            </div>
            <div className='col-6 col-md-6 col-sm-6 col-12 mb-1'>
                <div className='col-8 col-md-8 col-sm-8 col-12 mx-auto'>
                <select className='form-select' id="s_name">
                    <option value="130" selected>Sameer Khan</option>                
                </select>
                </div>
            </div>
            <div className='col-3 col-md-3 col-sm-3 col-12 mb-1'>
                <select className='form-select' id="s_designation">
                    <option value="coo">Chief Business Officer</option>                
                </select>
            </div>
        </div> : null}


        <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                        severity={alertType}
                        action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setalertopen(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        sx={{ mb: 2 }}
                      >
                        {AlertMessage}
                    </Alert>
                </Collapse>
          </Box>
        <div className='mx-auto mt-3' style={{width: "fit-content"}}>
           {data.savedFlag == true ? 
           <button className='btn btn-info' type='submit' onClick={()=>{window.open(`${url}/api/viewnl-new/${client_id}/${og_rating_id}`, '_blank')}}> View </button> : 
           <button className='btn btn-primary' type='submit'> Save </button>}
        </div>
        </form>
      </>
        : 
      (<div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
      <div class="wrapper" id="loader2">
        <svg class="hourglass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 206" preserveAspectRatio="none">
          <path class="middle" d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"/>
          <path class="outer" d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"/>
        </svg>
      </div>
       </div>)}
    </div>
  )
}
