import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { url } from "../../../../Config";

export default function CloseInternally(props) {
    const { client_id, og_rating_id } = useParams();
    const Navigate = useNavigate();
    const id = localStorage.getItem("userID");
    const [data, setdata] = useState(null);
    const [latestRec, setlatestRec] = useState([]);
    const [RC, setRC] = useState([]);
    const [compiled_client_details, setcompiled_client_details] = useState(null);
    const [value, setvalue] = useState(null);
    const [alertopen, setalertopen] = useState(false);
    const [AlertMessage, setAlertMessage] = useState(null);
    const [alertType, setalertType] = useState("error");

    const DateFormat = ()=>{
        const currentDate = new Date();
        const day = currentDate.getDate();
        const monthIndex = currentDate.getMonth();
        const year = currentDate.getFullYear();

        const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const formattedDate = `${day}-${monthNames[monthIndex]}-${year.toString().slice(-2)}`;

        return formattedDate;
    }

    function DateFormating(params) {
        if (params == null) {
          return "-";
        } else {
          const date = new Date(params);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/generateICindex/${client_id}/${og_rating_id}`);
            const resp = res.data;
            if(resp.message == "IC already Done"){
                Navigate(`/Ratings/closer_internally/edit/${client_id}/${og_rating_id}`);
                return;
            }
            else{
                setdata(resp);
                setRC(resp.rc_data);
                setlatestRec(resp.latestRecords);
                setcompiled_client_details(resp.compiled_client_details);
            }
        }

        func();
    },[])

    const submit = async (event)=>{
        event.preventDefault();
        let longTermRating = null;
        let shortTermRating = null;
        let fiveYrRating = null;
        let outLook = null;
        let ratingAction = null;
        let ratingType = null;
        let opTitle = null;
        let rc_meeting_date = null;

        if(latestRec.length > 0){
            longTermRating = latestRec[0].longTermRating;
            shortTermRating = latestRec[0].shortTermRating;
            fiveYrRating = latestRec[0].fiveYrRating;
            ratingAction = latestRec[0].ratingAction;
            ratingType = latestRec[0].ratingType;
            outLook = latestRec[0].outLook;
            opTitle = latestRec[0].opTitle;
            rc_meeting_date = latestRec[0].rc_meeting_date;
        }
        const res = await axios.post(`${url}/api/storereview`,{
            "record_id" : og_rating_id,
            "recordId" : og_rating_id,
            "opid" : client_id,
            "c_rc_date" : DateFormating(data.rc_data[0].date),
            "rating_watch" : data.rc_data[0].final_rating_watch,
            "reviewDate" : document.getElementById("reviewDate").value,
            "longTermRating" : data.final_l_term,
            "shortTermRating" : data.final_s_term,
            "fiveYrRating" : data.final_5yr,
            "c_rating_watch" : data.rc_data[0].final_rating_watch,
            "outLook" : data.final_outlook,
            "ratingAction" : data.final_rating_action,
            "ratingType" : ratingType,
            "c_rating_watch" : data.lastestRW,
            "confidential" : "Confidential",
            "y_truly" : "Yours Truly",
            "s_name" : document.getElementById("s_name").value,
            "s_designation" : document.getElementById("s_designation").value,
            "address" : data.cities[0],
            "opTitle" : opTitle,
            "c_rc_meeting_date" : rc_meeting_date
        });

        if(res.data.status == true){
            localStorage.setItem("review", "Review Saved Successfully!");
            Navigate(`/Ratings/closer_internally/edit/${client_id}/${og_rating_id}`);
        }
        else{
            console.log(res, "response");
            setalertType("error");
            setAlertMessage("An error occure while process your request");
            setalertopen(true);
        }
        
    }

  return (
    <div style={{height: "90vh"}}>
      {data !== null ? <form className='card shadow col-11 mx-auto p-2 border border-dark p-1 mt-3 mb-3' style={{backgroundColor: "whitesmoke"}} onSubmit={submit}>
        <div className='row'>
            {props.screenWidth <= 576 && 
            <div className='col-12 text-wrap text-center fw-bold' style={{fontStyle : "12px"}}>
            {data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null}
            </div>}

            {props.screenWidth <= 576 && 
            <div className={'col-12 text-wrap text-center mb-1'} style={{fontSize : "10px"}}>
                {data !== null ? data.latestRecords.length > 0 ? 
                <>
                {data.latestRecords[0].ratingType == "BFR" ? `${data.latestRecords[0].ratingType} - ` : `${data.latestRecords[0].ratingType} Ratings -`}
                {data.latestRecords[0].ratingAction == "Initial" ? data.latestRecords[0].ratingAction : "Update"}
                </>
                 : null : null}
            </div>}
            {props.screenWidth > 576 && 
            <div className='col-3'>
            </div> }
            {props.screenWidth > 576 && <div className='col-6 text-wrap text-center fs-6 fw-bold'>
                {compiled_client_details !== null ? compiled_client_details.clientName : null}
            </div> }
            {props.screenWidth > 576 && <div className='col-3'>
                <input className='form-control' value={'Confidential'} disabled />
            </div>}
        </div>

        <div className='row mt-1'>
            {props.screenWidth > 576 && 
            <div className='col-3'>
            </div> }
            {props.screenWidth > 576 && <div className='col-6 text-wrap text-center fs-6'>
                {data !== null ? data.latestRecords.length > 0 ? 
                <>
                {data.latestRecords[0].ratingType == "BFR" ? `${data.latestRecords[0].ratingType} - ` : `${data.latestRecords[0].ratingType} Ratings -`}
                {data.latestRecords[0].ratingAction == "Initial" ? data.latestRecords[0].ratingAction : "Update"}
                </>
                 : null : null}
            </div>}
            <div className={props.screenWidth > 576 ? 'col-3' : 'col-12 mb-1'}>
                <input className='form-control' type='text' value={DateFormat()} id="reviewDate" disabled required/>
            </div>
            {props.screenWidth <= 576 && <div className={'col-12'}>
                <input className='form-control' value={'Confidential'} disabled />
            </div>}
        </div>

        <div className='mt-3' style={{overflow: "auto"}}>
            <table className='table table-secondary newtable'>
                <tbody>
                    {data !== null ? data.latestRecords.length > 0 ?
                    data.latestRecords[0].ratingType == "BFR" ?
                    <tr>
                            <td className='col-4 fw-bold'>Opinion</td>
                            <td className='col-2 fw-bold'>Rating</td>
                            <td className='col-2 fw-bold'>Outlook</td>
                            <td className='col-2 fw-bold'>Action</td>
                            <td className='col-2 fw-bold'>Opinion Type</td>
                    </tr> :
                    data.latestRecords[0].ratingType == "Project Grading" ?
                        <tr>
                            <td className='col-4 fw-bold'>Opinion</td>
                            <td className='col-2 fw-bold'>Grading</td>
                            <td className='col-2 fw-bold'>Outlook</td>
                            <td className='col-2 fw-bold'>Action</td>
                            <td className='col-2 fw-bold'>Opinion Type</td>
                        </tr>:
                        data.latestRecords[0].ratingType == "Entity" || data.latestRecords[0].ratingType == "Debt Instrument"?
                        <>
                        <tr>
                            <td className='col-2 fw-bold'>Opinion</td>
                            <td className='col-4 fw-bold text-center' colSpan="2">Ratings</td>
                            <td className='col-2 fw-bold'>Outlook</td>
                            <td className='col-2 fw-bold'>Action</td>
                            <td className='col-2 fw-bold'>Opinion Type</td>
                        </tr>
                        <tr>
                            <td className='col-2'></td>
                            <td className='col-2 fw-bold text-center'>Long Term</td>
                            <td className='col-2 fw-bold text-center'>Short Term</td>
                            <td className='col-2'></td>
                            <td className='col-2'></td>
                            <td className='col-2'></td>
                        </tr>
                        </> :
                        data.latestRecords[0].ratingType == "IFS" ?
                        <tr>
                            <td className='col-4 fw-bold'>Opinion</td>
                            <td className='col-2 fw-bold text-center'>IFS Rating</td>
                            <td className='col-2 fw-bold'>Outlook</td>
                            <td className='col-2 fw-bold'>Action</td>
                            <td className='col-2 fw-bold'>Opinion Type</td>
                        </tr> :
                        <tr>
                            <td className='col-4 fw-bold'>Opinion</td>
                            <td className='col-2 fw-bold text-center'>Rating</td>
                            <td className='col-2 fw-bold'>Outlook</td>
                            <td className='col-2 fw-bold'>Action</td>
                            <td className='col-2 fw-bold'>Opinion Type</td>
                        </tr>
                         : null : null
                    }
                    {data !== null ? data.latestRecords.length > 0 ? 
                    data.latestRecords[0].ratingType == "BFR" ?
                        <tr>
                            <td className='col-4'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null}</td>
                            <td className='col-2'>{data !== null ? data.final_l_term : null}</td>
                            <td className='col-2'>{data !== null ? data.final_outlook : null}</td>
                            <td className='col-2'>{data !== null ? data.final_rating_action : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingType : null : null}</td>
                        </tr> :
                    data.latestRecords[0].ratingType == "Project Grading" ?
                        <tr>
                            <td className='col-4'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null}</td>
                            <td className='col-2'>{data !== null ? data.final_l_term : null }</td>
                            <td className='col-2'>{data !== null ? data.final_outlook  : null}</td>
                            <td className='col-2'>{data !== null ? data.final_rating_action : null }</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingType : null : null}</td>
                        </tr> :
                        data.latestRecords[0].ratingType == "Entity" || data.latestRecords[0].ratingType == "Debt Instrument" ?
                        <tr>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null}</td>
                            <td className='col-2 text-center'>{data !== null ? data.final_l_term : null}</td>
                            <td className='col-2 text-center'>{data !== null ? data.final_s_term : null}</td>
                            <td className='col-2'>{data !== null ? data.final_outlook : null}</td>
                            <td className='col-2'>{data !== null ? data.final_rating_action : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingType : null : null}</td>
                        </tr> :
                        data.latestRecords[0].ratingType == "IFS" ?
                        <tr>
                            <td className='col-4'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null}</td>
                            <td className='col-2'>{data !== null ? data.final_l_term : null}</td>
                            <td className='col-2'>{data !== null ? data.final_outlook : null}</td>
                            <td className='col-2'>{data !== null ? data.final_rating_action : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingType : null : null}</td>
                        </tr> :
                        <tr>
                            <td className='col-4'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].opTitle : null : null}</td>
                            <td className='col-2'>{data !== null ? data.final_l_term : null}</td>
                            <td className='col-2'>{data !== null ? data.final_outlook : null}</td>
                            <td className='col-2'>{data !== null ? data.final_rating_action : null}</td>
                            <td className='col-2'>{data !== null ? data.latestRecords.length > 0 ? data.latestRecords[0].ratingType : null : null}</td>
                        </tr>
                    :null : null}
                </tbody>
            </table>
        </div>

        <div className='row mt-3'>
            <div className='col-lg-3 col-md-3 col-sm-3 col-12 mb-1'>
                <input className='form-control' value={'Yours Truly'} disabled/>
            </div>
            <div className='col-6 col-md-6 col-sm-6 col-12 mb-1'>
                <div className='col-8 col-md-8 col-sm-8 col-12 mx-auto'>
                <select className='form-select' id="s_name">
                <option value="19">Jhangeer Hanif</option>
                </select>
                </div>
            </div>
            <div className='col-3 col-md-3 col-sm-3 col-12 mb-1'>
                <select className='form-select' id="s_designation">
                    <option value="uh">Chief Rating Officer</option>
                </select>
            </div>
        </div>

        <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                        severity={alertType}
                        action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setalertopen(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        sx={{ mb: 2 }}
                      >
                        {AlertMessage}
                    </Alert>
                </Collapse>
          </Box>
        <div className='mx-auto mt-3' style={{width: "fit-content"}}>
            <button className='btn btn-primary' type='submit'> Save </button>
        </div>

      </form> : 
            (<div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
            <div class="wrapper" id="loader2">
              <svg
                class="hourglass"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 120 206"
                preserveAspectRatio="none"
              >
                <path
                  class="middle"
                  d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
                />
                <path
                  class="outer"
                  d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
                />
              </svg>
            </div>
          </div>)}
    </div>
  )
}
