import React,{useState, useEffect, useRef} from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { url } from '../../../Config';

export default function Opinion_assessment() {
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const id = localStorage.getItem("userID");
    const [bool, setbool] = useState(false);
    const [team, setteam] = useState([]);
    const [cob, setcob] = useState([]);
    const [analyst, setanalyst] = useState([]);
    const targetRef = useRef(null);
    const btn = useRef(null);
    const tl = useRef(null);
    const rt = useRef(null);
    const rs = useRef(null);
    const cn = useRef(null);
    const anl = useRef(null);
    const op = useRef(null);
    const [data, setdata] = useState([]);

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/opinionAllocate`);
            const resp = res.data;
            setdata(resp.unAllocatedOpinions);
            setcob(resp.cob);
        }
        func();
    }, [bool])

    useEffect(()=>{
        const func = async ()=>{ 
            const res2 = await axios.get(`${url}/api/getTeamAndAnalystList/${id}`);
            const resp2 = res2.data;
            setteam(resp2.team);
            if(id !== "19"){
                setanalyst(resp2.team[0].analyst);
            }
        }

        func();

    },[])

    const submit = async ()=>{
        if(tl.current.value == 0){
            setalertOpen(true);
            setalertmessage("Select Team Lead and Analyst First");  
            setalertType("error")
            return;
        }
        const res = await axios.post(`${url}/api/saveAllocation`, {
            "loginuser" : Number(id),
            "leadRc" : 19,
            "team" : tl.current.value,
            "analyst": anl.current.value,
            "opId" : op.current.value
        });
        const resp = res.data;
        if(resp.status == true){
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("success");
            btn.current.hidden = true;
            op.current.value = 0;
            setbool(!bool);
        }
        else{
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error");
        }
    }

  return (
    <div style={{height: "90vh"}}>
      <div className='card shadow col-10 mt-5 p-3 mx-auto'>
        <div className='fs-4 fw-bold mt-2 text-center text-decoration-underline' style={{color: "#000078"}}>
         Opinion Assignment
        </div>
        <Box className="mx-auto" ref={targetRef} sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
        </Box>
        <div className='mt-3 fw-bold fs-6'>
                <div className='text-decoration-underline'>
                    Opinion:
                </div>
                <div className='col-10 mt-1'>
                    <select className='form-select' ref={op} onChange={(e)=>{
                        const filteredData = data.filter(item => item.ID == e.target.value);
                        if(filteredData.length > 0){
                            rt.current.value = filteredData[0].rating_type.title;
                            rs.current.value = filteredData[0].rating_scale.title;
                            cn.current.value = filteredData[0].client_name.title;
                        }
                        else{
                            rt.current.value = null;
                            rs.current.value = null;
                            cn.current.value = null;
                        }
                        if(e.target.value > 0){
                            btn.current.hidden = false;
                        }
                        else{
                            btn.current.hidden = true;
                        }
                    }}>
                        <option value={0}>Select Opinion</option>
                        {
                            data.map((value, index)=>{
                                return(
                                    <option value={value.ID}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className='text-decoration-underline mt-2'>
                    Rating Type:
                </div>
                <div className='col-10 mt-1'>
                    <input className='form-control' ref={rt} disabled />
                </div>
                <div className='text-decoration-underline mt-2'>
                    Rating Scale:
                </div>
                <div className='col-10 mt-1'>
                    <input className='form-control' ref={rs} disabled />
                </div>
                <div className='text-decoration-underline mt-2'>
                    Client Name:
                </div>
                <div className='col-10 mt-1'>
                    <input className='form-control' ref={cn} disabled />
                </div>
            
                <div className='text-decoration-underline mt-2'>
                    Team:
                </div>
                <div className='col-10 mt-1'>
                    <select className='form-select' ref={tl} onChange={(e)=>{
                        for(let i in team){
                            if(team[i].id == e.target.value){
                                setanalyst(team[i].analyst)
                                return;
                            }
                        }
                    }}>
                    {id == "19" && <option value={0}>Select Team Lead</option>}
                    {
                        team.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.display_name}</option>
                            )
                        })
                    }
                    </select>
                </div>
                {   
                    analyst.length > 0 &&
                    <>
                        <div className='text-decoration-underline mt-2'>
                            Analyst:
                        </div>
                        <div className='col-10 mt-1'>
                        <select className='form-select' defaultValue={cob.length > 0 ? cob[0].id : 0} ref={anl}>
                            {
                                cob.map((value, index)=>{
                                    return(
                                        <option value={value.id}>{value.display_name}</option>
                                    )
                                })
                            }
                            {
                                analyst.map((value, index)=>{
                                    return(
                                        <option value={value.id}>{value.display_name}</option>
                                    )
                                })
                            }
                        </select>
                        </div>
                    
                    </>
                }
                <div className='mt-3' style={{width: "fit-content"}} ref={btn} hidden={true}>
                    <button className='btn btn-primary' onClick={()=>{submit()}}>Submit</button>
                </div>
        </div>

      </div>
    </div>
  )
}
