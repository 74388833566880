import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../../Config';
import Select from "react-select";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { Check, Clear, Event } from "@material-ui/icons";


export default function Edit_methodolody(props) {
    const sourceRef = useRef(null);
    const scaleRef = useRef(null);
    const dateref = useRef(null);
    const mmRef = useRef(null);
    const docRef = useRef(null);
    const imageRef = useRef(null);
    const textRef = useRef(null);
    const nameRef = useRef(null);
    const btnRef = useRef(null);
    const enterRef = useRef(null);
    const btnRef2 = useRef(null);

    const Navigate = useNavigate();
    const { m_id } = useParams();
    const [source, setsource] = useState([]);
    const [scale, setscale] = useState([]);
    const [group, setgroup] = useState([]);
    const [grouparr, setgrouparr] = useState([]);
    const [groupOptions, setgroupOptions] = useState([]);
    const [industryOptions, setindustryOptions] = useState([]);
    const [industry, setindustry] = useState([]);
    const [industry2, setindustry2] = useState([]);
    const [industryarr, setindustryarr] = useState([]);
    const [sector, setsector] = useState([]);
    const [sector2, setsector2] = useState([]);
    const [sectorarr, setsectorarr] = useState([]);
    const [sectorOption, setsectorOption] = useState([]);
    const [scale_file, setscale_file] = useState(null);
    const [minutes_file, setminutes_file] = useState(null);
    const [doc_file, setdoc_file] = useState(null);
    const [img_file, setimg_file] = useState(null);
    const [sourceval, setsourceval] = useState('');
    const [scaleval, setscaleval] = useState('');
    const [methodology, setmethodology] = useState({});
    const [bool, setbool] = useState(false);
    const [status, setstatus] = useState(null);
    const [activeMethodology, setactiveMethodology] = useState([]);

    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();

    const rightsval = localStorage.getItem("rights");

    let rights = [];
  
    if(rightsval !== null){
      rights = rightsval.split(",");
    }
    const can_approve_methodology = rights.includes("can_approve_methodology");


    useEffect(()=>{

        const func = async ()=>{

            const res = await axios.get(`${url}/api/view_mathodology/${m_id}`);
            const resp = res.data;
            setsource(resp.rating_agencies);
            setscale(resp.rating_scales);
            setstatus(resp.methodology.status);
            const groups = resp.segments.map(item => ({ value: item.id, label: item.title }));
            
            setgroup(groups);
            const industries = resp.main_sectors.map(item => ({ value: item.id, label: item.title }));
            setindustry(industries);
            setindustry2(resp.main_sectors);

            const sec = resp.sectors.map(item => ({ value: item.id, label: item.title }));
            setsector(sec);
            setsector2(resp.sectors);

            const methodology = resp.methodology;
            setmethodology(methodology);
            setsourceval(Number(methodology.source));
            setscaleval(Number(methodology.scale_id));
            let grouparray = [];
            let groupop = [];
            if(methodology.segment_id !== null){
                for(let i in groups){
                    for(let j in methodology.segment_id){
                        if(Number(methodology.segment_id[j]) == groups[i].value){
                            groupop.push(groups[i]);
                        }
                    }
                }
                for(let i in methodology.segment_id){
                    grouparray.push(Number(methodology.segment_id[i]));
                }
                setgrouparr(grouparray);
                setgroupOptions(groupop)
            }
            else{
                setgrouparr([])
            }


            let industryarray = [];
            let industryop = [];
            if(methodology.main_sector_id !== null){
                for(let i in industries){
                    for(let j in methodology.main_sector_id){
                        if(Number(methodology.main_sector_id[j]) == industries[i].value){
                            industryop.push(industries[i]);
                        }
                    }
                }
                for(let i in methodology.main_sector_id){
                    industryarray.push(Number(methodology.main_sector_id[i]));
                }
                setindustryarr(industryarray);
                setindustryOptions(industryop)
            }
            else{
                setindustryarr([])
            }


            let sectorarray = [];
            let sectorop = [];
            if(methodology.sector_id !== null){
                for(let i in sec){
                    for(let j in methodology.sector_id){
                        if(Number(methodology.sector_id[j]) == sec[i].value){
                            sectorop.push(sec[i]);
                        }
                    }
                }
                for(let i in methodology.sector_id){
                    sectorarray.push(Number(methodology.sector_id[i]));
                }
                setsectorarr(sectorarray);
                setsectorOption(sectorop)
            }
            else{
                setsectorarr([])
            }
            

        }

        const func2 = async ()=>{
            const res = await axios.get(`${url}/api/getmethodology`);
            const resp = res.data;
            setactiveMethodology(resp.methodologies);
        }

        func();
        func2();
    },[bool])


    const handlegroup = (data)=>{
        let arr = [];
        let arr2 = [];
        let arr3 = [];
        let arr4 = [];
        let arr5 = [];


        for(let i in data){
            arr.push(data[i].value);
            for(let j in industry2){
                if(data[i].value == industry2[j].segment_id){
                    arr2.push(industry2[j]);
                }
            }
        }

        for(let i in arr2){
            for(let j in sector2){
                if(arr2[i].id == sector2[j].main_sector_id){
                    arr4.push(sector2[j])
                }
            }
        }

        if(arr2.length > 0){
            const mergedarr = arr2.map(item => ({ value: item.id, label: item.title }));
            for(let i in mergedarr){
                arr3.push(mergedarr[i].value)
            }
    
            setindustryarr(arr3);

            setindustryOptions(mergedarr);
        }
        else{
            setindustryarr([]);
            setindustryOptions([]);
        }

        if(arr4.length > 0){
            const mergedarr2 = arr4.map(item => ({ value: item.id, label: item.title }));
            for(let i in mergedarr2){
                arr5.push(mergedarr2[i].value)
            }
    
            setsectorarr(arr5);
            setsectorOption(mergedarr2);
        }
        else{
            setsectorarr([]);
            setsectorOption([]);
        }

        setgrouparr(arr);
        setgroupOptions(data)
 
    }


    const handleindustry = (data)=>{

        let arr = []
        let arr2 = [];
        let arr3 = [];

        for(let i in data){
            arr.push(data[i].value);
            for(let j in sector2){
                if(data[i].value == sector2[j].main_sector_id){
                    arr2.push(sector2[j])
                }
            }
        }

        if(arr2.length > 0){
            const mergedarr = arr2.map(item => ({ value: item.id, label: item.title }));
            for(let i in mergedarr){
                arr3.push(mergedarr[i].value)
            }
    
            setsectorarr(arr3);

            setsectorOption(mergedarr);
        }
        else{
            setsectorarr([]);
            setsectorOption([]);
        }

        setindustryarr(arr);
        setindustryOptions(data)
    }

    const handlesector = (data)=>{

        let arr = []

        for(let i in data){
            arr.push(data[i].value)
        }

        setsectorarr(arr);
        setsectorOption(data)
    }

    const editForm = async (event)=>{
        setalertOpen(false);
        event.preventDefault();
        btnRef.current.disabled = true;
        btnRef.current.innerHTML = "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";
        const fileData = new FormData();
        if(sourceval == ''){
        }
        else{
            fileData.append("source", sourceval);
        }

        if(scaleval == ''){
        }
        else{
            fileData.append("scale_id", scaleval);
        }

        if(textRef.current.value == ''){
        }
        else{

            fileData.append("write_up", textRef.current.value);
        }

        if(grouparr.length == 0){
        }
        else{
            fileData.append("segment", grouparr);
        }

        if(industryarr.length == 0){
        }
        else{
            fileData.append("main_sector", industryarr);
        }

        if(sectorarr.length == 0){
        }
        else{
            fileData.append("sub_sector", sectorarr);
        }

        fileData.append("methodology_id", m_id);
        fileData.append("ss_name", nameRef.current.value);
        fileData.append("date", dateref.current.value);


        if(scale_file !== null && scale_file!== undefined){
            fileData.append("scale_file", scale_file);
        }
        if(minutes_file !== null && minutes_file!== undefined){
            fileData.append("mm_file", minutes_file);
        }
        if(doc_file !== null && doc_file!== undefined){
            fileData.append("doc_file", doc_file);
        }
        if(img_file !== null && img_file!== undefined){
            fileData.append("image", img_file);
        }


        const res = await axios.post(`${url}/api/edit_methadology`,
        fileData,
        {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        });
        const resp = res.data;

        if(resp.status == true){
            btnRef.current.disabled = false;
            btnRef.current.innerHTML =  "Update";
            setbool(!bool)
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btnRef.current.disabled = false;
            btnRef.current.innerHTML =  "Update";
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
    }

    const approve = async ()=>{
        setalertOpen(false);
        btnRef2.current.disabled = true;
        btnRef2.current.innerHTML = "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";
        const fileData = new FormData();
        if(sourceval == ''){
        }
        else{
            fileData.append("source", sourceval);
        }

        if(scaleval == ''){
        }
        else{
            fileData.append("scale_id", scaleval);
        }

        if(textRef.current.value == ''){
        }
        else{

            fileData.append("write_up", textRef.current.value);
        }

        if(grouparr.length == 0){
        }
        else{
            fileData.append("segment", grouparr);
        }

        if(industryarr.length == 0){
        }
        else{
            fileData.append("main_sector", industryarr);
        }

        if(sectorarr.length == 0){
        }
        else{
            fileData.append("sub_sector", sectorarr);
        }

        fileData.append("methodology_id", m_id);
        fileData.append("ss_name", nameRef.current.value);
        fileData.append("date", dateref.current.value);


        if(scale_file !== null && scale_file!== undefined){
            fileData.append("scale_file", scale_file);
        }
        if(minutes_file !== null && minutes_file!== undefined){
            fileData.append("mm_file", minutes_file);
        }
        if(doc_file !== null && doc_file!== undefined){
            fileData.append("doc_file", doc_file);
        }
        if(img_file !== null && img_file!== undefined){
            fileData.append("image", img_file);
        }


        const res = await axios.post(`${url}/api/approve_methodology`,
        fileData,
        {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        });
        const resp = res.data;

        if(resp.status == true){
            btnRef2.current.disabled = false;
            btnRef2.current.innerHTML = "Approve";
            setbool(!bool)
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btnRef2.current.disabled = false;
            btnRef2.current.innerHTML = "Approve";
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }

    }


  return (
    <div>
       <div className='fs-3 fw-bold text-center mt-2 mb-3' style={{color: "#000078"}}>
            Edit Methodology
        </div>
        <div>
            <form className='card shadow col-11 mx-auto p-2' onSubmit={(event)=>{editForm(event)}}>
                <div>
                    <div className='mt-2 fw-bold'>
                        Source
                    </div>
                    <div className='mt-2'>
                        <select className='form-select' ref={sourceRef} value={sourceval} onChange={(e)=>{setsourceval(e.target.value)}}>
                           <option value=''>Source</option>
                           {
                            source.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.name}</option>
                                )
                            })
                           }
                        </select>
                    </div>
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Select Scale
                    </div>
                    <div className='mt-2'>
                        <select className='form-select' ref={scaleRef} value={scaleval} onChange={(e)=>{setscaleval(e.target.value)}}>
                            <option value=''>Select Scale</option>
                            {
                                scale.map((value, index)=>{
                                    return(
                                        <option value={value.id}>{value.title}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Group
                    </div>
                    <div className='mt-2'>
                        <Select
                            options={group}
                            placeholder="Select Group"
                            value={groupOptions}
                            onChange={handlegroup}
                            isSearchable={true}
                            isMulti
                        />
                    </div>
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Industry
                    </div>
                    <div className='mt-2'>
                        <Select
                            options={industry}
                            placeholder="Select Industry"
                            value={industryOptions}
                            onChange={handleindustry}
                            isSearchable={true}
                            isMulti
                        />
                    </div>
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Sector
                    </div>
                    <div className='mt-2'>
                        <Select
                            options={sector}
                            placeholder="Select Sector"
                            value={sectorOption}
                            onChange={handlesector}
                            isSearchable={true}
                            isMulti
                        />
                    </div>
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Methodology Name
                    </div>
                    <div className='mt-2'>
                        <select className='form-select' onChange={(e)=>{
                            if(e.target.value == 'New'){
                                nameRef.current.value = "";
                                nameRef.current.disabled = false;
                                enterRef.current.hidden = false;
                            }
                            else{
                                nameRef.current.value = e.target.value;
                                nameRef.current.disabled = true;
                                enterRef.current.hidden = true;
                            }
                        }}>
                            <option value={'New'}>New</option>
                            {
                            activeMethodology.map((value, index)=>{
                                return(
                                    <option value={value.name2}>{value.name2}</option>
                                )
                            })
                            }
                        </select>
                    </div>
                    <div className='mt-2'>
                        <div ref={enterRef} style={{color: 'red'}}>Enter Methodology Name Here:</div>
                        <input className='form-control' type='text' defaultValue={methodology.name} ref={nameRef} required/>
                    </div>
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Date
                    </div>
                    <div className='mt-2'>
                       <input className='form-control' type='date' defaultValue={methodology.date} ref={dateref} />
                    </div>
                </div>   
                <div>
                    <div className='mt-2 fw-bold'>
                        Upload Scale File
                    </div>
                    <div className='mt-2'>
                        <input className='form-control' ref={scaleRef} type='file' id="scaleInput" onChange={(e)=>{
                            const file = e.target.files[0];
                            if (file && file.type === "application/pdf") {
                                setscale_file(e.target.files[0])
                            }
                            else{
                                e.target.value = null;
                                setscale_file(null);
                                alert("Please Upload Rating Scale File in PDF format");
                            }
                        }} />
                    </div>
                    {(methodology.scale_file !== null && methodology.scale_file !== '') &&
                    <div className='mt-1'>
                        Uploaded File: <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${methodology.scale_file_encrypt}`} target='_blank'>File</a>
                    </div>}
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Upload MM File
                    </div>
                    <div className='mt-2'>
                        <input className='form-control' ref={mmRef} type='file' id="mmInput" onChange={(e)=>{
                            setminutes_file(e.target.files[0])
                        }} />
                    </div>
                    {(methodology.mm_file !== null && methodology.mm_file !== '') &&
                    <div className='mt-1'>
                        Uploaded File: <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${methodology.mm_file_encrypt}`} target='_blank'>File</a>
                    </div>}
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Upload DOC File
                    </div>
                    <div className='mt-2'>
                        <input className='form-control' ref={docRef} type='file' id="docInput" onChange={(e)=>{
                            setdoc_file(e.target.files[0])
                        }} />
                    </div>
                    {(methodology.doc_file !== null && methodology.doc_file !== '') &&
                    <div className='mt-1'>
                        Uploaded File: <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${methodology.doc_file_encrypt}`} target='_blank'>File</a>
                    </div>}
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Upload Image
                    </div>
                    <div className='mt-2'>
                        <input className='form-control' ref={imageRef} type='file' id="imgInput" accept=".jpeg, .jpg, .png, .gif, .svg" onChange={(e)=>{
                            setimg_file(e.target.files[0])
                        }} />
                    </div>
                    {(methodology.img_file !== null && methodology.img_file !== '') &&
                    <div className='mt-1'>
                        Uploaded File: <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${methodology.img_file_encrypt}`} target='_blank'>File</a>
                    </div>}
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Write Up
                    </div>
                    <div className='mt-2'>
                        <textarea className='form-control' defaultValue={methodology.text} ref={textRef}/>
                    </div>
                </div>
                <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
                </Box>
                <div className='mt-2 mb-2'>
                    <button className='btn btn-primary' type='submit' ref={btnRef}>Update</button>
                    {status !== 'Approved' ? can_approve_methodology == true && 
                    <button className='btn btn-success ms-1' type='button' ref={btnRef2} onClick={()=>{approve()}}>Approve</button> : null}
                </div>
            </form>
        </div>
        <br/>
    </div>
  )
}
