import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../Config';
import { Check, Clear, Event } from "@material-ui/icons";
import Select from "react-select";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';
const id = localStorage.getItem("userID");


export default function AddPublication(props) {

    const [source, setsource] = useState([]);
    const Navigate = useNavigate();
    const [group, setgroup] = useState([]);
    const [industries, setindustries] = useState([]);
    const [sectors, setsectors] = useState([]);
    const [file, setfile] = useState(null);
    const [img_file, setimg_file] = useState(null);
    const [groupval, setgroupval] = useState('');
    const [industriesval, setindustriesval] = useState('');
    const [sectorsval, setsectorsval] = useState('');
    const [sourceval, setsourceval] = useState('');
    const [publication_type_val, setpublication_type_val] = useState('');
    const [publication_name, setpublication_name] = useState('');
    const [sectorname, setsectorname] = useState('');
    const [team, setteam] = useState([]);
    const [currentDate, setCurrentDate] = useState('');

    const sourceRef = useRef(null);
    const publication_typpe_Ref = useRef(null);
    const fileref = useRef(null);
    const imageRef = useRef(null);
    const textRef = useRef(null);
    const teamRef = useRef(null);
    const dateRef = useRef(null);
    const supRef = useRef(null);
    const ssnameRef = useRef(null);
    const btnRef = useRef(null);


    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();

    useEffect(()=>{

        const func = async ()=>{

            const res = await axios.get(`${url}/api/add_publications/${id}`);
            const resp = res.data;
            setsource(resp.rating_agencies);
            setgroup(resp.group);
            setteam(resp.respectiveTeams)
            // setscale(resp.rating_scales);
            // const groups = resp.segments.map(item => ({ value: item.id, label: item.title }));
            // setgroup(groups);
            // const industries = resp.main_sectors.map(item => ({ value: item.id, label: item.title }));
            // setindustry(industries);
            // const sec = resp.sectors.map(item => ({ value: item.id, label: item.title }));
            // setsector(sec);

        }

        func();
    },[])

    useEffect(() => {
        // Function to get the current date in the format "YYYY-MM-DD"
        const getCurrentDate = () => {
          const now = new Date();
          const year = now.getFullYear();
          const month = (now.getMonth() + 1).toString().padStart(2, '0');
          const day = now.getDate().toString().padStart(2, '0');
          return `${year}-${month}-${day}`;
        };
    
        setCurrentDate(getCurrentDate());
      }, []);

    const submitForm = async (event)=>{
        event.preventDefault();
        btnRef.current.disabled = true;
        btnRef.current.innerHTML = "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";

        const fileData = new FormData();


        fileData.append("source", sourceval);
        fileData.append("publication_type", publication_type_val);
        fileData.append("userId", id);
        fileData.append("segment", groupval);
        fileData.append("main_sector", industriesval);
        fileData.append("sub_sector", sectorsval);
        fileData.append("ss_name", sectorname);
        fileData.append("team_lead", teamRef.current.value);
        fileData.append("date", dateRef.current.value);
        fileData.append("write_up", textRef.current.value);
        if(file !== null && file!== undefined){
            fileData.append("file", file);
        }
        if(img_file !== null && img_file!== undefined){
            fileData.append("image", img_file);
        }

        const res = await axios.post(`${url}/api/insertPublication`,
        fileData,
        {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        })
        const resp = res.data;
        // console.log(resp, 'resp')
        if(resp.status == true){
            btnRef.current.disabled = false;
            btnRef.current.innerHTML = "Save";

            Navigate('/publications');
            // setalertType("success");
            // setalertOpen(true);
            // setalertmessage(resp.message);
        }
        else{
            btnRef.current.disabled = false;
            btnRef.current.innerHTML = "Save";
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
    }


  return (
        <div>
      <div className='fs-3 fw-bold text-center mt-2 mb-3' style={{color: "#000078"}}>
            Add New Publication
        </div>
        <div>
            <form className='card shadow col-11 mx-auto p-2' onSubmit={(event)=>{submitForm(event)}}>
                <div>
                    <div className='mt-2 fw-bold'>
                        Source
                    </div>
                    <div className='mt-2'>
                        <select className='form-select' ref={sourceRef} value={sourceval} onChange={(e)=>{setsourceval(e.target.value)}}>
                           <option value=''>Select Source</option>
                           {
                            source.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.name}</option>
                                )
                            })
                           }
                        </select>
                    </div>
                </div>

                <div>
                <div className='mt-2 fw-bold'>
                    Group:
                </div>
                <div className='mt-2'>
                    <select className='form-select' value={groupval} onChange={(e)=>{
                        setgroupval(e.target.value);
                        if(e.target.value == ''){
                            setsectors([]);
                            setindustries([]);
                        }
                        else{
                            for(let i in group){
                                if(group[i].id == e.target.value){
                                    setindustries(group[i].industries);
                                }
                            }
                        }
                        }
                        
                        }>
                        <option value={''}>Select Group</option>
                        {
                            group.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Cluster:
                </div>
                <div className='mt-2'>
                    <select className='form-select' value={industriesval} onChange={(e)=>{
                        setindustriesval(e.target.value)
                        if(e.target.value == ''){
                            setsectors([]);
                        }
                        else{
                            for(let i in industries){
                                if(industries[i].id == e.target.value){
                                    setsectors(industries[i].sectors);
                                }
                            }
                        }
                        }}>
                        <option value={''}>Select Industry</option>
                        {
                            industries.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Sector:
                </div>
                <div className='mt-2'>
                    <select className='form-select' id='sectorname' value={sectorsval} onChange={(e)=>{
                        setsectorsval(e.target.value);
                        let sector = document.getElementById('sectorname');
                        let sector_name = `Comments | Coronavirus | ${sector.options[sector.selectedIndex].text}`
                        setsectorname(sector_name);
                        }}>
                        <option value={''}>Select Sector</option>
                        {
                            sectors.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                    <div className='mt-2 fw-bold'>
                        Select Publication Type
                    </div>
                    <div className='mt-2'>
                        <select className='form-select' ref={publication_typpe_Ref} value={publication_type_val} onChange={(e)=>{setpublication_type_val(e.target.value)}} required>
                            <option value="Event Insight">Event Insight</option>
                            <option value="Dashboard">Dashboard</option>
                            <option value="Other">Other</option>                           
                        </select>
                    </div>
                </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Publication Name:
                </div>
                <div className='mt-2 fw-bold'>
                    <input className='form-control' ref={ssnameRef} value={sectorname} onChange={(e)=>{setsectorname(e.target.value)}}  />
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Sector Owner:
                </div>
                <div className='mt-2 fw-bold'>
                    <select className='form-select' ref={teamRef} onChange={(e)=>{
                       
                        }}>
                        {
                            team.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.display_name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Date:
                </div>
                <div className='mt-2 fw-bold'>
                    <input className='form-control' type='date' value={currentDate} ref={dateRef} />
                </div>
            </div>
            <div>
                    <div className='mt-2 fw-bold'>
                        Upload File
                    </div>
                    <div className='mt-2'>
                        <input className='form-control' ref={fileref} type='file' id="docInput" onChange={(e)=>{
                            setfile(e.target.files[0])
                        }} />
                    </div>
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Upload Image:
                    </div>
                    <div className='mt-2'>
                        <input className='form-control' ref={imageRef} type='file' id="imgInput" accept=".jpeg, .jpg, .png, .gif, .svg" onChange={(e)=>{
                            setimg_file(e.target.files[0])
                        }} />
                    </div>
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Write Up
                    </div>
                    <div className='mt-2'>
                        <textarea className='form-control' placeholder='You can Type Maximum 400 characters here' maxLength={400} ref={textRef}/>
                    </div>
                </div>
                <Box className="mx-auto mt-2 mb-2" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
                </Box>
                <div>
                    <button className='btn btn-primary mt-3 mt-2' type='submit' ref={btnRef}>Save</button>
                </div>
            </form>
            <br/>
        </div>
        <br/>
    </div>
  )
}
