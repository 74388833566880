import React, { useEffect, useState, useRef } from 'react';
import { url } from '../../../../Config';
import axios from 'axios';
import Select from "react-select";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';


export default function ApproveMapping(props) {
    const [data, setdata] = useState([]);
    const [Opinion1, setOpinion1] = useState([]);
    const [Opinion2, setOpinion2] = useState([]);
    const [Opinion1arr, setOpinion1arr] = useState([]);
    const [Opinion2arr, setOpinion2arr] = useState([]);
    const [Opinion1_options, setOpinion1_options] = useState([]);
    const [Opinion2_options, setOpinion2_options] = useState([]);
    const [bool, setbool] = useState(false);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [a1, seta1] = useState('null');
    const [a2, seta2] = useState('null');
    const select1 = useRef(null);
    const select2 = useRef(null);
    const load1 = useRef(null);
    const load2 = useRef(null);
    const btn1 = useRef(null);
    const btn2 = useRef(null);

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/approvemapping`);
            const resp = res.data;
            setdata(resp.var);
            if(btn1.current){
                btn1.current.disabled = true
            }
            if(btn2.current){
                btn2.current.disabled = true
            }
        }

        func();
    },[btn1.current])
    


    const handleOpinion1 = (data)=>{
        setOpinion1_options(data);
        const arr = [];
        for(let i in data){
            arr.push(data[i].value)
        }
        setOpinion1arr(arr);
    }

    const handleOpinion2 = (data)=>{
        setOpinion2_options(data);
        const arr = [];
        for(let i in data){
            arr.push(data[i].value);
        }
        setOpinion2arr(arr);
    }

    const ApproveMapping = async ()=>{
        setalertOpen(false);
        btn1.current.disabled = true;
        btn1.current.innerHTML = "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";

        const res = await axios.post(`${url}/api/savemappingapprovals`,{
            'ids': Opinion1arr
        });
        const resp = res.data;

        if(resp.status == true){
            btn1.current.disabled = false;
            btn1.current.innerHTML = "Save";
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btn1.current.disabled = false;
            btn1.current.innerHTML = "Save";
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        
    }
    const DisApproveMapping = async ()=>{
        setalertOpen(false);
        btn2.current.disabled = true;
        btn2.current.innerHTML = "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";
        const res = await axios.post(`${url}/api/disapproveapprovedmaping`,{
            'ids': Opinion2arr
        });
        const resp = res.data;

        if(resp.status == true){
            btn2.current.disabled = false;
            btn2.current.innerHTML = "Update";
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btn2.current.disabled = false;
            btn2.current.innerHTML = "Update";
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        
    }

  return (
    <div style={{height: '90vh'}}>
      <div className='fs-3 fw-bold text-center mt-2 mb-3' style={{color: "#000078"}}>
        Opinion & Assessment Format Mapping Approval Section
      </div>
      <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
        </Box>
      <div className='row p-5 mt-5'>
            <div className='card shadow ms-lg-5 ms-md-5 me-lg-2 me-md-2 me-0 col-lg-5 col-md-5 col-12 p-3'>
                <div className='text-center fs-5 fw-bold'>
                    Pending Approvals For Assessment Format Mapping
                </div>
                <div className='mt-3'>
                    <select className='form-control' value={a1} onChange={async (e)=>{
                        seta1(e.target.value);
                        if(e.target.value !== 'null'){
                            btn1.current.disabled = true;
                            select1.current.hidden = true;
                            load1.current.hidden = false;
                        }
                        else{
                            btn1.current.disabled = true;
                        }
                        setOpinion1_options([]);
                        const res = await axios.get(`${url}/api/findopinions/${e.target.value}`);
                        const resp = res.data;
                        const merged = resp.opinion.map(item => ({ value: item.id, label: item.name }));
                        setOpinion1(merged);
                        if(e.target.value !== 'null'){
                            if(merged.length > 0){
                                btn1.current.disabled = false;
                                select1.current.hidden = false;
                                load1.current.hidden = true;
                            }
                            else{
                                select1.current.hidden = false;
                                load1.current.hidden = true;
                            }
                        }
                    }}>
                        <option value={'null'}>Select Format</option>
                        {
                            data.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className='mt-2' ref={select1}>
                    <Select
                            options={Opinion1}
                            placeholder="Select Opinion"
                            value={Opinion1_options}
                            onChange={handleOpinion1}
                            isSearchable={true}
                            isMulti
                    />
                </div>
                <div className='mt-3 mx-auto' hidden={true} ref={load1} style={{width: 'fit-content'}}>
                    <i className='fa-solid fa-spinner fa-spin fa-2xl'></i>
                </div>
                <div className='mt-3'>
                    <button className='btn btn-primary' ref={btn1}  onClick={()=>{ApproveMapping()}}>Save</button>
                </div>
            </div>
            <div className='card shadow ms-lg-5 ms-md-5 me-lg-2 me-md-2 me-0 col-lg-5 col-md-5 col-12 p-3'>
                <div className='text-center fs-5 fw-bold'>
                    Already Approved Mapped Formats
                </div>
                <div className='mt-3'>
                    <select className='form-control' value={a2} onChange={async(e)=>{
                        seta2(e.target.value);
                        if(e.target.value !== 'null'){
                            btn2.current.disabled = true;
                            select2.current.hidden = true;
                            load2.current.hidden = false;
                        }
                        else{
                            btn2.current.disabled = true;
                        }
                        setOpinion2_options([]);
                         const res = await axios.get(`${url}/api/getmappedandapprovedopinions/${e.target.value}`);
                         const resp = res.data;
                         const merged = resp.opinion.map(item => ({ value: item.id, label: item.name }));
                         setOpinion2(merged);
                         if(e.target.value !== 'null'){
                            if(merged.length > 0){
                                btn2.current.disabled = false;
                                select2.current.hidden = false;
                                load2.current.hidden = true;
                            }
                            else{
                                select2.current.hidden = false;
                                load2.current.hidden = true;
                            }
                        }
                    }}>
                        <option value={'null'}>Select Format</option>
                        {
                            data.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className='mt-2' ref={select2}>
                    <Select
                            options={Opinion2}
                            placeholder="Select Opinion"
                            value={Opinion2_options}
                            onChange={handleOpinion2}
                            isSearchable={true}
                            isMulti
                    />
                </div>
                <div className='mt-3 mx-auto' ref={load2} hidden={true} style={{width: 'fit-content'}}>
                    <i className='fa-solid fa-spinner fa-spin fa-2xl'></i>
                </div>
                <div className='mt-3'>
                    <button className='btn btn-success' ref={btn2} onClick={()=>{DisApproveMapping()}}>Update</button>
                </div>
            </div>
      </div>
    </div>
  )
}
