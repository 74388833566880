import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import PPL from "../PPL";
import { url } from "../../../../Config";
import { Nav } from "react-bootstrap";


export default function Editppl(props) {

    const { client_id, og_rating_id } = useParams();
    const [clientId, setclientId] = useState(null);
    const Navigate = useNavigate();
    const message = localStorage.getItem("alert");
    const id = localStorage.getItem("userID");
    const [data, setdata] = useState(null);
    const [date, setdate] = useState();
    const [PPL_data, setPPL_data] = useState(null);
    const [withdraw_text, setwithdraw_text] = useState(null);
    const [text, settext] = useState(null);
    const [customers, setcustomers] = useState([]);
    const [compiled_client_details, setcompiled_client_details] = useState(null);
    const [lastRC, setlastRC] = useState([]);
    const [alertopen, setalertopen] = useState(false);
    const [AlertMessage, setAlertMessage] = useState(null);
    const [alertType, setalertType] = useState("error");
    const [rr_krd, setrr_krd] = useState({});
    const [allPPL, setallPPL] = useState([]);
    const [action, setaction] = useState(null);
    const [og_ratingdata, setog_ratingdata] = useState({});
    const btnRef = useRef(null);
    const [clientname, setclientname] = useState("");


    function DateFormat(params) {
        if (params == null) {
          return "-";
        } else {
          const date = new Date(params);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

    function currentDate(){
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    
    useEffect(()=>{
        let hasRun = false;
        const func = async ()=>{
            const res = await axios.get(`${url}/api/getEditppl/${id}/${client_id}/${og_rating_id}`);
            const resp = res.data;
            if(resp.message == "Generate PPL First"){
                Navigate(`/Ratings/generate_ppl/${client_id}/${og_rating_id}`)
            }
            else{
                setclientId(resp.compiled_client_details.client_id);
                if(resp.PPL_data.rating_type == "BFR" || resp.PPL_data.rating_type == "Social Impact and Performance Rating"){
                    const res2 = await axios.get(`${url}/api/rr_krd/${client_id}/${og_rating_id}/bfr_sip`);
                    const resp2 = res2.data;
                    setrr_krd(resp2);
                }
                else if(resp.PPL_data.rating_type == "Performance Ranking"){
                    const res2 = await axios.get(`${url}/api/rr_krd/${client_id}/${og_rating_id}/perf`);
                    const resp2 = res2.data;
                    setrr_krd(resp2);
                }
                else{
                    const res2 = await axios.get(`${url}/api/rr_krd/${client_id}/${og_rating_id}/krd`);
                    const resp2 = res2.data;
                    setrr_krd(resp2);
                }
                setPPL_data(resp.PPL_data);
                if(resp.latestRecords.length > 0){
                    setog_ratingdata(resp.latestRecords[0])
                }
                setallPPL(resp.allPPls);
                setwithdraw_text(resp.PPL_data.withdraw_text);
                settext(resp.PPL_data.text);
                setdate(resp.PPL_data.date);
                setdata(resp);
                const arr = [];
                arr.push(resp.customers.ceo);
                arr.push(resp.customers.liasonOne);
                arr.push(resp.customers.liasonTwo);

            for(let i in arr){
                if(resp.PPL_data.to_name == `${arr[i][0]} ${arr[i][1]} ${arr[i][2]}`){
                    arr.splice(i, 1);
                }
            }
            resp.customers = arr;
            setcustomers(arr);
            setcompiled_client_details(resp.compiled_client_details);
            setclientname(resp.compiled_client_details.clientName);
            }
            
            
        }

        function runAfterDelay() {
        if (!hasRun) {
            localStorage.removeItem("alert")
            hasRun = true;
        }
        }

        func();
        setTimeout(runAfterDelay, 10000);

    },[document.getElementById("textarea1")])


    const update = async (event)=>{

        event.preventDefault();
        setalertopen(false);
        let longTermRating = null;
        let shortTermRating = null;
        let fiveYrRating = null;
        let outLook = null;
        let ratingAction = null;
        let ratingType = null;
        let address = null;
        let opTitle = null;
        let company = null;
        let pplId = null;

        if(PPL_data !== null){
            pplId = PPL_data.id
            longTermRating = og_ratingdata.longTermRating
            shortTermRating = og_ratingdata.shortTermRating
            fiveYrRating = og_ratingdata.fiveYrRating
            outLook = og_ratingdata.outLook
            ratingAction = og_ratingdata.LtAction
            ratingType = og_ratingdata.ratingType
            opTitle = PPL_data.opinion
            address = PPL_data.address
            company = PPL_data.company
            
        }

        const res = await axios.post(`${url}/api/updatePPL`,{
            "pplId": pplId,
            "recordId": og_rating_id,
            "to_name": document.getElementById("client1").value,
            "to_designation": document.getElementById("designation1").value,
            "company" : company,
            "opid": client_id,
            "longTermRating": longTermRating,
            "shortTermRating" : shortTermRating,
            "fiveYrRating" : fiveYrRating,
            "outLook" : outLook,
            "ratingAction" : ratingAction,
            "ratingType" : ratingType,
            "confidential" : "Confidential",
            "pplDate" : date,
            "ppl_text1" : withdraw_text,
            "ppl_text2" : text,
            "y_truly" : "Yours truly",
            "s_name" : 19,
            "s_designation" : "cro",
            "address" : address,
            "opTitle" : opTitle,
            "uh1": "Jhangeer Hanif",
        })
        
        if(res.data.status == true){
            let PPL = PPL_data;
            PPL.date = date;
            setPPL_data(PPL);
            setalertType("success");
            setAlertMessage("Successfully Updated");
            setalertopen(true);
        }
        else{

            setalertType("error");
            setAlertMessage("Some Error occure while Processing your request");
            setalertopen(true);
        }
    }

    const approve = async ()=>{
        if(rr_krd.count == 0 && PPL_data.action !== "Suspended" && PPL_data.action !== "Withdraw"){
            setalertType("error");
            setAlertMessage("RR and KRD is required before PPL Approval");
            setalertopen(true);
            return;
        }
        let pplId = null;

        if(PPL_data !== null){
            pplId = PPL_data.id
        }

        if(btnRef.current){
            btnRef.current.disabled = true;
            btnRef.current.innerHTML =  "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";
        }

        const res = await axios.post(`${url}/api/secondApproval`, {
            "pplId" : pplId,
            "recordId": og_rating_id,
            "pplDate" : date,
        })

        if(res.data.status == true && res.data.message == true){
            if(btnRef.current){
                btnRef.current.disabled = false;
                btnRef.current.innerHTML =  "Approve";
            }
            
            setalertType("success");
            setAlertMessage("Successfully Approved");
            setalertopen(true);
            document.getElementById("save").hidden = true
            document.getElementById("approve").hidden = true
        }
        else{
            if(btnRef.current){
                btnRef.current.disabled = false;
                btnRef.current.innerHTML =  "Approve";
            }
           
            setalertType("error");
            setAlertMessage("Some Error occure while Processing your request");
            setalertopen(true);
        }

        console.log(res);
    }

    const pdfview = async (pplid)=>{
        window.open(`/Ratings/pplview/${pplid}`);
    }

    const preventKeyboardInput = (e) => {
        e.preventDefault();
      };

  return (
    <div style={{height: "90vh"}}>
      {data !== null ? 
      <>
      <div className="col-11 mx-auto mt-3">
        <button className="btn btn-primary" onClick={()=>{window.open(`/edit-client-details/${clientId}`, '_blank')}}>Edit Client</button>
      </div>
      <form className='card shadow col-11 mx-auto p-2 border border-dark p-1 mb-3' style={{backgroundColor: "whitesmoke"}} onSubmit={update}>
      {message !== null && <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                <Collapse in={true}>
                    <Alert
                        severity={'success'}
                        action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setalertopen(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        sx={{ mb: 2 }}
                      >
                        {message}
                    </Alert>
                </Collapse>
      </Box>}
        <div className='row'>
            {props.screenWidth <= 576 && 
            <div className='col-12 text-wrap text-center fw-bold' style={{fontStyle : "12px"}}>
            {data !== null ? clientname : null}
            </div>}

            {props.screenWidth <= 576 && 
            <div className={'col-12 text-wrap text-center mb-1'} style={{fontSize : "10px"}}>
                {data !== null ? data.latestRecords.length > 0 ? 
                <>
                {PPL_data !== null ? data.latestRecords[0].rating_type_id == 3 ? <b>Mutual Fund Ratings</b> : <b>{PPL_data.rating_type} Ratings</b> : null} - {PPL_data !== null ? <b>{PPL_data.opinion_type}</b> : null}
                </>
                 : null : null}
            </div>}

            <div className={props.screenWidth >= 576 ? 'col-3' : 'col-12 mb-lg-0 mb-md-0 mb-sm-0 mb-1'}>
                <select className='form-select' id="client1" onChange={(e)=>{
                    const value = e.target.value;
                    let desig = document.getElementById('designation1');
                    if(value == `${compiled_client_details.ceo[0]} ${compiled_client_details.ceo[1]} ${compiled_client_details.ceo[2]}`){
                        if(desig !== null){
                            desig.value = `${compiled_client_details.ceo[3]}`;
                        }
                    }
                    if(value == `${compiled_client_details.liasonOne[0]} ${compiled_client_details.liasonOne[1]} ${compiled_client_details.liasonOne[2]}`){
                        if(desig !== null){
                            desig.value = `${compiled_client_details.liasonOne[3]}`;                            
                        }
                    }
                    if(value == `${compiled_client_details.liasonTwo[0]} ${compiled_client_details.liasonTwo[1]} ${compiled_client_details.liasonTwo[2]}`){
                        if(desig !== null){
                            desig.value = `${compiled_client_details.liasonTwo[3]}`;
                        }
                    }
                }}> {PPL_data !== null ? <option value={PPL_data.to_name}> {PPL_data.to_name} </option> : null}
                    {
                        customers.map((value, index)=>{
                            return(
                                <option value={`${value[0]} ${value[1]} ${value[2]}`}>{`${value[0]} ${value[1]} ${value[2]}`}</option>
                            );
                        })
                    }
                    
                </select>
            </div>
            {props.screenWidth > 576 && <div className='col-6 text-wrap text-center fs-6 fw-bold'>
                {data !== null ? clientname : null}
            </div> }
            {props.screenWidth > 576 && <div className='col-3'>
                <input className='form-control' value={PPL_data !== null ? PPL_data.confidential : null} disabled />
            </div>}
        </div>
        <div className='row mt-1'>
            <div className={props.screenWidth > 576 ? 'col-3' : 'col-12 mb-1'}>
                <select className='form-select' id="designation1">
                    {PPL_data !== null ? <option value={PPL_data.to_designation}> {PPL_data.to_designation} </option> : null}
                    {
                        customers.map((value, index)=>{
                            return(
                                <option value={value[3]}>{value[3]}</option>
                            );
                        })
                    }
                </select>
            </div>
            {props.screenWidth > 576 && <div className='col-6 text-wrap text-center fs-6'>
                {data !== null ? data.latestRecords.length > 0 ? 
                <>
                {PPL_data !== null ?  PPL_data.rating_type : null} - {PPL_data !== null ? PPL_data.opinion_type : null}
                </>
                 : null : null}
            </div> }
            <div className={props.screenWidth > 576 ? 'col-3' : 'col-12 mb-1'}>
                <input className='form-control' type='date' id="date" value={date} min={data.initiation_date} onKeyDown={preventKeyboardInput} onPaste={preventKeyboardInput} onChange={(e)=>{setdate(e.target.value)}} required/>
            </div>
            {props.screenWidth <= 576 && <div className={'col-12'}>
                <input className='form-control' value={'Confidential'} disabled />
            </div>}
        </div>
        <div className='col-lg-3 col-md-3 col-sm-6 col-12 mt-1'>
            <input className='form-control' value={PPL_data !== null ? PPL_data.company : null} disabled />
        </div>
        <div className='col-lg-3 col-md-3 col-sm-6 col-12 mt-1'>
            <input className='form-control' value={PPL_data !== null ? PPL_data.address : null} disabled />
        </div>
        <div className='mt-3'>
            <textarea className='form-control' id="textarea1" value={withdraw_text} onChange={(e)=>{setwithdraw_text(e.target.value)}} />
        </div>
        <div className='mt-3' style={{overflow: "auto"}}>

            <table className='table table-secondary newtable'>
                <tbody>
                    {data !== null ? data.latestRecords.length > 0 ?
                    data.latestRecords[0].ratingType == "BFR" ?
                    <tr>
                            <td className='col-4 fw-bold'>Opinion</td>
                            <td className='col-2 fw-bold'>Rating</td>
                            <td className='col-2 fw-bold'>Outlook</td>
                            <td className='col-2 fw-bold'>Action</td>
                            <td className='col-2 fw-bold'>Opinion Type</td>
                    </tr> :
                    data.latestRecords[0].ratingType == "Project Grading" ?
                        <tr>
                            <td className='col-4 fw-bold'>Opinion</td>
                            <td className='col-2 fw-bold'>Grading</td>
                            <td className='col-2 fw-bold'>Outlook</td>
                            <td className='col-2 fw-bold'>Action</td>
                            <td className='col-2 fw-bold'>Opinion Type</td>
                        </tr>:
                    data.latestRecords[0].ratingType == "Entity" || data.latestRecords[0].ratingType == "Debt Instrument" ?
                        <>
                        <tr>
                            <td className='col-2 fw-bold'>Opinion</td>
                            <td className='col-4 fw-bold text-center' colSpan="2">Ratings</td>
                            <td className='col-2 fw-bold'>Outlook</td>
                            <td className='col-2 fw-bold'>Action</td>
                            <td className='col-2 fw-bold'>Opinion Type</td>
                        </tr>
                        <tr>
                            <td className='col-2'></td>
                            <td className='col-2 fw-bold text-center'>Long Term</td>
                            <td className='col-2 fw-bold text-center'>Short Term</td>
                            <td className='col-2'></td>
                            <td className='col-2'></td>
                            <td className='col-2'></td>
                        </tr>
                        </> :
                        data.latestRecords[0].ratingType == "Performance Ranking" ?
                        <tr>
                            <td className='col-2 fw-bold'>Opinion</td>
                            <td className="col-1 fw-bold">1-Year Action</td>
                            <td className="col-1 fw-bold">3-Year Action</td>
                            <td className="col-1 fw-bold">5-Year Action</td>
                            <td className="col-1 fw-bold">1 year</td>
                            <td className="col-1 fw-bold">3 year</td>
                            <td className="col-1 fw-bold">5 year</td>
                            <td className='col-2 fw-bold'>Opinion Type</td>
                        </tr> :
                        data.latestRecords[0].ratingType == "IFS" ?
                        <tr>
                            <td className='col-2 fw-bold'>Opinion</td>
                            <td className='col-2 fw-bold'>IFS Rating</td>
                            <td className='col-2 fw-bold'>Outlook</td>
                            <td className='col-2 fw-bold'>Action</td>
                            <td className='col-2 fw-bold'>Opinion Type</td>
                        </tr> :
                        <tr>
                            <td className='col-4 fw-bold'>Opinion</td>
                            <td className='col-2 fw-bold'>Rating</td>
                            <td className='col-2 fw-bold'>Outlook</td>
                            <td className='col-2 fw-bold'>Action</td>
                            <td className='col-2 fw-bold'>Opinion Type</td>
                        </tr>
                         : null : null
                    }
                    {data !== null ? data.latestRecords.length > 0 ? 
                    data.latestRecords[0].ratingType == "BFR" ?
                        <tr>
                            <td className='col-4'>{PPL_data !== null ? PPL_data.opinion : null}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.long_term : null}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.outlook : null}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.action : null}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.rating_type : null}</td>
                        </tr> :
                    data.latestRecords[0].ratingType == "Project Grading" ?
                        <tr>
                            <td className='col-4'>{PPL_data !== null ? PPL_data.opinion : null}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.long_term : null}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.outlook : null}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.action : null}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.rating_type : null}</td>
                        </tr> :
                    data.latestRecords[0].ratingType == "Entity" || data.latestRecords[0].ratingType == "Debt Instrument" ?
                        <tr>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.opinion : null}</td>
                            <td className='col-2 text-center'>{PPL_data !== null ? PPL_data.long_term : null}</td>
                            <td className='col-2 text-center'>{PPL_data !== null ? PPL_data.short_term : null}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.outlook : null}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.action : null}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.rating_type : null}</td>
                        </tr> :
                    data.latestRecords[0].ratingType == "Performance Ranking" ?
                        <tr>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.opinion : null}</td>
                            <td className="col-1">{data.latestRecords[0].StAction}</td>
                            <td className="col-1">{data.latestRecords[0].LtAction}</td>
                            <td className="col-1">{data.latestRecords[0]['5yearAction']}</td>
                            <td className="col-1">{data.latestRecords[0].shortTermRating}</td>
                            <td className="col-1">{data.latestRecords[0].longTermRating}</td>
                            <td className="col-1">{data.latestRecords[0].fiveYrRating}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.rating_type : null}</td>
                        </tr> :
                        <tr>
                            <td className='col-4'>{PPL_data !== null ? PPL_data.opinion : null}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.long_term : null}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.outlook : null}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.action : null}</td>
                            <td className='col-2'>{PPL_data !== null ? PPL_data.rating_type : null}</td>
                        </tr>
                    :null : null}
                </tbody>
            </table>
        </div>
        <div className='mt-3'>
            <textarea className='form-control' id="textarea2" value={text} onChange={(e)=>{settext(e.target.value)}} />
        </div>
        <div className='row mt-3'>
            <div className='col-lg-3 col-md-3 col-sm-3 col-12 mb-1'>
                <input className='form-control' value={'Yours Truly'} disabled/>
            </div>
            <div className='col-6 col-md-6 col-sm-6 col-12 mb-1'>
                <div className='col-8 col-md-8 col-sm-8 col-12 mx-auto'>
                <select className='form-select' id="s_name">
                    <option value={PPL_data !== null ? PPL_data.s_id : null}>{PPL_data !== null ? PPL_data.s_name : null}</option>
                </select>
                </div>
            </div>
            <div className='col-3 col-md-3 col-sm-3 col-12 mb-1'>
                <select className='form-select' id="s_designation">
                <option value={PPL_data !== null ? PPL_data.s_designation : null}>{PPL_data !== null ? PPL_data.s_desig : null}</option>
                </select>
            </div>
        </div>
        <div>
            Encl:
        </div>
        <div className='row mt-3'>
            <div className='col-lg-3 col-md-3 col-3 col-12'>
                <FormGroup>
                    <FormControlLabel control={<Checkbox value={"1"} id="draftpr" defaultChecked />}  label="Draft Press Release" />
                </FormGroup>
            </div>
            {PPL_data !== null ? PPL_data.action !== 'Withdraw' && 
            <div className='col-lg-3 col-md-3 col-3 col-12'>
                <FormGroup>
                    <FormControlLabel control={<Checkbox value={"1"} id="rr" defaultChecked />}  label="Draft Rating Report" />
                </FormGroup>
            </div> : null}
        </div>
        <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                        severity={alertType}
                        action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setalertopen(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        sx={{ mb: 2 }}
                      >
                        {AlertMessage}
                    </Alert>
                </Collapse>
          </Box>
        <div className="d-flex">
            <div className='mx-auto mt-3' style={{width: "fit-content"}}>
                {PPL_data !== null && data !== null ? PPL_data.second_approve !== 'Approved' && data.can_approve_ppl == 1 && PPL_data.date == currentDate() ? <button className='btn btn-success me-1 btn-sm mt-1 me-1' type='button' id="approve" ref={btnRef} onClick={()=>{approve()}}> Approve </button> : null : null}
                {PPL_data !== null ? PPL_data.second_approve !== 'Approved' ? <button className='btn btn-primary btn-sm me-1 mt-1' id="save" type='submit'> Update </button> : null : null}
                {data !== null ? data.RC_count > 1 ?
                 <button className='btn btn-primary btn-sm ms-1 mt-1' id="postappealPPl" type="button" onClick={()=>{
                    if(PPL_data.second_approve !== 'Approved'){
                        setalertType("error");
                        setAlertMessage("PPL approval is required!");
                        setalertopen(true);
                    }
                    else{
                        Navigate(`/Ratings/generate_ppl/${client_id}/${og_rating_id}`);
                    }
                }}> Generate Post Appeal PPL </button> : null: null}
                {data !== null ? PPL_data !== null ? <>
                    {
                        allPPL.map((value, index)=>{
                            return(
                                <button className='btn btn-primary ms-1 btn-sm mt-1' type='button' onClick={()=>{pdfview(value.id)}}> Print View({DateFormat(value.date)}) </button>
                            )
                        })
                    }
                </> : <button className="btn btn-primary ms-1 btn-sm mt-1" type="button" onClick={() => window.open(`https://209.97.168.200/wizpacv1/w_q/ppl/view_ppl_v2.php?id=${client_id}&record_id=${og_rating_id}`, '_blank')}> View PPL </button> : null}
            </div>
        </div>
      </form>
      </>
       : 
      (<div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
      <div class="wrapper" id="loader2">
        <svg
          class="hourglass"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 120 206"
          preserveAspectRatio="none"
        >
          <path
            class="middle"
            d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
          />
          <path
            class="outer"
            d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
          />
        </svg>
      </div>
    </div>)}
      <br/> <br />
    </div>
  )
}
