import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../../../Config';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";


export default function Jcr_client(props) {

    const [rating_agency, setrating_agency] = useState([]);
    const [rating_type, setrating_type] = useState([]);
    const [rating_scale, setrating_scale] = useState([]);
    const [relationship, setrelationship] = useState([]);
    const [segment, setsegment] = useState([]);
    const [Main_sector, setMain_sector] = useState([]);
    const [sector, setsector] = useState([]);
    const [fundManager, setfundManager] = useState([]);
    const [fundType, setfundType] = useState([]);
    const [instrumentType, setinstrumentType] = useState([]);
    const [issuerEntry, setissuerEntry] = useState([]);
    const [rating_agency_val, setrating_agency_val] = useState('');
    const [rating_type_val, setrating_type_val] = useState('');
    const [rating_scale_val, setrating_scale_val] = useState('');
    const [relationship_val, setrelationship_val] = useState('');
    const [segment_val, setsegment_val] = useState('');
    const [Main_sector_val, setMain_sector_val] = useState('');
    const [sector_val, setsector_val] = useState('');
    const [fundManager_val, setfundManager_val] = useState('');
    const [fundType_val, setfundType_val] = useState('');
    const [instrumentType_val, setinstrumentType_val] = useState('');
    const [issuerEntry_val, setissuerEntry_val] = useState('');
    const [dualClient_val, setdualClient_val] = useState('0')

    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();

    const fundManagerRef = useRef(null);
    const fundTypeRef = useRef(null);
    const instTenorRef = useRef(null);
    const instTyperRef = useRef(null);
    const instAmountRef = useRef(null);
    const instIssueDateRef = useRef(null);
    const segmentRef = useRef(null);
    const mainSectorRef = useRef(null);
    const sectorRef = useRef(null);
    const inst_issuer_entityRef = useRef(null);

    const entityRef = useRef(null);
    const instrumentTenorRef = useRef(null);
    const PlannedTenorRef = useRef(null);
    const InstrumentIssueRef = useRef(null);
    const btnRef = useRef(null);

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/jcr_client`);
            const resp = res.data
            setrating_type(resp.rating_type);
            setrelationship(resp.relationships);
            setsegment(resp.segments);
            setfundManager(resp.fund_managers);
            setfundType(resp.fund_types);
            setinstrumentType(resp.instrument_types);
            setissuerEntry(resp.companies);
            setrating_agency(resp.ratingAgencies);


        }

        func();
    },[])

    const submitForm = async (event)=>{

        event.preventDefault();
        setalertOpen(false);

        if(rating_type_val == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Rating Type First!`);
            return ;
        }
        if(rating_scale_val == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Rating Scale First!`);
            return ;
        }

        if(rating_scale_val == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Rating Scale First!`);
            return ;
        }

        btnRef.current.disabled = true;

        const res = await axios.post(`${url}/api/addjcr_client`, {
            'client_name': entityRef.current.value,
            'rating_agency_id': rating_agency_val,
            'dual_client': dualClient_val,
            'rating_type_id': rating_type_val,
            'rating_scale_id': rating_scale_val,
            'relationship_id': relationship_val,
            'segment_id': segment_val,
            'main_sector_id': Main_sector_val,
            'sector_id': sector_val,
            'instrument_tenure': instrumentTenorRef.current.value,
            'instrument_type_id': instrumentType_val,
            'instrument_issuedate': InstrumentIssueRef.current.value
        });

        const resp = res.data;
        if(resp.status == true){
            btnRef.current.disabled = false;
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btnRef.current.disabled = false;
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
    }

  return (
    <div>
        <div className='fs-4 fw-bold text-center'>
            VIS Client Entry
        </div>
        <form className='mt-2 card shadow col-10 mx-auto p-2' onSubmit={(event)=>{submitForm(event)}}>
            <div className='fs-6 fw-bold mt-2'>
                Entity
            </div>
            <div className='mt-1'>
                <input className='form-control' ref={entityRef} />
            </div>
            <div className='fs-6 fw-bold mt-2'>
                Dual Client
            </div>
            <div className='mt-1'>
                <select className='form-select' value={dualClient_val} onChange={(e)=>{setdualClient_val(e.target.value)}}>
                    <option value={'0'}>No</option>
                    <option value={'1'}>Yes</option>
                </select>
            </div>
            <div className='fs-6 fw-bold mt-2'>
                Rating Agency
            </div>
            <div className='mt-1'>
                <select className='form-select' value={rating_agency_val} onChange={(e)=>{setrating_agency_val(e.target.value)}}>
                    <option>Select Rating Agency</option>
                    {
                        rating_agency.map((value, index)=>{
                            return(
                                <option value={value.ID}>{value.title}</option>
                            )
                        })
                    }

                </select>
            </div>
            <div className='fs-6 fw-bold mt-2'>
                Rating Type
            </div>
            <div className='mt-1'>
                <select className='form-select' value={rating_type_val} onChange={(e)=>{
                    if(Number(e.target.value) == 1 || Number(e.target.value) == 2){
                        fundManagerRef.current.hidden = true;
                        fundTypeRef.current.hidden = true;
                        instTenorRef.current.hidden = true;
                        instTyperRef.current.hidden = true;
                        instAmountRef.current.hidden = true;
                        instIssueDateRef.current.hidden = true;
                        segmentRef.current.hidden = false;
                        mainSectorRef.current.hidden = false;
                        sectorRef.current.hidden = false;
                        inst_issuer_entityRef.current.hidden = true;

                        setfundManager_val('');
                        setfundType_val('');
                        setinstrumentType_val('');
                        instTenorRef.current.value = null;
                        instAmountRef.current.value = null;
                        instIssueDateRef.current.value = '';
                        setsegment_val('');
                        setMain_sector_val('');
                        setsector_val('');
                        setissuerEntry_val('');

                    }
                    else if(Number(e.target.value) == 3){
                        fundManagerRef.current.hidden = false;
                        fundTypeRef.current.hidden = false;
                        instTenorRef.current.hidden = true;
                        instTyperRef.current.hidden = true;
                        instAmountRef.current.hidden = true;
                        instIssueDateRef.current.hidden = true;
                        segmentRef.current.hidden = true;
                        mainSectorRef.current.hidden = true;
                        sectorRef.current.hidden = true;
                        inst_issuer_entityRef.current.hidden = true;

                        setinstrumentType_val('');
                        instTenorRef.current.value = null;
                        instAmountRef.current.value = null;
                        instIssueDateRef.current.value = '';
                        setsegment_val('');
                        setMain_sector_val('');
                        setsector_val('');
                        setissuerEntry_val('');

                    }
                    else if(Number(e.target.value) == 4){
                        fundManagerRef.current.hidden = true;
                        fundTypeRef.current.hidden = true;
                        instTenorRef.current.hidden = false;
                        instTyperRef.current.hidden = false;
                        instAmountRef.current.hidden = false;
                        instIssueDateRef.current.hidden = false;
                        segmentRef.current.hidden = false;
                        mainSectorRef.current.hidden = false;
                        sectorRef.current.hidden = false;
                        inst_issuer_entityRef.current.hidden = false;

                        setfundManager_val('');
                        setfundType_val('');

                    }
                    else{
                        fundManagerRef.current.hidden = true;
                        fundTypeRef.current.hidden = true;
                        instTenorRef.current.hidden = true;
                        instTyperRef.current.hidden = true;
                        instAmountRef.current.hidden = true;
                        instIssueDateRef.current.hidden = true;
                        segmentRef.current.hidden = false;
                        mainSectorRef.current.hidden = false;
                        sectorRef.current.hidden = false;
                        inst_issuer_entityRef.current.hidden = true;

                        setinstrumentType_val('');
                        instTenorRef.current.value = null;
                        instAmountRef.current.value = null;
                        instIssueDateRef.current.value = '';
                        setsegment_val('');
                        setissuerEntry_val('');

                    }

                    if(e.target.value !== ''){
                        for(let i in rating_type){
                            if(Number(e.target.value) == rating_type[i].id){
                                setrating_scale(rating_type[i].rating_scale);
                            }
                        }
                        setrating_type_val(e.target.value);
                    }
                    else{
                        setrating_type_val(e.target.value);
                        setrating_scale([]);
                        setrating_scale_val('');
                    }

                }}>
                    <option value={""}>Select Rating Type</option>
                    {
                        rating_type.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.title}</option>
                            )
                        })
                    }
                </select>
            </div>
            <div className='fs-6 fw-bold mt-2'>
                Rating Scale
            </div>
            <div className='mt-1'>
                <select className='form-select' value={rating_scale_val} onChange={(e)=>{
                    setrating_scale_val(e.target.value);
                }}>
                    <option value={''}>Select Rating Scale</option>
                    {
                        rating_scale.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.title}</option>
                            )
                        })
                    }
                </select>
            </div>
            <div className='fs-6 fw-bold mt-2'>
                Relationship
            </div>
            <div className='mt-1'>
                <select className='form-select' value={relationship_val} onChange={(e)=>{setrelationship_val(e.target.value)}}>
                    <option value={''}>Select Relationship</option>
                    {
                        relationship.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.title}</option>
                            )
                        })
                    }
                </select>
            </div>
            <div ref={segmentRef}>
                <div className='fs-6 fw-bold mt-2'>
                    Segment
                </div>
                <div className='mt-1'>
                    <select className='form-select' value={segment_val} onChange={(e)=>{
                        if(e.target.value !== ''){
                            setsegment_val(e.target.value);
                            for(let i in segment){
                                if(Number(e.target.value) == segment[i].id){
                                    setMain_sector(segment[i].main_sector);
                                }
                            }
                        }
                        else{
                            setsegment_val(e.target.value);
                            setMain_sector([]);
                            setsector([]);
                        }

                    }}>
                        <option value={''}>Select Segment</option>
                        {
                            segment.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div ref={mainSectorRef}>
                <div className='fs-6 fw-bold mt-2'>
                    Main Sector
                </div>
                <div className='mt-1'>
                    <select className='form-select' value={Main_sector_val} onChange={(e)=>{
                       if(e.target.value !== ''){
                        setMain_sector_val(e.target.value);
                        for(let i in Main_sector){
                            if(Number(e.target.value) == Main_sector[i].id){
                                setsector(Main_sector[i].sector);
                            }
                        }
                       }
                       else{
                        setMain_sector_val(e.target.value)
                        setsector([])
                       } 
                    }}>
                        <option value={''}>Select Main Sector</option>
                        {
                            Main_sector.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div ref={sectorRef}>
                <div className='fs-6 fw-bold mt-2'>
                    Sector
                </div>
                <div className='mt-1'>
                    <select className='form-select' value={sector_val} onChange={(e)=>{setsector_val(e.target.value)}}>
                        <option value={''}>Select Sector</option>
                        {
                            sector.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div ref={fundManagerRef}>
                <div className='fs-6 fw-bold mt-2'>
                    Fund Manager
                </div>
                <div className='mt-1'>
                    <select className='form-select' value={fundManager_val} onChange={(e)=>{setfundManager_val(e.target.value)}}>
                        <option value={''}>Sector Fund Manager</option>
                        {
                            fundManager.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div ref={fundTypeRef}>
                <div className='fs-6 fw-bold mt-2'>
                    Fund Type
                </div>
                <div className='mt-1'>
                    <select className='form-select' value={fundType_val} onChange={(e)=>{setfundType_val(e.target.value)}}>
                        <option value={''}>Sector Fund Type</option>
                        {
                            fundType.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div ref={instTyperRef}>
                <div className='fs-6 fw-bold mt-2'>
                    Instrument Type
                </div>
                <div className='mt-1'>
                    <select className='form-select' value={instrumentType_val} onChange={(e)=>{setinstrumentType_val(e.target.value)}}>
                        <option value={''}>Select Instrument Type</option>
                        {
                            instrumentType.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div ref={inst_issuer_entityRef}>
                <div className='fs-6 fw-bold mt-2'>
                    Issuer Entity
                </div>
                <div className='mt-1'>
                    <select className='form-select'>
                        <option value={''}>Select Issuer Entity</option>
                        {
                            issuerEntry.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div ref={instTenorRef}>
                <div className='fs-6 fw-bold mt-2'>
                    Instrument Tenor (Years)
                </div>
                <div className='mt-1'>
                    <input className='form-control' ref={instrumentTenorRef} />
                </div>
            </div>
            <div ref={instAmountRef}>
                <div className='fs-6 fw-bold mt-2'>
                    Planned Amount (M)
                </div>
                <div className='mt-1'>
                    <input className='form-control' ref={PlannedTenorRef} />
                </div>
            </div>
            <div ref={instIssueDateRef}>
                <div className='fs-6 fw-bold mt-2'>
                    Instrument Issue Date
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='date' ref={InstrumentIssueRef}/>
                </div>
            </div>
            <Box className="mx-auto" sx={{ width: "98%" }}>
                        <Collapse in={alertopen}>
                            <Alert
                            severity={alertType}
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setalertOpen(false);
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            >
                            {AlertMessage}
                            </Alert>
                        </Collapse>
            </Box>
            <div className='mt-2'>
                <button className='btn btn-primary' ref={btnRef}>Save</button>
            </div>
        </form>
        
    </div>
  )
}
