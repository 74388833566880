import React, {useState, useEffect, useRef} from 'react';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import axios from 'axios';
import { url } from '../../../../Config';
import { Link, useNavigate } from 'react-router-dom';


export default function Add_new_client(props) {
    const id = localStorage.getItem("userID");
    const Navigate = useNavigate();
    const [group, setgroup] = useState([]);
    const [industries, setindustries] = useState([]);
    const [sectors, setsectors] = useState([]);
    const [clientgroups, setclientgroups] = useState([]);
    const [cities, setcities] = useState([]);
    const [legalstatuses, setlegalstatuses] = useState([]);
    const [groupval, setgroupval] = useState('');
    const [industriesval, setindustriesval] = useState('');
    const [sectorsval, setsectorsval] = useState('');
    const [clientgroupsval, setclientgroupsval] = useState('');
    const [citiesval, setcitiesval] = useState('');
    const [legalstatusesval, setlegalstatusesval] = useState('');
    const [ceo_respect, setceo_respect] = useState('Mr.');
    const [Liaison1respect, setLiaison1respect] = useState('Mr.');
    const [Liaison2respect, setLiaison2respect] = useState('Mr.');
    const [ceo_desig, setceo_desig] = useState('MD');
    const [Liaison1desig, setLiaison1desig] = useState(null);
    const [Liaison2desig, setLiaison2desig] = useState(null);
    const [ceo_tier, setceo_tier] = useState('Tier-I');
    const [Liaison1tier, setLiaison1tier] = useState('Tier-I');
    const [Liaison2tier, setLiaison2tier] = useState('Tier-I');
       
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();

    const nameref = useRef(null);
    const addressRef = useRef(null);
    const date1Ref = useRef(null);
    const date2Ref = useRef(null);
    const date3Ref = useRef(null);
    const ceo_F = useRef(null);
    const Ceo_S = useRef(null);
    const ceo_office_no = useRef(null);
    const ceo_cell = useRef(null);
    const ceo_email = useRef(null);
    const L1_F = useRef(null);
    const L1_S = useRef(null);
    const L1_office_no = useRef(null);
    const L1_cell = useRef(null);
    const L1_email = useRef(null);
    const L2_F = useRef(null);
    const L2_S = useRef(null);
    const L2_office_no = useRef(null);
    const L2_cell = useRef(null);
    const L2_email = useRef(null);



    const btnref = useRef(null);

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/get_add_new_client`);
            const resp = res.data;
            setgroup(resp.groups)
            setclientgroups(resp.clientgroups)
            setcities(resp.cities)
            setlegalstatuses(resp.legalstatuses)

        }

        func();
    },[])


    const submitform = async (event)=>{

        event.preventDefault();


        if(groupval == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Group First!`);
            return ;
        }
        if(industriesval == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Industry First!`);
            return ;
        }
        if(sectorsval == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Sector First!`);
            return ;
        }
        if(clientgroupsval == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Group Name First!`);
            return ;
        }
        if(citiesval == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Headoffice First!`);
            return ;
        }
        if(legalstatusesval == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Legal Status First!`);
            return ;
        }

        btnref.current.disabled = true;

        const res = await axios.post(`${url}/api/addClientInfo`,{
            'user_id': id,
            'clientName': nameref.current.value,
            'group': Number(groupval),
            'industry': Number(industriesval),
            'sector': Number(sectorsval),
            'groupname': Number(clientgroupsval),
            'headoffice': Number(citiesval),
            'legalstatus': Number(legalstatusesval),
            'commercialOperationsDate': date3Ref.current.value,
            'listingDate': date2Ref.current.value,
            'incorporationDate': date1Ref.current.value,
            'address': addressRef.current.value,
            'ceo_name_title': ceo_respect,
            'ceoFirstName': ceo_F.current.value,
            'ceoSecondName': Ceo_S.current.value,
            'ceo_desig': ceo_desig,
            'ceo_office_no': ceo_office_no.current.value,
            'ceo_cell_no': ceo_cell.current.value,
            'ceo_email': ceo_email.current.value,
            'ceo_tier': ceo_tier,
            'liaison_one_title': Liaison1respect,
            'liaison_one_first_name': L1_F.current.value,
            'liaison_one_second_name': L1_S.current.value,
            'liaison_one_desig': Liaison1desig,
            'liaison_one_office_no': L1_office_no.current.value,
            'liaison_one_cell_no': L1_cell.current.value,
            'liaison_one_email': L1_email.current.value,
            'liaison_one_tier': Liaison1tier,
            'liaison_two_title': Liaison2respect,
            'liaison_two_first_name': L2_F.current.value,
            'liaison_two_second_name': L2_S.current.value,
            'liaison_two_desig': Liaison2desig,
            'liaison_two_office_no': L2_office_no.current.value,
            'liaison_two_cell_no': L2_cell.current.value,
            'liaison_two_email': L2_email.current.value,
            'liaison_two_tier': Liaison2tier,
        })

        const resp = res.data;
        if(resp.status == true){
            btnref.current.disabled = false;
            Navigate(`/client-entry-view`);
        }
        else{
            btnref.current.disabled = false;
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
   
    }

  return (
    <div>
      <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            New Client Entry
        </div>
        <form className='col-10 card shadow p-3 mx-auto mt-lg-4 mt-md-4 mt-2' onSubmit={(event)=>{submitform(event)}}>
            <div>
                <div className='mt-2 fw-bold'>
                    Name:
                </div>
                <div className='mt-2'>
                    <input className='form-control' ref={nameref} required />
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Group:
                </div>
                <div className='mt-2'>
                    <select className='form-select' value={groupval} onChange={(e)=>{
                        setgroupval(e.target.value);
                        if(e.target.value == ''){
                            setsectors([]);
                            setindustries([]);
                        }
                        else{
                            for(let i in group){
                                if(group[i].id == e.target.value){
                                    setindustries(group[i].industries);
                                }
                            }
                        }
                        }
                        
                        }>
                        <option value={''}>Select Group</option>
                        {
                            group.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Industry:
                </div>
                <div className='mt-2'>
                    <select className='form-select' value={industriesval} onChange={(e)=>{
                        setindustriesval(e.target.value)
                        if(e.target.value == ''){
                            setsectors([]);
                        }
                        else{
                            for(let i in industries){
                                if(industries[i].id == e.target.value){
                                    setsectors(industries[i].sectors);
                                }
                            }
                        }
                        }}>
                        <option value={''}>Select Industry</option>
                        {
                            industries.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Sector:
                </div>
                <div className='mt-2'>
                    <select className='form-select' value={sectorsval} onChange={(e)=>{setsectorsval(e.target.value)}}>
                        <option value={''}>Select Sector</option>
                        {
                            sectors.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Group Name:
                </div>
                <div className='mt-2'>
                    <select className='form-select' value={clientgroupsval} onChange={(e)=>{setclientgroupsval(e.target.value)}}>
                        <option value={''}>Select Group Name</option>
                        {
                            clientgroups.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Head Office:
                </div>
                <div className='mt-2'>
                    <select className='form-select' value={citiesval} onChange={(e)=>{setcitiesval(e.target.value)}}>
                        <option value={''}>Select Head Office</option>
                        {
                            cities.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.city}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Legal Status:
                </div>
                <div className='mt-2'>
                    <select className='form-select' value={legalstatusesval} onChange={(e)=>{setlegalstatusesval(e.target.value)}}>
                        <option value={''}>Select Head Office</option>
                        {
                            legalstatuses.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Address:
                </div>
                <div className='mt-2'>
                    <input className='form-control' ref={addressRef} required/>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Incorporation Date:
                </div>
                <div className='mt-2'>
                    <input className='form-control' type='date' ref={date1Ref} required/>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Listing Date:
                </div>
                <div className='mt-2'>
                    <input className='form-control' type='date' ref={date2Ref}/>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Commercial Operations Date:
                </div>
                <div className='mt-2'>
                    <input className='form-control' type='date' ref={date3Ref}/>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    CEO:
                </div>
                <div className='mt-2'>
                    <select className='form-control' value={ceo_respect} onChange={(e)=>{setceo_respect(e.target.value)}}>
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="">-</option>
                    </select>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='First Name' ref={ceo_F} required/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='Second Name' ref={Ceo_S} required/>
                </div>
                <div className='mt-1'>
                    <select className='form-control' value={ceo_desig} onChange={(e)=>{setceo_desig(e.target.value)}}>
                        <option value='MD'>MD</option>
                        <option value='CEO'>CEO</option>
                        <option value='President'>President</option>
                        <option value='Chairman'>Chairman</option>
                        <option value='President & CEO'>President & CEO</option>
                    </select>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='Office No' ref={ceo_office_no}/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='Cell No' ref={ceo_cell}/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' placeholder='Email' ref={ceo_email}/>
                </div>
                <div className='mt-1'>
                    <select className='form-control' value={ceo_tier} onChange={(e)=>{setceo_tier(e.target.value)}}>
                        <option value="Tier-I">Tier-I</option>
                        <option value="Tier-II">Tier-II</option>
                        <option value="Tier-III">Tier-III</option>
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Liaison Person 1:
                </div>
                <div className='mt-2'>
                    <select className='form-control' value={Liaison1respect} onChange={(e)=>{setLiaison1respect(e.target.value)}}>
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="">-</option>
                    </select>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='First Name' ref={L1_F} required/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='Second Name' ref={L1_S} required/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' value={Liaison1desig} onChange={(e)=>{setLiaison1desig(e.target.value)}} placeholder='Designation' />
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' ref={L1_office_no} placeholder='Office No'/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' ref={L1_cell} placeholder='Cell No'/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' ref={L1_email} placeholder='Email'/>
                </div>
                <div className='mt-1'>
                    <select className='form-control' value={Liaison1tier} onChange={(e)=>{setLiaison1tier(e.target.value)}}>
                        <option value="Tier-I">Tier-I</option>
                        <option value="Tier-II">Tier-II</option>
                        <option value="Tier-III">Tier-III</option>
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Liaison Person 2:
                </div>
                <div className='mt-2'>
                    <select className='form-control' value={Liaison2respect} onChange={(e)=>{setLiaison2respect(e.target.value)}}>
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="">-</option>
                    </select>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='First Name' ref={L2_F} required/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' placeholder='Second Name' ref={L2_S} required/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' value={Liaison2desig} onChange={(e)=>{setLiaison2desig(e.target.value)}} placeholder='Designation' />
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' ref={L2_office_no} placeholder='Office No'/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' type='text' ref={L2_cell} placeholder='Cell No'/>
                </div>
                <div className='mt-1'>
                    <input className='form-control' ref={L2_email} placeholder='Email'/>
                </div>
                <div className='mt-1'>
                    <select className='form-control' value={Liaison2tier} onChange={(e)=>{setLiaison2tier(e.target.value)}}>
                        <option value="Tier-I">Tier-I</option>
                        <option value="Tier-II">Tier-II</option>
                        <option value="Tier-III">Tier-III</option>
                    </select>
                </div>
            </div>
            <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                    severity={alertType}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setalertOpen(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                    >
                    {AlertMessage}
                    </Alert>
                </Collapse>
            </Box>
            <div className='mt-4'>
                <button className='btn btn-primary' ref={btnref} type='submit'>Submit</button>
            </div>
        </form>
    </div>
  )
}
