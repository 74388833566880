import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { url } from "../../../../../Config";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { NavLink } from "react-router-dom";
import { Check, Clear, Event } from "@material-ui/icons";
import { Table, TableCell, TableRow } from "@mui/material";
import TableComponent from "../../../../Atoms/TableComponent";


export default function Sp_status(props) {

    const [data, setdata] = useState([]);

    useEffect(()=>{

        const func = async ()=>{
            const res = await axios.get(`${url}/api/sp_status`);
            const resp = res.data;
            setdata(resp);
        }

        func();

    },[])
    
    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 210;
        }
      };
      
      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };

      const preventKeyboardInput = (e) => {
        e.preventDefault();
      };
    
    
     const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
      const sectorHide = () => {
        if (props.screenWidth > 500) {
          return false;
        } else {
          return true;
        }
      };
    
      function innerInitiDate(params) {
        if (params.data.rating_initiation_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.rating_initiation_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
    
    
      function innerFileDate(params) {
        if (params.data.date == null) {
          return "-";
        } else {
          const date = new Date(params.data.date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };

      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          field: "sNo",
          // minWidth: 66,
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Opinion",
          minWidth: responsiveColumns(),
          // comparator: customComparator,
          field: "opinion_name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          filterParams: {
            caseSensitive: false,
          },
          cellRenderer: cellrandered,
          // cellRenderer: cellrandered,
          tooltipField: "opinion_name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Analyst",
          hide: columnHide(),
          field: "analyst_name",
          minWidth: 100,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrandered,
        },
        {
          headerName: "Team Lead",
          hide: columnHide(),
          field: "teamlead_name",
          minWidth: 100,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrandered,
        },
        {
          headerName: "Supporting Analyst",
          hide: columnHide(),
          field: "support_analyst",
          minWidth: 100,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrandered,
        },
        {
          headerName: "Supporting Analyst TL",
          hide: columnHide(),
          field: "support_analyst_tl",
          minWidth: 100,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrandered,
        },
        {
          headerName: "Stage",
          field: "status",
          minWidth: 300,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: (params)=>{
            if(params.value == 'Entered'){
                return(
                    <div>Requested by Team Lead | Yet to be Approved By CRO</div>
                )
            }
            else if(params.value == 'CRO Approved'){
                return(
                    <div>Approved By CRO | Analyst yet to be Assigned By RM</div>
                )
            }
            else if(params.value == "CRO Declined"){
                return(
                    <div>Declined By CRO | Process Ended</div>
                )
            }
            else if(params.data.end == 1){
                return(
                    <div>Process Ended</div>
                )
            }
            else if(params.value == "Analyst Assigned" && params.data.support_analyst_tl_approval == null){
                return(
                    <div>Supporting Analyst Assigned By RM | Yet to be Approved by his/her Team Lead</div>
                )
            }
            else if(params.value == "Analyst Assigned" && params.data.support_analyst_tl_approval == "Approved"){
                return(
                    <div>Supporting Analyst Assigned </div>
                )
            }
            else if(params.value == "Analyst Assigned" && params.data.support_analyst_tl_approval == "Declined"){
                return(
                    <div>{`TL has declined request | Process revert back to RM`}</div>
                )
            }
          },
        },
        
    
        ]

        const MobViewRender = (params) => (
            <h1 style={{ padding: "10px 20px" }}>
              <Table className="overflow-scroll responsiveTableFonts">
                <TableRow>
                  <TableCell
                    variant="head"
                    className="fw-bolder responsiveTableFonts py-1"
                  >
                    Team Lead
                  </TableCell>
                  <TableCell className="responsiveTableFonts py-1">
                    {params.data.teamlead_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    variant="head"
                    className="fw-bolder responsiveTableFonts py-1"
                  >
                    Analyst
                  </TableCell>
                  <TableCell className="responsiveTableFonts py-1">
                    {params.data.analyst_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    variant="head"
                    className="fw-bolder responsiveTableFonts py-1"
                  >
                    Supporting Analyst
                  </TableCell>
                  <TableCell className="responsiveTableFonts py-1">
                    {params.data.supporting_tl_name == null ? 'Not yet Assigned' : params.data.supporting_tl_name}
                  </TableCell>
                </TableRow>

              </Table>
            </h1>
          );

  return (
    <div>
       <TableComponent
        value={"SP_status"}
        Data={data}
        screenWidth={props.screenWidth}
        MobViewRender={MobViewRender}
        columnDefs={columnDefs}
        datefilter={false}
        />
    </div>
  );
  
}
