import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from "axios";
import "../../../index.css";
import error from "../../../Assets/Images/error_icon.png"
import { url } from "../../../Config";
import { formatDatefunc } from "../../../Config";



export default function SecpPack() {
    const [startdate, setstartdate] = useState();
    const [enddate, setenddate] = useState();
  
  
    const func = async () => {
        if (startdate == null || startdate == "" || enddate == null || enddate == "") {
          document.getElementById("loading_message").hidden = false
          document.getElementById("loading_message").innerHTML = "Invalid Dates!"
          }
        else{

            if(startdate < enddate){
                // document.getElementById("loading_message").innerHTML = "SECP Pack take some time to load PDF, Please wait while PDF is loading"
                // document.getElementById("error").hidden = true;
                // document.getElementById("loading_message").hidden = false
                // document.getElementById("loader2").hidden = false
                // axios({
                //   url: `https://209.97.168.200/pacrawizpackv3/public/api/secpindex/${startdate}/${enddate}`,
                //   method: 'Get',
                //   responseType: 'blob',
                // }).then(response => {
                //   console.log(response, "response");
                //   const file = new Blob([response.data], { type: 'application/pdf' })
                //   const fileURL = URL.createObjectURL(file)
                //   window.open(fileURL)
                //   document.getElementById("loading_message").hidden = true
                // document.getElementById("loader2").hidden = true
                // }).catch(
                //   error => {
                //     console.error(error)
                //     document.getElementById("loading_message").innerHTML = "An Error occure while loading PDF. Please try again or contact MIT"
                //     document.getElementById("loader2").hidden = true
                //     document.getElementById("error").hidden = false;
                //   }
                //   )
                window.open(`https://209.97.168.200/pacrawizpackv3/public/api/secpindex/${startdate}/${enddate}`, '_blank');
            }
            else{
              alert("startdate should be smaller than enddate")
            }
          }
        }
  
    return (
      <>
      <div>
        <p className="mx-auto p-3 fs-3 fw-bold text-capitalize" style={{ width: "fit-content", color:"#000078" }}>
            SECP Pack
        </p>      
        <div className="row w-100">
          <div
            className="card shadow mx-auto col-lg-10 col-md-10 col-sm-10 col-11 pt-4"
            style={{ height: "auto", backgroundColor: "#e0e0e0" }}
          >
  
            <div className="row w-100 pb-3">
                <div className="col-lg-5 col-md-5 ms-lg-4 ms-md-2 ms-sm-3 col-sm-11 col-11 ms-2">
                <input
                  type="date"
                  style={{height: "38px"}}
                  className="form-control"
                  onChange={(e) => {
                    setstartdate(e.target.value);
                  }}
                />
                </div>
                <div className="col-lg-5 col-md-5 ms-md-1 pt-sm-1 pt-md-0 col-sm-11 ms-sm-3 col-11 ms-2 pt-1">
                <input
                  type="date"
                  style={{height: "38px"}}
                  className="form-control"
                  onChange={(e) => {
                    setenddate(e.target.value);
                  }}
                />
                </div>
                <div className="col-lg-1 col-md-1 col-sm-1 pt-sm-1 pt-md-0 ms-md-1 mx-sm-auto pt-1 mx-auto" style={{width: "fit-content"}}>
                <button type="button" id="btn" className="btn btn-primary h-60" onClick={func}>
                Submit
              </button>
                </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div className="mt-2 mx-auto fw-bold fs-5" id="loading_message" hidden={true} style={{width: "fit-content", color:"#000078" }}>
      </div>
      <div class="wrapper" id="loader2" hidden={true}>
        <svg class="hourglass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 206" preserveAspectRatio="none">
            <path class="middle" d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"/>
            <path class="outer" d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"/>
        </svg>
      </div>
      <div className="col-12" hidden={true} id="error">
        <center>
          <img className="col-1" src={error}/>
        </center>
      </div>
      </>
    );
}
