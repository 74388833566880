import React, {useState, useEffect, useRef} from 'react';
import { v4 as uuidv4 } from "uuid";
import axios from 'axios'
import { url } from '../../../../Config';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from '@mui/icons-material/Save';
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';

export default function EditModels(props) {
    const { id } = useParams();
    const [tasks, setTasks] = useState([{id: uuidv4()}]);
    const [model_name, setmodel_name] = useState('');
    const [heading_name, setheading_name] = useState([]);
    const [subheading_name, setsubheading_name] = useState([]);
    const [heading_name1, setheading_name1] = useState(null)
    const [mainheading, setmainheading] = useState([]);
    const [mainheadingarr, setmainheadingarr] = useState([]);
    const [subheading, setsubheading] = useState([]);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [alertopen1, setalertOpen1] = useState(false);
    const [alertType1, setalertType1] = useState();
    const [AlertMessage1, setalertmessage1] = useState();
    const [addsubheadinginput, setaddsubheadinginput] = useState([]);
    const [data, setdata] = useState(null);
    const [bool, setbool] = useState(false);

    const modelRef = useRef(null);
    const btnRef = useRef(null);


    useEffect(()=>{
        setdata(null);
        const func = async ()=>{
            const res = await axios.get(`${url}/api/postcreatemodel/${id}`);
            const resp = res.data;
            setdata(resp.data)
            setmodel_name(resp.pacra_model_name[0].name);
            setmainheading(resp.mainheading);
            let arr = [];
            let arr1 = [];
            let arr2 = [...addsubheadinginput]

            for(let i in resp.mainheading){
                for(let j in resp.mainheading[i].subheading){
                    arr.push({id: resp.mainheading[i].subheading[j].id, name: resp.mainheading[i].subheading[j].name});
                }
                arr1.push({id: resp.mainheading[i].id, name: resp.mainheading[i].name});
                arr2.push([]);
            }
            setmainheadingarr(arr1);
            setaddsubheadinginput(arr2)
            setsubheading(arr);
        }

        func();
    },[bool])

    const handleadd = ()=>{
        setTasks([...tasks, { id: uuidv4() }]);
    }

    const saveinput = async (index, id)=>{
        let name = document.getElementById(`addsubinput${index}`).value;
        const res = await axios.post(`${url}/api/addsubheading`,{
            'id' : id,
            'name': name
        })
        const resp = res.data;
        console.log(resp, 'response');
        if(resp.status == true){
            setbool(!bool)
        }
        else{

        }
    }

    const delete_mainheading = async (id)=>{
        Swal.fire({ title: 'Do you want to delete the Main Heading?', 
        showDenyButton: false, showCancelButton: true, confirmButtonText: 'Delete'})
        .then(async(result) => {
            if (result.isConfirmed) {
              const res = await axios.get(`${url}/api/deletemainheading/${id}`);
              const resp = res.data;
              console.log(resp, 'response');
              if(resp.status == true){
                  setbool(!bool)
              }
              else{
      
              }
            }
        })
    }

    const delete_subheading = async (id)=>{
        Swal.fire({ title: 'Do you want to delete the sub heading?', 
        showDenyButton: false, showCancelButton: true, confirmButtonText: 'Delete'})
        .then(async(result) => {
            if (result.isConfirmed) {
                const res = await axios.get(`${url}/api/deletesubheading/${id}`);
                const resp = res.data;
                console.log(resp, 'response');
                if(resp.status == true){
                    setbool(!bool)
                }
                else{

                }
            }
        })
    }

    const addsubheadinginputfunc = (index)=>{
        // let arr = [...addsubheadinginput];
        // arr[index].push({ id: uuidv4() })
        // setaddsubheadinginput(arr);

        document.getElementById(`addinput${index}`).hidden = false
        document.getElementById(`addinputbtn${index}`).hidden = true
    }
    
    const editForm = async (event)=>{
        event.preventDefault();
        btnRef.current.disabled = true;
        btnRef.current.innerHTML =  "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";
        const res = await axios.post(`${url}/api/update_model`,{
            'id': Number(id),
            'name': modelRef.current.value,
            'mainheading': mainheadingarr,
            'subheading': subheading
        })

        const resp = res.data;
        if(resp.status == true){
            btnRef.current.disabled = false;
            btnRef.current.innerHTML =  "Update Model";
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success");
        }
        else{
            btnRef.current.disabled = false;
            btnRef.current.innerHTML =  "Update Model";
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error")
        }
        
    }

    const submitForm = async (event)=>{
        event.preventDefault();
        const res = await axios.post(`${url}/api/add_new_heading`, {
            'id': id,
            'mainheading' : heading_name1,
            'subheading' : subheading_name
        });

        const resp = res.data
        console.log(resp, 'resp');
        if(resp.status == true){
            window.location.reload();
        }
        else{
            setalertOpen1(true);
            setalertmessage1(resp.message);  
            setalertType1("error")
        }
        

    }

  return (
    <div>
        <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/model-dashboard"} >Assessment Format Grid</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Update Model</li>
                </ol>
            </nav>
            <hr/>
        </div>
      <div className='fs-4 fw-bold text-center mt-2'>
            Update Model
      </div>
      <div>
       {data !== null && <div className='d-flex justify-content-end' style={{width: '99%'}} >
            <button className='btn btn-primary mt-2 d-flex justify-content-end' type='button' data-bs-toggle="modal" data-bs-target={`#addnew`}>Add New Heading</button>
        </div>}
      <form className="modal fade" id={`addnew`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" onSubmit={(event)=>{submitForm(event)}}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add New Heading</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <Box className="mx-auto" sx={{ width: "98%" }}>
                            <Collapse in={alertopen1}>
                                <Alert
                                severity={alertType1}
                                action={
                                    <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setalertOpen(false);
                                    }}
                                    >
                                    <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                    sx={{ mb: 2 }}
                                >
                                {AlertMessage1}
                                </Alert>
                            </Collapse>
                </Box>
                <div>
                <div className='mt-3'>
                    <div className='fs-6 fw-bold'>
                        Enter Heading name
                    </div>
                    <div className='mt-1 col-12'>
                        <input className='form-control' value={heading_name1} onChange={(e)=>{
                            setheading_name1(e.target.value)
                        }} required />
                    </div>
                </div>
                <div className='mt-3'>
                    <div className='fs-6 fw-bold'>
                        Sub Headings
                    </div>
                    <div className='mt-1 col-12'>
                        <textarea className='form-control' value={subheading_name} onChange={(e)=>{
                            const val = e.target.value;
                            const arr = val.split(',');
                            setsubheading_name(arr);
                        }} />
                    </div>
                </div>
                </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </div>
                </div>
            </div>
        </form>
        {data !== null ?
            <form className='mt-2 p-3' onSubmit={(event)=>{editForm(event)}}>
                <div>
                    <div className='card shadow p-2 mt-3'>
                        <div className='fs-6 fw-bold'>
                            Model name
                        </div>
                        <div className='mt-1 col-12'>
                            <input className='form-control' ref={modelRef} defaultValue={model_name} required/>
                        </div>
                    </div>
                    <hr />
                    {
                        mainheading.map((value, index)=>{
                            return(
            <div className='card shadow p-2 mb-2'>
                                    <div className='mt-3'>
                                        <div className='fs-6 fw-bold'>
                                            Main Heading
                                        </div>
                                        <div className='row'>
                                            <div className='mt-1 col-11'>
                                                <input className='form-control' defaultValue={value.name} onChange={(e)=>{
                                                        let arr = [...mainheadingarr];
                                                        for(let i in arr){
                                                            if(arr[i].id == value.id){
                                                                arr[i].name = e.target.value;
                                                            }
                                                        }
                                                        // arr[index][index2] = {id: value2.id, desc: e.target.value};
                                                        setmainheadingarr(arr);

                                                        }} required/>
                                            </div>
                                            <div className='mt-1 col-1'>
                                                <Tooltip title={'Delete'}><DeleteIcon sx={{color: 'red'}} onClick={()=>{delete_mainheading(value.id)}}/></Tooltip>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mt-3'>
                                        <div className='fs-6 fw-bold'>
                                            Sub Headings
                                        </div>
                                        {
                                        value.subheading.map((value2, index2)=>{
                                            return(
                                                <div className='row'>
                                                    <div className='mt-1 col-11'>
                                                        <input className='form-control' defaultValue={value2.name} onChange={(e)=>{
                                                        let arr = [...subheading];
                                                        for(let i in arr){
                                                            if(arr[i].id == value2.id){
                                                                arr[i].name = e.target.value;
                                                            }
                                                        }
                                                        // arr[index][index2] = {id: value2.id, desc: e.target.value};
                                                        setsubheading(arr);

                                                        }} required/>
                                                    </div>
                                                    <div className='mt-1 col-1'>
                                                        <Tooltip title={'Delete'}><DeleteIcon sx={{color: 'blue'}} onClick={()=>{delete_subheading(value2.id)}}/></Tooltip>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        {
                                            <div className='row' hidden={true} id={`addinput${index}`}>
                                                <div className='mt-1 col-11'>
                                                    <input className='form-control mt-1' id={`addsubinput${index}`} />
                                                </div>
                                                <div className='mt-1 col-1'>
                                                    <Tooltip title="Save"><SaveIcon sx={{color: '#000078'}} id={`Save${index}`} onClick={(e)=>{saveinput(index, value.id)}} /></Tooltip>
                                                </div>
                                            </div>
                                        }
                                        <div className='mt-2'>
                                            <button className='btn btn-primary' type='button' id={`addinputbtn${index}`} hidden={false} onClick={()=>{addsubheadinginputfunc(index)}}>Add Sub Heading</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                        <Box className="mx-auto" sx={{ width: "98%" }}>
                            <Collapse in={alertopen}>
                                <Alert
                                severity={alertType}
                                action={
                                    <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setalertOpen(false);
                                    }}
                                    >
                                    <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                    sx={{ mb: 2 }}
                                >
                                {AlertMessage}
                                </Alert>
                            </Collapse>
                        </Box>
                     <div className='mt-3 mx-auto' style={{width: 'fit-content'}}>
                        <button className='btn btn-primary' ref={btnRef} type='submit'>Update Model</button>
                     </div>
                    
                </div>
                
            </form>
        : (
            <div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
            <div class="wrapper" id="loader2">
              <svg
                class="hourglass"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 120 206"
                preserveAspectRatio="none"
              >
                <path
                  class="middle"
                  d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
                />
                <path
                  class="outer"
                  d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
                />
              </svg>
            </div>
          </div>
          )}
      </div>
    </div>
  )
}
