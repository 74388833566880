import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Home from './Home';
import Details from './Details';
import History from './History';
import PSX from './PSX';
import GetData from '../../../API/GetData';
import { useParams } from 'react-router-dom';
import Typewriter from "typewriter-effect";
import { Skeleton } from '@mui/material';
import axios from 'axios';
import { url } from '../../../Config';
import Rating_Data from './rating_Data';
import { decryptParams } from '../encrypt_url';
import { useLocation } from 'react-router-dom';
import {encryptParams} from "../encrypt_url";

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const { clientId } = useParams();
    const [state, setstate] = useState([])



    return (
        <>
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
                className='card_heading_main'
            >
                {value === index && (
                    <Box>
                        {/* <div className='d-md-inline-flex w-100 pb-2'>
                            <h2 className='mb-0 fw-bold'>
                                {sendingReq ? <Skeleton width={400} height={60} /> :
                                    title && <Typewriter
                                        onInit={(typewriter) => {
                                            typewriter
                                                .typeString(title)
                                                .start();
                                        }}
                                    />
                                }
                            </h2>
                            {value == 0 ? <p className='ms-3 mb-0'>{sendingReq ? <Skeleton width={150} height={30} /> :
                                title == '' ?
                                    <span className='text-danger'>(Invalid Opinion ID)</span>
                                    :
                                    <Typewriter
                                        onInit={(typewriter) => {
                                            typewriter
                                                .pauseFor(4000)
                                                .typeString('(Rating Mandate)')
                                                .start();
                                        }}
                                    />
                            }</p> : null}
                        </div> */}
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        </>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function index(props) {
    const [value, setValue] = useState(0);
    const [sendingReq, setsendingReq] = useState(false);
    const [opinionsData, setopinionsData] = useState([]);
    const [title, settitle] = useState('');
    const [DetailsTblOne, setDetailsTblOne] = useState([]);
    const [mandate, setmandate] = useState([]);
    const [DetailsGroupTable, setDetailsGroupTable] = useState([]);
    const [DetailsGroupTitle, setDetailsGroupTitle] = useState();
    const location = useLocation();
    const pathname = location.pathname;
    const encryptedParams = pathname.substring('/OpinionDetails/'.length);
    // Decrypt the parameters
    const { clientId} = decryptParams(encryptedParams);
    
    // const { clientId } = useParams();
    const [historyData, sethistoryData] = useState([]);
    const [client_detail, setclient_detail] = useState({});
    const [Ceo, setCeo] = useState([]);
    const [LiasonOne, setLiasonOne] = useState([]);
    const [LiasonTwo, setLiasonTwo] = useState([]);
    const [client, setclient] = useState(null);
    const [status, setstatus] = useState('loading');




    useEffect(() => {
        OpinionDetailsData()
        OpinionDetailsTblOne()
        OpinionDetailsTblTwo()
        HistoryTableData()

    }, [clientId])

    const HistoryTableData = async () => {
        setsendingReq(true);
        const res = GetData.getHistory(clientId);
        const resp = await res;
        const Data = resp.data;
        for (let i in Data) {
            Data[i].sNo = Number(i) + 1;
        }
        let temparray = []
        for (let i in Data) {
            temparray.push(Data[i])
        }
        setsendingReq(false)
        sethistoryData(temparray);
    };

    const OpinionDetailsData = async () => {
        setsendingReq(true)
        setstatus('loading')
        const res = GetData.OpinionDetailsApi(clientId)
        const resp = await res
        const Data = resp.data
        setsendingReq(false)
        setopinionsData(Data)
        setstatus('loaded')
    }

    

    useEffect(()=>{
        const func = async () => {
            setsendingReq(true)
            const res = GetData.OpinionTitle(clientId)
            const resp = await res
            setsendingReq(false)
            if (resp && resp.data[0] == null) {
                settitle(null);
            }
            else {
                settitle(resp.data[0].name);
            }
        }

        func();
    },[clientId])

    useEffect(()=>{
        const func = async ()=>{
          const res2 = await axios.get(`${url}/api/get_client_detail_api/${clientId}`);
          const resp2 = res2.data.data;
            if(resp2.length > 0){
                setclient_detail(resp2[0]);
                let ceo = resp2[0].ceo;
                if(ceo !== null && ceo !== undefined){
                  let ceo_detail = ceo.split(',');
                  setCeo(ceo_detail);
                }
    
                let L1 = resp2[0].liaison_one;
                if(L1 !== null && L1 !== undefined){
                  let L1_detail = L1.split(',');
                  setLiasonOne(L1_detail);
                }
                let L2 = resp2[0].liaison_two;
                if(L2 !== null && L2 !== undefined){
                  let L2_detail = L2.split(',');
                  setLiasonTwo(L2_detail);
                }
            }
         const response = await axios.get(`${url}/api/get_mandate/${clientId}`);
         const respon = response.data;
         setmandate(respon.mandate);
            
        }
    
        func();
        
      },[clientId])

      useEffect(() => {
        const func = async ()=>{
            const res = await axios.get(`${url}/api/client_opinion_relations/${clientId}`);
            const  resp = res.data;
            setclient(resp.client_id);
        }
    
        func()
        
      }, [clientId]);
    

    const OpinionDetailsTblOne = async () => {
        setsendingReq(true)
        const res = GetData.OpinionDetailsTblOneApi(clientId)
        const resp = await res
        const Data = resp && resp.data.filter(element => {
            return element !== null;
        })
        for (let i in Data) {
            Data[i].CRA = 'PACRA';
            Data[i].sNo = Number(i) + 1;
        }
        setsendingReq(false)
        setDetailsTblOne(Data)
    }

    const OpinionDetailsTblTwo = async () => {
        setsendingReq(true)
        const res = GetData.GroupDetailsTable(clientId)
        const resp = await res
        const groupTitle = resp && resp.data[0].group
        const Data = resp && resp.data[1].filter(element => {
            return element !== null;
        })
        for (let i in Data) {
            Data[i].CRA = 'PACRA'
            Data[i].sNo = Number(i) + 1
        }
        setsendingReq(false)
        setDetailsGroupTable(Data)
        setDetailsGroupTitle(groupTitle)
    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    return (
        <Box
            sx={{ bgcolor: 'background.paper' }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                className="theme_text"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                <Tab style={{ maxWidth: "120px" }} label="Home" {...a11yProps(0)} />
                <Tab style={{ maxWidth: "120px" }} label="Details" {...a11yProps(1)} />
                <Tab style={{ maxWidth: "120px" }} label="History" {...a11yProps(2)} />
                {/* <Tab style={{ maxWidth: "120px" }} label="Data" {...a11yProps(3)} /> */}

            </Tabs>
            <TabPanel value={value} index={0}>
                <Home
                    title={title}
                    status={status}
                    opinionsData={opinionsData}
                    screenWidth={props.screenWidth}
                    mandate={mandate}
                    client_id={clientId}
                    open={props.open}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Details
                    title={title}
                    LiasonTwo={LiasonTwo}
                    LiasonOne ={LiasonOne}
                    Ceo = {Ceo}
                    client={client}
                    client_detail = {client_detail}
                    screenWidth={props.screenWidth}
                    opinionsData={opinionsData}
                    DetailsTblOne={DetailsTblOne}
                    DetailsGroupTable={DetailsGroupTable}
                    groupTitle={DetailsGroupTitle}
                    Opinions={props.Opinions}
                    clientId={clientId} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <History
                    historyData={historyData}
                    screenWidth={props.screenWidth}
                    open={props.open}
                    clientId={clientId} /> 
            </TabPanel>
            {/* <TabPanel value={value} index={3}>
                <Rating_Data />
            </TabPanel> */}
            {/* <TabPanel value={value} index={4}>
                <Data />
            </TabPanel> */}
        </Box>
    );
}