import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { url } from '../../../Config';


export default function HR_training(props) {

    const date1Ref = useRef(null);
    const titleRef = useRef(null);
    const durRef = useRef(null);
    const numberRef = useRef(null);
    const boardAreaRef = useRef(null);
    const btnRef = useRef(null);


    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();

    const submitForm = async (event)=>{
        event.preventDefault();
        setalertOpen(false);

        const res = await axios.post(`${url}/api/storeTrainingHr`, {
            'date': date1Ref.current.value,
            'title': titleRef.current.value,
            'duration': durRef.current.value,
            'attendees': numberRef.current.value,
            'areas': boardAreaRef.current.value,
        })

        const resp = res.data;
        if(resp.status == true){
            btnRef.current.disabled = false;
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btnRef.current.disabled = false;
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }

    }

  return (
    <div>
      <div style={{height: '90vh'}}>
       <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            HR Trainings
        </div>
        <form className ='col-10 card shadow p-3 mx-auto mt-2' onSubmit={(event)=>{submitForm(event)}}>
        <div>
            <div className='mt-2 fw-bold'>
                Date
            </div>
            <div className='mt-2'>
                <input className='form-control' type='date' ref={date1Ref} required />
            </div>
        </div>
        <div>
            <div className='fw-bold mt-2'>
                Title Of Training Course
            </div>
            <div className='mt-2'>
                <input className='form-control' ref={titleRef} placeholder='Enter Title' required />
            </div>
        </div>
        <div>
            <div className='fw-bold mt-2'>
                Duration (hours)
            </div>
            <div className='mt-2'>
                <input className='form-control' ref={durRef} type='number' required />
            </div>
        </div>
        <div>
            <div className='fw-bold mt-2'>
                No. Of Officials Attended (Cadre-Wise)
            </div>
            <div className='mt-2'>
                <input className='form-control' ref={numberRef} type='number' required />
            </div>
        </div>
        <div>
            <div className='fw-bold mt-2'>
                Broad Areas Covered
            </div>
            <div className='mt-2'>
                <input className='form-control' ref={boardAreaRef} placeholder='Enter Areas Covered' required />
            </div>
        </div>
        <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
        </Box>
        <div className='mt-3'>
            <button className='btn btn-primary' ref={btnRef}  type='submit'>Save</button>
        </div>
        </form>
    </div>
    </div>
  )
}
