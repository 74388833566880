import React,{useEffect, useState} from 'react';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import TableComponent from '../../Atoms/TableComponent';
import { url } from '../../../Config';
import axios from 'axios';
import Skeleton from "@mui/material/Skeleton";


export default function Opinion_transferlog(props) {
    const [data, setdata] = useState([]);


    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/portfolioSwitch`);
            const resp = res.data;
            setdata(resp.portfolioSwitchLog);
        }
        func();
    },[])

    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };

      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };

      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };

      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Opinion Name",
          minWidth: responsiveColumns(),
          field: "opinion_name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "opinion_name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Transferred By",
          minWidth: responsiveColumns(),
          field: "approver_name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: cellrander,
          tooltipField: "approver_name",
        },
        {
          headerName: "To",
          field: "new_user_name",
          minWidth: 130,
          minWidth: 130,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          tooltipField: "pre_lterm",
          hide: columnHide(),
          cellRenderer: (params) => {
            if (params.value === "nullData") return <Skeleton />;
            if (params.value === "" || params.value == null) {
              return "-";
            } else {
              return(`[${params.data.old_user_name}] to [${params.value}]`);
            }
          },
          // pinned: responsiveColumnPin(),
        },
        {
            headerName: "Timestamp",
            minWidth: responsiveColumns(),
            field: "date",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            hide: columnHide(),
            cellRenderer: cellrander,
            tooltipField: "date",
        },

      ];


      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
          {/* <Table className="overflow-scroll responsiveTableFonts">
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Team
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.managerName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Analyst
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.pacraAnalyst}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                NL
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerNotiDate(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                FC
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerFCDate(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Open Request
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerOpenRequest(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Approval
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerApproval(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Closed
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerClosed(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Re-Opened
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerreOpen(params)}
              </TableCell>
            </TableRow>
          </Table> */}
        </h1>
      );


  return (
    <div>
      <TableComponent
      Data={data}
      screenWidth={props.screenWidth}
      MobViewRender={MobViewRender}
      columnDefs={columnDefs}
      datefilters={false}
      datefilter={false}
       />
    </div>
  )
}
