import React, {useRef, useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../Config';


export default function BD_dashboard(props) {

    const Navigate = useNavigate();

    const id = localStorage.getItem("userID");
    const card1 = useRef(null);
    const card2 = useRef(null);
    const card3 = useRef(null);
    const card4 = useRef(null);
    const card5 = useRef(null);
    const card6 = useRef(null);



  return (
    <div style={props.screenWidth > 760 ? {height : '90vh'} : null}>
        <div className='mt-2 text-center mb-2 fw-bold fs-3' style={{color:"#000078"}}>
            Business Development and Marketing
        </div>
        <div className='row p-5 mt-5'>
            <div className='card shadow ms-lg-5 ms-md-5 me-lg-2 me-md-2 me-0 col-lg-5 col-md-5 col-12 p-3'>
                <div className='text-center fs-5 fw-bold'>
                    Mandate Section
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card1} onClick={()=>{Navigate('/mandate-new')}} onMouseEnter={()=>{card1.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card1.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Mandate New
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card2} onClick={()=>{Navigate('/mandate-received')}} onMouseEnter={()=>{card2.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card2.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Mandate Received
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card3} onClick={()=>{Navigate('/mandate-revised')}} onMouseEnter={()=>{card3.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card3.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Mandate Revised
                </div>
            </div>
            <div className='card shadow  col-lg-5 col-md-5 col-12 p-3'>
                <div className='text-center fs-5 fw-bold'>
                    Client Section
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card4} onClick={()=>{Navigate('/client-entry-view')}} onMouseEnter={()=>{card4.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card4.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Add/Edit/Approve
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card5} onClick={()=>{Navigate('/cob-invites')}} onMouseEnter={()=>{card5.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card5.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Invite Clients
                </div>
                {id !== '130' && <div className='card shadow text-center mt-3 border p-3' ref={card6} onClick={()=>{Navigate('/Ratings')}} onMouseEnter={()=>{card6.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card6.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    Ratings
                </div>}
            </div>
        </div>
    </div>
  )
}
