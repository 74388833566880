import React, {useState, useEffect, useRef} from 'react';
import { url } from '../../../../../Config';
import axios from 'axios';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { v4 as uuidv4 } from "uuid";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export default function EditSecpReport(props) {

    const id = localStorage.getItem("userID");
    const userid = localStorage.getItem("userID");
    const Navigate = useNavigate();
    const { secp_id } = useParams();
    const nameRef = useRef(null);
    const date1Ref = useRef(null);
    const date2Ref = useRef(null);
    const fileRef = useRef(null);
    const btnRef = useRef(null);


    const [files, setfiles] = useState(null);    
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [bool, setbool] = useState(false);
    const [data, setdata] = useState({});

    const rightsval = localStorage.getItem("rights");
  
    let rights = [];
  
    if(rightsval !== null){
      rights = rightsval.split(",");
    }
  
    const has_right = rights.includes("2");
    const can_edit_after_approval = rights.includes("51");


    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/updateSecpReport/${secp_id}`);
            const resp = res.data;
            setdata(resp.secpreport);

            if(resp.secpreport.file_name == null){
                if(fileRef.current){
                    fileRef.current.required = true;
                }
            }
            

        }

        func();
    },[!bool, fileRef.current])

    const editForm = async (event)=>{
        event.preventDefault();
        setalertOpen(false);

        if(files !== null && files !== undefined){
            const input = document.getElementById('pdfInput');

            if (input.files.length === 0) {
                setalertType("error");
                setalertOpen(true);
                setalertmessage('Please select a PDF file.');
                return;
            }

            const allowedFormats = ['application/pdf'];
            const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
            const file = input.files[0];

            // Check file format
            if (!allowedFormats.includes(file.type)) {
                setalertType("error");
                setalertOpen(true);
                setalertmessage('Invalid file format. Please choose a valid pdf file.');
                return;
            }

            // Check file size
            if (file.size > maxFileSize) {
                setalertType("error");
                setalertOpen(true);
                setalertmessage('File size exceeds the maximum allowed limit (2MB).');
                return;
            }

        }

        
        btnRef.current.disabled = true;
        const currentDateOnly = new Date().toISOString().split('T')[0];

        const fileData = new FormData();
            fileData.append("reportname", nameRef.current.value);
            fileData.append("reportDate", currentDateOnly);
            fileData.append("start_date", date1Ref.current.value);
            fileData.append("end_date", date2Ref.current.value);
            if(files !== null && files !== undefined){
                fileData.append("secpreportfile", files);
            }
            fileData.append("id", Number(id));
            fileData.append("recordId", Number(secp_id));

            const res = await axios.post(`${url}/api/onUpdateSecpReport`,
                fileData,
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
            );
            
            
            const resp = res.data;

            if(resp.status == true){
                btnRef.current.disabled = false;
                setbool(!bool);
                setalertType("success");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
            else{
                btnRef.current.disabled = false;
                setalertType("error");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
    
        
    }

    const approve = async ()=>{
        setalertOpen(false);
        const res = await axios.post(`${url}/api/ApproveSecp`, {
            'id' : Number(secp_id),
            'userid' : Number(userid)
        });
        const resp = res.data;
        if(resp.status == true){
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success");
            setbool(!bool);
        }
        else{
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error")
        }
    }


  return (
    <div style={{height: '90vh'}}>
      <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/secp-view"} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Update SECP QTR Report</li>
                </ol>
            </nav>
            <hr/>
        </div>
        <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            Update SECP QTR Report
        </div>
        <form className ='col-10 card shadow p-3 mx-auto mt-2' onSubmit={(event)=>{editForm(event)}}>
        <div>
            <div className='fw-bold'>
                Report
            </div>
            <div className='mt-2'>
                <input className='form-control' ref={nameRef} defaultValue={data.title} placeholder='Title' required />
            </div>
        </div>
        <div className='row mt-2'>
            <div className='col-5 me-2'>
                <div className='fw-bold'>
                    Start Date
                </div>
                <div className='mt-2'>
                    <input className='form-control' defaultValue={data.start_date} ref={date1Ref} type='date' required />
                </div>
            </div>
            <div className='col-5'>
                <div className='fw-bold'>
                    End Date
                </div>
                <div className='mt-2'>
                    <input className='form-control' defaultValue={data.end_date} ref={date2Ref} type='date' required />
                </div>
            </div>
        </div>
        <div className='mt-2'>
            <div className='fw-bold'>
                File
            </div>
            <div className='mt-2'>
                <input className='form-control' id="pdfInput" ref={fileRef} type='file' accept=".pdf" onChange={(e)=>{
                setfiles(e.target.files[0]);
            }} />
            </div>
            {data.file_name !== null && 
            <div className='mt-1'>
                Uploaded File: <a href={`${url}/api/viewsecppdf/${data.file_name}`} target='_blank'><PictureAsPdfIcon /></a>
            </div>}
        </div>
        <Box className="mx-auto" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
        </Box>
        <div className='mt-3'>
            {(has_right == true || can_edit_after_approval || data.status == 'submit' || data.status == null) && <button className='btn btn-primary me-1' ref={btnRef}  type='submit'>Update</button>}
            {data.status !== 'approve' && has_right == true && <button className='btn btn-success' onClick={()=>{approve()}} type='button'>Approve</button>}
        </div>
        </form>
    </div>
  )
}
