import React, { useEffect, useState } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import Logo from "../../../Assets/Images/PACRA_white.png";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export default function FeeVariation(props) {
    const [startdate, setstartdate] = useState();
    const [enddate, setenddate] = useState();
  
    function DateFormat(params) {
      if (params == null) {
        return "-";
      } else {
        const date = new Date(params);
        const yyyy = date.getFullYear();
        const yy = yyyy.toString();
        const y = yy.slice(2, 4);
        let mm = date.toLocaleString("default", { month: "short" });
        let dd = date.getDate();
        if (dd < 10) dd = "0" + dd;
        return dd + "-" + mm + "-" + y;
      }
    }
  
    const func = async () => {
      if (
        startdate == null ||
        startdate == "" ||
        enddate == null ||
        enddate == ""
      ) {
        console.log("enter valid date");
      } else {
        const response = await axios.get(`https://209.97.168.200/pacrawizpackv3/public/api/feeVariationpdfApi/${startdate}/${enddate}`);
        const data = response.data;
        for(let i in data){
            data[i].sNo = Number(i) + 1
            data[i].fee_min = data[i].fee_min.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            data[i].fee_max = data[i].fee_max.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            data[i].feeAnvl = data[i].feeAnvl.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            data[i].fee_per_policy = `${data[i].fee_min} - ${data[i].fee_max}`
        }
  
        // create PDF document
        const doc = new jsPDF();
  
        // set font type and size for document
        doc.setFont("helvetica");
        doc.setFontSize(12);
        const logoWidth = 30;
        const logoHeight = 30;
        const docWidth = doc.internal.pageSize.width;
        const logoX = (docWidth - logoWidth) / 2;
        const logoY = 10;
        // load logo image
        const logo = new Image();
        logo.src = Logo;
        logo.onload = () => {
          // add logo image to the document
          doc.addImage(logo, "PNG", logoX, logoY, logoWidth, logoHeight);
  
          // add title to the document
          doc.text(
            `C-vii | Detail of variation of fee charged by the CRA 
             ${DateFormat(startdate)} to ${DateFormat(enddate)} | 3QFY2023
             `,
            doc.internal.pageSize.getWidth() / 2,
            logoY + logoHeight + 10,
            { align: "center" }
          );
  
          // add table to the document
          const headers = [
            [ "#",
              "Name of Entity/ Issuer",
              "Agreement Date",
              "Fee as per policy (Rs.)",
              "Fee Charged (Rs. )",
              "Comments if variation exist in fee charged and fee as per policy",
            ],
          ];

          const rows = data.map((d) => [
            d.sNo,
            d.name,
            DateFormat(d.effectiveDate),
            d.fee_per_policy,
            d.feeAnvl,
            d.reason_outof_range_fee,
          ]);

          doc.autoTable({
            head: headers,
            body: rows,
            startY: 60,
            margin: { top: 10 },
          });
  
          // open PDF in new tab
          window.open(doc.output("bloburl"), "_blank");
        };
      }
    };
  
    return (
      <div>
        <p className="mx-auto p-3 fs-3 fw-bold text-capitalize" style={{ width: "fit-content", color:"#000078" }}>
        C-vii | Detail of variation of fee charged by the CRA
        </p>      
        <div className="row w-100">
          <div
            className="card shadow mx-auto col-lg-10 col-md-10 col-sm-10 col-11 pt-4"
            style={{ height: "auto", backgroundColor: "#e0e0e0" }}
          >
  
            <div className="row w-100 pb-3">
                <div className="col-lg-5 col-md-5 ms-lg-4 ms-md-2 ms-sm-3 col-sm-11 col-11 ms-2">
                <input
                  type="date"
                  style={{height: "38px"}}
                  className="form-control"
                  onChange={(e) => {   
                    setstartdate(e.target.value);
                  }}
                />
                </div>
                <div className="col-lg-5 col-md-5 ms-md-1 pt-sm-1 pt-md-0 col-sm-11 ms-sm-3 col-11 ms-2 pt-1">
                <input
                  type="date"
                  style={{height: "38px"}}
                  className="form-control"
                  onChange={(e) => {
                    setenddate(e.target.value);
                  }}
                />
                </div>
                <div className="col-lg-1 col-md-1 col-sm-1 pt-sm-1 pt-md-0 ms-md-1 mx-sm-auto col-1 pt-1 mx-auto">
                <button type="button" className="btn btn-primary h-60" onClick={func} >
                Submit
              </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
}
