import React, { useEffect, useState } from "react";
import TableComponent from "../../Atoms/TableComponent";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { NavLink } from "react-router-dom";
import { Check, Clear, Event } from "@material-ui/icons";
import { Table, TableCell, TableRow } from "@mui/material";
import GetData from "../../../API/GetData";
import axios from "axios";


export default function Methodologygridindex(props) {

    const id = localStorage.getItem("userID");
    const [Methodology, setMethodology] = useState([]);

  const responsiveColumns = () => {
    if (props.screenWidth < 770) {
      return null;
    } else {
      return 120;
    }
  };
  const responsiveColumnPin = () => {
    if (props.screenWidth < 770) {
      return null;
    } else {
      return "left";
    }
  };
  const detailShow = () => {
    if (props.screenWidth < 770) {
      return false;
    } else {
      return true;
    }
  };

  const columnHide = () => {
    if (props.screenWidth < 770) {
      return true;
    } else {
      return false;
    }
  };

  const guestHide = () => {
    if(id !== '243'){
      if (props.screenWidth < 770) {
        return true;
      } else {
        return false;
      }
    }
    else{
      return true;
    }
  };

  const sectorHide = () => {
    if (props.screenWidth > 500) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {

    const func = async ()=>{
      const res = await axios.get(`https://209.97.168.200/pacrawizpackv3/public/api/methodology`);
      const resp = res.data.data;
      setMethodology(resp);
    }

    func();
  }, []);

  const PvtRatingsdatefilters = (
    startDate,
    endDate,
    gridApi,
    getFilterType
  ) => {
    var dateFilterComponent = gridApi.api.getFilterInstance("date");
    dateFilterComponent.setModel({
      type: getFilterType(),
      inRange: true,
      dateFrom: startDate,
      dateTo: endDate,
    });
    var dateFilterComponent1 = gridApi.api.getFilterInstance("d_l");
    dateFilterComponent1.setModel({
      type: getFilterType(),
      inRange: true,
      dateFrom: startDate,
      dateTo: endDate,
    });
    var dateFilterComponent2 = gridApi.api.getFilterInstance("d_date");
    dateFilterComponent2.setModel({
      type: getFilterType(),
      inRange: true,
      dateFrom: startDate,
      dateTo: endDate,
    });
    var dateFilterComponent3 = gridApi.api.getFilterInstance("d_dl");
    dateFilterComponent3.setModel({
      type: getFilterType(),
      inRange: true,
      dateFrom: startDate,
      dateTo: endDate,
    });
  };

  function fullDate(params) {
    if (params.value === "nullData") return <Skeleton />;
    if (params.value == null) {
      return "-";
    } else if (params.value == "") {
      return "-";
    } else {
      const date = new Date(params.value);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }
  const cellrander = (params) => {
    if (params.value === "nullData") return <Skeleton />;
    if (params.value === "" || params.value == null) {
      return "-";
    } else {
      return params.value;
    }
  };
  const cellrandered = (params) => {
    if (params.value === "nullData") {
      return <Skeleton />;
    } else {
      return params.value;
    }
  };

  const columnDefs = [
    {
      headerName: "",
      field: "sNo",
      maxWidth: 30,
      filter: true,
      menuTabs: false,
      pinned: responsiveColumnPin(),
      hide: detailShow(),
      cellRenderer: "agGroupCellRenderer",
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      headerName: "#",
      maxWidth: 50,
      // minWidth: 66,
      field: "sNo",
      sortable: true,
      filter: true,
      // filter: "agSetColumnFilter",
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          "  </div>" +
          "</div>",
      },
      suppressFiltersToolPanel: true,
      menuTabs: false,
      cellRenderer: cellrandered,
      pinned: responsiveColumnPin(),
    },

    {
      headerName: "Name",
      minWidth: responsiveColumns(),
      field: "name",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      cellRenderer: cellrander,
      tooltipField: "name",
      pinned: responsiveColumnPin(),
    },
    {
      headerName: "Rating Scale",
      minWidth: responsiveColumns(),
      field: "rating_scale",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      cellRenderer: cellrander,
      tooltipField: "rating_scale",
      pinned: responsiveColumnPin(),
    },

    {
      headerName: "Date",
      hide: sectorHide(),
      field: "date",
      minWidth: 115,
      // hide: true,
      sortable: true,
      filter: "agDateColumnFilter",
      excelMode: "windows",
      cellRenderer: fullDate,
      debounceMs: "DateFilter",
      filterParams: {
        filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
        inRangeInclusive: true,
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateAsString = moment(cellValue).format("DD/MM/YYYY");
          var dateParts = dateAsString.split("/");
          var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );

          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }

          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        buttons: ["clear", "reset", "apply"],
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
        },
      },
    },

    {
      headerName: "Methodology DL",
      field: "d_l",
      minWidth: 125,
      hide: columnHide(),
      sortable: true,
      filter: "agDateColumnFilter",
      excelMode: "windows",
      debounceMs: "DateFilter",
      filterParams: {
        filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
        inRangeInclusive: true,
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateAsString = moment(cellValue).format("DD/MM/YYYY");
          var dateParts = dateAsString.split("/");
          var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );

          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }

          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        buttons: ["clear", "reset", "apply"],
      },
      cellRenderer: fullDate,
    },
    {
      headerName: "Days To DL",
      hide: columnHide(),
      field: "day_dl",
      minWidth: 90,
      cellRenderer: cellrandered,
      sortable: true,
      cellClass: "ag-right-aligned-cell",
      filter: "agNumberColumnFilter",
      excelMode: "windows",
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          "  </div>" +
          "</div>",
      },
      cellRenderer: function (params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value < 0) {
          return <span className="text-danger"> {params.value} </span>;
        } else {
          return <span className="text-success"> {params.value} </span>;
        }
      },
    },
    {
        headerName: "Scale",
        hide: columnHide(),
        field: "scale_file",
        filter: "agSetColumnFilter",
        minWidth: 80,
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
        },
        cellRenderer: function (params) {
          if (params.value === "nullData") return <Skeleton />;
          else if(params.value == null || params.value == ''){
            return <Clear className="theme_text" />;
          }
          else return <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.scale_file_encrypt}`} target="_blank"><Check className="theme_text" /></a>;
        },
      },

      {
        headerName: "Minutes",
        hide: columnHide(),
        field: "mm_file",
        filter: "agSetColumnFilter",
        minWidth: 80,
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
        },
        cellRenderer: function (params) {
          if (params.value === "nullData") return <Skeleton />;
          else if(params.value == null || params.value == ''){
            return <Clear className="theme_text" />;
          }
          else return <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.mm_file_encrypt}`} target="_blank"><Check className="theme_text" /></a>;
        },
      },

      {
        headerName: "Document",
        hide: columnHide(),
        field: "doc_file",
        filter: "agSetColumnFilter",
        minWidth: 80,
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
        },
        cellRenderer: function (params) {
          if (params.value === "nullData") return <Skeleton />;
          else if(params.value == null || params.value == ''){
            return <Clear className="theme_text" />;
          }
          else return <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.doc_file_encrypt}`} target="_blank"><Check className="theme_text" /></a>;
        },
      },
      
      {
        headerName: "Image",
        hide: columnHide(),
        field: "img_file",
        filter: "agSetColumnFilter",
        minWidth: 80,
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
        },
        cellRenderer: function (params) {
          if (params.value === "nullData") return <Skeleton />;
          else if(params.value == null || params.value == ''){
            return <Clear className="theme_text" />;
          }
          else return <a href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.img_file_encrypt}`} target="_blank"><Check className="theme_text" /></a>;
        },
      }

    // {
    //   headerName: "DB DL",
    //   hide: columnHide(),
    //   field: "d_dl",
    //   minWidth: 100,
    //   sortable: true,
    //   filter: "agDateColumnFilter",
    //   excelMode: "windows",
    //   debounceMs: "DateFilter",
    //   filterParams: {
    //     filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
    //     inRangeInclusive: true,
    //     comparator: function (filterLocalDateAtMidnight, cellValue) {
    //       var dateAsString = moment(cellValue).format("DD/MM/YYYY");
    //       var dateParts = dateAsString.split("/");
    //       var cellDate = new Date(
    //         Number(dateParts[2]),
    //         Number(dateParts[1]) - 1,
    //         Number(dateParts[0])
    //       );

    //       if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    //         return 0;
    //       }

    //       if (cellDate < filterLocalDateAtMidnight) {
    //         return -1;
    //       }

    //       if (cellDate > filterLocalDateAtMidnight) {
    //         return 1;
    //       }
    //     },
    //     buttons: ["clear", "reset", "apply"],
    //   },
    //   cellRenderer: fullDate,
    // },
    // {
    //   headerName: "Sector Study",
    //   hide: columnHide(),
    //   field: "file",
    //   filter: "agSetColumnFilter",
    //   minWidth: 80,
    //   headerComponentParams: {
    //     template:
    //       '<div class="ag-cell-label-container" role="presentation">' +
    //       '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
    //       '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
    //       '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
    //       '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
    //       '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
    //       '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
    //       '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
    //       '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
    //       "  </div>" +
    //       "</div>",
    //   },
    //   cellRenderer: function (params) {
    //     if (params.value === "nullData") return <Skeleton />;
    //     else if(params.value == null || params.value == undefined){
    //       return ("");
    //     }
    //     else return <a href={`https://209.97.168.200/pacrawizpackv3/public/view/storage/app/${params.value}`} target="_blank"><Check className="theme_text" /></a>;
    //   },
    // },
    // {
    //   headerName: "Dash Board",
    //   hide: columnHide(),
    //   minWidth: 80,
    //   field: "dfile2",
    //   filter: "agSetColumnFilter",
    //   cellRenderer: function (params) {
    //     if (params.value === "nullData") return <Skeleton />;
    //     if (params.value == "x") {
    //       return <Clear className="theme_text" />;
    //     }
    //     else if(params.value == null || params.value == undefined){
    //       return ("");
    //     }
    //     else {
    //       return '-';
    //     }
    //   },
    //   headerComponentParams: {
    //     template:
    //       '<div class="ag-cell-label-container" role="presentation">' +
    //       '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
    //       '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
    //       '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
    //       '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
    //       '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
    //       '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
    //       '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
    //       '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
    //       "  </div>" +
    //       "</div>",
    //   },
    // },
    // {
    //   headerName: "DB Days To DL",
    //   hide: columnHide(),
    //   minWidth: 90,
    //   field: "day_dlD",
    //   // hide: true,
    //   sortable: true,
    //   cellClass: "ag-right-aligned-cell",
    //   filter: "agNumberColumnFilter",
    //   headerComponentParams: {
    //     template:
    //       '<div class="ag-cell-label-container" role="presentation">' +
    //       '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
    //       '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
    //       '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
    //       '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
    //       '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
    //       '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
    //       '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
    //       '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
    //       "  </div>" +
    //       "</div>",
    //   },
    //   cellRenderer: function (params) {
    //     if (params.value === "nullData") return <Skeleton />;
    //     if (params.value < 0) {
    //       return <span className="text-danger"> {params.value} </span>;
    //     } else {
    //       return <span className="text-success"> {params.value} </span>;
    //     }
    //   },
    //   excelMode: "windows",
    // },
    // {
    //   headerName: "History",
    //   hide: columnHide(),
    //   minWidth: 90,
    //   filter: "agSetColumnFilter",
    //   field: "id",
    //   cellRenderer: function (params) {
    //     if (params.value === "nullData") return <Skeleton />;
    //     else if(params.value == null || params.value == undefined){
    //       return ("");
    //     }
    //     else
    //       return (
    //         <a
    //           href={`https://209.97.168.200/pacrawizpackv3/public/admin/getSectorHistory/${params.value}`}
    //           target="_blank"
    //           rel="noopener noreferrer"
    //         >
    //           <Event className="theme_text" />
    //         </a>
    //       );
    //   },
    // },
    // {
    //   headerName: "Client Outstanding",
    //   hide: guestHide(),
    //   minWidth: 120,
    //   field: "opinions",
    //   cellRenderer: cellrandered,
    //   sortable: true,
    //   filter: "agSetColumnFilter",
      
    //   headerComponentParams: {
    //     template:
    //       '<div class="ag-cell-label-container" role="presentation">' +
    //       '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
    //       '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
    //       '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
    //       '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
    //       '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
    //       '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
    //       '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
    //       '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
    //       "  </div>" +
    //       "</div>",
    //   },
    //   excelMode: "windows",
    // },
    // {
    //   headerName: "Client In COB",
    //   hide: guestHide(),
    //   minWidth: 100,
    //   field: "opinions",
    //   cellRenderer: cellrandered,
    //   sortable: true,
    //   filter: "agSetColumnFilter",
    //   headerComponentParams: {
    //     template:
    //       '<div class="ag-cell-label-container" role="presentation">' +
    //       '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
    //       '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
    //       '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
    //       '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
    //       '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
    //       '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
    //       '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
    //       '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
    //       "  </div>" +
    //       "</div>",
    //   },
    //   excelMode: "windows",
    // },
  ];
  
  const MobViewRender = (params) => (
    <h1 style={{ padding: "10px 20px" }}>
      <Table className="overflow-scroll responsiveTableFonts">
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Methodology DL
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.d_l}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Days To DL
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.day_dl}
          </TableCell>
        </TableRow>
        <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Scale
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.scale_file !== null ? <a className='me-1' href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.scale_file_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Minutes
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.mm_file !== null ? <a className='me-1' href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.mm_file_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Document
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.doc_file !== null ? <a className='me-1' href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.doc_file_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Image
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.img_file !== null ? <a className='me-1' href={`https://209.97.168.200/pacrawizpackv3/public/api/methodology/${params.data.img_file_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
        {/* <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Sector Study
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            <a
              href={`https://209.97.168.200/pacrawizpackv3/public/admin/pacraWork/${params.data.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Check className="theme_text" />
            </a>
          </TableCell>
        </TableRow> */}
        {/* <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            DB Days To DL
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.day_dlD < 0 ? (
              <span className="text-danger">
                {" "}
                {params.data.day_dlD}{" "}
              </span>
            ) : (
              <span className="text-success">
                {" "}
                {params.data.day_dlD}{" "}
              </span>
            )}
          </TableCell>
        </TableRow> */}
        {/* <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            History
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            <a
              href={`https://209.97.168.200/pacrawizpackv3/public/admin/getSectorHistory/${params.data.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Event className="theme_text" />
            </a>
          </TableCell>
        </TableRow> */}
      </Table>
    </h1>
  );

  return (
     <>
      <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            Methodology with Deadlines
      </div>
      <TableComponent
        // value={"Methodology"}
        // stating = {props.stating}
        // handleRfreshTab={props.handleRfreshTab}
        Data={Methodology}
        screenWidth={props.screenWidth}
        MobViewRender={MobViewRender}
        columnDefs={columnDefs}
        datefilters={PvtRatingsdatefilters}
        datefilter={false}
      />
    </>
  )
}
