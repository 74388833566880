import React,{useState, useEffect, useRef} from 'react';
import { url } from '../../../../../Config';
import Select from "react-select";
import axios from 'axios';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';


export default function Revertback_analyst(props) {

    const Navigate = useNavigate();
    const [opdata, setopdata] = useState([]);
    const [opinion_selected, setopinion_selected] = useState([]);
    const [bool, setbool] = useState(false);
    const [opiniondata, setopiniondata] = useState([]);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const id = localStorage.getItem("userID");
    const desig = localStorage.getItem("designation");
    const [selectedOptions, setSelectedOptions] = useState();
    const [reason, setreason] = useState("");


    const btn = useRef(null);
    const reqRef = useRef(null);
    const reasonRef = useRef(null);
    const terminatebtn = useRef(null)
    const targetRef = useRef(null);
    const reqbtn = useRef(null);
    const approveRef = useRef(null);
    const revertRef = useRef(null);


    useEffect(()=>{

        const func = async ()=>{
            setopdata([]);
            const res = await axios.get(`${url}/api/get_terminate_portfolio_record/${id}`);
            const resp = res.data;
            const mappedOptions = resp.map(item => ({ value: item.opinion_id, label: item.name }));
            setopdata(mappedOptions);
            setopiniondata(resp);
        }

        func();

    },[bool])

    function handleSelect(data) {

        btn.current.hidden = true;
        reasonRef.current.hidden = true;

        let arr = [];
        console.log(data, 'data');
        for(let i in data){
            for(let j in opiniondata){
                if(data[i].value == opiniondata[j].opinion_id){
                    arr.push(opiniondata[j]);
                }
            }
        }
        setSelectedOptions(data);
        setopinion_selected(arr);

        if(data.length > 0){
            btn.current.hidden = false;
            reasonRef.current.hidden = false;
        }
        else{
            btn.current.hidden = true;
            reasonRef.current.hidden = true;
        }


    }

    // const terminatefunc = async(event)=>{
    //     document.getElementById('reopenbtn').disabled = true
    //     document.getElementById('reopenbtn').innerHTML = 'Please Wait'
    //     const res = await axios.post(`${url}/api/terminate_request`,{
    //         'id': support_portfolioid,
    //         'terminate_reason': terminate_reason
    //     })

    //     const resp = res.data

    //     if(resp.status == true){
    //         setbool(!bool);
    //         document.getElementById('reopenbtn').disabled = false;
    //         document.getElementById('reopenbtn').innerHTML = 'Submit'
    //         document.getElementById('reasonreopenclose').click();
    //         setalertOpen(true);
    //         setalertmessage(resp.message);
    //         setalertType("success");
    //         if(btn.current){
    //             btn.current.hidden = false;
    //         }
    //         if(terminatebtn.current){
    //             terminatebtn.current.hidden = true;
    //         }
    //     }
    //     else{
    //         document.getElementById('reopenbtn').disabled = false;
    //         document.getElementById('reopenbtn').innerHTML = 'Submit'
    //         document.getElementById('reasonreopenclose').click();
    //         setalertOpen(true);
    //         setalertmessage(resp.message);  
    //         setalertType("error");
    //     }
    // }

    const submit = async(event)=>{
        event.preventDefault();

        if(reqbtn.current){
            reqbtn.current.disabled = true;
            reqbtn.current.innerHTML = 'Please Wait...';
        }

        const res = await axios.post(`${url}/api/terminate_request`, {
            'opinion_selected': opinion_selected,
            'reason' : reason
        })

        const resp = res.data;

        if(resp.status == true){
            if(reqbtn.current){
                reqbtn.current.disabled = false;
                reqbtn.current.innerHTML = 'Revert Back';
            }
            setbool(!bool);
            setSelectedOptions([]);
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success");
        }
        else{
            if(reqbtn.current){
                reqbtn.current.disabled = false;
                reqbtn.current.innerHTML = 'Revert Back';
            }
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error");
        }
    }


  return (
    <div style={{height: "90vh"}}>
      <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
        Support Portfolio
        </div>
        <div className='mt-1 p-5 row'>
            <div className='card shadow fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={reqRef} style={{fontSize: '13px'}} onClick={()=>{Navigate('/request-support-analyst')}}>
                1. Request For Support Analyst
            </div>
            <div className='card shadow fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={approveRef} style={{fontSize: '13px'}} onClick={()=>{Navigate('/approve-tl-support')}}>
                2. Approve/Decline Support Analyst Requests
            </div>
            <div className='card shadow fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={revertRef} style={{backgroundColor: "#000078", color: "white", fontSize: '13px'}} onClick={()=>{Navigate('/revert-back-analyst')}}>
                3. Revert Back Analyst
            </div>
        </div>
        <form className='card shadow col-10 mx-auto p-3 mt-1' onSubmit={(event)=>{submit(event)}}>
            <div className='fs-4 fw-bold mt-2 text-center text-decoration-underline' style={{color: "#000078"}}>
                Revert Back Analyst
            </div>
            <Box className="mx-auto" ref={targetRef} sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
            </Box>
            <div className='col-10 mx-auto mt-3 fw-bold fs-6'>
                <div className='text-decoration-underline'>
                    Opinion(s):
                </div>
                <div className='col-10 mt-1'>
                    <Select
                        options={opdata}
                        placeholder="Select Opinion"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isSearchable={true}
                        isMulti
                    />
                </div>

                <div ref={reasonRef} hidden={true}>
                    <div className='text-decoration-underline mt-3'>
                        Reason
                    </div>
                    <div className='col-10 mt-1'>
                        <textarea className='form-control' value={reason} onChange={(e)=>{setreason(e.target.value)}} required />
                    </div>
                </div>

                <div className='mt-3' style={{width: "fit-content"}} ref={btn} hidden={true}>
                    <button className='btn btn-primary' ref={reqbtn} type='submit'>Revert Back</button>
                </div>

            </div>

        </form>
        <div className='card shadow col-10 mx-auto p-3 mt-1' style={{overflow: 'scroll'}}>
            <table className='table table-bordered border-dark'>
                <thead>
                    <tr>
                        <th scope="col">Opinion</th>
                        <th scope="col">Analyst</th>
                        <th scope="col">Supporting Analyst</th>
                        <th scope="col">Stage</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        opinion_selected.map((value, index)=>{
                            return(
                                <tr>
                                    <td>{value.name}</td>
                                    <td>{value.anlyst_name}</td>
                                    <td>{value.support_analyst_name == null ? 'Not Assigned Yet' : value.support_analyst_name}</td>
                                    <td>{value.status == 'Entered' ? 'Requested' : value.status}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    </div>
  )
}
