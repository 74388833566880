import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { url } from '../../../Config';
import { Link } from 'react-router-dom';
import Select from "react-select";


export default function Add_complaints(props) {

    const date1Ref = useRef(null);
    const nameRef = useRef(null);
    const natureRef = useRef(null);
    const date2Ref = useRef(null);
    const date3Ref = useRef(null);
    const optionRef = useRef(null);
    const btnRef = useRef(null);

    const [opinion, setopinion] = useState([]);
    const [opiniondata, setopiniondata] = useState('');
    const [selectedOptions, setSelectedOptions] = useState();

    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();


    useEffect(()=>{

        const func = async ()=>{
            const res = await axios.get(`https://209.97.168.200/pacrawizpackv3/public/api/outstanding`);
            const resp =  res.data.data;

            const mappedOptions = resp.map(item => ({ value: item.Entity, label: item.Entity }));
            mappedOptions.sort((a, b) => a.value.localeCompare(b.value));
            setopinion(mappedOptions);
        }

        func();

    },[])

    function handleSelect(data) {
        setSelectedOptions(data);
        setopiniondata(data.value);
    }


    const submitForm = async (event)=>{
        event.preventDefault();
        setalertOpen(false);

        if(opiniondata == '' || opiniondata == null){
            setalertType("error");
            setalertOpen(true);
            setalertmessage('Please Select Entity First');
            return;
        }
        if(optionRef.current.value == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage('Please Select An Option from "Status" field');
            return;
        }

        const res = await axios.post(`${url}/api/storeComplaint`, {
            'date': date1Ref.current.value,
            'entity': opiniondata,
            'nature': natureRef.current.value,
            'date_complaint_received': date2Ref.current.value,
            'action': date3Ref.current.value,
            'status': optionRef.current.value,

        })

        const resp = res.data;
        if(resp.status == true){
            btnRef.current.disabled = false;
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btnRef.current.disabled = false;
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }

    }

  return (
    <div style={{height: '90vh'}}>
         <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/complaints"} >Complaints</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Add Complaints</li>
                </ol>
            </nav>
            <hr/>
        </div>
       <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            Add Complaints
        </div>
        <form className ='col-10 card shadow p-3 mx-auto mt-2' onSubmit={(event)=>{submitForm(event)}}>
        <div>
            <div className='mt-2 fw-bold'>
                Date
            </div>
            <div className='mt-2'>
                <input className='form-control' type='date' ref={date1Ref} required />
            </div>
        </div>
        <div>
            <div className='fw-bold mt-2'>
                Nature Of Complaint
            </div>
            <div className='mt-2'>
                <textarea className='form-control' ref={natureRef} placeholder='Enter Complaint Nature here' required />
            </div>
        </div>
        <div>
            <div className='mt-2 fw-bold'>
                Date Of Complaint Received
            </div>
            <div className='mt-2'>
                <input className='form-control' type='date' ref={date2Ref} required />
            </div>
        </div>
        <div>
            <div className='fw-bold mt-2'>
                Name Of Entity
            </div>
            <div className='mt-2'>
                {/* <input className='form-control' ref={nameRef} placeholder='Enter Name of Entity' required /> */}
                <Select
                        options={opinion}
                        placeholder="Select Opinion"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isSearchable={true}
                        />
            </div>
        </div>
        <div>
            <div className='mt-2 fw-bold'>
                Action Taken By PACRA
            </div>
            <div className='mt-2'>
                <input className='form-control' type='date' ref={date3Ref} required />
            </div>
        </div>
        <div>
            <div className='mt-2 fw-bold'>
                Status
            </div>
            <div className='mt-2'>
                <select className='form-select' ref={optionRef}>
                    <option value="">Select Status</option>
                    <option value="1">In-Process</option>
                    <option value="2">Closed</option>
                    <option value="3">Status Details</option>
                </select>
            </div>
        </div>
        <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
        </Box>
        <div className='mt-3'>
            <button className='btn btn-primary' ref={btnRef}  type='submit'>Save</button>
        </div>
        </form>
    </div>
  )
}
