import React, {useState, useEffect, useRef} from 'react';
import { url } from '../../../../../Config';
import axios from 'axios';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { v4 as uuidv4 } from "uuid";
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import ApprovalIcon from '@mui/icons-material/Approval';


export default function EditCriteria(props) {

    const id = localStorage.getItem("userID");
    const userid = localStorage.getItem("userID");
    const [data, setdata] = useState({});
    const { criteria_id } = useParams();
    const Navigate = useNavigate();
    const nameRef = useRef(null);
    const DescriptionRef = useRef(null);
    const AppointmentRef = useRef(null);
    const Ex_in_Ref = useRef(null);
    const Total_Ex_Ref = useRef(null);
    const btnRef = useRef(null);

    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [bool, setbool] = useState(false);

    
    const rightsval = localStorage.getItem("rights");
  
    let rights = [];
  
    if(rightsval !== null){
      rights = rightsval.split(",");
    }
  
    const has_right = rights.includes("4");
    const can_edit_after_approval = rights.includes("51");


    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/UpdateCriteria/${criteria_id}`);
            const resp = res.data;
            setdata(resp.criteria);
        }

        func();
    },[bool])

    const editForm = async (event)=>{
        event.preventDefault();

        btnRef.current.disabled = true;

        const fileData = new FormData();
            fileData.append("criteria_member", nameRef.current.value);
            fileData.append("description", DescriptionRef.current.value);
            fileData.append("appointment_date", AppointmentRef.current.value);
            fileData.append("pacra_experience", Ex_in_Ref.current.value);
            fileData.append("total_experience", Total_Ex_Ref.current.value);
            fileData.append("id", Number(id));
            fileData.append("recordId", Number(criteria_id));

            const res = await axios.post(`${url}/api/onUpdateCriteria`,
                fileData,
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
            );
            
            const resp = res.data;
            btnRef.current.disabled = false;
            if(resp.status == true){
                btnRef.current.disabled = false;
                setbool(!bool);
                setalertType("success");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
            else{
                btnRef.current.disabled = false;
                setalertType("error");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
    }

    const approve = async ()=>{
        setalertOpen(false);
        const res = await axios.post(`${url}/api/ApproveCriteria`, {
            'id' : Number(criteria_id),
            'userid' : Number(userid)
        });
        const resp = res.data;
        if(resp.status == true){
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success");
            setbool(!bool);
        }
        else{
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error")
        }
    }



  return (
        <div style={{height: '90vh'}}>
      <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/criteria-view"} >Dashboard</Link></li>
                    <li className="breadcrumb-item"><Link to={`/view-criteria/${criteria_id}`} >Criteria Group Member Detail</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Update Criteria Group Member</li>
                </ol>
            </nav>
            <hr/>
        </div>
        <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            Update Criteria Group Member
        </div>
        <form className ='col-10 card shadow p-3 mx-auto mt-2' onSubmit={(event)=>{editForm(event)}}>
            <div>
                <div className='fw-bold'>
                    Director
                </div>
                <div className='mt-2'>
                    <input className='form-control' ref={nameRef} defaultValue={data.name} placeholder='Enter Name' required />
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Description
                </div>
                <div className='mt-2'>
                    <input className='form-control' ref={DescriptionRef} defaultValue={data.description} placeholder='Enter Description' required />
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Date of appointment
                </div>
                <div className='mt-2'>
                    <input className='form-control' ref={AppointmentRef} defaultValue={data.appointment_date} type='date' required />
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Experience in PACRA
                </div>
                <div className='mt-2'>
                    <input className='form-control' type='text' defaultValue={data.experience_pacra} ref={Ex_in_Ref} required />
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Total Experience
                </div>
                <div className='mt-2'>
                    <input className='form-control' type='text' defaultValue={data.experience_total} ref={Total_Ex_Ref} placeholder='Enter Total Experience' required />
                </div>
            </div>
            <Box className="mx-auto" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
        </Box>
        <div className='mt-3'>
            {(has_right == true || can_edit_after_approval || data.status == 'submit' || data.status == null) && <button className='btn btn-primary me-1' ref={btnRef}  type='submit'>Update</button>}
            {data.status !== 'approve' && has_right == true && <button className='btn btn-success' onClick={()=>{approve()}} type='button'>Approve</button>}
        </div>
        </form>

    </div>
  )
}
