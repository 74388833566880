import React, {useState, useEffect, useRef} from 'react';
import { url } from '../../../../../Config';
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CancelIcon from "@mui/icons-material/Cancel";



export default function AddDirector(props) {

    const id = localStorage.getItem("userID");
    const [company1, setcompany1] = useState([{ id: uuidv4() }]);
    const [company2, setcompany2] = useState([{ id: uuidv4() }]);
    const [company1title, setcompany1title] = useState([]);
    const [company2title, setcompany2title] = useState([]);
    const Navigate = useNavigate();
    const nameRef = useRef(null);
    const sinceRef = useRef(null);
    const approvalRef = useRef(null);
    const AssociationRef = useRef(null);
    const positionRef = useRef(null);
    const introRef = useRef(null);
    const imageRef = useRef(null);
    const btnRef = useRef(null);


    const [img, setimg] = useState(null);    
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();

    const addcompany1 = ()=>{
        setcompany1([...company1, { id: uuidv4() }]);
    }
    const deletecompany1 = (id, index)=>{
        setcompany1(company1.filter((company1) => company1.id !== id));
        const newtitle = [...company1title];
        newtitle.splice(index, 1);
        setcompany1title(newtitle)
        // const newtitle = [...title];
        // newtitle.splice(index, 1);
        // settitle(newtitle)
    }
    const addcompany2 = ()=>{
        setcompany2([...company2, { id: uuidv4() }]);
    }
    const deletecompany2 = (id, index)=>{
        setcompany2(company2.filter((company2) => company2.id !== id));
        const newtitle = [...company2title];
        newtitle.splice(index, 1);
        setcompany2title(newtitle)
        // const newtitle = [...title];
        // newtitle.splice(index, 1);
        // settitle(newtitle)
    }

    const submitForm = async (event)=>{
        event.preventDefault();

        const input = document.getElementById('imageInput');
        const allowedFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg+xml'];
        const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

        const file = input.files[0];
        
        // Check file format
        if (!allowedFormats.includes(file.type)) {
            setalertType("error");
            setalertOpen(true);
            setalertmessage('Invalid file format. Please choose a valid image file.');
            return;
        }

        // Check file size
        if (file.size > maxFileSize) {
            setalertType("error");
            setalertOpen(true);
            setalertmessage('File size exceeds the maximum allowed limit (2MB).');
            return;
        }

        const comp1 = company1title.join(',');
        const comp2 = company2title.join(',');

        btnRef.current.disabled = true;

        const fileData = new FormData();
            fileData.append("directorName", nameRef.current.value);
            fileData.append("directorsince", sinceRef.current.value);
            fileData.append("secpapprovaldate", approvalRef.current.value);
            fileData.append("directorShip", comp1);
            fileData.append("companies_shares", comp2);
            fileData.append("txtAreaIntro", introRef.current.value);
            fileData.append("directorImage", img);
            fileData.append("id", Number(id));

            const res = await axios.post(`${url}/api/storeDirector`,
                fileData,
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
            );
            
            const resp = res.data;
            if(resp.status == true){
                Navigate(`/edit-director/${resp.id}`);
            }
            else{
                btnRef.current.disabled = false;
                setalertType("error");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
    }


  return (
    <div>
      <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/directors-view"} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Add Director</li>
                </ol>
            </nav>
            <hr/>
        </div>
        <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            Add Director
        </div>
        <form className ='col-10 card shadow p-3 mx-auto mt-2' onSubmit={(event)=>{submitForm(event)}}>
        <div>
            <div className='fw-bold'>
                Director
            </div>
            <div className='mt-2'>
                <input className='form-control' ref={nameRef} placeholder='Enter Name' required />
            </div>
        </div>
        <div>
            <div className='mt-2 fw-bold'>
                Director since
            </div>
            <div className='mt-2'>
                <input className='form-control' type='date' ref={sinceRef} required />
            </div>
        </div>
        <div>
            <div className='mt-2 fw-bold'>
                SECP Approval Date
            </div>
            <div className='mt-2 row'>
                <input className='form-control' type='date' ref={approvalRef} required />
            </div>
        </div>
        <div>
            <div className='mt-2 fw-bold'>
                Directorship in other companies
            </div>
            <div className='mt-2'>
                {
                    company1.map((value, index)=>{
                        const { id } = value;
                        if(index == 0){
                            return(
                                <input className='form-control' placeholder='Other Companies' value={company1title[index]} onChange={(e)=>{
                                    let company1titles = [...company1title];
                                    company1titles[0] = e.target.value;
                                    setcompany1title(company1titles);
                                }} required/>
                            )
                        }
                        else{
                            return(
                                <div className='d-flex'>
                                    <input className='form-control mt-1' value={company1title[index]} onChange={(e)=>{
                                        let company1titles = [...company1title];
                                        company1titles[index] = e.target.value;
                                        setcompany1title(company1titles);
                                }} required />
                                    <div className='ms-2' onClick={()=>{deletecompany1(id, index)}}>
                                        <CancelIcon style={{color: "#dc3545"}} />
                                    </div>
                                </div>
                            )
                        }
                    })
                }

                <button className='btn btn-primary btn-sm mt-1' type='button' onClick={()=>{addcompany1()}}>Add New Field <AddCircleOutlinedIcon /> </button>
            </div>
        </div>
        <div>
            <div className='mt-2 fw-bold'>
                Name of companies where director is substantial shareholder
            </div>
            <div className='mt-2'>
                {
                        company2.map((value, index)=>{
                            const { id } = value;
                            if(index == 0){
                                return(
                                    <input className='form-control' placeholder='Companies' value={company2title[index]} onChange={(e)=>{
                                        let company2titles = [...company2title];
                                        company2titles[0] = e.target.value;
                                        setcompany2title(company2titles);
                                    }} required />
                                )
                            }
                            else{
                                return(
                                    <div className='d-flex'>
                                        <input className='form-control mt-1' value={company2title[index]} onChange={(e)=>{
                                        let company2titles = [...company2title];
                                        company2titles[index] = e.target.value;
                                        setcompany2title(company2titles);
                                        }} required />
                                        <div className='ms-2' onClick={()=>{deletecompany2(id, index)}}>
                                            <CancelIcon style={{color: "#dc3545"}} />
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                <button className='btn btn-primary btn-sm mt-1' type='button' onClick={()=>{addcompany2()}}>Add New Field <AddCircleOutlinedIcon /> </button>
            </div>
        </div>
        <div>
            <div className='mt-2 fw-bold'>
                Introduction
            </div>
            <div className='mt-2'>
                <textarea className='form-control' ref={introRef} required />
            </div>
        </div>
        <div>
            <div className='mt-2 fw-bold'>
                Image
            </div>
            <div className='mt-2'>
                <input className='form-control' ref={imageRef} type='file' id="imageInput" onChange={(e)=>{
                setimg(e.target.files[0]);
            }} accept=".jpeg, .jpg, .png, .gif, .svg"  required />
            </div>
        </div>
        <Box className="mx-auto" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
        </Box>
        <div className='mt-3'>
            <button className='btn btn-primary' ref={btnRef}  type='submit'>Save</button>
        </div>
      </form>
      <br/>
    </div>
  )
}
