import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { url } from '../../../Config';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


export default function AddCategory(props) {
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [Classification, setClassification] = useState([]);
    const [Categoryheading, setCategoryheading] = useState([]);
    const [classificationVal, setclassificationVal] = useState(0);
    const [categoryheadingVal, setcategoryheadingVal] = useState(0);
    const [CategoryName, setCategoryName] = useState('');

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/createCategory`);
            const resp = res.data;
            setClassification(resp.classification)
            setCategoryheading(resp.catHeading)
        }
        func();
    },[])

    const submitform = async (event)=>{
        event.preventDefault();
        if(classificationVal == 0 || classificationVal == "0"){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Option from Classification First!`);
            return ;
        }
        if(categoryheadingVal == 0 || categoryheadingVal == "0"){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Category Heading First!`);
            return ;
        }

        const res = await axios.post(`${url}/api/storeCategory`,{
            'pacra_policy_classification_id': classificationVal,
            'pacra_policy_cat_heading_id': categoryheadingVal,
            'category_title': CategoryName
        });
        const resp = res.data;
        if(resp.status == true){
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
    }


  return (
    <div style={{height: '90vh'}}>
        <form className='mt-5 col-10 mx-auto card shadow p-3' onSubmit={(event)=>{submitform(event)}}>
        <Box className="mx-auto" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
            </Box>
            <div className='fs-4 fw-bold text-center'>
                Add New Category
            </div>
            <div className='mt-3'>
                <div className='fs-6 fw-bold'>
                    Classification
                </div>
                <div className='mt-1'>
                    <select className='form-select' onChange={(e)=>{setclassificationVal(e.target.value)}}>
                        <option value={0}>Select Classification</option>
                        {
                            Classification.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                );
                            })
                        }
                    </select>
                </div>
                <div className='fs-6 fw-bold mt-2'>
                    Category Heading
                </div>
                <div className='mt-1'>
                    <select className='form-select' onChange={(e)=>{setcategoryheadingVal(e.target.value)}}>
                        <option value={0}>Select Category Heading</option>
                        {
                            Categoryheading.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                );
                            })
                        }
                    </select>
                </div>
                <div className='fs-6 fw-bold mt-2'>
                    Category Name
                </div>
                <div className='mt-1'>
                    <input className='form-control' value={CategoryName} onChange={(e)=>{setCategoryName(e.target.value)}} placeholder='Enter Category Name' required/>
                </div>
                <div className='mt-2'>
                    <button className='btn btn-primary btm-sm' type='submit'>Save</button>
                </div>
            </div>
        </form>

    </div>
  )
}
