import React, {useState, useEffect, useRef} from 'react';
import Select from "react-select";
import axios from 'axios';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { url } from '../../../Config';



export default function Opinion_transfer() {

    const [opdata, setopdata] = useState([]);
    const [opiniondata, setopiniondata] = useState([]);
    const [team, setteam] = useState([]);
    const [team_lead, setteam_lead] = useState('');
    const [team_lead_id, setteam_lead_id] = useState(null);
    const [cro, setcro] = useState([]);
    const [analyst, setanalyst] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState();
    const [opinionid, setopinionid] = useState();
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const id = localStorage.getItem("userID");
    const desig = localStorage.getItem("designation");
    const targetRef = useRef(null);
    const btn = useRef(null);
    const uh = useRef(null);
    const tl = useRef(null);
    const anl = useRef(null);
    const [bool1, setbool1] = useState(false);

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/optransferuh/${id}/${desig}`);
            const resp = res.data;
            const mergedArray = Array.from(new Map([...resp.osOpinions1, ...resp.initials].map(obj => [obj.Id, obj])).values());
            const mappedOptions = mergedArray.map(item => ({ value: item.Id, label: item.name }));
            setopiniondata(mergedArray);
            setopdata(mappedOptions);

            const res2 = await axios.get(`${url}/api/getTeamAndAnalystList/${id}`);
            const resp2 = res2.data;
            setteam(resp2.team);
            if(id !== "19"){
                setanalyst(resp2.team[0].analyst);
                setteam_lead(resp2.team[0].display_name)
                setteam_lead_id(resp2.team[0].id)
            }
            setcro(resp2.cro);

        }
        func()
    },[])

    const submit = async ()=>{
        if(tl.current.value == 0){
            setalertOpen(true);
            setalertmessage("Select Team Lead and Analyst First");  
            setalertType("error")
            return;
        }

        const cro = uh.current.value;
        const analyst = anl.current.value;
        const team_lead = tl.current.value;
        const op_ids = opinionid;

        const res = await axios.post(`${url}/api/opTransferDoNew`,{
            "id" : Number(id),
            "id_uh" : cro,
            "id_team" : team_lead,
            "id_analyst" : analyst,
            "op_ids" : op_ids
        });

        const resp = res.data;
        if(resp.status == true){
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("success");
        }
        else{
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error");
        }


    }
      

    function handleSelect(data) {
        const arr = []
        
        for(let i in data){
            arr.push(data[i].value)
        }

        if(data.length == 1){
            if(id == "19"){
                const filteredData = opiniondata.filter(item => item.Id === data[0].value);
                tl.current.value = filteredData[0].manager_id;
                const filteredteam = team.filter(item => item.id === filteredData[0].manager_id);
                setteam_lead(filteredData[0].manager_name);
                setteam_lead_id(filteredData[0].manager_id);
                console.log(filteredData[0]);
                setanalyst(filteredteam[0].analyst);
                setbool1(!bool1)
            }
            else{
                const filteredData = opiniondata.filter(item => item.Id === data[0].value);
                if(anl.current !== null){
                    anl.current.value = filteredData[0].analystId;
                }
            }
        }
        else {
            
            if(id == "19"){
                tl.current.value = 0;
                setanalyst([]);
            }
        }

        setSelectedOptions(data);
        setopinionid(arr);
        if(data.length > 0){
            btn.current.hidden = false;
        }
        else{
            btn.current.hidden = true;
        }
    }

    useEffect(()=>{
        if (anl.current !== null) {
            const filteredData = opiniondata.filter(item => item.Id === selectedOptions[0].value);
            anl.current.value = filteredData[0].analystId;
          }
    },[bool1])

  return (
    <div style={{height: "90vh"}}>
        <div className='mx-auto mt-3' style={{width: "fit-content"}}>
            <button className='btn btn-success' onClick={()=>{window.open(`/opinion-transfer-log`, '_blank')}}>Transfer Log</button>
        </div>
        <div className='card shadow mt-2 col-10 mx-auto p-3'>
            <div className='fs-4 fw-bold mt-2 text-center text-decoration-underline' style={{color: "#000078"}}>
                Opinion(s) Transfer
            </div>
            <Box className="mx-auto" ref={targetRef} sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
            </Box>
            <div className='col-10 mx-auto mt-3 fw-bold fs-6'>
                <div className='text-decoration-underline'>
                    Opinion(s):
                </div>
                <div className='col-10 mt-1'>
                    <Select
                        options={opdata}
                        placeholder="Select Opinion"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isSearchable={true}
                        isMulti
                        />
                </div>

                <div className='text-decoration-underline mt-2'>
                    CRO:
                </div>
                <div className='col-10 mt-1'>
                    <select className='form-select' ref={uh}>
                    {
                        cro.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.display_name}</option>
                            )
                        })
                    }
                    </select>
                </div>

                <div className='text-decoration-underline mt-2'>
                    Team:
                </div>
                <div className='col-10 mt-1'>
                    <select className='form-select' ref={tl} onChange={(e)=>{
                        for(let i in team){
                            if(team[i].id == e.target.value){
                                setteam_lead_id(team[i].id);
                                setteam_lead(team[i].display_name);
                                setanalyst(team[i].analyst)
                                return;
                            }
                        }
                    }}>
                    {id == "19" && <option value={0}>Select Team Lead</option>}
                    {
                        team.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.display_name}</option>
                            )
                        })
                    }
                    </select>
                </div>

                {   
                    analyst.length > 0 &&
                    <>
                        <div className='text-decoration-underline mt-2'>
                            Analyst:
                        </div>
                        <div className='col-10 mt-1'>
                        <select className='form-select' ref={anl}>
                            {
                                analyst.map((value, index)=>{
                                    return(
                                        <option value={value.id}>{value.display_name}</option>
                                    )
                                })
                            }
                        <option value={team_lead_id}>{team_lead}</option>

                        </select>
                        </div>
                    
                    </>
                }

                <div className='mt-3' style={{width: "fit-content"}} ref={btn} hidden={true}>
                    <button className='btn btn-primary' onClick={()=>{submit()}}>Submit</button>
                </div>
            </div>

        </div>
    
    </div>
    
  )
}
