import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../Config';
import { Check, Clear, Event } from "@material-ui/icons";
import Select from "react-select";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { event } from 'jquery';
const id = localStorage.getItem("userID");


export default function Edit_sector_study(props) {

    const [source, setsource] = useState([]);
    const { ss_id } = useParams();
    const Navigate = useNavigate();
    const [doc, setdoc] = useState([]);
    const [group, setgroup] = useState([]);
    const [industries, setindustries] = useState([]);
    const [sectors, setsectors] = useState([]);
    const [date, setdate] = useState([]);
    const [file, setfile] = useState(null);
    const [img_file, setimg_file] = useState(null);
    const [groupval, setgroupval] = useState('');
    const [industriesval, setindustriesval] = useState('');
    const [sectorsval, setsectorsval] = useState('');
    const [sourceval, setsourceval] = useState('');
    const [docval, setdocval] = useState('');
    const [docname, setdocname] = useState('');
    const [sectorname, setsectorname] = useState('');
    const [team, setteam] = useState([]);
    const [teamlead_id, setteamlead_id] = useState(null);
    const [teamlead_name, setteamlead_name] = useState(null);
    const [teamlead_val, setteamlead_val] = useState(null);
    const [cteamlead_id, setcteamlead_id] = useState(null);
    const [sector_study, setsector_study] = useState({});
    const [currentDate, setCurrentDate] = useState('');
    const id = localStorage.getItem("userID");
    const [bool, setbool] = useState(false);


    const sourceRef = useRef(null);
    const docRef = useRef(null);
    const fileref = useRef(null);
    const imageRef = useRef(null);
    const textRef = useRef(null);
    const teamRef = useRef(null);
    const dateRef = useRef(null);
    const date2Ref = useRef(null);
    const supRef = useRef(null);
    const ssnameRef = useRef(null);
    const btnRef = useRef(null);
    const btnRef2 = useRef(null);


    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [can_approve_ss, setcan_approve_ss] = useState(null);
    const [status, setstatus] = useState(null);

    useEffect(()=>{

        const func = async ()=>{

            const res = await axios.get(`${url}/api/get_edit_sector_study/${ss_id}/${id}`);
            const resp = res.data;
            setsector_study(resp.sector_study[0]);
            setsource(resp.rating_agencies);
            setdoc(resp.doc_types);
            setgroup(resp.group);
            for(let i in resp.group){
                if(resp.group[i].id == resp.sector_study[0].segment_id){
                    setindustries(resp.group[i].industries);
                    for(let j in resp.group[i].industries){
                        if(resp.group[i].industries[j].id == resp.sector_study[0].main_sector_id){
                            setsectors(resp.group[i].industries[j].sectors);
                        }
                    }
                }
            }
            setcan_approve_ss(resp.can_approve_ss);
            setdocname(resp.sector_study[0].attribute);
            setsectorname(resp.sector_study[0].sector_title);
            setstatus(resp.sector_study[0].status);

            setteam(resp.respectiveTeams)
            setsourceval(resp.sector_study[0].source);
            setdocval(resp.sector_study[0].attribute);
            setgroupval(resp.sector_study[0].segment_id);
            setindustriesval(resp.sector_study[0].main_sector_id);
            setsectorsval(resp.sector_study[0].sector_id);
            if(ssnameRef.current){
                ssnameRef.current.value = resp.sector_study[0].name;
            }
            if(textRef.current){
                textRef.current.value = resp.sector_study[0].text;
            }
            setteam(resp.respectiveTeams);
            setteamlead_id(resp.sector_study[0].team_lead_id);
            setteamlead_val(resp.sector_study[0].team_lead_id);
            setteamlead_name(resp.sector_study[0].team_lead);
            setcteamlead_id(resp.respectiveTeams[0].id);
            setdate(resp.sector_study[0].date);

            // setscale(resp.rating_scales);
            // const groups = resp.segments.map(item => ({ value: item.id, label: item.title }));
            // setgroup(groups);
            // const industries = resp.main_sectors.map(item => ({ value: item.id, label: item.title }));
            // setindustry(industries);
            // const sec = resp.sectors.map(item => ({ value: item.id, label: item.title }));
            // setsector(sec);

        }

        func();
    },[ssnameRef.current, bool])

    useEffect(() => {
        // Function to get the current date in the format "YYYY-MM-DD"
        const getCurrentDate = () => {
          const now = new Date();
          const year = now.getFullYear();
          const month = (now.getMonth() + 1).toString().padStart(2, '0');
          const day = now.getDate().toString().padStart(2, '0');
          return `${year}-${month}-${day}`;
        };
    
        setCurrentDate(getCurrentDate());
      }, []);

      const editForm = async (event)=>{
        event.preventDefault();
        btnRef.current.disabled = true;
        btnRef.current.innerHTML = "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";
        const fileData = new FormData();


        fileData.append("source", sourceval);
        fileData.append("docType", docval);
        fileData.append("userId", id);
        fileData.append("segment", groupval);
        fileData.append("main_sector", industriesval);
        fileData.append("sub_sector", sectorsval);
        fileData.append("ss_name", ssnameRef.current.value);
        fileData.append("team_lead", teamlead_val);
        fileData.append("date", date2Ref.current.value);
        fileData.append("write_up", textRef.current.value);
        fileData.append("sector_study_id", ss_id);
        fileData.append("old_file_path", sector_study.file);
        fileData.append("old_image_path", sector_study.image);
        if(file !== null && file!== undefined){
            fileData.append("file", file);
        }
        if(img_file !== null && img_file!== undefined){
            fileData.append("image", img_file);
        }

        const res = await axios.post(`${url}/api/edit_sector_study`,
        fileData,
        {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        })
        const resp = res.data;
        if(resp.status == true){
            btnRef.current.disabled = false;
            btnRef.current.innerHTML = 'Update';
            setbool(!bool);
            // Navigate('/sector_study');
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btnRef.current.disabled = false;
            btnRef.current.innerHTML = 'Update';
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
      }
      const Approve = async ()=>{
        btnRef2.current.disabled = true;
        btnRef2.current.innerHTML = "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";
        const fileData = new FormData();


        fileData.append("source", sourceval);
        fileData.append("docType", docval);
        fileData.append("userId", id);
        fileData.append("segment", groupval);
        fileData.append("main_sector", industriesval);
        fileData.append("sub_sector", sectorsval);
        fileData.append("ss_name", ssnameRef.current.value);
        fileData.append("team_lead", teamlead_val);
        fileData.append("date", date2Ref.current.value);
        fileData.append("write_up", textRef.current.value);
        fileData.append("sector_study_id", ss_id);
        fileData.append("old_file_path", sector_study.file);
        fileData.append("old_image_path", sector_study.image);
        if(file !== null && file!== undefined){
            fileData.append("file", file);
        }
        if(img_file !== null && img_file!== undefined){
            fileData.append("image", img_file);
        }

        const res = await axios.post(`${url}/api/approve_sector_study`,
        fileData,
        {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        })
        const resp = res.data;
        if(resp.status == true){
            btnRef2.current.disabled = false;
            btnRef2.current.innerHTML = "Approve";
            setbool(!bool);
            // Navigate('/sector_study');
            setalertType("success");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
        else{
            btnRef2.current.disabled = false;
            btnRef2.current.innerHTML = "Approve";
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
      }


  return (
    <div>
      <div className='fs-3 fw-bold text-center mt-2 mb-3' style={{color: "#000078"}}>
            Edit Sector Study
        </div>
        <div>
            <form className='card shadow col-11 mx-auto p-2' onSubmit={(event)=>{editForm(event)}}>
                <div>
                    <div className='mt-2 fw-bold'>
                        Source
                    </div>
                    <div className='mt-2'>
                        <select className='form-select' ref={sourceRef} value={sourceval} onChange={(e)=>{setsourceval(e.target.value)}}>
                           <option value=''>Select Source</option>
                           {
                            source.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.name}</option>
                                )
                            })
                           }
                        </select>
                    </div>
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Document Type
                    </div>
                    <div className='mt-2'>
                        <select className='form-select' ref={docRef} id='doctype' value={docval} onChange={(e)=>{
                                setdocval(e.target.value)
                                let doc = document.getElementById('doctype');
                                let doc_name = doc.options[doc.selectedIndex].text
                                setdocname(doc_name);
                            }} required>
                           <option value="">Select Document</option>
                           {
                            doc.map((value, index)=>{
                                return(
                                    <option value={value.title}>{value.title}</option>
                                )
                            })
                           }
                        </select>
                    </div>
                </div>
                <div>
                <div className='mt-2 fw-bold'>
                    Group:
                </div>
                <div className='mt-2'>
                    <select className='form-select' value={groupval} onChange={(e)=>{
                        setgroupval(e.target.value);
                        if(e.target.value == ''){
                            setsectors([]);
                            setindustries([]);
                        }
                        else{
                            for(let i in group){
                                if(group[i].id == e.target.value){
                                    setindustries(group[i].industries);
                                }
                            }
                        }
                        }
                        
                        }>
                        <option value={''}>Select Group</option>
                        {
                            group.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Cluster:
                </div>
                <div className='mt-2'>
                    <select className='form-select' value={industriesval} onChange={(e)=>{
                        setindustriesval(e.target.value)
                        if(e.target.value == ''){
                            setsectors([]);
                        }
                        else{
                            for(let i in industries){
                                if(industries[i].id == e.target.value){
                                    setsectors(industries[i].sectors);
                                }
                            }
                        }
                        }}>
                        <option value={''}>Select Industry</option>
                        {
                            industries.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Sector/Industry:
                </div>
                <div className='mt-2'>
                    <select className='form-select' id='sectorname' value={sectorsval} onChange={(e)=>{
                        setsectorsval(e.target.value);
                        let sector = document.getElementById('sectorname');
                        let sector_name = sector.options[sector.selectedIndex].text
                        setsectorname(sector_name);
                        }}>
                        <option value={''}>Select Sector</option>
                        {
                            sectors.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Sector Study Name:
                </div>
                <div className='mt-2 fw-bold'>
                    <input className='form-control' ref={ssnameRef} readOnly value={`${docname} | ${sectorname}`} />
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Sector Owner:
                </div>
                <div className='mt-2 fw-bold'>
                    <select className='form-select' ref={teamRef} value={teamlead_val} onChange={(e)=>{
                       setteamlead_val(e.target.value);
                        }}>
                        {
                            teamlead_id !== cteamlead_id ?
                            <option value={teamlead_id}>{teamlead_name}</option> : null
                        }
                        {
                            team.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.display_name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            {/* <div>
                <div className='mt-2 fw-bold'>
                    Supervise By:
                </div>
                <div className='mt-2 fw-bold'>
                    <input className='form-control' ref={supRef} placeholder='Enter The Supervise Name' />
                </div>
            </div> */}
            <div>
                <div className='mt-2 fw-bold'>
                    Uploaded Date:
                </div>
                <div className='mt-2 fw-bold'>
                    <input className='form-control' type='date' value={date} ref={dateRef} readOnly />
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Approval Date:
                </div>
                <div className='mt-2 fw-bold'>
                    <input className='form-control' type='date' value={currentDate} ref={date2Ref} readOnly />
                </div>
            </div>
            <div>
                    <div className='mt-2 fw-bold'>
                        Upload File
                    </div>
                    <div className='mt-2'>
                        <input className='form-control' ref={fileref} type='file' id="docInput" onChange={(e)=>{
                            setfile(e.target.files[0])
                        }} />
                    </div>
                    {(sector_study.file !== null && sector_study.file !== '') &&
                    <div className='mt-1'>
                        Uploaded File: <a href={`https://209.97.168.200/pacrawizpackv3/public/view/storage/app/${sector_study.file}`} target='_blank'>File</a>
                    </div>}
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Upload Image:
                    </div>
                    <div className='mt-2'>
                        <input className='form-control' ref={imageRef} type='file' id="imgInput" accept=".jpeg, .jpg, .png, .gif, .svg" onChange={(e)=>{
                            setimg_file(e.target.files[0])
                        }} />
                    </div>
                    {(sector_study.image !== null && sector_study.image !== '') &&
                    <div className='mt-1'>
                        Uploaded Image: <a href={`https://209.97.168.200/pacrawizpackv3/public/view/public/sectorStudy/${sector_study.image}`} target='_blank'>File</a>
                    </div>}
                </div>
                <div>
                    <div className='mt-2 fw-bold'>
                        Write Up
                    </div>
                    <div className='mt-2'>
                        <textarea className='form-control' placeholder='You can Type Maximum 400 characters here'  maxLength={400} ref={textRef}/>
                    </div>
                </div>
                <Box className="mx-auto mt-2 mb-2" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
                </Box>
                <div>
                    <button className='btn btn-primary mt-3 mt-2 me-1' type='submit' ref={btnRef}>Update</button>
                    {can_approve_ss != null ? status == 'Entered' && <button className='btn btn-success mt-3 mt-2' type='button' ref={btnRef2} onClick={()=>{Approve()}}>Approve</button> : null}
                </div>
            </form>
            <br/>
        </div>
        <br/>
    </div>
  )
}
