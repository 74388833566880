import React, {useState, useEffect} from 'react';
import axios from 'axios'
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import TableComponent from '../../../Atoms/TableComponent';
import {encryptParams} from "../../encrypt_url";
import { url } from '../../../../Config';



export default function Update(props) {

    const [data, setdata] = useState([]);

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/subtasklist`);
            const resp = res.data;
            setdata(resp);
        }
        func();
    },[])

     
    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };

      function innerNotiDate(params) {
        if (params.data.notification_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.notification_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };
      const responsiveColumnPin2 = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "right";
        }
      };
      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };

      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Opinion Name",
          minWidth: 250,
          field: "name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Analyst",
          minWidth: 250,
          field: "analyst",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Tab",
          minWidth: 250,
          field: "tab",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Task Title",
          minWidth: 250,
          field: "title",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "File Name",
          minWidth: 250,
          field: "task_file",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: (params)=>{
            return(
                <div>
                    <a href={`https://209.97.168.200/pacrawizpackv3/public/api/storage/app/storage/task_files/${params.value}`} target='_blank'>{params.value}</a>
                </div>
            )
          },
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Link",
          minWidth: 250,
          field: "",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: (params)=>{
            if(params.data.tab == 'Information'){
                const client_id = params.data.opinion_id;
                const og_rating_id = params.data.record_id;
                const encryptedParams = encryptParams({client_id, og_rating_id})
                return (
                    <div>
                        <a href={`/Ratings/addTask/${params.data.tab}/${encryptedParams}`} target='_blank'>Open</a>
                    </div>
                )
            }
            else if(params.data.tab == 'Site Visit'){
                const client_id = params.data.opinion_id;
                const og_rating_id = params.data.record_id;
                const encryptedParams = encryptParams({client_id, og_rating_id})
                return (
                    <div>
                        <a href={`/Ratings/addTask/SiteVisit/${params.data.opinion_id}/${params.data.record_id}`} target='_blank'>Open</a>
                    </div>
                )
            }
            else{
                
                return (
                    <div>
                        <a href={`/Ratings/addTask/${params.data.tab}/${params.data.opinion_id}/${params.data.record_id}`} target='_blank'>Open</a>
                    </div>
                )
            }
          },
          tooltipField: "",
          pinned: responsiveColumnPin(),
        },

    ]

    const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
          <Table className="overflow-scroll responsiveTableFonts">
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                NL
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerNotiDate(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Old File Name
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.old_file_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                New File Name
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.new_file_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Old File
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.old_file !== null ? <a href={`${url}/api/storage/app/storage/task_files/${params.data.old_file}`} target='_blank'> Old File </a> : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                New File
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.new_file !== null ? <a href={`${url}/api/storage/app/storage/task_files/${params.data.new_file}`} target='_blank'> New File </a> : "-"}
              </TableCell>
            </TableRow>
          </Table>
        </h1>
      );

  return (
    <div>
      <TableComponent
      Data={data}
      screenWidth={props.screenWidth}
      MobViewRender={MobViewRender}
      columnDefs={columnDefs}
      datefilters={datefilters}
      datefilter={false}
       />
    </div>
  )
}
