import axios from 'axios';
import React, {useState, useEffect, useRef} from 'react';
import { useParams } from "react-router-dom";
import { url } from '../../../Config';
import { encryptParams } from '../encrypt_url';


export default function Opinionsearchresult(props) {

  const { search } = useParams();
  const [pacraop, setpacraop] = useState([]);
  const [vis, setvis] = useState([]);
  const [otherop, setotherop] = useState([]);
  const [message, setmessage] = useState();

  useEffect(()=>{
    const func = async ()=>{
        setpacraop([]);
        setvis([]);
        setotherop([]);
        setmessage('Searching...');
        const res = await axios.get(`${url}/api/opinionresult/${search}`);
        const resp = res.data;
        setpacraop(resp.opinions_pacra);
        setvis(resp.opinions_jcr);
        setotherop(resp.opinions_pacra2);
        setmessage(resp.message);
    }

    func();
  },[search])

  const encryptit = (client)=>{
    const clientId = client;
    const encryptedParams = encryptParams({clientId});
    return encryptedParams;
  }

  return (
    <div>
        <div className='fs-5 mt-3 mb-2 ms-2'>
            <b>Searched for:</b> {search}
        </div>
        <div className='fs-3 mt-1 text-center'>
            {message}
        </div>
        <div className='mt-2 p-2'>
            {
                pacraop.length > 0 ?
                <table className='table table-striped table-hover table-bordered'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Entity</th>
                            <th>Industry</th>
                            <th>Rating Type</th>
                            <th>Action</th>
                            <th>CRA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            pacraop.map((value, index)=>{
                                return(
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td><a href={`/OpinionDetails/${encryptit(value.id)}`} target='_blank'>{value.name}</a></td>
                                        <td>{(value.og_main_sectors !== null && value.og_main_sectors !== undefined) ? value.og_main_sectors.title : '-'}</td>
                                        <td>{(value.og_ratings.og_rating_scale !== null && value.og_ratings.og_rating_scale !== undefined) ? value.og_ratings.og_rating_scale.title : '-'}</td>
                                        <td>{(value.og_ratings.og_action !== null && value.og_ratings.og_action !== undefined) ? value.og_ratings.og_action.title : '-'}</td>
                                        <td>PACRA</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                : null
            }
        </div>
        <div className='mt-2 p-2'>
            {
                otherop.length > 0 ?
                <table className='table table-striped table-hover table-bordered'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Entity</th>
                            <th>Status</th>
                            <th>CRA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            otherop.map((value, index)=>{
                                return(
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td><a href={`/OpinionDetails/${encryptit(value.id)}`} target='_blank'>{value.name}</a></td>
                                        <td className='fw-bold'>Not Initiated</td>
                                        <td>PACRA</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                : null
            }
        </div>
        <div className='mt-2 p-2'>
            {
                vis.length > 0 ?
                <table className='table table-striped table-hover table-bordered'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Entity</th>
                            <th>Industry</th>
                            <th>Rating Type</th>
                            <th>Action</th>
                            <th>CRA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            vis.map((value, index)=>{
                                return(
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{(value.og_companies !== null && value.og_companies !== undefined) ? <a href={`/vis-history/${value.client_id}`} target='_blank'>{value.og_companies.name}</a>  : '-'}</td>
                                        <td>{(value.og_companies.og_main_sectors !== null && value.og_companies.og_main_sectors !== undefined) ? value.og_companies.og_main_sectors.title : '-'}</td>
                                        <td>{(value.og_rating_scale !== null && value.og_rating_scale !== undefined) ? value.og_rating_scale.title : '-'}</td>
                                        <td>{(value.og_action !== null && value.og_action !== undefined) ? value.og_action.title : '-'}</td>
                                        <td>VIS</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                : null
            }
        </div>
    </div>
  )
}
