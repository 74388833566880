import React, {useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import TableComponent from '../../../Atoms/TableComponent';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import HistoryIcon from '@mui/icons-material/History';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import { Check, Clear, Event } from "@material-ui/icons";
import { url } from '../../../../Config';
import axios from 'axios';
import ApprovalIcon from '@mui/icons-material/Approval';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';


export default function Sponsors(props) {
    const Navigate = useNavigate();
    const userid = localStorage.getItem("userID");

    const [data, setdata] = useState([]);
    const [bool, setbool] = useState(false);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [count, setCount] = useState();


    const SponsorsRef = useRef(null);
    const DirectorsRef = useRef(null);
    const CriteriaRef = useRef(null);
    const RCRef = useRef(null);
    const SECPRef = useRef(null);

    useEffect(()=>{
        setdata([])
        const func = async ()=>{
            const res = await axios.get(`${url}/api/pacraShareholders`);
            const resp = res.data.pacraShareholders;
            let total_share = 0;
            let total_percent = 0;
            for(let i in resp){
              total_share = total_share + Number(resp[i].no_of_shares);
              let to_number = parseFloat(resp[i].shares_percent);
              total_percent = total_percent + to_number;
              resp[i].no_of_shares = resp[i].no_of_shares.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              if(Number.isInteger(to_number)){
                resp[i].shares_percent = `${to_number.toFixed(2)}%`;
              }
            }
            total_share = total_share.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            resp.push({})
            resp[resp.length - 1].no_of_shares = `Total: ${total_share}`;
            resp[resp.length - 1].shares_percent = `Total: ${total_percent}%`
            resp[resp.length - 1].name = `total`
            setCount(resp.length)
            setdata(resp.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)));
        }
        func();
    },[bool])

    const deleteitem = async(id)=>{
        Swal.fire({ title: 'Do you want to delete this Record?', 
    showDenyButton: false, showCancelButton: true, confirmButtonText: 'Delete'})
    .then(async(result) => {
        
      if (result.isConfirmed) {
        const res = await axios.get(`${url}/api/DeleteShareholders/${id}/${userid}`);
        const resp = res.data;
        if(resp.status == true){
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success");
            setbool(!bool);
        }
        else{
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error")
        }
      }
    })
        
    }

    const responsiveColumns = () => {
      if (props.screenWidth < 770) {
        return null;
      } else {
        return 120;
      }
    };
    function innerDate(params) {
      if (params.data.updated_at == null) {
        return "-";
      } else {
        const date = new Date(params.data.updated_at);
        const yyyy = date.getFullYear();
        const yy = yyyy.toString();
        const y = yy.slice(2, 4);
        let mm = date.toLocaleString("default", { month: "short" });
        let dd = date.getDate();
        if (dd < 10) dd = "0" + dd;
        return dd + "-" + mm + "-" + y;
      }
    }

      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };

      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null || params.value == 'total') {
          return "-";
        } else {
          return params.value;
        }
      };

      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };

    const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Name",
          minWidth: 250,
          field: "name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },

        {
          headerName: "No of Shares",
          field: "no_of_shares",
          minWidth: responsiveColumns(),
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: cellrander,
          tooltipField: "title",
          // pinned: responsiveColumnPin(),
        },
  
        {
          headerName: "Percentage",
          field: "shares_percent",
          minWidth: responsiveColumns(),
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: cellrander,
          tooltipField: "title",
        },
        {
          headerName: "Association",
          field: "association",
          minWidth: responsiveColumns(),
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: cellrander,
          tooltipField: "title",
        },
        {
          headerName: "Position",
          field: "position_company",
          minWidth: responsiveColumns(),
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: cellrander,
          tooltipField: "title",
        },

        {
            headerName: "Status",
            minWidth: 150,
            field: "status",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            hide: columnHide(),
            cellRenderer: (params)=>{
                if(params.value == 'approve'){
                    return(
                        <div className='text-capitalize' style={{color: 'green'}}>Approved</div>
                    )
                }
                else{
                    return(
                        <div className='text-capitalize'>{params.value}</div>
                    )
                }
            },
            tooltipField: "status",
        },

        {
            headerName: "Action",
            minWidth: 150,
            field: "",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
                if(params.data.status == 'submit'  || userid == '207'){
                  if(params.data.name !== 'total'){
                      return(
                          <div>
                              {/* <Tooltip title="Approve"><button className='btn btn-success btn-sm me-1 mb-1' onClick={()=>{approve(params.data.id)}}><ApprovalIcon /></button></Tooltip> */}
                              <Tooltip title="View"><VisibilityIcon sx={{color: 'blue'}} onClick={()=>{Navigate(`/view-shareholder/${params.data.id}`)}} /></Tooltip>
                              <Tooltip title="Delete"><DeleteIcon sx={{color: 'red'}} onClick={()=>{deleteitem(params.data.id)}}/></Tooltip>
                          </div>
                      )
                  }
                  else{
                    return null;
                  }
                }
                else{
                  if(params.data.name !== 'total'){
                    return(
                        <div>
                            {params.data.id !== undefined && <Tooltip title="View"><VisibilityIcon sx={{color: 'blue'}} onClick={()=>{Navigate(`/view-shareholder/${params.data.id}`)}} /></Tooltip>}
                        </div>
                    )
                  }
                  else{
                    return null;
                  }
                }
                
            },
        },
             
      ];

      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
           <Table className="overflow-scroll responsiveTableFonts">
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                No of Shares
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.no_of_shares}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Percentage
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.shares_percent}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Association
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.association}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Position
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.position_company}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Status
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.status}
              </TableCell>
            </TableRow>
          </Table>
        </h1>
      );

  return (
    <div>
      <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
        Management Details
      </div>
      <div className='mt-1 p-5 row'>
        <div className='card shadow fs-6 fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={SponsorsRef} style={{backgroundColor: "#000078", color: "white"}}
        onClick={()=>{Navigate('/secp-dashboard')}}>
            1. Sponsors/Shareholders
        </div>
        <div className='card shadow fs-6 fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={DirectorsRef} onClick={()=>{Navigate('/directors-view')}}>
            2. Directors
        </div>
        <div className='card shadow fs-6 fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={CriteriaRef} onClick={()=>{Navigate('/criteria-view')}}>
            3. Criteria Group
        </div>
        <div className='card shadow fs-6 fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={RCRef} onClick={()=>{Navigate('/rcreport-view')}}>
            4. Rating Committee
        </div>
        <div className='card shadow fs-6 fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={SECPRef} onClick={()=>{Navigate('/secp-view')}}>
            5. SECP Reports
        </div>
        <hr />
        <div className='mt-2 mb-2'>
            <button className='btn btn-primary me-2 mb-lg-0 mb-md-0 md-sm-1 mb-1' onClick={()=>{Navigate('/add-shareholder')}}>Add New Sponsor/Shareholder</button>
        </div>
        <Box className="mx-auto mb-1 mt-1" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
        </Box>
        <TableComponent
            Data={data}
            screenWidth={props.screenWidth}
            MobViewRender={MobViewRender}
            columnDefs={columnDefs}
            datefilters={datefilters}
            datefilter={false}
            secp = {true}
            managementdetails = {true}
            detail = {'shareholder'}
        />
      </div>
    </div>
  )
}
