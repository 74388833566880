import React from 'react';
import { url } from '../../../Config';

export default function News_letter(props) {
  return (
    <div>
      <iframe src={`https://209.97.168.200/pacrawizpackv3/public/api/get_news_letter`} style={{width: '100%', height: '100vh'}}> title="description"></iframe>

    </div>
  )
}
