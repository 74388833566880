import axios from 'axios';
import React, {useState, useEffect} from 'react'
import TableComponent from '../../../Atoms/TableComponent';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { NavLink } from "react-router-dom";
import { Check, Clear, Event } from "@material-ui/icons";
import { Table, TableCell, TableRow } from "@mui/material";
import { url } from '../../../../Config';
import { encryptParams } from '../../encrypt_url';

export default function Mandetes_to_upload(props) {
    const [data, setdata] = useState([]);
    const [count, setcount] = useState();
    useEffect(()=>{

        const func = async ()=>{
            let arr = [];
            let arr2 = [];
            let arr3 = [];
            const res = await axios.get(`http://127.0.0.1:8000/api/rcsignmissing`);
            const resp = res.data;
            for(let i in resp){
              let external = resp[i].rc_external.split(',');
              let approvalids = resp[i].approvalIds.split(',');
              let allIncluded = external.every(element => approvalids.includes(element));

                if (allIncluded) {
                    arr3.push(resp[i]);
                } else {
                    arr2.push(resp[i]);
                }

            }

            console.log(arr2, 'arr2');
            console.log(arr3, 'arr3');
            // for(let i in resp){
            //     if(resp[i].rating_mandate !== 'uploads/fee//' && resp[i].rating_mandate !== null && resp[i].rating_mandate !== 'uploads/mandates' && resp[i].rating_mandate !== 'uploads/mandates ' && resp[i].rating_mandate !== 'uploads/mandates/ '){
            //         try {
            //             const res1 = await axios.get(`https://209.97.168.200/pacrawizpackv3/public/view/storage/BD/ratingmandate/${resp[i].rating_mandate}`);
            //         } catch (error) {
            //             console.log('Opinion: ',resp[i].name)
            //             arr.push(resp[i])
            //         }
            //     }
            //     setcount(i);
            // }
            setdata(arr);

        }

        func();

    },[])

    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 210;
        }
      };
      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };
      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
  

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };
      const outstandingdatefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent = gridApi.api.getFilterInstance("Notification");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
        var dateFilterComponent1 = gridApi.api.getFilterInstance("Dissemination");
        dateFilterComponent1.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
      const customComparator = (valueA, valueB) => {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      };
      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          field: "sNo",
          // minWidth: 66,
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },

        {
            headerName: "Opinion",
            minWidth: responsiveColumns(),
            // comparator: customComparator,
            field: "name",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            filterParams: {
              caseSensitive: false,
            },
            cellRenderer: function (params) {
              if (params.value === "nullData") return <Skeleton />;
              else{
      
                if(params.data != null){
                  const clientId = params.value;
                  const encryptedParams = encryptParams({clientId});
                  return (
                    <a
                      href={`/OpinionDetails/${encryptedParams}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary"
                    >
                      {params.value}
                    </a>
                  );
              }
              }
            },
            // cellRenderer: cellrandered,
            tooltipField: "Entity",
            pinned: responsiveColumnPin(),
        },
        {
            headerName: "File Name",
            minWidth: responsiveColumns(),
            // comparator: customComparator,
            field: "rating_mandate",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            filterParams: {
              caseSensitive: false,
            },
            cellRenderer: function (params) {
                return (
                    <a
                      href={`/https://209.97.168.200/pacrawizpackv3/public/view/storage/BD/ratingmandate/${params.value}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary"
                    >
                      {params.value}
                    </a>
                  );
            },
            // cellRenderer: cellrandered,
            tooltipField: "Entity",
            pinned: responsiveColumnPin(),
        },
    
       
        {
          headerName: "H",
          hide: columnHide(),
          field: "Id",
          // hide: true,
          sortable: true,
          filter: "agSetColumnFilter",
          cellRenderer: function (params) {
            if (params.value === "nullData"){
               return <Skeleton />;
            }
            else{
              const clientId = params.value;
              const encryptedParams = encryptParams({clientId});
              return (
                <a
                  href={`/OpinionDetails/${encryptedParams}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Event className="theme_text" />
                </a>
              );
            }
          },
          excelMode: "windows",
        },
  
      ];
      
      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>

        </h1>
      );

  return (
    <div>
      <>
      <div>
        Count: {count}
      </div>
      <TableComponent
        Data={data}
        screenWidth={props.screenWidth}
        MobViewRender={MobViewRender}
        columnDefs={columnDefs}
        datefilters={outstandingdatefilters}
        datefilter={false}
      />
    </>
    </div>
  )
}
