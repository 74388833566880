class Auth {
    User = JSON.parse(localStorage.getItem('loggedIn'))
    constructor() {
        const now = new Date();
        if(this.User !== null && this.User.expiry !== null && this.User.expiry !== undefined){
            if (now.getTime() > this.User.expiry) {
                // If the item is expired, delete the item from storage
                // and return null
                this.authenticated = false;
                localStorage.clear()
    
            }
            else{
                // this.authenticated = this.User ? true : false;
                this.authenticated = true;
            }   
        }
        else{
            localStorage.clear()
            this.authenticated = false;
        }
             
    }

    login = () => {
        this.authenticated = true;
    }

    logout = () => {
        this.authenticated = false;
        localStorage.clear()
    }

    isAuthenticated() {
        
        return this.authenticated;
    }
}

export default new Auth();