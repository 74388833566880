import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { url } from '../../../../Config';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import TableComponent from '../../../Atoms/TableComponent';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';

export default function Edit_module_logs(props) {
    const [data, setdata] = useState([]);
    const id = localStorage.getItem("userID");

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/editModuleLogs/${id}`);
            const resp = res.data.data;
            setdata(resp);
        }
        func();
    },[])

    
    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };

      function innerNotiDate(params) {
        if (params.data.notification_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.notification_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };
      const responsiveColumnPin2 = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "right";
        }
      };
      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };

    const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Opinion Name",
          minWidth: 250,
          field: "name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
        {
            headerName: "NL",
            field: "notification_date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            hide: columnHide(),
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },

        {
          headerName: "Old File Name",
          minWidth: responsiveColumns(),
          field: "old_file_name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: cellrander,
          tooltipField: "RatingScale",
        },

        {
            headerName: "New File Name",
            minWidth: responsiveColumns(),
            field: "new_file_name",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            hide: columnHide(),
            cellRenderer: cellrander,
            tooltipField: "RatingScale",
          },

        {
          headerName: "Old File",
          minWidth: responsiveColumns(),
          field: "old_file",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: (params)=>{
            if(params.value !== null && params.value !== ""){
                return(
                    <a href={`${url}/api/storage/app/storage/task_files/${params.value}`} target='_blank'>Old File</a>
                );
            }
            else{
                return("_");
            }
          },
        },
        {
          headerName: "New File",
          minWidth: responsiveColumns(),
          field: "new_file",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: (params)=>{
            if(params.value !== null && params.value !== ""){
                return(
                    <a href={`${url}/api/storage/app/storage/task_files/${params.value}`} target='_blank'>New File</a>
                );
            }
            else{
                return("_");
            }
          },
        },
        {
            headerName: "Nature",
            minWidth: responsiveColumns(),
            field: "nature",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: cellrander,
            tooltipField: "RatingScale",
          },
        
        
      ];

      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
          <Table className="overflow-scroll responsiveTableFonts">
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                NL
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerNotiDate(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Old File Name
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.old_file_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                New File Name
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.new_file_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Old File
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.old_file !== null ? <a href={`${url}/api/storage/app/storage/task_files/${params.data.old_file}`} target='_blank'> Old File </a> : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                New File
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.new_file !== null ? <a href={`${url}/api/storage/app/storage/task_files/${params.data.new_file}`} target='_blank'> New File </a> : "-"}
              </TableCell>
            </TableRow>
          </Table>
        </h1>
      );

  return (
    <div>
      <TableComponent
      Data={data}
      screenWidth={props.screenWidth}
      MobViewRender={MobViewRender}
      columnDefs={columnDefs}
      datefilters={datefilters}
      datefilter={false}
       />
    </div>
  )
}


