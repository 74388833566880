import React from 'react'
import TableComponent from '../../../Atoms/TableComponent'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import HistoryIcon from '@mui/icons-material/History';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import { Check, Clear, Event } from "@material-ui/icons";
import axios from 'axios';
import { url } from '../../../../Config';


export default function Policygrid(props) {

    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };

      function innerReviewDate(params) {
        if (params.data.review_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.review_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
      function innerNextDate(params) {
        if (params.data.review_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.due_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      function innerEffectiveDate(params) {
        if (params.data.effective_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.effective_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };

      const responsiveColumnPin2 = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "right";
        }
      };

      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };

      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };

    const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Source",
          minWidth: 150,
          maxWidth: 100,
          field: "source_name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Category",
          minWidth: 250,
          field: "category_name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Doc Type",
          minWidth: 250,
          hide: columnHide(),
          field: "doc_type",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
        },
        {
            headerName: "Review Date",
            field: "review_date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            hide: columnHide(),
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },
        {
            headerName: "Effective Date",
            field: "effective_date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            hide: columnHide(),
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },
        {
            headerName: "Next Due Date",
            field: "due_date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            hide: columnHide(),
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },

        {
          headerName: "Days to Deadline",
          minWidth: responsiveColumns(),
          field: "diff_in_deadlines",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: function (params) {
            if (Number(params.value) < 0) {
              return <span className="text-danger"> {params.value} </span>;
            }
            if (
              Number(params.value) == 100000 ||
              Number(params.value) === "" ||
              Number(params.value) == null
            ) {
              return <Skeleton />;
            } else {
              return <span className="text-success"> {params.value} </span>;
            }
          },
          tooltipField: "RatingScale",
        },

        {
            headerName: "Law",
            minWidth: responsiveColumns(),
            field: "law",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            hide: columnHide(),
            cellRenderer: function (params) {
                let arr = [];
                for(let i in params.value){
                    arr.push(params.value[i].title);
                }
                let result = arr.join(',');
                return(result);
              },
            tooltipField: "RatingScale",
        },

        {
          headerName: "Clause",
          minWidth: responsiveColumns(),
          field: "clause",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: cellrander,
        },
        {
          headerName: "Dissemination",
          minWidth: responsiveColumns(),
          field: "dissemination_name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: cellrander,
        },
        {
          headerName: "Applicability",
          minWidth: responsiveColumns(),
          field: "applicability",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: function (params) {
            let arr = [];
            for(let i in params.value){
                arr.push(params.value[i].title);
            }
            let result = arr.join(',');
            return(result);
          },
        },
        {
          headerName: "Policy Document",
          minWidth: responsiveColumns(),
          field: "policy_document",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: (params)=>{
            if(params.value !== null && params.value !== ""){
                return(
                    <div>
                        <Tooltip title="Policy Document">
                            <a className='me-1' href={`${url}/api/getPolicyDoc/${params.data.policy_document_encrypt}`} target='_blank'><Check className="theme_text" /></a>
                        </Tooltip>
                    </div>
                );
            }
            else{
                return(
                    <Clear className="theme_text"/>
                );
            }
          },
        },
        {
          headerName: "Minutes",
          minWidth: responsiveColumns(),
          field: "minutes",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: (params)=>{
            if(params.value !== null && params.value !== ""){
                return(
                    <div>
                        <Tooltip title="Minutes">
                            <a className='me-1' href={`${url}/api/getPolicyDoc/${params.data.minutes_encrypt}`} target='_blank'><Check className="theme_text" /></a>
                        </Tooltip>
                    </div>
                );
            }
            else{
                return(<Clear className="theme_text"/>);
            }
          },
        },
        {
          headerName: "BOD Approval",
          minWidth: responsiveColumns(),
          field: "bod_approval",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: (params)=>{
            if(params.value !== null && params.value !== ""){
                return(
                    <div>
                        <Tooltip title="BoD Approval">
                            <a className='me-1' href={`${url}/api/getPolicyDoc/${params.data.bod_approval_encrypt}`} target='_blank'><Check className="theme_text" /></a>
                        </Tooltip>
                    </div>
                );
            }
            else{
                return(<Clear className="theme_text"/>);
            }
          },
        },
        {
          headerName: "Policy History",
          minWidth: responsiveColumns(),
          field: "",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          hide: columnHide(),
          cellRenderer: (params)=>{
            if(params.value !== null && params.value !== ""){
                return(
                    <div>
                        <Tooltip title="History">
                            <a href={`/policy_history/${params.data.pacra_policy_category_new_id}`} target='_blank'><Event className="theme_text" /></a>
                        </Tooltip>
                    </div>
                );
            }
            else{
                return("_");
            }
          },
        },
             
      ];

      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>
          <Table className="overflow-scroll responsiveTableFonts">
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Doc Type
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.doc_type}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Review Date
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerReviewDate(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Effective Date
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerEffectiveDate(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Next Due Date
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {innerNextDate(params)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Days to Deadline
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.diff_in_deadlines}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Law
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.law2}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Clause
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.clause}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Dissemination
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.dissemination_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Policy Document
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.policy_document !== null ? <a className='me-1' href={`${url}/api/getPolicyDoc/${params.data.policy_document_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Minutes
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.minutes !== null ? <a className='me-1' href={`${url}/api/getPolicyDoc/${params.data.minutes_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                BOD Approval
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                {params.data.bod_approval !== null ? <a className='me-1' href={`${url}/api/getPolicyDoc/${params.data.bod_approval_encrypt}`} target='_blank'><Check className="theme_text" /></a> : <Clear className="theme_text" />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                variant="head"
                className="fw-bolder responsiveTableFonts py-1"
              >
                Policy History
              </TableCell>
              <TableCell className="responsiveTableFonts py-1">
                <a href={`/policy_history/${params.data.pacra_policy_category_new_id}`} target='_blank'><Event className="theme_text" /></a>
              </TableCell>
            </TableRow>
          </Table>
        </h1>
      );


  return (
    <div>
      <TableComponent
        Data={props.policy}
        screenWidth={props.screenWidth}
        MobViewRender={MobViewRender}
        columnDefs={columnDefs}
        datefilters={datefilters}
        datefilter={false}
       />y
    </div>
  )
}
