import React, { useEffect, useState, useRef } from 'react';
import { url } from '../../../Config';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function Criteria_dashboard() {

    const Navigate = useNavigate();
    const dropdown = useRef();

    return(
        <div style={{height: '90vh'}}>
            <div className='mt-1 p-5 row'>
                <div className='card cardc card-1 shadow fs-6 fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-3 mb-2 p-2'>
                    <div style={{fontFamily: "Kalinga", color: "#000078", fontSize: '30px'}} onClick={()=>{Navigate('/methodology-view')}}>
                        Methodology
                    </div>
                </div>
                <div className='card cardc card-2 shadow fs-6 fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-3 mb-2 p-2' onClick={()=>{Navigate('/assessment-dashboard')}}>
                <div style={{fontFamily: "Kalinga", color: "#000078", fontSize: '30px'}}>
                        Assessment
                    </div>
                </div>
                {/* <div className='card cardc card-3 shadow fs-6 fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-3 mb-2 p-2'>
                    <div style={{fontFamily: "Kalinga", color: "#000078", fontSize: '30px'}} >
                        Model
                    </div>
                </div> */}
            </div>
            
            <div style={{width: '100%', height:'100%'}}>
                <iframe src={`https://209.97.168.200/pacrawizpackv3/public/api/criteria_graph`} style={{width: '100%', height: '60vh'}}> title="description"></iframe>
            </div>

        </div>

    )
}
