import React, {useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import TableComponent from '../../../Atoms/TableComponent';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import HistoryIcon from '@mui/icons-material/History';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import { Check, Clear, Event } from "@material-ui/icons";
import { url } from '../../../../Config';
import axios from 'axios';
import ApprovalIcon from '@mui/icons-material/Approval';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function Secp(props) {
  const Navigate = useNavigate();
  const userid = localStorage.getItem("userID");
  const [data, setdata] = useState([]);
  const SponsorsRef = useRef(null);
  const DirectorsRef = useRef(null);
  const CriteriaRef = useRef(null);
  const RCRef = useRef(null);
  const SECPRef = useRef(null);
  const [bool, setbool] = useState(false);


  const [alertopen, setalertOpen] = useState(false);
  const [alertType, setalertType] = useState();
  const [AlertMessage, setalertmessage] = useState();

  useEffect(()=>{
    setdata([])
    const func = async ()=>{
        const res = await axios.get(`${url}/api/viewSecpQtrlyReports`);
        const resp = res.data;
        setdata(resp.allreports);
    }
    func();
  },[bool])



  const deleteitem = async(id)=>{
    Swal.fire({ title: 'Do you want to delete this Record?', 
    showDenyButton: false, showCancelButton: true, confirmButtonText: 'Delete'})
    .then(async(result) => {
        
      if (result.isConfirmed) {
        const res = await axios.get(`${url}/api/DeleteSecp_Report/${id}/${userid}`);
        const resp = res.data;
        if(resp.status == true){
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success");
            setbool(!bool);
        }
        else{
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error")
        }
      }
    })
    
}


  const responsiveColumnPin = () => {
    if (props.screenWidth < 770) {
      return null;
    } else {
      return "left";
    }
  };

  const detailShow = () => {
    if (props.screenWidth < 770) {
      return false;
    } else {
      return true;
    }
  };

  const columnHide = () => {
    if (props.screenWidth < 770) {
      return true;
    } else {
      return false;
    }
  };

  const datefilters = (
    startDate,
    endDate,
    gridApi,
    getFilterType
  ) => {
    var dateFilterComponent =
      gridApi.api.getFilterInstance("date_rating_assigned");
    dateFilterComponent.setModel({
      type: getFilterType(),
      inRange: true,
      dateFrom: startDate,
      dateTo: endDate,
    });
  };

  function fullDate(params) {
    if (params.value === "nullData") return <Skeleton />;
    if (params.value == null) {
      return "-";
    } else if (params.value == "") {
      return "-";
    } else {
      const date = new Date(params.value);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }

  const cellrander = (params) => {
    if (params.value === "nullData") return <Skeleton />;
    if (params.value === "" || params.value == null) {
      return "-";
    } else {
      return params.value;
    }
  };

  const cellrandered = (params) => {
    if (params.value === "nullData") {
      return <Skeleton />;
    } else {
      return params.value;
    }
  };

const columnDefs = [
    {
      headerName: "",
      field: "sNo",
      maxWidth: 30,
      filter: true,
      menuTabs: false,
      pinned: responsiveColumnPin(),
      hide: detailShow(),
      cellRenderer: "agGroupCellRenderer",
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      headerName: "#",
      maxWidth: 50,
      // minWidth: 66,
      field: "sNo",
      sortable: true,
      filter: true,
      // filter: "agSetColumnFilter",
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          "  </div>" +
          "</div>",
      },
      suppressFiltersToolPanel: true,
      menuTabs: false,
      cellRenderer: cellrandered,
      pinned: responsiveColumnPin(),
    },
    {
      headerName: "Name",
      minWidth: 250,
      field: "title",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      cellRenderer: cellrander,
      tooltipField: "name",
      pinned: responsiveColumnPin(),
    },

    {
        headerName: "Start Date",
        field: "start_date",
        minWidth: 115,
        // hide: true,
        sortable: true,
        filter: "agDateColumnFilter",
        excelMode: "windows",
        cellRenderer: fullDate,
        debounceMs: "DateFilter",
        hide: columnHide(),
        filterParams: {
          filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            var dateAsString = moment(cellValue).format("DD/MM/YYYY");
            var dateParts = dateAsString.split("/");
            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );
  
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }
  
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
  
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
          buttons: ["clear", "reset", "apply"],
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        },
    },
    {
        headerName: "End Date",
        field: "end_date",
        minWidth: 115,
        // hide: true,
        sortable: true,
        filter: "agDateColumnFilter",
        excelMode: "windows",
        cellRenderer: fullDate,
        debounceMs: "DateFilter",
        hide: columnHide(),
        filterParams: {
          filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            var dateAsString = moment(cellValue).format("DD/MM/YYYY");
            var dateParts = dateAsString.split("/");
            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );
  
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }
  
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
  
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
          buttons: ["clear", "reset", "apply"],
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        },
    },

    {
        headerName: "Status",
        minWidth: 250,
        field: "status",
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        cellRenderer: (params)=>{
            if(params.value == 'approve'){
                return(
                    <div className='text-capitalize' style={{color: 'green'}}>Approved</div>
                )
            }
            else if(params.value == null){
              return(
                <div className='text-capitalize'>Submit</div>
            )
            }
            else{
                return(
                    <div className='text-capitalize'>{params.value}</div>
                )
            }
        },
        tooltipField: "status",
      },

    {
        headerName: "Action",
        minWidth: 250,
        field: "",
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        cellRenderer: (params)=>{
            if(params.data.status == 'submit' || params.data.status == null || userid == '207'){
                return(
                    <div>
                      
                        {/* <Tooltip title="Approve"><button className='btn btn-success btn-sm me-1 mb-1' onClick={()=>{approve(params.data.id)}}><ApprovalIcon /></button></Tooltip> */}
                        <Tooltip title="View"><VisibilityIcon sx={{color: 'blue'}} onClick={()=>{Navigate(`/edit-secpt-report/${params.data.id}`)}} /></Tooltip>
                        <Tooltip title="Delete"><DeleteIcon sx={{color: 'red'}} onClick={()=>{deleteitem(params.data.id)}}/></Tooltip>
                    </div>
                )
            }
            else{
                return(
                    <div>
                        <Tooltip title="View"><VisibilityIcon sx={{color: 'blue'}} onClick={()=>{Navigate(`/edit-secpt-report/${params.data.id}`)}} /></Tooltip>
                    </div>
                )
            }
            
        },
      },
         
  ];

    const MobViewRender = (params) => (
      <h1 style={{ padding: "10px 20px" }}>
        
      </h1>
    );

  return (
    <div>
      <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
        Management Details
      </div>
      <div className='mt-1 p-5 row'>
        <div className='card shadow fs-6 fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={SponsorsRef} onClick={()=>{Navigate('/secp-dashboard')}}>
            1. Sponsors/Shareholders
        </div>
        <div className='card shadow fs-6 fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={DirectorsRef} onClick={()=>{Navigate('/directors-view')}}>
            2. Directors
        </div>
        <div className='card shadow fs-6 fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={CriteriaRef} onClick={()=>{Navigate('/criteria-view')}}>
            3. Criteria Group
        </div>
        <div className='card shadow fs-6 fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={RCRef} onClick={()=>{Navigate('/rcreport-view')}}>
            4. Rating Committee
        </div>
        <div className='card shadow fs-6 fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={SECPRef} onClick={()=>{Navigate('/secp-view')}} style={{backgroundColor: "#000078", color: "white"}}>
            5. SECP Reports
        </div>
        <hr />
        <div className='mt-2 mb-2'>
            <button className='btn btn-primary me-2' onClick={()=>{Navigate('/add-secp-report')}} >Add New Report</button>
        </div>
        <Box className="mx-auto mb-1 mt-1" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
        </Box>
        <TableComponent
            Data={data}
            screenWidth={props.screenWidth}
            MobViewRender={MobViewRender}
            columnDefs={columnDefs}
            datefilters={datefilters}
            datefilter={false}
            secp = {true}
        />
      </div>
    </div>
  )
}
