import React, { useEffect } from 'react';
import { encryptParams } from '../../encrypt_url';
import { useParams } from "react-router-dom";



export default function Redirecting(props) {

    const {clientId} = useParams();


    useEffect(()=>{
        const encryptedParams = encryptParams({clientId});
        window.open(`/OpinionDetails/${encryptedParams}`, '_self');
    },[])


  return (
    <div>
      <div className='mt-3 fs-3 fw-bold'>
        Redirecting to Client's Detail...
      </div>
    </div>
  )
}
