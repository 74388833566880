import React, {useRef, useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../../../Config';
export default function Index(props) {

    const Navigate = useNavigate();
    
    const card1 = useRef(null);
    const card2 = useRef(null);
    const card3 = useRef(null);
    const card4 = useRef(null);


  return (
    <div style={props.screenWidth > 760 ? {height : '90vh'} : null}>
        <div className='mt-2 text-center mb-2 fw-bold fs-3' style={{color:"#000078"}}>
            VIS Module
        </div>
        <div className='row p-5 mt-5'>
            <div className='card shadow  col-lg-5 col-md-5 col-12 p-3 mx-auto'>

                <div className='card shadow text-center mt-3 border p-3' ref={card2} onClick={()=>{Navigate('/ratings-jcr')}} onMouseEnter={()=>{card2.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card2.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    VIS Ratings
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card3} onClick={()=>{Navigate('/jcr-client')}} onMouseEnter={()=>{card3.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card3.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    VIS Clients
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card4} onClick={()=>{Navigate('/old-sr-jcr')}} onMouseEnter={()=>{card4.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card4.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    VIS Report
                </div>
                <div className='card shadow text-center mt-3 border p-3' ref={card1} onClick={()=>{Navigate('/vis-grids')}} onMouseEnter={()=>{card1.current.style.backgroundColor = 'skyblue'}} onMouseLeave={()=>{card1.current.style.backgroundColor = '#000078'}} style={{backgroundColor:"#000078", color: 'white'}}>
                    VIS Grid
                </div>
            </div>
        </div>
    </div>
  )
}
