import React, {useState, useEffect, useRef} from 'react';
import { v4 as uuidv4 } from "uuid";
import axios from 'axios'
import { url } from '../../../../Config';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


export default function CreateModel(props) {

    const [tasks, setTasks] = useState([{id: uuidv4()}]);
    const [heading_name, setheading_name] = useState([])
    const [subheading_name, setsubheading_name] = useState([])
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const Navigate = useNavigate();

    const modelRef = useRef();
    const btnRef = useRef();

    const submitForm = async (event)=>{
        event.preventDefault();
        btnRef.current.disabled = true;
        btnRef.current.innerHTML =  " <i class='fa-solid fa-spinner fa-spin fa-lg'></i>";
        const res = await axios.post(`${url}/api/add_pacra_model`, {
            'modelname' : modelRef.current.value,
            'mainheading' : heading_name,
            'subheading' : subheading_name
        });

        const resp = res.data
        console.log(resp, 'resp');
        if(resp.status == true){
            btnRef.current.disabled = false;
            btnRef.current.innerHTML =  "Submit";
            Navigate(`/post-create-model/${resp.id}`)
        }
        else{
            btnRef.current.disabled = false;
            btnRef.current.innerHTML =  "Submit";
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error")
        }
        

    }

    const handleadd = ()=>{
        setTasks([...tasks, { id: uuidv4() }]);
    }

  return (
    <div style={{height: '90vh'}}>
        <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/model-dashboard"} >Assessment Format Grid</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Create Model</li>
                </ol>
            </nav>
            <hr/>
        </div>
      <div className='fs-4 mt-2 fw-bold text-center'>
            Create Model
      </div>
      <div>
        <form className='mt-5 col-10 mx-auto card shadow p-3' onSubmit={(event)=>{submitForm(event)}}>
            <div className='mt-3'>
                <div className='fs-6 fw-bold'>
                    Enter Model name
                </div>
                <div className='mt-1 col-12'>
                    <input className='form-control' ref={modelRef} required/>
                </div>
            </div>
            {
                tasks.map((value, index)=>{
                    return(
                        <div>
                            <div className='mt-3'>
                                <div className='fs-6 fw-bold'>
                                    Enter Heading name
                                </div>
                                <div className='mt-1 col-12'>
                                    <input className='form-control' value={heading_name[index]} onChange={(e)=>{
                                        const heading = [...heading_name];
                                        heading[index] = e.target.value;
                                        setheading_name(heading)
                                    }} required />
                                </div>
                            </div>
                            <div className='mt-3'>
                                <div className='fs-6 fw-bold'>
                                    Sub Headings
                                </div>
                                <div className='mt-1 col-12'>
                                    <textarea className='form-control' value={subheading_name[index]} onChange={(e)=>{
                                        const val = e.target.value;
                                        const arr = val.split(',');
                                        const sub_heading = [...subheading_name];
                                        sub_heading[index] = arr;
                                        setsubheading_name(sub_heading);
                                    }} />
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            <Box className="mx-auto" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                    severity={alertType}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setalertOpen(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                        sx={{ mb: 2 }}
                    >
                    {AlertMessage}
                    </Alert>
                </Collapse>
            </Box>
            <div className='mt-3'>
                <button className='btn btn-danger me-2 mt-1' type='button' onClick={()=>{handleadd()}}>Create Headings with Sub Headings</button>
                <button className='btn btn-primary mt-1' ref={btnRef} type='submit'>Submit</button>
            </div>
        </form>
      </div>
    </div>
  )
}
