import React, {useState, useEffect, useRef} from 'react';
import { url } from '../../../../../Config';
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CancelIcon from "@mui/icons-material/Cancel";


export default function AddRC_Members(props) {

    const id = localStorage.getItem("userID");
    const Navigate = useNavigate();
    const nameRef = useRef(null);
    const dateRef = useRef(null);
    const emailRef = useRef(null);
    const btnRef = useRef(null);

    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const [category, setcategory] = useState([]); 
    const [designations, setdesignations] = useState([]);
    const [catval, setcatval] = useState('');
    const [desigval, setdesigval] = useState('');

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/getRCmembersData`);
            const resp = res.data;
            setcategory(resp.rc_members_categories);
            setdesignations(resp.designations);
        }

        func();
    },[])

    const submitForm = async (event)=>{
        event.preventDefault();
        if(catval == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Option from Category First!`);
            return ;
        }
        if(desigval == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Option from Designation First!`);
            return ;
        }

        btnRef.current.disabled = true;

        const res = await axios.post(`${url}/api/storeRcMember`, {
            'rcmemberName': nameRef.current.value,
            'appointment_date': dateRef.current.value,
            'category': catval,
            'rc_member_email': emailRef.current.value,
            'designation': desigval,
            'id': Number(id),

        })

        const resp = res.data;
        if(resp.status == true){
            Navigate(`/edit-rc-member/${resp.id}`);
        }
        else{
            btnRef.current.disabled = false;
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
        }
    }


  return (
    <div>
      <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/rcreport-view"} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Add RC Member</li>
                </ol>
            </nav>
            <hr/>
        </div>
        <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            Add RC Member
        </div>
        <form className ='col-10 card shadow p-3 mx-auto mt-2' onSubmit={(event)=>{submitForm(event)}}>
        <div>
            <div className='fw-bold'>
                RC Member
            </div>
            <div className='mt-2'>
                <input className='form-control' ref={nameRef} placeholder='Enter Name' required />
            </div>
        </div>
        <div>
            <div className='fw-bold mt-2'>
                Applicable date for nomination
            </div>
            <div className='mt-2'>
                <input className='form-control' type='date' ref={dateRef} required />
            </div>
        </div>
        <div>
            <div className='fw-bold mt-2'>
                Category
            </div>
            <div className='mt-2'>
                <select className='form-select' value={catval} onChange={(e)=>{setcatval(e.target.value)}}>
                    <option value={''}>Select Category</option>
                    {
                        category.map((value, index)=>{
                            return(
                                <option value={value.title}>{value.title}</option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
        <div>
            <div className='fw-bold mt-2'>
                RC Member's Email
            </div>
            <div className='mt-2'>
                <input className='form-control' type="email" ref={emailRef} placeholder='Enter Email' required />
            </div>
        </div>
        <div>
            <div className='fw-bold mt-2'>
                Designation
            </div>
            <div className='mt-2'>
                <select className='form-select' value={desigval} onChange={(e)=>{setdesigval(e.target.value)}}>
                    <option value={''}>Select Designation</option>
                    {
                        designations.map((value, index)=>{
                            return(
                                <option value={value.title}>{value.title}</option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
        <Box className="mx-auto mt-1" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
        </Box>
        <div className='mt-3'>
            <button className='btn btn-primary' ref={btnRef}  type='submit'>Save</button>
        </div>
        </form>
    </div>
  )
}
