import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { url } from '../../../../Config';
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Select from "react-select";



export default function Opmappingv2(props) {

    const [models, setmodels] = useState([]);
    const [opdata, setopdata] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState();
    const [opinionid, setopinionid] = useState(null);
    const [model_id, setmodel_id] = useState('');
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const btnRef = useRef();

    function handleSelect(data) {
        const arr = [];

          
        for(let i in data){
            arr.push(data[i].value)
        }

        setSelectedOptions(data);
        setopinionid(arr);
    }

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/op_mapping_v2`);
            const resp = res.data;
            const mappedOptions = resp.initials_unmapped.map(item => ({ value: item.id, label: item.name }));
            setopdata(mappedOptions);
            setmodels(resp.models);
        }

        func();

    },[])

    const ModelMap = async ()=>{
        setalertOpen(false);
        if(model_id == ''){
            setalertOpen(true);
            setalertmessage('Select Model to Map');  
            setalertType("error");
            return;
        }
        if(opinionid == '' && opinionid == null){
            setalertOpen(true);
            setalertmessage('Select Opinion to Map');  
            setalertType("error");
            return;
        }
        if(opinionid !== null && opinionid !== '' ){
            if(opinionid.length == 0){
                setalertOpen(true);
                setalertmessage('Select Opinion to Map');  
                setalertType("error");
                return;
            }
        }

        btnRef.current.disabled = true;
        btnRef.current.innerHTML =   "<i class='fa-solid fa-spinner fa-spin fa-lg'></i>";

        const res = await axios.post(`${url}/api/save_mapping_v2`, {
            'opIds' : opinionid,
            'modelId': model_id
        });
        const resp = res.data;
        if(resp.status == true){
            btnRef.current.disabled = false;
            btnRef.current.innerHTML =  "Submit";
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("success");
        }
        else{
            btnRef.current.disabled = false;
            btnRef.current.innerHTML =  "Submit";
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error");
        }

    }

  return (
    <div style={{height: '90vh'}}>
        <div className='fs-3 fw-bold text-center mt-2 mb-3' style={{color: "#000078"}}>
            Opinion & Assessment Format Mapping Section
        </div>
        <div className='p-3'>
            <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
            </Box>
            <div className='fw-bold fs-6 text-center'>
                Opinion Need to be Mapped:
            </div>
            <div className='col-8 mx-auto mt-2'>
                <Select
                        options={opdata}
                        placeholder="Select Opinion"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isSearchable={true}
                        isMulti
                    />
            </div>
            <div className='fw-bold fs-6 text-center mt-2'>
                Model:
            </div>
            <div className='col-8 mx-auto mt-2'>
                <select className='form-select' value={model_id} onChange={(e)=>{setmodel_id(e.target.value)}}>
                    <option value={''}>Select Model</option>
                    {
                        models.map((value, index)=>{
                            return(
                                <option value={value.id}>{value.name}</option>
                            );
                        })
                    }
                </select>
            </div>
            <div className='mt-2 mx-auto' style={{width: 'fit-content'}}>
                <button className='btn btn-primary' ref={btnRef} onClick={()=>{ModelMap()}}>Submit</button>
            </div>
        </div>
    </div>
  )
}
