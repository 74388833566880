import React, { useState, useEffect } from "react";
import TableComponent from "../../Atoms/TableComponent";
import Skeleton from "@mui/material/Skeleton";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import GetGridsApi from "../../../API/GetGridsApi";
import { Check, Clear, Event } from "@material-ui/icons";
import moment from "moment";

export default function VisOutstandingOnSpecificDate(props) {
  const [VisOutstandingOnSpecificDate, setVisOutstandingOnSpecificDate] = useState([]);
  const [fromDate, setfromDate] = useState(localStorage.getItem("reportsfirstDate"));
  const [rowstatus, setrowstatus] = useState();
  const [Update, setUpdate] = useState();
  const [date, setdate] = useState();


  const responsiveColumns = () => {
    if (props.screenWidth < 770) {
      return null;
    } else {
      return 120;
    }
  };


  const responsiveColumnPin = () => {
    if (props.screenWidth < 770) {
      return null;
    } else {
      return "left";
    }
  };
  
  const detailShow = () => {
    if (props.screenWidth < 770) {
      return false;
    } else {
      return true;
    }
  };


  useEffect(() => {
    getTabsData(fromDate, null);
  }, []);

  const getTabsData = async (fromDate, toDate) => {
    if(fromDate !== null){
    setrowstatus(false)
    const res = GetGridsApi.VisOutstandingOnSpecificDateApi(fromDate, toDate);
    const resp = await res;
    setVisOutstandingOnSpecificDate(resp.data);
    if(resp.data.length == 0){
      setrowstatus(true)
    }
    localStorage.removeItem("reportsfirstDate");
    }
  };


  const update = async ()=>{
    if (date != null && date !== "") {
      setUpdate(true);
      setfromDate(date)
      const res = GetGridsApi.VisOutstandingOnSpecificDateApi(date, null);
      const resp = await res;
      console.log(resp.data, "ressspppp");
      setVisOutstandingOnSpecificDate(resp.data);
      if(resp.data.length == 0){
        setUpdate('no rows');
      }
      else{
        setUpdate(false);
      }
    }
  }

  const PvtRatingsdatefilters = (
    startDate,
    endDate,
    gridApi,
    getFilterType
  ) => {
    var dateFilterComponent =
      gridApi.api.getFilterInstance("notification_date");
    dateFilterComponent.setModel({
      type: getFilterType(),
      inRange: true,
      dateFrom: startDate,
      dateTo: endDate,
    });
  };

  function fullDate(params) {
    if (params.value === "nullData") return <Skeleton />;
    if (params.value == null) {
      return "-";
    } else if (params.value == "") {
      return "-";
    } else {
      const date = new Date(params.value);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }
  const cellrander = (params) => {
    if (params.value === "nullData") return <Skeleton />;
    if (params.value === "" || params.value == null) {
      return "-";
    } else {
      return params.value;
    }
  };
  const cellrandered = (params) => {
    if (params.value === "nullData") {
      return <Skeleton />;
    } else {
      return params.value;
    }
  };

  const columnDefs = [
    {
      headerName: "",
      field: "sNo",
      maxWidth: 30,
      filter: true,
      menuTabs: false,
      pinned: responsiveColumnPin(),
      hide: detailShow(),
      cellRenderer: "agGroupCellRenderer",
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      headerName: "#",
      maxWidth: 50,
      // minWidth: 66,
      field: "sNo",
      sortable: true,
      filter: true,
      // filter: "agSetColumnFilter",
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          "  </div>" +
          "</div>",
      },
      suppressFiltersToolPanel: true,
      menuTabs: false,
      cellRenderer: cellrandered,
      pinned: responsiveColumnPin(),
    },

    {
      headerName: "Opinion",
      minWidth: responsiveColumns(),
      field: "Entity",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      cellRenderer: cellrander,
      tooltipField: "Entity",
      pinned: responsiveColumnPin(),
    },
    {
      headerName: "Industry",
      minWidth: responsiveColumns(),
      field: "Industry",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      cellRenderer: cellrander,
      tooltipField: "Industry",
      pinned: responsiveColumnPin(),
    },

    {
      headerName: "Rating Type",
      minWidth: responsiveColumns(),
      field: "RatingScale",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      cellRenderer: cellrander,
      tooltipField: "Industry",
      pinned: responsiveColumnPin(),
    },
    {
      headerName: "Action",
      minWidth: responsiveColumns(),
      field: "RatingAction",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      cellRenderer: cellrander,
      tooltipField: "Industry",
      pinned: responsiveColumnPin(),
    },
    {
      headerName: "R|LT",
      minWidth: responsiveColumns(),
      field: "RatingLT",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      cellRenderer: cellrander,
      tooltipField: "RatingLT",
      pinned: responsiveColumnPin(),
    },
    {
      headerName: "R|ST",
      minWidth: responsiveColumns(),
      field: "RatingST",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      cellRenderer: cellrander,
      tooltipField: "RatingST",
      pinned: responsiveColumnPin(),
    },
    {
      headerName: "RW",
      minWidth: responsiveColumns(),
      field: "",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      cellRenderer: cellrander,
      tooltipField: "RatingST",
      pinned: responsiveColumnPin(),
    },
    {
      headerName: "Outlook",
      minWidth: responsiveColumns(),
      field: "Outlook",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      cellRenderer: cellrander,
      tooltipField: "RatingST",
      pinned: responsiveColumnPin(),
    },

    {
      headerName: "Dissemination",
      field: "Dissemination",
      minWidth: 115,
      // hide: true,
      sortable: true,
      filter: "agDateColumnFilter",
      excelMode: "windows",
      cellRenderer: fullDate,
      debounceMs: "DateFilter",
      filterParams: {
        filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
        inRangeInclusive: true,
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateAsString = moment(cellValue).format("DD/MM/YYYY");
          var dateParts = dateAsString.split("/");
          var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );

          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }

          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        buttons: ["clear", "reset", "apply"],
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
        },
      },
    },

    {
      headerName: "PR",
      field: "pr",
      // hide: columnHide(),
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      quickFilterText: "string",
      cellRenderer: (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value) {
          return (
            <a
              href={`https://209.97.168.200/wizpacv1/rm/${params.value}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <Check style={{ size: "20 20" }} className="theme_text" />{" "}
            </a>
          );
        } else {
          return <Clear className="theme_text" />;
        }
      },
    },
    {
      headerName: "RR",
      // hide: columnHide(),
      field: "sr",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      cellRenderer: (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value) {
          return (
            <a
              href={`https://209.97.168.200/wizpacv1/${params.value}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <Check className="theme_text" />{" "}
            </a>
          );
        } else {
          return <Clear className="theme_text" />;
        }
      },
    },

    {
      headerName: "H",
      // hide: columnHide(),
      field: "",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      // cellRenderer: (params) => {
      //   if (params.value === "nullData") return <Skeleton />;
      //   if (params.value) {
      //     return (
      //       <a
      //         href={`https://209.97.168.200/wizpacv1/${params.value}`}
      //         target="_blank"
      //         rel="noopener noreferrer"
      //       >
      //         {" "}
      //         <Check className="theme_text" />{" "}
      //       </a>
      //     );
      //   } else {
      //     return <Clear className="theme_text" />;
      //   }
      // },
    },
  ];
  const MobViewRender = (params) => (
    <h1 style={{ padding: "10px 20px" }}>
      <Table className="overflow-scroll responsiveTableFonts">
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Analyst
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.display_name}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Date
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.udate}
          </TableCell>
        </TableRow>
      </Table>
    </h1>
  );

  return (
    <>
      <div>
        <p
          className="mx-auto p-3 fs-3 fw-bold mb-0 text-capitalize"
          style={{ width: "fit-content", color: "#000078" }}
        >
          VIS Outstanding On Specific Date
        </p>
        <div className="row w-100">
        <div className="card shadow mx-auto col-lg-7 col-md-8 col-sm-10 col-12 p-lg-2 p-md-3 p-sm-2" style={{ height: "auto", backgroundColor: "#e0e0e0" }}>
          <div className="row w-100">
            <div className="col-lg-10 col-md-10 col-sm-10 col-9 p-1">
              <input type={"date"} className="form-control" defaultValue={fromDate} style={{ height: "38px" }} onChange={(e) => {setdate(e.target.value);}}></input>
            </div>
            <div className='col-lg-1 col-md-1 col-sm-1 col-1 mt-1 mx-sm-0'>
              <button type="button" className="btn btn-primary h-60" onClick={update}>
              Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
      <br />
      <TableComponent
        report={true}
        rowstatus = {rowstatus}
        fromDate = {fromDate}
        value={"VisOutstandingOnSpecificDate"}
        handleRfreshTab={props.handleRfreshTab}
        Data={VisOutstandingOnSpecificDate}
        screenWidth={props.screenWidth}
        MobViewRender={MobViewRender}
        columnDefs={columnDefs}
        datefilters={PvtRatingsdatefilters}
        update = {Update}
        datefilter={false}
        // FullPageTable={true}
      />
    </>
  );
}
