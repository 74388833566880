import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { url } from "../../../Config";
import { decryptParams } from '../encrypt_url';
import { useLocation } from 'react-router-dom';
import {encryptParams} from "../encrypt_url";
import TableComponent from "../../Atoms/TableComponent";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { NavLink } from "react-router-dom";
import { Check, Clear, Event } from "@material-ui/icons";
import { Table, TableCell, TableRow } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";


export default function Update_tasks_date(props) {

    const [data, setdata] = useState([]);
    const [bool, setbool] = useState(false);
    const [alertopen, setalertOpen] = useState(false);
    const [alertopen2, setalertOpen2] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
  


    useEffect(()=>{

        const func = async ()=>{
            setdata([]);
            const res = await axios.get(`${url}/api/gettasklist`);
            const resp = res.data;
            for(let i in resp){
                resp[i].sNo = Number(i) + 1;
            }
            setdata(resp);
        }

        func();

    },[bool])

    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 210;
        }
      };
      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };

      const preventKeyboardInput = (e) => {
        e.preventDefault();
      };
    
    
      
      const encryptit = (client)=>{
        const clientId = client;
        const encryptedParams = encryptParams({clientId});
        return encryptedParams;
      }
    
      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
      const sectorHide = () => {
        if (props.screenWidth > 500) {
          return false;
        } else {
          return true;
        }
      };
    
      function innerInitiDate(params) {
        if (params.data.rating_initiation_date == null) {
          return "-";
        } else {
          const date = new Date(params.data.rating_initiation_date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
    
    
      function innerFileDate(params) {
        if (params.data.date == null) {
          return "-";
        } else {
          const date = new Date(params.data.date);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };

      const submit = async (event, id, inputid, btnid, id2)=>{

        event.preventDefault();

        setalertOpen2(false);
        document.getElementById(btnid).innerHTML = "Please Wait...";
        document.getElementById(btnid).disabled = true;
        const date = document.getElementById(inputid).value;

        const res = await axios.post(`${url}/api/update_task_date`,{
            'id': id,
            'date': date
        });

        const resp = res.data;

        if(resp.status == true){
            document.getElementById(id2).click();
            setbool(!bool);
            setalertOpen2(true);
        }
        else{
            setalertType("error");
            setalertOpen(true);
            setalertmessage(resp.message);
            document.getElementById(btnid).innerHTML = "Submit";
            document.getElementById(btnid).disabled = false;

        }

      }

      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          field: "sNo",
          // minWidth: 66,
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Opinion",
          minWidth: responsiveColumns(),
          // comparator: customComparator,
          field: "name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          filterParams: {
            caseSensitive: false,
          },
          cellRenderer: function (params) {
            if (params.value === "nullData"){
              return <Skeleton />;
            } 
            else{
    
              const clientId = params.data.op_id;
              const encryptedParams = encryptParams({clientId});
    
              if(params.data != null){
                return (
                  <a
                    href={`/OpinionDetails/${encryptedParams}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary"
                  >
                    {params.value}
                  </a>
                );
            }
            }
          },
          // cellRenderer: cellrandered,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Analyst",
          hide: columnHide(),
          field: "analyst",
          minWidth: 94,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrandered,
        },
        {
          headerName: "Team Lead",
          hide: columnHide(),
          field: "team_lead",
          minWidth: 94,
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrandered,
        },
        {
            headerName: "Initiation Date",
            hide: sectorHide(),
            field: "rating_initiation_date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },
        {
            headerName: "File Date",
            hide: sectorHide(),
            field: "date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },
        {
            headerName: "Task",
            hide: columnHide(),
            field: "task",
            minWidth: 85,
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: cellrandered,
        },
        {
            headerName: "File",
            field: "task_file",
            minWidth: 85,
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
                if(params.data.status == 'Spaces'){
                    return(
                        <a href={`${url}/api/viewsubtask_spaces/${params.value}`} target="_blank"><DescriptionIcon /></a>
                    )
                }
                else{
                    return(
                        <a href={`https://209.97.168.200/pacrawizpackv3/public/api/storage/app/storage/task_files/${params.value}`} target="_blank"><DescriptionIcon /></a>
                    )
                }
            },
        },
        {
            headerName: "Update",
            field: "sub_task_id",
            minWidth: 85,
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
                return(
                    <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#sub-task${params.value}`}>Update</button>
                )
            },
        },
    
        ]

        const MobViewRender = (params) => (
            <h1 style={{ padding: "10px 20px" }}>
              <Table className="overflow-scroll responsiveTableFonts">
                <TableRow>
                  <TableCell
                    variant="head"
                    className="fw-bolder responsiveTableFonts py-1"
                  >
                    Team Lead
                  </TableCell>
                  <TableCell className="responsiveTableFonts py-1">
                    {params.data.team_lead}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    variant="head"
                    className="fw-bolder responsiveTableFonts py-1"
                  >
                    Analyst
                  </TableCell>
                  <TableCell className="responsiveTableFonts py-1">
                    {params.data.analyst}
                  </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                      variant="head"
                      className="fw-bolder responsiveTableFonts py-1"
                    >
                      Initiation Date
                    </TableCell>
                    <TableCell className="responsiveTableFonts py-1">
                      {innerInitiDate(params)}
                    </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    variant="head"
                    className="fw-bolder responsiveTableFonts py-1"
                  >
                    File Date
                  </TableCell>
                  <TableCell className="responsiveTableFonts py-1">
                    {innerFileDate(params)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    variant="head"
                    className="fw-bolder responsiveTableFonts py-1"
                  >
                    Task
                  </TableCell>
                  <TableCell className="responsiveTableFonts py-1">
                    {params.data.task}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    variant="head"
                    className="fw-bolder responsiveTableFonts py-1"
                  >
                    File
                  </TableCell>
                  <TableCell className="responsiveTableFonts py-1">
                    <a href={`${url}/api/viewsubtask_spaces/${params.data.task_file}`} target="_blank"><DescriptionIcon /></a>
                  </TableCell>
                </TableRow>
              </Table>
            </h1>
          );

  return (
    <div>

        {
            data.map((value, index)=>{
                return(
                    <form className="modal fade" id={`sub-task${value.sub_task_id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" onSubmit={(event)=>{submit(event, value.sub_task_id, `updateinput${value.sub_task_id}`, `updatebtn${value.sub_task_id}`, `reasonreopenclose${index}`)}}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update File Date : {value.name}</h5> 
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <Box className="mx-auto" sx={{ width: "98%" }}>
                                <Collapse in={alertopen}>
                                    <Alert
                                    severity={alertType}
                                    action={
                                        <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setalertOpen(false);
                                        }}
                                        >
                                        <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    sx={{ mb: 2 }}
                                    >
                                    {AlertMessage}
                                    </Alert>
                                </Collapse>
                            </Box>
                            <div className='fw-bold'>
                            Current Date
                            </div>
                            <div>
                            <input className="form-control" type="date" value={value.date} disabled />
                            </div>
                            <div className='fw-bold'>
                            Update
                            </div>
                            <div>
                            <input className="form-control" type="date" min={value.rating_initiation_date} onKeyDown={preventKeyboardInput} onPaste={preventKeyboardInput} id={`updateinput${value.sub_task_id}`} required />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" id={`reasonreopenclose${index}`} data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary" id={`updatebtn${value.sub_task_id}`}>Submit</button>
                        </div>
                        </div>
                    </div>
                    </form>
                )
            })
        }

        <Box className="mx-auto" sx={{ width: "98%" }}>
            <Collapse in={alertopen2}>
                <Alert
                severity={'success'}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen2(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {"Successfully Updated Task Date!"}
                </Alert>
            </Collapse>
        </Box>


      <TableComponent
        value={"UpdateTaskDate"}
        // stating = {props.stating}
        // state={props.state}
        Data={data}
        // checkdata={props.Outstanding}
        screenWidth={props.screenWidth}
        MobViewRender={MobViewRender}
        columnDefs={columnDefs}
        datefilter={false}
        // count={props.count}
      />
    </div>
  )
}
