import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { url } from '../../../../Config';


export default function Edit_perf_rank() {
    const [data, setdata] = useState(null);
    const {client_id, og_rating_id } = useParams();
    const [Title, setTitle] = useState(null);
    const [rating_rationale_text_1, setrating_rationale_text_1] = useState(null);
    const [rating_rationale_text_3, setrating_rationale_text_3] = useState(null);
    const [rating_rationale_text_5, setrating_rationale_text_5] = useState(null);
    const [total_fund, settotal_fund] = useState(null);
    const [alertopen, setalertopen] = useState(false);
    const [AlertMessage, setAlertMessage] = useState(null);
    const [alertType, setalertType] = useState("success");
    const [file1, setfiles1] = useState(null);
    const [file2, setfiles2] = useState(null);
    const [file3, setfiles3] = useState(null);
    const [file1name, setfiles1name] = useState("No File Chosen");
    const [file2name, setfiles2name] = useState("No File Chosen");
    const [file3name, setfiles3name] = useState("No File Chosen");
    const Navigate = useNavigate();

    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/geteditPerfRank/${client_id}/${og_rating_id}`);
            const resp = res.data;
            if(resp.message == "Data not added"){
                Navigate(`/Ratings/perf_rank/${client_id}/${og_rating_id}`);
            }
            else{
                
                setTitle(resp.title);
                setrating_rationale_text_1(resp.rr_data.rating_rationale_text_1);
                setrating_rationale_text_3(resp.rr_data.rating_rationale_text_3);
                setrating_rationale_text_5(resp.rr_data.rating_rationale_text_5);
                settotal_fund(resp.rr_data.total_fund);
                setfiles1name(resp.images.img1);
                setfiles2name(resp.images.img2);
                setfiles3name(resp.images.img3);
                setdata(resp);
            }
        }
        func();
    },[])


    const submit = async (event)=>{
        event.preventDefault();
        const res = await axios.post(`${url}/api/updatePerfRank`,{
            'record_id' : og_rating_id,
            'opinion_id' : client_id,
            'img1' : file1,
            'img2' : file2,
            'img3' : file3,
            'img1old' : data.images.img1,
            'img2old' : data.images.img2,
            'img3old' : data.images.img3,
            'rating_rationale_text_1' : rating_rationale_text_1,
            'rating_rationale_text_3' : rating_rationale_text_3,
            'rating_rationale_text_5' : rating_rationale_text_5,
            'press_release_title' : Title,
            'total_fund' : total_fund,

        },
        {
            headers: {
              "Content-Type": "multipart/form-data",
            },
        }
        )
        if(res.data.status == true){
            setalertType("success");
            setAlertMessage("Successfully Updated");
            setalertopen(true);
        }
        else{
            setalertType("error");
            setAlertMessage("An error occure while process your request");
            setalertopen(true);
        }
    }
  return (
    <div style={{height: "90vh"}}>
      <br />
      {data !== null ? <form className='card shadow col-11 mx-auto p-2' style={{backgroundColor: "whitesmoke"}} onSubmit={submit}>
        <div className='text-center text-decoration-underline fw-bold fs-5' style={{ color: "#000078", fontFamily: "Kalinga" }}>
            Rating Rationale & Key Rating Drivers
        </div>
        <div className='text-center text-decoration-underline fw-bold fs-5 mt-2' style={{ color: "#000078", fontFamily: "Kalinga" }}>
            {data !== null ? data.opName : null}
        </div>
        <div className='col-11 mx-auto mt-2'>
            <input className='form-control' placeholder='Enter Press Release Title' value={Title} id='title' onChange={(e)=>{setTitle(e.target.value)}} required />
        </div>
        <hr />
        <div className='col-11 mx-auto'>
            <div className='mt-1'>
                <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Rating Rationale 1-Year:</label>
                <div>
                    <textarea className='form-control' id='rating_rationale_1' value={rating_rationale_text_1} onChange={(e)=>{setrating_rationale_text_1(e.target.value)}} />
                </div>
            </div>
            <div className='mt-1'>
                <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Rating Rationale 3-Year:</label>
                <div>
                    <textarea className='form-control' id='rating_rationale_3' value={rating_rationale_text_3} onChange={(e)=>{setrating_rationale_text_3(e.target.value)}} />
                </div>
            </div>
            <div className='mt-1'>
                <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Rating Rationale 5-Year:</label>
                <div>
                    <textarea className='form-control' id='rating_rationale_5' value={rating_rationale_text_5} onChange={(e)=>{setrating_rationale_text_5(e.target.value)}} />
                </div>
            </div>
            <div className='mt-1'>
                <label className='fs-5 fw-bold' style={{ color: "#000078", fontFamily: "Kalinga" }}>Total Funds:</label>
                <div>
                    <input className='form-control' type='text' id='total_fund' value={total_fund} onChange={(e)=>{settotal_fund(e.target.value)}} />
                </div>
            </div> 
            <hr />

            <div className='mt-1'>
                <table className='table table-bordered'>
                    <tbody>
                        <tr>
                            <td className="col-2">Image 1-Year</td>
                            <td className="col-4">
                                <input className='form-control' type='file' onChange={(e) => {
                                    let newFiles = file1
                                    newFiles = e.target.files[0];
                                    setfiles1(newFiles);
                                    setfiles1name(newFiles.name);
                                }}/>
                            </td>
                            <td className="col-4"><span>{file1name}</span></td>
                        </tr>
                        <tr>
                            <td className='col-2'>Image 3-Year</td>
                            <td className="col-4">
                                <input className='form-control' type='file' onChange={(e) => {
                                    let newFiles = file2
                                    newFiles = e.target.files[0];
                                    setfiles2(newFiles);
                                    setfiles2name(newFiles.name);
                                }} />
                            </td>
                            <td className="col-4"><span>{file2name}</span></td>
                        </tr>
                        <tr>
                            <td className="col-2">Image 5-Year</td>
                            <td className="col-4">
                                <input className='form-control' type='file' onChange={(e) => {
                                    let newFiles = file3
                                    newFiles = e.target.files[0];
                                    setfiles3(newFiles);
                                    setfiles3name(newFiles.name);
                                }} />
                            </td>
                            <td className='col-4'><span>{file3name}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                        severity={alertType}
                        action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setalertopen(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        sx={{ mb: 2 }}
                      >
                        {AlertMessage}
                    </Alert>
                </Collapse>
        </Box>
            <div className='mx-auto mt-2' style={{width: "fit-content"}}>
                <button className='btn btn-primary btn-sm' type='submit'>Save</button>
            </div>
        </div>
      </form> : 
            (<div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
            <div class="wrapper" id="loader2">
              <svg
                class="hourglass"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 120 206"
                preserveAspectRatio="none"
              >
                <path
                  class="middle"
                  d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
                />
                <path
                  class="outer"
                  d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
                />
              </svg>
            </div>
          </div>)}
    </div>
  )
}
