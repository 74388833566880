import React, {useState, useEffect} from 'react'
import axios from 'axios';
import TableComponent from '../../../Atoms/TableComponent';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { NavLink } from "react-router-dom";
import { Check, Clear, Event } from "@material-ui/icons";
import { Table, TableCell, TableRow, Tooltip } from "@mui/material";
import { url } from '../../../../Config';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Checkbox from '@mui/material/Checkbox';
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { encryptParams } from '../../encrypt_url';


export default function Bypass_dd_list(props) {
    const [data, setdata] = useState([]);
    const [alertopen, setalertopen] = useState(false);
    const [AlertMessage, setAlertMessage] = useState(null);
    const [alertType, setalertType] = useState("error");
    const [bool, setbool] = useState(false);

    useEffect(()=>{

        const func = async ()=>{
            const res = await axios.get(`${url}/api/bypassdd`);
            const resp = res.data;
            setdata(resp);

        }

        func();
    },[bool])

    function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 210;
        }
      };
      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };
      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
  

      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };
      const outstandingdatefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent = gridApi.api.getFilterInstance("Notification");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
        var dateFilterComponent1 = gridApi.api.getFilterInstance("Dissemination");
        dateFilterComponent1.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };

      const customComparator = (valueA, valueB) => {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      };

      const approve = async (record_id)=>{
        setalertopen(false);
        document.getElementById(`approve${record_id}`).hidden = true;
        document.getElementById(`load${record_id}`).hidden = false;
        const res = await axios.post(`${url}/api/approvebypassdd`,{
            'record_id': record_id
        });
        const resp = res.data;
        if(resp.status == true){
            document.getElementById(`approve${record_id}`).hidden = false;
            document.getElementById(`load${record_id}`).hidden = true;
            setbool(!bool);
            setalertType("success");
            setAlertMessage(resp.message);
            setalertopen(true);
            
        }
        else{
            document.getElementById(`approve${record_id}`).hidden = false;
            document.getElementById(`load${record_id}`).hidden = true;
            setalertType("error");
            setAlertMessage(resp.message);
            setalertopen(true);
        }
      }

      const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          field: "sNo",
          // minWidth: 66,
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },

        {
            headerName: "Opinion",
            minWidth: responsiveColumns(),
            // comparator: customComparator,
            field: "name",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            filterParams: {
              caseSensitive: false,
            },
            cellRenderer: function (params) {
              if (params.value === "nullData") return <Skeleton />;
              else{
      
                if(params.data != null){
                  const clientId = params.data.opinion_id;
                  const encryptedParams = encryptParams({clientId});
                  return (
                    <a
                      href={`/OpinionDetails/${encryptedParams}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary"
                    >
                      {params.value}
                    </a>
                  );
              }
              }
            },
            // cellRenderer: cellrandered,
            tooltipField: "Entity",
            pinned: responsiveColumnPin(),
        },
        {
            headerName: "DD",
            field: "dd_date",
            minWidth: 125,
            hide: columnHide(),
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            debounceMs: "DateFilter",
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
            },
            cellRenderer: fullDate,
          },

          {
            headerName: "Status",
            hide: columnHide(),
            field: "status",
            minWidth: 100,
            sortable: true,
            filter: "agSetColumnFilter",
            tooltipField: "status",
            excelMode: "windows",
            cellRenderer: cellrandered,
          },

          {
            headerName: "Actions",
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            field: "priority",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
                return(
                    <div>
                    <Tooltip title='Approve'><><i className="fa-solid fa-circle-check fa-lg me-1" id={`approve${params.data.record_id}`} data-bs-toggle="tooltip" data-bs-placement="top" title="Approve" hidden={false} style={{color: 'green'}} onClick={()=>{approve(params.data.record_id)}}></i><i className="fa-solid fa-spinner fa-spin fa-lg me-1" id={`load${params.data.record_id}`} hidden={true} style={{color: 'green'}}></i></></Tooltip>
                    {/* <Tooltip title='Decline'><ThumbDownAltIcon className='me-1' sx={{color: 'red'}} data-bs-toggle="modal" data-bs-target={`#reason-decline${params.data.record_id}`}/></Tooltip> */}
                    <Tooltip title="View Reason"><VisibilityIcon className='me-1' sx={{color: 'blue'}} data-bs-toggle="modal" data-bs-target={`#reason-view${params.data.record_id}`} /></Tooltip>
                    </div>
                  )
            }

          }

  
      ];
      
      const MobViewRender = (params) => (
        <h1 style={{ padding: "10px 20px" }}>

        </h1>
      );

  return (
    <div>
           <Box className="mx-auto" sx={{ width: "98%" }}>
                        <Collapse in={alertopen}>
                            <Alert
                            severity={alertType}
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setalertopen(false);
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            >
                            {AlertMessage}
                            </Alert>
                        </Collapse>
            </Box>
        {
            data.map((value, index)=>{
                return(
                    <div className="modal fade" id={`reason-view${value.record_id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Reason to Bypass 15 days DD : {value.name}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='fw-bold'>
                                Reason
                                </div>
                                <div>
                                {value.reason}
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
      <TableComponent
        Data={data}
        screenWidth={props.screenWidth}
        MobViewRender={MobViewRender}
        columnDefs={columnDefs}
        datefilters={outstandingdatefilters}
        datefilter={false}
      />
    </div>
  )
}
