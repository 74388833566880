import React,{useEffect, useState, useRef} from 'react';
import { url } from '../../../../../Config';
import Select from "react-select";
import axios from 'axios';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import RecommendIcon from '@mui/icons-material/Recommend';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Swal from "sweetalert2";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ApprovalIcon from '@mui/icons-material/Approval';
import TableComponent from '../../../../Atoms/TableComponent';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";


export default function Rm_portfolio_list(props) {

    const userid = localStorage.getItem("userID");
    const [data, setdata] = useState([]);


    useEffect(()=>{
        setdata([])
        const func = async ()=>{
            const res = await axios.get(`${url}/api/rm_support_portfolio_record`);
            const resp = res.data;
            setdata(resp);
        }
        func();
      },[])


      const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };
  
      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };
    
      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
    
      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
    
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };
    
    const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Opinion",
          minWidth: 250,
          field: "opinion_name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "opinion_name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Team Lead",
          minWidth: 150,
          field: "team_lead_name",
          sortable: true,
          hide: columnHide(),
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "team_lead_name",
        },
        {
          headerName: "Current Analyst",
          minWidth: 150,
          field: "analyst_name",
          sortable: true,
          hide: columnHide(),
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "team_lead_name",
        },
        {
          headerName: "Reason",
          field: "reason_for_support_analyst",
          minWidth: responsiveColumns(),
          hide: columnHide(),
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: (params)=>{
            return(
                <Tooltip title="View"><VisibilityIcon sx={{color: 'blue'}} data-bs-toggle="modal" data-bs-target={`#reason${params.data.opinion_id}`}/></Tooltip>
            )
          },
          // pinned: responsiveColumnPin(),
        },
        {
            headerName: "Assign Analyst",
            minWidth: 150,
            field: "",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
                return(
                    <>
                      <button className='btn btn-primary btn-sm' onClick={()=>{window.open(`/assign-support-analyst/${params.data.id}`)}}>Assign</button>
                    </>
                  )
            },
        },
             
      ];
  
    const MobViewRender = (params) => (
          <h1 style={{ padding: "10px 20px" }}>
            <Table className="overflow-scroll responsiveTableFonts">
              <TableRow>
                <TableCell
                  variant="head"
                  className="fw-bolder responsiveTableFonts py-1"
                >
                  Team Lead
                </TableCell>
                <TableCell className="responsiveTableFonts py-1">
                  {params.data.team_lead_name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  variant="head"
                  className="fw-bolder responsiveTableFonts py-1"
                >
                  Current Analyst
                </TableCell>
                <TableCell className="responsiveTableFonts py-1">
                  {params.data.analyst_name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  variant="head"
                  className="fw-bolder responsiveTableFonts py-1"
                >
                  Reason
                </TableCell>
                <TableCell className="responsiveTableFonts py-1">
                  {params.data.reason_for_support_analyst}
                </TableCell>
              </TableRow>
            </Table>
          </h1>
        );

  return (
    <div>
       {
                  data.map((value, index)=>{
                    return(
                    <>
                      <div className="modal fade" id={`reason${value.opinion_id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Reason</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                               <div>
                                {value.reason_for_support_analyst}
                               </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    </>
                    )
                  })
        }

       <TableComponent
                Data={data}
                screenWidth={props.screenWidth}
                MobViewRender={MobViewRender}
                columnDefs={columnDefs}
                datefilters={datefilters}
                datefilter={false}
            />
    </div>
  )
}
