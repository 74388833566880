import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import "../../../index.css";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import test from "../../../Assets/Images/test.jpg";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import bg from "../../../Assets/Images/greyModel.jpg"
import white from "../../../Assets/Images/whitebg.jpg"
import { CardContent, Typography } from "@mui/material";
import { url } from "../../../Config";
import CloseIcon from '@mui/icons-material/Close';
import {encryptParams} from "../encrypt_url";
import SearchableDropdown from "../../Atoms/SearchableDropdown";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';


import "../../Atoms/style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "2px solid #cedbed",
  boxShadow: 24,
  p: 4,
  backgroundImage: `url(${white})`,
  backgroundSize: "cover",
};

export default function MyInprocess(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [check, setcheck] = useState("loading");
  const id = localStorage.getItem("userID");
  const [taskdates, settaskdates] = useState([]);
  const [searchby, setsearchby] = useState("All");
  const [rating_update, setrating_update] = useState([]);
  const [ratingType, setratingType] = useState([]);
  const [filterstatus, setfilter] = useState([]);
  const [alertopen, setalertOpen] = useState(false);
  const [alertType, setalertType] = useState();
  const [AlertMessage, setalertmessage] = useState();
  
  function DateFormat(params) {
    if (params == null || params == "") {
      return null;
    } else {
      const date = new Date(params);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }

  function DateFormat2(params) {
    if (params == null || params == "") {
      return "-";
    } else {
      const date = new Date(params);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }

  useEffect(()=>{
    const func = async ()=>{
      const rating_update_type = await axios.get(`${url}/api/rating_update_type`);
      const resp = rating_update_type.data;
      setrating_update(resp.sort((a, b) => a.title.localeCompare(b.title)));
      const rating_type = await axios.get(`${url}/api/rating_type`);
      const resp1 = rating_type.data;
      setratingType(resp1.sort((a, b) => a.title.localeCompare(b.title)));

    }
    func();
  },[])

  const [open, setOpen] = useState(Array(props.myinprocess.length).fill(false));
  let filteredOpinions = [];
  filteredOpinions = props.myinprocess.filter((value) => {
  if(value[searchby] != null && searchby !== "All" && searchTerm !== null){
    console.log(searchTerm, "searchTerm");
    return value[searchby].toLowerCase().includes(searchTerm.toLowerCase())
  }
  else if(searchby == "All" && searchTerm !== null){
    let Entity = [];
    if(value['Entity'] !== null){
      Entity = value['Entity'].toLowerCase().includes(searchTerm.toLowerCase());
    }
    let R_type = [];
    if(value['rating_type'] !== null){
      R_type = value['rating_type'].toLowerCase().includes(searchTerm.toLowerCase());
    }
    let r_updatetype = [];
    if(value['title'] !== null){
      r_updatetype = value['title'].toLowerCase().includes(searchTerm.toLowerCase());
    }
    let analystfilter = [];
    if(value['pacraAnalyst'] !== null){
      analystfilter = value['pacraAnalyst'].toLowerCase().includes(searchTerm.toLowerCase());
    }
    let leadfilter = [];
    if(value['managerName'] !== null){
     leadfilter = value['managerName'].toLowerCase().includes(searchTerm.toLowerCase());
    }

    return Entity || R_type || r_updatetype || analystfilter || leadfilter;
  }
  else if(searchTerm == null){
    return props.myinprocess;
  }
  }
  );

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
  };

 
  const Navigate = useNavigate();
  const handleOpen = async(index, opinion, record_id) => {
    settaskdates([]);
    setOpen((prevOpen) => {
      const newOpen = [...prevOpen];
      newOpen[index] = true;
      return newOpen;
    });
    const res = await axios.get(`${url}/api/taskdate/${opinion}/${record_id}`);
    const resp = res.data;
    settaskdates(resp);
  };

  const refreshDates = async (opinion, record_id)=>{
    settaskdates([]);
    const res = await axios.get(`${url}/api/taskdate/${opinion}/${record_id}`);
    const resp = res.data;
    settaskdates(resp);
  }

  const handleClose = (index) => {
    setOpen((prevOpen) => {
      const newOpen = [...prevOpen];
      newOpen[index] = false;
      return newOpen;
    });
  };

  const update_inititationDate = async (recordid, clientid, init_date)=>{

    setalertOpen(false);
    document.getElementById(`edit${recordid}`).style.display= 'none';
    document.getElementById(`load${recordid}`).hidden= false;
    const date = document.getElementById(`input${recordid}`).value
    const res = await axios.post(`${url}/api/update_initiation_date2`,{
      'record_id': recordid,
      'client_id': clientid,
      'old_date': init_date,
      'date': date
    })

    const resp =res.data;
    if(resp.status == true){
      document.getElementById(`edit${recordid}`).style.display= 'unset';
      document.getElementById(`load${recordid}`).hidden= true;
      setalertOpen(true);
      setalertmessage(resp.message);  
      setalertType("success");
    }
    else{
      document.getElementById(`edit${recordid}`).style.display= 'unset';
      document.getElementById(`load${recordid}`).hidden= true;
      setalertOpen(true);
      setalertmessage(resp.message);  
      setalertType("error");
    }
  }
  

  return (
    <div
      className="themeContainer"
      style={props.screenWidth < 500 ? {overflow: "scroll",backgroundImage:`url(${bg})`} : {height: "85vh", overflow: "scroll",backgroundImage:`url(${bg})`}}
    >
      <div>
        <div style={props.screenWidth > 771 ? {position: "fixed", zIndex: 999, width: "100%", backgroundColor: "white"}: null}>
            <div className="col-lg-6 col-md-6 col-sm-8 col-11 mx-auto p-2 d-flex mt-lg-0 mt-md-0 mt-sm-5 mt-5">

              {searchby == "pacraAnalyst" ?
              <select className="form-select" onChange={(e)=>{setSearchTerm(e.target.value)}}>
                <option>Select Analyst</option>
                <>{
                  props.analyst.map((value, index)=>{
                    return <option value={value.pacraAnalyst}>{value.pacraAnalyst}</option>
                  })
                }</>
              </select> :
              searchby == "managerName" ?
              <select className="form-select" onChange={(e)=>{setSearchTerm(e.target.value)}}>
                <option>Select Team Lead</option>
                <>{
                  props.lead.map((value, index)=>{
                    return <option value={value.managerName}>{value.managerName}</option>
                  })
                }</>
              </select> :
              searchby == "Entity" ?
              <SearchableDropdown 
              options={props.myinprocess}
              label={'Entity'}
              id={"id"}
              selectedVal={searchTerm}
              handleChange={(val) => setSearchTerm(val)}
              /> :
              searchby == "title" && searchby !== "All" ? 
              <select className="form-select" onChange={(e)=>{setSearchTerm(e.target.value)}}>
                <option>Select Update Type</option>
              <>{rating_update.map((value, index)=>{
                return(<option value={value.title}>{value.title}</option>);
              })}</>
              </select>:
              searchby == "All" ?
              <input
                id="search"
                className="form-control"
                type="text"
                placeholder="Search here.."
                autoComplete="off"
                onChange={handleSearch}
              /> :
              <select className="form-select" onChange={(e)=>{setSearchTerm(e.target.value)}}>
                <option>Select Rating Type</option>
              <>{ratingType.map((value, index)=>{
                return(<option value={value.title}>{value.title}</option>);
              })}</>
              </select>}
                <RefreshIcon
                className="ms-1"
                style={{ color: "#000078" }}
                onClick={() => {
                  props.setrefresh_inprocess((prerefresh_inprocess) => !prerefresh_inprocess);
                }}
              />
            </div>
            <div className="col-4 col-md-4 col-sm-6 col-8 mx-auto">
              <select className="form-select" id="search_select" onChange={(e)=>{
                setsearchby(e.target.value);
                setSearchTerm(null);
              }}>
                <option value={"All"}>All</option>
                <option value={"Entity"}>Client</option>
                <option value={"rating_type"}>Rating Type</option>
                <option value={"title"}>Rating Update Type</option>
                <option value={"pacraAnalyst"}>Analyst</option>
                <option value={"managerName"}>Team Lead</option>
              </select>
            </div>
        </div>
        {props.screenWidth > 771 && 
        <div style={{visibility: "hidden"}}>
            <div className="col-lg-6 col-md-6 col-sm-8 col-11 mx-auto p-2 d-flex mt-lg-0 mt-md-0 mt-sm-5 mt-5">

              {searchby == "pacraAnalyst" ?
              <select className="form-select" onChange={(e)=>{setSearchTerm(e.target.value)}}>
                <option>Select Analyst</option>
                <>{
                  props.analyst.map((value, index)=>{
                    return <option value={value.pacraAnalyst}>{value.pacraAnalyst}</option>
                  })
                }</>
              </select> :
              searchby == "managerName" ?
              <select className="form-select" onChange={(e)=>{setSearchTerm(e.target.value)}}>
                <option>Select Team Lead</option>
                <>{
                  props.lead.map((value, index)=>{
                    return <option value={value.managerName}>{value.managerName}</option>
                  })
                }</>
              </select> :
              searchby == "Entity" ?
              <SearchableDropdown 
              options={props.myinprocess}
              label={'Entity'}
              id={"id"}
              selectedVal={searchTerm}
              handleChange={(val) => setSearchTerm(val)}
              /> :
              searchby == "title" && searchby !== "All" ? 
              <select className="form-select" onChange={(e)=>{setSearchTerm(e.target.value)}}>
                <option>Select Update Type</option>
              <>{rating_update.map((value, index)=>{
                return(<option value={value.title}>{value.title}</option>);
              })}</>
              </select>:
              searchby == "All" ?
              <input
                id="search"
                className="form-control"
                type="text"
                placeholder="Search here.."
                autoComplete="off"
                onChange={handleSearch}
              /> :
              <select className="form-select" onChange={(e)=>{setSearchTerm(e.target.value)}}>
                <option>Select Rating Type</option>
              <>{ratingType.map((value, index)=>{
                return(<option value={value.title}>{value.title}</option>);
              })}</>
              </select>}
                <RefreshIcon
                className="ms-1"
                style={{ color: "#000078" }}
                onClick={() => {
                  props.setrefresh_inprocess((prerefresh_inprocess) => !prerefresh_inprocess);
                }}
              />
            </div>
            <div className="col-4 col-md-4 col-sm-6 col-8 mx-auto">
              <select className="form-select" id="search_select" onChange={(e)=>{
                setsearchby(e.target.value);
                setSearchTerm(null);
              }}>
                <option value={"All"}>All</option>
                <option value={"Entity"}>Client</option>
                <option value={"rating_type"}>Rating Type</option>
                <option value={"title"}>Rating Update Type</option>
                <option value={"pacraAnalyst"}>Analyst</option>
                <option value={"managerName"}>Team Lead</option>
              </select>
            </div>
        </div>}

        <div className="row ms-lg-5 ms-md-5 ms-sm-4 ms-2">
          {props.myinprocess.length > 0 ? <>
            {filteredOpinions.map((value, index) => {
              let arr = [];
              if(value.edit_module_tasks !== null){
                arr = value.edit_module_tasks.split(',');
              }
              else{
                arr = [];
              }

              return (
                <>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-1 mt-1">
                <Card
                  //  onMouseEnter={()=>{handleOpen(index, value.Id, value.record_id)}}
                   onClick={() => {
                    handleOpen(index, value.Id, value.record_id);
                  }}
                    
                    key={index}
                    className="card shadow col-lg-11 col-md-11 col-sm-11 col-12 hover-overlay ripple"
                    sx={{
                      backgroundImage: `url(${white})`,
                      backgroundColor: "white",
                      backgroundSize: "cover",
                      height : "100%"
                    }}
                  >
                    <CardContent>
                        {value.Entity &&  <div className="fw-bold fs-4" style={{fontFamily: "Kalinga"}}>{value.Entity}</div>}
                        {value.title && <Typography sx={{color: "black", fontFamily: "Kalinga", textShadow: "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white"}}> <b>Rating Update Type:</b> {value.title}</Typography>}
                        {value.Initiation && <Typography sx={{color: "black", fontFamily: "Kalinga", textShadow: "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white"}}> <b>Initiation Date:</b> {DateFormat(value.Initiation)}</Typography>}
                        <Typography sx={{color: "black", fontFamily: "Kalinga", textShadow: "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white"}}> <b>RC Approval:</b> {DateFormat2(value.rc_date)}</Typography>
                        {value.title && <Typography sx={{color: "black", fontFamily: "Kalinga", textShadow: "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white"}}> <b>Team Lead:</b> {value.managerName}</Typography>}
                        {value.title && <Typography sx={{color: "black", fontFamily: "Kalinga", textShadow: "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white"}}> <b>Analyst:</b> {value.pacraAnalyst}</Typography>}
                    </CardContent>
                  </Card>
                </div>
                  
                  <Modal
                    open={open[index]}
                    onClose={() => {
                      handleClose(index);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box className="col-10" sx={style}>
                      <div className ="text-end mb-0">
                        <RefreshIcon
                          className="ms-1"
                          style={{ color: "#000078" }}
                          onClick={() => {
                            refreshDates(value.Id, value.record_id);
                          }}
                        />
                        <CloseIcon onClick={()=>{handleClose(index)}} />
                      </div>
                      <div className="d-flex mx-auto" style={{width: "fit-content"}}>
                        <p
                          className={`fw-bold me-2 mt-0 ${props.screenWidth < 500 ? "fs-6" : "fs-3"} text-center`}
                          style={{ color: "#000078", fontFamily: "Kalinga", textShadow:
                          "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white",
                      }}
                        >
                          {value.Entity}
                        </p>
                      </div>
                      <Box className="mx-auto" sx={{ width: "98%" }}>
                        <Collapse in={alertopen}>
                            <Alert
                            severity={alertType}
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setalertOpen(false);
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            >
                            {AlertMessage}
                            </Alert>
                        </Collapse>
                      </Box>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="row">
                            <div className="col-md-4 col-lg-4 col-sm-5 col-12">
                              <label
                                className= {`fw-bold ${props.screenWidth < 500 ? "fs-6" : "fs-4"}`}
                                style={{
                                  color: "#000078",
                                  fontFamily: "Kalinga",
                                  textShadow: "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white",
                                }}
                              >
                                Update Type:
                              </label>
                            </div>
                            <div className="ms-1 col-lg-7 col-md-0 col-sm-0 col-12">
                              <input
                                className="form-control"
                                defaultValue={value.title}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-sm-1 mt-lg-0 mt-1">
                          <div className="row">
                            <div className="col-md-4 col-lg-4 col-sm-5 col-12">
                              <label
                                className={`fw-bold ${props.screenWidth < 500 ? "fs-6" : "fs-4"}`}
                                style={{
                                  color: "#000078",
                                  fontFamily: "Kalinga",
                                  textShadow: "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white",
                                }}
                              >
                                Initiation Date:
                              </label>
                            </div>
                            <div className="ms-1 col-lg-7 col-md-0 col-sm-0 col-12">
                              {/* {(value.reopen == 'Open' && arr.includes('Initiation Date') == true) ? 
                              <div className="row">
                                <div className="col-11">
                                  <input
                                    className="form-control"
                                    type="date"
                                    id={`input${value.record_id}`}
                                    defaultValue={value.Initiation}
                                  />
                                </div>
                                <div className="col">
                                  <EditIcon className="ms-1" id={`edit${value.record_id}`} style={{color: "#ffc107", display: 'unset'}} onClick={()=>{update_inititationDate(value.record_id, value.Id, value.Initiation)}} />
                                  <CircularProgress className="ms-1" id={`load${value.record_id}`} hidden={true} size={15} />
                                </div>
                              </div> : 
                              value.reopen == null ?
                              <input
                              className="form-control"
                              defaultValue={DateFormat(value.Initiation)}
                              disabled
                              /> : (value.reopen == 'Open' && arr.includes('Information') == 'Initiation Date') ? 
                              <input
                              className="form-control"
                              defaultValue={DateFormat(value.Initiation)}
                              disabled
                              /> :  <input
                              className="form-control"
                              defaultValue={DateFormat(value.Initiation)}
                              disabled
                              />} */}
                               <input
                              className="form-control"
                              defaultValue={DateFormat(value.Initiation)}
                              disabled
                              />

                                {/* <>
                                  <input
                                    className="form-control"
                                    type="date"
                                    defaultValue={value.Initiation}
                                  />
                                  <EditIcon style={{color: "#ffc107"}} />
                                </>
                              <input
                                className="form-control"
                                defaultValue={DateFormat(value.Initiation)}
                                disabled
                              /> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-sm-1 mt-lg-1 mt-1">
                          <div className="row">
                            <div className="col-lg-4 col-md-0 col-sm-5 col-12">
                              <label
                                className={`fw-bold ${props.screenWidth < 500 ? "fs-6" : "fs-4"}`}
                                style={{
                                  color: "#000078",
                                  fontFamily: "Kalinga",
                                  textShadow: "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white",
                                }}
                              >
                                Team Lead:
                              </label>
                            </div>
                            <div className="ms-1 col-lg-7 col-md-0 col-sm-0 col-12">
                              <input
                                className="form-control"
                                defaultValue={value.managerName}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-md-1 mt-sm-1 mt-lg-1 mt-1">
                          <div className="row">
                            <div className="col-md-4 col-lg-4 col-sm-5 col-5">
                              <label
                                className={`fw-bold ${props.screenWidth < 500 ? "fs-6" : "fs-4"}`}
                                style={{
                                  color: "#000078",
                                  fontFamily: "Kalinga",
                                  textShadow: "1px 1px white, -1px 1px white, 1px -1px white, -1px -1px white",
                                }}
                              >
                                Analyst:
                              </label>
                            </div>
                            <div className="ms-1 col-lg-7 col-md-0 col-sm-0 col-12">
                              <input
                                className="form-control"
                                defaultValue={value.pacraAnalyst}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row" style={props.screenWidth > 1000 ? { marginLeft: "150px" } : props.screenWidth <= 1000 && props.screenWidth > 900 ? { marginLeft: "110px" } : {height: 126 , overflow: "scroll"}}>
                        <button
                          type="button"
                          className="btn col-lg-3 col-md-5 col-sm-10 ms-lg-4 ms-md-3 ms-sm-1 ms-0 mt-1"
                          disabled={(value.reopen == 'Open' && arr.includes('Information') == true) ? false : value.reopen == null ? false : (value.reopen == 'Open' && arr.includes('Information') == false) ? true : false}
                          style={{
                            color: "black",
                            backgroundImage: `url(${test})`,
                            borderColor: "#cedbed",
                            backgroundColor: "#cedbed",
                          }}
                          onClick={async() => {
                            if(id !== '19' && id !== "42" && id !== "12"){
                              document.getElementById("info").innerHTML = "Please Wait..."
                              const client_id = value.Id;
                              const og_rating_id = value.record_id;
                              const encryptedParams = encryptParams({client_id, og_rating_id});

                              const res = await axios.get(`${url}/api/checkundertaking/${id}/${value.Id}/${value.record_id}`);
                              const data = res.data;
                              if(data.length == 0){
                                document.getElementById("info").innerHTML = "Information";
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const company = value.Entity;
                                const encryptedParams = encryptParams({client_id, og_rating_id, company});
                                window.open(`Ratings/undertaking/${encryptedParams}`);
                              }
                              else{
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const encryptedParams = encryptParams({client_id, og_rating_id});
                                window.open(`Ratings/addTask/Information/${encryptedParams}`, '_blank');
                                document.getElementById("info").innerHTML = "Information"
                              }
                            }
                            else{
                              const client_id = value.Id;
                              const og_rating_id = value.record_id;
                              const encryptedParams = encryptParams({client_id, og_rating_id})
                              window.open(`Ratings/addTask/Information/${encryptedParams}`, '_blank');
                            }
                          }}
                        >
                        <div id="info">Information</div><div style={{color: '#228B22'}}>{taskdates.length > 0 && taskdates[0].Information}</div>
                        </button>
                        <button
                          type="button"
                          className="btn col-lg-3 col-md-5 col-sm-10 ms-lg-4 ms-md-3 ms-sm-1 ms-0 mt-1"
                          disabled={(value.reopen == 'Open' && arr.includes('Assessment') == true) ? false : (value.reopen == null) ? false : (value.reopen == 'Open' && arr.includes('Assessment') == false) ? true : false}
                          onClick={async () => {
                            if(id !== '19' && id !== "42" && id !== "12"){
                              document.getElementById("assessment").innerHTML = "Please Wait..."
                              const res = await axios.get(`${url}/api/checkundertaking/${id}/${value.Id}/${value.record_id}`);
                              const data = res.data;
                              if(data.length == 0){
                                document.getElementById("assessment").innerHTML = "Assessment"
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const company = value.Entity;
                                const encryptedParams = encryptParams({client_id, og_rating_id, company});
                                window.open(`Ratings/undertaking/${encryptedParams}`);
                              }
                              else{
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const encryptedParams = encryptParams({client_id, og_rating_id});
                                window.open(`Ratings/addTask/Assessment/${encryptedParams}`, '_blank');
                                document.getElementById("assessment").innerHTML = "Assessment"
                              }
                            }
                            else{
                              const client_id = value.Id;
                              const og_rating_id = value.record_id;
                              const encryptedParams = encryptParams({client_id, og_rating_id})
                              window.open(`Ratings/addTask/Assessment/${encryptedParams}`, '_blank');
                            }
                          }}
                          style={{
                            color: "black",
                            backgroundImage: `url(${test})`,
                            borderColor: "#cedbed",
                            backgroundColor: "#cedbed",
                          }}
                        >
                        <div id="assessment"> Assessment </div> <div style={{color: '#228B22'}}>{taskdates.length > 0 && taskdates[0].Assessment}</div>
                        </button>
                        <button
                          type="button"
                          className="btn col-lg-3 col-md-5 col-sm-10 ms-lg-4 ms-md-3 ms-sm-1 ms-0 mt-1"
                          disabled={(value.reopen == 'Open' && arr.includes('Site Visit') == true) ? false : value.reopen == null ? false : (value.reopen == 'Open' && arr.includes('Site Visit') == false) ? true : false}
                          onClick={async() => {
                            if(id !== '19' && id !== "42" && id !== "12"){
                              document.getElementById("sv").innerHTML = "Please Wait..."
                              const res = await axios.get(`${url}/api/checkundertaking/${id}/${value.Id}/${value.record_id}`);
                              const data = res.data;
                              if(data.length == 0){
                                document.getElementById("sv").innerHTML = "Site Visit"
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const company = value.Entity;
                                const encryptedParams = encryptParams({client_id, og_rating_id, company});
                                window.open(`Ratings/undertaking/${encryptedParams}`);
                              }
                              else{
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const encryptedParams = encryptParams({client_id, og_rating_id});
                                window.open(`Ratings/addTask/SiteVisit/${encryptedParams}`);
                                document.getElementById("sv").innerHTML = "Site Visit"
                              }
                            }
                            else{
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const encryptedParams = encryptParams({client_id, og_rating_id});
                                window.open(`Ratings/addTask/SiteVisit/${encryptedParams}`);
                            }
                          }}
                          style={{
                            color: "black",
                            backgroundImage: `url(${test})`,
                            borderColor: "#cedbed",
                            backgroundColor: "#cedbed",
                          }}
                        >
                          <div id="sv">Site Visit</div><div style={{color: '#228B22'}}>{taskdates.length > 0 && taskdates[0]["Site Visit"]}</div>
                        </button>
                        <button
                          type="button"
                          className="btn col-lg-3 col-md-5 col-sm-10 ms-lg-4 ms-md-3 ms-sm-1 ms-0 mt-1"
                          disabled={(value.reopen == 'Open' && arr.includes('MM') == true) ? false : value.reopen == null ? false : (value.reopen == 'Open' && arr.includes('MM') == false) ? true : false}
                          onClick={async() => {
                            if(id !== '19' && id !== "42" && id !== "12"){
                              document.getElementById("mm").innerHTML = "Please Wait..."
                              const res = await axios.get(`${url}/api/checkundertaking/${id}/${value.Id}/${value.record_id}`);
                              const data = res.data;
                              if(data.length == 0){
                                document.getElementById("mm").innerHTML = "MM"
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const company = value.Entity;
                                const encryptedParams = encryptParams({client_id, og_rating_id, company});
                                window.open(`Ratings/undertaking/${encryptedParams}`);
                              }
                              else{
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const encryptedParams = encryptParams({client_id, og_rating_id});
                                window.open(`Ratings/addTask/MM/${encryptedParams}`);
                                document.getElementById("mm").innerHTML = "MM"
                              }
                            }
                            else{
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const encryptedParams = encryptParams({client_id, og_rating_id});
                                window.open(`Ratings/addTask/MM/${encryptedParams}`);
                            }
                          }}
                          style={{
                            color: "black",
                            backgroundImage: `url(${test})`,
                            borderColor: "#cedbed",
                            backgroundColor: "#cedbed",
                          }}
                        >
                          <div id="mm">MM</div><div style={{color: '#228B22'}}>{taskdates.length > 0 && taskdates[0].MM}</div>
                        </button>
                        <button
                          type="button"
                          className="btn col-lg-3 col-md-5 col-sm-10 ms-lg-4 ms-md-3 ms-sm-1 ms-0 mt-1"
                          disabled={(value.reopen == 'Open' && arr.includes('RC') == true) ? false : value.reopen == null ? false : (value.reopen == 'Open' && arr.includes('RC') == false) ? true : false}

                          onClick={async() => {
                            if(id !== '19' && id !== "42" && id !== "12"){
                              document.getElementById("rc").innerHTML = "Please Wait..."
                              const res = await axios.get(`${url}/api/checkundertaking/${id}/${value.Id}/${value.record_id}`);
                              const data = res.data;
                              if(data.length == 0){
                                document.getElementById("rc").innerHTML = "RC"
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const company = value.Entity;
                                const encryptedParams = encryptParams({client_id, og_rating_id, company});
                                window.open(`Ratings/undertaking/${encryptedParams}`);
                              }
                              else{
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const encryptedParams = encryptParams({client_id, og_rating_id});
                                window.open(`Ratings/addTask/RC/${encryptedParams}`);
                                document.getElementById("rc").innerHTML = "RC"
                              }
                            }
                            else{
                              const client_id = value.Id;
                              const og_rating_id = value.record_id;
                              const encryptedParams = encryptParams({client_id, og_rating_id});
                              window.open(`Ratings/addTask/RC/${encryptedParams}`);
                            }
                          }}
                          style={{
                            color: "black",
                            backgroundImage: `url(${test})`,
                            borderColor: "#cedbed",
                            backgroundColor: "#cedbed",
                          }}
                        >
                          <div id="rc">RC</div><div style={{color: '#228B22'}}>{taskdates.length > 0 ? taskdates[0].RC_Cover !== null ? DateFormat(taskdates[0].RC_Cover) : null : null}</div>
                        </button>
                        <button
                          type="button"
                          className="btn col-lg-3 col-md-5 col-sm-10 ms-lg-4 ms-md-3 ms-sm-1 ms-0 mt-1"
                          // disabled={(value.reopen == 'Open' && arr.includes('PPL') == true) ? false : value.reopen == null ? false : (value.reopen == 'Open' && arr.includes('PPL') == false) ? true : false}

                          onClick={async() => {
                            if(id !== '19' && id !== "42" && id !== "12"){
                              document.getElementById("ppl").innerHTML = "Please Wait..."
                              const res = await axios.get(`${url}/api/checkundertaking/${id}/${value.Id}/${value.record_id}`);
                              const data = res.data;
                              if(data.length == 0){
                                document.getElementById("ppl").innerHTML = "PPL"
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const company = value.Entity;
                                const encryptedParams = encryptParams({client_id, og_rating_id, company});
                                window.open(`Ratings/undertaking/${encryptedParams}`);
                              }
                              else{
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const encryptedParams = encryptParams({client_id, og_rating_id});
                                window.open(`Ratings/addTask/PPL/${encryptedParams}`);
                                document.getElementById("ppl").innerHTML = "PPL"
                              }
                            }
                            else{
                              const client_id = value.Id;
                              const og_rating_id = value.record_id;
                              const encryptedParams = encryptParams({client_id, og_rating_id});
                              window.open(`Ratings/addTask/PPL/${encryptedParams}`);
                            }
                          }}
                          style={{
                            color: "black",
                            backgroundImage: `url(${test})`,
                            borderColor: "#cedbed",
                            backgroundColor: "#cedbed",
                          }}
                        >
                          <div id="ppl">PPL</div><div style={{color: '#228B22'}}>{taskdates.length > 0 && DateFormat(taskdates[0].PPL)}</div>
                        </button>
                        <button
                          type="button"
                          className="btn col-lg-3 col-md-5 col-sm-10 ms-lg-4 ms-md-3 ms-sm-1 ms-0 mt-1"
                          // disabled={(value.reopen == 'Open' && arr.includes('NL') == true) ? false : value.reopen == null ? false : (value.reopen == 'Open' && arr.includes('NL') == false) ? true : false}

                          onClick={async() => {
                            if(id !== '19' && id !== "42" && id !== "12"){
                              document.getElementById("NL").innerHTML = "Please Wait..."
                              const res = await axios.get(`${url}/api/checkundertaking/${id}/${value.Id}/${value.record_id}`);
                              const data = res.data;
                              if(data.length == 0){
                                document.getElementById("NL").innerHTML = "NL"
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const company = value.Entity;
                                const encryptedParams = encryptParams({client_id, og_rating_id, company});
                                window.open(`Ratings/undertaking/${encryptedParams}`);
                              }
                              else{
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const encryptedParams = encryptParams({client_id, og_rating_id});
                                window.open(`Ratings/addTask/NL/${encryptedParams}`);
                                document.getElementById("NL").innerHTML = "NL"
                              }
                            }
                            else{
                              const client_id = value.Id;
                              const og_rating_id = value.record_id;
                              const encryptedParams = encryptParams({client_id, og_rating_id});
                              window.open(`Ratings/addTask/NL/${encryptedParams}`);
                            }
                          }}
                          style={{
                            color: "black",
                            backgroundImage: `url(${test})`,
                            borderColor: "#cedbed",
                            backgroundColor: "#cedbed",
                          }}
                        >
                          <div id="NL">NL</div><div style={{color: '#228B22'}}>{taskdates.length > 0 && DateFormat(taskdates[0].NL)}</div>
                        </button>
                        <button
                          type="button"
                          className="btn col-lg-3 col-md-5 col-sm-10 ms-lg-4 ms-md-3 ms-sm-1 ms-0 mt-1"

                          onClick={async() => {
                            if(id !== '19' && id !== "42" && id !== "12"){
                              document.getElementById("RR").innerHTML = "Please Wait..."
                              const res = await axios.get(`${url}/api/checkundertaking/${id}/${value.Id}/${value.record_id}`);
                              const data = res.data;
                              if(data.length == 0){
                                document.getElementById("RR").innerHTML = "RR"
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const company = value.Entity;
                                const encryptedParams = encryptParams({client_id, og_rating_id, company});
                                window.open(`Ratings/undertaking/${encryptedParams}`);
                              }
                              else{
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const encryptedParams = encryptParams({client_id, og_rating_id});
                                window.open(`Ratings/addTask/RR/${encryptedParams}`);
                                document.getElementById("RR").innerHTML = "RR"
                              }
                            }
                            else{
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const encryptedParams = encryptParams({client_id, og_rating_id});
                                window.open(`Ratings/addTask/RR/${encryptedParams}`);
                            }
                          }}
                          style={{
                            color: "black",
                            backgroundImage: `url(${test})`,
                            borderColor: "#cedbed",
                            backgroundColor: "#cedbed",
                          }}
                        >
                          <div id="RR">RR</div><div style={{color: '#228B22'}}>{taskdates.length > 0 && DateFormat(taskdates[0].RR)}</div>
                        </button>
                        <button
                          type="button"
                          className="btn col-lg-3 col-md-5 col-sm-10 ms-lg-4 ms-md-3 ms-sm-1 ms-0 mt-1"
                          disabled={(value.reopen == 'Open' && arr.includes('RED') == true) ? false : value.reopen == null ? false : (value.reopen == 'Open' && arr.includes('RED') == false) ? true : false}

                          onClick={async() => {
                            if(id !== '19' && id !== "42" && id !== "12"){
                              document.getElementById("RED").innerHTML = "Please Wait..."
                              const res = await axios.get(`${url}/api/checkundertaking/${id}/${value.Id}/${value.record_id}`);
                              const data = res.data;
                              if(data.length == 0){
                                document.getElementById("RED").innerHTML = "RED"
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const company = value.Entity;
                                const encryptedParams = encryptParams({client_id, og_rating_id, company});
                                window.open(`Ratings/undertaking/${encryptedParams}`);
                              }
                              else{
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const encryptedParams = encryptParams({client_id, og_rating_id});
                                window.open(`Ratings/addTask/RED/${encryptedParams}`);
                                document.getElementById("RED").innerHTML = "RED"
                              }
                            }
                            else{
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const encryptedParams = encryptParams({client_id, og_rating_id});
                                window.open(`Ratings/addTask/RED/${encryptedParams}`);
                            }
                          }}
                          style={{
                            color: "black",
                            backgroundImage: `url(${test})`,
                            borderColor: "#cedbed",
                            backgroundColor: "#cedbed",
                          }}
                        >
                          <div id="RED">RED</div><div style={{color: '#228B22'}}>{taskdates.length > 0 && taskdates[0].RED}</div>
                        </button>
                        <button
                          type="button"
                          className="btn col-lg-3 col-md-5 col-sm-10 ms-lg-4 ms-md-3 ms-sm-1 ms-0 mt-1"
                          onClick={async() => {
                            if(id !== '19' && id !== "42" && id !== "12"){
                              document.getElementById("fc").innerHTML = "Please Wait..."
                              const res = await axios.get(`${url}/api/checkundertaking/${id}/${value.Id}/${value.record_id}`);
                              const data = res.data;
                              if(data.length == 0){
                                document.getElementById("fc").innerHTML = "File Closure"
                                const client_id = value.Id;
                                const og_rating_id = value.record_id;
                                const company = value.Entity;
                                const encryptedParams = encryptParams({client_id, og_rating_id, company});
                                window.open(`Ratings/undertaking/${encryptedParams}`);
                              }
                              else{
                                window.open(`FC/${localStorage.getItem("userID")}/${value.Id}/${value.record_id}`);
                                document.getElementById("fc").innerHTML = "File Closure"
                              }
                            }
                            else{
                              window.open(`FC/${localStorage.getItem("userID")}/${value.Id}/${value.record_id}`);
                            }
                            
                          }}
                          style={{
                            color: "black",
                            backgroundImage: `url(${test})`,
                            // width: "740px",
                            borderColor: "#cedbed",
                            backgroundColor: "#cedbed",
                          }}
                        >
                          <div id="fc">File Closure</div><div style={{color: '#228B22'}}>{value.FC}</div>
                        </button>
                      </div>
                    </Box>
                  </Modal>
                </>
              );
            })}
            </>
           : (
            <div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
              <div class="wrapper" id="loader2">
                <svg
                  class="hourglass"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 120 206"
                  preserveAspectRatio="none"
                >
                  <path
                    class="middle"
                    d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
                  />
                  <path
                    class="outer"
                    d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
                  />
                </svg>
              </div>
            </div>
          )}

        </div>
        <div className="card mt-1 pt-2 d-flex" style={{bottom: 0, position: "fixed", zIndex: 999, width: "100%"}}>
            <div className="text-center">
              <b>Total Clients:</b> {props.myinprocess.length}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Filtered Clients:</b> {filteredOpinions.length}
            </div>
          </div>
      </div>
    </div>
  );
}
