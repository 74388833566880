import React, {useState, useEffect, useRef} from 'react';
import { url } from '../../../../../Config';
import axios from 'axios';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from 'react-router-dom';


export default function Addsponders(props) {

    const id = localStorage.getItem("userID");
    const Navigate = useNavigate();
    const nameRef = useRef(null);
    const sharesRef = useRef(null);
    const PercentageRef = useRef(null);
    const AssociationRef = useRef(null);
    const positionRef = useRef(null);
    const introRef = useRef(null);
    const imageRef = useRef(null);
    const btnRef = useRef(null);


    const [img, setimg] = useState(null);    
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();

    const submitForm = async (event)=>{
        event.preventDefault();
        setalertOpen(false);
        if(AssociationRef.current.value == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Option from Association First!`);
            return ;
        }
        if(positionRef.current.value == ''){
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`Select Option from Position in company First!`);
            return ;
        }
        const input = document.getElementById('imageInput');
        const allowedFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg+xml'];
        const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

        const file = input.files[0];
        
        // Check file format
        if (!allowedFormats.includes(file.type)) {
            setalertType("error");
            setalertOpen(true);
            setalertmessage('Invalid file format. Please choose a valid image file.');
            return;
        }

        // Check file size
        if (file.size > maxFileSize) {
            setalertType("error");
            setalertOpen(true);
            setalertmessage('File size exceeds the maximum allowed limit (2MB).');
            return;
        }

        btnRef.current.disabled = true;

            const fileData = new FormData();
            fileData.append("sponsorName", nameRef.current.value);
            fileData.append("noOfshares", sharesRef.current.value);
            fileData.append("sharesPercent", PercentageRef.current.value);
            fileData.append("association", AssociationRef.current.value);
            fileData.append("position", positionRef.current.value);
            fileData.append("txtAreaIntro", introRef.current.value);
            fileData.append("sponsorsImage", img);
            fileData.append("id", Number(id));

            const res = await axios.post(`${url}/api/storeShareholders`,
                fileData,
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
            );
            
            const resp = res.data;
            if(resp.status == true){
                Navigate(`/edit-shareholder/${resp.id}`);
            }
            else{
                btnRef.current.disabled = false;
                setalertType("error");
                setalertOpen(true);
                setalertmessage(resp.message);
            }
        
    }
  return (
    <div>
        <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/secp-dashboard"} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Add Sponsor/Sharehoder</li>
                </ol>
            </nav>
            <hr/>
        </div>
      <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
        Add Sponsor/Sharehoder
      </div>
      <form className ='col-10 card shadow p-3 mx-auto mt-2' onSubmit={(event)=>{submitForm(event)}}>
        <div>
            <div className='fw-bold'>
                Sharehoder/Sponsor
            </div>
            <div className='mt-2'>
                <input className='form-control' ref={nameRef} placeholder='Enter Name' required />
            </div>
        </div>
        <div>
            <div className='mt-2 fw-bold'>
                No. of Shares
            </div>
            <div className='mt-2'>
                <input className='form-control' ref={sharesRef} placeholder='Enter Shares' required />
            </div>
        </div>
        <div>
            <div className='mt-2 fw-bold'>
                Shares Percentage
            </div>
            <div className='mt-2'>
                <input className='form-control' ref={PercentageRef} placeholder='Enter Percentage' required />
            </div>
        </div>
        <div>
            <div className='mt-2 fw-bold'>
                Association
            </div>
            <div className='mt-2'>
                <select className='form-select' ref={AssociationRef}>
                    <option value="">Select Association</option>
                    <option value="Associated">Associated</option>
                    <option value="Not associated">Not associated</option>
                </select>
            </div>
        </div>
        <div>
            <div className='mt-2 fw-bold'>
                Position in company
            </div>
            <div className='mt-2'>
                <select className='form-select' ref={positionRef}>
                    <option value="">Select Position in company</option>
                    <option value="Nominee Directors / Shareholder">Nominee Directors / Shareholder</option>
                    <option value="Director / Shareholder">Director / Shareholder</option>
                    <option value="Shareholder">Shareholder</option>
                </select>
            </div>
        </div>
        <div>
            <div className='mt-2 fw-bold'>
                Introduction
            </div>
            <div className='mt-2'>
                <textarea className='form-control' ref={introRef} required />
            </div>
        </div>
        <div>
            <div className='mt-2 fw-bold'>
                Image
            </div>
            <div className='mt-2'>
                <input className='form-control' ref={imageRef} type='file' id="imageInput" onChange={(e)=>{
                setimg(e.target.files[0]);
            }} accept=".jpeg, .jpg, .png, .gif, .svg"  required />
            </div>
        </div>
        <Box className="mx-auto" sx={{ width: "98%" }}>
            <Collapse in={alertopen}>
                <Alert
                severity={alertType}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setalertOpen(false);
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {AlertMessage}
                </Alert>
            </Collapse>
        </Box>
        <div className='mt-3'>
            <button className='btn btn-primary' ref={btnRef}  type='submit'>Save</button>
        </div>
      </form>
      <br/>
    </div>
  )
}
