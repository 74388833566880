import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { NavLink } from "react-router-dom";
import { Check, Clear, Event } from "@material-ui/icons";
import { Table, TableCell, TableRow } from "@mui/material";
import GetGridsApi from "../../../API/GetGridsApi";
import TableComponent from "../../Atoms/TableComponent";

export default function RatingWd(props) {
    const [ratingWdApi, setRatingWdApi] = useState([]);
    const [rowstatus, setrowstatus] = useState();
    const [Update, setUpdate] = useState();
    const [startdate, setstartdate] = useState();
    const [enddate, setenddate] = useState();
    const [fromDate, setfromDate] = useState(localStorage.getItem('reportsfirstDate'));
    const [toDate, settoDate] = useState(localStorage.getItem('reportslastDate'));
    const responsiveColumns = () => {
      if (props.screenWidth < 770) {
        return null;
      } else {
        return 120;
      }
    };
    const responsiveColumnPin = () => {
      if (props.screenWidth < 770) {
        return null;
      } else {
        return "left";
      }
    };
    const detailShow = () => {
      if (props.screenWidth < 770) {
        return false;
      } else {
        return true;
      }
    };

    const columnHide = () => {
      if (props.screenWidth < 770) {
        return true;
      } else {
        return false;
      }
    };
  
  
    useEffect(() => {
      getTabsData(fromDate, toDate);
    }, []);
  
    const getTabsData = async (fromDate, toDate) => {
      if(fromDate !== null){
      setrowstatus(false)
      const res = GetGridsApi.RatingWdApi(fromDate, toDate);
      const resp = await res;
      console.log(resp.data.length,'ressspppp');
      setRatingWdApi(resp.data);
      if(resp.data.length == 0){
        setrowstatus(true);
      }
      setstartdate(fromDate)
        setenddate(toDate)
        localStorage.removeItem("reportsfirstDate");
        localStorage.removeItem("reportslastDate");
      }
    };

    const updatedata = async ()=>{
      if(startdate != null && enddate != null){
      setUpdate(true);
      const res = GetGridsApi.RatingWdApi(startdate, enddate);
      const resp = await res;
      setRatingWdApi(resp.data);
      if(resp.data.length == 0){
        setUpdate('no rows');
      }
      else{
        setUpdate(false);
      }
      }
    }
  
    const datefilters = (
      startDate,
      endDate,
      gridApi,
      getFilterType
    ) => {
      var dateFilterComponent =
        gridApi.api.getFilterInstance("prevratingsdate");
      dateFilterComponent.setModel({
        type: getFilterType(),
        inRange: true,
        dateFrom: startDate,
        dateTo: endDate,
      });
    };
  
    function fullDate(params) {
      if (params.value === "nullData") return <Skeleton />;
      if (params.value == null) {
        return "-";
      } else if (params.value == "") {
        return "-";
      } else {
        const date = new Date(params.value);
        const yyyy = date.getFullYear();
        const yy = yyyy.toString();
        const y = yy.slice(2, 4);
        let mm = date.toLocaleString("default", { month: "short" });
        let dd = date.getDate();
        if (dd < 10) dd = "0" + dd;
        return dd + "-" + mm + "-" + y;
      }
    }
    const cellrander = (params) => {
      if (params.value === "nullData") return <Skeleton />;
      if (params.value === "" || params.value == null) {
        return "-";
      } else {
        return params.value;
      }
    };
    const cellrandered = (params) => {
      if (params.value === "nullData") {
        return <Skeleton />;
      } else {
        return params.value;
      }
    };
  
    const columnDefs = [
      {
        headerName: "",
        field: "sNo",
        maxWidth: 30,
        filter: true,
        menuTabs: false,
        pinned: responsiveColumnPin(),
        hide: detailShow(),
        cellRenderer: "agGroupCellRenderer",
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        headerName: "#",
        maxWidth: 50,
        // minWidth: 66,
        field: "sNo",
        sortable: true,
        filter: true,
        // filter: "agSetColumnFilter",
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
        },
        suppressFiltersToolPanel: true,
        menuTabs: false,
        cellRenderer: cellrandered,
        pinned: responsiveColumnPin(),
      },
  
      {
        headerName: "Company / Issue Rated",
        minWidth: responsiveColumns(),
        field: "name",
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        cellRenderer: cellrander,
        tooltipField: "name",
        pinned: responsiveColumnPin(),
      },

      {
        headerName: "Last Rating Assigned",
        minWidth: responsiveColumns(),
        hide: columnHide(),
        field: "prevratings",
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        cellRenderer: cellrander,
        tooltipField: "RatingScale",
      },

      {
        headerName: "Rating Date",
        field: "prevratingsdate",
        minWidth: 115,
        // hide: true,
        sortable: true,
        filter: "agDateColumnFilter",
        excelMode: "windows",
        cellRenderer: fullDate,
        debounceMs: "DateFilter",
        filterParams: {
          filterOptions: ["equals", "lessThan", "greaterThan", "inRange", "null"],
          inRangeInclusive: true,
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            var dateAsString = moment(cellValue).format("DD/MM/YYYY");
            var dateParts = dateAsString.split("/");
            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );
  
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }
  
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
  
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
          buttons: ["clear", "reset", "apply"],
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        },
      },

      {
        headerName: "Sector / Industry",
        field: "industry",
        hide: columnHide(),
        minWidth: 130,
        minWidth: 130,
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        tooltipField: "pre_lterm",
        cellRenderer: cellrander,
        // pinned: responsiveColumnPin(),
      },
      {
        headerName: "Reason For Withdrawn",
        field: "",
        minWidth: 130,
        hide: columnHide(), 
        minWidth: 130,
        sortable: true,
        filter: "agSetColumnFilter",
        excelMode: "windows",
        cellRenderer: cellrander,
        // pinned: responsiveColumnPin(),
      },

    ];
    const MobViewRender = (params) => (
      <h1 style={{ padding: "10px 20px" }}>
        <Table className="overflow-scroll responsiveTableFonts">
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Last Rating Assigned
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.prevratings}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Sector / Industry
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {params.data.industry}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              variant="head"
              className="fw-bolder responsiveTableFonts py-1"
            >
              Reason For Withdrawn
            </TableCell>
            <TableCell className="responsiveTableFonts py-1">
              {}
            </TableCell>
          </TableRow>
        </Table>
      </h1>
    );
  
    return (
      <>
                <div>
      <p className="mx-auto p-3 fs-4 fw-bold mb-0 text-capitalize" style={{ width: "fit-content", color:"#000078" }}>
      B-xiii | Detail of Rating Withdrawn
      </p>      
      <div className="row w-100">
        <div
          className="card shadow mx-auto col-lg-10 col-md-10 col-sm-10 col-11 pt-4"
          style={{ height: "auto", backgroundColor: "#e0e0e0" }}
        >
          <div className="row w-100 pb-3">
              <div className="col-lg-5 col-md-5 ms-lg-4 ms-md-2 ms-sm-3 col-sm-11 col-11 ms-2">
              <input
                type="date"
                style={{height: "38px"}}
                defaultValue={fromDate}
                className="form-control"
                onChange={(e) => {
                  setstartdate(e.target.value);
                }}
              />
              </div>
              <div className="col-lg-5 col-md-5 ms-md-1 pt-sm-1 pt-md-0 col-sm-11 ms-sm-3 col-11 ms-2 pt-1">
              <input
                type="date"
                style={{height: "38px"}}
                defaultValue={toDate}
                className="form-control"
                onChange={(e) => {
                  setenddate(e.target.value);
                }}
              />
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 pt-sm-1 pt-md-0 ms-md-1 mx-sm-auto col-1 pt-1 mx-auto">
              <button type="button" className="btn btn-primary h-60" onClick={updatedata} >
              Submit
            </button>
              </div>
          </div>
        </div>
      </div>
    </div> <br />
        <TableComponent
          value={"ratingWdApi"}
          report={true}
          rowstatus = {rowstatus}
          handleRfreshTab={props.handleRfreshTab}
          Data={ratingWdApi}
          screenWidth={props.screenWidth}
          MobViewRender={MobViewRender}
          columnDefs={columnDefs}
          datefilters={datefilters}
          datefilter={true}
          update = {Update}
          // FullPageTable={true}
        />
      </>
    );
}
