import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import white from "../../../Assets/Images/whitebg.jpg";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { url } from "../../../Config";
import RefreshIcon from "@mui/icons-material/Refresh";
import { decryptParams } from '../encrypt_url';
import { useLocation } from 'react-router-dom';
import {encryptParams} from "../encrypt_url";


export default function PPL() {
  const location = useLocation();
  const pathname = location.pathname;
  const encryptedParams = pathname.substring('/Ratings/addTask/PPL/'.length);
  // Decrypt the parameters
  const { client_id, og_rating_id } = decryptParams(encryptedParams);
  const isadmin = localStorage.getItem('admin');
  const Navigate = useNavigate();
  const [data, setdata] = useState({});
  const [ppldata, setppldata] = useState(null);
  const [alredyGenenatedPpl, setalredyGenenatedPpl] = useState([]);
  const [alredyGenenatednl, setalredyGenenatednl] = useState([]);
  const [alredyGenenatedRc, setalredyGenenatedRc] = useState([]);
  const [getPpls, setgetPpls] = useState([]);
  const [lastRC, setlastRC] = useState([]);
  const [alertopen, setalertopen] = useState(false);
  const [alertopen2, setalertopen2] = useState(false);
  const [AlertMessage, setAlertMessage] = useState(null);
  const [alertopen3, setalertopen3] = useState(false);
  const [AlertMessage3, setAlertMessage3] = useState(null);
  const [alertType3, setalertType3] = useState("error");
  const [alertopen4, setalertopen4] = useState(false);
  const [AlertMessage4, setAlertMessage4] = useState(null);
  const [alertType4, setalertType4] = useState("error");
  const [alertopen5, setalertopen5] = useState(false);
  const [AlertMessage5, setAlertMessage5] = useState(null);
  const [alertType5, setalertType5] = useState("error");
  const [alertType, setalertType] = useState("error");
  const id = localStorage.getItem("userID")
  const [financial_file, setfinancial_file] = useState(null);
  const [disclosure_file, setdisclosure_file] = useState(null);
  const [bool, setbool] = useState(false);
  const [instrument, setinstrument] = useState("Select Instrument Type");
  const [amount, setamount] = useState(null);
  const [issuance, setissuance] = useState(null);
  const [tenor, settenor] = useState(null);
  const [redemption, setredemption] = useState(null);
  const [instrumenttype, setinstrumenttype] = useState([]);
  const [opinionsdata, setopinionsdata] = useState(null);
  const [RRandKrd, setRRandKrd] = useState(0);
  const [changeopinion, setchangeopinion] = useState(false);
  const [allppl, setallppl] = useState([]);
  const [pplcount, setpplcount] = useState(null);
  const [opinion, setopinion] = useState([]);
  const processpage = "PPL";
  const [checktask, setchecktask] = useState([]);
  const [reopen, setreopen] = useState(null);


  function DateFormat(params) {
    if (params == null) {
      return "-";
    } else {
      const date = new Date(params);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }

  useEffect(()=>{
    if(id != "19" && id !== "42" && id !== "12"){
      const func = async ()=>{
        const res = await axios.get(`${url}/api/agreedinProcess/${id}`);
        const resp = res.data;
        const arr = [];
        for(let i in resp){
          if(resp[i].user_id == id || resp[i].lead_rc_id == id || resp[i].user_id1 == id){
            arr.push(resp[i]);
          }
        }
        setopinion(arr);
      }
      func();
    }
    else{
      const func = async ()=>{
        const resp2 = await axios.get(
          `${url}/api/myinprocess/${id}`
        );
        const data2 = resp2.data;
        const arr2 = [];
        for(let i in data2){
          if(data2[i].user_id == id || data2[i].lead_rc_id == id || data2[i].user_id1 == id || id == "42" || id == "12"){
              arr2.push(data2[i]);
          }
        } 
        setopinion(arr2);
      }

      func();
    }

  },[]);
  
  
  // const [financial_file, setfinancial_file] = useState(null);


  useEffect(() => {
    const func = async () => {
      const resp = await axios.get(`${url}/api/addTask/FC/${client_id}/${og_rating_id}`);
      const res = resp.data;
      const response = res[0];
      // const res2 = resp2.data;
      if(response.edit_module_tasks !== null){
        let arr = response.edit_module_tasks.split(',');
        setchecktask(arr);

        }
      else{
        setchecktask([]);
      }
      
      setreopen(response.reopen);
      setdata(res[0]);
      const instrumenttype = await axios.get(`${url}/api/instrument`);
      const instrumenttyperesp = instrumenttype.data.data;
      setinstrumenttype(instrumenttyperesp);
      const opiniondata = await axios.get(`${url}/api/getAddDetailsData/${client_id}`);
      const opinion = opiniondata.data.data[0];
      setopinionsdata(opinion);
      if(opinion.instrument_type_id !== null && opinion.instrument_type_id !== 0 && opinion.instrument_type_id !== "0"){
        setinstrument(opinion.instrument_type_id);
      }
      setamount(opinion.instrument_amount);
      setissuance(opinion.instrument_issuedate);
      settenor(opinion.instrument_tenure);
      setredemption(opinion.termination_date);
      
    };
    func();
  }, [changeopinion]);

  useEffect(()=>{
    setppldata(null);
    const func = async ()=>{
      const getpplapi = await axios.get(`${url}/api/ppl_data/${id}/${client_id}/${og_rating_id}`)
      const getppl = getpplapi.data;
      setppldata(getppl);
      setallppl(getppl.allPPl);
      setpplcount(getppl.ppl_count);
      setalredyGenenatedPpl(getppl.alredyGenenatedPpl);
      setalredyGenenatednl(getppl.alredyGenenatednl);
      setalredyGenenatedRc(getppl.alredyGenenatedRc);
      setgetPpls(getppl.getPpls);
      setlastRC(getppl.lastRC);
      const checkRRandKRD = await axios.get(`${url}/api/checkRRandKRD/${client_id}/${og_rating_id}/${getppl.opDetails[0].rating_scale_id}`)
      setRRandKrd(checkRRandKRD.data.RRandKrd);

    }
    func();
  },[bool, changeopinion])

  const uploadfinancials = async (event)=>{
    setalertopen4(false);
    event.preventDefault();
    const fileData = new FormData();
    console.log(financial_file);
    fileData.append("filename", financial_file);
    fileData.append("stage", "Financials");
    fileData.append("recordId", og_rating_id);
    const res = await axios.post(`${url}/api/storepplfiles`,
    fileData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )
  if(res.data.status == true && res.data.message == "Financials added successfully"){
    setalertType4("success");
    setAlertMessage4("Financials Uploaded Successfully");
    setalertopen4(true);
  }
  else{
    setalertType4("error");
    setAlertMessage4(res.data.message);
    setalertopen4(true);
  }

  }

  const uploadDisclosure = async (event)=>{
    event.preventDefault();
    setalertopen5(false);
    const fileData = new FormData();
    fileData.append("filename", disclosure_file);
    fileData.append("stage", "Disclosure");
    fileData.append("recordId", og_rating_id);
    const res = await axios.post(`${url}/api/storepplfiles`,
    fileData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )

  if(res.data.status == true && res.data.message == "Disclosure added successfully"){
    setalertType5("success");
    setAlertMessage5("Disclosure Uploaded Successfully");
    setalertopen5(true);
  }
  else{
    setalertType5("error");
    setAlertMessage5(res.data.message);
    setalertopen5(true);
  }

  }

  const cancelReview = async ()=>{
    const res = await axios.post(`${url}/api/cancelReview`,{
      "recId" : ppldata.lastRecord['id']
    });
    if(res.data.status == true){
      setalertType("success");
      setAlertMessage(res.data.message);
      setalertopen(true);
      setbool(!bool);

    }
    else{
      setalertType("error");
      setAlertMessage("Some Error occure while Processing your request");
      setalertopen(true);
    }
  }

  const addDetail = async ()=>{
    if(instrument == "Select Instrument Type"){
      setinstrument(null);
    }
    const res = await axios.post(`${url}/api/instrumentChangesFormApi`,{
      "opinionId": client_id,
      "instrument": instrument,
      "amount": amount,
      "issuance": issuance,
      "tenor": tenor,
      "redemption":  redemption
    });

    if(res.data.status == true){
        setalertType3("success");
        setAlertMessage3("Successfully Updated");
        setalertopen3(true);
    }
    else{
      setalertType3("error");
      setAlertMessage3("Some Error occure while Processing your request");
      setalertopen3(true);
    }
  }

  const generateReport = async()=>{
    try {
      document.getElementById("gn").hidden = true;
      document.getElementById("generateprogress").hidden = false;
      const res = await axios.get(`${url}/api/generateReport/${client_id}/${og_rating_id}/ppl`);
      if(res.status == 200){
        document.getElementById("gn").hidden = false;
        document.getElementById("generateprogress").hidden = true;
        if(data.rating_scales_title == "Entity" && data.initiation_date >= "2024-10-28"){
          window.open(`https://209.97.168.200/pacrawizpackv3/public/rating-report/${og_rating_id}`, '_blank');
        }
        else{
          window.open(`${url}/api/generateReport/${client_id}/${og_rating_id}/ppl`, '_blank');
        }
      }
    } catch (error) {
      document.getElementById("gn").hidden = false;
      document.getElementById("generateprogress").hidden = true;
      setalertType("error");
      
      if(error.response !== null){
        setAlertMessage(error.response.data.message);
      }
      setalertopen(true);
    }
  }

  const preventKeyboardInput = (e) => {
    e.preventDefault();
  };

  return (
    <div style={{height: "90vh"}}>
      <div
        className="fs-3 fw-bold text-center mt-2"
        style={{ color: "#000078", fontFamily: "Kalinga" }}
      >
        PPL
      </div>
      <div
        className="card m-2 shadow"
        style={{
          backgroundColor: "#e0e0e0",
          backgroundImage: `url(${white})`,
          backgroundSize: "cover",
        }}
      >
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw" }}
                >
                  CRO
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <input
                  className="form-control"
                  disabled
                  type="text"
                  defaultValue={data && data.lead_name}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  Team Lead
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 ms-lg-3 ms-md-3">
                <input
                  className="form-control"
                  disabled
                  type="text"
                  defaultValue={data && data.manager_name}
                >

                </input>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  {" "}
                  Analyst{" "}
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <input
                  className="form-control"
                  disabled
                  type="text"
                  defaultValue={data && data.username}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  {" "}
                  Opinion{" "}
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 ms-lg-3 ms-md-3">
                <select className="form-select" value={`${client_id},${og_rating_id}`} onChange={(e)=>{
                  const val = e.target.value;
                  const arr = val.split(",");
                  const client_id = arr[0];
                  const og_rating_id = arr[1];
                  const encryptedParams = encryptParams({client_id, og_rating_id});
                  Navigate(`/Ratings/addTask/PPL/${encryptedParams}`);
                  setchangeopinion(!changeopinion);
                }}>
                  {
                    opinion.map((value, index)=>{
                      return(
                        <option value={`${value.Id},${value.record_id}`}>{value.Entity}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  {" "}
                  Rating Update Type{" "}
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <input
                  className="form-control"
                  disabled
                  type="text"
                  defaultValue={data && data.ratingUpdateType_title}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  Process
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 ms-lg-3 ms-md-3">
              <select className="form-select" defaultValue={processpage} onChange={(e)=>{
                const encryptedParams = encryptParams({client_id, og_rating_id})
                Navigate(`/Ratings/addTask/${e.target.value}/${encryptedParams}`);
              }}>
                <option value={"Information"}>Information</option>
                <option value={"Assessment"}>Assessment</option>
                <option value={"SiteVisit"}>Site Visit</option>
                <option value={"MM"}>MM</option>
                <option value={"RC"}>RC</option>
                <option value={"PPL"}>PPL</option>
                <option value={"NL"}>NL</option>
                <option value={"RR"}>RR</option>
                <option value={"RED"}>RED</option>
              </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isadmin == 'true' &&
      <div className="text-center">
            <b>Record id:</b> {og_rating_id} <b>Opinion id:</b> {client_id}
      </div>}
      <div className="mx-auto mt-2 mb-2" style={{width: "fit-content"}} onClick={()=>{setbool(!bool);}}>
        <i class="fa fa-refresh fa-2x" aria-hidden="true"></i>
      </div>
      {(reopen == 'Open' && checktask.includes('NL') == true) ?
      <div className="card m-2 shadow p-2">
        {ppldata !== null ? alredyGenenatedRc.length == 0 && <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={true}>
                      <Alert
                        severity={'error'}
                        sx={{ mb: 2 }}
                      >
                        {'Please generate RC to enable RR & KRD'}
                      </Alert>
                    </Collapse>
          </Box> : null}
        {ppldata !== null ? ppldata.financialsFile[0].rc_upload_date == null && <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={true}>
                      <Alert
                        severity={'error'}
                        sx={{ mb: 2 }}
                      >
                        {'RC approval is required for PPL'}
                      </Alert>
                    </Collapse>
          </Box> : null}
          <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                        severity={alertType}
                        action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setalertopen(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        sx={{ mb: 2 }}
                      >
                        {AlertMessage}
                    </Alert>
                </Collapse>
          </Box>
        {ppldata !== null ? <div className="row">
          {
            ppldata !== null ?
            ppldata.lastRecord['pacra_outlook'] == ppldata.secondLastRecord['pacra_outlook'] &&
            ppldata.lastRecord['pacra_five_yr'] == ppldata.secondLastRecord['pacra_five_yr'] &&
            ppldata.lastRecord['pacra_lterm'] == ppldata.secondLastRecord['pacra_lterm'] &&
            (ppldata.lastRecord['pacra_sterm'] == ppldata.secondLastRecord['pacra_sterm'] ||
            ppldata.secondLastRecord['pacra_sterm'] == '5') ?
            
            ppldata.secondLastRW !== "unset" && ppldata.lastRecord.review_cancelled != 1 && ppldata.lastRW == ppldata.secondLastRW ?
            <>
              {
                alredyGenenatedRc.length > 1 ? getPpls.length == 2 ?
                <> { (Number(data.rating_update_type) != 6 && Number(data.rating_update_type) != 4) || (ppldata.review == 0) ? <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blank')}}> Post Appeal PPL </button> </div> : null }</> :
                <>{ (Number(data.rating_update_type) != 6 && Number(data.rating_update_type) != 4) || (ppldata.review == 0) ? <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/closer_internally/${ppldata.lastRecord['client_id']}/${ppldata.lastRecord['id']}`, '_blank')}}> Close Internally </button> </div> : null} </> : null
              }

              {
                alredyGenenatedRc.length <= 1 && <> {(Number(data.rating_update_type) != 6 && Number(data.rating_update_type) != 4) || (ppldata.review == 0) ? <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/closer_internally/${ppldata.lastRecord['client_id']}/${ppldata.lastRecord['id']}`, '_blank')}}> Close Internally </button> </div> : null}</>
              }
              {
                ppldata.lastRecord.reviewId=='' || ppldata.lastRecord.reviewId== null ? <> { (Number(data.rating_update_type) != 6 && Number(data.rating_update_type) != 4) || (ppldata.review == 0) ? <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary " type="button" onClick={()=>{cancelReview()}}> Dissemination </button> </div> : null}</> : null
              }
            </>
            :
            <>
              {
                alredyGenenatedPpl.length == 0 ?
                ppldata.financialsFile[0].rc_upload_date != null ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/generate_ppl/${client_id}/${og_rating_id}`, '_blank')}}> Generate PPL </button> 
                </div> : null : null
              }
              {
                alredyGenenatedPpl.length > 0 ?
                alredyGenenatedPpl[0].second_approve !== 'Approved' ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blank')}}> Edit PPL </button> 
                </div> : 
                alredyGenenatednl.length == 0 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary" type="button" onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blank')}}> PPL </button> 
                </div> :
                <> 
                {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blank')}}> PPL </button> 
                </div> :
                <>
                {
                  pplcount > 1 ?
                  allppl.map((value, index)=>{
                    return(
                      <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " type="button" onClick={()=>{
                        window.open(`/Ratings/pplview/${value.id}`);
                      }} >View PPL ({DateFormat(value.date)})</button>
                     </div>
                    )
                  }) :
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{
                    window.open(`/Ratings/pplview/${alredyGenenatedPpl[0].id}`);
                  }} >View PPL</button>
                </div> 
                }
                </> : null
                }
                </> : null
              }
              {/* {
                alredyGenenatedPpl.length > 0 ?
                alredyGenenatedPpl[0].second_approve == 'Approved' && alredyGenenatednl.length == 0 ?
                <>
                {
                  pplcount > 1 ?
                  allppl.map((value, index)=>{
                    return(
                      <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " type="button" onClick={()=>{
                        window.open(`/Ratings/pplview/${value.id}`);
                      }} >View PPL ({DateFormat(value.date)})</button>
                     </div>
                    )
                  }) :
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{
                    window.open(`/Ratings/pplview/${alredyGenenatedPpl[0].id}`);
                  }} >View PPL 2</button>
                </div> 
                }
                </>
                : null : null
              } */}

              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ?
                ppldata.opDetails[0].rating_scale_id == 19 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(BFR) </button> 
                </div> :
                ppldata.opDetails[0].rating_scale_id == 28 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(SIP) </button> 
                </div> :
                ppldata.opDetails[0].rating_scale_id == 7 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/perf_rank/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(Performance Ranking) </button> 
                </div> :
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/rr_krd/${client_id}/${og_rating_id}/add/${ppldata.financialsFile[0].rating_type_id}`, '_blank')}}> RR & KRD </button> 
                </div> : null :
                alredyGenenatedRc.length > 0 ?
                ppldata.opDetails[0].rating_scale_id == 19 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(BFR) </button> 
                </div> :
                ppldata.opDetails[0].rating_scale_id == 28 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(SIP) </button> 
                </div> :
                ppldata.opDetails[0].rating_scale_id== 7 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/perf_rank/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(Performance Ranking) </button> 
                </div> :
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/rr_krd/${client_id}/${og_rating_id}/add/${ppldata.financialsFile[0].rating_type_id}`, '_blank')}}> RR & KRD </button> 
                </div> : null

              }

              {
                RRandKrd !== 0 && <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " target="_blank" onClick={()=>{
                    window.open(`${url}/api/draftPr/${og_rating_id}`, '_blank')}
                    }> Draft PR </button> 
                </div>
              }
              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ? 
                <>
                {ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 ? <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropF">Upload Financials</button> 
                </div> : null}
                {ppldata.opDetails[0].rating_scale_id== 9 ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropD">Upload Disclosure</button> 
                </div> : null}
                </> : null :
                <>
                {ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 ? <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropF"> Upload Financials </button>
                </div> : null}
                {ppldata.opDetails[0].rating_scale_id== 9 &&
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropD">Upload Disclosure</button>
                </div>}
                </>
              }
              {
                alredyGenenatednl.length > 0 ? 
                alredyGenenatednl[0].second_approve != "Approved" ?
                <> 
                  { ppldata !== null ?
                    ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 && ppldata.opDetails[0].pacra_action !== 5
                    && ppldata.opDetails[0].pacra_action !== 6 && ppldata.opDetails[0].pacra_action !== 11 && ppldata.opDetails[0].pacra_action !== 18 &&
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button type="button" className="btn btn-primary " onClick={()=>{generateReport()}}> <div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button> 
                  </div> : null
                    }
                </>
                 : null :
                 <> 
                  { ppldata !== null ?
                    ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 && ppldata.opDetails[0].pacra_action !== 5
                    && ppldata.opDetails[0].pacra_action !== 6 && ppldata.opDetails[0].pacra_action !== 11 && ppldata.opDetails[0].pacra_action !== 18 &&
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button type="button" className="btn btn-primary " onClick={()=>{generateReport()}}> <div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button>
                    </div> : null
                  }
                 </>
              }
            </> 
            :
            <>
 
              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ?
                <>
              {
                  ppldata.opDetails[0].rating_scale_id == 19 ?
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(BFR) </button> 
                  </div> :
                  ppldata.opDetails[0].rating_scale_id == 28 ?
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(SIP) </button> 
                  </div> :
                ppldata.opDetails[0].rating_scale_id== 7 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/perf_rank/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(Performance Ranking) </button>
                </div> :
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/rr_krd/${client_id}/${og_rating_id}/add/${ppldata.financialsFile[0].rating_type_id}`, '_blank')}}> RR & KRD </button>
                </div>
              }

              { ppldata !== null ? ppldata.financialsFile[0].rc_upload_date != null ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blank')}}> PPL </button> 
                </div> : null : null
              }
                </> : null
                : 
                <>
                  {
                    alredyGenenatedRc.length > 0 ?
                    <>
                    { ppldata !== null ? ppldata.financialsFile[0].rc_upload_date != null ?
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blanl')}}> PPL</button>
                    </div> : null : null
                    }
                      {
                        ppldata.opDetails[0].rating_scale_id == 19 ?
                        <div className="me-1 mb-1" style={{width: "fit-content"}}>
                          <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(BFR) </button>
                        </div>
                         : ppldata.opDetails[0].rating_scale_id== 28 ?
                         <div className="me-1 mb-1" style={{width: "fit-content"}}>
                          <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(SIP) </button>
                        </div>
                         :
                        ppldata.opDetails[0].rating_scale_id== 7 ?
                        <div className="me-1 mb-1" style={{width: "fit-content"}}>
                          <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{window.open(`/Ratings/perf_rank/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(Performance Ranking) </button>
                        </div>
                         :
                        <div className="me-1 mb-1" style={{width: "fit-content"}}>
                          <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{window.open(`/Ratings/rr_krd/${client_id}/${og_rating_id}/add/${ppldata.financialsFile[0].rating_type_id}`, '_blank')}}> RR & KRD </button>
                        </div>
                      }
                    </> : null
                  }
                </>
                
              }
              {
                ppldata !== null ? ppldata.pr !== false ?
                <>
                {RRandKrd !== 0 && 
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{
                    window.open(`${url}/api/draftPr/${og_rating_id}`, '_blank')}
                    }>Draft PR</button>
                </div>}
                </>
                 :
                <>
                {RRandKrd !== 0 &&
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{
                    window.open(`${url}/api/draftPr/${og_rating_id}`, '_blank')}
                    }>Draft PR</button>
                </div>
                }

                {
                  ppldata.ratingType == 4 ? 
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button className="btn btn-primary " style={{width : "100%"}} data-bs-toggle="modal" data-bs-target="#staticBackdropDetail">Add Details</button>
                  </div> : null
                }
                </>
              : null}
              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve == "Approved" ?
                <>
                {
                  pplcount > 1 ?
                  allppl.map((value, index)=>{
                    return(
                      <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " type="button" onClick={()=>{
                        window.open(`/Ratings/pplview/${value.id}`);
                      }} >View PPL ({DateFormat(value.date)})</button>
                     </div>
                    )
                  }) :
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{
                    window.open(`/Ratings/pplview/${alredyGenenatedPpl[0].id}`);
                  }} >View PPL</button>
                </div> 
                }
                </> : null : null
              }
              
              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ?
                <>
                {ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 ? <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" style={{width : "100%"}} data-bs-toggle="modal" data-bs-target="#staticBackdropF">Upload Financials</button>
                </div> : null}
                  {
                    ppldata.opDetails[0].rating_scale_id == 9 ?
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " type="button" style={{width : "100%"}} data-bs-toggle="modal" data-bs-target="#staticBackdropD">Upload Disclosure</button>
                    </div> : null
                  }
                </> : null : 
                <>
                  {ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 ? <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button className="btn btn-primary " type="button" style={{width : "100%"}} data-bs-toggle="modal" data-bs-target="#staticBackdropF">Upload Financials</button>
                  </div> : null}
                  {
                    ppldata.opDetails[0].rating_scale_id == 9 ?
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " style={{width : "100%"}} data-bs-toggle="modal" data-bs-target="#staticBackdropD">Upload Disclosure</button>
                    </div> : null
                  }

                </>
              }

              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ?
                <> 
                  {ppldata !== null ?
                    ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 && ppldata.opDetails[0].pacra_action !== 5
                    && ppldata.opDetails[0].pacra_action !== 6 && ppldata.opDetails[0].pacra_action !== 11 && ppldata.opDetails[0].pacra_action !== 18 &&
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button type="button" className="btn btn-primary "  style={{width : "100%"}} onClick={()=>{generateReport()}}> <div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button>
                    </div> : null
                  }
                </>
                 : null :
                 <> 
                  { ppldata !== null ?
                    ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 && ppldata.opDetails[0].pacra_action !== 5
                    && ppldata.opDetails[0].pacra_action !== 6 && ppldata.opDetails[0].pacra_action !== 11 && ppldata.opDetails[0].pacra_action !== 18 &&
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button type="button" className="btn btn-primary "  style={{width : "100%"}} onClick={()=>{generateReport()}}> <div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button>
                    </div> : null
                  }
                 </>
              }
            </> : null
          }
          
        </div> :
        <div className="mx-auto"> <CircularProgress /> </div>}
      </div> :
        reopen == null ?
      <div className="card m-2 shadow p-2">
        {ppldata !== null ? alredyGenenatedRc.length == 0 && <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={true}>
                      <Alert
                        severity={'error'}
                        sx={{ mb: 2 }}
                      >
                        {'Please generate RC to enable RR & KRD'}
                      </Alert>
                    </Collapse>
          </Box> : null}
        {ppldata !== null ? ppldata.financialsFile[0].rc_upload_date == null && <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={true}>
                      <Alert
                        severity={'error'}
                        sx={{ mb: 2 }}
                      >
                        {'RC approval is required for PPL'}
                      </Alert>
                    </Collapse>
          </Box> : null}
          <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                        severity={alertType}
                        action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setalertopen(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        sx={{ mb: 2 }}
                      >
                        {AlertMessage}
                    </Alert>
                </Collapse>
          </Box>
        {ppldata !== null ? <div className="row">
          {
            ppldata !== null ?
            ppldata.lastRecord['pacra_outlook'] == ppldata.secondLastRecord['pacra_outlook'] &&
            ppldata.lastRecord['pacra_five_yr'] == ppldata.secondLastRecord['pacra_five_yr'] &&
            ppldata.lastRecord['pacra_lterm'] == ppldata.secondLastRecord['pacra_lterm'] &&
            (ppldata.lastRecord['pacra_sterm'] == ppldata.secondLastRecord['pacra_sterm'] ||
            ppldata.secondLastRecord['pacra_sterm'] == '5') ?
            
            ppldata.secondLastRW !== "unset" && ppldata.lastRecord.review_cancelled != 1 && ppldata.lastRW == ppldata.secondLastRW ?
            <>
              {
                alredyGenenatedRc.length > 1 ? getPpls.length == 2 ?
                <> { (Number(data.rating_update_type) != 6 && Number(data.rating_update_type) != 4) || (ppldata.review == 0) ? <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blank')}}> Post Appeal PPL </button> </div> : null }</> :
                <>{ (Number(data.rating_update_type) != 6 && Number(data.rating_update_type) != 4) || (ppldata.review == 0) ? <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/closer_internally/${ppldata.lastRecord['client_id']}/${ppldata.lastRecord['id']}`, '_blank')}}> Close Internally </button> </div> : null} </> : null
              }

              {
                alredyGenenatedRc.length <= 1 && <> {(Number(data.rating_update_type) != 6 && Number(data.rating_update_type) != 4) || (ppldata.review == 0) ? <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/closer_internally/${ppldata.lastRecord['client_id']}/${ppldata.lastRecord['id']}`, '_blank')}}> Close Internally </button> </div> : null}</>
              }
              {
                ppldata.lastRecord.reviewId=='' || ppldata.lastRecord.reviewId== null ? <> { (Number(data.rating_update_type) != 6 && Number(data.rating_update_type) != 4) || (ppldata.review == 0) ? <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary " type="button" onClick={()=>{cancelReview()}}> Dissemination </button> </div> : null}</> : null
              }
            </>
            :
            <>
              {
                alredyGenenatedPpl.length == 0 ?
                ppldata.financialsFile[0].rc_upload_date != null ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/generate_ppl/${client_id}/${og_rating_id}`, '_blank')}}> Generate PPL </button> 
                </div> : null : null
              }
              {
                alredyGenenatedPpl.length > 0 ?
                alredyGenenatedPpl[0].second_approve !== 'Approved' ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blank')}}> Edit PPL </button> 
                </div> : 
                alredyGenenatednl.length == 0 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary" type="button" onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blank')}}> PPL </button> 
                </div> :
                <> 
                {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blank')}}> PPL </button> 
                </div> :
                <>
                {
                  pplcount > 1 ?
                  allppl.map((value, index)=>{
                    return(
                      <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " type="button" onClick={()=>{
                        window.open(`/Ratings/pplview/${value.id}`);
                      }} >View PPL ({DateFormat(value.date)})</button>
                     </div>
                    )
                  }) :
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{
                    window.open(`/Ratings/pplview/${alredyGenenatedPpl[0].id}`);
                  }} >View PPL</button>
                </div> 
                }
                </> : null
                }
                </> : null
              }
              {/* {
                alredyGenenatedPpl.length > 0 ?
                alredyGenenatedPpl[0].second_approve == 'Approved' && alredyGenenatednl.length == 0 ?
                <>
                {
                  pplcount > 1 ?
                  allppl.map((value, index)=>{
                    return(
                      <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " type="button" onClick={()=>{
                        window.open(`/Ratings/pplview/${value.id}`);
                      }} >View PPL ({DateFormat(value.date)})</button>
                     </div>
                    )
                  }) :
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{
                    window.open(`/Ratings/pplview/${alredyGenenatedPpl[0].id}`);
                  }} >View PPL 2</button>
                </div> 
                }
                </>
                : null : null
              } */}

              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ?
                ppldata.opDetails[0].rating_scale_id == 19 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(BFR) </button> 
                </div> :
                ppldata.opDetails[0].rating_scale_id == 28 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(SIP) </button> 
                </div> :
                ppldata.opDetails[0].rating_scale_id == 7 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/perf_rank/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(Performance Ranking) </button> 
                </div> :
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/rr_krd/${client_id}/${og_rating_id}/add/${ppldata.financialsFile[0].rating_type_id}`, '_blank')}}> RR & KRD </button> 
                </div> : null :
                alredyGenenatedRc.length > 0 ?
                ppldata.opDetails[0].rating_scale_id == 19 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(BFR) </button> 
                </div> :
                ppldata.opDetails[0].rating_scale_id == 28 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(SIP) </button> 
                </div> :
                ppldata.opDetails[0].rating_scale_id== 7 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/perf_rank/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(Performance Ranking) </button> 
                </div> :
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/rr_krd/${client_id}/${og_rating_id}/add/${ppldata.financialsFile[0].rating_type_id}`, '_blank')}}> RR & KRD </button> 
                </div> : null

              }

              {
                RRandKrd !== 0 && <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " target="_blank" onClick={()=>{
                    window.open(`${url}/api/draftPr/${og_rating_id}`, '_blank')}
                    }> Draft PR </button> 
                </div>
              }
              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ? 
                <>
                {ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 ? <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropF">Upload Financials</button> 
                </div> : null}
                {ppldata.opDetails[0].rating_scale_id== 9 ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropD">Upload Disclosure</button> 
                </div> : null}
                </> : null :
                <>
                {ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 ? <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropF"> Upload Financials </button>
                </div> : null}
                {ppldata.opDetails[0].rating_scale_id== 9 &&
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropD">Upload Disclosure</button>
                </div>}
                </>
              }
              {
                alredyGenenatednl.length > 0 ? 
                alredyGenenatednl[0].second_approve != "Approved" ?
                <> 
                  { ppldata !== null ?
                    ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 && ppldata.opDetails[0].pacra_action !== 5
                    && ppldata.opDetails[0].pacra_action !== 6 && ppldata.opDetails[0].pacra_action !== 11 && ppldata.opDetails[0].pacra_action !== 18 &&
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button type="button" className="btn btn-primary " onClick={()=>{generateReport()}}> <div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button> 
                  </div> : null
                    }
                </>
                 : null :
                 <> 
                  { ppldata !== null ?
                    ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 && ppldata.opDetails[0].pacra_action !== 5
                    && ppldata.opDetails[0].pacra_action !== 6 && ppldata.opDetails[0].pacra_action !== 11 && ppldata.opDetails[0].pacra_action !== 18 &&
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button type="button" className="btn btn-primary " onClick={()=>{generateReport()}}> <div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button>
                    </div> : null
                  }
                 </>
              }
            </> 
            :
            <>
 
              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ?
                <>
              {
                  ppldata.opDetails[0].rating_scale_id == 19 ?
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(BFR) </button> 
                  </div> :
                  ppldata.opDetails[0].rating_scale_id == 28 ?
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(SIP) </button> 
                  </div> :
                ppldata.opDetails[0].rating_scale_id== 7 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/perf_rank/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(Performance Ranking) </button>
                </div> :
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/rr_krd/${client_id}/${og_rating_id}/add/${ppldata.financialsFile[0].rating_type_id}`, '_blank')}}> RR & KRD </button>
                </div>
              }

              { ppldata !== null ? ppldata.financialsFile[0].rc_upload_date != null ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blank')}}> PPL </button> 
                </div> : null : null
              }
                </> : null
                : 
                <>
                  {
                    alredyGenenatedRc.length > 0 ?
                    <>
                    { ppldata !== null ? ppldata.financialsFile[0].rc_upload_date != null ?
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blanl')}}> PPL</button>
                    </div> : null : null
                    }
                      {
                        ppldata.opDetails[0].rating_scale_id == 19 ?
                        <div className="me-1 mb-1" style={{width: "fit-content"}}>
                          <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(BFR) </button>
                        </div>
                         : ppldata.opDetails[0].rating_scale_id== 28 ?
                         <div className="me-1 mb-1" style={{width: "fit-content"}}>
                          <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(SIP) </button>
                        </div>
                         :
                        ppldata.opDetails[0].rating_scale_id== 7 ?
                        <div className="me-1 mb-1" style={{width: "fit-content"}}>
                          <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{window.open(`/Ratings/perf_rank/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(Performance Ranking) </button>
                        </div>
                         :
                        <div className="me-1 mb-1" style={{width: "fit-content"}}>
                          <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{window.open(`/Ratings/rr_krd/${client_id}/${og_rating_id}/add/${ppldata.financialsFile[0].rating_type_id}`, '_blank')}}> RR & KRD </button>
                        </div>
                      }
                    </> : null
                  }
                </>
                
              }
              {
                ppldata !== null ? ppldata.pr !== false ?
                <>
                {RRandKrd !== 0 && 
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{
                    window.open(`${url}/api/draftPr/${og_rating_id}`, '_blank')}
                    }>Draft PR</button>
                </div>}
                </>
                 :
                <>
                {RRandKrd !== 0 &&
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{
                    window.open(`${url}/api/draftPr/${og_rating_id}`, '_blank')}
                    }>Draft PR</button>
                </div>
                }

                {
                  ppldata.ratingType == 4 ? 
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button className="btn btn-primary " style={{width : "100%"}} data-bs-toggle="modal" data-bs-target="#staticBackdropDetail">Add Details</button>
                  </div> : null
                }
                </>
              : null}
              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve == "Approved" ?
                <>
                {
                  pplcount > 1 ?
                  allppl.map((value, index)=>{
                    return(
                      <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " type="button" onClick={()=>{
                        window.open(`/Ratings/pplview/${value.id}`);
                      }} >View PPL ({DateFormat(value.date)})</button>
                     </div>
                    )
                  }) :
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{
                    window.open(`/Ratings/pplview/${alredyGenenatedPpl[0].id}`);
                  }} >View PPL</button>
                </div> 
                }
                </> : null : null
              }
              
              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ?
                <>
                {ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 ? <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" style={{width : "100%"}} data-bs-toggle="modal" data-bs-target="#staticBackdropF">Upload Financials</button>
                </div> : null}
                  {
                    ppldata.opDetails[0].rating_scale_id == 9 ?
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " type="button" style={{width : "100%"}} data-bs-toggle="modal" data-bs-target="#staticBackdropD">Upload Disclosure</button>
                    </div> : null
                  }
                </> : null : 
                <>
                  {ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 ? <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button className="btn btn-primary " type="button" style={{width : "100%"}} data-bs-toggle="modal" data-bs-target="#staticBackdropF">Upload Financials</button>
                  </div> : null}
                  {
                    ppldata.opDetails[0].rating_scale_id == 9 ?
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " style={{width : "100%"}} data-bs-toggle="modal" data-bs-target="#staticBackdropD">Upload Disclosure</button>
                    </div> : null
                  }

                </>
              }

              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ?
                <> 
                  {ppldata !== null ?
                    ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 && ppldata.opDetails[0].pacra_action !== 5
                    && ppldata.opDetails[0].pacra_action !== 6 && ppldata.opDetails[0].pacra_action !== 11 && ppldata.opDetails[0].pacra_action !== 18 &&
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button type="button" className="btn btn-primary "  style={{width : "100%"}} onClick={()=>{generateReport()}}> <div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button>
                    </div> : null
                  }
                </>
                 : null :
                 <> 
                  { ppldata !== null ?
                    ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 && ppldata.opDetails[0].pacra_action !== 5
                    && ppldata.opDetails[0].pacra_action !== 6 && ppldata.opDetails[0].pacra_action !== 11 && ppldata.opDetails[0].pacra_action !== 18 &&
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button type="button" className="btn btn-primary "  style={{width : "100%"}} onClick={()=>{generateReport()}}> <div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button>
                    </div> : null
                  }
                 </>
              }
            </> : null
          }
          
        </div> :
        <div className="mx-auto"> <CircularProgress /> </div>}
      </div> :
      (reopen == 'Open' && checktask.includes('NL') == false) ?
      <div className="mt-3 fw-4 text-center"> Not allowed to Edit after File Closure</div> :
      <div className="card m-2 shadow p-2">
        {ppldata !== null ? alredyGenenatedRc.length == 0 && <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={true}>
                      <Alert
                        severity={'error'}
                        sx={{ mb: 2 }}
                      >
                        {'Please generate RC to enable RR & KRD'}
                      </Alert>
                    </Collapse>
          </Box> : null}
        {ppldata !== null ? ppldata.financialsFile[0].rc_upload_date == null && <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={true}>
                      <Alert
                        severity={'error'}
                        sx={{ mb: 2 }}
                      >
                        {'RC approval is required for PPL'}
                      </Alert>
                    </Collapse>
          </Box> : null}
          <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                        severity={alertType}
                        action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setalertopen(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        sx={{ mb: 2 }}
                      >
                        {AlertMessage}
                    </Alert>
                </Collapse>
          </Box>
        {ppldata !== null ? <div className="row">
          {
            ppldata !== null ?
            ppldata.lastRecord['pacra_outlook'] == ppldata.secondLastRecord['pacra_outlook'] &&
            ppldata.lastRecord['pacra_five_yr'] == ppldata.secondLastRecord['pacra_five_yr'] &&
            ppldata.lastRecord['pacra_lterm'] == ppldata.secondLastRecord['pacra_lterm'] &&
            (ppldata.lastRecord['pacra_sterm'] == ppldata.secondLastRecord['pacra_sterm'] ||
            ppldata.secondLastRecord['pacra_sterm'] == '5') ?
            
            ppldata.secondLastRW !== "unset" && ppldata.lastRecord.review_cancelled != 1 && ppldata.lastRW == ppldata.secondLastRW ?
            <>
              {
                alredyGenenatedRc.length > 1 ? getPpls.length == 2 ?
                <> { (Number(data.rating_update_type) != 6 && Number(data.rating_update_type) != 4) || (ppldata.review == 0) ? <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blank')}}> Post Appeal PPL </button> </div> : null }</> :
                <>{ (Number(data.rating_update_type) != 6 && Number(data.rating_update_type) != 4) || (ppldata.review == 0) ? <div className="me-1 mb-1" style={{width: "fit-content"}}><button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/closer_internally/${ppldata.lastRecord['client_id']}/${ppldata.lastRecord['id']}`, '_blank')}}> Close Internally </button> </div> : null} </> : null
              }

              {
                alredyGenenatedRc.length <= 1 && <> {(Number(data.rating_update_type) != 6 && Number(data.rating_update_type) != 4) || (ppldata.review == 0) ? <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/closer_internally/${ppldata.lastRecord['client_id']}/${ppldata.lastRecord['id']}`, '_blank')}}> Close Internally </button> </div> : null}</>
              }
              {
                ppldata.lastRecord.reviewId=='' || ppldata.lastRecord.reviewId== null ? <> { (Number(data.rating_update_type) != 6 && Number(data.rating_update_type) != 4) || (ppldata.review == 0) ? <div className="me-1 mb-1" style={{width: "fit-content"}}> <button className="btn btn-primary " type="button" onClick={()=>{cancelReview()}}> Dissemination </button> </div> : null}</> : null
              }
            </>
            :
            <>
              {
                alredyGenenatedPpl.length == 0 ?
                ppldata.financialsFile[0].rc_upload_date != null ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/generate_ppl/${client_id}/${og_rating_id}`, '_blank')}}> Generate PPL </button> 
                </div> : null : null
              }
              {
                alredyGenenatedPpl.length > 0 ?
                alredyGenenatedPpl[0].second_approve !== 'Approved' ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blank')}}> Edit PPL </button> 
                </div> : 
                alredyGenenatednl.length == 0 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary" type="button" onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blank')}}> PPL </button> 
                </div> :
                <> 
                {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blank')}}> PPL </button> 
                </div> :
                <>
                {
                  pplcount > 1 ?
                  allppl.map((value, index)=>{
                    return(
                      <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " type="button" onClick={()=>{
                        window.open(`/Ratings/pplview/${value.id}`);
                      }} >View PPL ({DateFormat(value.date)})</button>
                     </div>
                    )
                  }) :
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{
                    window.open(`/Ratings/pplview/${alredyGenenatedPpl[0].id}`);
                  }} >View PPL</button>
                </div> 
                }
                </> : null
                }
                </> : null
              }
              {/* {
                alredyGenenatedPpl.length > 0 ?
                alredyGenenatedPpl[0].second_approve == 'Approved' && alredyGenenatednl.length == 0 ?
                <>
                {
                  pplcount > 1 ?
                  allppl.map((value, index)=>{
                    return(
                      <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " type="button" onClick={()=>{
                        window.open(`/Ratings/pplview/${value.id}`);
                      }} >View PPL ({DateFormat(value.date)})</button>
                     </div>
                    )
                  }) :
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{
                    window.open(`/Ratings/pplview/${alredyGenenatedPpl[0].id}`);
                  }} >View PPL 2</button>
                </div> 
                }
                </>
                : null : null
              } */}

              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ?
                ppldata.opDetails[0].rating_scale_id == 19 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(BFR) </button> 
                </div> :
                ppldata.opDetails[0].rating_scale_id == 28 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(SIP) </button> 
                </div> :
                ppldata.opDetails[0].rating_scale_id == 7 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/perf_rank/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(Performance Ranking) </button> 
                </div> :
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/rr_krd/${client_id}/${og_rating_id}/add/${ppldata.financialsFile[0].rating_type_id}`, '_blank')}}> RR & KRD </button> 
                </div> : null :
                alredyGenenatedRc.length > 0 ?
                ppldata.opDetails[0].rating_scale_id == 19 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(BFR) </button> 
                </div> :
                ppldata.opDetails[0].rating_scale_id == 28 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(SIP) </button> 
                </div> :
                ppldata.opDetails[0].rating_scale_id== 7 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/perf_rank/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(Performance Ranking) </button> 
                </div> :
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/rr_krd/${client_id}/${og_rating_id}/add/${ppldata.financialsFile[0].rating_type_id}`, '_blank')}}> RR & KRD </button> 
                </div> : null

              }

              {
                RRandKrd !== 0 && <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " target="_blank" onClick={()=>{
                    window.open(`${url}/api/draftPr/${og_rating_id}`, '_blank')}
                    }> Draft PR </button> 
                </div>
              }
              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ? 
                <>
                {ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 ? <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropF">Upload Financials</button> 
                </div> : null}
                {ppldata.opDetails[0].rating_scale_id== 9 ? 
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropD">Upload Disclosure</button> 
                </div> : null}
                </> : null :
                <>
                {ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 ? <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropF"> Upload Financials </button>
                </div> : null}
                {ppldata.opDetails[0].rating_scale_id== 9 &&
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" data-bs-toggle="modal" data-bs-target="#staticBackdropD">Upload Disclosure</button>
                </div>}
                </>
              }
              {
                alredyGenenatednl.length > 0 ? 
                alredyGenenatednl[0].second_approve != "Approved" ?
                <> 
                  { ppldata !== null ?
                    ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 && ppldata.opDetails[0].pacra_action !== 5
                    && ppldata.opDetails[0].pacra_action !== 6 && ppldata.opDetails[0].pacra_action !== 11 && ppldata.opDetails[0].pacra_action !== 18 &&
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button type="button" className="btn btn-primary " onClick={()=>{generateReport()}}> <div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button> 
                  </div> : null
                    }
                </>
                 : null :
                 <> 
                  { ppldata !== null ?
                    ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 && ppldata.opDetails[0].pacra_action !== 5
                    && ppldata.opDetails[0].pacra_action !== 6 && ppldata.opDetails[0].pacra_action !== 11 && ppldata.opDetails[0].pacra_action !== 18 &&
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button type="button" className="btn btn-primary " onClick={()=>{generateReport()}}> <div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button>
                    </div> : null
                  }
                 </>
              }
            </> 
            :
            <>
 
              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ?
                <>
              {
                  ppldata.opDetails[0].rating_scale_id == 19 ?
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(BFR) </button> 
                  </div> :
                  ppldata.opDetails[0].rating_scale_id == 28 ?
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(SIP) </button> 
                  </div> :
                ppldata.opDetails[0].rating_scale_id== 7 ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/perf_rank/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(Performance Ranking) </button>
                </div> :
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/rr_krd/${client_id}/${og_rating_id}/add/${ppldata.financialsFile[0].rating_type_id}`, '_blank')}}> RR & KRD </button>
                </div>
              }

              { ppldata !== null ? ppldata.financialsFile[0].rc_upload_date != null ?
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blank')}}> PPL </button> 
                </div> : null : null
              }
                </> : null
                : 
                <>
                  {
                    alredyGenenatedRc.length > 0 ?
                    <>
                    { ppldata !== null ? ppldata.financialsFile[0].rc_upload_date != null ?
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{window.open(`/Ratings/edit_ppl/${client_id}/${og_rating_id}`, '_blanl')}}> PPL</button>
                    </div> : null : null
                    }
                      {
                        ppldata.opDetails[0].rating_scale_id == 19 ?
                        <div className="me-1 mb-1" style={{width: "fit-content"}}>
                          <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(BFR) </button>
                        </div>
                         : ppldata.opDetails[0].rating_scale_id== 28 ?
                         <div className="me-1 mb-1" style={{width: "fit-content"}}>
                          <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{window.open(`/Ratings/bfr/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(SIP) </button>
                        </div>
                         :
                        ppldata.opDetails[0].rating_scale_id== 7 ?
                        <div className="me-1 mb-1" style={{width: "fit-content"}}>
                          <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{window.open(`/Ratings/perf_rank/${client_id}/${og_rating_id}`, '_blank')}}> RR & KRD(Performance Ranking) </button>
                        </div>
                         :
                        <div className="me-1 mb-1" style={{width: "fit-content"}}>
                          <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{window.open(`/Ratings/rr_krd/${client_id}/${og_rating_id}/add/${ppldata.financialsFile[0].rating_type_id}`, '_blank')}}> RR & KRD </button>
                        </div>
                      }
                    </> : null
                  }
                </>
                
              }
              {
                ppldata !== null ? ppldata.pr !== false ?
                <>
                {RRandKrd !== 0 && 
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{
                    window.open(`${url}/api/draftPr/${og_rating_id}`, '_blank')}
                    }>Draft PR</button>
                </div>}
                </>
                 :
                <>
                {RRandKrd !== 0 &&
                <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" style={{width : "100%"}} onClick={()=>{
                    window.open(`${url}/api/draftPr/${og_rating_id}`, '_blank')}
                    }>Draft PR</button>
                </div>
                }

                {
                  ppldata.ratingType == 4 ? 
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button className="btn btn-primary " style={{width : "100%"}} data-bs-toggle="modal" data-bs-target="#staticBackdropDetail">Add Details</button>
                  </div> : null
                }
                </>
              : null}
              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve == "Approved" ?
                <>
                {
                  pplcount > 1 ?
                  allppl.map((value, index)=>{
                    return(
                      <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " type="button" onClick={()=>{
                        window.open(`/Ratings/pplview/${value.id}`);
                      }} >View PPL ({DateFormat(value.date)})</button>
                     </div>
                    )
                  }) :
                  <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" onClick={()=>{
                    window.open(`/Ratings/pplview/${alredyGenenatedPpl[0].id}`);
                  }} >View PPL</button>
                </div> 
                }
                </> : null : null
              }
              
              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ?
                <>
                {ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 ? <div className="me-1 mb-1" style={{width: "fit-content"}}>
                  <button className="btn btn-primary " type="button" style={{width : "100%"}} data-bs-toggle="modal" data-bs-target="#staticBackdropF">Upload Financials</button>
                </div> : null}
                  {
                    ppldata.opDetails[0].rating_scale_id == 9 ?
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " type="button" style={{width : "100%"}} data-bs-toggle="modal" data-bs-target="#staticBackdropD">Upload Disclosure</button>
                    </div> : null
                  }
                </> : null : 
                <>
                  {ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 ? <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button className="btn btn-primary " type="button" style={{width : "100%"}} data-bs-toggle="modal" data-bs-target="#staticBackdropF">Upload Financials</button>
                  </div> : null}
                  {
                    ppldata.opDetails[0].rating_scale_id == 9 ?
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button className="btn btn-primary " style={{width : "100%"}} data-bs-toggle="modal" data-bs-target="#staticBackdropD">Upload Disclosure</button>
                    </div> : null
                  }

                </>
              }

              {
                alredyGenenatednl.length > 0 ?
                alredyGenenatednl[0].second_approve != "Approved" ?
                <> 
                  {ppldata !== null ?
                    ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 && ppldata.opDetails[0].pacra_action !== 5
                    && ppldata.opDetails[0].pacra_action !== 6 && ppldata.opDetails[0].pacra_action !== 11 && ppldata.opDetails[0].pacra_action !== 18 &&
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                      <button type="button" className="btn btn-primary "  style={{width : "100%"}} onClick={()=>{generateReport()}}> <div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button>
                    </div> : null
                  }
                </>
                 : null :
                 <> 
                  { ppldata !== null ?
                    ppldata.opDetails[0].rating_scale_id !== 6 && ppldata.opDetails[0].rating_scale_id !== 7 && ppldata.opDetails[0].pacra_action !== 5
                    && ppldata.opDetails[0].pacra_action !== 6 && ppldata.opDetails[0].pacra_action !== 11 && ppldata.opDetails[0].pacra_action !== 18 &&
                    <div className="me-1 mb-1" style={{width: "fit-content"}}>
                    <button type="button" className="btn btn-primary "  style={{width : "100%"}} onClick={()=>{generateReport()}}> <div id="gn">Generate Report</div> <div id="generateprogress" hidden>Please Wait...</div></button>
                    </div> : null
                  }
                 </>
              }
            </> : null
          }
          
        </div> :
        <div className="mx-auto"> <CircularProgress /> </div>}
      </div>
      }

      <div className="modal fade mt-5" id="staticBackdropF" tabIndex="-1" aria-labelledby="staticBackdrop" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Upload Financial</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                    <Collapse in={alertopen4}>
                        <Alert
                            severity={alertType4}
                            action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setalertopen4(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            sx={{ mb: 2 }}
                          >
                            {AlertMessage4}
                        </Alert>
                    </Collapse>
                </Box>
              <div>
                {(data.rating_scales_title == "Entity" && data.initiation_date >= "2024-10-28") ? "Uploaded File must be in Excel format" : null}
              </div>
              <br/>
              <form onSubmit={uploadfinancials}>
                <div className="col-10 mx-auto">
                  <input className="form-control" type="file" onChange={(e)=>{
                    const file = e.target.files[0];
                    if(data.rating_scales_title == "Entity" && data.initiation_date >= "2024-10-28"){
                      if (file && (file.type === "application/vnd.ms-excel" || 
                        file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
                        setfinancial_file(file);
                      } else {
                        e.target.value = null;
                        setfinancial_file(null);
                        setalertType4("error");
                        setAlertMessage4("Please select file in Excel format.")
                        setalertopen4(true);
                      }
                    }
                    else{
                      if (file && file.type === "application/pdf" && file.size <= 2048 * 1024) {
                        setfinancial_file(file);
                      } else {
                        e.target.value = null;
                        setfinancial_file(null);
                        setalertType4("error");
                        setAlertMessage4("Please select a PDF file with a maximum size of 2 MB.")
                        setalertopen4(true);
                      }
                    }

                  }} required />
                </div>
                <div className="mt-3 mx-auto" style={{width: "fit-content"}}>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div> 
      </div>
      <div className="modal fade mt-5" id="staticBackdropD" tabIndex="-1" aria-labelledby="staticBackdrop" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabelD">Upload Disclosure</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                    <Collapse in={alertopen5}>
                        <Alert
                            severity={alertType5}
                            action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setalertopen5(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            sx={{ mb: 2 }}
                          >
                            {AlertMessage5}
                        </Alert>
                    </Collapse>
              </Box>
              <form onSubmit={uploadDisclosure}>
                <div className="col-10 mx-auto">
                  <input className="form-control" type="file" onChange={(e)=>{
                    const file = e.target.files[0];
                    if (file && file.type === "application/pdf" && file.size <= 2048 * 1024) {
                      setdisclosure_file(file);
                    } else {
                      e.target.value = null;
                      setdisclosure_file(null);
                      setalertType5("error");
                      setAlertMessage5("Please select a PDF file with a maximum size of 2 MB.")
                      setalertopen5(true);
                    }
                  }} required />
                </div>
                <div className="mt-3 mx-auto" style={{width: "fit-content"}}>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade mt-5" id="staticBackdropDetail" tabIndex="-1" aria-labelledby="staticBackdrop" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabelD">Enter Details of: {ppldata !== null ? ppldata.opDetails.length > 0 ? ppldata.opDetails[0].name : null : null}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body" style={{height : "420px", overflow: "scroll"}}>
                <Box className="mx-auto mb-1" sx={{ width: "98%" }}>
                    <Collapse in={alertopen3}>
                        <Alert
                            severity={alertType3}
                            action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setalertopen3(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            sx={{ mb: 2 }}
                          >
                            {AlertMessage3}
                        </Alert>
                    </Collapse>
              </Box>
                <div className="col-10 mx-auto">
                  <div>
                    <label className="fw-bold fs-6">Instrument</label>
                    <div>
                      <select className="form-select" value={instrument} onChange={(e)=>{setinstrument(e.target.value)}}>
                          <option value={'Select Instrument Type'}>Select Instrument Type</option>
                        {instrumenttype.map((value, index)=>{
                          return(
                            <option value={value.id}>{value.title}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="mt-1">
                    <label className="fw-bold fs-6">Amount: (mln)</label>
                    <div>
                      <input className="form-control" type="number" value={amount} onChange={(e)=>{setamount(e.target.value)}}></input>
                    </div>
                  </div>
                  <div className="mt-1">
                    <label className="fw-bold fs-6">Issuance Date:</label>
                    <div>
                      <input className="form-control" type="date"
                        onKeyDown={preventKeyboardInput}  // Prevents typing via keyboard
                        onPaste={preventKeyboardInput}    // Prevents pasting into the input                       
                       value={issuance}
                        onChange={(e)=>{setissuance(e.target.value)}}></input>
                    </div>
                  </div>
                  <div className="mt-1">
                    <label className="fw-bold fs-6">Tenor: (years):</label>
                    <div>
                      <input className="form-control" type="number" value={tenor} onChange={(e)=>{settenor(e.target.value)}}></input>
                    </div>
                  </div>
                  <div className="mt-1">
                    <label className="fw-bold fs-6">Redemption Date:</label>
                    <div>
                      <input className="form-control" type="date" value={redemption}
                      onKeyDown={preventKeyboardInput}  // Prevents typing via keyboard
                      onPaste={preventKeyboardInput}    // Prevents pasting into the input                      
                       onChange={(e)=>{setredemption(e.target.value)}}></input>
                    </div>
                  </div>
                </div>
                <div className="mt-3 mx-auto" style={{width: "fit-content"}}>
                  <button type="submit" className="btn btn-primary" onClick={()=>{addDetail()}}>Submit</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
