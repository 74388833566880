import React, { useEffect, useState } from "react";
import Card from "./Card";
// import { List, arrayMove } from "react-movable";
import Draggable, { DraggableCore } from "react-draggable"; // Both at the same time
import { ControlCamera } from "@material-ui/icons";
import SadFace from "../../Atoms/SadFace";
import { Check, Clear, Event } from "@material-ui/icons";


function Home(props) {
  const eventLogger = (e, data) => {
    console.log("Event: ", e);
    console.log("Data: ", data);
  };

  const [opinionsData, setopinionsData] = useState([]);
  const [status, setstatus] = useState('loading');
  const isadmin = localStorage.getItem('admin');

  useEffect(() => {
    setopinionsData(props.opinionsData);
    setstatus(props.status)
  }, [props.opinionsData]);

  function DateFormat(params) {
    if (params == null) {
      return "-";
    } else {
      const date = new Date(params);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }

  return (
    <div>
       <div className='text-center mt-2 fs-3 fw-bold' style={{color: "#000078" }}>
            {props.title}
        </div>
        {isadmin == 'true' &&
          <div className="text-center">
                <b>Opinion id:</b> {props.client_id}
          </div>}
        <div className='text-center mt-2 fs-6 fw-bold' style={{color: "#000078" }}>
          (<a href="#" data-bs-toggle="modal" data-bs-target={'#mandate'}>Rating Mandate</a>)
        </div>
        <div className="modal fade" id={'mandate'} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Mandate Files</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                      <table className="table table-striped table-hover table-bordered">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Rating Mandate</th>
                                <th>Fee Mandate</th>
                              </tr>
                            </thead>
                            <tbody>
                            {props.mandate.map((value, index)=>{
                              return(
                                <tr>
                                  <td>{DateFormat(value.effectiveDate)}</td>
                                  <td>
                                  {value.rating_mandate == 'uploads/mandates/' || value.rating_mandate == 'uploads/mandates/ ' ||value.rating_mandate == 'uploads/mandates' || value.rating_mandate == null ? <Clear className="theme_text" /> :
                                  
                                  value.rating_mandate.includes('http') == true ?
                                   <a
                                    href={value.rating_mandate}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Check style={{ size: "20 20" }} className="theme_text" />
                                  </a>

                                  :
                                    <a
                                    href={`https://209.97.168.200/pacrawizpackv3/public/view/storage/BD/ratingmandate/${value.rating_mandate}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Check style={{ size: "20 20" }} className="theme_text" />
                                  </a>}
                                  </td>
                                  <td>
                                  {(value.fee_mandate == 'uploads/fee/' || value.fee_mandate == null) ? <Clear className="theme_text" /> : 
                                   value.fee_mandate.includes('http') == true ?
                                   <a
                                    href={value.fee_mandate}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Check style={{ size: "20 20" }} className="theme_text" />
                                  </a>
                                   :
                                    <a
                                    href={`https://209.97.168.200/pacrawizpackv3/public/view/storage/BD/feemandate/${value.fee_mandate}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Check style={{ size: "20 20" }} className="theme_text" />
                                  </a>}
                                  </td>
                                </tr>
                                )
                              })}

                            </tbody>
                      </table>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" id={`close`} data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
        </div>
      <div
      className="col-md-10 mx-auto"
      style={{ height: `calc(100vh - 150px)`, overflow: "scroll" }}
    >
      <div className="row h-100">
        {opinionsData ? (
          (opinionsData.length > 0 && status == 'loaded') ?
          opinionsData.map((each) => {
            return (
              <Draggable
                // axis="yx"
                handle=".handle"
                // defaultPosition={{ x: 0, y: 0 }}
                // position={null}
                // grid={[50, 50]}
                // scale={1}
                // onStart={handleStart}
                // onDrag={handleDrag}
                // onStop={handleStop}
              >
                <div className="col-md-6 p-1">
                  <div className="handle">
                    <ControlCamera />
                  </div>
                  <Card each={each} />
                </div>
              </Draggable>
            );
          }) :
          status == 'loading' ?
          (
            <div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
            <div class="wrapper" id="loader2">
              <svg
                class="hourglass"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 120 206"
                preserveAspectRatio="none"
              >
                <path
                  class="middle"
                  d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
                />
                <path
                  class="outer"
                  d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
                />
              </svg>
            </div>
          </div>)
          : (status == 'loaded' && opinionsData.length == 0) ?
          (<div className='text-center mt-5 fs-5 fw-bold' style={{color: "#000078" }}>No Record to Show</div>)
          : null

        ) : (
          <div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
          <div class="wrapper" id="loader2">
            <svg
              class="hourglass"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 120 206"
              preserveAspectRatio="none"
            >
              <path
                class="middle"
                d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
              />
              <path
                class="outer"
                d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
              />
            </svg>
          </div>
          </div>
        )}
      </div>
    </div>
    </div>

  );
}

export default Home;

{
  /* <List
values={items}
onChange={({ oldIndex, newIndex }) =>
setItems(arrayMove(items, oldIndex, newIndex))
}
  renderList={({ children, props }) => <div className='row h-100' {...props}>{children}</div>}
  renderItem={({ value, props }) => <div className='col-md-6 p-1' {...props}>{value}</div>}
/> */
}

// const Item1 = <Card />
// const Item2 = <Card />
// const Item3 = <Card />
// const Item4 = <Card />
// const Item5 = <Card />
// const Item6 = <Card />
// const Item7 = <Card />
// const Item8 = <Card />
// const [items, setItems] = useState([Item1, Item2, Item3, Item4, Item5, Item6, Item7, Item8]);
