import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import GetGridsApi from "../../../API/GetGridsApi";
import TableComponent from "../../Atoms/TableComponent";

function RatingmoventIGF(props) {
  const [ratingmoventIGF, setratingmoventIGF] = useState([]);
  const [rowstatus, setrowstatus] = useState();
  const [Update, setUpdate] = useState();
  const [startdate, setstartdate] = useState();
  const [enddate, setenddate] = useState();
  const [fromDate, setfromDate] = useState(
    localStorage.getItem("reportsfirstDate")
  );
  const [toDate, settoDate] = useState(localStorage.getItem("reportslastDate"));

  const responsiveColumns = () => {
    if (props.screenWidth < 770) {
      return null;
    } else {
      return 120;
    }
  };
  const responsiveColumnPin = () => {
    if (props.screenWidth < 770) {
      return null;
    } else {
      return "left";
    }
  };
  const detailShow = () => {
    if (props.screenWidth < 770) {
      return false;
    } else {
      return true;
    }
  };

  const columnHide = () => {
    if (props.screenWidth < 770) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getTabsData(fromDate, toDate);
  }, []);

  function innerDesceminationDate(params) {
    if (params.data.dissemination_date == null) {
      return "-";
    } else {
      const date = new Date(params.data.dissemination_date);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }

  const getTabsData = async (fromDate, toDate) => {
    if (fromDate !== null) {
      setrowstatus(false)
      const res = GetGridsApi.ratingmoventIGFApi(fromDate, toDate);
      const resp = await res;
      if (
        resp.data ==
        'The exception message is :Undefined offset: 0{"status":false,"message":"Server error. Please try again later......."}'
      ) {
        setratingmoventIGF([]);
        setrowstatus(true);
        setstartdate(fromDate);
        setenddate(toDate);
        localStorage.removeItem("reportsfirstDate");
        localStorage.removeItem("reportslastDate");
      } else {
        setratingmoventIGF(resp.data);
        if(resp.data.length == 0){
          setrowstatus(true);
        }
        setstartdate(fromDate);
        setenddate(toDate);
        localStorage.removeItem("reportsfirstDate");
        localStorage.removeItem("reportslastDate");
      }
    }
  };

  const updatedata = async () => {
    if(startdate != null && enddate != null){
    setUpdate(true);
    const res = GetGridsApi.ratingmoventIGFApi(startdate, enddate);
    const resp = await res;
    if (
      resp.data ==
      'The exception message is :Undefined offset: 0{"status":false,"message":"Server error. Please try again later......."}'
    ) {
      setratingmoventIGF([]);
      setUpdate('no rows');
    } else {
      setratingmoventIGF(resp.data);
      if(resp.data.length == 0){
        setUpdate('no rows');
      }
      else{
        setUpdate(false);
      }
    }
    }
  };

  const datefilters = (
    startDate,
    endDate,
    gridApi,
    getFilterType
  ) => {
    var dateFilterComponent =
      gridApi.api.getFilterInstance("dissemination_date");
    dateFilterComponent.setModel({
      type: getFilterType(),
      inRange: true,
      dateFrom: startDate,
      dateTo: endDate,
    });
  };

  function fullDate(params) {
    if (params.value === "nullData") return <Skeleton />;
    if (params.value == null) {
      return "-";
    } else if (params.value == "") {
      return "-";
    } else {
      const date = new Date(params.value);
      const yyyy = date.getFullYear();
      const yy = yyyy.toString();
      const y = yy.slice(2, 4);
      let mm = date.toLocaleString("default", { month: "short" });
      let dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      return dd + "-" + mm + "-" + y;
    }
  }

  const cellrander = (params) => {
    if (params.value === "nullData") return <Skeleton />;
    if (params.value === "" || params.value == null) {
      return "-";
    } else {
      return params.value;
    }
  };

  const cellrandered = (params) => {
    if (params.value === "nullData") {
      return <Skeleton />;
    } else {
      return params.value;
    }
  };

  const columnDefs = [
    {
      headerName: "",
      field: "sNo",
      maxWidth: 30,
      filter: true,
      menuTabs: false,
      pinned: responsiveColumnPin(),
      hide: detailShow(),
      cellRenderer: "agGroupCellRenderer",
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      headerName: "#",
      maxWidth: 50,
      // minWidth: 66,
      field: "sNo",
      sortable: true,
      filter: true,
      // filter: "agSetColumnFilter",
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          "  </div>" +
          "</div>",
      },
      suppressFiltersToolPanel: true,
      menuTabs: false,
      cellRenderer: cellrandered,
      pinned: responsiveColumnPin(),
    },

    {
      headerName: "Opinion",
      minWidth: responsiveColumns(),
      field: "name",
      sortable: true,
      filter: "agSetColumnFilter",
      excelMode: "windows",
      cellRenderer: cellrander,
      tooltipField: "title",
      pinned: responsiveColumnPin(),
    },

    {
      headerName: "Notification Date",
      field: "dissemination_date",
      minWidth: 115,
      // hide: true,
      sortable: true,
      filter: "agDateColumnFilter",
      excelMode: "windows",
      cellRenderer: fullDate,
      debounceMs: "DateFilter",
      filterParams: {
        filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
        inRangeInclusive: true,
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateAsString = moment(cellValue).format("DD/MM/YYYY");
          var dateParts = dateAsString.split("/");
          var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );

          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          }

          if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
        },
        buttons: ["clear", "reset", "apply"],
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
        },
      },
    },
    {
      headerName: "Pre Rev LT",
      field: "pre_lterm",
      minWidth: 130,
      minWidth: 130,
      sortable: true,
      filter: "agSetColumnFilter",
      hide: columnHide(),
      excelMode: "windows",
      tooltipField: "main",
      cellRenderer: cellrander,
      // pinned: responsiveColumnPin(),
    },
    {
      headerName: "Post Rev LT",
      field: "lterm",
      minWidth: 130,
      minWidth: 130,
      sortable: true,
      hide: columnHide(),
      filter: "agSetColumnFilter",
      excelMode: "windows",
      tooltipField: "main",
      cellRenderer: cellrander,
      // pinned: responsiveColumnPin(),
    },
    {
      headerName: "Rating Action",
      field: "ratingAction",
      minWidth: 130,
      minWidth: 130,
      sortable: true,
      hide: columnHide(),
      filter: "agSetColumnFilter",
      excelMode: "windows",
      tooltipField: "main",
      cellRenderer: cellrander,
      // pinned: responsiveColumnPin(),
    },
  ];
  const MobViewRender = (params) => (
    <h1 style={{ padding: "10px 20px" }}>
      <Table className="overflow-scroll responsiveTableFonts">
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Pre Rev LT
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.pre_lterm}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Post Rev LT
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.lterm}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            variant="head"
            className="fw-bolder responsiveTableFonts py-1"
          >
            Rating Action
          </TableCell>
          <TableCell className="responsiveTableFonts py-1">
            {params.data.ratingAction}
          </TableCell>
        </TableRow>
      </Table>
    </h1>
  );

  return (
    <>
      <div>
        <p
          className="mx-auto p-3 fs-4 fw-bold mb-0 text-capitalize"
          style={{ width: "fit-content", color: "#000078" }}
        >
          B-iii | Rating Movement from Investment Grade to Non-investment Grade
          and vice versa
        </p>
        <div className="row w-100">
          <div
            className="card shadow mx-auto col-lg-10 col-md-10 col-sm-10 col-11 pt-4"
            style={{ height: "auto", backgroundColor: "#e0e0e0" }}
          >
            <div className="row w-100 pb-3">
              <div className="col-lg-5 col-md-5 ms-lg-4 ms-md-2 ms-sm-3 col-sm-11 col-11 ms-2">
                <input
                  type="date"
                  style={{ height: "38px" }}
                  defaultValue={fromDate}
                  className="form-control"
                  onChange={(e) => {
                    setstartdate(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-5 col-md-5 ms-md-1 pt-sm-1 pt-md-0 col-sm-11 ms-sm-3 col-11 ms-2 pt-1">
                <input
                  type="date"
                  style={{ height: "38px" }}
                  defaultValue={toDate}
                  className="form-control"
                  onChange={(e) => {
                    setenddate(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 pt-sm-1 pt-md-0 ms-md-1 mx-sm-auto col-1 pt-1 mx-auto">
                <button
                  type="button"
                  className="btn btn-primary h-60"
                  onClick={updatedata}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <br />
      <TableComponent
        value={"ratingmoventIGF"}
        update = {Update}
        rowstatus = {rowstatus}
        report={true}
        handleRfreshTab={props.handleRfreshTab}
        Data={ratingmoventIGF}
        screenWidth={props.screenWidth}
        MobViewRender={MobViewRender}
        columnDefs={columnDefs}
        datefilters={datefilters}
        datefilter={true}
        FullPageTable={true}
      />
    </>
  );
}

export default RatingmoventIGF;
